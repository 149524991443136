import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from 'src/app/config/event-emitter.service';
import { NotificationService } from 'src/app/config/notification.service';
import { Permission } from 'src/app/utils/enums/permission.enum';
import { UserPermissionService } from 'src/app/utils/services/user-permission.service';
import { OnboardService } from './onboard.service';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
})
export class OnboardComponent implements OnInit {

  viewPartnerProfileActionPermission: boolean = false;
  viewPartnerOnboardActionPermission: boolean = false;
  resendInviteLinkPartnerOnboardActionPermission: boolean = false;
  approvePartnerOnboardActionPermission: boolean = false;
  rejectPartnerOnboardActionPermission: boolean = false;

  viewClientProfileActionPermission: boolean = false;
  viewClientOnboardActionPermission: boolean = false;
  resendInviteLinkClientOnboardActionPermission: boolean = false;
  approveClientOnboardActionPermission: boolean = false;
  rejectClientOnboardActionPermission: boolean = false;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;
  ticket: any;

  displayedColumnsTableOne: string[] = ['indexnumber','vDisplayUniqueId','vEmailId','vPhoneNo','iStatus','iCreatedAt','actions'];
  dataSourceTableOne = new MatTableDataSource();

  view: any = 0;

  constructor(
    private service: OnboardService,
    public ngxSmartModalService: NgxSmartModalService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private notifyService : NotificationService,
    private userPermissionService: UserPermissionService
  ) {
    // assign individual permissions
    const ViewPartnerProfileAction: Permission = Permission.ViewPartnerProfileAction;
    const ViewPartnerOnboardAction: Permission = Permission.ViewPartnerOnboardAction;
    const ResendInviteLinkPartnerOnboardAction: Permission = Permission.ResendInviteLinkPartnerOnboardAction;
    const ApprovePartnerOnboardAction: Permission = Permission.ApprovePartnerOnboardAction;
    const RejectPartnerOnboardAction: Permission = Permission.RejectPartnerOnboardAction;

    const ViewClientProfileAction: Permission = Permission.ViewClientProfileAction;
    const ViewClientOnboardAction: Permission = Permission.ViewClientOnboardAction;
    const ResendInviteLinkClientOnboardAction: Permission = Permission.ResendInviteLinkClientOnboardAction;
    const ApproveClientOnboardAction: Permission = Permission.ApproveClientOnboardAction;
    const RejectClientOnboardAction: Permission = Permission.RejectClientOnboardAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ViewPartnerProfileAction)) {
      this.viewPartnerProfileActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ViewClientProfileAction)) {
      this.viewClientProfileActionPermission = true;
    }

    if (this.userPermissionService.isAuthorizedFor(ViewPartnerOnboardAction)) {
      this.viewPartnerOnboardActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ResendInviteLinkPartnerOnboardAction)) {
      this.resendInviteLinkPartnerOnboardActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ApprovePartnerOnboardAction)) {
      this.approvePartnerOnboardActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(RejectPartnerOnboardAction)) {
      this.rejectPartnerOnboardActionPermission = true;
    }

    if (this.userPermissionService.isAuthorizedFor(ViewClientOnboardAction)) {
      this.viewClientOnboardActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ResendInviteLinkClientOnboardAction)) {
      this.resendInviteLinkClientOnboardActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ApproveClientOnboardAction)) {
      this.approveClientOnboardActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(RejectClientOnboardAction)) {
      this.rejectClientOnboardActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.getRefreshAPIData();
  }

  getRefreshAPIData() {
    if(this.viewPartnerOnboardActionPermission==false && this.viewClientOnboardActionPermission){
      this.clientDetails(this.viewClientOnboardActionPermission);
    }else{
      this.GetPartnerOnboard();
    }
  }

  partnerDetails(hasPermission:boolean) {
    if (hasPermission) {
    this.view = "0";
    this.getRefreshAPIData();
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  clientDetails(hasPermission:boolean) {
    if (hasPermission) {
    this.view = "1";
    this.getOnboardList();
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  public primarySkillData: any = [];
  GetPartnerOnboard() {
    this.service.GetPartnerOnboard().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        // this.notifyService.showError(result.message,'');
        this.notifyService.showError(result.message, "");
      }
    });
  }

  getOnboardList() {
    this.service.getOnboardList().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        // this.notifyService.showError(result.message,'');
        this.notifyService.showError(result.message, "");

      }
    });
  }

  acceptPartnerOnboardTicket(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.service.acceptPartnerOnboardTicket({iTicketId:data.iTicketId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetPartnerOnboard();
          // this.notifyService.showSuccess(result.message,'');
        this.notifyService.showSuccess(result.message,'');

        } else {
          // this.notifyService.showError(result.message,'');
        this.notifyService.showError(result.message, "");
        }
      });
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  acceptOnboardTicket(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.service.acceptOnboardTicket({iTicketId:data.iTicketId}).then((result: any) => {
        if (result['code'] == 200) {
          this.getOnboardList();
          // this.notifyService.showSuccess(result.message,'');
        this.notifyService.showSuccess(result.message,'');

        } else {
          // this.notifyService.showError(result.message,'');
        this.notifyService.showError(result.message, "");

        }
      });
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  rejectPartnerOnboardTicket(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.service.rejectPartnerOnboardTicket({iTicketId:data.iTicketId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetPartnerOnboard();
          // this.notifyService.showSuccess(result.message,'');
        this.notifyService.showSuccess(result.message,'');

        } else {
          // this.notifyService.showError(result.message,'');
        this.notifyService.showError(result.message, "");

        }
      });
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  rejectOnboardTicket(data:any, hasPermission: boolean) {
    if (hasPermission) {
      this.service.rejectOnboardTicket({iTicketId:data.iTicketId}).then((result: any) => {
        if (result['code'] == 200) {
          this.getOnboardList();
          // this.notifyService.showSuccess(result.message,'');
        this.notifyService.showSuccess(result.message,'');

        } else {
          // this.notifyService.showError(result.message,'');
        this.notifyService.showError(result.message, "");

        }
      });
    }
  }

  partnerOnboardingTicketView(data: any, modalToOpen: any, hasPermission: boolean){
    if (hasPermission) {
      this.service.PartnerOnboardingTicketView(data.iTicketId).then((result: any) => {
        if (result['code'] == 200) {
          this.ticket = result['data'];
          this.modalService.open(modalToOpen, { centered: true , windowClass: 'partnerOnboard-modal'});
        } else {
          // this.notifyService.showError(result.message,'');
        this.notifyService.showError(result.message, "");

        }
      });
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  OnboardingTicketView(data: any, modalToOpen: any, hasPermission: boolean){
    if (hasPermission) {
      this.service.OnboardingTicketView(data.iTicketId).then((result: any) => {
        if (result['code'] == 200) {
          this.ticket = result['data'];
          this.modalService.open(modalToOpen, { centered: true , windowClass: 'partnerOnboard-modal' });
        } else {
          // this.notifyService.showError(result.message,'');
        this.notifyService.showError(result.message, "");

        }
      });
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  public vEmailId:any;
  PartnerResendInviteLinkModal(modelName:any, vEmailId:any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modelName, { centered:true, windowClass: 'confirmation-modal' });
      this.vEmailId = vEmailId;
    }
  }

  ClientResendInviteLinkModal(modelName:any, vEmailId:any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modelName, { centered:true, windowClass: 'confirmation-modal' });
      this.vEmailId = vEmailId;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  RefreshPartnerInvite(vEmailId: any){
    this.service.RefreshPartnerInvite({vEmailId: vEmailId}).then((result: any) => {
      if (result.code == 200) {
        this.notifyService.showSuccess(result.message,'');
      }
    });
  }

  RefreshClientInvite(vEmailId: any){
    this.service.RefreshClientInvite({vEmailId: vEmailId}).then((result: any) => {
      if (result.code == 200) {
        this.notifyService.showSuccess(result.message,'');
      }
    });
  }
}
