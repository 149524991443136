import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { EventEmitterService } from '../../config/event-emitter.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html'
})
export class ErrorModalComponent implements OnInit {

  constructor(public ngxSmartModalService: NgxSmartModalService, public eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
    this.eventEmitterService.modelMessageData.subscribe((data:any) => {
      this.displayPopUpModelFn(data);
    });
  }

  public displayPopUp_msg:any = "";
  public displayPopUp_status:any = "";
  displayPopUpModelFn(data:any){
      this.displayPopUp_msg = data.msg;
      this.displayPopUp_status = data.status;
      this.ngxSmartModalService.open("displayPopUpModel");
      setTimeout(() => {
        this.ngxSmartModalService.close("displayPopUpModel");
      }, environment.dis_alert_timeout);
  }

}
