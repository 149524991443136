import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private http: HttpClient) { }

  getUserList() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/client`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  changeActiveInactiveUser(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/user-active-inactive`;
      this.http
        .put(apiURL,data)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  // GetClientStatus(iUserId:any) {
  //   const promise = new Promise((resolve, reject) => {
  //     let apiURL = environment.baseUrl + `/client/v1/client-profile-status/?iUserId=`+iUserId;
  //     this.http
  //       .get(apiURL)
  //       .toPromise()
  //       .then((res) => {
  //         resolve(res);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  //   return promise;
  // }

}
