<div class="container-fluid skill-management">
    <div class="row">
      <div class="col-12">
        <div class="wrapper">

          <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="title" role="tabpanel" aria-labelledby="skill-management-tab">

              <div class="pagetitle-wrapper">
                <div class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                  <div class="d-flex align-items-center">
                    <i class="pagetitle-icon"><span class="material-icons">extension</span></i>
                    <h2 class="pagetitle">Permission Management</h2>
                  </div>
                </div>
                <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                  <button matSuffix mat-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <input matInput placeholder="" (keyup)="applyFilterOne($event)" />
                </mat-form-field>
                <div class="table-responsive">
                  <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                  </div>
                  <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }" [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                    <ng-container matColumnDef="indexnumber">
                      <th mat-header-cell *matHeaderCellDef> No </th>
                      <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableOnePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.TableOnePaginator.pageIndex * this.TableOnePaginator.pageSize}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vRoleName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.vRoleName}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="tiRoleType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Role Type </th>
                      <td mat-cell *matCellDef="let element">
                        <mat-chip-list>
                          <mat-chip class="blue-chip" *ngIf="element.tiRoleType == 1">
                            Admin
                          </mat-chip>
                          <mat-chip class="blue-chip" *ngIf="element.tiRoleType == 3">
                            Partner
                          </mat-chip>
                          <mat-chip class="blue-chip" *ngIf="element.tiRoleType == 4">
                            Client
                          </mat-chip>
                        </mat-chip-list>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="iCreatedAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Time </th>
                        <td mat-cell *matCellDef="let element">
                          <span>{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="tiStatus">
                        <th name="user-role-status-header" id="user-role-status-header"
                          mat-header-cell *matHeaderCellDef>
                          Status
                        </th>
                        <td name="user-role-status" id="user-role-status" mat-cell
                          *matCellDef="let element">
                          <span *ngIf="element.tiStatus == 1" class="status-active" data-bs-toggle="tooltip" data-bs-placement="top" title="Permissions are assigned">Active</span>
                          <span *ngIf="element.tiStatus == 0" class="status-reject" data-bs-toggle="tooltip" data-bs-placement="top" title="Permissions are not assigned">Inactive</span>
                        </td>
                      </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> Actions </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex flex-wrap">
                          <button class="status-btn lightblue-bg blue-text text-center mb-2" placement="left auto" [ngbTooltip]="editRolePermissionActionPermission ? 'Edit Permission':'Not Authorized'" [ngClass]="editRolePermissionActionPermission ? '':'unauthorized-cursor'"(click)="OpenModelEditPermission(editPermission,element,editRolePermissionActionPermission)">
                            <mat-icon>create</mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                  </table>
                </div>
                <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }" #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
                </mat-paginator>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <ng-template #editPermission let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Edit Permission : {{vRoleName}}</h1>
          <perfect-scrollbar style="height: 400px;">
            <ul class="permission">
                <ng-container *ngFor="let main of permission; let i = index">
                  <li>
                    <div class="d-flex align-items-center">
                    <button *ngIf="!main.isExpanded" (click)="Toggle(main)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Open">
                        <mat-icon class="icon-size-1">add</mat-icon>
                    </button>
                    <button *ngIf="main.isExpanded" (click)="Toggle(main)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Close">
                        <mat-icon class="icon-size-1">remove</mat-icon>
                    </button>
                    <input
                      [checked]="main.tiParentStatus"
                      type="checkbox"
                      [value]="main.vParentFeature"
                      class="mx-2"
                      (change)="mainPermissionChange($event, main)"
                    />
                    <span class="fw-semibold">{{ main.vParentFeature }}</span>
                    </div>
                    <div class="mx-3">
                    <ng-container
                      *ngFor="let sub of main.child; let k = index"
                    >
                    <li *ngIf="main.child" style="margin-left: 25px" [ngClass]="main.isExpanded?'child-section-open': 'child-section-close'">
                        <input
                          (change)="subPermissionChange($event, main, sub)"
                          type="checkbox"
                          value="sub.vChildFeature"
                          class="mx-2"
                          [checked]="sub.tiChildStatus"
                        />{{ sub.vChildFeature }}
                      </li>
                    </ng-container>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </perfect-scrollbar>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn" (click)="EditPermission()">Update</button>
              <button type="button" class="modal-btn-outline" (click)="d('Cross click');this.permission=[]">Cancel</button>
            </div>
        </div>
      </div>
    </div>
  </ng-template>


