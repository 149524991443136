<div name="documents-box" id="documents-box" class="col-12">
  <div name="documents-card-container" id="documents-card-container" class="card-container" *ngIf="documentsToggle == false">
    <div name="documents-card-header" id="documents-card-header" class="card-header">
      <div name="documents-title-section" id="documents-title-section" class="title-section">
        <h4 name="documents-card-title" id="documents-card-title" class="card-title">Documents</h4>
        <div placement="right auto" [ngbTooltip]="editTalentDocumentsActionPermission ? '' : 'Not Authorized'" [ngClass]="
        editTalentDocumentsActionPermission ? '' : 'unauthorized-cursor'">
        <button name="documents-card-edit-button" id="documents-card-edit-button" class="card-edit-button" type="button" (click)="toggleDocuments(editTalentDocumentsActionPermission)" [ngClass]="
        editTalentDocumentsActionPermission ? '' : 'unauthorized-action'">
          <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
        </button>
        </div>
      </div>
      <!-- <div name="documents-incomplete-status-section" id="documents-incomplete-status-section" class="status-section">
        <div name="documents-incomplete-status-box" id="documents-incomplete-status-box" class="status-box" *ngIf="!documentsCardCompleted">
          <img id="documents-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'" alt="status-icon">
          <label name="documents-incomplete-status-text" id="documents-incomplete-status-text" class="status-text incomplete">Incomplete</label>
        </div>
        <div name="documents-completed-status-box" id="documents-completed-status-box" class="status-box" *ngIf="documentsCardCompleted">
          <img id="documents-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'" alt="status-icon">
          <label name="documents-completed-status-text" id="documents-completed-status-text" class="status-icon" class="status-text completed">Completed</label>
        </div>
      </div> -->
    </div>
    <!-- <div name="documents-card" id="documents-card" class="card" [ngClass]="documentsCardCompleted ? 'completed' : 'incomplete'"> -->
    <div name="documents-card" id="documents-card" class="card">
      <div name="documents-card-content" id="documents-card-content" class="card-content">
        <div name="documents-cmmi-level-text-box-with-icon" id="documents-cmmi-level-text-box-with-icon" class="text-box-with-icon bottom-spacing">
          <img id="documents-cmmi-level-icon" class="icon" [src]="documentsStatus?.vAadharPdf ? imagePath+'green-tick.svg' : imagePath+'info-icon.svg'" alt="green-tick">
          <label name="documents-cmmi-level-text" id="documents-cmmi-level-text" class="text" [ngClass]="{'disabled': !documentsStatus?.vAadharPdf}">Aadhaar
            <!-- <span class="text-danger" [ngClass]="{'d-none': documentsStatus?.vAadharPdf}">*</span> -->
          </label>
        </div>
        <div name="documents-utility-bill-text-box-with-icon" id="documents-utility-bill-text-box-with-icon" class="text-box-with-icon bottom-spacing">
          <img id="documents-utility-bill-icon" class="icon" [src]="imagePath + 'green-tick.svg'" [src]="documentsStatus?.vPanPdf ? imagePath+'green-tick.svg' : imagePath+'info-icon.svg'" alt="green-tick">
          <label name="documents-utility-bill-text" id="documents-utility-bill-text" class="text" [ngClass]="{'disabled': !documentsStatus?.vPanPdf}">PAN Card
            <!-- <span class="text-danger" [ngClass]="{'d-none': documentsStatus?.vPanPdf}">*</span> -->
          </label>
        </div>
        <div name="documents-trademark-text-box-with-icon" id="documents-trademark-text-box-with-icon" class="text-box-with-icon">
          <img id="documents-trademark-icon" class="icon" [src]="imagePath + 'info-icon.svg'" [src]="documentsStatus?.vDrivingPdf ? imagePath+'green-tick.svg' : imagePath+'info-icon.svg'" alt="info-icon">
          <label name="documents-trademark-text" id="documents-trademark-text" class="text" [ngClass]="{'disabled': !documentsStatus?.vDrivingPdf}">Driving <span [ngClass]="{'d-none': documentsStatus?.vDrivingPdf}"></span></label>        
        </div>
        <div name="documents-trademark-text-box-with-icon" id="documents-trademark-text-box-with-icon" class="text-box-with-icon">
          <img id="documents-trademark-icon" class="icon" [src]="imagePath + 'info-icon.svg'" [src]="documentsStatus?.vPassportPdf ? imagePath+'green-tick.svg' : imagePath+'info-icon.svg'" alt="info-icon">
          <label name="documents-trademark-text" id="documents-trademark-text" class="text" [ngClass]="{'disabled': !documentsStatus?.vPassportPdf}">Passport <span [ngClass]="{'d-none': documentsStatus?.vPassportPdf}"></span></label>
        </div>
      </div>
    </div>
  </div>
</div>

<div name="documents-card-container" id="documents-card-container" class="card-container"
*ngIf="documentsToggle == true">
<div name="documents-card-header" id="documents-card-header" class="card-header">
  <div name="documents-title-section" id="documents-title-section" class="title-section">
    <h4 name="documents-card-title" id="documents-card-title" class="card-title">
      Documents
    </h4>
  </div>
</div>
<div class="edit-profile">
    <div name="documents-modal-body" id="documents-modal-body" class="modal-body modal-scroll">
      <form id="documentsForm" name="documentsForm" class="form" [formGroup]="documentsForm" (ngSubmit)="UploadDocuments()">
        <div name="documents-row" id="documents-row" class="row">
          <div name="documents-col-1" id="documents-col-1" class="col-12">
            <div name="documents-col-1-bold-title" id="documents-col-1-bold-title" class="bold-title first-space">
              Aadhaar
            </div>
          </div>
          <div name="documents-col-2" id="documents-col-2" class="col-12 col-md-6">
            <label for="iAadharCardNumber" name="documents-col-2-label" id="documents-col-2-label" class="custom-label">Aadhaar Card Number
              <!-- <span class="text-danger">*</span> -->
            </label>
            <mat-form-field hideRequiredMarker name="documents-col-2-input" id="documents-col-2-input" class="custom-input readonly" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="iAadharCardNumber" formControlName="iAadharCardNumber" placeholder="573526480077" readonly>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <label for="vAadharPdf" name="documents-video-label-1" id="documents-video-label-1" class="custom-label">Upload File
              <!-- <span class="text-danger">*</span> -->
            </label>
            <mat-form-field hideRequiredMarker name="documents-video-input-1" id="documents-video-input-1" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngIf="vAadharPdf">
                        {{ vAadharPdf.name }} <button type="button" name="button" class="btn" (click)="RemoveMedia(1)"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="documents-video-input-1-vVideo-1" id="documents-video-input-1-vVideo-1" matInput readonly formControlName="vAadharPdf" [value]="vAadharPdf ? ' ' : ''" placeholder="Upload File" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="documents-video-input-1-vVideo-2"
                id="vAadharPdf"
                class="file-upload-input"
                (change)="FileBrowseHandler($event,1)"
                formControlName="vAadharPdf"
                accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel"
              />
              <button name="documents-video-input-1-upload-btn" id="documents-video-input-1-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <!-- <mat-error class="reduce-bottom-space" *ngIf="documentsForm.controls['vAadharPdf'].invalid">
                <mat-error class="reduce-bottom-space" *ngIf="documentsForm.controls['vAadharPdf'].errors!.required">
                  File is required
                </mat-error>
              </mat-error> -->
              <mat-hint class="text">Max 4MB</mat-hint>
            </mat-form-field>
          </div>
          <div name="awards-class" id="awards-class" class="col-12 mt-2" *ngIf = "documentsData?.vAadharPdf">
            <div name="awards-class-1" id="awards-1" class="image-display-container pb-2">
              <div name="award-1" id="award-1" class="image-name">
                <span>1)</span>
                <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                <a [href]="documentsData.vAadharPdfUrl" target="_blank">{{ documentsData.vAadharPdf }}</a>
              </div>
              <!-- <button type="button" name="button" id="award-1-btn" (click)="DeleteDocument(documentsData.vAadharPdf)">
                <img id="award-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
              </button> -->
            </div>
          </div>
          <div name="documents-col-4" id="documents-col-4" class="col-12">
            <div name="documents-col-4-bold-title" id="documents-col-4-bold-title" class="bold-title">
              PAN
            </div>
          </div>
          <div name="documents-col-5" id="documents-col-5" class="col-12 col-md-6">
            <label for="iPanNumber" name="documents-col-5-label" id="documents-col-5-label" class="custom-label">PAN Number
              <!-- <span class="text-danger">*</span> -->
            </label>
            <mat-form-field hideRequiredMarker name="documents-col-3-input" id="documents-col-3-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="iPanNumber" formControlName="iPanNumber" placeholder="EDERW2222W">
              <mat-error name="documents-col-5-error-0" id="documents-col-5-error-0" class="reduce-bottom-space" *ngIf="documentsForm.controls['iPanNumber'].invalid">
                <!-- <mat-error name="documents-col-5-error-1" id="documents-col-5-error-1" class="reduce-bottom-space" *ngIf="documentsForm.controls['iPanNumber'].errors!.required">
                  PAN Number is required
                </mat-error> -->
                <mat-error name="documents-col-5-error-1" id="documents-col-5-error-1" class="reduce-bottom-space" *ngIf="(documentsForm.controls['iPanNumber'].errors!.minlength || documentsForm.controls['iPanNumber'].errors!.maxlength) && !(documentsForm.controls['iPanNumber'].errors!.pattern)">
                  PAN Number should be 10 digit
                </mat-error>
                <mat-error name="documents-col-5-error-1" id="documents-col-5-error-1" class="reduce-bottom-space" *ngIf="documentsForm.controls['iPanNumber'].errors!.pattern">
                  Please enter valid PAN Number
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <label for="vPanPdf" name="documents-video-label-2" id="documents-video-label-2" class="custom-label">Upload File
              <!-- <span class="text-danger">*</span> -->
            </label>
            <mat-form-field hideRequiredMarker name="documents-video-input-2" id="documents-video-input-2" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngIf="vPanPdf">
                        {{ vPanPdf.name }} <button type="button" name="button" class="btn" (click)="RemoveMedia(2)"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="documents-video-input-2-vVideo-1" id="documents-video-input-2-vVideo-1" matInput readonly formControlName="vPanPdf" [value]="vPanPdf ? ' ' : ''" placeholder="Upload File" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="documents-video-input-2-vVideo-2"
                id="vPanPdf"
                class="file-upload-input"
                (change)="FileBrowseHandler($event,2)"
                formControlName="vPanPdf"
                accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel"
              />
              <button name="documents-video-input-2-upload-btn" id="documents-video-input-2-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <!-- <mat-error class="reduce-bottom-space" *ngIf="documentsForm.controls['vPanPdf'].invalid">
                <mat-error class="reduce-bottom-space" *ngIf="documentsForm.controls['vPanPdf'].errors!.required">
                  File is required
                </mat-error>
              </mat-error> -->
              <mat-hint class="text">Max 4MB</mat-hint>
            </mat-form-field>
          </div>
          <div name="awards-class" id="awards-class" class="col-12 mt-2" *ngIf = "documentsData?.vPanPdf">
            <div name="awards-class-1" id="awards-1" class="image-display-container pb-2">
              <div name="award-1" id="award-1" class="image-name">
                <span>1)</span>
                <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                <a [href]="documentsData.vPanPdfUrl" target="_blank">{{ documentsData.vPanPdf }}</a>
              </div>
              <!-- <button type="button" name="button" id="award-1-btn" (click)="DeleteDocument(documentsData.vPanPdf)">
                <img id="award-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
              </button> -->
            </div>
          </div>
          <div name="documents-col-7" id="documents-col-7" class="col-12">
            <div name="documents-col-7-bold-title" id="documents-col-7-bold-title" class="bold-title">
              Universal Account Number (UAN)
            </div>
          </div>
          <div name="documents-col-8" id="documents-col-8" class="col-12 col-md-6">
            <label for="iUanNumber" name="documents-col-8-label" id="documents-col-8-label" class="custom-label">UAN Number
              <!-- <span class="text-danger">*</span> -->
            </label>
            <mat-form-field hideRequiredMarker name="documents-col-8-input" id="documents-col-8-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="iUanNumber" formControlName="iUanNumber" placeholder="343434343434">
              <mat-error name="documents-col-8-error-0" id="documents-col-8-error-0" class="reduce-bottom-space" *ngIf="documentsForm.controls['iUanNumber'].invalid">
                <!-- <mat-error name="documents-col-8-error-1" id="documents-col-8-error-1" class="reduce-bottom-space" *ngIf="documentsForm.controls['iUanNumber'].errors!.required">
                  UAN Number is required
                </mat-error> -->
                <mat-error name="documents-col-8-error-1" id="documents-col-8-error-1" class="reduce-bottom-space" *ngIf="(documentsForm.controls['iUanNumber'].errors!.minlength || documentsForm.controls['iUanNumber'].errors!.maxlength) && !(documentsForm.controls['iUanNumber'].errors!.pattern)">
                  UAN Number should be 12 digit
                </mat-error>
                <mat-error name="documents-col-8-error-1" id="documents-col-8-error-1" class="reduce-bottom-space" *ngIf="documentsForm.controls['iUanNumber'].errors!.pattern">
                  Please enter valid number
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="documents-col-10" id="documents-col-10" class="col-12">
            <div name="documents-col-10-bold-title" id="documents-col-10-bold-title" class="bold-title">
              Passport
            </div>
          </div>
          <div name="documents-col-11" id="documents-col-11" class="col-12 col-md-6">
            <label for="iPassportNumber" name="documents-col-11-label" id="documents-col-11-label" class="custom-label">Passport Number</label>
            <mat-form-field hideRequiredMarker name="documents-col-11-input" id="documents-col-11-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="iPassportNumber" formControlName="iPassportNumber" placeholder="S3434343">
              <mat-error class="reduce-bottom-space" *ngIf="documentsForm.controls['iPassportNumber'].invalid">
                <mat-error class="reduce-bottom-space" *ngIf="(documentsForm.controls['iPassportNumber'].errors!.minlength || documentsForm.controls['iPassportNumber'].errors!.maxength) && !(documentsForm.controls['iPassportNumber'].errors!.pattern)">
                  Passport Number should be 8 digit
                </mat-error>
                <mat-error class="reduce-bottom-space" *ngIf="documentsForm.controls['iPassportNumber'].errors!.pattern">
                  Please enter valid number
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <label for="vPassportPdf" name="documents-video-label-3" id="documents-video-label-3" class="custom-label">Upload File</label>
            <mat-form-field hideRequiredMarker name="documents-video-input-3" id="documents-video-input-3" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngIf="vPassportPdf">
                        {{ vPassportPdf.name }} <button type="button" name="button" class="btn" (click)="RemoveMedia(3)"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="documents-video-input-3-vVideo-1" id="documents-video-input-3-vVideo-1" matInput readonly [value]="vPassportPdf ? ' ' : ''" placeholder="Upload File" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="documents-video-input-3-vVideo-2"
                id="vPassportPdf"
                class="file-upload-input"
                (change)="FileBrowseHandler($event,3)"
                formControlName="vPassportPdf"
                accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel"
              />
              <button name="documents-video-input-3-upload-btn" id="documents-video-input-3-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <mat-hint class="text">Max 4MB</mat-hint>
            </mat-form-field>
          </div>
          <div name="awards-class" id="awards-class" class="col-12 mt-2" *ngIf = "documentsData?.vPassportPdf">
            <div name="awards-class-1" id="awards-1" class="image-display-container pb-2">
              <div name="award-1" id="award-1" class="image-name">
                <span>1)</span>
                <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                <a [href]="documentsData.vPassportPdfUrl" target="_blank">{{ documentsData.vPassportPdf }}</a>
              </div>
              <button type="button" name="button" id="award-1-btn" (click)="DeleteDocument(documentsData.vPassportPdf)">
                <img id="award-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
              </button>
            </div>
          </div>
          <div name="documents-col-10" id="documents-col-10" class="col-12">
            <div name="documents-col-10-bold-title" id="documents-col-10-bold-title" class="bold-title">
              Driving License
            </div>
          </div>
          <div name="documents-col-11" id="documents-col-11" class="col-12 col-md-6">
            <label for="iDrivingLicenseNumber" name="documents-col-11-label" id="documents-col-11-label" class="custom-label">Driving License Number </label>
            <mat-form-field hideRequiredMarker name="documents-col-11-input" id="documents-col-11-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="iDrivingLicenseNumber" formControlName="iDrivingLicenseNumber" placeholder="GJ01 20210029521">
              <!-- <mat-error name="documents-col-11-error-0" id="documents-col-11-error-0" class="reduce-bottom-space" *ngIf="documentsForm.controls['iDrivingLicenseNumber'].invalid">
                <mat-error name="documents-col-11-error-1" id="documents-col-11-error-1" class="reduce-bottom-space" *ngIf="documentsForm.controls['iDrivingLicenseNumber'].errors!.required">
                  Driving License Number is required
                </mat-error>
                 <mat-error name="documents-col-11-error-1" id="documents-col-11-error-1" class="reduce-bottom-space" *ngIf="documentsForm.controls['iDrivingLicenseNumber'].errors!.pattern">
                  Please enter valid number
                </mat-error> 
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <label for="vDrivingPdf" name="documents-video-label-4" id="documents-video-label-4" class="custom-label">Upload File </label>
            <mat-form-field hideRequiredMarker name="documents-video-input-4" id="documents-video-input-4" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngIf="vDrivingPdf">
                        {{ vDrivingPdf.name }} <button type="button" name="button" class="btn" (click)="RemoveMedia(4)"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="documents-video-input-4-vVideo-1" id="documents-video-input-4-vVideo-1" matInput readonly formControlName="vDrivingPdf" [value]="vDrivingPdf ? ' ' : ''" placeholder="Upload File" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="documents-video-input-4-vVideo-2"
                id="vDrivingPdf"
                class="file-upload-input"
                (change)="FileBrowseHandler($event,4)"
                formControlName="vDrivingPdf"
                accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel"
              />
              <button name="documents-video-input-4-upload-btn" id="documents-video-input-4-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <!-- <mat-error class="reduce-bottom-space" *ngIf="documentsForm.controls['vDrivingPdf'].invalid">
                <mat-error class="reduce-bottom-space" *ngIf="documentsForm.controls['vDrivingPdf'].errors!.required">
                  File is required
                </mat-error>
              </mat-error> -->
              <mat-hint class="text">Max 4MB</mat-hint>
            </mat-form-field>
          </div>
          <div name="awards-class" id="awards-class" class="col-12 mt-2" *ngIf = "documentsData?.vDrivingPdf">
            <div name="awards-class-1" id="awards-1" class="image-display-container pb-2">
              <div name="award-1" id="award-1" class="image-name">
                <span>1)</span>
                <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                <a [href]="documentsData.vDrivingPdfUrl" target="_blank">{{ documentsData.vDrivingPdf }}</a>
              </div>
              <button type="button" name="button" id="award-1-btn" (click)="DeleteDocument(documentsData.vDrivingPdf)">
                <img id="award-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div name="documents-modal-footer" id="documents-modal-footer" class="modal-footer">
      <button type="button" name="documents-modal-footer-btn-cancel" id="documents-modal-footer" class="blue-secondary-small-btn mx-2" form="documentsForm" (click)="CancelDocumentsModal(); GetDocuments()">Cancel</button>
      <button type="submit" name="documents-modal-footer-btn-submit" id="documents-modal-footer" class="blue-primary-small-btn" form="documentsForm">Save</button>
    </div>
  </div>
</div>
