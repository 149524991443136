import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, private router: Router) { }

  login(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + '/admin/v1/login';
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetPasswordResetLink(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + '/common/v1/forgot-password';
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  VerifyToken(token: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + `/common/v1/resetlink/${token}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  ResetPassword(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + '/common/v1/reset-password';
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  HandleSubUserLogin(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/admin/v2/subuser-login`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
  
  CheckLoginEmail(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v2/check-login-email`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

}
