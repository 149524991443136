<div name="company-details-box" id="company-details-box" class="col-12">
  <div
    name="company-details-card-container"
    id="company-details-card-container"
    class="card-container"
    *ngIf="companyDetailsToggle == false"
  >
    <div
      name="company-details-card-header"
      id="company-details-card-header"
      class="card-header"
    >
      <div
        name="company-details-title-section"
        id="company-details-title-section"
        class="title-section"
      >
        <h4
          name="company-details-card-title"
          id="company-details-card-title"
          class="card-title"
        >
          Company Details
        </h4>
        <button
          name="company-details-card-edit-button"
          id="company-details-card-edit-button"
          class="card-edit-button"
          type="button"
          (click)="
            toggleCompanyDetails(editClientCompanyDetailsActionPermission)
          "
          [ngClass]="
            editClientCompanyDetailsActionPermission
              ? ''
              : 'unauthorized-cursor'
          "
          placement="right auto"
          [ngbTooltip]="
            editClientCompanyDetailsActionPermission ? '' : 'Not Authorized'
          "
        >
          <img
            class="card-edit-button-icon"
            [src]="imagePath + 'blue-edit-icon.svg'"
            alt="edit-button"
          />
        </button>
      </div>
    </div>
    <div name="company-details-card" id="company-details-card" class="card">
      <div
        name="company-details-card-content"
        id="company-details-card-content"
        class="card-content"
      >
        <div name="company-details-row" id="company-details-row" class="row">
          <div
            name="company-details-col-1"
            id="company-details-col-1"
            class="col-12 col-lg-3 col-sm-6"
          >
            <label
              name="company-details-col-1-label"
              id="company-details-col-1-label"
              class="label"
            >
              Intro Deck
            </label>
            <label
              name="company-details-col-1-text"
              id="company-details-col-1-text"
              class="text link bottom-spacing"
              *ngIf="companyDetailsArray.intro_deck_url"
            >
              <a target="_blank" href="{{ companyDetailsArray.intro_deck_url }}"
                >View Introdeck</a
              >
              <img
                class="info-tooltip"
                [src]="imagePath + 'info_icon.svg'"
                alt="email-info"
                placement="bottom"
                ngbTooltip="Click on View Intro Deck for view Company Information!"
              />
            </label>
            <label
              name="company-details-col-1-text"
              id="company-details-col-1-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.intro_deck_url == ''"
            >
              No File Attached
              <img
                class="info-tooltip"
                *ngIf="companyDetailsArray.intro_deck_url"
                [src]="imagePath + 'info_icon.svg'"
                alt="email-info"
                placement="bottom"
                ngbTooltip="Click on View Intro Deck for view Company Information!"
              />
            </label>
          </div>
          <div
            name="company-details-col-2"
            id="company-details-col-2"
            class="col-12 col-lg-3 col-sm-6"
          >
            <label
              name="company-details-col-2-label"
              id="company-details-col-2-label"
              class="label"
            >
              Video
            </label>
            <label
              name="company-details-col-2-text"
              id="company-details-col-2-text"
              class="text link bottom-spacing"
              *ngIf="companyDetailsArray.video_url"
            >
              <a target="_blank" href="{{ companyDetailsArray.video_url }}"
                >View Video</a
              >
            </label>
            <label
              name="company-details-col-2-text"
              id="company-details-col-2-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.video_url == ''"
              >Not Available</label
            >
          </div>
          <div
            name="company-details-col-3"
            id="company-details-col-3"
            class="col-12 col-lg-3 col-sm-6"
          >
            <label
              name="company-details-col-3-label"
              id="company-details-col-3-label"
              class="label"
            >
              Images
            </label>
            <label
              name="company-details-col-3-text"
              id="company-details-col-3-text"
              class="text link bottom-spacing"
              *ngIf="companyDetailsArray.images == 0"
            >
              No Files
            </label>
            <span *ngIf="companyDetailsArray.images > 0">
              <label
                name="company-details-col-3-text"
                id="company-details-col-3-text"
                class="text link bottom-spacing"
              >
                <a>{{ companyDetailsArray.images }}</a>
              </label>
            </span>
          </div>
          <div
            name="company-details-col-4"
            id="company-details-col-4"
            class="col-12 col-lg-3 col-sm-6"
          >
            <label
              name="company-details-col-4-label"
              id="company-details-col-4-label"
              class="label"
            >
              Size
            </label>
            <label
              name="company-details-col-3-text"
              id="company-details-col-3-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == ''"
            >
              Not Available
            </label>
            <label
              name="company-details-col-4-text"
              id="company-details-col-4-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == 1"
            >
              1 - 10 Employees
            </label>
            <label
              name="company-details-col-4-text"
              id="company-details-col-4-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == 2"
            >
              10-25 Employees
            </label>
            <label
              name="company-details-col-4-text"
              id="company-details-col-4-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == 3"
            >
              25-100 Employees
            </label>
            <label
              name="company-details-col-4-text"
              id="company-details-col-4-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == 4"
            >
              100-500 Employees
            </label>
            <label
              name="company-details-col-4-text"
              id="company-details-col-4-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == 5"
            >
              More than 500+ Employees
            </label>
          </div>
          <div
            name="company-details-col-5"
            id="company-details-col-5"
            class="col-12"
          >
            <label
              name="company-details-col-5-label"
              id="company-details-col-5-label"
              class="label"
            >
              Technology
            </label>
            <label
              name="company-details-col-5-text"
              id="company-details-col-5-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.technology?.length > 0"
              >{{ companyDetailsArray.technologiesName }}</label
            >
            <label
              name="company-details-col-5-text"
              id="company-details-col-5-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.technology?.length == 0"
              >Not Available</label
            >
          </div>
          <div
            name="company-details-col-6"
            id="company-details-col-6"
            class="col-12"
          >
            <label
              name="company-details-col-6-label"
              id="company-details-col-6-label"
              class="label"
            >
              Industries
            </label>
            <label
              name="company-details-col-6-text"
              id="company-details-col-6-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.industries?.length > 0"
            >
              {{ companyDetailsArray.industriesName }}
            </label>
            <label
              name="company-details-col-6-text"
              id="company-details-col-6-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.industries?.length == 0"
            >
              Not Available
            </label>
          </div>
          <div
            name="company-details-col-7"
            id="company-details-col-7"
            class="col-12"
          >
            <label
              name="company-details-col-7-label"
              id="company-details-col-7-label"
              class="label"
            >
              Services
            </label>
            <label
              name="company-details-col-7-text"
              id="company-details-col-7-text"
              class="text bottom-spacing"
              *ngIf="companyDetailsArray.services?.length > 0"
            >
              {{ companyDetailsArray.servicesName }}
            </label>
            <label
              name="company-details-col-7-text"
              id="company-details-col-7-text"
              class="text"
              *ngIf="companyDetailsArray.services?.length == 0"
            >
              Not Available
            </label>
          </div>

          <div name="executive-details-card-content-col-9" id="executive-details-card-content-col-9" class="col-12">
            <label name="executive-details-card-content-col-9-highlighted-title"
              id="executive-details-card-content-col-9-highlighted-title" class="highlighted-title">
              Finance
            </label>
          </div>
          <div name="company-details-col-6" id="company-details-col-6" class="col-12">
            <label name="company-details-col-6-label" id="company-details-col-6-label" class="label">
              Shipping Address
            </label>
            <label name="company-details-col-6-text" id="company-details-col-6-text" class="text"
              *ngIf="companyDetailsArray.vShippingAddress">
              {{ companyDetailsArray.vShippingAddress }}
            </label>
            <label name="company-details-col-6-text" id="company-details-col-6-text" class="text"
              *ngIf="!companyDetailsArray.vShippingAddress">
              Not Available
            </label>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="card-container" *ngIf="companyDetailsToggle == true">
    <div
      name="company-details-card-header"
      id="company-details-card-header"
      class="card-header"
    >
      <div
        name="company-details-title-section"
        id="company-details-title-section"
        class="title-section"
      >
        <h4
          name="company-details-card-title"
          id="company-details-card-title"
          class="card-title"
        >
          Company Details
        </h4>
      </div>
    </div>
    <div class="edit-profile">
      <div
        name="company-details-modal-body"
        id="company-details-modal-body"
        class="modal-body modal-scroll"
      >
        <form
          id="companyDetailsForm"
          name="companyDetailsForm"
          class="form"
          [formGroup]="companyDetailsForm"
          (ngSubmit)="UpdateCompanyDetails()"
        >
          <div class="row">
            <div class="col-12 col-md-6 field-box">
              <label
                for="vIntroDeck"
                name="company-details-label"
                id="company-details-intro-deck-label"
                class="custom-label"
                >Intro Deck
              </label>
              <mat-form-field
                hideRequiredMarker
                name="company-details-intro-deck-input"
                id="company-details-intro-deck-input"
                class="custom-input"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <div
                  name="company-details-intro-deck-file-container"
                  id="company-details-intro-deck-file-container"
                  class="file-container"
                >
                  <div
                    name="company-details-intro-deck-file-container"
                    id="company-details-intro-deck-file-container"
                    class="files"
                  >
                    <div
                      name="company-details-intro-deck-file-container"
                      id="company-details-intro-deck-file-container"
                      class="file-scroll-box"
                    >
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="introDeck">
                          {{ introDeck.name }}
                          <button
                            type="button"
                            name="button"
                            class="btn"
                            (click)="CancelMedia('', 3)"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input
                  name="company-details-intro-deck-vIntroDeck-1"
                  id="company-details-intro-deck-vIntroDeck-1"
                  matInput
                  readonly
                  [value]="introDeck ? ' ' : ''"
                  formControlName="vIntroDeck"
                  placeholder="Upload Intro Deck"
                />
                <input
                  matSuffix
                  type="file"
                  #uploadFile
                  name="company-details-intro-deck-vIntroDeck-2"
                  id="company-details-intro-deck-vIntroDeck-2"
                  class="file-upload-input"
                  (change)="FileBrowseHandler($event, 3)"
                  formControlName="vIntroDeck"
                  accept="application/pdf"
                />
                <button
                  name="company-details-intro-deck-upload-btn"
                  id="company-details-intro-deck-upload-btn"
                  class="upload-btn"
                  matSuffix
                >
                  <img
                    id="company-details-file-upload-icon"
                    [src]="imagePath + 'file-upload-icon.svg'"
                    alt="file upload"
                  />
                </button>
                <!-- <mat-hint>Max 10MB PDF required</mat-hint> -->
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <label
                for="vSize"
                name="company-details-size-label"
                id="company-details-size-label"
                class="custom-label"
                >Size
              </label>
              <mat-form-field
                hideRequiredMarker
                floatLabel="never"
                floatPlaceholder="never"
                class="custom-dropdown form"
                name="company-details-size-select"
                id="company-details-size-select"
              >
                <mat-select
                  id="vSize"
                  formControlName="vSize"
                  placeholder="Select size"
                >
                  <mat-option
                    *ngFor="let item of sizeListArray; let i = index"
                    [value]="item.id"
                    >{{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <label
                for="vVideo"
                name="company-details-video-label"
                id="company-details-video-label"
                class="custom-label"
                >Video</label
              >
              <mat-form-field
                hideRequiredMarker
                name="company-details-video-input"
                id="company-details-video-input"
                class="custom-input"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="video">
                          {{ video.name }}
                          <button
                            type="button"
                            name="button"
                            class="btn"
                            (click)="CancelMedia('', 2)"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input
                  name="company-details-video-input-vVideo-1"
                  id="company-details-video-input-vVideo-1"
                  matInput
                  readonly
                  [value]="video ? ' ' : ''"
                  formControlName="vVideo"
                  placeholder="Upload Video"
                />
                <input
                  matSuffix
                  type="file"
                  #uploadFile
                  name="company-details-video-input-vVideo-2"
                  id="company-details-video-input-vVideo-2"
                  class="file-upload-input"
                  (change)="FileBrowseHandler($event, 2)"
                  formControlName="vVideo"
                  accept="video/*"
                />
                <button
                  name="company-details-video-input-upload-btn"
                  id="company-details-video-input-upload-btn"
                  class="upload-btn"
                  matSuffix
                >
                  <img
                    [src]="imagePath + 'file-upload-icon.svg'"
                    alt="file upload"
                  />
                </button>
                <mat-hint>Max 50MB</mat-hint>
              </mat-form-field>
            </div>
            <div
              name="company-details-video-display"
              id="company-details-video-display"
              class="col-12"
              *ngIf="
                companyDetailsArray.video != null &&
                companyDetailsArray.video != ''
              "
            >
              <div
                name="company-details-video-class-1"
                id="company-details-video-class-1"
                class="image-display-container margin-class"
              >
                <div
                  name="company-details-video-name-1"
                  id="company-details-video-name-1"
                  class="image-name d-flex align-items-center"
                >
                  <label for="video">1)</label>
                  <div
                    class="image-thumbnail d-flex justify-content-center align-items-center"
                  >
                    <mat-icon>video_file</mat-icon>
                  </div>
                  <a
                    href="{{ companyDetailsArray?.video_url }}"
                    target="_blank"
                    >{{ companyDetailsArray.video }}</a
                  >
                </div>
                <button
                  type="button"
                  name="button"
                  (click)="
                    companyDetailImageThumbnail(companyDetailsArray.video, 2)
                  "
                >
                  <img
                    id="company-details-video-img-1"
                    alt="delete-image"
                    [src]="imagePath + 'delete_image.svg'"
                  />
                </button>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <label
                for="vImages"
                name="company-details-image-label"
                id="company-details-image-label"
                class="custom-label"
                >Images</label
              >
              <mat-form-field
                hideRequiredMarker
                name="company-details-image-input"
                id="company-details-image-input"
                class="custom-input"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box" *ngIf="mediaArray.length <= 5">
                      <perfect-scrollbar [config]="config">
                        <div
                          class="file"
                          *ngFor="let file of mediaArray; let i = index"
                        >
                          <label for="image">{{ i + 1 }})</label>
                          {{ file.name }}
                          <button
                            type="button"
                            name="button"
                            class="btn"
                            (click)="CancelMedia(i, 1)"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input
                  name="company-details-image-vImages-1"
                  id="company-details-image-vImages-1"
                  matInput
                  readonly
                  [value]="mediaArray.length > 0 ? ' ' : ''"
                  formControlName="vImages"
                  placeholder="Upload Image"
                />
                <input
                  matSuffix
                  type="file"
                  #uploadFile
                  name="company-details-image-vImages-2"
                  id="company-details-image-vImages-2"
                  class="file-upload-input"
                  (change)="FileBrowseHandler($event, 1)"
                  formControlName="vImages"
                  accept="image/*"
                  multiple
                />
                <button
                  name="company-details-image-upload-btn"
                  id="company-details-image-upload-btn"
                  class="upload-btn"
                  matSuffix
                >
                  <img
                    [src]="imagePath + 'file-upload-icon.svg'"
                    alt="file upload"
                  />
                </button>
                <label *ngIf="imagesUploadError">{{ imagesUploadError }}</label>
                <mat-hint>Max 5 images (1MB each)</mat-hint>
              </mat-form-field>
            </div>
            <div
              name="company-details-image-display"
              id="company-details-image-display"
              class="col-12"
              *ngIf="companyDetailsArray.images > 0"
            >
              <div
                name="company-details-image-class-1"
                id="company-details-image-class-1"
                class="image-display-container margin-class"
                *ngFor="let element of companyDetailsArray.imagesUrl"
              >
                <div
                  name="company-details-name-1"
                  id="company-details-name-1"
                  class="image-name"
                >
                  <img
                    class="image-thumbnail"
                    [src]="element.vMediaUrl"
                    alt="images"
                  />
                  <label>{{ element.vMediaName }}</label>
                </div>
                <button
                  type="button"
                  name="button"
                  (click)="companyDetailImageThumbnail(element.vMediaName, 1)"
                >
                  <img
                    id="company-details-img-1"
                    alt="delete-image"
                    [src]="imagePath + 'delete_image.svg'"
                  />
                </button>
              </div>
            </div>
            <div
              name="company-details-col-7"
              id="company-details-col-7"
              class="col-12 focus-correction-text"
            >
              <label
                for="vTechnology"
                name="company-details-col-7-label"
                id="company-details-col-7-label"
                class="custom-label"
                >Technologies
              </label>
              <mat-form-field
                hideRequiredMarker
                floatPlaceholder="never"
                floatLabel="never"
                name="company-details-col-7-select"
                id="company-details-col-7-select"
                class="custom-dropdown form"
              >
                <mat-chip-list
                  #chipList1
                  aria-label="vTechnology selection"
                  class="custom-multi-chip-dropdown"
                  formControlName="vTechnology"
                >
                  <mat-chip
                    *ngFor="let technology of technologies"
                    [removable]="true"
                    (removed)="OnTechnologyRemoved(technology)"
                  >
                    {{ technology.vTechnologyName }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-chip>
                  <input
                    matInput
                    #technologyInput
                    placeholder="Technologies"
                    (keyup)="_filterTechnology(technologyInput.value)"
                    [matAutocomplete]="auto1"
                    [matChipInputFor]="chipList1"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="technologyInput.value = ''"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #auto1="matAutocomplete"
                  [displayWith]="DisplayFnTechnology"
                  (optionSelected)="
                    AddTechnology($event.option.value);
                    technologyInput.value = ''
                  "
                >
                  <mat-option
                    *ngFor="let technology of filterTechnologies"
                    [value]="technology"
                  >
                    {{ technology.vTechnologyName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div
              name="company-details-industry-details-col-7"
              id="company-details-industry-details-col-7"
              class="col-12 focus-correction-text"
            >
              <label
                for="vIndustryDetails"
                name="company-details-industry-details-col-7-label"
                id="company-details-industry-details-col-7-label"
                class="custom-label"
                >Industries
              </label>
              <mat-form-field
                hideRequiredMarker
                floatPlaceholder="never"
                floatLabel="never"
                name="company-details-industry-details-col-7-select"
                id="company-details-industry-details-col-7-select"
                class="custom-dropdown form"
              >
                <mat-chip-list
                  #chipList2
                  aria-label="vIndustry selection"
                  class="custom-multi-chip-dropdown"
                  formControlName="vIndustryDetails"
                >
                  <mat-chip
                    *ngFor="let industry of industries"
                    [removable]="true"
                    (removed)="OnIndustryRemoved(industry)"
                  >
                    {{ industry.vIndustryName }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-chip>
                  <input
                    matInput
                    #industryInput
                    placeholder="Industries"
                    (keyup)="_filterIndustry(industryInput.value)"
                    [matAutocomplete]="auto2"
                    [matChipInputFor]="chipList2"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="industryInput.value = ''"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #auto2="matAutocomplete"
                  [displayWith]="DisplayFnIndustry"
                  (optionSelected)="
                    AddIndustry($event.option.value); industryInput.value = ''
                  "
                >
                  <mat-option
                    *ngFor="let industry of filterIndustries"
                    [value]="industry"
                  >
                    {{ industry.vIndustryName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div
              name="company-details-service-details-col-7"
              id="company-details-service-details-col-7"
              class="col-12 focus-correction-text"
            >
              <label
                for="vServiceDetails"
                name="company-details-service-details-col-7-label"
                id="company-details-service-details-col-7-label"
                class="custom-label"
                >Services
              </label>
              <mat-form-field
                hideRequiredMarker
                floatPlaceholder="never"
                floatLabel="never"
                name="company-details-service-details-col-7-select"
                id="company-details-service-details-col-7-select"
                class="custom-dropdown form"
              >
                <mat-chip-list
                  #chipList3
                  aria-label="vService selection"
                  class="custom-multi-chip-dropdown"
                  formControlName="vServiceDetails"
                >
                  <mat-chip
                    *ngFor="let service of services"
                    [removable]="true"
                    (removed)="OnServiceRemoved(service)"
                  >
                    {{ service.vServiceName }}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-chip>
                  <input
                    matInput
                    #serviceInput
                    placeholder="Services"
                    (keyup)="_filterService(serviceInput.value)"
                    [matAutocomplete]="auto3"
                    [matChipInputFor]="chipList3"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="serviceInput.value = ''"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #auto3="matAutocomplete"
                  [displayWith]="DisplayFnService"
                  (optionSelected)="
                    AddService($event.option.value); serviceInput.value = ''
                  "
                >
                  <mat-option
                    *ngFor="let service of filterServices"
                    [value]="service"
                  >
                    {{ service.vServiceName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div name="executive-details-col-4" id="executive-details-col-4" class="col-12">
            <label name="executive-details-col-4-bold-title" id="executive-details-col-4-bold-title"
              class="bold-title top-spacing">
              Finance
            </label>
          </div>
          <div name="primary-details-col-6" id="primary-details-col-6" class="col-12 col-md-12">
            <label for="vServiceDetails" name="company-details-service-details-col-7-label" id="company-details-service-details-col-7-label" class="custom-label">Shipping Address</label>
            <mat-form-field hideRequiredMarker class="custom-input" name="primary-details-col-6-input" id="primary-details-col-6-input" class="custom-input"
              floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="vAddress" formControlName="vShippingAddress"
                placeholder="Shipping Address ">
            </mat-form-field>
          </div>
          
        </form>
      </div>
      <div
        name="company-details-modal-footer"
        id="company-details-modal-footer"
        class="modal-footer"
      >
        <button
          type="button"
          name="company-details-btn-cancel"
          id="company-details-btn-cancel"
          class="blue-secondary-small-btn mx-3"
          (click)="
            this.companyDetailsToggle = false;
            this.companyDetailsForm.reset();
            this.companyDetailsSubmitted = false;
            resetAllChipArray()
          "
        >
          Cancel
        </button>
        <button
          type="submit"
          name="company-details-btn-submit"
          id="company-details-btn-submit"
          class="blue-primary-small-btn"
          form="companyDetailsForm"
        >
          Save
        </button>
      </div>
    </div>
    <div></div>
  </div>
</div>
