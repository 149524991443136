<div name="primary-details-box" id="primary-details-box" class="col-12">
  <div name="primary-details-card-container" id="primary-details-card-container" class="card-container" *ngIf="primaryDetailsToggle==false">
    <div name="primary-details-card-header" id="primary-details-card-header" class="card-header">
      <div name="primary-details-title-section" id="primary-details-title-section" class="title-section">
        <h4 name="primary-details-card-title" id="primary-details-card-title" class="card-title">Primary Details</h4>
        <button name="primary-details-card-edit-button" id="primary-details-card-edit-button" class="card-edit-button"
          type="button" (click)="togglePrimaryDetails(editClientPrimaryDetailsActionPermission)" [ngClass]="editClientPrimaryDetailsActionPermission ? '':'unauthorized-cursor'" placement="right auto" [ngbTooltip]="editClientPrimaryDetailsActionPermission ?  '':'Not Authorized'"><img class="card-edit-button-icon"
            [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button"></button>
      </div>
      <div name="primary-details-status-section" id="primary-details-status-section" class="status-section">
        <div name="primary-details-incomplete-status-box" id="primary-details-incomplete-status-box" class="status-box"
          *ngIf="!primaryDetailsCardCompleted">
          <img id="primary-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
            alt="status-icon">
          <label name="primary-details-incomplete-status-text" id="primary-details-incomplete-status-text"
            class="status-text incomplete">Pending</label>
        </div>
        <div name="primary-details-completed-status-box" id="primary-details-completed-status-box" class="status-box"
          *ngIf="primaryDetailsCardCompleted">
          <img id="primary-details-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'"
            alt="status-icon">
          <label name="primary-details-completed-status-text" id="primary-details-completed-status-text"
            class="status-text completed">Completed</label>
        </div>
      </div>
    </div>
    <div name="primary-details-card" id="primary-details-card" class="card"
      [ngClass]="primaryDetailsCardCompleted ? 'completed' : 'incomplete'">
      <div name="primary-details-card-top-section" id="primary-details-card-top-section" class="card-top-section">
        <div name="primary-details-card-profile-img-container" id="primary-details-card-profile-img-container"
          class="card-profile-img-container">
          <img *ngIf="oldImage" id="primary-details-card-profile-img" class="card-profile-img" src="{{oldImage}}"
            alt="Profile Image">
          <div name="green-circle-logo" id="green-circle-logo" class="image-box" *ngIf="!oldImage">
            <img class="profile-image" [src]="imagePath + 'person-black.svg'" alt="profile image">
          </div>
        </div>
      </div>
      <div name="primary-details-card-content" id="primary-details-card-content"
        class="card-content card-bottom-section">
        <div name="primary-details-card-content-row" id="primary-details-card-content-row" class="row">
          <div name="primary-details-card-content-col-1" id="primary-details-card-content-col-1"
            class="col-12 col-lg-3 col-sm-6">
            <label name="primary-details-card-content-col-1-label" id="primary-details-card-content-col-1-label"
              class="label">
              Name
            </label>
            <label name="primary-details-card-content-col-1-text" id="primary-details-card-content-col-1-text"
              class="text bottom-spacing">
              {{primaryDetailsForm!.value.vCompanyName}}
            </label>
          </div>
          <div name="primary-details-card-content-col-2" id="primary-details-card-content-col-2"
            class="col-12 col-lg-3 col-sm-6">
            <label name="primary-details-card-content-col-2-label" id="primary-details-card-content-col-2-label"
              class="label">
              Email Address
            </label>
            <label name="primary-details-card-content-col-2-text" id="primary-details-card-content-col-2-text"
              class="text bottom-spacing">
              {{primaryDetailsForm!.value.vEmailId}}
            </label>
          </div>
          <div name="primary-details-card-content-col-3" id="primary-details-card-content-col-3"
            class="col-12 col-lg-3 col-sm-6">
            <label name="primary-details-card-content-col-3-label" id="primary-details-card-content-col-3-label"
              class="label">
              Alternate Email Address
            </label>
            <label name="primary-details-card-content-col-3-text" id="primary-details-card-content-col-3-text"
              class="text bottom-spacing">
              <label *ngIf="primaryDetailsForm?.value.vAltEmailId">{{primaryDetailsForm!.value.vAltEmailId}}</label>
              <label name="alternate-email-not-available" id="alternate-email-not-available" class="text"
                *ngIf="!primaryDetailsForm?.value.vAltEmailId">Not Available</label>
            </label>
          </div>
          <div name="primary-details-card-content-col-4" id="primary-details-card-content-col-4"
            class="col-12 col-lg-3 col-sm-6">
            <label name="primary-details-card-content-col-4-label" id="primary-details-card-content-col-4-label"
              class="label">
              Website
            </label>
            <label name="primary-details-card-content-col-4-text" id="primary-details-card-content-col-4-text"
              class="text link bottom-spacing">
              <a target="_blank" name="primary-details-card-content-col-4-link"
                id="primary-details-card-content-col-4-link" class="d-flex"
                href="{{primaryDetailsForm!.value.vCompanyWebsiteUrl}}">
                <mat-icon class="fs-5 w-auto h-auto me-1">open_in_new</mat-icon>View Website
              </a>
            </label>
          </div>
          <div name="primary-details-card-content-col-5" id="primary-details-card-content-col-5"
            class="col-12 col-lg-3 col-sm-6">
            <label name="primary-details-card-content-col-5-label" id="primary-details-card-content-col-5-label"
              class="label">
              Phone Number
            </label>
            <label name="primary-details-card-content-col-5-text" id="primary-details-card-content-col-5-text"
              class="text bottom-spacing">
              {{ primaryDetailsForm!.value.vPhoneNo.dialCode }} - {{ primaryDetailsForm!.value.vPhoneNo.number }}
            </label>
          </div>
          <div name="primary-details-card-content-col-6" id="primary-details-card-content-col-6"
            class="col-12 col-lg-3 col-sm-6">
            <label name="primary-details-card-content-col-6-text" id="primary-details-card-content-col-6-text"
              class="label">
              Address
            </label>
            <p name="primary-details-card-content-col-6-text" id="primary-details-card-content-col-6-text"
              class="text bottom-spacing">
              {{primaryDetailsForm!.value.vAddress}}
            </p>
          </div>
          <div name="primary-details-card-content-founded-date" id="primary-details-card-content-founded-date"
            class="col-12 col-lg-3 col-sm-6">
            <label name="primary-details-card-content-founded-date-text"
              id="primary-details-card-content-founded-date-text" class="label">
              Founded Date
            </label>
            <label name="primary-details-card-content-founded-date-text"
              id="primary-details-card-content-founded-date-text" class="text bottom-spacing">
              <label
                *ngIf="primaryDetailsForm?.value.displayCompanyFoundedDate">{{primaryDetailsForm!.value.displayCompanyFoundedDate
                * 1000 | date:'MM/dd/YYYY'}}</label>
              <label *ngIf="!primaryDetailsForm.value.displayCompanyFoundedDate">Not Available</label>
            </label>
          </div>
          <div name="primary-details-card-content-col-7" id="primary-details-card-content-col-7" class="col-12">
            <label name="primary-details-card-content-col-7-label" id="primary-details-card-content-col-7-label"
              class="label">
              Bio
            </label>
            <div name="primary-details-card-content-col-7-text" id="primary-details-card-content-col-7-text"
              class="text bottom-spacing">
              <perfect-scrollbar>
                <p class="text with-scroll">{{primaryDetailsForm!.value.iCompanyBio}}</p>
              </perfect-scrollbar>
            </div>
          </div>
          <hr/>
          <div name="primary-details-card-content-col-8" id="primary-details-card-content-col-8" class="col-12">
            <label name="primary-details-card-content-col-8-label" id="primary-details-card-content-col-8-label"
              class="label">
              Social Media Links
            </label>
            <label name="social-links-not-available" id="social-links-not-available" class="text"
              *ngIf="!primaryDetailsForm?.value.txLinkedinUrl && !primaryDetailsForm?.value.txFacebookUrl && !primaryDetailsForm?.value.txTwitterUrl && !primaryDetailsForm?.value.txInstagramUrl">Not
              Available</label>
            <div *ngIf="primaryDetailsForm?.value.txLinkedinUrl"
              name="primary-details-card-content-col-8-social-container"
              id="primary-details-card-content-col-8-social-container" class="social-container">
              <a target="_blank" href="{{primaryDetailsForm!.value.txLinkedinUrl}}"
                name="primary-details-card-content-col-8-social-text-linkedin"
                id="primary-details-card-content-col-8-social-text-linkedin"
                class="text social-text-linkedin">LinkedIn</a>
              <div *ngIf="primaryDetailsForm?.value.txFacebookUrl"
                name="primary-details-card-content-col-8-social-container-divider-1"
                id="primary-details-card-content-col-8-social-container-divider-1" class="divider">|</div>
              <a *ngIf="primaryDetailsForm?.value.txFacebookUrl" target="_blank"
                href="{{primaryDetailsForm!.value.txFacebookUrl}}"
                name="primary-details-card-content-col-8-social-text-facebook"
                id="primary-details-card-content-col-8-social-text-facebook"
                class="text social-text-facebook">Facebook</a>
              <div *ngIf="primaryDetailsForm?.value.txTwitterUrl"
                name="primary-details-card-content-col-8-social-container-divider-2"
                id="primary-details-card-content-col-8-social-container-divider-2" class="divider">|</div>
              <a *ngIf="primaryDetailsForm?.value.txTwitterUrl" target="_blank"
                href="{{primaryDetailsForm!.value.txTwitterUrl}}"
                name="primary-details-card-content-col-8-social-text-twitter"
                id="primary-details-card-content-col-8-social-text-twitter" class="text social-text-twitter">Twitter</a>
              <div *ngIf="primaryDetailsForm?.value.txInstagramUrl"
                name="primary-details-card-content-col-8-social-container-divider-3"
                id="primary-details-card-content-col-8-social-container-divider-3" class="divider">|</div>
              <a *ngIf="primaryDetailsForm?.value.txInstagramUrl" target="_blank"
                href="{{primaryDetailsForm!.value.txInstagramUrl}}"
                name="primary-details-card-content-col-8-social-text-instagram"
                id="primary-details-card-content-col-8-social-text-instagram"
                class="text social-text-instagram">Instagram</a>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
  <div name="primary-details-card-container" id="primary-details-card-container" class="card-container" *ngIf="primaryDetailsToggle==true">
      <div name="primary-details-card-header" id="primary-details-card-header" class="card-header">
          <div name="primary-details-title-section" id="primary-details-title-section" class="title-section">
            <h4 name="primary-details-card-title" id="primary-details-card-title" class="card-title">Primary Details</h4>
          </div>
        </div>
      <div class="edit-profile">
          <div name="primary-details-modal-body" id="primary-details-modal-body" class="modal-body modal-scroll ">
            <form id="primaryDetailsForm" class="form" [formGroup]="primaryDetailsForm" (ngSubmit)="PrimaryDetailsProcess()">
              <div name="primary-details-profile-image-container" id="primary-details-profile-image-container"
                class="profile-image-container">
                <div name="primary-details-profile-image-block" id="primary-details-profile-image-block"
                  class="profile-image-block">
                  <input name="primary-details-image-input" id="primary-details-image-input" type="file" style="display:none"
                    #fileInputComapnyLogo accept="image/*" formControlName="newImage"
                    (change)="fileChangeEvent($event, Crop)" />
                  <img id="primary-details-image" class="profile-image-container-size" *ngIf="oldImage && !croppedImage"
                    src="{{oldImage}}" alt="profile picture">
                  <img id="primary-details-image" class="profile-image-container-size" *ngIf="croppedImage"
                    src="{{croppedImage}}" alt="profile picture">
                  <div name="green-circle-logo" id="green-circle-logo" class="image-box" *ngIf="!oldImage && !croppedImage">
                    <img class="profile-image" [src]="imagePath + 'person-black.svg'" alt="profile image">
                  </div>
                </div>
                <div name="primary-details-profile-image-upload" id="primary-details-profile-image-upload"
                  class="profile-image-upload">
                  <div class="dark-shadow">
                    <button type="button" name="primary-details-profile-upload-button"
                      id="primary-details-profile-upload-button" class="profile-upload-button"
                      (click)="fileInputComapnyLogo.click();">
                      <mat-icon class="upload-file-img"
                              >edit</mat-icon
                            >
                    </button>
                  </div>
                  <label name="primary-details-profile-upload-size-info" id="primary-details-profile-upload-size-info"
                    class="profile-upload-size-info">(Max 1mb Upload Size)<span class="text-danger">*</span></label>
                  <mat-error class="reduce-bottom-space" *ngIf="profileImageError">
                    <mat-error class="reduce-bottom-space" *ngIf="profileImageError">
                      Photo is required
                    </mat-error>
                  </mat-error>
                  <!-- <label name="primary-details-profile-upload-size-info" id="primary-details-profile-upload-size-info" class="profile-upload-size-info">(Max 1mb Upload Size)</label>
                  <span class="reduce-bottom-space profile-error" *ngIf="!oldImage && !profileImageFile">Photo is required</span> -->
                </div>
              </div>
              <div name="primary-details-row" id="primary-details-row" class="row">
                <div name="primary-details-col-1" id="primary-details-col-1" class="col-12 col-md-6 field-box">
                  <label for="vCompanyName" name="primary-details-col-1-label" id="primary-details-col-1-label"
                    class="custom-label">Company Name <span class="text-danger">*</span></label>
                    <mat-form-field class="custom-input" hideRequiredMarker name="primary-details-col-1-input" id="primary-details-col-1-input"
                    floatLabel="never">
                    <input type="text" matInput id="vCompanyName" formControlName="vCompanyName" placeholder="TopTech Inc."
                      required>
                    <mat-error name="primary-details-col-1-error-0" id="primary-details-col-1-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vCompanyName'].invalid">
                      <mat-error name="primary-details-col-1-error-1" id="primary-details-col-1-error-1"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vCompanyName'].errors!.required">
                        Company Name is required
                      </mat-error>
                      <mat-error name="primary-details-col-1-error-2" id="primary-details-col-1-error-2"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vCompanyName'].errors!.minlength">
                        Minimum 2 characters are required
                      </mat-error>
                      <mat-error name="primary-details-col-1-error-4" id="primary-details-col-1-error-4"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vCompanyName'].errors!.maxlength">
                        Must not exceed 46 characters
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-col-2" id="primary-details-col-2" class="col-12 col-md-6 field-box">
                  <label for="vEmailId" name="primary-details-col-2-label" id="primary-details-col-2-label"
                    class="custom-label">Email Address <span class="text-danger">*</span></label>
                  <mat-form-field class="custom-input" hideRequiredMarker name="primary-details-col-2-input" id="primary-details-col-2-input"
                    floatLabel="never" floatPlaceholder="never">
                    <input type="text" matInput id="vEmailId" formControlName="vEmailId" placeholder="toptech@inc.com">
                    <mat-error name="primary-details-col-3-error-0" id="primary-details-col-3-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vEmailId'].invalid">
                      <mat-error name="primary-details-col-3-error-2" id="primary-details-col-3-error-2"
                        class="reduce-bottom-space"
                        *ngIf="primaryDetailsForm.controls['vEmailId'].errors!.email || primaryDetailsForm.controls['vEmailId'].errors!.pattern">
                        Enter valid email
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-col-3" id="primary-details-col-3" class="col-12 col-md-6 field-box">
                  <label for="vAltEmailId" name="primary-details-col-3-label" id="primary-details-col-3-label"
                    class="custom-label">Alternate Email Address</label>
                  <mat-form-field class="custom-input" hideRequiredMarker name="primary-details-col-3-input" id="primary-details-col-3-input"
                   floatLabel="never" floatPlaceholder="never">
                    <input type="text" matInput id="vAltEmailId" formControlName="vAltEmailId"
                      placeholder="infotoptech@gmail.com">
                    <mat-error name="primary-details-col-3-error-0" id="primary-details-col-3-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vAltEmailId'].invalid">
                      <mat-error name="primary-details-col-3-error-2" id="primary-details-col-3-error-2"
                        class="reduce-bottom-space"
                        *ngIf="primaryDetailsForm.controls['vAltEmailId'].errors!.email || primaryDetailsForm.controls['vAltEmailId'].errors!.pattern">
                        Enter valid email
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-col-4" id="primary-details-col-4" class="col-12 col-md-6 field-box">
                  <label name="primary-details-col-4-label" id="primary-details-col-4-label" for="vCompanyWebsiteUrl"
                    class="custom-label">Website <span class="text-danger">*</span></label>
                  <mat-form-field class="custom-input" name="primary-details-col-4-input" id="primary-details-col-4-input" class="custom-input"
                    floatLabel="never" floatPlaceholder="never">
                    <input type="text" matInput id="vCompanyWebsiteUrl" formControlName="vCompanyWebsiteUrl"
                      placeholder="Ex. https://companywebsite.com" required>
                    <mat-error name="primary-details-col-4-error-0" id="primary-details-col-4-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vCompanyWebsiteUrl'].invalid">
                      <mat-error name="primary-details-col-4-error-1" id="primary-details-col-4-error-1"
                        class="reduce-bottom-space"
                        *ngIf="primaryDetailsForm.controls['vCompanyWebsiteUrl'].errors!.required">
                        Website URL is required
                      </mat-error>
                      <mat-error name="primary-details-col-4-error-2" id="primary-details-col-4-error-2"
                        class="reduce-bottom-space"
                        *ngIf="primaryDetailsForm.controls['vCompanyWebsiteUrl'].errors!.minlength">
                        Must have minimum 6 characters
                      </mat-error>
                      <mat-error name="primary-details-col-4-error-3" id="primary-details-col-4-error-3"
                        class="reduce-bottom-space"
                        *ngIf="primaryDetailsForm.controls['vCompanyWebsiteUrl'].errors!.maxlength">
                        Must not exceed 30 characters
                      </mat-error>
                      <mat-error name="primary-details-col-4-error-4" id="primary-details-col-4-error-4"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vCompanyWebsiteUrl'].errors!.pattern">
                        Invalid URL entered
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- <div name="primary-details-col-5" id="primary-details-col-5" class="col-12 col-md-6 position-relative field-box">
                  <label for="vPhoneNo" name="primary-details-col-5-label" id="primary-details-col-5-label"
                    class="custom-label">Phone Number <span class="text-danger">*</span></label>
                  <mat-form-field class="custom-input readonly" name="primary-details-col-2-input" id="primary-details-col-2-input"
                    floatLabel="never" floatPlaceholder="never">
                    <input type="text" matInput id="vPhoneNo" formControlName="vPhoneNo" placeholder="9033704449" readonly>
                  </mat-form-field>
                </div> -->
                <div name="primary-details-col-5" id="primary-details-col-5" class="col-12 col-md-6 position-relative mb-3 pb-3">
                  <label for="vPhoneNo" name="primary-details-col-5-label" id="primary-details-col-5-label" class="custom-label">Phone Number <span class="text-danger">*</span></label>
                  <ngx-intl-tel-input [cssClass]="'custom'" [ngClass]="{'tel-input-error': primaryDetailsForm.controls['vPhoneNo']?.invalid}" name="input-field-company-phone-number" id="input-field-company-phone-number" class="custom-country-phone-code-dropdown input" [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" (countryChange)="countryChange($event)"
                    [selectedCountryISO]="CountryISO.India" [maxLength]="maxLength"
                    [phoneValidation]="true" [separateDialCode]="false"
                    [numberFormat]="PhoneNumberFormat.National" id="vPhoneNo" name="vPhoneNo" formControlName="vPhoneNo"
                    (paste)="(false)" (copy)="false">
                  </ngx-intl-tel-input>
                    <mat-error name="employee-details-col-4-error-0" id="employee-details-col-4-error-0" class="position-absolute w-100 increase-z-index reduce-bottom-space p-0" *ngIf="primaryDetailsForm.controls['vPhoneNo']?.invalid">
                      <mat-error name="employee-details-col-4-error-1" id="employee-details-col-4-error-1" class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vPhoneNo'].errors!.required">
                        Phone Number is required
                      </mat-error>
                      <mat-error name="employee-details-col-4-error-1" id="employee-details-col-4-error-1" class="reduce-bottom-space" *ngIf="!primaryDetailsForm.controls['vPhoneNo'].errors!.required">
                        Kindly enter valid phone number
                      </mat-error>
                    </mat-error>
                </div>
                <div name="primary-details-col-6" id="primary-details-col-6" class="col-12 col-md-12">
                  <label for="vAddress" name="primary-details-col-6-label" id="primary-details-col-6-label"
                    class="custom-label">Address <span class="text-danger">*</span></label>
                  <mat-form-field hideRequiredMarker class="custom-input" name="primary-details-col-6-input" id="primary-details-col-6-input" class="custom-input"
                    floatLabel="never" floatPlaceholder="never">
                    <input type="text" matInput id="vAddress" formControlName="vAddress"
                      placeholder="Teksun Silicon Valley Office 1607 S Main St" required>
                    <mat-error name="primary-details-col-6-error-0" id="primary-details-col-6-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vAddress'].invalid">
                      <mat-error name="primary-details-col-6-error-1" id="primary-details-col-6-error-1"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vAddress'].errors!.required">
                        Address is required
                      </mat-error>
                      <mat-error name="primary-details-col-6-error-2" id="primary-details-col-6-error-2"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vAddress'].errors!.minlength">
                        Must have minimum 3 characters
                      </mat-error>
                      <mat-error name="primary-details-col-6-error-3" id="primary-details-col-6-error-3"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['vAddress'].errors!.maxlength">
                        Must not exceed 255 characters
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-col-7" id="primary-details-col-7" class="col-12 col-md-6 field-box focus-correction-text location-field">
                  <label for="iCountryId" name="primary-details-col-7-label" id="primary-details-col-7-label"
                    class="custom-label">Country <span class="text-danger">*</span></label>
                  <mat-form-field hideRequiredMarker name="primary-details-col-7-input" id="primary-details-col-7-input" class="custom-dropdown form" floatLabel="never" floatPlaceholder="never">
                    <input type="text" placeholder="Search Country" matInput formControlName="iCountryId" [matAutocomplete]="auto" required>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option *ngFor="let option of countryfilteredOptions | async" [value]="option.vName" (onSelectionChange)="GetStateListClick(option?.iCountryId,true)">
                        {{option?.vName}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error name="primary-details-col-7-error-0" id="primary-details-col-7-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iCountryId'].invalid">
                      <mat-error name="primary-details-col-7-error-1" id="primary-details-col-7-error-1"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iCountryId'].errors?.required">
                        Country is required
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-col-8" id="primary-details-col-8" class="col-12 col-md-6 focus-correction-text location-field">
                  <label for="iStateId" name="primary-details-col-8-label" id="primary-details-col-8-label"
                    class="custom-label">State/Province/Region <span class="text-danger">*</span></label>
                  <mat-form-field hideRequiredMarker name="primary-details-col-8-input" id="primary-details-col-8-input" class="custom-dropdown form" floatLabel="never" floatPlaceholder="never">
                    <input type="text" placeholder="Search State" matInput formControlName="iStateId" [matAutocomplete]="auto1" required>
                    <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                      <mat-option *ngFor="let option of statefilteredOptions | async" [value]="option.vName" (onSelectionChange)="GetCityListClick(option?.iStateId,true)">
                        {{option?.vName}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error name="primary-details-col-8-error-0" id="primary-details-col-8-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iStateId'].invalid">
                      <mat-error name="primary-details-col-8-error-1" id="primary-details-col-8-error-1"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iStateId'].errors?.required">
                        State is required
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-col-9" id="primary-details-col-9" class="col-12 col-md-6 focus-correction-text location-field">
                  <label for="iCityId" name="primary-details-col-9-label" id="primary-details-col-9-label"
                    class="custom-label">City/Town <span class="text-danger">*</span></label>
                  <mat-form-field hideRequiredMarker name="primary-details-col-9-input" id="primary-details-col-9-input" class="custom-dropdown form" floatLabel="never" floatPlaceholder="never">
                    <input type="text" placeholder="Search City" matInput formControlName="iCityId" [matAutocomplete]="auto2" required>
                    <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                      <mat-option *ngFor="let option of cityfilteredOptions | async" [value]="option.vName" (onSelectionChange)="AssignCity(option?.iCityId)">
                        {{option?.vName}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error name="primary-details-col-9-error-0" id="primary-details-col-9-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iCityId'].invalid">
                      <mat-error name="primary-details-col-9-error-1" id="primary-details-col-9-error-1"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iCityId'].errors?.required">
                        City is required
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-col-10" id="primary-details-col-10" class="col-12 col-md-6 field-box">
                  <label for="iZipCode" name="primary-details-col-10-label" id="primary-details-col-10-label"
                    class="custom-label">Zip Code <span class="text-danger">*</span></label>
                  <mat-form-field hideRequiredMarker name="primary-details-col-10-input" id="primary-details-col-10-input"
                    class="custom-input" floatLabel="never" floatPlaceholder="never">
                    <input type="text" matInput id="iZipCode" formControlName="iZipCode" placeholder="Ex. 235647" required>
                    <mat-error name="primary-details-col-10-error-0" id="primary-details-col-10-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iZipCode'].invalid">
                      <mat-error name="primary-details-col-10-error-1" id="primary-details-col-10-error-1"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iZipCode'].errors!.required">
                        Zipcode is required
                      </mat-error>
                      <mat-error name="primary-details-col-10-error-2" id="primary-details-col-10-error-2"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iZipCode'].errors!.pattern">
                        Only numbers and characters are allowed
                      </mat-error>
                      <mat-error name="primary-details-col-10-error-3" id="primary-details-col-10-error-3"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iZipCode'].errors!.minlength">
                        Must have minimum 5 characters
                      </mat-error>
                      <mat-error name="primary-details-col-10-error-4" id="primary-details-col-10-error-4"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iZipCode'].errors!.maxlength">
                        Must not exceed 10 characters
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-founded-date" id="primary-details-founded-date" class="col-12 col-md-6">
                  <label for="iCompanyFoundedDate" name="primary-details-founded-date-label"
                    id="primary-details-founded-date-label" class="custom-label">Founded Date <span
                      class="text-danger">*</span></label>
                  <mat-form-field hideRequiredMarker name="primary-details-founded-date-input"
                    id="primary-details-founded-date-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
                    <input matInput [matDatepicker]="picker" id="iCompanyFoundedDate" [max]="today"
                      formControlName="iCompanyFoundedDate" placeholder="Choose Date" readonly required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error name="primary-details-founded-date-error-0" id="primary-details-founded-date-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['iCompanyFoundedDate'].invalid">
                      <mat-error name="primary-details-col-10-error-1" id="primary-details-founded-date-error-1"
                        class="reduce-bottom-space"
                        *ngIf="primaryDetailsForm.controls['iCompanyFoundedDate'].errors!.required">
                        Founded Date is required
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-col-11" id="primary-details-col-11" class="col-12">
                  <label for="iCompanyBio" name="primary-details-col-11-label" id="primary-details-col-11-label"
                    class="custom-label">Bio <span class="text-danger">*</span></label>
                  <mat-form-field name="mat-form-field-company-bio" id="mat-form-field-company-bio"
                    class="custom-input textarea" floatLabel="never" floatPlaceholder="never">
                    <textarea matInput rows="15" name="iCompanyBio" id="iCompanyBio"
                      formControlName="iCompanyBio" placeholder="Type here" #iCompanyBio></textarea>
                    <span name="bio-character-count-1" id="bio-character-count-1" class="bio-character-counter"><span
                        name="bio-character-count-2" id="bio-character-count-2" class="me-1"
                        [ngClass]="{'exceeded': iCompanyBio.value.length < 150}">{{iCompanyBio.value.length}}</span> /
                      150</span>
                    <mat-error name="mat-error-company-bio" id="mat-error-company-bio"
                      *ngIf="primaryDetailsForm.controls['iCompanyBio'].invalid">
                      <mat-error name="mat-error-company-bio-text-1" id="mat-error-company-bio-text-1"
                        *ngIf="primaryDetailsForm.controls['iCompanyBio'].errors!.required">
                        Bio is required
                      </mat-error>
                      <mat-error name="mat-error-company-bio-text-1" id="mat-error-company-bio-text-1"
                        *ngIf="primaryDetailsForm.controls['iCompanyBio'].errors!.minlength">
                        Must have minimum 150 characters
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-social-media" id="primary-details-social-media" class="col-12">
                  <label name="primary-details-social-media-bold-title" id="primary-details-social-media-bold-title"
                    class="social-media-title">
                    Social Media Links
                  </label>
                </div>
                <div name="primary-details-col-12" id="primary-details-col-12" class="col-12 col-md-6 field-box">
                  <label for="txLinkedinUrl" name="primary-details-col-12-label" id="primary-details-col-12-label"
                    class="custom-label">LinkedIn <span class="text-danger">*</span></label>
                  <mat-form-field name="primary-details-col-12-input" id="primary-details-col-12-input" class="custom-input"
                    floatLabel="never" floatPlaceholder="never">
                    <input type="text" matInput id="txLinkedinUrl" formControlName="txLinkedinUrl"
                      placeholder="https://www.linkedin.com/signup" required>
                    <mat-error name="primary-details-col-12-error-0" id="primary-details-col-12-error-0"
                      class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['txLinkedinUrl'].invalid">
                      <mat-error name="primary-details-col-12-error-1" id="primary-details-col-12-error-1"
                        class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['txLinkedinUrl'].errors!.required">
                        Linkedin is required
                      </mat-error>
                      <!-- <mat-error name="primary-details-col-12-error-1" id="primary-details-col-12-error-1" class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['txLinkedinUrl'].errors!.pattern">
                        Enter Valid URL
                      </mat-error> -->
                    </mat-error>
                  </mat-form-field>
                </div>
                <div name="primary-details-col-13" id="primary-details-col-13" class="col-13 col-md-6 field-box">
                  <label for="txFacebookUrl" name="primary-details-col-13-label" id="primary-details-col-13-label"
                    class="custom-label">Facebook</label>
                  <mat-form-field name="primary-details-col-13-input" id="primary-details-col-13-input" class="custom-input"
                    floatLabel="never" floatPlaceholder="never">
                    <input type="text" matInput id="txFacebookUrl" formControlName="txFacebookUrl"
                      placeholder="https://www.facebook.com">
                    <!-- <mat-error name="primary-details-col-13-error-0" id="primary-details-col-13-error-0" class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['txFacebookUrl'].invalid">
                      <mat-error name="primary-details-col-13-error-1" id="primary-details-col-13-error-1" class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['txFacebookUrl'].errors!.pattern">
                        Enter Valid URL
                      </mat-error>
                    </mat-error> -->
                  </mat-form-field>
                </div>
                <div name="primary-details-col-14" id="primary-details-col-14" class="col-14 col-md-6 field-box">
                  <label for="txTwitterUrl" name="primary-details-col-14-label" id="primary-details-col-14-label"
                    class="custom-label">Twitter</label>
                  <mat-form-field name="primary-details-col-14-input" id="primary-details-col-14-input" class="custom-input"
                    floatLabel="never" floatPlaceholder="never">
                    <input type="text" matInput id="txTwitterUrl" formControlName="txTwitterUrl"
                      placeholder="https://twitter.com/login">
                    <!-- <mat-error name="primary-details-col-14-error-0" id="primary-details-col-14-error-0" class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['txTwitterUrl'].invalid">
                      <mat-error name="primary-details-col-14-error-1" id="primary-details-col-14-error-1" class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['txTwitterUrl'].errors!.pattern">
                        Enter Valid URL
                      </mat-error>
                    </mat-error> -->
                  </mat-form-field>
                </div>
                <div name="primary-details-col-15" id="primary-details-col-15" class="col-15 col-md-6 field-box">
                  <label for="txInstagramUrl" name="primary-details-col-15-label" id="primary-details-col-15-label"
                    class="custom-label">Instagram</label>
                  <mat-form-field name="primary-details-col-15-input" id="primary-details-col-15-input" class="custom-input"
                    floatLabel="never" floatPlaceholder="never">
                    <input type="text" matInput id="txInstagramUrl" formControlName="txInstagramUrl"
                      placeholder="https://www.instagram.com/">
                    <!-- <mat-error name="primary-details-col-15-error-0" id="primary-details-col-15-error-0" class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['txInstagramUrl'].invalid">
                      <mat-error name="primary-details-col-15-error-1" id="primary-details-col-15-error-1" class="reduce-bottom-space" *ngIf="primaryDetailsForm.controls['txInstagramUrl'].errors!.pattern">
                        Enter Valid URL
                      </mat-error>
                    </mat-error> -->
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div> 
        
        <div name="primary-details-modal-footer" id="primary-details-modal-footer" class="modal-footer">
          <button type="button" name="primary-details-modal-footer-btn-cancel" id="primary-details-modal-footer-btn-cancel"
            class="blue-secondary-small-btn mx-3"
            (click)="this.primaryDetailsForm.reset();this.primaryDetailsToggle=false;this.primaryDetailsSubmitted = false; GetPrimaryDetails();this.profileImageError = false; croppedImage=''">Cancel</button>
          <button type="submit" name="primary-details-modal-footer-btn-submit" id="primary-details-modal-footer-btn-submit"
            class="blue-primary-small-btn" form="primaryDetailsForm">Save</button>
        </div>
    </div>
    </div>
</div>

<ng-template #Crop let-c="close" let-d="dismiss">
  <div name="crop-modal-header" id="crop-modal-header" class="modal-header">
    <h1 name="crop-modal-title" id="crop-modal-title" class="modal-title">Crop Image</h1>
  </div>
  <div class="">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
      [roundCropper]="true" format="png" (imageCropped)="ImageCropped($event)" (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
    </image-cropper>
  </div>
  <div name="crop-modal-footer" id="crop-modal-footer" class="modal-footer">
    <button type="submit" name="crop-modal-footer-btn-submit" id="crop-modal-footer-btn-submit" class="primary-small-btn"
      (click)="d('cross click');this.profileImageError=false">Crop</button>
    <button type="button" name="crop-modal-footer-btn-cancel" id="crop-modal-footer-btn-cancel"
      class="secondary-small-btn mx-3" (click)="d('cross click'); CancelCropModel()">Cancel</button>
  </div>
</ng-template>