import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EventEmitterService {

    EmpolyeeData = new EventEmitter();
    modelMessageData = new EventEmitter();
    AuthenticateUser = new EventEmitter();
    viewEmpPublish_Sub = new EventEmitter();
    viewPartnerPublish_Sub = new EventEmitter();
    viewClientPublish_Sub = new EventEmitter();
    GetProfileStatusSubMethod= new EventEmitter();
    GetProfileStatusAPISubMethod = new EventEmitter();
    MenuListSubMethod = new EventEmitter();
    GetProfileDetailsAPISubMethod = new EventEmitter();
    GetDynamicDoucmentsSubMethod = new EventEmitter();
    //subsVar: Subscription;

    constructor() { }

    EmitEmpolyeeData(data:any) {
        this.EmpolyeeData.emit(data);
    }

    modelMessage(data:any) {
        this.modelMessageData.emit(data);
    }

    callAuthenticateUser() {
        this.AuthenticateUser.emit();
    }

    viewEmpPublish_Pub(iUserId:any) {
        this.viewEmpPublish_Sub.emit(iUserId);
    }

    viewPartnerPublish_Pub(iUserId:any) {
        this.viewPartnerPublish_Sub.emit(iUserId);
    }

    viewClientPublish_Pub(iUserId:any) {
        this.viewClientPublish_Sub.emit(iUserId);
    }

    GetProfileStatusCallMethod(data:any){
        this.GetProfileStatusSubMethod.emit(data);
    }
    
    MenuListCallMethod(){
        this.MenuListSubMethod.emit();
    }

    GetProfileDetailsAPICallMethod() {
        this.GetProfileDetailsAPISubMethod.emit();
    }

    GetProfileStatusAPICallMethod() {
        this.GetProfileStatusAPISubMethod.emit();
    }

    GetDynamicDoucmentsCallMethod() {
        this.GetDynamicDoucmentsSubMethod.emit();
    }
}
