import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewTalentService {

  constructor(private http: HttpClient) { }


  //profile service

  GetProfileStatus(talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/employee-profile-status?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  PublishProfile(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/publish-profile?iUserId=${talentUserId}`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  // GetSkills(data: any) {
  //   const promise = new Promise((resolve, reject) => {
  //     const apiURL = environment.baseUrl + '/common/v1/skillsList?pageIndex=' + data.pageNumber + '&perPage=' + data.perPage;
  //     this.http
  //       .get(apiURL)
  //       .toPromise()
  //       .then((res) => {
  //         // Success
  //         resolve(res);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(err);
  //       });
  //   });
  //   return promise;
  // }

  GetPastClients() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + "/employee/v1/pastClients";
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetContracts() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + "/employee/v1/contractList";
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UpdateProfile(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/employeeProfile?iUserId=${talentUserId}`;
      const formData: FormData = new FormData();
      formData.append("image", data.vProfilePhoto);
      formData.append("experience", data.experience);
      formData.append("bio", data.bio);
      formData.append("skills", data.skills);
      formData.append("ssnAadharLink", data.ssnAadharLink);
      formData.append("vFirstName", data.vFirstName);
      formData.append("vLastName", data.vLastName);
      formData.append("iCountry", data.iCountry);
      formData.append("iState", data.iState);
      formData.append("iCity", data.iCity);
      formData.append("iPrimarySkillId", data.iPrimarySkillId);
      formData.append("iLanguageId", data.iLanguage);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetClientReview() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + "/employee/v1/review-get-list";
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetCountryList() {
    return this.http.get(`${environment.baseUrl}/common/v1/countryList?perPage=-1&pageNumber=-1`);
  }

  GetStateList(countryId: any) {
    return this.http.get(`${environment.baseUrl}/common/v1/stateList?perPage=-1&pageNumber=-1&iCountryId=` + countryId);
  }

  GetCityList(stateId: any) {
    return this.http.get(`${environment.baseUrl}/common/v1/cityList?perPage=-1&pageNumber=-1&iStateId=` + stateId);
  }

  GetPrimarySkillList() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + "/common/v1/list/primary-skill";
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetSecondarySkillList(iPrimarySkillId: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + `/common/v1/list/secondary-skill`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UploadEmployeeVideo(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/employeeVideo?iUserId=${talentUserId}`;
      const formData: FormData = new FormData();
      formData.append("vSkillVideo", data.vSkillVideo);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetLangaugeList() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + "/common/v1/list/languages";
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetTechnologyList() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/list/technology`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetPortfolio(talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/employee/v1/portfolio?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetEducation(talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/education?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetProfessionalDetails(talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/professional-details?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetEmployeeProfile(talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/employee/v1/view-profile?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
