import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { FormGroup, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationService } from "../../../../config/notification.service";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { StaticMsg } from "src/app/config/en";
import { CompanyDetailsService } from "./company-details.service";
import { environment } from "../../../../../environments/environment";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Permission } from "../../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../../utils/services/user-permission.service";
import { ViewClientService } from "../view-client.service";

@Component({
  selector: "client-company-details",
  templateUrl: "./company-details.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class ClientCompanyDetailsComponent implements OnInit {
  @Input() clientUserId:any;
  companyDetailsToggle:boolean=false;

  
  editClientCompanyDetailsActionPermission: boolean = false;

  imagePath = environment.image_path;

  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  companyDetailsForm!: FormGroup;
  companyDetailsSubmitted: any = false;

  public sizeListArray: any = [
    { id: 1, name: "1 - 10" },
    { id: 2, name: "10 - 25" },
    { id: 3, name: "25 - 100" },
    { id: 4, name: "100 - 500" },
    { id: 5, name: "More than 500+" },
  ];

  industryListArray: any = [];
  serviceListArray: any = [];
  technologyListArray: any = [];
  companyDetailsArray: any = [];
  introDeck: any;
  video: any;
  mediaArray: any = [];
  imagesUploadError: any;
  count: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild("uploadFile") fileInput: any;
  fileAttr: any;
  filterTechnologies: any;
  technologies: any=[];
  filterIndustries: any;
  industries: any=[];
  filterServices: any;
  services: any=[];

  constructor(config: NgbModalConfig, private userPermissionService: UserPermissionService, private modalService: NgbModal, private profileService: ViewClientService, private companyDetailsService: CompanyDetailsService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService) {
    const EditClientCompanyDetailsAction: Permission =  Permission.EditClientCompanyDetailsAction;
    
    if (this.userPermissionService.isAuthorizedFor(EditClientCompanyDetailsAction)) {
      this.editClientCompanyDetailsActionPermission = true;
    }

    
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.companyDetailsForm = new FormGroup({
      vIntroDeck: new FormControl("", []),
      vVideo: new FormControl("", []),
      vImages: new FormControl("", []),
      vSize: new FormControl(""),
      vTechnology: new FormControl("", []),
      vIndustryDetails: new FormControl("", []),
      vServiceDetails: new FormControl("", []),
      vShippingAddress: new FormControl("", []),
    });

    this.GetCompanyDetails();
    this.GetIndustryList();
    this.GetServiceList();
    this.GetTechnologyList();
  }



  MultiSelectTechnologyCompareFn(item1: any, item2: any): boolean {
    return item1 && item2 ? item1.iMasterTechnologyId === item2.iMasterTechnologyId : item1 === item2;
  }

  MultiSelectIndustryCompareFn(item1: any, item2: any): boolean {
    return item1 && item2 ? item1.iMasterIndustryId === item2.iMasterIndustryId : item1 === item2;
  }

  MultiSelectServiceCompareFn(item1: any, item2: any): boolean {
    return item1 && item2 ? item1.iMasterServiceId === item2.iMasterServiceId : item1 === item2;
  }

  FileBrowseHandler(event: any, type: any) {
    if (type == 1) {
      if (this.companyDetailsArray?.imagesUrl?.length < 5) {
        if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg") {
          let fileSize = event["target"]["files"][0]["size"] / 1024;
          if (fileSize > 4 * 1024) {
            this.notifyService.showError(StaticMsg.FileLargerError, "");
            return;
          } else {
            if (this.mediaArray.length == 5 || event.target.files.length > 5) {
              this.notifyService.showError(StaticMsg.ImageOutOfRangeError, "");
              return;
            }
            if (this.mediaArray.length < 5) {
              for (let i = 0; i < event.target.files.length; i++) {
                if (this.count < 5) {
                  this.mediaArray.push(event.target.files[i]);
                  this.count++;
                } else {
                  this.notifyService.showError(StaticMsg.ImageLimitError, "");
                }
              }
            }
          }
        } else {
          this.notifyService.showError(StaticMsg.NotValidImageTypeError, "");
        }
      } else {
        this.notifyService.showError(StaticMsg.ImageLimitError, "");
      }
    }
    if (type == 2) {
      if (event.target.files[0].type == "video/mp4") {
        let fileSize = event["target"]["files"][0]["size"] / 1024;
        if (fileSize > 1024 * 50) {
          this.notifyService.showError(StaticMsg.VideoLargerThan50MbError, "");
          if (type == 2) {
            this.video = "";
            this.companyDetailsForm.controls.vVideo.patchValue("");
          }
          return;
        } else {
          this.video = event.target.files[0];
        }
      } else {
        this.notifyService.showError(StaticMsg.NotValidVideoTypeError, "");
      }
    }
    if (type == 3) {
      if (event.target.files[0].type == "application/pdf") {
        let fileSize = event["target"]["files"][0]["size"] / 1024;
        if (fileSize > 10 * 1024) {
          this.notifyService.showError(StaticMsg.IntroDeckFileLargerError, "");
          if (type == 3) {
            this.introDeck = "";
            this.companyDetailsForm.controls.vIntroDeck.patchValue("");
          }
          return;
        } else {
          this.introDeck = event.target.files[0];
        }
      } else {
        this.notifyService.showError(StaticMsg.NotPdfFileError, "");
      }
    }
  }

  CancelMedia(index: any, type: any) {
    if (type == 3) {
      this.introDeck = "";
      this.companyDetailsForm.controls.vIntroDeck.patchValue("");
    }

    if (type == 2) {
      this.video = "";
      this.companyDetailsForm.controls.vVideo.patchValue("");
    }

    if (type == 1) {
      this.mediaArray.splice(index, 1);
      if (this.mediaArray.length < 1) {
        this.companyDetailsForm.controls.vImages.patchValue("");
      }
      this.count = this.companyDetailsArray.imagesUrl.length;
    }
  }

  GetIndustryList() {
    this.profileService.GetIndustryList().then((result: any) => {
      if (result.code == 200) {
        this.industryListArray = result["data"];
        this.filterIndustries = result["data"];
      }
    });
  }

  GetServiceList() {
    this.profileService.GetServiceList().then((result: any) => {
      if (result.code == 200) {
        this.serviceListArray = result["data"];
        this.filterServices = result["data"];
      }
    });
  }

  GetTechnologyList() {
    this.profileService.GetTechnologyList().then((result: any) => {
      if (result.code == 200) {
        this.technologyListArray = result["data"];
        this.filterTechnologies = result["data"];
      }
    });
  }

  OnIndustryRemoved(industry: string) {
    const industries = this.companyDetailsForm.value.vIndustryDetails as string[];
    this.removeFirst(industries, industry);
    if (this.companyDetailsForm.controls['vIndustryDetails'].value.length == 0) {
      this.companyDetailsForm.controls.vIndustryDetails.setValue(industries); // To trigger change detection
    }
  }

  OnServiceRemoved(service: string) {
    const services = this.companyDetailsForm.value.vServiceDetails as string[];
    this.removeFirst(services, service);
    if (this.companyDetailsForm.controls['vServiceDetails'].value.length == 0) {
      this.companyDetailsForm.controls.vServiceDetails.setValue(services); // To trigger change detection
    }
  }

  OnTechnologyRemoved(technology: string) {
    const technologies = this.companyDetailsForm.value.vTechnology as string[];
    this.removeFirst(technologies, technology);
    if (this.companyDetailsForm.controls['vTechnology'].value.length == 0) {
      this.companyDetailsForm.controls.vTechnology.setValue(technologies); // To trigger change detection
    }
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  GetCompanyDetails() {
    this.companyDetailsService.GetCompanyDetails(this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        let industriesName: any = [];
        let servicesName: any = [];
        let technologiesName: any = [];
        result["data"]["industries"].forEach((element: any) => {
          industriesName.push(element.vIndustryName);
        });
        result["data"]["services"].forEach((element: any) => {
          servicesName.push(element.vServiceName);
        });
        if (result["data"]["technology"].length > 0) {
          result["data"]["technology"].forEach((element: any) => {
            technologiesName.push(element.vTechnologyName);
          });
        }
        result["data"]["industriesName"] = industriesName.toString().replace(/,/g, ", ");
        result["data"]["servicesName"] = servicesName.toString().replace(/,/g, ", ");
        result["data"]["technologiesName"] = technologiesName.length > 0 ? technologiesName.toString().replace(/,/g, ", ") : result["data"]["technology"];

        this.technologies=result["data"]["technology"];
        this.industries=result["data"]["industries"];
        this.services=result["data"]["services"];
        this.companyDetailsArray = result["data"];
        this.count = this.companyDetailsArray.imagesUrl.length;
      }
    });
  }

  UpdateCompanyDetails() {
    this.companyDetailsSubmitted = true;
    if (this.companyDetailsForm.invalid) {
      return;
    }
    let industryIds: any = [];
    let serviceIds: any = [];
    let technologyIds: any = [];

    // console.log(this.companyDetailsForm.value.vTechnology,"this.companyDetailsForm.value.vTechnology");
    this.companyDetailsForm.value.vTechnology.forEach((element: any) => {
      technologyIds.push(element.iMasterTechnologyId);
    });

    this.companyDetailsForm.value.vIndustryDetails.forEach((element: any) => {
      industryIds.push(element.iMasterIndustryId);
    });

    this.companyDetailsForm.value.vServiceDetails.forEach((element: any) => {
      serviceIds.push(element.iMasterServiceId);
    });
    let reqData = {
      vIntroDeck: this.introDeck,
      vVideo: this.video,
      media: this.mediaArray[0],
      media1: this.mediaArray[1],
      media2: this.mediaArray[2],
      media3: this.mediaArray[3],
      media4: this.mediaArray[4],
      vSize: this.companyDetailsForm.value.vSize,
      vTechnology: technologyIds,
      vIndustryDetails: industryIds,
      vServiceDetails: serviceIds,
      vShippingAddress: this.companyDetailsForm.value.vShippingAddress,
    };
    this.companyDetailsService.UpdateCompanyDetails(reqData,this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetCompanyDetails();
          this.notifyService.showSuccess(result.message, "");
          this.eventEmitterService.GetProfileStatusAPICallMethod();
          this.companyDetailsToggle=false;
      } else {
        this.companyDetailsToggle=false;
      }
    });
  }

  companyDetailImageThumbnail(url: any, mediaType: any) {
    let reqObj = {
      url: url,
      mediaType: mediaType,
    };
    this.companyDetailsService.DeleteCompanyDetailImages(reqObj,this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        if (mediaType === 2) {
          this.notifyService.showSuccess("Video deleted successfully!", "");
        } else {
          this.notifyService.showSuccess("Image deleted successfully!", "");
        }
        this.GetCompanyDetails();
      }
    });
  }

  //Dropdown with search

  resetAllChipArray(){
    this.technologies=[];
    this.industries=[];
    this.GetCompanyDetails();
  }
  
  // Add Technology
  AddTechnology(technology: any): void {
    if (technology) {
      let index = this.technologies.findIndex((x: any) => x.iMasterTechnologyId === technology.iMasterTechnologyId);
      if (index > -1) {
        return;
      } else {
        const vTechnologyName = technology.vTechnologyName;
        const iMasterTechnologyId = technology.iMasterTechnologyId;
        if (vTechnologyName) {
            this.technologies.push({ vTechnologyName: vTechnologyName.trim(), iMasterTechnologyId: iMasterTechnologyId });
        }
        this.companyDetailsForm.controls["vTechnology"].setValue(this.technologies);
      }
      this.filterTechnologies = this.technologyListArray;
    }
  }

  DisplayFnTechnology(technology: any): string {
    return technology && technology.vTechnologyName ? technology.vTechnologyName : "";
  }

  _filterTechnology(name: string): any {
    // console.log(name);

    const filterValue = name?.toLowerCase();
    this.filterTechnologies = this.technologyListArray;

    this.filterTechnologies = this.filterTechnologies.filter((technology: { vTechnologyName: string }) => technology.vTechnologyName.toLowerCase().startsWith(filterValue));
  }

  // Add Industry
  AddIndustry(industry: any): void {
    if (industry) {
      let index = this.industries.findIndex((x: any) => x.iMasterIndustryId === industry.iMasterIndustryId);
      if (index > -1) {
        return;
      } else {
        const vIndustryName = industry.vIndustryName;
        const iMasterIndustryId = industry.iMasterIndustryId;
        if (vIndustryName) {
          this.industries.push({ vIndustryName: vIndustryName.trim(), iMasterIndustryId: iMasterIndustryId });
        }
        this.companyDetailsForm.controls["vIndustryDetails"].setValue(this.industries);
      }
      this.filterIndustries = this.industryListArray;
    }
  }

  DisplayFnIndustry(industry: any): string {
    return industry && industry.vIndustryName ? industry.vIndustryName : "";
  }

  _filterIndustry(name: string): any {
    // console.log(name);

    const filterValue = name?.toLowerCase();
    this.filterIndustries = this.industryListArray;

    this.filterIndustries = this.filterIndustries.filter((industry: { vIndustryName: string }) => industry.vIndustryName.toLowerCase().startsWith(filterValue));
  }

    // Add Service
    AddService(service: any): void {
      if (service) {
        let index = this.services.findIndex((x: any) => x.iMasterServiceId === service.iMasterServiceId);
        if (index > -1) {
          return;
        } else {
          const vServiceName = service.vServiceName;
          const iMasterServiceId = service.iMasterServiceId;
          if (vServiceName) {
            this.services.push({ vServiceName: vServiceName.trim(), iMasterServiceId: iMasterServiceId });
          }
          this.companyDetailsForm.controls["vServiceDetails"].setValue(this.services);
        }
        this.filterServices = this.serviceListArray;
      }
    }
  
    DisplayFnService(service: any): string {
      return service && service.vServiceName ? service.vServiceName : "";
    }
  
    _filterService(name: string): any {
      // console.log(name);
  
      const filterValue = name?.toLowerCase();
      this.filterServices = this.serviceListArray;
  
      this.filterServices = this.filterServices.filter((service: { vServiceName: string }) => service.vServiceName.toLowerCase().startsWith(filterValue));
    }

    toggleCompanyDetails(hasPermission:boolean){
      if(hasPermission){
        this.companyDetailsForm.reset();
        this.companyDetailsForm.patchValue({
          vSize: this.companyDetailsArray["size"],
          vTechnology: this.companyDetailsArray["technology"],
          vIndustryDetails: this.companyDetailsArray["industries"],
          vServiceDetails: this.companyDetailsArray["services"],
          vShippingAddress: this.companyDetailsArray["vShippingAddress"],
        });
        this.introDeck = "";
        this.video = "";
        this.mediaArray = [];
        this.count = this.companyDetailsArray?.imagesUrl?.length;
        this.companyDetailsToggle=true;
    
      } else {
        this.notifyService.showError("Not Authorized!", "");
      }
    }
}
