import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationService } from "../../../../config/notification.service";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { CertificationsService } from "./certifications.service";
import { StaticMsg } from "src/app/config/en";
import { environment } from "../../../../../environments/environment";
import { Permission } from "../../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../../utils/services/user-permission.service";

@Component({
  selector: "client-certifications",
  templateUrl: "./certifications.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class ClientCertificationsComponent implements OnInit {
  @Input() clientUserId:any;
  certificationsToggle:boolean=false;
  
  editClientCertificationsActionPermission: boolean = false;

  imagePath = environment.image_path;

  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  certificationForm!: FormGroup;
  isoCertificationForm!: FormGroup;
  certificationSubmitted: any = false;
  @ViewChild("uploadFile") fileInput: any;
  ISOList: any = [];
  CMMIList: any = [];
  isoCertificateList: any = [];
  cmmiCertificates: any = [];
  otherCertificatesList: any;
  isoCertificate: any;
  iecCertificate: any;
  cmmiCertificate: any;
  trademarkCertifiacate: any;
  reward: any;
  cmmiStatus: any;
  iClientCMMIRequired: any;
  iParnterCMMIRequired: any;

  constructor(config: NgbModalConfig, private userPermissionService: UserPermissionService, private modalService: NgbModal, public eventEmitterService: EventEmitterService, private notifyService: NotificationService, private certificationsService: CertificationsService) {
    const EditClientCertificationsAction: Permission =  Permission.EditClientCertificationsAction;
    
    if (this.userPermissionService.isAuthorizedFor(EditClientCertificationsAction)) {
      this.editClientCertificationsActionPermission = true;
    }
    
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });
    this.GetCMMICertificates();

    this.certificationForm = new FormGroup({
      vCMMIId: new FormControl("", [Validators.required]),
      vCMMICerNumber: new FormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(12), Validators.pattern("^(?! ).*[^ ]$")]),
      vCMMICertificatePdf: new FormControl("", [Validators.required]),
    });
  }

  public certificationsCardCompleted: any = false;
  updateProfileStatus(data: any) {
    this.certificationsCardCompleted = data["compnayCertifications"];
  }



  GetCertificate() {
    this.certificationsService.GetCertificate(this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.CMMIList = result["data"];
      }
    });
  }

  GetCheckApplicable() {
    this.certificationsService.GetCheckApplicable(this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.iClientCMMIRequired = result.data.iClientCMMIRequired;

        if (this.iClientCMMIRequired) {
          this.certificationForm.controls["vCMMIId"].disable();
          this.certificationForm.controls["vCMMICerNumber"].disable();
          this.certificationForm.controls["vCMMICertificatePdf"].disable();
          this.certificationForm.controls["vCMMIId"].patchValue("");
          this.certificationForm.controls["vCMMICerNumber"].patchValue("");
          this.certificationForm.controls["vCMMICertificatePdf"].patchValue("");
          this.certificationForm.controls["vCMMICerNumber"].setErrors(null);
          this.certificationForm.controls["vCMMIId"].setErrors(null);
          this.certificationForm.controls["vCMMICertificatePdf"].setErrors(null);
        } else {
          this.certificationForm.controls["vCMMIId"].enable();
          this.certificationForm.controls["vCMMICerNumber"].enable();
          this.certificationForm.controls["vCMMICertificatePdf"].enable();
          this.certificationForm.controls["vCMMICertificatePdf"].setValidators([Validators.required]);
          this.certificationForm.controls["vCMMIId"].setValidators([Validators.required]);
          this.certificationForm.controls["vCMMICerNumber"].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(12), Validators.pattern("^(?! ).*[^ ]$")]);
        }
      }
    });
  }

  CheckApplicable(event: any) {
    let reqData = {
      iClientCMMIRequired: event.checked,
    };
    this.certificationsService.CheckApplicable(reqData,this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetCheckApplicable();
        this.eventEmitterService.GetProfileStatusAPICallMethod();
      }
    });
  }

  FileBrowseHandler(event: any, type: any) {
    let fileSize = event["target"]["files"][0]["size"] / 1024;
    if (fileSize < 4 * 1024) {
      if (event.target.files[0].type == "application/pdf") {
        this.cmmiCertificate = event.target.files[0];
      } else {
        this.notifyService.showError(StaticMsg.NotPdfFileError, "");
        return;
      }
    } else {
      this.notifyService.showError(StaticMsg.FileLargerError, "");this.cmmiCertificate = "";
      this.certificationForm.controls["vCMMICertificatePdf"].patchValue(null);
      return;
    }
  }

  CancelMedia() {
    this.cmmiCertificate = "";
    this.certificationForm.controls["vCMMICertificatePdf"].patchValue(null);
  }

  GetCMMICertificates() {
    this.certificationsService.GetCMMICertificates(this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.cmmiCertificates = result["data"];
        this.cmmiStatus = result["cmmiStatus"];
      }
    });
  }

  DeleteCertificate(id: any) {
    this.certificationsService.DeleteCertificate(id,this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetCMMICertificates();
        this.eventEmitterService.GetProfileStatusAPICallMethod();
      }
    });
  }

  UploadCertificate() {
    this.certificationSubmitted = true;
    let tempflag = true;
    if (this.certificationForm.controls["vCMMIId"].value && this.certificationForm.controls["vCMMICerNumber"].value && this.certificationForm.controls["vCMMICertificatePdf"].value) {
      if (this.certificationForm.invalid) {
        return;
      } else {
        tempflag = false;
      }
    } else {
      this.certificationForm.controls["vCMMIId"].markAsTouched();
      this.certificationForm.controls["vCMMICerNumber"].markAsTouched();
      this.certificationForm.controls["vCMMICertificatePdf"].markAsTouched();
    }
    if (!this.iClientCMMIRequired && this.cmmiCertificates.length === 0 && tempflag) {
      this.certificationForm.controls["vCMMIId"].markAsTouched();
      this.certificationForm.controls["vCMMICerNumber"].markAsTouched();
      this.certificationForm.controls["vCMMICertificatePdf"].markAsTouched();
      return;
    }

    let reqData = {
      vCMMIId: this.certificationForm.value.vCMMIId,
      vCMMICerNumber: this.certificationForm.value.vCMMICerNumber,
      cmmiCertificate: this.cmmiCertificate,
    };

    this.certificationsService.UploadCertificate(reqData,this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetCMMICertificates();
        this.notifyService.showSuccess(result.message, "");
        this.certificationForm.reset();
        this.cmmiCertificate = "";
        this.certificationForm.controls["vCMMICerNumber"].setErrors(null);
        this.certificationForm.controls["vCMMIId"].setErrors(null);
        this.certificationForm.controls["vCMMICertificatePdf"].setErrors(null);
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.certificationsToggle=false;
      } else {
        if(this.iClientCMMIRequired){
          this.certificationsToggle=false;
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        }else{
          this.notifyService.showError("Please fill certificate details", "");
          return;
        }
          
      }
    });
  }

  toggleCertifications(hasPermission:boolean){
    if(hasPermission){
      this.GetCheckApplicable();
      this.GetCertificate();
      this.certificationForm.reset();
      this.cmmiCertificate = "";
      this.GetCMMICertificates();
      this.certificationsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }
}
