import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AwardsService } from './awards.service';
import { EventEmitterService } from '../../../../config/event-emitter.service';
import { NotificationService } from 'src/app/config/notification.service';
import { StaticMsg } from '../../../../config/en'
import { environment } from "../../../../../environments/environment";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UserPermissionService } from 'src/app/utils/services/user-permission.service';
import { Permission } from 'src/app/utils/enums/permission.enum';

@Component({
  selector: 'talent-awards',
  templateUrl: './awards.component.html',
  providers: [
    NgbModalConfig,
    NgbModal
  ]
})
export class TalentAwardsComponent implements OnInit {

  @Input() talentUserId:any;
  awardsToggle:boolean=false;

   // toggle actions permission
   addTalentRewardsActionPermission: boolean = false;
   editTalentRewardsActionPermission: boolean = false;
  
  imagePath = environment.image_path;
  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false
  };

  awardsForm!: FormGroup;
  awardsEditForm!: FormGroup;
  checkAwards = new FormControl(false);
  awardsFormSubmitted: any = false;
  awardsEditFormSubmitted: any = false;
  awardsList: any = [];
  uploadedAwardsList: any = [];
  specificAwardsList: any = [];
  vAwardName: any;
  iAwardId: any;
  vAwardDocument: any;
  public awardDetailsCardCompleted:any = false;
  public disableAwards:any = false;
  awardsTypeListArray: any = ['ABC', 'Other'];
  checkAwdsApplicable: any;

  filteredAward!: Observable<any[]>;

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    public eventEmitterService: EventEmitterService,
    private awardsService: AwardsService,
    private notifyService : NotificationService,
    private userPermissionService: UserPermissionService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;

    // assign individual permissions
    const AddTalentRewardsAction: Permission = Permission.AddTalentRewardsAction;
    const EditTalentRewardsAction: Permission = Permission.EditTalentRewardsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddTalentRewardsAction)) {
      this.addTalentRewardsActionPermission = true;
    }

    if (this.userPermissionService.isAuthorizedFor(EditTalentRewardsAction)) {
      this.editTalentRewardsActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data:any) => {
      this.updateProfileStatus(data);
    });
    this.GetUploadedAwards();
    this.GetCheckApplicable();
    this.awardsForm = new FormGroup({
      vAwardId: new FormControl('', [Validators.required]),
      vAwardDocument: new FormControl('', [Validators.required]),
      vOther: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50), Validators.pattern('^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$')])
    });

    this.awardsEditForm = new FormGroup({
      vType: new FormControl('', [Validators.required]),
      vAwardDocument: new FormControl('', [Validators.required]),
      vOther: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50), Validators.pattern('^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$')])
    });

    this.filteredAward = this.awardsForm.controls["vAwardId"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value, this.awardsList, "vAwardName") : this.awardsList.slice()))
    );
  }

  private _filter(value: string, array: any, key: any): string[] {
    let filterValue: string;
    if (value) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = "";
    }

    return array.filter((option: any) => option[key].toLowerCase().indexOf(filterValue) === 0);
  }

  GetAwardId(iAwardId: number) {
    this.iAwardId = iAwardId;
  }

  updateProfileStatus(data:any){
    this.awardDetailsCardCompleted = data['awardDetailsCardCompleted'];
  }

  AwardsModal(modelName:any,hasPermission:boolean) {
    if(hasPermission){
    this.GetAwards();
    this.awardsForm.reset();
    this.vAwardDocument = '';
    this.modalService.open(modelName, { centered:true, windowClass: 'profile-modal' });
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  AwardsEditModal(modelName:any, element: any,hasPermission:boolean) {
    if(hasPermission){
    this.awardsEditForm.reset();
    this.vAwardDocument = '';
    this.modalService.open(modelName, { centered:true, windowClass: 'profile-modal' });
    this.GetTypeofAwards(element.iAwardId);
    this.iAwardId = element.iAwardId;
    this.vAwardName = element.vAwardName;
    if ( element.vAwardName == 'Other') {
      this.awardsEditForm.controls.vOther.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(50), Validators.pattern('^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$')]);
    } else {
      this.awardsEditForm.controls.vOther.clearValidators();
      this.awardsEditForm.controls.vOther.setErrors(null);
    }
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  GetAwards(){
    this.awardsService.GetAwards().then((result: any) => {
      if (result.code == 200) {
        this.awardsList = result['data'];
      }
    });
  }

  FileBrowseHandler(event: any) {
    let fileSize = event['target']['files'][0]['size'] / 1024;
    if(fileSize > 4*1024 ){
      this.notifyService.showError(StaticMsg.FileLargerError,'');
      this.awardsForm.controls["vAwardDocument"].setValue('');
    }else{
      if ( event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/vnd.ms-excel" || event.target.files[0].type == "image/x-png" || event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" ) {
        this.vAwardDocument  = event.target.files[0];
      }else{
        this.notifyService.showError(StaticMsg.NotPdfImageFileError,'');
      }
    }
  }

  AwardChange(event:any) {
    if ( event.source.value == 'Other') {
      this.awardsForm.controls.vOther.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(50), Validators.pattern('^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$')]);
    } else {
      this.awardsForm.controls.vOther.clearValidators();
      this.awardsForm.controls.vOther.setErrors(null);
    }
  }

  GetUploadedAwards() {
    this.awardsService.GetUploadedAwards(this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.uploadedAwardsList = result['data'];
      }
    });
  }

  DeleteUploadedAwards(){
    this.awardsService.DeleteUploadedAwards(this.iAwardId,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.GetUploadedAwards();
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message,'');
      }
      else{
        this.modalService.dismissAll();
      }
    });
  }

  GetTypeofAwards(type:any){
    this.awardsService.GetTypeofAwards(type,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.specificAwardsList = result['data'];
      }
    });
  }

  DeleteTypeofAward(id: any, iAwardId: any){
    this.awardsService.DeleteTypeofAward(id,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.GetTypeofAwards(iAwardId);
        this.GetUploadedAwards();
        this.vAwardDocument = '';
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message,'');
      }
    });
    
  }

  UploadAwards() {
    if(this.iAwardId){ 
    }else{
      this.notifyService.showError("Please select Award from the list", "");
      this.awardsForm.controls["vAwardId"].setValue('');
      return;
    }
    this.awardsFormSubmitted = true;
    if (this.awardsForm.invalid) {
      return;
    }

    

    let reqData = {
      vAwardId : this.iAwardId,
      vAwardDocument: this.vAwardDocument,
      vValue: this.awardsForm.value.vOther
    }
    this.awardsService.UploadAward(reqData,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.modalService.dismissAll();
        this.GetUploadedAwards();
        this.iAwardId=undefined;
        this.vAwardDocument = '';
        this.notifyService.showSuccess('Award Added Successfully!','');
        this.awardsForm.reset();
      }
    });
  }

  EditAwards() {
    this.awardsEditFormSubmitted = true;
    // if (this.awardsEditForm.invalid) {
    //   return;
    // }
    if ( this.awardsEditForm.invalid && this.specificAwardsList.length == 0) {
      return;
    }
    let reqData = {
      vAwardId : this.iAwardId,
      vAwardDocument: this.vAwardDocument,
      vValue: this.awardsEditForm.value.vOther
    }
    this.awardsService.UploadAward(reqData,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.awardsEditForm.controls.vAwardDocument.patchValue('');
        this.awardsEditForm.controls.vOther.patchValue('');
        this.GetTypeofAwards(this.iAwardId);
        this.vAwardDocument = '';
        this.modalService.dismissAll();
        this.notifyService.showSuccess('Award Updated Successfully!','');
        this.awardsEditForm.reset();
      } else {
        this.modalService.dismissAll();
      }
    });
  }

  CheckApplicable(event: any) {
    let reqData = {
      iEmpAwardRequired: event.checked
    }
    this.awardsService.CheckApplicable(reqData,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.GetCheckApplicable();
      }
    });
  }

  GetCheckApplicable(){
    this.awardsService.GetCheckApplicable(this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.checkAwdsApplicable = result.data.iEmpAwardRequired;
      }
    });
  }

  crossClick() {
    if(this.vAwardDocument) {
      this.vAwardDocument = ''
    }
  }

}
