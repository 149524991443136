import { Component, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit, OnInit } from "@angular/core";
import { VERSION } from "@angular/material/core";
import { Router } from "@angular/router";
import { NavItem } from "./nav-item";
import { NavService } from "./nav.service";
import { EventEmitterService } from "./../../config/event-emitter.service";
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-defaultlayout",
  templateUrl: "./defaultLayout.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class DefaultlayoutComponent implements AfterViewInit, OnInit {
  @ViewChild("appDrawer") appDrawer?: ElementRef;
  version = VERSION;

  public disabledPublishDefaultProfile: any = false;
  public completePublishProfile: any = false;

  navItems: NavItem[] = [];

  constructor(private navService: NavService, private router: Router, public eventEmitterService: EventEmitterService, private userPermissionService: UserPermissionService, private cookieService: CookieService) {
    setTimeout(() => {
      this.AddNavItems();
    }, 500);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  AddNavItems() {
    // assign individual permissions
    const AccessDashboard: Permission = Permission.AccessDashboard;
    // const AccessPartnerOnboard: Permission = Permission.AccessPartnerOnboard;
    // const AccessClientOnboard: Permission = Permission.AccessClientOnboard;
    // const AccessPartnerPublish: Permission = Permission.AccessPartnerPublish;
    // const AccessEmployeePublish: Permission = Permission.AccessEmployeePublish;
    // const AccessClientPublish: Permission = Permission.AccessClientPublish;
    const AccessPartnerUserManagement: Permission = Permission.AccessPartnerUserManagement;
    const AccessEmployeeUserManagement: Permission = Permission.AccessEmployeeUserManagement;
    const AccessClientUserManagement: Permission = Permission.AccessClientUserManagement;
    const AccessTitleAndSkill: Permission = Permission.AccessTitleAndSkill;
    const AccessTechnology: Permission = Permission.AccessTechnology;
    const AccessReasons: Permission = Permission.AccessReasons;
    const AccessContact: Permission = Permission.AccessContact;
    const AccessIndustry: Permission = Permission.AccessIndustry;
    const AccessService: Permission = Permission.AccessService;
    const AccessLanguage: Permission = Permission.AccessLanguage;
    const AccessHobbie: Permission = Permission.AccessHobbie;
    const AccessDegree: Permission = Permission.AccessDegree;
    const AccessBranch: Permission = Permission.AccessBranch;
    const AccessAward: Permission = Permission.AccessAward;
    const AccessCertificate: Permission = Permission.AccessCertificate;
    const AccessDealEngine: Permission = Permission.AccessDealEngine;
    const AccessBilling: Permission = Permission.AccessBilling;
    const AccessContracts: Permission = Permission.AccessContracts;
    const AccessInterviews: Permission = Permission.AccessInterviews;
    const AccessRequirements: Permission = Permission.AccessRequirements;
    const AccessRole: Permission = Permission.AccessRole;
    const AccessPermissionManagement: Permission = Permission.AccessPermissionManagement;
    const AccessRoleManagement: Permission = Permission.AccessRoleManagement;

    let roleId = localStorage.getItem("sub_admin_role");
    let userType = localStorage.getItem("sub_admin_account_type");

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AccessDashboard)) {
      this.navItems.push({
        displayName: "Dashboard",
        iconName: "dashboard.svg",
        route: "dashboard",
        activeIconName: "dashboard-active.svg",
        className: "",
      });
    }

    // if (this.userPermissionService.isAuthorizedFor(AccessPartnerOnboard) || this.userPermissionService.isAuthorizedFor(AccessClientOnboard)) {
    //   this.navItems.push({
    //     displayName: "Onboard",
    //     iconName: "description.svg",
    //     activeIconName: "description-active.svg",
    //     route: "onboard",
    //     className: "",
    //     children: [],
    //   });

      // let navItemIndex = this.navItems.findIndex((obj: any) => obj.displayName == "Onboard");

      // if (this.userPermissionService.isAuthorizedFor(AccessPartnerOnboard)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Partner Onboard",
      //     iconName: "description.svg",
      //     route: "partner-onboard",
      //     activeIconName: "description-active.svg",
      //     className: "",
      //   });
      // }
      // if (this.userPermissionService.isAuthorizedFor(AccessClientOnboard)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Client Onboard",
      //     iconName: "description.svg",
      //     route: "client-onboard",
      //     activeIconName: "description-active.svg",
      //     className: "",
      //   });
      // }
    // }

    // if (this.userPermissionService.isAuthorizedFor(AccessPartnerPublish) || this.userPermissionService.isAuthorizedFor(AccessEmployeePublish) || this.userPermissionService.isAuthorizedFor(AccessClientPublish)) {
    //   this.navItems.push({
    //     displayName: "Publish",
    //     iconName: "interviews.svg",
    //     activeIconName: "interviews-active.svg",
    //     route: "publish",
    //     className: "",
    //     children: [],
    //   });

      // let navItemIndex = this.navItems.findIndex((obj: any) => obj.displayName == "Publish");

      // if (this.userPermissionService.isAuthorizedFor(AccessPartnerPublish)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Partner Publish",
      //     iconName: "interviews.svg",
      //     activeIconName: "interviews-active.svg",
      //     route: "partner-publish",
      //     className: "",
      //   });
      // }
      // if (this.userPermissionService.isAuthorizedFor(AccessEmployeePublish)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Employee Publish",
      //     iconName: "interviews.svg",
      //     activeIconName: "interviews-active.svg",
      //     route: "employee-publish",
      //     className: "",
      //   });
      // }
      // if (this.userPermissionService.isAuthorizedFor(AccessClientPublish)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Client Publish",
      //     iconName: "interviews.svg",
      //     activeIconName: "interviews-active.svg",
      //     route: "client-publish",
      //     className: "",
      //   });
      // }
    // }

    if (this.userPermissionService.isAuthorizedFor(AccessPartnerUserManagement) || this.userPermissionService.isAuthorizedFor(AccessEmployeeUserManagement) || this.userPermissionService.isAuthorizedFor(AccessClientUserManagement)) {
      this.navItems.push({
        displayName: "User Management",
        iconName: "reviews.svg",
        activeIconName: "reviews-active.svg",
        className: "",
        children: [],
      });

      let navItemIndex = this.navItems.findIndex((obj: any) => obj.displayName == "User Management");

      if (this.userPermissionService.isAuthorizedFor(AccessPartnerUserManagement)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Partner",
          iconName: "reviews.svg",
          activeIconName: "reviews-active.svg",
          route: "user-partner",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessEmployeeUserManagement)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Employee",
          iconName: "reviews.svg",
          activeIconName: "reviews-active.svg",
          route: "user-employee",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessClientUserManagement)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Client",
          iconName: "reviews.svg",
          activeIconName: "reviews-active.svg",
          route: "user-client",
          className: "",
        });
      }
    }

    if (
      this.userPermissionService.isAuthorizedFor(AccessTitleAndSkill) ||
      this.userPermissionService.isAuthorizedFor(AccessTechnology) ||
      this.userPermissionService.isAuthorizedFor(AccessReasons) ||
      this.userPermissionService.isAuthorizedFor(AccessContact) ||
      this.userPermissionService.isAuthorizedFor(AccessIndustry) ||
      this.userPermissionService.isAuthorizedFor(AccessService)
    ) {
      this.navItems.push({
        displayName: "General",
        iconName: "refer-a-friend.svg",
        activeIconName: "refer-a-friend-active.svg",
        className: "",
        children: [],
      });

      let navItemIndex = this.navItems.findIndex((obj: any) => obj.displayName == "General");

      if (this.userPermissionService.isAuthorizedFor(AccessTitleAndSkill)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Title & Skill",
          iconName: "refer-a-friend.svg",
          activeIconName: "refer-a-friend-active.svg",
          route: "skill",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessTechnology)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Technology",
          iconName: "refer-a-friend.svg",
          activeIconName: "refer-a-friend-active.svg",
          route: "technology",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessReasons)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Reasons",
          iconName: "refer-a-friend.svg",
          activeIconName: "refer-a-friend-active.svg",
          route: "reasons",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessContact)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Contact",
          iconName: "refer-a-friend.svg",
          activeIconName: "refer-a-friend-active.svg",
          route: "contact",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessIndustry)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Industry",
          iconName: "partner-admin.svg",
          activeIconName: "partner-admin-active.svg",
          route: "industry",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessService)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Service",
          iconName: "partner-admin.svg",
          activeIconName: "partner-admin-active.svg",
          route: "service",
          className: "",
        });
      }
    }

    if (
      this.userPermissionService.isAuthorizedFor(AccessLanguage) ||
      this.userPermissionService.isAuthorizedFor(AccessHobbie) ||
      this.userPermissionService.isAuthorizedFor(AccessDegree) ||
      this.userPermissionService.isAuthorizedFor(AccessBranch) ||
      this.userPermissionService.isAuthorizedFor(AccessAward) ||
      this.userPermissionService.isAuthorizedFor(AccessCertificate)
    ) {
      this.navItems.push({
        displayName: "Employee",
        iconName: "help-desk.svg",
        activeIconName: "help-desk-active.svg",
        className: "",
        children: [],
      });

      let navItemIndex = this.navItems.findIndex((obj: any) => obj.displayName == "Employee");

      if (this.userPermissionService.isAuthorizedFor(AccessLanguage)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Language",
          iconName: "help-desk.svg",
          activeIconName: "help-desk-active.svg",
          route: "emp-languages",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessHobbie)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Hobbie",
          iconName: "help-desk.svg",
          activeIconName: "help-desk-active.svg",
          route: "emp-hobbie",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessDegree)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Degree",
          iconName: "help-desk.svg",
          activeIconName: "help-desk-active.svg",
          route: "emp-degree",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessBranch)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Branch",
          iconName: "help-desk.svg",
          activeIconName: "help-desk-active.svg",
          route: "emp-branch",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessAward)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Award",
          iconName: "help-desk.svg",
          activeIconName: "help-desk-active.svg",
          route: "emp-award",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessCertificate)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Certificate",
          iconName: "help-desk.svg",
          activeIconName: "help-desk-active.svg",
          route: "emp-certificate",
          className: "",
        });
      }
    }

    if (this.userPermissionService.isAuthorizedFor(AccessDealEngine)) {
      this.navItems.push({
        displayName: "Deal Engine",
        iconName: "deal-engine.svg",
        route: "dealengine",
        activeIconName: "deal-engine-active.svg",
        className: "",
      });
    }

    if (this.userPermissionService.isAuthorizedFor(AccessBilling)) {
      this.navItems.push({
        displayName: "Billing",
        iconName: "description.svg",
        route: "billing",
        activeIconName: "description-active.svg",
        className: "",
      });
    }

    if (this.userPermissionService.isAuthorizedFor(AccessContracts)) {
      this.navItems.push({
        displayName: "Contracts",
        iconName: "description.svg",
        route: "contracts",
        activeIconName: "description-active.svg",
        className: "",
      });
    }

    if (this.userPermissionService.isAuthorizedFor(AccessInterviews)) {
      this.navItems.push({
        displayName: "Interviews",
        iconName: "description.svg",
        route: "interviews",
        activeIconName: "description-active.svg",
        className: "",
      });
    }

    if (this.userPermissionService.isAuthorizedFor(AccessRequirements)) {
      this.navItems.push({
        displayName: "Requirements",
        iconName: "description.svg",
        route: "requirements",
        activeIconName: "description-active.svg",
        className: "",
      });
    }

    if (
      this.userPermissionService.isAuthorizedFor(AccessRole) ||
      this.userPermissionService.isAuthorizedFor(AccessPermissionManagement) ||
      this.userPermissionService.isAuthorizedFor(AccessRoleManagement)
    ) {
      this.navItems.push({
        displayName: "Access Management",
        iconName: "rolemanagement.svg",
        activeIconName: "rolemanagement-active.svg",
        className: "",
        children: [],
      });

      let navItemIndex = this.navItems.findIndex((obj: any) => obj.displayName == "Access Management");

      if (this.userPermissionService.isAuthorizedFor(AccessRole)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Role",
          iconName: "rolemanagement.svg",
          activeIconName: "rolemanagement-active.svg",
          route: "role",
          className: "",
        });
      }
      // if (this.userPermissionService.isAuthorizedFor(AccessParentPermission)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Parent Permission",
      //     iconName: "rolemanagement.svg",
      //   activeIconName: "rolemanagement-active.svg",
      //     route: "parent-permission",
      //     className: "",
      //   });
      // }
      // if (this.userPermissionService.isAuthorizedFor(AccessChildPermission)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Child Permission",
      //     iconName: "rolemanagement.svg",
      //     activeIconName: "rolemanagement-active.svg",
      //     route: "child-permission",
      //     className: "",
      //   });
      // }
      if (this.userPermissionService.isAuthorizedFor(AccessPermissionManagement)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Permission Management",
          iconName: "rolemanagement.svg",
          activeIconName: "rolemanagement-active.svg",
          route: "permission-management",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessRoleManagement)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Role Management - Admin",
          iconName: "rolemanagement.svg",
          activeIconName: "rolemanagement-active.svg",
          route: "role-management",
          className: "",
        });

        this.navItems[navItemIndex].children?.push({
          displayName: "Role Management - Partner",
          iconName: "rolemanagement.svg",
          activeIconName: "rolemanagement-active.svg",
          route: "role-management-partner",
          className: "",
        });

        this.navItems[navItemIndex].children?.push({
          displayName: "Role Management - Client",
          iconName: "rolemanagement.svg",
          activeIconName: "rolemanagement-active.svg",
          route: "role-management-client",
          className: "",
        });
      }
    }

    if (roleId && userType) {
      this.navItems.push({
        displayName: "Profile",
        iconName: "account-circle.svg",
        activeIconName: "account-circle-active.svg",
        route: "/user-profile",
        disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
        completePublishProfile: this.completePublishProfile,
        className: "",
      });
    }
  }

  Logout() {
    let device_id = localStorage.getItem("admin_device_id");
    this.navService.logoutAPI({ vImei: device_id }).subscribe((res: any) => {
      this.navService.Logout();
      this.userPermissionService.ResetPermissions();
      localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_type");
        localStorage.removeItem("admin_user_email");
        localStorage.removeItem("sub_admin_account_type");
        localStorage.removeItem("sub_admin_email");
        localStorage.removeItem("sub_admin_role");
        localStorage.removeItem("admin_zendesk_token");
        localStorage.removeItem("admin_device_id");
      this.cookieService.delete("admin_user");
      this.router.navigate(["/login"]);
    });
  }
}
