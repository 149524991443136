import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewPartnerService {

  constructor(private http: HttpClient) { }

  //profile service

  GetProfileStatus(partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/partner-profile-status?iUserId=${partnerUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  PublishProfile(data: any, partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/publish-profile?iUserId=${partnerUserId}`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
  GetCountryList() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/countryList`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetStateList(countryId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/stateList?iCountryId=` + countryId;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCityList(stateId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/cityList?iStateId=` + stateId;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetSecondarySkillList() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + "/common/v1/list/secondary-skill";
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetIndustryList() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/list/industry`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetServiceList() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/list/service`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetTechnologyList() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/list/technology`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetExecutiveDetails(partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/executive-details?iUserId=${partnerUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCompanyDetails(partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/company-details?iUserId=${partnerUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdatePartnerExecutive(data: any, partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/executive-details?iUserId=${partnerUserId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAdditionalDetails(partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/additional-details?iUserId=${partnerUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetPrimaryDetails(partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/primary-details?iUserId=${partnerUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdatePrimaryDetails(data: any, selectedImage: any, date: any, iCountryId: any, iStateId: any, iCityId: any, partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/primary-details?iUserId=${partnerUserId}`;
      const formData: FormData = new FormData();
      formData.append("iCityId", iCityId);
      formData.append("iCompanyBio", data.iCompanyBio);
      formData.append("vEmailId", data.vEmailId);
      formData.append("iCountryId", iCountryId);
      formData.append("iStateId", iStateId);
      formData.append("iZipCode", data.iZipCode);
      formData.append("txFacebookUrl", data.txFacebookUrl);
      formData.append("txInstagramUrl", data.txInstagramUrl);
      formData.append("txLinkedinUrl", data.txLinkedinUrl);
      formData.append("txTwitterUrl", data.txTwitterUrl);
      formData.append("vAddress", data.vAddress);
      formData.append("vDialCode", data.vPhoneNo.dialCode);
      formData.append("vCountryCode", data.vPhoneNo.countryCode);
      formData.append("vPhoneNo", data.vPhoneNo.number);
      formData.append("vAltEmailId", data.vAltEmailId);
      formData.append("vCompanyName", data.vCompanyName);
      formData.append("vCompanyWebsiteUrl", data.vCompanyWebsiteUrl);
      formData.append("iCompanyFoundedDate", date);
      formData.append("newImage", selectedImage);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateCompanyDetails(data: any, partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/company-details?iUserId=${partnerUserId}`;
      const formData: FormData = new FormData();
      formData.append("vIntroDeck", data.vIntroDeck);
      formData.append("vVideo", data.vVideo);
      formData.append("media", data.media);
      formData.append("media1", data.media1);
      formData.append("media2", data.media2);
      formData.append("media3", data.media3);
      formData.append("media4", data.media4);
      formData.append("vSize", data.vSize);
      formData.append("vTechnology", data.vTechnology);
      formData.append("vIndustryDetails", data.vIndustryDetails);
      formData.append("vServiceDetails", data.vServiceDetails);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateAdditionalDetails(data: any, partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v1/additional-details?iUserId=${partnerUserId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }


  GetCertificate(partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/list/certificate?iUserId=${partnerUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllCertificate(partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/ISO-certificate?iUserId=${partnerUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UploadISOCertificate(data: any, partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/ISO-certificate?iUserId=${partnerUserId}`;
      const formData: FormData = new FormData();
      formData.append("vISOId", data.vISOId);
      formData.append("vISOCerNumber", data.vISOCerNumber);
      formData.append("isoCertificate", data.isoCertificate);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteAllCertificate(id: any, partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/ISO-certificate/${id}?iUserId=${partnerUserId}`;
      this.http
        .delete(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UploadCertificate(data: any, partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/user-certificate?iUserId=${partnerUserId}`;
      const formData: FormData = new FormData();
      formData.append("vIECNumber", data.vIECNumber);
      formData.append("iecCertificate", data.iecCertificate);
      formData.append("vCMMIId", data.vCMMIId);
      formData.append("vCMMICerNumber", data.vCMMICerNumber);
      formData.append("cmmiCertificate", data.cmmiCertificate);
      formData.append("trademarkCertifiacate", data.trademarkCertifiacate);
      formData.append("reward", data.reward);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCertificatesStatus(partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/certificates-status?iUserId=${partnerUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteCompanyDetailImages(data: any, partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/company-details-images?iUserId=${partnerUserId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteCompanyDetailVideo(data: any, partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/company-details-video?iUserId=${partnerUserId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetPartnerdetails(partnerUserId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/view-profile?iUserId=${partnerUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
