<div name="project-portfolio-box" id="project-portfolio-box" class="col-12">
  <div name="project-portfolio-card-container" id="project-portfolio-card-container" class="card-container">
    <div name="project-portfolio-card-header" id="project-portfolio-card-header" class="card-header">
      <div name="project-portfolio-title-section" id="project-portfolio-title-section" class="title-section custom-title">
        <div class="d-flex w-100 custom-title-flex">
          <div class="order-1">
            <h4 name="project-portfolio-card-title" id="project-portfolio-card-title" class="card-title award-margin">Project Portfolio</h4>
          </div>
          <div class="order-2" placement="right auto" [ngbTooltip]="
          addTalentProjectPortfolioActionPermission ? '' : 'Not Authorized'
        " [ngClass]="
          addTalentProjectPortfolioActionPermission ? '' : 'unauthorized-cursor'
        ">
            <button name="executive-details-card-edit-button" id="executive-details-card-edit-button" class="card-edit-button ecard-add-button" type="button" (click)="ProjectPortfolioModal(projectPortfolio,addTalentProjectPortfolioActionPermission)" [ngClass]="
            addTalentProjectPortfolioActionPermission ? '' : 'unauthorized-action'
          ">
              <img class="card-add-button-icon" [src]="imagePath + 'green-add.svg'" alt="add-button">Add New
            </button>
          </div>
        </div>
        <!-- <div class="d-flex w-100 custom-title-flex-end">
          <div name="project-portfolio-status-section" id="project-portfolio-status-section" class="status-section status-margin">
            <div name="project-portfolio-incomplete-status-box" id="project-portfolio-incomplete-status-box" class="status-box" *ngIf="!projectPortfolioCardCompleted">
              <img id="project-portfolio-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'" alt="status-icon">
              <label name="project-portfolio-incomplete-status-text" id="project-portfolio-incomplete-status-text" class="status-text incomplete">Pending</label>
            </div>
            <div name="project-portfolio-completed-status-text" id="project-portfolio-completed-status-text" class="status-box" *ngIf="projectPortfolioCardCompleted">
              <img id="project-portfolio-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'" alt="status-icon">
              <label name="project-portfolio-completed-status-text" id="project-portfolio-completed-status-text" class="status-text completed">Completed</label>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div name="project-portfolio-card" id="project-portfolio-card" class="card" >
      <perfect-scrollbar>
        <div name="project-portfolio-card-content" id="project-portfolio-card-content" class="card-content scroll-card pb-0" *ngIf="PortfolioArray.length > 0">
          <div *ngFor="let element of PortfolioArray" name="project-portfolio-card-content-col-7" id="project-portfolio-card-content-col-7" class="project-portfolio-card-content">
            <label name="project-portfolio-card-content-col-7-label" id="project-portfolio-card-content-col-7-label" class="label">
              {{ element?.vTitile }}
                <button name="project-portfolio-card-edit-button" id="project-portfolio-card-edit-button" class="card-edit-button"
                  type="button" (click)="ProjectPortfolioEditModal(projectPortfolioEdit,element,editTalentProjectPortfolioActionPermission)" [ngClass]="
                  editTalentProjectPortfolioActionPermission ? '' : 'unauthorized-cursor'" placement="right auto" [ngbTooltip]="editTalentProjectPortfolioActionPermission ? '' : 'Not Authorized'">
                  <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
                </button>
            </label>
            <p name="project-portfolio-card-content-col-7-text" id="project-portfolio-card-content-col-7-text" class="text">
              {{ element?.txDescription }}
            </p>
          </div>
        </div>
        <div name="project-portfolio-card-content" id="project-portfolio-card-content" class="card-content" *ngIf="PortfolioArray.length == 0">
          <label class="text">No Portfolio Found</label>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>

<ng-template #projectPortfolio let-c="close" let-d="dismiss">
  <div name="project-portfolio-modal-header" id="project-portfolio-modal-header" class="modal-header">
    <h1 name="project-portfolio-modal-title" id="project-portfolio-modal-title" class="modal-title">Project Portfolio</h1>
  </div>
  <perfect-scrollbar>
    <div name="project-portfolio-modal-body" id="project-portfolio-modal-body" class="modal-body modal-scroll">
      <form name="projectPortfolioForm" id="projectPortfolioForm" class="form" [formGroup]="projectPortfolioForm" (ngSubmit)="ProjectPortfolioProcess()">
        <div name="project-portfolio-row" id="project-portfolio-row" class="row">
          <div name="project-portfolio-col-1" id="project-portfolio-col-1" class="col-12 col-md-6">
            <label for="vProjectTitle" name="project-portfolio-col-1-label" id="project-portfolio-col-1-label" class="custom-label">Project Title <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="project-portfolio-col-1-input" id="project-portfolio-col-1-input" class="custom-input" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="vProjectTitle" formControlName="vProjectTitle" placeholder="Project Title" required>
              <mat-error name="project-portfolio-col-1-error-0" id="project-portfolio-col-1-error-0" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vProjectTitle'].invalid">
                <mat-error name="project-portfolio-col-1-error-1" id="project-portfolio-col-1-error-1" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vProjectTitle'].errors!.required">
                  Project Title is required
                </mat-error>
                <mat-error name="project-portfolio-col-1-error-2" id="project-portfolio-col-1-error-2" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vProjectTitle'].errors!.minlength">
                  Must have minimum 2 characters
                </mat-error>
                <mat-error name="project-portfolio-col-1-error-3" id="project-portfolio-col-1-error-3" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vProjectTitle'].errors!.maxlength">
                  Must not exceed 46 characters
                </mat-error>
                <mat-error name="project-portfolio-col-1-error-4" id="project-portfolio-col-1-error-4" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vProjectTitle'].errors!.pattern">
                  Only characters are allowed
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="project-portfolio-col-2" id="project-portfolio-col-2" class="col-12 col-md-6">
            <label for="vUrl" name="project-portfolio-col-2-label" id="project-portfolio-col-2-label" class="custom-label">URL</label>
            <mat-form-field hideRequiredMarker name="project-portfolio-col-2-input" id="project-portfolio-col-2-input" class="custom-input" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="vUrl" formControlName="vUrl" placeholder="https://www.w3.org/Provider/Style/dummy.html">
              <mat-error name="project-portfolio-col-2-error-0" id="project-portfolio-col-2-error-0" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vUrl'].invalid">
                <!-- <mat-error name="project-portfolio-col-2-error-1" id="project-portfolio-col-2-error-1" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vUrl'].errors!.required">
                  URL is required
                </mat-error> -->
                <mat-error name="project-portfolio-col-2-error-2" id="project-portfolio-col-2-error-2" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vUrl'].errors!.minlength">
                  Must have minimum 2 characters
                </mat-error>
                <mat-error name="project-portfolio-col-2-error-4" id="project-portfolio-col-2-error-4" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vUrl'].errors!.pattern">
                  please enter valid URL
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <label for="vProjectScreenshot" name="project-portfolio-video-label" id="project-portfolio-video-label" class="custom-label">Project Screenshot</label>
            <mat-form-field hideRequiredMarker name="company-details-image-input" id="company-details-image-input" class="custom-input visibility-input" floatPlaceholder="never" floatLabel="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box full-width" *ngIf="mediaArray.length <= 5">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngFor="let file of mediaArray">
                        {{ file.name }} <button type="button" name="button" (click)="crossClick(file)" class="btn"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="company-details-image-vImages-1" id="company-details-image-vImages-1" matInput readonly formControlName="vProjectScreenshot" [value]="mediaArray.length > 0 ? ' ' : ''" placeholder="Upload Image" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="company-details-image-vImages-2"
                id="company-details-image-vImages-2"
                class="file-upload-input"
                (change)="FileBrowseHandler($event,1)"
                formControlName="vProjectScreenshot"
                accept="image/*"
                multiple
              />
              <button name="company-details-image-upload-btn" id="company-details-image-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <div class="input-details-box">
                <label class="text">Max 5 images (4MB each)</label>
              </div>
            </mat-form-field>
          </div>
          <div name="project-portfolio-col-11" id="project-portfolio-col-11" class="col-12">
            <label for="vDescription" name="project-portfolio-col-11-label" id="project-portfolio-col-11-label" class="custom-label">Description <span class="text-danger">*</span></label>
            <mat-form-field floatPlaceholder="never" floatLabel="never" name="mat-form-field-company-bio" id="mat-form-field-company-bio" class="custom-input textarea">
              <textarea matInput rows="7" name="vDescription" id="vDescription" formControlName="vDescription" placeholder="Type here" #vDescription></textarea>
              <span name="bio-character-count-1" id="bio-character-count-1" class="bio-character-counter"><span name="bio-character-count-2" id="bio-character-count-2" class="me-1" [ngClass]="{'exceeded': vDescription.value.length < 150}">{{vDescription.value.length}}</span> / 150</span>
              <mat-error name="mat-error-company-bio" id="mat-error-company-bio" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vDescription'].invalid">
                <mat-error name="mat-error-company-bio-text-1" id="mat-error-company-bio-text-1" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vDescription'].errors!.required">
                  Description is required
                </mat-error>
                <mat-error name="mat-error-company-bio-text-2" id="mat-error-company-bio-text-2" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vDescription'].errors!.minlength">
                  Must have minimum 150 characters
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="project-portfolio-col-11" id="project-portfolio-col-11" class="col-12">
            <label for="vMyContribution" name="project-portfolio-col-11-label" id="project-portfolio-col-11-label" class="custom-label">My Contribution <span class="text-danger">*</span></label>
            <mat-form-field floatPlaceholder="never" floatLabel="never" name="mat-form-field-company-bio" id="mat-form-field-company-bio" class="custom-input textarea">
              <textarea matInput rows="7" name="vMyContribution" id="vMyContribution" formControlName="vMyContribution" placeholder="Type here" #vMyContribution></textarea>
              <span name="bio-character-count-1" id="bio-character-count-1" class="bio-character-counter"><span name="bio-character-count-2" id="bio-character-count-2" class="me-1" [ngClass]="{'exceeded': vMyContribution.value.length < 150}">{{vMyContribution.value.length}}</span> / 150</span>
              <mat-error name="mat-error-company-bio" id="mat-error-company-bio" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vMyContribution'].invalid">
                <mat-error name="mat-error-company-bio-text-1" id="mat-error-company-bio-text-1" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vMyContribution'].errors!.required">
                  My Contribution is required
                </mat-error>
                <mat-error name="mat-error-company-bio-text-2" id="mat-error-company-bio-text-2" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vMyContribution'].errors!.minlength">
                  Must have minimum 150 characters
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="project-portfolio-col-7" id="project-portfolio-col-7" class="col-12">
            <label for="vTechnology" name="project-portfolio-col-7-label" id="project-portfolio-col-7-label" class="custom-label">Technology <span class="text-danger">*</span></label>

            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never"
            name="project-portfolio-input" id="project-portfolio-input"
            class="custom-dropdown custom-input form">
              <mat-chip-list #technologyChipList1 aria-label="Technology selection" class="custom-multi-chip-dropdown"
                formControlName="vTechnology">
                <mat-chip *ngFor="let technology of technologies" [removable]="true" (removed)="OnTechnologyRemoved(technology)">
                  {{technology.vTechnologyName}}
                  <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input matInput #technologyInput1 (keyup)="_filterTechnology(technologyInput1.value)" [matAutocomplete]="autoTechnology"
                  [matChipInputFor]="technologyChipList1" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="technologyInput1.value = ''">
              </mat-chip-list>
              <mat-autocomplete #autoTechnology="matAutocomplete" [displayWith]="DisplayFnTechnology"
                (optionSelected)="AddTechnology($event.option.value); technologyInput1.value = ''">
                <mat-option *ngFor="let technology of filterTechnologies" [value]="technology">
                  {{technology.vTechnologyName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="professional-details-col-7-error-0" id="professional-details-col-7-error-0" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vTechnology'].invalid">
                <mat-error name="professional-details-col-7-error-1" id="professional-details-col-7-error-1" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vTechnology'].errors?.required">
                  Technology is required
                </mat-error>
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="project-portfolio-col-7-select" id="project-portfolio-col-7-select" class="custom-dropdown form">
              <mat-select panelClass="custom-multi-select-dropdown" name="professional-details-vTechnology" id="professional-details-vTechnology" formControlName="vTechnology" multiple [compareWith]="MultiSelectTechnologyCompareFn" placeholder="Select Technology">
                <mat-select-trigger>
                  <mat-chip-list>
                    <mat-chip *ngFor="let technology of projectPortfolioForm.controls['vTechnology'].value"
                      [removable]="true" (removed)="OnAddTechnologyRemoved(technology)">
                      {{ technology.vTechnologyName }}
                      <mat-icon matChipRemove>close</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </mat-select-trigger>
                <mat-option *ngFor="let technology of projectTechnologyListArray" [value]="technology">{{technology.vTechnologyName}}</mat-option>
              </mat-select>
              <mat-error name="professional-details-col-7-error-0" id="professional-details-col-7-error-0" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vTechnology'].invalid">
                <mat-error name="professional-details-col-7-error-1" id="professional-details-col-7-error-1" class="reduce-bottom-space" *ngIf="projectPortfolioForm.controls['vTechnology'].errors?.required">
                  Technology is required
                </mat-error>
              </mat-error>
            </mat-form-field> -->

          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <div name="project-portfolio-modal-footer" id="project-portfolio-modal-footer" class="modal-footer">
    <button type="submit" name="project-portfolio-modal-footer-btn-submit" id="project-portfolio-modal-footer-btn-submit" class="btn btn-active" form="projectPortfolioForm">Submit</button>
    <button type="button" name="project-portfolio-modal-footer-btn-cancel" id="project-portfolio-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="d('Cross click')">Cancel</button>
  </div>
</ng-template>

<ng-template #projectPortfolioEdit let-c="close" let-d="dismiss">
  <div name="project-portfolio-modal-header" id="project-portfolio-modal-header" class="modal-header with-button">
    <h1 name="project-portfolio-modal-title" id="project-portfolio-modal-title" class="modal-title">Edit Project Portfolio</h1>
    <button type="button" name="button" class="delete-btn" (click)="removeProject(projectPortfolioEditForm.value.iPortfolioId)">
      <img [src]="imagePath + 'delete_image.svg'" alt="Delete" class="delete-img"> Remove Project
    </button>
  </div>
  <perfect-scrollbar>
    <div name="project-portfolio-modal-body" id="project-portfolio-modal-body" class="modal-body modal-scroll">
      <form name="projectPortfolioEditForm" id="projectPortfolioEditForm" class="form" [formGroup]="projectPortfolioEditForm" (ngSubmit)="ProjectPortfolioEditProcess()">
        <div name="project-portfolio-row" id="project-portfolio-row" class="row">
          <div name="project-portfolio-col-1" id="project-portfolio-col-1" class="col-12 col-md-6">
            <label for="vProjectTitle" name="project-portfolio-col-1-label" id="project-portfolio-col-1-label" class="custom-label">Project Title <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="project-portfolio-col-1-input" id="project-portfolio-col-1-input" class="custom-input" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="vProjectTitle" formControlName="vProjectTitle" placeholder="Project Title" required>
              <mat-error name="project-portfolio-col-1-error-0" id="project-portfolio-col-1-error-0" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vProjectTitle'].invalid">
                <mat-error name="project-portfolio-col-1-error-1" id="project-portfolio-col-1-error-1" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vProjectTitle'].errors!.required">
                  Project Title is required
                </mat-error>
                <mat-error name="project-portfolio-col-1-error-2" id="project-portfolio-col-1-error-2" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vProjectTitle'].errors!.minlength">
                  Must have minimum 2 characters
                </mat-error>
                <mat-error name="project-portfolio-col-1-error-3" id="project-portfolio-col-1-error-3" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vProjectTitle'].errors!.maxlength">
                  Must not exceed 46 characters
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="project-portfolio-col-2" id="project-portfolio-col-2" class="col-12 col-md-6">
            <label for="vUrl" name="project-portfolio-col-2-label" id="project-portfolio-col-2-label" class="custom-label">URL</label>
            <mat-form-field hideRequiredMarker name="project-portfolio-col-2-input" id="project-portfolio-col-2-input" class="custom-input" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="vUrl" formControlName="vUrl" placeholder="https://www.w3.org/Provider/Style/dummy.html">
              <mat-error name="project-portfolio-col-2-error-0" id="project-portfolio-col-2-error-0" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vUrl'].invalid">
                <!-- <mat-error name="project-portfolio-col-2-error-1" id="project-portfolio-col-2-error-1" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vUrl'].errors!.required">
                  URL is required
                </mat-error> -->
                <mat-error name="project-portfolio-col-2-error-2" id="project-portfolio-col-2-error-2" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vUrl'].errors!.minlength">
                  Must have minimum 2 characters
                </mat-error>
                <mat-error name="project-portfolio-col-2-error-4" id="project-portfolio-col-2-error-4" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vUrl'].errors!.pattern">
                  Please enter valid URL
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <label for="vProjectScreenshot" name="project-portfolio-video-label" id="project-portfolio-video-label" class="custom-label">Project Screenshot</label>
            <mat-form-field hideRequiredMarker name="company-details-image-input" id="company-details-image-input" class="custom-input visibility-input" floatPlaceholder="never" floatLabel="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box full-width" *ngIf="mediaArray.length <= 5">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngFor="let file of mediaArray">
                        {{ file.name }} <button type="button" name="button" (click)="crossClick(file)" class="btn"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="company-details-image-vImages-1" id="company-details-image-vImages-1" matInput readonly formControlName="vProjectScreenshot" [value]="mediaArray.length > 0 ? ' ' : ''" placeholder="Upload Image" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="company-details-image-vImages-2"
                id="company-details-image-vImages-2"
                class="file-upload-input"
                (change)="FileBrowseHandler($event,1)"
                formControlName="vProjectScreenshot"
                accept="image/*"
                multiple
              />
              <button name="company-details-image-upload-btn" id="company-details-image-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <div class="input-details-box">
                <label class="text">Max 5 images (4MB each)</label>
              </div>
            </mat-form-field>
          </div>
          <div name="certification-class" id="certification-class" class="col-12 mt-2" *ngIf="editPortfolioData.UserPortfolioMedia.length > 0">
            <div name="certification-class-1" id="certification-1" class="image-display-container pb-2" *ngFor="let ele of editPortfolioData.UserPortfolioMedia;let i=index">
              <div name="award-1" id="award-1" class="image-name">
                <!-- <span></span> -->
                <label>{{i+1}})</label>
                <img class="image-thumbnail" [src]="ele?.vUrl" alt="images">
                <span>{{ele?.fileName}}</span>
              </div>
              <button type="button" name="button" id="award-1-btn" (click)="deleteThumbnail(ele,i)">
                <img id="award-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
              </button>
            </div>
          </div>
          <div name="project-portfolio-col-11" id="project-portfolio-col-11" class="col-12">
            <label for="vDescription" name="project-portfolio-col-11-label" id="project-portfolio-col-11-label" class="custom-label">Description <span class="text-danger">*</span></label>
            <mat-form-field floatPlaceholder="never" floatLabel="never" name="mat-form-field-company-bio" id="mat-form-field-company-bio" class="custom-input textarea">
              <textarea matInput rows="7" name="vDescription" id="vDescription" formControlName="vDescription" placeholder="Type here" #vDescription></textarea>
              <span name="bio-character-count-1" id="bio-character-count-1" class="bio-character-counter"><span name="bio-character-count-2" id="bio-character-count-2" class="me-1" [ngClass]="{'exceeded': vDescription.value.length < 150}">{{vDescription.value.length}}</span> / 150</span>
              <mat-error name="mat-error-company-bio" id="mat-error-company-bio" *ngIf="projectPortfolioEditForm.controls['vDescription'].invalid">
                <mat-error name="mat-error-company-bio-text-1" id="mat-error-company-bio-text-1" *ngIf="projectPortfolioEditForm.controls['vDescription'].errors!.required">
                  Description is required
                </mat-error>
                <mat-error name="mat-error-company-bio-text-2" id="mat-error-company-bio-text-2" *ngIf="projectPortfolioEditForm.controls['vDescription'].errors!.minlength">
                  Must have minimum 150 characters
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="project-portfolio-col-11" id="project-portfolio-col-11" class="col-12">
            <label for="vMyContribution" name="project-portfolio-col-11-label" id="project-portfolio-col-11-label" class="custom-label">My Contribution <span class="text-danger">*</span></label>
            <mat-form-field floatPlaceholder="never" floatLabel="never" name="mat-form-field-company-bio" id="mat-form-field-company-bio" class="custom-input textarea">
              <textarea matInput rows="7" name="vMyContribution" id="vMyContribution" formControlName="vMyContribution" placeholder="Type here" #vMyContribution></textarea>
              <span name="bio-character-count-1" id="bio-character-count-1" class="bio-character-counter"><span name="bio-character-count-2" id="bio-character-count-2" class="me-1" [ngClass]="{'exceeded': vMyContribution.value.length < 150}">{{vMyContribution.value.length}}</span> / 150</span>
              <mat-error name="mat-error-company-bio" id="mat-error-company-bio" *ngIf="projectPortfolioEditForm.controls['vMyContribution'].invalid">
                <mat-error name="mat-error-company-bio-text-1" id="mat-error-company-bio-text-1" *ngIf="projectPortfolioEditForm.controls['vMyContribution'].errors!.required">
                  My Contribution is required
                </mat-error>
                <mat-error name="mat-error-company-bio-text-2" id="mat-error-company-bio-text-2" *ngIf="projectPortfolioEditForm.controls['vMyContribution'].errors!.minlength">
                  Must have minimum 150 characters
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="project-portfolio-col-7" id="project-portfolio-col-7" class="col-12">
            <label for="vTechnology" name="project-portfolio-col-7-label" id="project-portfolio-col-7-label" class="custom-label">Technology <span class="text-danger">*</span></label>
          
            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never"
            name="project-portfolio-input" id="project-portfolio-input"
            class="custom-dropdown custom-input form">
              <mat-chip-list #technologyChipList2 aria-label="Technology selection" class="custom-multi-chip-dropdown"
                formControlName="vTechnology">
                <mat-chip *ngFor="let technology of technologies" [removable]="true" (removed)="OnTechnologyRemoved(technology)">
                  {{technology.vTechnologyName}}
                  <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input matInput #technologyInput2 (keyup)="_filterTechnology(technologyInput2.value)" [matAutocomplete]="autoTechnology"
                  [matChipInputFor]="technologyChipList2" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="technologyInput2.value = ''">
              </mat-chip-list>
              <mat-autocomplete #autoTechnology="matAutocomplete" [displayWith]="DisplayFnTechnology"
                (optionSelected)="AddTechnology($event.option.value); technologyInput2.value = ''">
                <mat-option *ngFor="let technology of filterTechnologies" [value]="technology">
                  {{technology.vTechnologyName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="professional-details-col-7-error-0" id="professional-details-col-7-error-0" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vTechnology'].invalid">
                <mat-error name="professional-details-col-7-error-1" id="professional-details-col-7-error-1" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vTechnology'].errors?.required">
                  Technology is required
                </mat-error>
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="project-portfolio-col-7-select" id="project-portfolio-col-7-select" class="custom-dropdown form">
              <mat-label>Select Technology</mat-label>
              <mat-select panelClass="custom-multi-select-dropdown" name="professional-details-vTechnology" id="professional-details-vTechnology" formControlName="vTechnology" multiple [compareWith]="MultiSelectTechnologyCompareFn">
                <mat-select-trigger>
                  <mat-chip-list>
                    <mat-chip *ngFor="let technology of projectPortfolioEditForm.controls['vTechnology'].value"
                      [removable]="true" (removed)="OnTechnologyRemoved(technology)">
                      {{ technology.vTechnologyName }}
                      <mat-icon matChipRemove>close</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </mat-select-trigger>
                <mat-option *ngFor="let technology of projectTechnologyListArray" [value]="technology">{{technology.vTechnologyName}}</mat-option>
              </mat-select>
              <mat-error name="professional-details-col-7-error-0" id="professional-details-col-7-error-0" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vTechnology'].invalid">
                <mat-error name="professional-details-col-7-error-1" id="professional-details-col-7-error-1" class="reduce-bottom-space" *ngIf="projectPortfolioEditForm.controls['vTechnology'].errors?.required">
                  Technology is required
                </mat-error>
              </mat-error>
            </mat-form-field> -->
            
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <div name="project-portfolio-modal-footer" id="project-portfolio-modal-footer" class="modal-footer">
    <button type="submit" name="project-portfolio-modal-footer-btn-submit" id="project-portfolio-modal-footer-btn-submit" class="btn btn-active" form="projectPortfolioEditForm">Submit</button>
    <button type="button" name="project-portfolio-modal-footer-btn-cancel" id="project-portfolio-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="d('Cross click')">Cancel</button>
  </div>
</ng-template>
