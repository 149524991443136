<div class="container-fluid interview-table skill-management">
    <div class="row">
      <div class="col-12">
        <div class="wrapper">

          <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="title" role="tabpanel" aria-labelledby="skill-management-tab">

              <div class="pagetitle-wrapper">
                <div class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                  <div class="d-flex align-items-center">
                    <i class="pagetitle-icon"><span class="material-icons">ballot</span></i>
                    <h2 class="pagetitle">Interview</h2>
                  </div>
          
                </div>
                <div class="d-flex">
                <mat-form-field class="w-75" floatLabel="never" class="table-filter">
                  <button matSuffix mat-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <input matInput placeholder="Search Interview" (keyup)="applyFilterOnSearch($event)" />
                </mat-form-field>
                <mat-form-field class="table-filter w-25">
                  <mat-select placeholder="Select Interview Status" [(value)]="filterType" (selectionChange)="GetInterview()">
                    <mat-option value="All">All</mat-option>
                      <mat-option *ngFor="let interview of interviewStatus" [value]="interview.value">
                        {{interview.viewValue}}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
                </div>
                <div class="table-responsive">
                  <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                  </div>
                  <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }" [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                    <ng-container matColumnDef="indexnumber">
                      <th mat-header-cell *matHeaderCellDef> No </th>
                      <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableOnePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.TableOnePaginator.pageIndex * this.TableOnePaginator.pageSize}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vEmpName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Employee Name </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.vEmpFirstName}} {{element.vEmpLastName}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vEmpDesignation">
                        <th mat-header-cell *matHeaderCellDef> Designation </th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{element.vEmpDesignation}}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="fEmpExperience">
                        <th mat-header-cell *matHeaderCellDef> Experience </th>
                        <td mat-cell *matCellDef="let element">
                            <!-- <span>{{element.fEmpExperience}}</span> -->
                            <span  *ngIf="element.fEmpExperience >= 1">
                                {{ (element.fEmpExperience).toString().split(".")[0] }}+
                              </span>
                              <span *ngIf="element.fEmpExperience < 1">
                                {{ (element.fEmpExperience * 10) | number:'1.1-2' }}+
                              </span>


                        </td>
                      </ng-container>
                
                      <ng-container matColumnDef="iDateEpoch">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Interview Time </th>
                        <td mat-cell *matCellDef="let element">
                          <span>{{element.iDateEpoch * 1000 | date:'dd MMMM, yyyy'}} from {{element.iStartTime * 1000 | date:'h:mm a'}} to {{element.iEndTime * 1000 | date:'h:mm a'}}</span>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="iRoundNumber">
                        <th mat-header-cell *matHeaderCellDef> Round</th>
                        <td mat-cell *matCellDef="let element">
                          <span *ngIf="element.iRoundNumber==1">1st</span>
                          <span *ngIf="element.iRoundNumber==2">2nd</span>
                          </td>
                      </ng-container>

                      <ng-container matColumnDef="vClientCompanyName">
                        <th mat-header-cell *matHeaderCellDef> Client Name </th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{element.vClientCompanyName}}</span>
                        </td>
                      </ng-container>
                     
                      <ng-container matColumnDef="vPartnerCompanyName">
                        <th mat-header-cell *matHeaderCellDef> Partner Name </th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{element.vPartnerCompanyName}}</span>
                        </td>
                      </ng-container>
                     
                      <ng-container matColumnDef="tiInterviewStatus">
                        <th mat-header-cell *matHeaderCellDef> Interview Status </th>
                        <td mat-cell *matCellDef="let element" style="min-width: 320px">
                          <mat-chip-list *ngFor="let obj of interviewStatus">
                            <mat-chip *ngIf="obj.value==element.tiInterviewStatus" [ngClass]="{'green-text':element.tiInterviewStatus=='1' || element.tiInterviewStatus=='7', 'pink-text': element.tiInterviewStatus=='5' || element.tiInterviewStatus=='10' || element.tiInterviewStatus=='2'}">
                              {{obj.viewValue}}
                            </mat-chip>
                          </mat-chip-list>
                        </td>
                      </ng-container>

                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> Actions </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex flex-wrap">
                          <div placement="left auto" [ngbTooltip]="accessVideoConferencePermission ? 'Join Conference':'Not Authorized'" [ngClass]="accessVideoConferencePermission ? '':'unauthorized-cursor'">
                            <button class="status-btn lightblue-bg blue-text text-center mb-2" [ngClass]="accessVideoConferencePermission ? '':'unauthorized-action'" routerLink="/video-conference/{{element.iInterviewDetailId}}" *ngIf="element.tiInterviewStatus == 6">
                              <mat-icon>video_call</mat-icon>
                            </button>
                          </div>
                          <span *ngIf="element.tiInterviewStatus != 6" class="status-btn lightgray-bg gray-text"><span
                            class="material-icons me-1">hide_source</span>No Action</span>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                  </table>
                </div>
                <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }" #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
                </mat-paginator>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <ng-template #viewInterview let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">View Interview Detail</h1>
          <div class="card">
            <div class="image-box">
              <img class="card-image" src="" alt="Profile Image" />
              <div class="text-box">
                <label class="title">{{ apiResponse.vEmpFirstName }}</label>
                <label class="sub-title">{{ apiResponse.vEmpDesignation }}
                  <span class="text-highlight" *ngIf="employeeData.fEmpExperience >= 1">
                    ({{ apiResponse.fEmpExperience.toString().split(".")[0] }}+)
                  </span>
                  <span class="text-highlight" *ngIf="employeeData.fEmpExperience < 1">
                    ({{ apiResponse.fEmpExperience * 10 | number: "1.1-2" }}+)
                  </span>
                </label>
                <label class="text">{{ apiResponse.vEmpDisplayUniqueId }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>


