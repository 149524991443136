import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ViewTalentService } from "../view-talent.service";
import { ProfessionalDetailsService } from "./professional-details.service";
import { map, startWith } from "rxjs/operators";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { Observable } from "rxjs";
import { NotificationService } from "src/app/config/notification.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { environment } from "../../../../../environments/environment";
import { UserPermissionService } from "src/app/utils/services/user-permission.service";
import { Permission } from "src/app/utils/enums/permission.enum";

@Component({
  selector: "talent-professional-details",
  templateUrl: "./professional-details.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class TalentProfessionalDetailsComponent implements OnInit {
  @Input() talentUserId:any;
  professionalDetailsToggle:boolean=false;

  editTalentProfessionalDetailsActionPermission: boolean = false;

  imagePath = environment.image_path;
  filteredOptions!: Observable<any[]>;
  filteredCmpOptions!: Observable<any[]>;

  professionalDetailsForm!: FormGroup;
  professionalDetailsSubmitted: any = false;

  designationList: any = [];
  designationUpdatedList: any = [];
  technologyList: any = [];
  skillList: any = [];
  languageList: any = [];
  professionalDetailsArray: any = [];
  monday: any;
  tuesday: any;
  wednesday: any;
  thursday: any;
  friday: any;
  saturday: any;
  sunday: any;
  iPrimarySkillId: any;
  selectedSkills: any = [];
  iCmpDesignationId: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  skills: any = [];
  allSkills: any = [];
  filterSkills: any;

  technologies: any = [];
  allTechnologies: any = [];
  filterTechnologies: any;

  languages: any = [];
  allLanguages: any = [];
  filterLanguages: any;

  monthOptions: any = [
    { id: 0, value: 0 },
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
    { id: 9, value: 9 },
    { id: 10, value: 10 },
    { id: 11, value: 11 },
  ];
  yearOptions: any = [
    { id: 0, value: 0 },
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
    { id: 9, value: 9 },
    { id: 10, value: 10 },
    { id: 11, value: 11 },
    { id: 12, value: 12 },
    { id: 13, value: 13 },
    { id: 14, value: 14 },
    { id: 15, value: 15 },
    { id: 16, value: 16 },
    { id: 17, value: 17 },
    { id: 18, value: 18 },
    { id: 19, value: 19 },
    { id: 20, value: 20 },
    { id: 21, value: 21 },
    { id: 22, value: 22 },
    { id: 23, value: 23 },
    { id: 24, value: 24 },
    { id: 25, value: 25 },
    { id: 26, value: 26 },
    { id: 27, value: 27 },
    { id: 28, value: 28 },
    { id: 29, value: 29 },
    { id: 30, value: 30 },
    { id: 31, value: 31 },
    { id: 32, value: 32 },
    { id: 33, value: 33 },
    { id: 34, value: 34 },
    { id: 35, value: 35 },
    { id: 36, value: 36 },
    { id: 37, value: 37 },
    { id: 38, value: 38 },
    { id: 39, value: 39 },
    { id: 40, value: 40 },
    { id: 41, value: 41 },
    { id: 42, value: 42 },
    { id: 43, value: 43 },
    { id: 44, value: 44 },
    { id: 45, value: 45 },
    { id: 46, value: 46 },
    { id: 47, value: 47 },
    { id: 48, value: 48 },
    { id: 49, value: 49 },
    { id: 50, value: 50 },
  ];

  constructor(
    config: NgbModalConfig,
    private userPermissionService: UserPermissionService,
    private modalService: NgbModal,
    private talentService: ViewTalentService,
    private professionalDetailsService: ProfessionalDetailsService,
    public eventEmitterService: EventEmitterService,
    private notifyService: NotificationService
  ) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const EditTalentProfessionalDetailsAction: Permission = Permission.EditTalentProfessionalDetailsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(EditTalentProfessionalDetailsAction)) {
      this.editTalentProfessionalDetailsActionPermission = true;
    }

    this.filterSkills = this.skillList;
    this.filterTechnologies = this.technologyList;
    this.filterLanguages = this.languageList;
  }

  ngOnInit(): void {
    this.GetTechnologyList();
    this.GetPrimarySkillList();
    this.GetDesignationList();
    this.GetLangaugeList();
    this.GetProfessionalDetails();
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });

    this.professionalDetailsForm = new FormGroup({
      vEmpDesignation: new FormControl("", [Validators.required]),
      iEmpExYears: new FormControl("", [Validators.required]),
      iEmpExMonths: new FormControl("", [Validators.required]),
      vCmpDesignation: new FormControl("", [Validators.required]),
      iTechnology: new FormControl("", [Validators.required]),
      iSkills: new FormControl("", [Validators.required]),
      iLanguage: new FormControl("", [Validators.required]),
      vMondayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vMondayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vMondayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vMondayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vTuesdayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vTuesdayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vTuesdayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vTuesdayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vWednesdayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vWednesdayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vWednesdayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vWednesdayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vThursdayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vThursdayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vThursdayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vThursdayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vFridayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vFridayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vFridayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vFridayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vSaturdayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vSaturdayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vSaturdayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vSaturdayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vSundayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vSundayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vSundayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vSundayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      iMondayStatus: new FormControl("", [Validators.required]),
      iTuesdayStatus: new FormControl("", [Validators.required]),
      iWednesdayStatus: new FormControl("", [Validators.required]),
      iThursdayStatus: new FormControl("", [Validators.required]),
      iFridayStatus: new FormControl("", [Validators.required]),
      iSaturdayStatus: new FormControl("", [Validators.required]),
      iSundayStatus: new FormControl("", [Validators.required]),
    });
    this.filteredOptions = this.professionalDetailsForm.controls["vEmpDesignation"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value) : this.designationList.slice()))
    );
    this.filteredCmpOptions = this.professionalDetailsForm.controls["vCmpDesignation"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filterDesignation(value) : this.designationUpdatedList.slice()))
    );
  }

  public professionalDetailsCardCompleted: any = false;
  updateProfileStatus(data: any) {
    this.professionalDetailsCardCompleted = data["professionalDetailsCardCompleted"];
  }

  private _filter(value: string): string[] {
    let filterValue: string;
    if (value) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = "";
    }

    return this.designationList.filter((option: any) => option.vPrimarySkill.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterDesignation(value: string): string[] {
    let filterValue: string;
    if (value) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = "";
    }

    return this.designationUpdatedList.filter((option: any) => option.vCmpDesignation.toLowerCase().indexOf(filterValue) === 0);
  }

  // MultiSelectTechnologyCompareFn(item1: any, item2: any): boolean {
  //   return item1 && item2 ? item1.iMasterTechnologyId === item2.iMasterTechnologyId : item1 === item2;
  // }

  // MultiSelectSkillCompareFn(item1: any, item2: any): boolean {
  //   return item1 && item2 ? item1.iSkillId === item2.iSkillId : item1 === item2;
  // }

  // MultiSelectLanguageCompareFn(item1: any, item2: any): boolean {
  //   return item1 && item2 ? item1.iLanguageId === item2.iLanguageId : item1 === item2;
  // }

  // OnTechnologyRemoved(technology: string) {
  //   const technologies = this.professionalDetailsForm.value.iTechnology as string[];
  //   this.removeFirst(technologies, technology);
  //   this.professionalDetailsForm.controls.iTechnology.setValue(technologies); // To trigger change detection
  // }

  OnSkillRemoved(skill: string) {
    const skills = this.skills;
    this.removeFirst(skills, skill);
    // this.SkillRemoved(skill);
    this.skills = skills; // To trigger change detection

    this.skillList.push(skill);

    if (this.skills.length == 0) {
      this.professionalDetailsForm.controls["iSkills"].setValue("");
    }
  }

  // Add talent
  AddSkill(skill: any): void {
    if (skill) {
      let index = this.skills.findIndex((x: any) => x.iSkillId === skill.iSkillId);
      if (index > -1) {
        return;
      } else {
        const vName = skill.vName;
        const iSkillId = skill.iSkillId;
        // Add our talent
        if (vName) {
          // const index = this.allSkills.indexOf(skill);
          this.skills.push({ vName: vName.trim(), iSkillId: iSkillId });
        }
        this.professionalDetailsForm.controls["iSkills"].setValue(this.skills);
      }
      this.filterSkills = this.skillList;
    }
  }

  DisplayFnTalent(skill: any): string {
    return skill && skill.vName ? skill.vName : "";
  }

  _filterTalent(name: string): any {
    // console.log(name);

    const filterValue = name?.toLowerCase();
    this.filterSkills = this.skillList;

    this.filterSkills = this.filterSkills.filter((skill: { vName: string }) => skill.vName.toLowerCase().startsWith(filterValue));
  }

  // OnLanguageRemoved(language: string) {
  //   const languages = this.professionalDetailsForm.value.iLanguage as string[];
  //   this.removeFirst(languages, language);
  //   this.professionalDetailsForm.controls.iLanguage.setValue(languages); // To trigger change detection
  // }

  // private removeFirst<T>(array: T[], toRemove: T): void {
  //   const index = array.indexOf(toRemove);
  //   if (index !== -1) {
  //     array.splice(index, 1);
  //   }
  // }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  OnTechnologyRemoved(technology: string) {
    const technologies = this.technologies;
    this.removeFirst(technologies, technology);
    this.technologies = technologies; // To trigger change detection
    this.technologyList.push(technology);
    if (this.technologies.length == 0) {
      this.professionalDetailsForm.controls["iTechnology"].setValue("");
    }
  }

  AddTechnology(technology: any): void {
    if (technology) {
      let index = this.technologies.findIndex((x: any) => x.iMasterTechnologyId === technology.iMasterTechnologyId);
      if (index > -1) {
        return;
      } else {
        const iMasterTechnologyId = technology.iMasterTechnologyId;
        const state = technology.state;
        const vTechnologyName = technology.vTechnologyName;
        if (vTechnologyName) {
          this.technologies.push({ iMasterTechnologyId: iMasterTechnologyId, vTechnologyName: vTechnologyName.trim() });
        }
        this.professionalDetailsForm.controls["iTechnology"].setValue(this.technologies);
      }
      this.filterTechnologies = this.technologyList;
    }
  }

  DisplayFnTechnology(technology: any): string {
    return technology && technology.vTechnologyName ? technology.vTechnologyName : "";
  }

  _filterTechnology(name: string): any {
    const filterValue = name?.toLowerCase();
    this.filterTechnologies = this.technologyList;
    this.filterTechnologies = this.filterTechnologies.filter((technology: { vTechnologyName: string }) => technology.vTechnologyName.toLowerCase().startsWith(filterValue));
  }

  OnLanguageRemoved(language: string) {
    const languages = this.languages;
    this.removeFirst(languages, language);
    this.languages = languages; // To trigger change detection
    this.languageList.push(language);
    if (this.languages.length == 0) {
      this.professionalDetailsForm.controls["iLanguage"].setValue("");
    }
  }

  AddLanguage(language: any): void {
    if (language) {
      let index = this.languages.findIndex((x: any) => x.iLanguageId === language.iLanguageId);
      if (index > -1) {
        return;
      } else {
        const iLanguageId = language.iLanguageId;
        const state = language.state;
        const vLanguageName = language.vLanguageName;
        if (vLanguageName) {
          this.languages.push({ iLanguageId: iLanguageId, state: state, vLanguageName: vLanguageName.trim() });
        }
        this.professionalDetailsForm.controls["iLanguage"].setValue(this.languages);
      }
      this.filterLanguages = this.languageList;
    }
  }

  DisplayFnLanguage(language: any): string {
    return language && language.vLanguageName ? language.vLanguageName : "";
  }

  _filterLanguage(name: string): any {
    const filterValue = name?.toLowerCase();
    this.filterLanguages = this.languageList;
    this.filterLanguages = this.filterLanguages.filter((language: { vLanguageName: string }) => language.vLanguageName.toLowerCase().startsWith(filterValue));
  }

  GetTechnologyList() {
    this.talentService.GetTechnologyList().then((result: any) => {
      if (result.code == 200) {
        this.technologyList = result["data"];
      }
    });
  }

  GetPrimarySkillList() {
    this.talentService.GetPrimarySkillList().then((result: any) => {
      if (result.code == 200) {
        this.designationList = result["data"];
      }
    });
  }

  GetSecondarySkillList(id: any) {
    this.talentService.GetSecondarySkillList(id).then((result: any) => {
      if (result.code == 200) {
        this.skillList = result["data"];
      }
    });
  }

  GetLangaugeList() {
    this.talentService.GetLangaugeList().then((result: any) => {
      if (result.code == 200) {
        this.languageList = result["data"];
      }
    });
  }

  OnRatingSet(rating: any, index: any) {
    this.skills[index].rating = rating;
  }

  GetProfessionalDetails() {
    this.professionalDetailsService.GetProfessionalDetails(this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        let month;
        if (result.data.vExperienceMonth == "01") {
          month = 1;
        } else if (result.data.vExperienceMonth == "1") {
          month = 10;
        } else {
          month = Number(result.data.vExperienceMonth);
        }
        let languagesName: any = [];
        let skillsName: any = [];
        let technologiesName: any = [];
        result["data"]["iLanguage"].forEach((element: any) => {
          languagesName.push(element.vLanguageName);
        });
        result["data"]["iSkills"].forEach((element: any) => {
          skillsName.push(element.vName);
        });
        if (result["data"]["iTechnology"].length > 0) {
          result["data"]["iTechnology"].forEach((element: any) => {
            technologiesName.push(element.vTechnologyName);
          });
        }
        result["data"]["languagesName"] = languagesName.toString().replace(/,/g, ", ");
        result["data"]["skillsName"] = skillsName.toString().replace(/,/g, ", ");
        result["data"]["technologiesName"] = technologiesName.length > 0 ? technologiesName.toString().replace(/,/g, ", ") : result["data"]["iTechnology"];

        this.monday = (result.data.tMondayStart + ":" + result.data.tMondayEnd).split(":");
        this.tuesday = (result.data.tTuesdayStart + ":" + result.data.tTuesdayEnd).split(":");
        this.wednesday = (result.data.tWednesdayStart + ":" + result.data.tWednesdayEnd).split(":");
        this.thursday = (result.data.tThursdayStart + ":" + result.data.tThursdayEnd).split(":");
        this.friday = (result.data.tFridayStart + ":" + result.data.tFridayEnd).split(":");
        this.saturday = (result.data.tSaturdayStart + ":" + result.data.tSaturdayEnd).split(":");
        this.sunday = (result.data.tSundayStart + ":" + result.data.tSundayEnd).split(":");
        this.iPrimarySkillId = result.data.vEmpDesignation?.iPrimarySkillId;
        this.iCmpDesignationId = result.data.vCmpDesignation?.iCmpDesignationId;
        this.professionalDetailsForm.patchValue({
          vEmpDesignation: result.data.vEmpDesignation?.vPrimarySkill,
          vCmpDesignation: result.data.vCmpDesignation?.vCmpDesignation,
          iEmpExYears: result.data.vExperienceYear,
          iEmpExMonths: month == 0 ? result.data.vExperienceMonth : month,
          iTechnology: result.data.iTechnology,
          iSkills: result.data.iSkills,
          iLanguage: result.data.iLanguage,
          vMondayStartHour: this.monday[0],
          vMondayStartMinute: this.monday[1],
          vMondayEndHour: this.monday[3],
          vMondayEndMinute: this.monday[4],
          vTuesdayStartHour: this.tuesday[0],
          vTuesdayStartMinute: this.tuesday[1],
          vTuesdayEndHour: this.tuesday[3],
          vTuesdayEndMinute: this.tuesday[4],
          vWednesdayStartHour: this.wednesday[0],
          vWednesdayStartMinute: this.wednesday[1],
          vWednesdayEndHour: this.wednesday[3],
          vWednesdayEndMinute: this.wednesday[4],
          vThursdayStartHour: this.thursday[0],
          vThursdayStartMinute: this.thursday[1],
          vThursdayEndHour: this.thursday[3],
          vThursdayEndMinute: this.thursday[4],
          vFridayStartHour: this.friday[0],
          vFridayStartMinute: this.friday[1],
          vFridayEndHour: this.friday[3],
          vFridayEndMinute: this.friday[4],
          vSaturdayStartHour: this.saturday[0],
          vSaturdayStartMinute: this.saturday[1],
          vSaturdayEndHour: this.saturday[3],
          vSaturdayEndMinute: this.saturday[4],
          vSundayStartHour: this.sunday[0],
          vSundayStartMinute: this.sunday[1],
          vSundayEndHour: this.sunday[3],
          vSundayEndMinute: this.sunday[4],
          iMondayStatus: result["data"].iMondayStatus,
          iTuesdayStatus: result["data"].iTuesdayStatus,
          iWednesdayStatus: result["data"].iWednesdayStatus,
          iThursdayStatus: result["data"].iThursdayStatus,
          iFridayStatus: result["data"].iFridayStatus,
          iSaturdayStatus: result["data"].iSaturdayStatus,
          iSundayStatus: result["data"].iSundayStatus,
        });
        this.professionalDetailsArray = result["data"];
        this.GetSecondarySkillList(this.professionalDetailsArray.vEmpDesignation?.iPrimarySkillId);
        if (!("vExperienceMonth" in result.data)) {
          this.professionalDetailsForm.controls["iEmpExMonths"].setValue(this.monthOptions[0].value);
        }
        this.skills = result.data.iSkills;
        this.technologies = result.data.iTechnology;
        this.languages = result.data.iLanguage;
      }
    });
  }

  copyToAll() {
    this.professionalDetailsForm.patchValue({
      vTuesdayStartHour: this.professionalDetailsForm.value.vMondayStartHour,
      vTuesdayStartMinute: this.professionalDetailsForm.value.vMondayStartMinute,
      vTuesdayEndHour: this.professionalDetailsForm.value.vMondayEndHour,
      vTuesdayEndMinute: this.professionalDetailsForm.value.vMondayEndMinute,
      vWednesdayStartHour: this.professionalDetailsForm.value.vMondayStartHour,
      vWednesdayStartMinute: this.professionalDetailsForm.value.vMondayStartMinute,
      vWednesdayEndHour: this.professionalDetailsForm.value.vMondayEndHour,
      vWednesdayEndMinute: this.professionalDetailsForm.value.vMondayEndMinute,
      vThursdayStartHour: this.professionalDetailsForm.value.vMondayStartHour,
      vThursdayStartMinute: this.professionalDetailsForm.value.vMondayStartMinute,
      vThursdayEndHour: this.professionalDetailsForm.value.vMondayEndHour,
      vThursdayEndMinute: this.professionalDetailsForm.value.vMondayEndMinute,
      vFridayStartHour: this.professionalDetailsForm.value.vMondayStartHour,
      vFridayStartMinute: this.professionalDetailsForm.value.vMondayStartMinute,
      vFridayEndHour: this.professionalDetailsForm.value.vMondayEndHour,
      vFridayEndMinute: this.professionalDetailsForm.value.vMondayEndMinute,
      vSaturdayStartHour: this.professionalDetailsForm.value.vMondayStartHour,
      vSaturdayStartMinute: this.professionalDetailsForm.value.vMondayStartMinute,
      vSaturdayEndHour: this.professionalDetailsForm.value.vMondayEndHour,
      vSaturdayEndMinute: this.professionalDetailsForm.value.vMondayEndMinute,
      vSundayStartHour: this.professionalDetailsForm.value.vMondayStartHour,
      vSundayStartMinute: this.professionalDetailsForm.value.vMondayStartMinute,
      vSundayEndHour: this.professionalDetailsForm.value.vMondayEndHour,
      vSundayEndMinute: this.professionalDetailsForm.value.vMondayEndMinute,
      iTuesdayStatus: this.professionalDetailsForm.value.iMondayStatus,
      iWednesdayStatus: this.professionalDetailsForm.value.iMondayStatus,
      iThursdayStatus: this.professionalDetailsForm.value.iMondayStatus,
      iFridayStatus: this.professionalDetailsForm.value.iMondayStatus,
      iSaturdayStatus: this.professionalDetailsForm.value.iMondayStatus,
      iSundayStatus: this.professionalDetailsForm.value.iMondayStatus,
    });
  }

  GetPrimarySkillId(iPrimarySkillId: any) {
    // console.log("this.iPrimarySkillId>>>>>>>>>>>>>",this.iPrimarySkillId);
    // console.log("iPrimarySkillId",iPrimarySkillId);
    this.iPrimarySkillId = iPrimarySkillId;
    this.GetSecondarySkillList(iPrimarySkillId);
  }

  GetCmpDesignation(iCmpDesignationId:any){
    this.iCmpDesignationId=iCmpDesignationId;
  }

  UploadProfessionalDetails() {
    // console.log("on submit button call >>>>>>>>>>>>>>>>>>>>.");
    if (this.professionalDetailsForm.invalid) {
      return;
    }

    if(this.iPrimarySkillId){ 
    }else{
      this.notifyService.showError("Please select Designation from the list", "");
      this.professionalDetailsForm.controls["vEmpDesignation"].setValue('');
      return;
    }
    if (this.iCmpDesignationId) {
    } else {
      this.notifyService.showError("Please select Designation from the list", "");
      this.professionalDetailsForm.controls["vCmpDesignation"].setValue('');
      return;
    }

    if (this.professionalDetailsForm.controls["vMondayStartHour"]?.value >= this.professionalDetailsForm.controls["vMondayEndHour"]?.value) {
      return;
    } else if (this.professionalDetailsForm.controls["vTuesdayStartHour"]?.value >= this.professionalDetailsForm.controls["vTuesdayEndHour"]?.value) {
      return;
    } else if (this.professionalDetailsForm.controls["vWednesdayStartHour"]?.value >= this.professionalDetailsForm.controls["vWednesdayEndHour"]?.value) {
      return;
    } else if (this.professionalDetailsForm.controls["vThursdayStartHour"]?.value >= this.professionalDetailsForm.controls["vThursdayEndHour"]?.value) {
      return;
    } else if (this.professionalDetailsForm.controls["vFridayStartHour"]?.value >= this.professionalDetailsForm.controls["vFridayEndHour"]?.value) {
      return;
    } else if (this.professionalDetailsForm.controls["vSaturdayStartHour"]?.value >= this.professionalDetailsForm.controls["vSaturdayEndHour"]?.value) {
      return;
    } else if (this.professionalDetailsForm.controls["vSundayStartHour"]?.value >= this.professionalDetailsForm.controls["vSundayEndHour"]?.value) {
      return;
    }

    let vExperienceLevel;
    if (this.professionalDetailsForm.value.iEmpExYears >= 0 || this.professionalDetailsForm.value.iEmpExMonths >= 0) {
      if (this.professionalDetailsForm.value.iEmpExYears >= 0 && this.professionalDetailsForm.value.iEmpExMonths >= 0) {
        if (this.professionalDetailsForm.value.iEmpExMonths < 10) {
          vExperienceLevel = "0" + this.professionalDetailsForm.value.iEmpExMonths;
        } else {
          vExperienceLevel = this.professionalDetailsForm.value.iEmpExMonths;
        }
        vExperienceLevel = this.professionalDetailsForm.value.iEmpExYears + "." + vExperienceLevel;
      } else if (this.professionalDetailsForm.value.iEmpExYears > 0) {
        vExperienceLevel = this.professionalDetailsForm.value.iEmpExYears;
      } else if (this.professionalDetailsForm.value.iEmpExMonths > 0) {
        if (this.professionalDetailsForm.value.iEmpExMonths < 10) {
          vExperienceLevel = "0" + this.professionalDetailsForm.value.iEmpExMonths;
        } else {
          vExperienceLevel = this.professionalDetailsForm.value.iEmpExMonths;
        }
        vExperienceLevel = 0 + "." + vExperienceLevel;
      }
    }
    this.professionalDetailsSubmitted = true;
    // console.log(this.professionalDetailsForm,">>>>>>>>>this.professionalDetailsForm");

    if (this.professionalDetailsForm.invalid) {
      return;
    }

    let technologyIds: any = [];
    let languageIds: any = [];

    this.professionalDetailsForm.value.iTechnology.forEach((element: any) => {
      technologyIds.push(element.iMasterTechnologyId);
    });

    this.professionalDetailsForm.value.iLanguage.forEach((element: any) => {
      languageIds.push(element.iLanguageId);
    });

    let reqData = {
      vEmpDesignation: this.iPrimarySkillId,
      vExperienceLevel: vExperienceLevel,
      vCmpDesignation: this.iCmpDesignationId,
      iTechnology: technologyIds,
      iSkills: this.skills,
      iLanguage: languageIds,
      tMondayStart: this.professionalDetailsForm.value.vMondayStartHour + ":" + this.professionalDetailsForm.value.vMondayStartMinute,
      tMondayEnd: this.professionalDetailsForm.value.vMondayEndHour + ":" + this.professionalDetailsForm.value.vMondayEndMinute,
      tTuesdayStart: this.professionalDetailsForm.value.vTuesdayStartHour + ":" + this.professionalDetailsForm.value.vTuesdayStartMinute,
      tTuesdayEnd: this.professionalDetailsForm.value.vTuesdayEndHour + ":" + this.professionalDetailsForm.value.vTuesdayEndMinute,
      tWednesdayStart: this.professionalDetailsForm.value.vWednesdayStartHour + ":" + this.professionalDetailsForm.value.vWednesdayStartMinute,
      tWednesdayEnd: this.professionalDetailsForm.value.vWednesdayEndHour + ":" + this.professionalDetailsForm.value.vWednesdayEndMinute,
      tThursdayStart: this.professionalDetailsForm.value.vThursdayStartHour + ":" + this.professionalDetailsForm.value.vThursdayStartMinute,
      tThursdayEnd: this.professionalDetailsForm.value.vThursdayEndHour + ":" + this.professionalDetailsForm.value.vThursdayEndMinute,
      tFridayStart: this.professionalDetailsForm.value.vFridayStartHour + ":" + this.professionalDetailsForm.value.vFridayStartMinute,
      tFridayEnd: this.professionalDetailsForm.value.vFridayEndHour + ":" + this.professionalDetailsForm.value.vFridayEndMinute,
      tSaturdayStart: this.professionalDetailsForm.value.vSaturdayStartHour + ":" + this.professionalDetailsForm.value.vSaturdayStartMinute,
      tSaturdayEnd: this.professionalDetailsForm.value.vSaturdayEndHour + ":" + this.professionalDetailsForm.value.vSaturdayEndMinute,
      tSundayStart: this.professionalDetailsForm.value.vSundayStartHour + ":" + this.professionalDetailsForm.value.vSundayStartMinute,
      tSundayEnd: this.professionalDetailsForm.value.vSundayEndHour + ":" + this.professionalDetailsForm.value.vSundayEndMinute,
      iMondayStatus: this.professionalDetailsForm.value.iMondayStatus,
      iTuesdayStatus: this.professionalDetailsForm.value.iTuesdayStatus,
      iWednesdayStatus: this.professionalDetailsForm.value.iWednesdayStatus,
      iThursdayStatus: this.professionalDetailsForm.value.iThursdayStatus,
      iFridayStatus: this.professionalDetailsForm.value.iFridayStatus,
      iSaturdayStatus: this.professionalDetailsForm.value.iSaturdayStatus,
      iSundayStatus: this.professionalDetailsForm.value.iSundayStatus,
    };
    this.professionalDetailsService
      .UploadProfessionalDetails(reqData,this.talentUserId)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.notifyService.showSuccess(result.message, "");
          this.GetProfessionalDetails();
          this.professionalDetailsForm.reset();
          this.eventEmitterService.GetProfileStatusAPICallMethod();
          this.professionalDetailsToggle=false;
        } else {
          // this.professionalDetailsToggle=false;
          this.notifyService.showError(result.message, "");

          // this.notifyService.showError(result.message,'');
        }
      })
      .catch((err) => {
        console.log(err, "UploadProfessionalDetails");
      });
  }



  toggleProfessionalDetails(hasPermission:boolean, element: any){
    if(hasPermission){
      this.GetProfessionalDetails();
    this.professionalDetailsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }


  GetDesignationList() {
    this.professionalDetailsService.GetDesignationList().then((result: any) => {
      if (result.code == 200) {
        this.designationUpdatedList = result["data"];
      }
    });
  }

}
