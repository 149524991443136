import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalConfig,NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../config/event-emitter.service';
import { ReasonsService } from './reasons.service';
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

interface Common {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-reasons',
  templateUrl: './reasons.component.html',
  styles: [
  ]
})
export class ReasonsComponent implements OnInit {

  reasonTypes: Common[] = [
        {value: 1, viewValue: 'Client Rejects Employee'},
        {value: 2, viewValue: 'Partner Cancels Interview'}
      ];
  
  addReasonForm!: FormGroup;
  editReasonForm!:FormGroup;

  apiResponse!:any;
  filterType:any='0';
  displayedColumnsTableOne: string[] = ['indexnumber','name','tiReasonType','iCreatedAt','actions'];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

  // toggle actions permission
  addReasonsActionPermission: boolean = false;
  editReasonsActionPermission: boolean = false;
  deleteReasonsActionPermission: boolean = false;

  constructor(
    private reasonService: ReasonsService,
    public eventEmitterService: EventEmitterService, 
    private modalService: NgbModal,
    config: NgbModalConfig, 
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  ) 
  {
    config.backdrop = 'static';
    config.keyboard = false;

    // assign individual permissions
    const AddReasonsAction: Permission = Permission.AddReasonsAction;
    const EditReasonsAction: Permission = Permission.EditReasonsAction;
    const DeleteReasonsAction: Permission = Permission.DeleteReasonsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddReasonsAction)) {
      this.addReasonsActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditReasonsAction)) {
      this.editReasonsActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeleteReasonsAction)) {
      this.deleteReasonsActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetReasons()
    this.addReasonForm = new FormGroup({
      ReasonName: new FormControl('', [Validators.required,Validators.minLength(3),Validators.maxLength(250)]),
      ReasonType: new FormControl('', [Validators.required])
    });

    this.editReasonForm = new FormGroup({
      id: new FormControl('', []), 
      editedReasonName: new FormControl('', [Validators.required,Validators.minLength(3),Validators.maxLength(250)]),
      editedReasonType: new FormControl('', [Validators.required])
    });
  }

  OpenModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  OpenModelEditReason(modelName:any, element:any, hasPermission: boolean) {
    if (hasPermission) {
      this.editReasonForm.patchValue({
        id: element['id'],
        editedReasonName: element['name'],
        editedReasonType: element['tiReasonType']
      })
      this.modalService.open(modelName, { centered: true });
    }
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  selectReasonType(){
    if(this.filterType=='0'){
      this.dataSourceTableOne.data=this.apiResponse;
    }else{
      let filteredData = this.apiResponse.filter((item:any)=>{
        return item.tiReasonType==this.filterType;
      });
      this.dataSourceTableOne.data=filteredData;
    }
  }

  GetReasons() {
    this.reasonService.GetReasons().then((result: any) => {
      if (result['code'] == 200) {
        this.apiResponse=result['data'];
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    }).catch((err: any) => {console.log(err)});
  }

  AddReason() {
    if (this.addReasonForm.invalid) {
      return;
    }
    let reqData = {
      name : this.addReasonForm.value.ReasonName,
      tiReasonType : this.addReasonForm.value.ReasonType
    } 
    this.reasonService.AddReason(reqData).then((result: any) => {
      if (result['code'] == 200) {
        this.addReasonForm.reset();
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message,'');
        this.GetReasons();
      } else {
        this.modalService.dismissAll();
        this.notifyService.showError(result.message,'');
      }
    }).catch((err: any) => {
      console.log(err, 'AddReason');
    });
  }

  EditReason() {
    if (this.editReasonForm.invalid) {
      return;
    } 
    let reqData = {
      id : this.editReasonForm.value.id,
      name : this.editReasonForm.value.editedReasonName,
      tiReasonType : this.editReasonForm.value.editedReasonType
    } 
    this.reasonService.EditReason(reqData).then((result: any) => {
      if (result['code'] == 200) {
        this.editReasonForm.reset();
        this.notifyService.showSuccess(result.message,'');
        this.modalService.dismissAll();
        this.GetReasons();
      } else {
        this.modalService.dismissAll();
        this.notifyService.showError(result.message,'');
      }
    }).catch((err: any) => {
      console.log(err, "EditReason");
    });
  }

  DeleteReason(item: any, hasPermission: boolean): void {
    if (hasPermission) {
      this.reasonService.DeleteReason(item.id).then((result: any) => {
        this.GetReasons();
        this.notifyService.showSuccess(result.message,'');
      }).catch((err: any) => {
        console.log(err, 'DeleteReason');
      })
    }
  }


}
