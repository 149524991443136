import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http:HttpClient) { }

  EditProfile(data:any,selectedImage:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/profile-update`;
      const formData: FormData = new FormData();
      formData.append("newImage", selectedImage);
      formData.append("firstName", data.vFirstName);    
      formData.append("lastName", data.vLastName);
      this.http
        .put(apiURL,formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetProfileInfo() {
    let apiURL = environment.baseUrl + `/admin/v1/user-profile`;
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }
}
