import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { EventEmitterService } from '../../config/event-emitter.service';
import { UserPermissionService } from "../../utils/services/user-permission.service";
import { CookieService } from "ngx-cookie-service";
import * as CryptoJS from 'crypto-js';
import { encode as base64Encode, decode as base64Decode } from "base64-arraybuffer";
import { TranslateFormService } from 'src/app/utils/services/translate-form.service';
import { NotificationService } from 'src/app/config/notification.service';
import { ReCaptchaV3Service } from "ng-recaptcha";

export interface ILoginAttempt {
  loginAttempts: number;
  timeToWait: number;
  wrongLoginAttemptCount?: number;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  hide: boolean = true;
  LoginForm: FormGroup;
  isLoginSubmit: boolean = false;

  cookieValue: string = "false";

  LoginAttemptJsonData: ILoginAttempt = {
    loginAttempts: 0,
    timeToWait: 0,
    wrongLoginAttemptCount: 1,
  };

  constructor(
    private tf: TranslateFormService,
    private router: Router,
    private loginService: LoginService,
    public ngxSmartModalService: NgxSmartModalService,
    public eventEmitterService: EventEmitterService,
    private userPermissionService: UserPermissionService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private cookieService: CookieService,
    private notifyService: NotificationService,
  ) {

    if (!this.cookieService.check("admin_user_login")) {
      let cookieData = CryptoJS.AES.encrypt(JSON.stringify(this.LoginAttemptJsonData), this.userPermissionService.GetEncryptionKey()).toString();
      this.cookieService.set("admin_user_login", cookieData);
    } else {
      let storedCookieData: any = this.cookieService.get("admin_user_login");
      const bytes = CryptoJS.AES.decrypt(storedCookieData, this.userPermissionService.GetEncryptionKey());
      if (bytes.toString()) {
        let check = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.LoginAttemptJsonData = {
          loginAttempts: check.loginAttempts,
          timeToWait: check.timeToWait,
          wrongLoginAttemptCount: check.wrongLoginAttemptCount,
        };
      }
    }

    
  }

  ngOnInit(): void {
    this.LoginForm = new FormGroup({
      vEmailId: new FormControl('', [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(70)]),
      txPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
      tiAccountType: new FormControl('1'),
      vImei: new FormControl('899101234578526')
    });
  }

  get has(): { [key: string]: AbstractControl } {
    return this.LoginForm.controls;
  }


  async CheckLoginAttempts(setSeconds: number) {
    if (this.cookieService.check("admin_user_login")) {
      let storedCookieData = this.cookieService.get("admin_user_login");
      const bytes = CryptoJS.AES.decrypt(storedCookieData, this.userPermissionService.GetEncryptionKey());
      if (bytes.toString()) {
        let check = await JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if (check.loginAttempts < 3 && check.timeToWait == 0) {
          this.LoginAttemptJsonData = {
            loginAttempts: check.loginAttempts + 1,
            timeToWait: 0,
            wrongLoginAttemptCount: check.wrongLoginAttemptCount,
          };
          let cookieData = CryptoJS.AES.encrypt(JSON.stringify(this.LoginAttemptJsonData), this.userPermissionService.GetEncryptionKey()).toString();
          this.cookieService.set("admin_user_login", cookieData);
        }

        if (check.loginAttempts >= 3 && check.timeToWait == 0) {
          var addTime = new Date();
          addTime.setSeconds(addTime.getSeconds() + setSeconds * check.wrongLoginAttemptCount);
          this.LoginAttemptJsonData = {
            loginAttempts: 3,
            timeToWait: addTime.valueOf(),
            wrongLoginAttemptCount: check.wrongLoginAttemptCount + 1,
          };
          let cookieData = CryptoJS.AES.encrypt(JSON.stringify(this.LoginAttemptJsonData), this.userPermissionService.GetEncryptionKey()).toString();
          this.cookieService.set("admin_user_login", cookieData);
        }

        if (new Date().valueOf() > check.timeToWait && check.timeToWait != 0) {
          this.LoginAttemptJsonData = {
            loginAttempts: 0,
            timeToWait: 0,
            wrongLoginAttemptCount: check.wrongLoginAttemptCount,
          };
          let cookieData = CryptoJS.AES.encrypt(JSON.stringify(this.LoginAttemptJsonData), this.userPermissionService.GetEncryptionKey()).toString();
          this.cookieService.set("admin_user_login", cookieData);
        }
      }
    }

    return this.LoginAttemptJsonData;
  }


  async CheckLoginEmail() {
    if (this.LoginForm.invalid) {
      return;
    }
    this.isLoginSubmit = true;

    if (this.cookieService.check("admin_user_login")) {
      let storedCookieData = this.cookieService.get("admin_user_login");
      const bytes = CryptoJS.AES.decrypt(storedCookieData, this.userPermissionService.GetEncryptionKey());
      if (bytes.toString()) {
        let outerCheck = await JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if (new Date().valueOf() > outerCheck.timeToWait) {
    let reqJSON = {
      vEmailId: this.LoginForm.controls['vEmailId'].value,
      tiAccountType: this.LoginForm.controls["tiAccountType"].value
    }
    this.loginService.CheckLoginEmail(reqJSON).then(async (result: any) => {
      if (result.code == 200) {
        if (result.data == 1) {
          let check = await this.CheckLoginAttempts(30);
                if (new Date().valueOf() > check.timeToWait) {
                  this.Login();
                } else {
                  let timeDiff = Math.abs(new Date().valueOf() - check.timeToWait) / 1000;
                  this.notifyService.showInfo(`Your account is temporary locked for ${timeDiff.toFixed()} seconds!`, "");
                }
        } else {
          let check = await this.CheckLoginAttempts(30);
                if (new Date().valueOf() > check.timeToWait) {
                  this.LoginSubUser();
                } else {
                  let timeDiff = Math.abs(new Date().valueOf() - check.timeToWait) / 1000;
                  this.notifyService.showInfo(`Your account is temporary locked for ${timeDiff.toFixed()} seconds!`, "");
                }
        }
      } else {
        this.notifyService.showError(result.message,'');
      }
    });
  }else {
    let timeDiff = Math.abs(new Date().valueOf() - outerCheck.timeToWait) / 1000;
    this.notifyService.showInfo(`Your account is temporary locked for ${timeDiff.toFixed()} seconds!`, "");
  }
}
}
}

  async Login() {
    if (this.LoginForm.invalid) {
      return;
    }
    this.isLoginSubmit = true;
    this.recaptchaV3Service.execute("Login").subscribe(async (token: string) => {
    // let encryptedText: any =  await this.tf.EncryptData(this.LoginForm.value);

    //   let reqJSON = {
    //     data: base64Encode(encryptedText),
    //   };

    let eData:any= await this.tf.dynamicPush(JSON.stringify(this.LoginForm.value));

    let reqJSON = {
      data: eData,
    };

    this.loginService.login(reqJSON).then((result: any) => {
      if (result['code'] == 200) {
        result.data = this.tf.dynamicPop(result.data);
        // delete login check cookie
        this.cookieService.delete("admin_user_login");
        let JSONData = {
          loggedInUser: 1,
          userType: result.data.tiAccountType,
          userEmail: result.data.vEmailId,
        }
        let cookieData = CryptoJS.AES.encrypt(JSON.stringify(JSONData), this.userPermissionService.GetEncryptionKey()).toString();
        this.cookieService.set("admin_user", cookieData);

        this.notifyService.showSuccess(result.message,'');
        localStorage.setItem('admin_user_token', result.data.txAccessToken);
        localStorage.setItem("admin_user_type", result.data.tiAccountType);
        localStorage.setItem("admin_user_email", result.data.vEmailId);
        localStorage.setItem("admin_device_id", result.data.vImei);

        this.userPermissionService.AssignUserPermissions();
        this.router.navigate(['dashboard']);
      } else {
        this.notifyService.showError(result.message,'');
      }
    }).catch((err) => {
      console.log(err);
    });
  });
  }

  async LoginSubUser() {
    if (this.LoginForm.invalid) {
      return;
    }
    this.isLoginSubmit = true;

    this.recaptchaV3Service.execute("Login").subscribe(async (token: string) => {
    // let encryptedText: any =  await this.tf.EncryptData(this.LoginForm.value);

    //   let reqJSON = {
    //     data: base64Encode(encryptedText),
    //   };
    let eData:any= await this.tf.dynamicPush(JSON.stringify(this.LoginForm.value));

    let reqJSON = {
      data: eData,
    };

    this.loginService.HandleSubUserLogin(reqJSON).then((result: any) => {
      if (result['code'] == 200) {
        result.data = this.tf.dynamicPop(result.data);
        // delete login check cookie
        this.cookieService.delete("admin_user_login");  

        let JSONData = {
          loggedInUser: 2,
          userType: result.data.tiAccountType,
          userEmail: result.data.vEmailId,
          roleId: result.data.iRoleId,
        }
        let cookieData = CryptoJS.AES.encrypt(JSON.stringify(JSONData), this.userPermissionService.GetEncryptionKey()).toString();
        this.cookieService.set("admin_user", cookieData);

        this.notifyService.showSuccess(result.message,'');
        localStorage.setItem('admin_user_token', result.data.txAccessToken);
        localStorage.setItem("admin_user_type", result.data.tiAccountType);
        localStorage.setItem("admin_user_email", result.data.vEmailId);
        localStorage.setItem("sub_admin_account_type", result.data.tiAccountType);
        localStorage.setItem("sub_admin_email", result.data.vEmailId);
        localStorage.setItem("sub_admin_role", result.data.iRoleId);
        localStorage.setItem("admin_zendesk_token", result.data.zendeskToken);
        localStorage.setItem("admin_device_id", result.data.vImei);

        this.userPermissionService.AssignUserPermissions();
        this.router.navigate(['dashboard']);
      } else {
        this.notifyService.showError(result.message,'');
      }
    }).catch((err) => {
      console.log(err);
    });
  });
  }

}
