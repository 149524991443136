<div class="page-content admin-section">
  
  <h1 class="admin-title">Onboard</h1>

  <div>
    <div>
      <button mat-button (click)="partnerDetails(viewPartnerOnboardActionPermission)" [ngClass]="{'active-tab-btn' : view == 0}" class="inactive-tab-btn" *ngIf="viewPartnerOnboardActionPermission">Partner</button>
      <button mat-button (click)="clientDetails(viewClientOnboardActionPermission)" [ngClass]="{'active-tab-btn' : view == 1}" class="inactive-tab-btn space-left" *ngIf="viewClientOnboardActionPermission">Client</button>
    </div>
    <div class="view-container">
      <h2 *ngIf="view == 0" class="main-heading">Partner Onboard Ticket</h2>
      <h2 *ngIf="view == 1" class="main-heading">Client Onboard Ticket</h2>
      <mat-form-field class="custom-input custom-search-input" floatLabel="never" floatPlaceholder="never">
        <mat-label>Search Email Address</mat-label>
        <input matInput type="text" (keyup)="applyFilterOne($event)">
        <button matSuffix>
          <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none"/>
            <path id="Path_690" data-name="Path 690" d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z" transform="translate(0)" fill="#464646"/>
          </svg>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="table-border">
    <div class="table-responsive">
      <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
      </div>
      <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }"
        [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
        <ng-container matColumnDef="indexnumber">
          <th mat-header-cell *matHeaderCellDef> No </th>
          <td mat-cell *matCellDef="let i = index;">
            {{ this.TableOnePaginator.pageIndex == 0 ? i+1 : i+ 1 + this.TableOnePaginator.pageIndex *
            this.TableOnePaginator.pageSize}}
          </td>
        </ng-container>
        <ng-container matColumnDef="vDisplayUniqueId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Unique Id </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.vDisplayUniqueId}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="vEmailId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.vEmailId}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="vPhoneNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.vDialCode}}-{{element.vPhoneNo}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="iStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element">
            <label class="status-tag bg-yellow" *ngIf="element.iStatus == '1'">
              Pending
            </label>
            <label class="status-tag bg-green" *ngIf="element.iStatus == '2'">
              Success
            </label>
            <label class="status-tag bg-red" *ngIf="element.iStatus == '3'">
              Reject
            </label>
          </td>
        </ng-container>
        <ng-container matColumnDef="iCreatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Time </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="view == 0" class="d-flex align-items-center justify-content-start">
              <button class="status-btn action-btn bg-blue" placement="left auto" [ngbTooltip]="viewPartnerProfileActionPermission ? 'View Profile':'Not Authorized'" [ngClass]="viewPartnerProfileActionPermission ? '':'unauthorized-cursor'"
                (click)="partnerOnboardingTicketView(element, partnerProfileView, viewPartnerProfileActionPermission)">
                <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="12.5" viewBox="0 0 18.333 12.5">
                  <path id="Path_9273" data-name="Path 9273" d="M10.167,4.5A9.856,9.856,0,0,0,1,10.75a9.847,9.847,0,0,0,18.333,0A9.856,9.856,0,0,0,10.167,4.5Zm0,10.417a4.167,4.167,0,1,1,4.167-4.167A4.168,4.168,0,0,1,10.167,14.917Zm0-6.667a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,10.167,8.25Z" transform="translate(-1 -4.5)" fill="#48acf0"/>
                </svg>
              </button>
              <button class="status-btn action-btn bg-yellow" placement="left auto" [ngbTooltip]="resendInviteLinkPartnerOnboardActionPermission ? 'Resend Invite Link':'Not Authorized'" [ngClass]="resendInviteLinkPartnerOnboardActionPermission ? '':'unauthorized-cursor'"
              (click)="PartnerResendInviteLinkModal(partnerResendInviteLink, element.vEmailId, resendInviteLinkPartnerOnboardActionPermission)" *ngIf="element.iStatus == 2">
                <svg xmlns="http://www.w3.org/2000/svg" width="12.954" height="12.965" viewBox="0 0 12.954 12.965">
                  <path id="Path_14498" data-name="Path 14498" d="M15.06,5.9a6.484,6.484,0,1,0,1.685,6.2H15.06a4.859,4.859,0,1,1-4.577-6.482A4.791,4.791,0,0,1,13.9,7.063L11.293,9.672h5.671V4Z" transform="translate(-4.01 -4)" fill="#e5a93b"/>
                </svg>
              </button>
              <button *ngIf="element.iPartnerStatus == 1" class="status-btn action-btn bg-green" placement="left auto" [ngbTooltip]="approvePartnerOnboardActionPermission ? 'Approve':'Not Authorized'" [ngClass]="approvePartnerOnboardActionPermission ? '':'unauthorized-cursor'"
                (click)="acceptPartnerOnboardTicket(element, approvePartnerOnboardActionPermission)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path id="check_circle_FILL1_wght600_GRAD200_opsz40" d="M9.142,13.987,14.7,8.41,13.442,7.132l-4.3,4.3L7.02,9.311,5.743,10.588ZM10.25,18.25a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z" transform="translate(-2.25 -2.25)" fill="#58b47c"/>
                </svg>    
              </button>
              <button *ngIf="element.iPartnerStatus == 1" class="status-btn action-btn bg-red" placement="left auto" [ngbTooltip]="rejectPartnerOnboardActionPermission ? 'Reject':'Not Authorized'" [ngClass]="rejectPartnerOnboardActionPermission ? '':'unauthorized-cursor'"
                (click)="rejectPartnerOnboardTicket(element, rejectPartnerOnboardActionPermission)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path id="cancel_FILL1_wght600_GRAD200_opsz40" d="M7.546,14.025l2.7-2.685,2.7,2.685,1.07-1.07-2.685-2.7,2.685-2.7-1.07-1.07-2.7,2.685-2.7-2.685-1.07,1.07,2.685,2.7-2.685,2.7Zm2.7,4.225a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z" transform="translate(-2.25 -2.25)" fill="#ee6060"/>
                </svg>
              </button>
              <!-- <span *ngIf="element.iPartnerStatus != 1" class="status-btn lightgray-bg gray-text"><span class="material-icons">hide_source</span>No Action</span> -->
            </div>
            <div *ngIf="view == 1" class="d-flex justify-content-start">
              <!-- <button class="status-btn action-btn bg-blue" placement="left auto" [ngbTooltip]="viewClientProfileActionPermission ? 'View Profile':'Not Authorized'" [ngClass]="viewClientProfileActionPermission ? '':'unauthorized-cursor'"
                (click)="OnboardingTicketView(element, ticketProfileView, viewClientProfileActionPermission)" data-bs-toggle="tooltip"
                  data-bs-placement="bottom" title="View Profile">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="12.5" viewBox="0 0 18.333 12.5">
                    <path id="Path_9273" data-name="Path 9273" d="M10.167,4.5A9.856,9.856,0,0,0,1,10.75a9.847,9.847,0,0,0,18.333,0A9.856,9.856,0,0,0,10.167,4.5Zm0,10.417a4.167,4.167,0,1,1,4.167-4.167A4.168,4.168,0,0,1,10.167,14.917Zm0-6.667a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,10.167,8.25Z" transform="translate(-1 -4.5)" fill="#48acf0"/>
                  </svg>
              </button> -->
              <button class="status-btn action-btn bg-yellow" placement="left auto" [ngbTooltip]="resendInviteLinkClientOnboardActionPermission ? 'Resend Invite Link':'Not Authorized'" [ngClass]="resendInviteLinkClientOnboardActionPermission ? '':'unauthorized-cursor'"
              (click)="ClientResendInviteLinkModal(clientResendInviteLink, element.vEmailId, resendInviteLinkClientOnboardActionPermission)" *ngIf="element.txLoginToken" data-bs-toggle="tooltip"
                data-bs-placement="bottom" title="Resend Invite Link">
                <svg xmlns="http://www.w3.org/2000/svg" width="12.954" height="12.965" viewBox="0 0 12.954 12.965">
                  <path id="Path_14498" data-name="Path 14498" d="M15.06,5.9a6.484,6.484,0,1,0,1.685,6.2H15.06a4.859,4.859,0,1,1-4.577-6.482A4.791,4.791,0,0,1,13.9,7.063L11.293,9.672h5.671V4Z" transform="translate(-4.01 -4)" fill="#e5a93b"/>
                </svg>
              </button>
              <button *ngIf="element.iClientStatus == 1" class="status-btn action-btn bg-green" placement="left auto" [ngbTooltip]="approveClientOnboardActionPermission ? 'Approve':'Not Authorized'" [ngClass]="approveClientOnboardActionPermission ? '':'unauthorized-cursor'"
                (click)="acceptOnboardTicket(element, approveClientOnboardActionPermission)" data-bs-toggle="tooltip"
                data-bs-placement="bottom" title="Approve">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path id="check_circle_FILL1_wght600_GRAD200_opsz40" d="M9.142,13.987,14.7,8.41,13.442,7.132l-4.3,4.3L7.02,9.311,5.743,10.588ZM10.25,18.25a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z" transform="translate(-2.25 -2.25)" fill="#58b47c"/>
                </svg>
              </button>
              <button *ngIf="element.iClientStatus == 1" class="status-btn action-btn bg-red" placement="left auto" [ngbTooltip]="rejectClientOnboardActionPermission ? 'Reject':'Not Authorized'" [ngClass]="rejectClientOnboardActionPermission ? '':'unauthorized-cursor'"
                (click)="rejectOnboardTicket(element, rejectClientOnboardActionPermission)" data-bs-toggle="tooltip"
                data-bs-placement="bottom" title="Reject">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path id="cancel_FILL1_wght600_GRAD200_opsz40" d="M7.546,14.025l2.7-2.685,2.7,2.685,1.07-1.07-2.685-2.7,2.685-2.7-1.07-1.07-2.7,2.685-2.7-2.685-1.07,1.07,2.685,2.7-2.685,2.7Zm2.7,4.225a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z" transform="translate(-2.25 -2.25)" fill="#ee6060"/>
                </svg>
              </button>
              <!-- <span *ngIf="element.iClientStatus != 1" class="status-btn lightgray-bg gray-text"><span class="material-icons">hide_source</span>No Action</span> -->
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
      </table>
    </div>
    <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
      #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>

<ng-template #partnerProfileView let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-view">
    <button class="modal-close-btn" (click)="d('Cross click')">
      <mat-icon>close</mat-icon>
    </button>
    <div class="d-flex align-items-center content container">
      <div class="w-100">
        <h1 class="title">Partner Profile</h1>
        <div class="content-box">
          <div class="row">
            <div class="col-12 col-sm-3">
              <p class="text">Email Id</p>
              <p class="sub-text">{{ ticket?.vEmailId }}</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">Phone No</p>
              <p class="sub-text" *ngIf="ticket?.vPhoneNo">{{ticket?.vDialCode}}-{{ ticket?.vPhoneNo }}</p>
              <p class="sub-text" *ngIf="!ticket?.vPhoneNo">Not Available</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">Company Name</p>
              <p class="sub-text" *ngIf="ticket?.vCompanyName">{{ ticket?.vCompanyName }}</p>
              <p class="sub-text" *ngIf="!ticket?.vCompanyName">Not Available</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">Company Website</p>
              <p class="sub-text" *ngIf="ticket?.vWebsiteAnswer">{{ ticket?.vWebsiteAnswer }}</p>
              <p class="sub-text" *ngIf="!ticket?.vWebsiteAnswer">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-3">
              <p class="text">Address</p>
              <p class="sub-text" *ngIf="ticket?.vAddress">{{ ticket?.vAddress }}</p>
              <p class="sub-text" *ngIf="!ticket?.vAddress">Not Available</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">Location</p>
              <p class="sub-text">{{ ticket?.iCityvName }}, {{ ticket?.iStatevName }}, {{ ticket?.iCountryvName }}</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">ZipCode</p>
              <p class="sub-text" *ngIf="ticket?.iZipcodeAnswer">{{ ticket?.iZipcodeAnswer }}</p>
              <p class="sub-text" *ngIf="!ticket?.iZipcodeAnswer">Not Available</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">Company Size</p>
              <p class="sub-text" *ngIf="ticket?.iCompanySizevName">{{ ticket?.iCompanySizevName }}</p>
              <p class="sub-text" *ngIf="!ticket?.iCompanySizevName">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-3">
              <p class="text">Project Stage :</p>
              <p class="sub-text" *ngIf="ticket?.iProjectStagevName">{{ ticket?.iProjectStagevName }}</p>
              <p class="sub-text" *ngIf="!ticket?.iProjectStagevName">Not Available</p>
            </div>
            <div class="col-12 col-sm-9">
              <p class="text">Why to become Partner</p>
              <div class="scroll-box mb-3">
                <perfect-scrollbar>
                  <p class="sub-text" *ngIf="ticket?.vWhyToBecomePartner">{{ ticket?.vWhyToBecomePartner }}</p>
                  <p class="sub-text" *ngIf="!ticket?.vWhyToBecomePartner">Not Available</p>              
                </perfect-scrollbar>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="text">Industries</p>
              <div class="d-flex flex-wrap">
                <div *ngFor="let item of ticket?.vIndustryData">
                  <p class="sub-text outline-content">{{ item }}</p>
                </div>
              </div>
              <p class="sub-text" *ngIf="ticket.vIndustryData.length == 0">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="text">Services</p>
              <p class="sub-text" *ngIf="ticket?.vServiceName">{{ ticket?.vServiceName }}</p>
              <p class="sub-text" *ngIf="!ticket?.vServiceName">Not Available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #partnerResendInviteLink let-c="close" let-d="dismiss">
  <div name="request-delete-modal-header" id="request-delete-modal-header" class="modal-header">
    <h1 name="request-delete-modal-title" id="request-delete-modal-title" class="modal-title">Invite Confirmation</h1>
  </div>
  <div name="request-delete-modal-body" id="request-delete-modal-body" class="modal-body">
    <h1 name="request-delete-modal-title" id="request-delete-modal-title" class="modal-title">Are you sure you want to resend invite link?</h1>
  </div>
  <div name="request-delete-modal-footer" id="request-delete-modal-footer" class="modal-footer">
    <button type="submit" name="request-delete-modal-footer-btn-submit" id="request-delete-modal-footer-btn-submit me-4" class="btn btn-active" (click)="RefreshPartnerInvite(vEmailId); d('cross click');">Resend</button>
    <button type="button" name="request-delete-modal-footer-btn-cancel" id="request-delete-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="d('cross click')">Cancel</button>
  </div>
</ng-template>

<ng-template #ticketProfileView let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-view">
    <button class="modal-close-btn" (click)="d('Cross click')">
      <mat-icon>close</mat-icon>
    </button>
    <div class="d-flex align-items-center content container">
      <div class="w-100">
        <h1 class="title">Client Profile</h1>
        <div class="content-box">
          <div class="row">
            <div class="col-12 col-sm-3">
              <p class="text">Email Id</p>
              <p class="sub-text">{{ ticket?.vEmailId }}</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">Phone No</p>
              <p class="sub-text" *ngIf="ticket?.vPhoneNo">{{ticket?.vDialCode}}-{{ ticket?.vPhoneNo }}</p>
              <p class="sub-text" *ngIf="!ticket?.vPhoneNo">Not Available</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">Company Name</p>
              <p class="sub-text" *ngIf="ticket?.vCompanyName">{{ ticket?.vCompanyName }}</p>
              <p class="sub-text" *ngIf="!ticket?.vCompanyName">Not Available</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">Company Website</p>
              <p class="sub-text" *ngIf="ticket?.vWebsiteAnswer">{{ ticket?.vWebsiteAnswer }}</p>
              <p class="sub-text" *ngIf="!ticket?.vWebsiteAnswer">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-3">
              <p class="text">Address</p>
              <p class="sub-text" *ngIf="ticket?.vAddress">{{ ticket?.vAddress }}</p>
              <p class="sub-text" *ngIf="!ticket?.vAddress">Not Available</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">Location</p>
              <p class="sub-text">{{ ticket?.iCityvName }}, {{ ticket?.iStatevName }}, {{ ticket?.iCountryvName }}</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">ZipCode</p>
              <p class="sub-text" *ngIf="ticket?.iZipcodeAnswer">{{ ticket?.iZipcodeAnswer }}</p>
              <p class="sub-text" *ngIf="!ticket?.iZipcodeAnswer">Not Available</p>
            </div>
            <div class="col-12 col-sm-3">
              <p class="text">Company Size</p>
              <p class="sub-text" *ngIf="ticket?.iCompanySizevName">{{ ticket?.iCompanySizevName }}</p>
              <p class="sub-text" *ngIf="!ticket?.iCompanySizevName">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-3">
              <p class="text">Company Type</p>
              <p class="sub-text" *ngIf="ticket?.vCompanyTypeName">{{ ticket?.vCompanyTypeName }}</p>
              <p class="sub-text" *ngIf="!ticket?.vCompanyTypeName">Not Available</p>
            </div>
            <div class="col-12 col-sm-9">
              <p class="text">Why to become Client</p>
              <div class="col scroll-box mb-3">
                <perfect-scrollbar>
                  <p class="sub-text" *ngIf="ticket?.vWhyToBecomeClient">{{ ticket?.vWhyToBecomeClient }}</p>
                  <p class="sub-text" *ngIf="!ticket?.vWhyToBecomeClient">Not Available</p>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #clientResendInviteLink let-c="close" let-d="dismiss">
  <div name="request-delete-modal-header" id="request-delete-modal-header" class="modal-header">
    <h1 name="request-delete-modal-title" id="request-delete-modal-title" class="modal-title">Invite Confirmation</h1>
  </div>
  <div name="request-delete-modal-body" id="request-delete-modal-body" class="modal-body">
    <h1 name="request-delete-modal-title" id="request-delete-modal-title" class="modal-title">Are you sure you want to resend invite link?</h1>
  </div>
  <div name="request-delete-modal-footer" id="request-delete-modal-footer" class="modal-footer">
    <button type="submit" name="request-delete-modal-footer-btn-submit" id="request-delete-modal-footer-btn-submit me-4" class="btn btn-active" (click)="RefreshClientInvite(vEmailId); d('cross click');">Resend</button>
    <button type="button" name="request-delete-modal-footer-btn-cancel" id="request-delete-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="d('cross click')">Cancel</button>
  </div>
</ng-template>
