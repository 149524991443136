<div class="container-fluid skill-management">
  <div class="row">
    <div class="col-12">
      <div class="wrapper">
        <div class="tab-content flex-grow-1 w-100">
          <div class="tab-pane fade show active" aria-labelledby="skill-management-tab">
            <div class="pagetitle-wrapper">
              <div
                class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                <div class="d-flex align-items-center">
                  <i class="pagetitle-icon"><span class="material-icons">account_box</span></i>
                  <h2 class="pagetitle">Partner Onboard Ticket</h2>
                </div>
              </div>
              <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                <button matSuffix mat-button>
                  <mat-icon>search</mat-icon>
                </button>
                <input matInput placeholder="Ex: support@rapidise.com" (keyup)="applyFilterOne($event)" />
              </mat-form-field>
              <div class="table-responsive">
                <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                </div>
                <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }"
                  [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                  <ng-container matColumnDef="indexnumber">
                    <th mat-header-cell *matHeaderCellDef> No </th>
                    <td mat-cell *matCellDef="let i = index;">
                      {{ this.TableOnePaginator.pageIndex == 0 ? i+1 : i+ 1 + this.TableOnePaginator.pageIndex *
                      this.TableOnePaginator.pageSize}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vDisplayUniqueId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Unique Id </th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.vDisplayUniqueId}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vEmailId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.vEmailId}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vPhoneNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.vDialCode}}-{{element.vPhoneNo}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="iStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-chip-list>
                        <mat-chip class="yellow-chip" *ngIf="element.iStatus == '1'">
                          Pending
                        </mat-chip>
                        <mat-chip class="blue-chip" *ngIf="element.iStatus == '2'">
                          Success
                        </mat-chip>
                        <mat-chip class="red-chip" *ngIf="element.iStatus == '3'">
                          Reject
                        </mat-chip>
                        <mat-chip class="purple-chip" *ngIf="element.iStatus == '4'">
                          Archive
                        </mat-chip>
                      </mat-chip-list>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="iCreatedAt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Time </th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex flex-wrap">
                        <button class="status-btn lightblue-bg blue-text text-center" placement="left auto" [ngbTooltip]="viewPartnerOnboardActionPermission ? 'View Profile':'Not Authorized'" [ngClass]="viewPartnerOnboardActionPermission ? '':'unauthorized-cursor'"
                          (click)="partnerOnboardingTicketView(element, partnerProfileView, viewPartnerOnboardActionPermission)">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button class="status-btn lightpurple-bg purple-text text-center" placement="left auto" [ngbTooltip]="resendInviteLinkPartnerOnboardActionPermission ? 'Resend Invite Link':'Not Authorized'" [ngClass]="resendInviteLinkPartnerOnboardActionPermission ? '':'unauthorized-cursor'"
                        (click)="PartnerResendInviteLinkModal(partnerResendInviteLink, element.vEmailId, resendInviteLinkPartnerOnboardActionPermission)" *ngIf="element.iStatus == 2">
                          <mat-icon>replay</mat-icon>
                        </button>
                        <button *ngIf="element.iPartnerStatus == 1" class="status-btn lightgreen-bg green-text" placement="left auto" [ngbTooltip]="approvePartnerOnboardActionPermission ? 'Approve':'Not Authorized'" [ngClass]="approvePartnerOnboardActionPermission ? '':'unauthorized-cursor'"
                          (click)="acceptPartnerOnboardTicket(element, approvePartnerOnboardActionPermission)">
                          <span class="material-icons">check_circle</span>
                        </button>
                        <button *ngIf="element.iPartnerStatus == 1" class="status-btn lightpink-bg pink-text" placement="left auto" [ngbTooltip]="rejectPartnerOnboardActionPermission ? 'Reject':'Not Authorized'" [ngClass]="rejectPartnerOnboardActionPermission ? '':'unauthorized-cursor'"
                          (click)="rejectPartnerOnboardTicket(element, rejectPartnerOnboardActionPermission)">
                          <span class="material-icons">cancel</span>
                        </button>
                        <span *ngIf="element.iPartnerStatus != 1" class="status-btn lightgray-bg gray-text"><span class="material-icons">hide_source</span>No Action</span>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                </table>
              </div>
              <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
                #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #partnerProfileView let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-view">
    <button class="modal-close-btn" (click)="d('Cross click')">
      <mat-icon>close</mat-icon>
    </button>
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h1 class="title">Partner Profile</h1>
        <div class="content-box">
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Email Id:</p>
            </div>
            <div class="col">
              <p class="sub-text">{{ ticket?.vEmailId }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Phone No:</p>
            </div>
            <div class="col">
              <p class="sub-text" *ngIf="ticket?.vPhoneNo">{{ticket?.vDialCode}}-{{ ticket?.vPhoneNo }}</p>
              <p class="sub-text" *ngIf="!ticket?.vPhoneNo">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Company Name:</p>
            </div>
            <div class="col">
              <p class="sub-text" *ngIf="ticket?.vCompanyName">{{ ticket?.vCompanyName }}</p>
              <p class="sub-text" *ngIf="!ticket?.vCompanyName">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Company Website:</p>
            </div>
            <div class="col">
              <p class="sub-text" *ngIf="ticket?.vWebsiteAnswer">{{ ticket?.vWebsiteAnswer }}</p>
              <p class="sub-text" *ngIf="!ticket?.vWebsiteAnswer">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Address :</p>
            </div>
            <div class="col">
              <p class="sub-text" *ngIf="ticket?.vAddress">{{ ticket?.vAddress }}</p>
              <p class="sub-text" *ngIf="!ticket?.vAddress">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Location :</p>
            </div>
            <div class="col">
              <p class="sub-text">{{ ticket?.iCityvName }}, {{ ticket?.iStatevName }}, {{ ticket?.iCountryvName }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">ZipCode :</p>
            </div>
            <div class="col">
              <p class="sub-text" *ngIf="ticket?.iZipcodeAnswer">{{ ticket?.iZipcodeAnswer }}</p>
              <p class="sub-text" *ngIf="!ticket?.iZipcodeAnswer">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Company Size :</p>
            </div>
            <div class="col">
              <p class="sub-text" *ngIf="ticket?.iCompanySizevName">{{ ticket?.iCompanySizevName }}</p>
              <p class="sub-text" *ngIf="!ticket?.iCompanySizevName">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Project Stage :</p>
            </div>
            <div class="col">
              <p class="sub-text" *ngIf="ticket?.iProjectStagevName">{{ ticket?.iProjectStagevName }}</p>
              <p class="sub-text" *ngIf="!ticket?.iProjectStagevName">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Why to become Partner :</p>
            </div>
            <div class="col scroll-box mb-3">
              <perfect-scrollbar>
              <p class="sub-text" *ngIf="ticket?.vWhyToBecomePartner">{{ ticket?.vWhyToBecomePartner }}</p>
              <p class="sub-text" *ngIf="!ticket?.vWhyToBecomePartner">Not Available</p>              </perfect-scrollbar>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Industries :</p>
            </div>
            <div class="col">
              <p class="sub-text" *ngIf="ticket?.vIndustryName">{{ ticket?.vIndustryName }}</p>
              <p class="sub-text" *ngIf="!ticket?.vIndustryName">Not Available</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="text">Services :</p>
            </div>
            <div class="col">
              <p class="sub-text" *ngIf="ticket?.vServiceName">{{ ticket?.vServiceName }}</p>
              <p class="sub-text" *ngIf="!ticket?.vServiceName">Not Available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #partnerResendInviteLink let-c="close" let-d="dismiss">
  <div name="request-delete-modal-header" id="request-delete-modal-header" class="modal-header">
    <h1 name="request-delete-modal-title" id="request-delete-modal-title" class="modal-title">Invite Confirmation</h1>
  </div>
  <div name="request-delete-modal-body" id="request-delete-modal-body" class="modal-body">
    <h1 name="request-delete-modal-title" id="request-delete-modal-title" class="modal-title">Are you sure you want to resend invite link?</h1>
  </div>
  <div name="request-delete-modal-footer" id="request-delete-modal-footer" class="modal-footer">
    <button type="submit" name="request-delete-modal-footer-btn-submit" id="request-delete-modal-footer-btn-submit me-4" class="btn btn-active" (click)="RefreshPartnerInvite(vEmailId); d('cross click');">Resend</button>
    <button type="button" name="request-delete-modal-footer-btn-cancel" id="request-delete-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="d('cross click')">Cancel</button>
  </div>
</ng-template>
