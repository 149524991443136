import { Component, OnInit, ViewChild } from '@angular/core';
import { UserManagementService } from './user-partner.service';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../../config/event-emitter.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { Permission } from "./../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

@Component({
  selector: 'app-user-partner',
  templateUrl: './user-partner.component.html'
})
export class UserPartnerComponent implements OnInit {
  // toggle actions permission
  viewPartnerProfileActionPermission: boolean = false;
  activePartnerUserActionPermission: boolean = false;
  inactivePartnerUserActionPermission: boolean = false;

  constructor(
    private service : UserManagementService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private userPermissionService: UserPermissionService,
    private notifyService: NotificationService,
  ){
    // assign individual permissions
    const ViewPartnerProfileAction: Permission = Permission.ViewPartnerProfileAction;
    const ActivePartnerUserAction: Permission = Permission.ActivePartnerUserAction;
    const InactivePartnerUserAction: Permission = Permission.InactivePartnerUserAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ViewPartnerProfileAction)) {
      this.viewPartnerProfileActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ActivePartnerUserAction)) {
      this.activePartnerUserActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(InactivePartnerUserAction)) {
      this.inactivePartnerUserActionPermission = true;
    }
  }

  displayedColumnsTableOne: string[] = ['indexnumber', 'name', 'email', 'number', 'uniqueId', 'publishStatus', 'accountStatus', 'create', 'actions'];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

  ngOnInit(): void {
    this.getUserList();
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  getUserList() {
    this.service.getUserList().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      }
    });
  }

  changeActiveInactiveUser(userId: any, accStatus: any, hasPermission: boolean) {
    if (hasPermission) {
      this.service.changeActiveInactiveUser({userId:userId,accStatus:accStatus}).then((result: any) => {
        if (result['code'] == 200) {
          this.notifyService.showSuccess(result.message,'');
          this.getUserList();
        }
      });
    }
  }

  publishView(data: any, modalToOpen: any, hasPermission: boolean){
    if (hasPermission) {
      this.GetProfileStatus(data['userId']);
      this.modalService.open(modalToOpen, { centered: true, windowClass: 'wide-modal' });
      setTimeout(() => {
        this.eventEmitterService.viewPartnerPublish_Pub(data['userId']);
      }, environment.view_open_model);
    }
  }

  public progressValue:any = 0;
  public iProfilePublishCompleted:any = 0;
  GetProfileStatus(iUserId:any){
    this.service.GetProfileStatus(iUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result['data']['iProfilePublishCompleted'];
        this.progressValue = result['data']['progressValue'];
        this.eventEmitterService.GetProfileStatusCallMethod(result['data']);
      }
    });
  }

}
