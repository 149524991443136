<div class="container-fluid skill-management">
  <div class="row">
    <div class="col-12">
      <div class="wrapper">

        <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="title" role="tabpanel" aria-labelledby="skill-management-tab">

            <div class="pagetitle-wrapper">
              <div
                class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                <div class="d-flex align-items-center">
                  <i class="pagetitle-icon"><span class="material-icons">extension</span></i>
                  <h2 class="pagetitle">Role</h2>
                </div>
                <div placement="bottom auto" [ngbTooltip]="addRoleActionPermission ? 'Add Role':'Not Authorized'"
                  [ngClass]="addRoleActionPermission ? '':'unauthorized-cursor'">
                  <button class="status-btn lightpurple-bg purple-text text-center ml-0 ml-sm-3 mr-0 mt-3 mt-sm-0 px-3"
                    [ngClass]="addRoleActionPermission ? '':'unauthorized-action'"
                    (click)="OpenModal(addRole, addRoleActionPermission)">
                    <mat-icon class="mr-2">library_add</mat-icon> Add
                  </button>
                </div>
              </div>
              <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                <button matSuffix mat-button>
                  <mat-icon>search</mat-icon>
                </button>
                <input matInput placeholder="" (keyup)="applyFilterOne($event)" />
              </mat-form-field>
              <div class="table-responsive">
                <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                </div>
                <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }"
                  [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                  <ng-container matColumnDef="indexnumber">
                    <th mat-header-cell *matHeaderCellDef> No </th>
                    <td mat-cell *matCellDef="let i = index;">
                      {{ this.TableOnePaginator.pageIndex == 0 ? i+1 : i+ 1 + this.TableOnePaginator.pageIndex *
                      this.TableOnePaginator.pageSize}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vRoleName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.vRoleName}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="tiRoleType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Role Type </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-chip-list>
                        <mat-chip class="blue-chip" *ngIf="element.tiRoleType == 1">
                          Admin
                        </mat-chip>
                        <mat-chip class="blue-chip" *ngIf="element.tiRoleType == 3">
                          Partner
                        </mat-chip>
                        <mat-chip class="blue-chip" *ngIf="element.tiRoleType == 4">
                          Client
                        </mat-chip>
                      </mat-chip-list>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="iCreatedAt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Time </th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="tiStatus">
                    <th name="user-role-status-header" id="user-role-status-header" mat-header-cell *matHeaderCellDef>
                      Status
                    </th>
                    <td name="user-role-status" id="user-role-status" mat-cell *matCellDef="let element">
                      <span *ngIf="element.tiStatus == 1" class="status-active" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="Permissions are assigned">Active</span>
                      <span *ngIf="element.tiStatus == 0" class="status-reject" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="Permissions are not assigned">Inactive</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="isAdminRole">
                    <th mat-header-cell *matHeaderCellDef>
                      Admin Role
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-checkbox (change)="EditAdminRole($event, element)" [checked]="element.isAdminRole"></mat-checkbox>
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex flex-wrap">
                        <button class="status-btn lightpink-bg pink-text text-center mb-2" placement="left auto"
                          [ngbTooltip]="deleteRoleActionPermission ? 'Delete':'Not Authorized'"
                          [ngClass]="deleteRoleActionPermission ? '':'unauthorized-cursor'"
                          (click)="OpenDeleteRole(deleteRole, element,deleteRoleActionPermission)"
                          *ngIf="element.tiStatus == 0">
                          <mat-icon>delete</mat-icon>
                        </button>
                        <button class="status-btn lightblue-bg blue-text text-center mb-2" placement="left auto"
                          [ngbTooltip]="editRoleActionPermission ? 'Edit':'Not Authorized'"
                          [ngClass]="editRoleActionPermission ? '':'unauthorized-cursor'"
                          (click)="OpenModelEditRole(editRole,element,editRoleActionPermission)"
                          *ngIf="element.tiStatus == 0">
                          <mat-icon>create</mat-icon>
                        </button>
                        <span *ngIf="element.tiStatus == 1" class="status-btn lightgray-bg gray-text">
                          <span class="material-icons me-1">hide_source</span>
                          No Action
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                </table>
              </div>
              <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
                #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
              </mat-paginator>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #addRole let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-edit add-skill-modal">
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h1 class="title">Add Role</h1>
        <form class="form" [formGroup]="addRoleForm" (ngSubmit)="AddRole()">
          <div class="row">
            <div class="col-12 mb-4">
              <label class="label">Role Name</label>
              <mat-form-field hideRequiredMarker appearance="outline">
                <input type="text" formControlName="vRoleName" matInput placeholder="Enter role">
                <mat-error *ngIf="addRoleForm.controls['vRoleName'].invalid">
                  <mat-error *ngIf="addRoleForm.controls['vRoleName'].errors.required">Role Name is required</mat-error>
                  <mat-error *ngIf="addRoleForm.controls['vRoleName'].errors?.minlength"> Minimum 3 character required
                  </mat-error>
                  <mat-error *ngIf="addRoleForm.controls['vRoleName'].errors?.maxlength"> Maximum 100 character allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 mb-4">
              <mat-form-field appearance="outline">
                <mat-select placeholder="Select Role Type" formControlName="tiRoleType">
                  <!-- <mat-option value="All">All</mat-option> -->
                  <mat-option *ngFor="let role of roleTypeList" [value]="role.id">
                    {{ role.tiRoleType }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addRoleForm.controls['tiRoleType'].invalid">
                  <mat-error *ngIf="addRoleForm.controls['tiRoleType'].errors.required">Role Type is
                    required</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center flex-row btn-box">
            <button type="submit" class="modal-btn">Add</button>
            <button type="button" class="modal-btn-outline"
              (click)="d('Cross click'); this.addRoleForm.reset(); this.submitted = false;">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editRole let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-edit add-skill-modal">
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h1 class="title">Edit Role</h1>
        <form class="form" [formGroup]="editRoleForm" (ngSubmit)="EditRole()">
          <div class="row">
            <div class="col-12 mb-4">
              <label class="label">Role Name</label>
              <mat-form-field hideRequiredMarker appearance="outline">
                <input type="text" formControlName="vRoleName" matInput placeholder="Enter role">
                <mat-error *ngIf="addRoleForm.controls['vRoleName'].invalid">
                  <mat-error *ngIf="addRoleForm.controls['vRoleName'].errors.required">Role Name is required</mat-error>
                  <mat-error *ngIf="addRoleForm.controls['vRoleName'].errors?.minlength"> Minimum 3 character required
                  </mat-error>
                  <mat-error *ngIf="addRoleForm.controls['vRoleName'].errors?.maxlength"> Maximum 100 character allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 mb-4">
              <mat-form-field appearance="outline">
                <mat-select placeholder="Select Role Type" formControlName="tiRoleType">
                  <!-- <mat-option value="All">All</mat-option> -->
                  <mat-option *ngFor="let role of roleTypeList" [value]="role.id">
                    {{ role.tiRoleType }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addRoleForm.controls['tiRoleType'].invalid">
                  <mat-error *ngIf="addRoleForm.controls['tiRoleType'].errors.required">Role Type is
                    required</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center flex-row btn-box">
            <button type="submit" class="modal-btn">Edit</button>
            <button type="button" class="modal-btn-outline"
              (click)="d('Cross click'); this.editRoleForm.reset();">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteRole let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-edit add-skill-modal">
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h1 class="title d-flex justify-content-center">Are you sure?</h1>
        <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
          <button type="submit" class="modal-btn" (click)="DeleteRole(iRoleId)">Yes</button>
          <button type="button" class="modal-btn-outline px-4" (click)="d('Cross click')">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>