<div class="page-content admin-section">

    <div class="view-container">
        <h1 class="admin-title">Talent Profile</h1>
        <mat-form-field class="custom-input custom-search-input" floatLabel="never" floatPlaceholder="never">
            <mat-label>Search Email Address</mat-label>
            <input matInput type="text" (keyup)="applyFilterOne($event)">
            <button matSuffix>
                <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                    viewBox="0 0 20 20">
                    <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
                    <path id="Path_690" data-name="Path 690"
                        d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
                        transform="translate(0)" fill="#464646" />
                </svg>
            </button>
        </mat-form-field>
    </div>

    <div class="table-border">
        <div class="table-responsive">
            <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
            </div>
            <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }"
                [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                <!-- <ng-container matColumnDef="indexnumber">
                    <th mat-header-cell *matHeaderCellDef> No </th>
                    <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableOnePaginator.pageIndex == 0 ? i+1 : i+ 1 + this.TableOnePaginator.pageIndex *
                        this.TableOnePaginator.pageSize}}
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let element">{{element.name}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </th>
                    <td mat-cell *matCellDef="let element">{{element.email}}</td>
                </ng-container>
                <ng-container matColumnDef="number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.number}}</span>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="uniqueId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Unique Id </th>
                    <td mat-cell *matCellDef="let element">{{element.uniqueId}}</td>
                </ng-container> -->
                <ng-container matColumnDef="publishStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let element">
                        <!-- <span *ngIf="element.publishStatus == 2" class="status status-inactive"><span class="material-icons">block</span>Pending Approval</span> -->
                        <label *ngIf="element.publishStatus == 2" class="status-tag bg-blue">
                            Under-review
                        </label>
                        <label *ngIf="element.publishStatus == 1" class="status-tag bg-yellow">
                            Pending
                        </label>
                        <label *ngIf="element.publishStatus == 3" class="status-tag bg-green">
                            Approved
                        </label>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="accountStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Status </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.accountStatus == 0" class="status status-pending"><span
                                class="material-icons">hourglass_empty</span>Pending</span>
                        <span *ngIf="element.accountStatus == 1" class="status status-active"><span
                                class="material-icons">done</span>Active</span>
                        <span *ngIf="element.accountStatus == 2" class="status status-inactive"><span
                                class="material-icons">block</span>Inactive</span>
                        <span *ngIf="element.accountStatus == 3" class="status status-inactive"><span
                                class="material-icons">delete</span>Delete</span>
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="create">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Time </th>
                    <td mat-cell *matCellDef="let element">{{element.create * 1000 | date:'MM/dd/yy, h:mm a'}}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex">
                            <button class="status-btn action-btn bg-blue" placement="left auto"
                                [ngbTooltip]="viewTalentProfileActionPermission ? 'View Profile':'Not Authorized'"
                                [ngClass]="viewTalentProfileActionPermission ? '':'unauthorized-cursor'"
                                (click)="viewProfile(element,viewTalentProfileActionPermission)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="12.5"
                                    viewBox="0 0 18.333 12.5">
                                    <path id="Path_9273" data-name="Path 9273"
                                        d="M10.167,4.5A9.856,9.856,0,0,0,1,10.75a9.847,9.847,0,0,0,18.333,0A9.856,9.856,0,0,0,10.167,4.5Zm0,10.417a4.167,4.167,0,1,1,4.167-4.167A4.168,4.168,0,0,1,10.167,14.917Zm0-6.667a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,10.167,8.25Z"
                                        transform="translate(-1 -4.5)" fill="#48acf0" />
                                </svg>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="toggleAction">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex" *ngIf="element.accountStatus == 1 || element.accountStatus == 2">
                            <label class="toggle-btn" placement="left auto"
                            [ngbTooltip]="element.accountStatus == 1  ? 'Active': element.accountStatus == 2 ? 'Inactive' :''">
                                <input type="checkbox" [checked]="element.accountStatus == 1 ? true : false" (change)="toggleStatus($event,element.userId)">
                                <span class="slider round"></span>
                            </label>
                            <!-- <button *ngIf="element.accountStatus == 1" class="status-btn lightpurple-bg purple-text"
                                placement="left auto"
                                [ngbTooltip]="inactiveEmployeeUserActionPermission ? 'Inactive':'Not Authorized'"
                                [ngClass]="inactiveEmployeeUserActionPermission ? '':'unauthorized-cursor'"
                                (click)="changeActiveInactiveUser(element.userId,2,inactiveEmployeeUserActionPermission)">
                                <mat-icon>thumb_down</mat-icon>
                            </button>
                            <button *ngIf="element.accountStatus == 2" class="status-btn lightgreen-bg green-text"
                                placement="left auto"
                                [ngbTooltip]="activeEmployeeUserActionPermission ? 'Active':'Not Authorized'"
                                [ngClass]="activeEmployeeUserActionPermission ? '':'unauthorized-cursor'"
                                (click)="changeActiveInactiveUser(element.userId,1,activeEmployeeUserActionPermission)">
                                <mat-icon>thumb_up</mat-icon>
                            </button> -->
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
            </table>
        </div>
        <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
            #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
        </mat-paginator>
    </div>

</div>
