import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClientPublishService } from './client-publish.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../../config/event-emitter.service';
import { environment } from '../../../../environments/environment';
import { Permission } from "./../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

@Component({
  selector: 'app-client-publish',
  templateUrl: './client-publish.component.html'
})
export class ClientPublishComponent implements OnInit {
  // toggle actions permission
  viewClientPublishActionPermission: boolean = false;
  approveClientPublishActionPermission: boolean = false;
  rejectClientPublishActionPermission: boolean = false;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;
  ticket: any;

  displayedColumnsTableOne: string[] = ['indexnumber','vEmailId','actions'];
  dataSourceTableOne = new MatTableDataSource();

  constructor(
    private service: ClientPublishService,
    public ngxSmartModalService: NgxSmartModalService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  ) {
    // assign individual permissions
    const ViewClientPublishAction: Permission = Permission.ViewClientPublishAction;
    const ApproveClientPublishAction: Permission = Permission.ApproveClientPublishAction;
    const RejectClientPublishAction: Permission = Permission.RejectClientPublishAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ViewClientPublishAction)) {
      this.viewClientPublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ApproveClientPublishAction)) {
      this.approveClientPublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(RejectClientPublishAction)) {
      this.rejectClientPublishActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetPublish();
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  public primarySkillData: any = [];
  GetPublish() {
    this.service.GetPublish().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    });
  }

  acceptPublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.service.acceptPublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetPublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }
  }

  rejectPublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.service.rejectPublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetPublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }
  }

  publishView(data: any, modalToOpen: any, hasPermission: boolean){
    if (hasPermission) {
      this.GetProfileStatus(data['iUserId']);
      this.modalService.open(modalToOpen, { centered: true, windowClass: 'wide-modal' });
      setTimeout(() => {
        this.eventEmitterService.viewClientPublish_Pub(data['iUserId']);
      }, environment.view_open_model);
    }
  }

  public iProfilePublishCompleted:any = 0;
  public progressValue:any;
  public partnerCompanyName:any;
  GetProfileStatus(iUserId:any){
    this.service.GetProfileStatus(iUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result['data']['iProfilePublishCompleted'];
        this.progressValue = result['data']['progressValue'];
        this.partnerCompanyName = result['data']['partnerCompanyName'];
        this.eventEmitterService.GetProfileStatusCallMethod(result['data']);
      }
    });
  }
}
