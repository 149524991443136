import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { OnboardPartnerService } from './onboard-partner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../../config/event-emitter.service';
import { NotificationService } from '../../../config/notification.service';
import { Permission } from "./../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../utils/services/user-permission.service";

@Component({
  selector: 'app-onboard-partner',
  templateUrl: './onboard-partner.component.html',
})
export class OnboardPartnerComponent implements OnInit {
    // toggle actions permission
    viewPartnerOnboardActionPermission: boolean = false;
    resendInviteLinkPartnerOnboardActionPermission: boolean = false;
    approvePartnerOnboardActionPermission: boolean = false;
    rejectPartnerOnboardActionPermission: boolean = false;

    @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
    @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;
    ticket: any;

    displayedColumnsTableOne: string[] = ['indexnumber','vDisplayUniqueId','vEmailId','vPhoneNo','iStatus','iCreatedAt','actions'];
    dataSourceTableOne = new MatTableDataSource();

    constructor(
      private service: OnboardPartnerService,
      public ngxSmartModalService: NgxSmartModalService,
      public eventEmitterService: EventEmitterService,
      private modalService: NgbModal,
      private notifyService : NotificationService,
      private userPermissionService: UserPermissionService
    ) {
      // assign individual permissions
      const ViewPartnerOnboardAction: Permission = Permission.ViewPartnerOnboardAction;
      const ResendInviteLinkPartnerOnboardAction: Permission = Permission.ResendInviteLinkPartnerOnboardAction;
      const ApprovePartnerOnboardAction: Permission = Permission.ApprovePartnerOnboardAction;
      const RejectPartnerOnboardAction: Permission = Permission.RejectPartnerOnboardAction;

      // check what user is authorized
      if (this.userPermissionService.isAuthorizedFor(ViewPartnerOnboardAction)) {
        this.viewPartnerOnboardActionPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(ResendInviteLinkPartnerOnboardAction)) {
        this.resendInviteLinkPartnerOnboardActionPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(ApprovePartnerOnboardAction)) {
        this.approvePartnerOnboardActionPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(RejectPartnerOnboardAction)) {
        this.rejectPartnerOnboardActionPermission = true;
      }
    }

    ngOnInit(): void {
      this.getRefreshAPIData();
    }

    getRefreshAPIData() {
      this.GetPartnerOnboard();
    }

    ngAfterViewInit() {
      this.dataSourceTableOne.paginator = this.tableOnePaginator;
      this.dataSourceTableOne.sort = this.tableOneSort;
    }

    applyFilterOne(filterValue: any) {
      this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
    }

    public primarySkillData: any = [];
    GetPartnerOnboard() {
      this.service.GetPartnerOnboard().then((result: any) => {
        if (result['code'] == 200) {
          this.dataSourceTableOne.data = result['data'];
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }

    acceptPartnerOnboardTicket(data:any, hasPermission: boolean){
      if (hasPermission) {
        this.service.acceptPartnerOnboardTicket({iTicketId:data.iTicketId}).then((result: any) => {
          if (result['code'] == 200) {
            this.GetPartnerOnboard();
            this.notifyService.showSuccess(result.message,'');
          } else {
            this.notifyService.showError(result.message,'');
          }
        });
      }
    }

    rejectPartnerOnboardTicket(data:any, hasPermission: boolean){
      if (hasPermission) {
        this.service.rejectPartnerOnboardTicket({iTicketId:data.iTicketId}).then((result: any) => {
          if (result['code'] == 200) {
            this.GetPartnerOnboard();
            this.notifyService.showSuccess(result.message,'');
          } else {
            this.notifyService.showError(result.message,'');
          }
        });
      }
    }

    partnerOnboardingTicketView(data: any, modalToOpen: any, hasPermission: boolean){
      if (hasPermission) {
        this.service.PartnerOnboardingTicketView(data.iTicketId).then((result: any) => {
          if (result['code'] == 200) {
            this.ticket = result['data'];
            this.modalService.open(modalToOpen, { centered: true });
          } else {
            this.notifyService.showError(result.message,'');
          }
        });
      }
    }

    public vEmailId:any;
    PartnerResendInviteLinkModal(modelName:any, vEmailId:any, hasPermission: boolean) {
      if (hasPermission) {
        this.modalService.open(modelName, { centered:true, windowClass: 'confirmation-modal' });
        this.vEmailId = vEmailId;
      }
    }

    RefreshPartnerInvite(vEmailId: any){
      this.service.RefreshPartnerInvite({vEmailId: vEmailId}).then((result: any) => {
        if (result.code == 200) {
          this.notifyService.showSuccess(result.message,'');
        }
      });
    }
}
