import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavService } from "../nav.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { EventEmitterService } from "../../../config/event-emitter.service";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserPermissionService } from "../../../utils/services/user-permission.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-top-nav",
  templateUrl: "./top-nav.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class TopNavComponent implements OnInit {
  roleId = localStorage.getItem("sub_admin_role");
  userType = localStorage.getItem("sub_admin_account_type");

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    public navService: NavService,
    public router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    public eventEmitterService: EventEmitterService,
    private userPermissionService: UserPermissionService,
    private cookieService: CookieService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {
    let user = localStorage.getItem("admin_user_type");
    let accessToken = localStorage.getItem("admin_user_token");
    let roleId = localStorage.getItem("sub_admin_role");
    let userType = localStorage.getItem("sub_admin_account_type");
    let subUserEmailId = localStorage.getItem("sub_admin_email");

    if (user && accessToken && !roleId && !userType) {
      this.authenticateUser();
      this.eventEmitterService.AuthenticateUser.subscribe((res: any) => {
        this.authenticateUser();
      });
    } else {
      this.LoginSubUserInformation(roleId, userType, subUserEmailId);
      this.eventEmitterService.AuthenticateUser.subscribe((res: any) => {
        this.LoginSubUserInformation(roleId, userType, subUserEmailId);
      });
      this.eventEmitterService.GetProfileDetailsAPISubMethod.subscribe(() => {
        this.LoginSubUserInformation(roleId, userType, subUserEmailId);
      });
    }

  }

  public json_user_data: any = {
    login_status: false,
  };
  authenticateUser() {
    this.navService.viewProfile().subscribe(
      (res: any) => {
        if (res.code == 200) {
          this.json_user_data = res.data;
          this.json_user_data.login_status = true;
        } else {
          this.router.navigate(["/login"]);
        }
      },
      (err: any) => {
        console.log(err, "err");
      }
    );
  }

  public json_sub_user_data = {
    login_status: false,
    iSubUserId: 0,
    vSubUserProfilePhoto: "",
    dis_name: "NA",
    dis_short_name: "NA",
  };
  LoginSubUserInformation(roleId: any, userType: any, subUserEmailId: any) {
    let reqJSON = {
      roleId: roleId,
      userType: userType,
      emailId: subUserEmailId,
    };
    this.navService.LoginSubUserInformation(reqJSON).then((res: any) => {
      if (res.code == 200) {
        this.json_sub_user_data = res.data;
        this.json_sub_user_data.login_status = true;
        this.json_sub_user_data.dis_name = "NA";
        this.json_sub_user_data.dis_short_name = "NA";
        // console.log(this.json_sub_user_data, "json_sub_user_data >>>>>>>>>");
      } else {
        this.router.navigate(["/login"]);
      }
    });
  }

  Logout() {
    let device_id = localStorage.getItem("admin_device_id");
    this.navService.logoutAPI({ vImei: device_id }).subscribe((res: any) => {
      this.json_user_data.login_status = false;
      this.navService.Logout();
      this.userPermissionService.ResetPermissions();
        localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_type");
        localStorage.removeItem("admin_user_email");
        localStorage.removeItem("sub_admin_account_type");
        localStorage.removeItem("sub_admin_email");
        localStorage.removeItem("sub_admin_role");
        localStorage.removeItem("admin_zendesk_token");
        localStorage.removeItem("admin_device_id");
      this.cookieService.delete("admin_user");
      this.router.navigate(["/login"]);
    });
  }
}
