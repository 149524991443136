import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  DashboardCount() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/dashboard`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GeCountClients() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/client-data`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCountPartner() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/partner-data`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCountTalent() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/talent-data`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetTalentOverview() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/talent-overview`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetRecentJoinClientdetails() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/recent-join-client`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetRecentJoinPartnerdetails() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/recent-join-partner`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetRecentJoinTalentdetails() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/recent-join-talent`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCountContracts() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/get-count-contracts`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetCountRequirements() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/get-count-requirements`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetCountInterviews() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/get-count-interviews`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetClientOverview() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/get-client-overview`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetTotalBilling() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/get-total-billing`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetTotalRequirements(filterFlag: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/get-total-requirements?flag=${filterFlag}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetDealOverview() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/get-deals-overview`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetCountryProfileOverview(start:any, end:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/get-country-profile-overview?start=${start}&end=${end}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetInterviewsOverview() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/get-interviews-overview`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetRecentContracts() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/get-contracts?tabFlag=4`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
