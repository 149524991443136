import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { ViewTalentService } from "../view-talent.service";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { ProjectPortfolioService } from "./project-portfolio.service";
import { NotificationService } from "../../../../config/notification.service";
import { StaticMsg } from "../../../../config/en";
import { environment } from "../../../../../environments/environment";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Permission } from "../../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../../utils/services/user-permission.service";

@Component({
  selector: "talent-project-portfolio",
  templateUrl: "./project-portfolio.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class TalentProjectPortfolioComponent implements OnInit {
  @Input() talentUserId:any;
  projectPortfolioToggle:boolean=false;

  // toggle actions permission
  addTalentProjectPortfolioActionPermission: boolean = false;
  editTalentProjectPortfolioActionPermission: boolean = false;

  imagePath = environment.image_path;
  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  projectPortfolioForm!: FormGroup;
  projectPortfolioSubmitted: any = false;

  projectPortfolioEditForm!: FormGroup;
  projectPortfolioEditSubmitted: any = false;

  projectTechnologyListArray: any = [];
  PortfolioArray: any = [];
  mediaArray: any = [];
  technology: any = [];
  editPortfolioData: any;
  @ViewChild("uploadFile") fileInput: any;
  fileAttr: any;
  imagesUploadError: any;
  thumbnailArray: any = [];
  imageUploaded: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  technologies: any = [];
  allTechnologies: any = [];
  filterTechnologies: any;

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private profileService: ViewTalentService,
    private projectPortfolioService: ProjectPortfolioService,
    public eventEmitterService: EventEmitterService,
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  ) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const AddTalentProjectPortfolioAction: Permission = Permission.AddTalentProjectPortfolioAction;
    const EditTalentProjectPortfolioAction: Permission = Permission.EditTalentProjectPortfolioAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddTalentProjectPortfolioAction)) {
      this.addTalentProjectPortfolioActionPermission = true;
    }

    if (this.userPermissionService.isAuthorizedFor(EditTalentProjectPortfolioAction)) {
      this.editTalentProjectPortfolioActionPermission = true;
    }

    

    this.filterTechnologies = this.projectTechnologyListArray;
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });

    this.projectPortfolioForm = new FormGroup({
      vProjectTitle: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46)]),
      vUrl: new FormControl("", [Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]),
      vProjectScreenshot: new FormControl("", []),
      vDescription: new FormControl("", [Validators.required, Validators.minLength(150)]),
      vMyContribution: new FormControl("", [Validators.required, Validators.minLength(150)]),
      vTechnology: new FormControl("", [Validators.required]),
    });

    this.projectPortfolioEditForm = new FormGroup({
      vProjectTitle: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46)]),
      vUrl: new FormControl("", [Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]),
      vProjectScreenshot: new FormControl("", []),
      vDescription: new FormControl("", [Validators.required, Validators.minLength(150)]),
      vMyContribution: new FormControl("", [Validators.required, Validators.minLength(150)]),
      vTechnology: new FormControl("", [Validators.required]),
      iPortfolioId: new FormControl("", []),
    });

    this.GetPortfolio();
    this.GetTechnologyList();
  }

  public projectPortfolioCardCompleted: any = false;
  updateProfileStatus(data: any) {
    this.projectPortfolioCardCompleted = data["projectPortfolioCardCompleted"];
  }

  ProjectPortfolioModal(projectPortfolio: any,hasPermission:boolean) {
    if(hasPermission){
      this.projectPortfolioForm.reset();
    this.mediaArray = [];
    this.technology = [];
    this.technologies = [];
    this.modalService.open(projectPortfolio, { centered: true, windowClass: "profile-modal" });
      } else {
        this.notifyService.showError("Not Authorized!", "");
      }
  }

  ProjectPortfolioProcess() {
    this.projectPortfolioSubmitted = true;
    if (this.projectPortfolioForm.invalid || this.imagesUploadError) {
      return;
    }
    let technologyIds: any = [];
    this.projectPortfolioForm.value.vTechnology.forEach((element: any) => {
      technologyIds.push(element.iMasterTechnologyId);
    });

    let reqData = {
      vProjectTitle: this.projectPortfolioForm.value.vProjectTitle,
      vUrl: this.projectPortfolioForm.value.vUrl,
      vProjectScreenshot: this.projectPortfolioForm.value.vProjectScreenshot,
      vDescription: this.projectPortfolioForm.value.vDescription,
      vMyContribution: this.projectPortfolioForm.value.vMyContribution,
      vTechnology: technologyIds,
      media: this.mediaArray[0],
      media1: this.mediaArray[1],
      media2: this.mediaArray[2],
      media3: this.mediaArray[3],
      media4: this.mediaArray[4],
    };

    this.projectPortfolioService.AddPortfolio(reqData,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetPortfolio();
        this.modalService.dismissAll();
        this.notifyService.showSuccess("Portfolio Added Successfully!", "");
        this.eventEmitterService.GetProfileStatusAPICallMethod();
      } else {
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message, "");
      }
    });
  }

  // OnAddTechnologyRemoved(technology: string) {
  //   const technologies = this.projectPortfolioForm.value.vTechnology as string[];
  //   this.removeFirst(technologies, technology);
  //   this.projectPortfolioForm.controls.vTechnology.setValue(technologies); // To trigger change detection
  // }

  ProjectPortfolioEditModal(projectPortfolio: any, element: any,hasPermission:boolean) {
    if(hasPermission){
    this.imageUploaded = 0;
    this.imageUploaded = element.UserPortfolioMedia.length;
    this.technology = [];
    this.editPortfolioData = "";
    this.mediaArray = [];
    this.projectPortfolioEditForm.patchValue({
      vProjectScreenshot: "",
    });
    this.modalService.open(projectPortfolio, { centered: true, windowClass: "profile-modal" });
    this.editPortfolioData = element;
    if (element.UserPortfolioTechnologies.length > 0) {
      element.UserPortfolioTechnologies.forEach((element: any) => {
        this.technology.push(element.MasterTechnology);
      });
    }

    this.projectPortfolioEditForm.patchValue({
      vProjectTitle: element.vTitile,
      vUrl: element.vURL == "null" ? "" : element.vURL,
      vDescription: element.txDescription,
      vMyContribution: element.txContribution,
      vTechnology: this.technology,
      iPortfolioId: element.iPortfolioId,
    });

    this.technologies = this.technology;
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  ProjectPortfolioEditProcess(): any {
    this.projectPortfolioEditSubmitted = true;
    if (this.projectPortfolioEditForm.invalid) {
      return;
    }

    let technologyIds: any = [];
    this.projectPortfolioEditForm.value.vTechnology.forEach((element: any) => {
      technologyIds.push(element.iMasterTechnologyId);
    });

    let reqData = {
      iPortfolioId: this.projectPortfolioEditForm.value.iPortfolioId,
      vProjectTitle: this.projectPortfolioEditForm.value.vProjectTitle,
      vUrl: this.projectPortfolioEditForm.value.vUrl,
      vProjectScreenshot: this.projectPortfolioEditForm.value.vProjectScreenshot,
      vDescription: this.projectPortfolioEditForm.value.vDescription,
      vMyContribution: this.projectPortfolioEditForm.value.vMyContribution,
      vTechnology: technologyIds,
      media: this.mediaArray[0],
      media1: this.mediaArray[1],
      media2: this.mediaArray[2],
      media3: this.mediaArray[3],
      media4: this.mediaArray[4],
    };
    this.projectPortfolioService.EditPortfolio(reqData,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetPortfolio();
        this.modalService.dismissAll();
        this.notifyService.showSuccess("Portfolio Updated Successfully!", "");
      } else {
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message, "");
      }
    });
  }

  GetPortfolio() {
    this.profileService.GetPortfolio(this.talentUserId).then((result: any) => {
      if (result["code"] == 200) {
        this.PortfolioArray = result.data;
      } else {
        this.PortfolioArray = [];
      }
    });
  }

  GetTechnologyList() {
    this.profileService.GetTechnologyList().then((result: any) => {
      if (result.code == 200) {
        this.projectTechnologyListArray = result["data"];
      }
    });
  }

  // OnTechnologyRemoved(technology: string) {
  //   const technologies = this.projectPortfolioEditForm.value.vTechnology as string[];
  //   this.removeFirst(technologies, technology);
  //   this.projectPortfolioEditForm.controls.vTechnology.setValue(technologies); // To trigger change detection
  // }

  // private removeFirst<T>(array: T[], toRemove: T): void {
  //   const index = array.indexOf(toRemove);
  //   if (index !== -1) {
  //     array.splice(index, 1);
  //   }
  // }

  // MultiSelectTechnologyCompareFn(item1: any, item2: any): boolean {
  //   return item1 && item2 ? item1.iMasterTechnologyId === item2.iMasterTechnologyId : item1 === item2;
  // }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  OnTechnologyRemoved(technology: string) {
    const technologies = this.technologies;
    this.removeFirst(technologies, technology);
    this.technologies = technologies; // To trigger change detection
    this.projectTechnologyListArray.push(technology);
    if (this.technologies.length == 0) {
      this.projectPortfolioForm.controls["vTechnology"].setValue("");
      this.projectPortfolioEditForm.controls["vTechnology"].setValue("");
    }
  }

  AddTechnology(technology: any): void {
    if (technology) {
      let index = this.technologies.findIndex((x: any) => x.iMasterTechnologyId === technology.iMasterTechnologyId);
      if (index > -1) {
        return;
      } else {
        const iMasterTechnologyId = technology.iMasterTechnologyId;
        const state = technology.state;
        const vTechnologyName = technology.vTechnologyName;
        if (vTechnologyName) {
          this.technologies.push({ iMasterTechnologyId: iMasterTechnologyId, vTechnologyName: vTechnologyName.trim() });
        }
        this.projectPortfolioForm.controls["vTechnology"].setValue(this.technologies);
        this.projectPortfolioEditForm.controls["vTechnology"].setValue(this.technologies);
      }
    }
  }

  DisplayFnTechnology(technology: any): string {
    return technology && technology.vTechnologyName ? technology.vTechnologyName : "";
  }

  _filterTechnology(name: string): any {
    const filterValue = name?.toLowerCase();
    this.filterTechnologies = this.projectTechnologyListArray;
    this.filterTechnologies = this.filterTechnologies.filter((technology: { vTechnologyName: string }) => technology.vTechnologyName.toLowerCase().startsWith(filterValue));
  }

  FileBrowseHandler(event: any, type: any) {
    let fileSize = event["target"]["files"][0]["size"] / 1024;
    if (fileSize > 4 * 1024) {
      this.notifyService.showError(StaticMsg.FileLargerError, "");
    } else {
      if (type == 1 && (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg")) {
        if (this.mediaArray.length == 5 || event.target.files.length > 5) {
          this.imagesUploadError = "Allow only 5 Images";
          this.notifyService.showError(StaticMsg.PortfolioImageOutOfRangeError, "");
        }
        if (this.imageUploaded + event.target.files.length > 5) {
          this.imagesUploadError = "Allow only 5 Images";
          this.notifyService.showError(StaticMsg.PortfolioImageOutOfRangeError, "");
        }
        if (this.mediaArray.length < 5) {
          this.imagesUploadError = "";
          for (let i = 0; i < event.target.files.length; i++) {
            this.mediaArray.push(event.target.files[i]);
          }
        }
      } else {
        this.notifyService.showError(StaticMsg.NotValidImageTypeError, "");
      }
    }
  }

  removeProject(id: any) {
    this.projectPortfolioService.RemovePortfolio(id,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message, "");
        this.GetPortfolio();
        this.eventEmitterService.GetProfileStatusAPICallMethod();
      }
    });
  }

  deleteThumbnail(element: any, i: any) {
    let reqObj = {
      id: element.fileName,
      iPortfolioId: this.projectPortfolioEditForm.value.iPortfolioId,
    };
    this.projectPortfolioService.RemoveThumbNail(reqObj,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.editPortfolioData.UserPortfolioMedia.splice(i, 1);
        this.GetPortfolio();
        this.notifyService.showSuccess("Screenshot deleted successfully", "");
      } else {
        this.notifyService.showSuccess(result.message, "");
      }
    });
  }

  crossClick(file: any) {
    if (this.mediaArray.length > 0) {
      this.mediaArray.splice(
        this.mediaArray.findIndex((a: any) => a.name === file.name),
        1
      );
      if (this.mediaArray.length < 5) {
        this.imagesUploadError = false;
      }
    }
  }
}
