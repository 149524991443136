import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { EmpHobbiesService } from "./emp-hobbie.service";
import { Permission } from "./../../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../../utils/services/user-permission.service";

@Component({
  selector: "app-emp-hobbie",
  templateUrl: "./emp-hobbie.component.html",
})
export class EmpHobbieComponent implements OnInit {
  addForm!: FormGroup;
  editForm!: FormGroup;

  displayedColumnsTableOne: string[] = [
    "indexnumber",
    "name",
    "iCreatedAt",
    "actions",
  ];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild("TableOnePaginator", { static: true })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort", { static: true }) tableOneSort: MatSort;

  // toggle actions permission
  addHobbieActionPermission: boolean = false;
  editHobbieActionPermission: boolean = false;
  deleteHobbieActionPermission: boolean = false;

  constructor(
    private service: EmpHobbiesService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private userPermissionService: UserPermissionService
  ) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const AddHobbieAction: Permission = Permission.AddHobbieAction;
    const EditHobbieAction: Permission = Permission.EditHobbieAction;
    const DeleteHobbieAction: Permission = Permission.DeleteHobbieAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddHobbieAction)) {
      this.addHobbieActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditHobbieAction)) {
      this.editHobbieActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeleteHobbieAction)) {
      this.deleteHobbieActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GET();
    this.addForm = new FormGroup({
      name: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
    });

    this.editForm = new FormGroup({
      id: new FormControl("", []),
      name: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
    });
  }

  OpenModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  openModelEdit(modelName: any, element: any, hasPermission: boolean) {
    if (hasPermission) {
      this.editForm.patchValue({
        id: element["id"],
        name: element["name"],
      });
      this.modalService.open(modelName, { centered: true });
    }
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value
      .trim()
      .toLowerCase();
  }

  GET() {
    this.service
      .GET()
      .then((result: any) => {
        if (result["code"] == 200) {
          this.dataSourceTableOne.data = result["data"];
        } else {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  handleAdd() {
    if (this.addForm.invalid) {
      return;
    }
    this.service.POST(this.addForm.value).then((result: any) => {
      if (result["code"] == 200) {
        this.addForm.reset();
        this.modalService.dismissAll();
        this.eventEmitterService.modelMessage({
          msg: result.message,
          status: 0,
        });
        this.GET();
      } else {
        this.modalService.dismissAll();
        this.eventEmitterService.modelMessage({
          msg: result.message,
          status: 1,
        });
      }
    });
  }

  handleEdit() {
    if (this.editForm.invalid) {
      return;
    }
    this.service.PUT(this.editForm.value).then((result: any) => {
      if (result["code"] == 200) {
        this.eventEmitterService.modelMessage({
          msg: result.message,
          status: 0,
        });
        this.modalService.dismissAll();
        this.GET();
      } else {
        this.modalService.dismissAll();
        this.eventEmitterService.modelMessage({
          msg: result.message,
          status: 1,
        });
      }
    });
  }

  DELETE(item: any, hasPermission: boolean): void {
    if (hasPermission) {
      this.service.DELETE(item.id).then((result: any) => {
        this.GET();
        this.eventEmitterService.modelMessage({ msg: result.message, status: 0 });
      });
    }
  }
}
