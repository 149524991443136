<div class="container-fluid skill-management">
    <div class="row">
      <div class="col-12">
        <div class="wrapper">

          <div class="nav flex-row nav-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="horizontal">
            <a class="nav-link w-50" [ngClass]="{ 'active' : tabState == 'title' }" id="title-tab" data-toggle="pill" href="skill#title" role="tab" aria-controls="title" aria-selected="true" >Primary Skill</a>
            <a class="nav-link w-50" [ngClass]="{ 'active' : tabState == 'skill' }" id="skill-tab" data-toggle="pill" href="skill#skill" role="tab" aria-controls="skill" aria-selected="false" >Secondary Skill</a>
          </div>

          <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
            <div class="tab-pane fade" [ngClass]="{ 'show active' : tabState == 'title' }" id="title" role="tabpanel" aria-labelledby="skill-management-tab">

              <div class="pagetitle-wrapper">
                <div class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                  <div class="d-flex align-items-center">
                    <i class="pagetitle-icon"><span class="material-icons">grade</span></i>
                    <h2 class="pagetitle">Primary Skill Management</h2>
                  </div>
                  <div placement="top auto" [ngbTooltip]="addPrimarySkillActionPermission ? '':'Not Authorized'" [ngClass]="addPrimarySkillActionPermission ? '':'unauthorized-cursor'">
                    <button class="status-btn lightpurple-bg purple-text text-center ml-0 ml-sm-3 mr-0 mt-3 mt-sm-0 px-3" (click)="OpenModal(addPrimarySkill,addPrimarySkillActionPermission)" [ngClass]="addPrimarySkillActionPermission ? '':'unauthorized-action'">
                      <mat-icon class="mr-2">library_add</mat-icon> Add
                    </button>
                  </div>
                </div>
                <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                  <button matSuffix mat-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <input matInput placeholder="Ex: Android Developer" (keyup)="applyFilterOne($event)" />
                </mat-form-field>
                <div class="table-responsive">
                  <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                  </div>
                  <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }" [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                    <ng-container matColumnDef="indexnumber">
                      <th mat-header-cell *matHeaderCellDef> No </th>
                      <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableOnePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.TableOnePaginator.pageIndex * this.TableOnePaginator.pageSize}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vPrimarySkill">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Skill </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.vPrimarySkill}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="iCreatedAt">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Time </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> Actions </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex flex-wrap">
                          <div placement="top auto" [ngbTooltip]="editPrimarySkillActionPermission ? '':'Not Authorized'" [ngClass]="editPrimarySkillActionPermission ? '':'unauthorized-cursor'">
                            <button class="status-btn lightblue-bg blue-text text-center mb-2"(click)="OpenModelForEditPriSkill(editPrimarySkill,element,editPrimarySkillActionPermission)" [ngClass]="editPrimarySkillActionPermission ? '':'unauthorized-action'">
                              <mat-icon>create</mat-icon>
                            </button>
                          </div>
                          <div placement="top auto" [ngbTooltip]="deletePrimarySkillActionPermission ? '':'Not Authorized'" [ngClass]="deletePrimarySkillActionPermission ? '':'unauthorized-cursor'">
                            <button class="status-btn lightpink-bg pink-text text-center mb-2" (click)="DeletePrimarySkill(element, deletePrimarySkillActionPermission)" [ngClass]="deletePrimarySkillActionPermission ? '':'unauthorized-action'">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                  </table>
                </div>
                <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }" #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
                </mat-paginator>
              </div>

            </div>

            <div class="tab-pane fade" [ngClass]="{ 'show active' : tabState == 'skill' }" id="skill" role="tabpanel" aria-labelledby="skill-management-tab">

              <div class="pagetitle-wrapper">
                <div class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                  <div class="d-flex align-items-center">
                    <i class="pagetitle-icon"><span class="material-icons">grade</span></i>
                    <h2 class="pagetitle">Secondary Skill Management</h2>
                  </div>
                    <button class="status-btn lightpurple-bg purple-text text-center ml-0 ml-sm-3 mr-0 mt-3 mt-sm-0 px-3" placement="bottom auto" [ngbTooltip]="addSecondarySkillActionPermission ? '':'Not Authorized'" [ngClass]="addSecondarySkillActionPermission ? '':'unauthorized-cursor'" (click)="OpenModal(addSecondarySkill,addSecondarySkillActionPermission)">
                      <mat-icon class="mr-2">library_add</mat-icon> Add
                    </button>
                </div>
                <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                  <button matSuffix mat-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <input matInput (keyup)="applyFilterOne($event)" placeholder="Ex. Mongodb" />
                </mat-form-field>
                <div class="table-responsive">
                  <div *ngIf="dataSourceTableTwo?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                  </div>
                  <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableTwo?.filteredData?.length <= 0 }" [dataSource]="dataSourceTableTwo" matSort #TableTwoSort="matSort" class="datatable">
                    <ng-container matColumnDef="indexnumber">
                      <th mat-header-cell *matHeaderCellDef> No </th>
                      <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableTwoPaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.TableTwoPaginator.pageIndex * this.TableTwoPaginator.pageSize}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vPrimarySkill">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Skill </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.vPrimarySkill}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Secondary Skill </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.vName}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="iCreatedAt">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Time </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> Actions </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex flex-wrap">
                            <button class="status-btn lightblue-bg blue-text text-center mb-2" placement="left auto" [ngbTooltip]="editSecondarySkillActionPermission ? '':'Not Authorized'" [ngClass]="editSecondarySkillActionPermission ? '':'unauthorized-cursor'" (click)="OpenModelForEditSecSkill(editSecondarySkill,element,editSecondarySkillActionPermission)">
                              <mat-icon>create</mat-icon>
                            </button>
                            <button class="status-btn lightpink-bg pink-text text-center mb-2" placement="left auto" [ngbTooltip]="deleteSecondarySkillActionPermission ? '':'Not Authorized'" [ngClass]="deleteSecondarySkillActionPermission ? '':'unauthorized-cursor'" (click)="DeleteSecondarySkill(element, deleteSecondarySkillActionPermission)">
                              <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableTwo; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableTwo;"></tr>
                  </table>
                </div>
                <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableTwo?.filteredData?.length <= 0 }" #TableTwoPaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
                </mat-paginator>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

 <ng-template #editPrimarySkill let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Edit Primary Skill</h1>
          <form class="form" [formGroup]="editPrimarySkillForm" (ngSubmit)="EditPrimarySkill()">
            <div class="row">
              <div class="col-12">
                <label class="label">Primary Skill</label>
                <mat-form-field class="profile-input" floatLabel="never" appearance="outline">
                  <input type="text" formControlName="primarySkill" matInput placeholder="Ex. Cloud Developer">
                  <mat-error *ngIf="submitted && editPrimarySkillFormHas.primarySkill.errors">
                    <mat-error *ngIf="editPrimarySkillFormHas.primarySkill.errors.required">Primary Skill is required</mat-error>
                    <mat-error *ngIf="editPrimarySkillFormHas.primarySkill.errors?.minlength"> Minimum 3 character required </mat-error>
                    <mat-error *ngIf="editPrimarySkillFormHas.primarySkill.errors?.maxlength"> Maximum 100 character allowed </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn">Edit</button>
              <button type="button" class="modal-btn-outline" (click)="d('Cross click'); this.editPrimarySkillForm.reset(); this.submitted = false;">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #addPrimarySkill let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Add Primary Skill</h1>
          <form class="form" [formGroup]="addPrimarySkillForm" (ngSubmit)="AddPrimarySkill()">
            <div class="row">
              <div class="col-12">
                <label class="label">Primary Skill</label>
                <mat-form-field hideRequiredMarker appearance="outline">
                  <input type="text" formControlName="primarySkill" matInput placeholder="Ex. Cloud Developer">
                  <mat-error *ngIf="submitted && addPrimarySkillFormHas.primarySkill.errors">
                    <mat-error *ngIf="addPrimarySkillFormHas.primarySkill.errors.required">Primary Skill is required</mat-error>
                    <mat-error *ngIf="addPrimarySkillFormHas.primarySkill.errors?.minlength"> Minimum 3 character required </mat-error>
                    <mat-error *ngIf="addPrimarySkillFormHas.primarySkill.errors?.maxlength"> Maximum 100 character allowed </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn">Add</button>
              <button type="button" class="modal-btn-outline" (click)="d('Cross click'); this.addPrimarySkillForm.reset(); this.submitted = false;">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #editSecondarySkill let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Edit Secondary Skill</h1>
          <form class="form" [formGroup]="editSecondarySkillForm" (ngSubmit)="EditSecondarySkill()">
            <div class="row">
              <div class="col-12">
                <!-- <label for="primarySkill" class="label font-weight-500">Primary Skill</label>
                <mat-form-field hideRequiredMarker appearance="fill" class="dropdown">
                  <mat-select id="primarySkill" formControlName="primarySkill" [(ngModel)]="selectedPrimarySkill" placeholder="Title" required>
                     <mat-option value="default" readonly disabled>Primary Skill</mat-option> --
                    <mat-option *ngFor="let item of primarySkillData; let i = index" [value]="item.iPrimarySkillId">{{item.vPrimarySkill}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="submitted && editSecondarySkillFormHas.primarySkill.errors">
                    <mat-error *ngIf="editSecondarySkillFormHas.primarySkill.errors.required">
                      Primary Skill is required
                    </mat-error>
                  </mat-error>
                </mat-form-field> -->
              </div>
              <div class="col-12">
                <label class="label">Secondary Skill</label>
                <mat-form-field class="profile-input" floatLabel="never" appearance="outline">
                  <input type="text" formControlName="secondarySkill" matInput placeholder="Ex. Cloud Developer">
                  <mat-error *ngIf="submitted && editSecondarySkillFormHas.secondarySkill.errors">
                    <mat-error *ngIf="editSecondarySkillFormHas.secondarySkill.errors.required"> Secondary Skill is required </mat-error>
                    <mat-error *ngIf="editSecondarySkillFormHas.secondarySkill.errors?.minlength"> Minimum 3 character required </mat-error>
                    <mat-error *ngIf="editSecondarySkillFormHas.secondarySkill.errors?.maxlength"> Maximum 255 character allowed </mat-error>

                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn">Edit</button>
              <button type="button" class="modal-btn-outline" (click)="d('Cross click'); this.editPrimarySkillForm.reset(); this.submitted = false;">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #addSecondarySkill let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Add Secondary Skill</h1>
          <form class="form" [formGroup]="addSecondarySkillForm" (ngSubmit)="AddSecondarySkill()">
            <div class="row">
              <div class="col-12">
                <!-- <label for="primarySkill" class="label font-weight-500">Primary Skill</label> -->
                <!-- <mat-form-field hideRequiredMarker appearance="fill" class="dropdown">
                  <mat-select id="primarySkill" formControlName="primarySkill" [(ngModel)]="selectedPrimarySkill" placeholder="Title" required>
                     <mat-option value="default">Primary Skill</mat-option> --
                    <mat-option *ngFor="let item of primarySkillData; let i = index" [value]="item.iPrimarySkillId">{{item.vPrimarySkill}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="submitted && addSecondarySkillFormHas.primarySkill.errors">
                    <mat-error *ngIf="addSecondarySkillFormHas.primarySkill.errors.required">
                      Primary Skill is required
                    </mat-error>
                  </mat-error>
                </mat-form-field> -->
              </div>
              <div class="col-12">
                <label class="label">Secondary Skill</label>
                <mat-form-field class="profile-input" floatLabel="never" appearance="outline">
                  <input type="text" formControlName="secondarySkill" matInput placeholder="Ex. Cloud Developer">
                  <mat-error *ngIf="submitted && addSecondarySkillFormHas.secondarySkill.errors">
                    <mat-error *ngIf="addSecondarySkillFormHas.secondarySkill.errors.required"> Secondary Skill is required </mat-error>
                    <mat-error *ngIf="addSecondarySkillFormHas.secondarySkill.errors?.minlength"> Minimum 3 character required </mat-error>
                    <mat-error *ngIf="addSecondarySkillFormHas.secondarySkill.errors?.maxlength"> Maximum 255 character allowed </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn">Add</button>
              <button type="button" class="modal-btn-outline" (click)="d('Cross click'); this.addSecondarySkillForm.reset(); this.submitted = false;">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
