import { Routes, RouterModule } from "@angular/router";
import { AccessGuard } from "./modules/login/authentication.service";
import { DefaultlayoutComponent } from "./modules/defaultLayout/defaultLayout.component";

import { LoginComponent } from "./modules/login/login.component";
import { TicketComponent } from "./modules/ticket/ticket.component";
import { ChangePasswordComponent } from './admin/change-password/change-password.component';
import { ProfileComponent } from "./modules/profile/profile.component";
import { DashboardComponent } from "./admin/dashboard/dashboard.component";
import { SkillComponent } from "./modules/skill/skill.component";
import { TechnologyComponent } from "./modules/technology/technology.component";
import { RapidiseContactComponent } from "./modules/rapidiseContact/rapidiseContact.component";
import { DesignSystemComponent } from "./design-system/design-system.component";

// Publish - Start
import { PartnerPublishComponent } from "./modules/publish/publish-partner/partner-publish.component";
import { EmployeePublishComponent } from "./modules/publish/publish-employee/employee-publish.component";
import { ClientPublishComponent } from "./modules/publish/publish-client/client-publish.component";
// Publish - End

// Onboard - Start
import { OnboardPartnerComponent } from "./modules/onboard/onboard-partner/onboard-partner.component";
import { OnboardClientComponent } from "./modules/onboard/onboard-client/onboard-client.component";
// Onboard - End

// Partner - Start
import { IndustryComponent } from "./modules/industry/industry.component";
import { ServiceComponent } from "./modules/service/service.component";
// Partner - End

// Employee Component - Start
import { EmpLanguagesComponent } from "./modules/employee/user-employee/emp-language/emp-language.component";
import { EmpHobbieComponent } from "./modules/employee/user-employee/emp-hobbie/emp-hobbie.component";
import { EmpDegreeComponent } from "./modules/employee/user-employee/emp-degree/emp-degree.component";
import { EmpBranchComponent } from "./modules/employee/user-employee/emp-branch/emp-branch.component";
import { EmpAwardComponent } from "./modules/employee/user-employee/emp-award/emp-award.component";
import { EmpCertificateComponent } from "./modules/employee/user-employee/emp-certificate/emp-certificate.component";
import { EmpInterviewStatusComponent } from "./modules/employee/user-employee/emp-interview-status/emp-interview-status.component";
// Employee Component - End

//User Management - Start
import { UserPartnerComponent } from "./modules/user-management/user-partner/user-partner.component";
import { UserEmployeeComponent } from "./modules/user-management/user-employee/user-employee.component";
import { UserClientComponent } from "./modules/user-management/user-client/user-client.component";
// import { DatatableComponent } from "./modules/dashboard/datatable/datatable.component";
import { DealEngineComponent } from "./modules/deal-engine/deal-engine.component";
import { ReasonsComponent } from "./modules/reasons/reasons.component";
//User Management - End

import { ContractComponent } from "./modules/contract/contract.component";
import { InterviewComponent } from "./modules/interview/interview.component";
import { RequirementComponent } from "./modules/requirement/requirement.component";
import { BillingComponent } from "./modules/billing/billing.component";

import { SiteConstructionComponent } from "./modules/site-construction/site-construction.component";

import { RoleComponent } from "./modules/role/role.component";
import { ChildPermissionManagementComponent } from "./modules/child-permission-management/child-permission-management.component";
import { ParentPermissionManagementComponent } from "./modules/parent-permission-management/parent-permission-management.component";
import { SubUserComponent } from "./modules/sub-user/sub-user.component";
import { PermissionManagementComponent } from "./modules/permission-management/permission-management.component";

import { Permission } from "./utils/enums/permission.enum";
import { PermissionGuard } from "./utils/services/permission.guard";

import { ContainerComponent } from './admin/layouts/container/container.component';
import { OnboardComponent } from "./admin/onboard/onboard.component";
import { ViewClientComponent } from "./admin/client/view-client/view-client.component";
import { ViewPartnerComponent } from "./admin/partner/view-partner/view-partner.component";
import { PublishComponent } from './admin/publish/publish.component';
import { ClientComponent } from './admin/client/client.component';
import { PartnerComponent } from './admin/partner/partner.component';
import { TalentComponent } from './admin/talent/talent.component';
import { RequirementsComponent } from './admin/requirements/requirements.component';
import { ContractsComponent } from './admin/contracts/contracts.component';
import { InterviewsComponent } from './admin/interviews/interviews.component';
import { ViewTalentComponent } from "./admin/talent/view-talent/view-talent.component";
import { RequirementDetailsComponent } from './admin/requirements/requirement-details/requirement-details.component';
import { PmsGrowthComponent } from "./admin/pms-growth/pms-growth.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [AccessGuard],
    component: ContainerComponent,
    children: [
      // Dashboard
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [PermissionGuard],
        data: {
          permission: Permission.AccessDashboard,
          title: "Dashboard",
          breadcrumb: [
            {
              label: "Dashboard",
              url: "",
            },
          ],
        },
      },

      // Onboard
      // { path: "partner-onboard", canActivate: [PermissionGuard], data: { permission: Permission.AccessPartnerOnboard }, component: OnboardPartnerComponent },
      // { path: "client-onboard", canActivate: [PermissionGuard], data: { permission: Permission.AccessClientOnboard }, component: OnboardClientComponent },

      // Publish
      // { path: "partner-publish", canActivate: [PermissionGuard], data: { permission: Permission.AccessPartnerPublish }, component: PartnerPublishComponent },
      // { path: "employee-publish", canActivate: [PermissionGuard], data: { permission: Permission.AccessEmployeePublish }, component: EmployeePublishComponent },
      // { path: "client-publish", canActivate: [PermissionGuard], data: { permission: Permission.AccessClientPublish }, component: ClientPublishComponent },

      // User Management
      { path: "user-partner", canActivate: [PermissionGuard], data: { permission: Permission.AccessPartnerUserManagement }, component: UserPartnerComponent },
      { path: "user-employee", canActivate: [PermissionGuard], data: { permission: Permission.AccessEmployeeUserManagement }, component: UserEmployeeComponent },
      { path: "user-client", canActivate: [PermissionGuard], data: { permission: Permission.AccessClientUserManagement }, component: UserClientComponent },

      // General
      { path: "skill", canActivate: [PermissionGuard], data: { permission: Permission.AccessTitleAndSkill }, component: SkillComponent },
      { path: "technology", canActivate: [PermissionGuard], data: { permission: Permission.AccessTechnology }, component: TechnologyComponent },
      { path: "reasons", canActivate: [PermissionGuard], data: { permission: Permission.AccessReasons }, component: ReasonsComponent },
      { path: "contact", canActivate: [PermissionGuard], data: { permission: Permission.AccessContact }, component: RapidiseContactComponent },
      { path: "industry", canActivate: [PermissionGuard], data: { permission: Permission.AccessIndustry }, component: IndustryComponent },
      { path: "service", canActivate: [PermissionGuard], data: { permission: Permission.AccessService }, component: ServiceComponent },
      { path: "pms-growth",canActivate: [PermissionGuard], data: { permission: Permission.AccessGrowth },  component: PmsGrowthComponent },
      
      // Employee
      { path: "emp-languages", canActivate: [PermissionGuard], data: { permission: Permission.AccessLanguage }, component: EmpLanguagesComponent },
      { path: "emp-hobbie", canActivate: [PermissionGuard], data: { permission: Permission.AccessHobbie }, component: EmpHobbieComponent },
      { path: "emp-degree", canActivate: [PermissionGuard], data: { permission: Permission.AccessDegree }, component: EmpDegreeComponent },
      { path: "emp-branch", canActivate: [PermissionGuard], data: { permission: Permission.AccessBranch }, component: EmpBranchComponent },
      { path: "emp-award", canActivate: [PermissionGuard], data: { permission: Permission.AccessAward }, component: EmpAwardComponent },
      { path: "emp-certificate", canActivate: [PermissionGuard], data: { permission: Permission.AccessCertificate }, component: EmpCertificateComponent },

      // Deal Engine
      {
        path: "dealengine",
        component: DealEngineComponent,
        canActivate: [PermissionGuard],
        data: {
          permission: Permission.AccessDealEngine,
          title: "Dashboard",
          breadcrumb: [
            {
              label: "Dashboard",
              url: "",
            },
            {
              label: "Deal Engine",
              url: "/dashboard/dealengine",
            },
          ],
        },
      },
      //view-client
      { path: "client/:clientUserId",canActivate: [PermissionGuard], data: { permission: Permission.ViewClientProfileAction },component: ViewClientComponent },
      //view-partner
      { path: "partner/:partnerUserId",canActivate: [PermissionGuard], data: { permission: Permission.ViewPartnerProfileAction },component: ViewPartnerComponent },
      //view-talent
      { path: "talent/:talentUserId",canActivate: [PermissionGuard], data: { permission: Permission.ViewTalentProfileAction },component: ViewTalentComponent },
      // Billing
      { path: "billing", canActivate: [PermissionGuard], data: { permission: Permission.AccessBilling }, component: BillingComponent },

      // Contracts
      { path: "contracts", canActivate: [PermissionGuard], data: { permission: Permission.AccessContracts }, component: ContractComponent },
      { path: 'contract-sandbox', loadChildren: () => import('./admin/contract-sandbox/contract-sandbox.module').then(m => m.ContractSandboxModule) },

      // Interviews
      { path: "interviews", canActivate: [PermissionGuard], data: { permission: Permission.AccessInterviews }, component: InterviewComponent },

      // Requirements
      { path: "requirements", canActivate: [PermissionGuard], data: { permission: Permission.AccessRequirements }, component: RequirementComponent },

      // Activity Tracking
      { path: 'activity-tracking', loadChildren: () => import('./admin/activity-tracking/activity-tracking.module').then(m => m.ActivityTrackingModule) },

      // Access Management
      { path: "role", canActivate: [PermissionGuard], data: { permission: Permission.AccessRole }, component: RoleComponent },
      // { path: "parent-permission", canActivate: [PermissionGuard], data: { permission: Permission.AccessParentPermission }, component: ParentPermissionManagementComponent },
      // { path: "child-permission", canActivate: [PermissionGuard], data: { permission: Permission.AccessChildPermission }, component: ChildPermissionManagementComponent },
      { path: "permission-management", canActivate: [PermissionGuard], data: { permission: Permission.AccessPermissionManagement }, component: PermissionManagementComponent },
      { path: "role-management", canActivate: [PermissionGuard], data: { permission: Permission.AccessRoleManagement }, component: SubUserComponent },
      { path: 'role-management-partner', canActivate: [PermissionGuard], data: { permission: Permission.AccessRoleManagement }, loadChildren: () => import('./modules/sub-user-partner/sub-user-partner.module').then(m => m.SubUserPartnerModule) },
      { path: 'role-management-client', canActivate: [PermissionGuard], data: { permission: Permission.AccessRoleManagement }, loadChildren: () => import('./modules/sub-user-client/sub-user-client.module').then(m => m.SubUserClientModule) },


      { path: "video-conference", canActivate: [PermissionGuard], data: { permission: Permission.AccessVideoConference }, loadChildren: () => import("./modules/video-conference/video-conference.module").then((m) => m.VideoConferenceModule) },

      { path: "user-profile", loadChildren: () => import("./modules/sub-user-profile/sub-user-profile.module").then((m) => m.SubUserProfileModule) },

      { path: "profile", component: ProfileComponent },
      { path: "change-password", component: ChangePasswordComponent },
      { path: "ticket", component: TicketComponent },

      // {
      //   path: "dashboard/datatable",
      //   component: DatatableComponent,
      // },
      {
        path: "onboard",
        canActivate: [PermissionGuard], 
        data: { permission: Permission.AccessOnboard },
        component: OnboardComponent,
      },
      {
        path: "publish",
        canActivate: [PermissionGuard], 
        data: { permission: Permission.AccessPublish },
        component: PublishComponent,
      },
      {
        path: "client",
        canActivate: [PermissionGuard], 
        data: { permission: Permission.AccessClientUserManagement },
        component: ClientComponent,
      },
      {
        path: "partner",
        canActivate: [PermissionGuard], 
        data: { permission: Permission.AccessPartnerUserManagement },
        component: PartnerComponent,
      },
      {
        path: "talent",
        canActivate: [PermissionGuard], 
        data: { permission: Permission.AccessEmployeeUserManagement },
        component: TalentComponent,
      },
      {
        path: "requirement",
        canActivate: [PermissionGuard], 
        data: { permission: Permission.AccessRequirements },
        component: RequirementsComponent,
      },
      {
        path: "requirement-details/:requirementID",
        canActivate: [PermissionGuard], 
        data: { permission: Permission.ViewRequirementAction },
        component: RequirementDetailsComponent,
      },
      {
        path: "contract",
        canActivate: [PermissionGuard], 
        data: { permission: Permission.AccessContracts },
        component: ContractsComponent,
      },
      {
        path: "interview",
        canActivate: [PermissionGuard], 
        data: { permission: Permission.AccessInterviews },
        component: InterviewsComponent,
      },
      // {path:'dashboard',component:DashboardComponent},
      // {path:'emp-interview-status',component:EmpInterviewStatusComponent},
    ],
  },
  // { path: "", component: SiteConstructionComponent },
  // { path: "**", redirectTo: "", pathMatch: "full" },
  { path: "design-system", component: DesignSystemComponent },
  {
    path: "login",
    component: LoginComponent,
  },
  { path: "user-password-creation/:txEmailToken", loadChildren: () => import("./modules/sub-user-password-creation/sub-user-password-creation.module").then((m) => m.SubUserPasswordCreationModule) },
  { path: "user-reset-password/:txEmailToken", loadChildren: () => import("./modules/sub-user-reset-password/sub-user-reset-password.module").then((m) => m.SubUserResetPasswordModule) },
];

export const AppRoutingModule = RouterModule.forRoot(routes, { useHash: false });
