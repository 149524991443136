import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProfessionalDetailsService {
  constructor(private http: HttpClient) {}

  GetProfessionalDetails(talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/professional-details?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UploadProfessionalDetails(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/employee/v1/professional-details?iUserId=${talentUserId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetDesignationList() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = environment.baseUrl + "/common/v1/list/get-designation";
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
