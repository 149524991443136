import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyDocumentsService {

  constructor( private http: HttpClient ) { }

  GetCompanyDocuments(clientUserId:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/company-documents?iUserId=${clientUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateDocuments(data: any,clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/company-documents?iUserId=${clientUserId}`;
      const formData: FormData = new FormData();
      formData.append("vEINNumber", data.vEINNumber);
      formData.append("vCompanyFileNumber", data.vCompanyFileNumber);
      formData.append("vCorporateIdentityNumber", data.vCorporateIdentityNumber);
      formData.append("vDUNUSNumber", data.vDUNUSNumber);
      formData.append("vCertificateOfIncorporationPdf", data.vCertificateOfIncorporationPdf);
      formData.append("vGoodStandingCertificate", data.vGoodStandingCertificate);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteDocument(document:any,clientUserId:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/company-document/${document}?iUserId=${clientUserId}`;
      this.http
        .delete(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCheckApplicable(clientUserId:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/check-applicable?iUserId=${clientUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  CheckApplicable(data: any,clientUserId:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/check-applicable?iUserId=${clientUserId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
