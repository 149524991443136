import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disCertificate',
  pure: true
})

export class DisCertificatePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    let certificateLevel;

    switch (value) {
      case 1:
        certificateLevel = 'CMMI Level 1';
        break;
      case 2:
        certificateLevel = 'CMMI Level 2';
        break;
      case 3:
        certificateLevel = 'CMMI Level 3';
        break;
      case 4:
        certificateLevel = 'CMMI Level 4';
        break;
      case 5:
        certificateLevel = 'CMMI Level 5';
        break;
      case 6:
        certificateLevel = 'ISO 9001';
        break;
      case 7:
        certificateLevel = 'ISO 14001';
        break;
      case 8:
        certificateLevel = 'ISO 22000';
        break;
      case 9:
        certificateLevel = 'ISO 13485';
        break;
      case 10:
        certificateLevel = 'ISO 20121';
        break;
      case 11:
        certificateLevel = 'ISO 639';
        break;
      case 12:
        certificateLevel = 'ISO 45001';
        break;
      case 13:
        certificateLevel = 'ISO 4217';
        break;
      case 14:
        certificateLevel = 'ISO 37001';
        break;
      case 15:
        certificateLevel = 'ISO/IEC 17025';
        break;
      case 16:
        certificateLevel = 'ISO 26000';
        break;
      case 17:
        certificateLevel = 'ISO 8601';
        break;
      case 18:
        certificateLevel = 'ISO 31000';
        break;
      case 19:
        certificateLevel = 'ISO 3166';
        break;
      case 20:
        certificateLevel = 'ISO 50001';
        break;
      case 21:
        certificateLevel = 'ISO/IEC 27001';
        break;
      case 22:
        certificateLevel = 'E & O';
        break;
      case 23:
        certificateLevel = 'Corporate Filling Number';
        break;
      case 24:
        certificateLevel = 'Workers Crop';
        break;
      case 25:
        certificateLevel = 'GL/BOP';
        break;
      default:
        certificateLevel = 'default';
        break;
    }
    let str = value + " %";
    return str;
  }



}
