import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { EducationService } from './education.service';
import { EventEmitterService } from '../../../../config/event-emitter.service';
import { NotificationService } from '../../../../config/notification.service';
import { StaticMsg } from '../../../../config/en';
import { environment } from "../../../../../environments/environment";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UserPermissionService } from 'src/app/utils/services/user-permission.service';
import { Permission } from 'src/app/utils/enums/permission.enum';

@Component({
  selector: 'talent-education',
  templateUrl: './education.component.html',
  providers: [
    NgbModalConfig,
    NgbModal
  ]
})
export class TalentEducationComponent implements OnInit {
  @Input() talentUserId:any;
  educationToggle:boolean=false;

   // toggle actions permission
   addTalentEducationActionPermission: boolean = false;
   editTalentEducationActionPermission: boolean = false;


  imagePath = environment.image_path;
  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false
  };

  addEducationForm!: FormGroup;
  updateEducationForm!: FormGroup;
  addeducationSubmitted: any = false;
  editeducationSubmitted: any = false;
  educationArray: any = [];
  vDegCertificate: any;
  today = new Date(); 
  degreeListArray: any = [];
  branchListArray: any = [];
  selectedEducationCertName: any = '';
  selectedEducationCertUrl: any = '';

  iDegreeId!: number;
  filteredAddDegree!: Observable<any[]>;
  filteredEditDegree!: Observable<any[]>;
  iBranchId!: number;
  filteredAddBranch!: Observable<any[]>;
  filteredEditBranch!: Observable<any[]>;
  
  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private educationService: EducationService,
    public eventEmitterService: EventEmitterService,
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;

    // assign individual permissions
    const AddTalentEducationAction: Permission = Permission.AddTalentEducationAction;
    const EditTalentEducationAction: Permission = Permission.EditTalentEducationAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddTalentEducationAction)) {
      this.addTalentEducationActionPermission = true;
    }

    if (this.userPermissionService.isAuthorizedFor(EditTalentEducationAction)) {
      this.editTalentEducationActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data:any) => {
      this.updateProfileStatus(data);
    });

    this.addEducationForm = new FormGroup({
      vDegree: new FormControl('', [Validators.required]),
      vOtherDegree: new FormControl('', [Validators.required]),
      vBranch: new FormControl('', [Validators.required]),
      vOtherBranch: new FormControl('', [Validators.required]),
      iJoiningYear: new FormControl('', [Validators.required]),
      iCompletionYear: new FormControl('', [Validators.required]),
      iCgpa: new FormControl('', [Validators.required, Validators.pattern(`(^([1-9]{1,2})([\.]{1})([0-9]{1,2})?$)`) ]),
      vCollege: new FormControl('', [Validators.required, Validators.pattern(`^[a-zA-Z]+( ?[a-zA-Z]+)*$`)]),
      vDegCertificate: new FormControl('')
    });

    this.updateEducationForm = new FormGroup({
      vDegree: new FormControl('', [Validators.required]),
      vOtherDegree: new FormControl('', [Validators.required]),
      vBranch: new FormControl('', [Validators.required]),
      vOtherBranch: new FormControl('', [Validators.required]),
      iJoiningYear: new FormControl('', [Validators.required]),
      iCompletionYear: new FormControl('', [Validators.required]),
      iCgpa: new FormControl('', [Validators.required, Validators.pattern(`(^([1-9]{1,2})([\.]{1})([0-9]{1,2})?$)`)]),
      vCollege: new FormControl('', [Validators.required, Validators.pattern(`^[a-zA-Z]+( ?[a-zA-Z]+)*$`)]),
      vDegCertificate: new FormControl(''),
      iEduId: new FormControl('')
    });
    this.GetDegreeList();
    this.GetBranchList();
    this.GetEducation();

    this.filteredAddDegree = this.addEducationForm.controls["vDegree"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value, this.degreeListArray, "vDegreeName") : this.degreeListArray.slice()))
    );

    this.filteredAddBranch = this.addEducationForm.controls["vBranch"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value, this.branchListArray, "vBranchName") : this.branchListArray.slice()))
    );

    this.filteredEditDegree = this.updateEducationForm.controls["vDegree"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value, this.degreeListArray, "vDegreeName") : this.degreeListArray.slice()))
    );

    this.filteredEditBranch = this.updateEducationForm.controls["vBranch"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value, this.branchListArray, "vBranchName") : this.branchListArray.slice()))
    );
  }

  public educationCardCompleted:any = false;
  updateProfileStatus(data:any){
    this.educationCardCompleted = data['educationCardCompleted'];
  }

  private _filter(value: string, array: any, key: any): string[] {
    let filterValue: string;
    if (value) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = "";
    }

    return array.filter((option: any) => option[key].toLowerCase().indexOf(filterValue) === 0);
  }

  GetDegreeId(iDegreeId: number) {
    this.iDegreeId = iDegreeId;
  }

  GetBranchId(iBranchId: number) {
    this.iBranchId = iBranchId;
  }

  UploadFileEvt(event: any) {
    let fileSize = event['target']['files'][0]['size'] / 1024;
    if(fileSize > 4*1024 ){
      this.notifyService.showError(StaticMsg.FileLargerError,'');
      return;
    }else{
      if ( event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/vnd.ms-excel" || event.target.files[0].type == "image/x-png" || event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" ) {
        this.vDegCertificate = event.target.files[0];
      } else {
        this.notifyService.showError(StaticMsg.NotPdfImageFileError,'');
      }
    }
  }

  GetDegreeList(){
    this.educationService.GetDegreeList().then((result: any) => {
      if (result.code == 200) {
        this.degreeListArray = result['data'];
      }
    });
  }

  GetBranchList(){
    this.educationService.GetBranchList().then((result: any) => {
      if (result.code == 200) {
        this.branchListArray = result['data'];
      }
    });
  }

  GetEducation(){
    this.educationService.GetEducation(this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.educationArray = result['data'];
      } else {
        this.educationArray = [];
      }
    });
  }

  SelectionChange(value:any) {
    if ( value == 'Other - if degree is not listed') {
      this.addEducationForm.controls.vOtherDegree.setValidators([Validators.required]);
      this.updateEducationForm.controls.vOtherDegree.setValidators([Validators.required]);
    }
    if ( value && value != 'Other - if degree is not listed') {
      this.addEducationForm.controls.vOtherDegree.patchValue('');
      this.addEducationForm.controls.vOtherDegree.clearValidators();
      this.addEducationForm.controls.vOtherDegree.setErrors(null);
      this.updateEducationForm.controls.vOtherDegree.patchValue('');
      this.updateEducationForm.controls.vOtherDegree.clearValidators();
      this.updateEducationForm.controls.vOtherDegree.setErrors(null);
    }
    if ( value == 'Other - if branch is not listed') {
      this.addEducationForm.controls.vOtherBranch.setValidators([Validators.required]);
      this.updateEducationForm.controls.vOtherBranch.setValidators([Validators.required]);
    }
    if ( value && value != 'Other - if branch is not listed') {
      this.addEducationForm.controls.vOtherBranch.patchValue('');
      this.addEducationForm.controls.vOtherBranch.clearValidators();
      this.addEducationForm.controls.vOtherBranch.setErrors(null);
      this.updateEducationForm.controls.vOtherBranch.patchValue('');
      this.updateEducationForm.controls.vOtherBranch.clearValidators();
      this.updateEducationForm.controls.vOtherBranch.setErrors(null);
    }
  }

  AddEducationModal(education:any,hasPermission:boolean) {
    if(hasPermission){
      this.modalService.open(education, { centered:true, windowClass: 'profile-modal' });
    this.addEducationForm.reset();
    this.vDegCertificate = '';
      } else {
        this.notifyService.showError("Not Authorized!", "");
      }
  }

  UpdateEducationModal(education:any, element: any,hasPermission:boolean) {
    if(hasPermission){
    this.updateEducationForm.reset();
    this.modalService.open(education, { centered:true, windowClass: 'profile-modal' });
    if(element){
      this.selectedEducationCertName = element['vDegreeCertificateName'];
      this.selectedEducationCertUrl = element['vDocUrl']
      let degree = this.degreeListArray.find((c:any) => c.iDegreeId == element.iDegreeId);
      if (degree.vDegreeName == 'Other - if degree is not listed') {
        this.updateEducationForm.controls.vOtherDegree.setValidators([Validators.required]);
      }
      if ( degree.vDegreeName && degree.vDegreeName != 'Other - if degree is not listed') {
        this.updateEducationForm.controls.vOtherDegree.patchValue('');
        this.updateEducationForm.controls.vOtherDegree.clearValidators();
        this.updateEducationForm.controls.vOtherDegree.setErrors(null);
      }
      let branch = this.branchListArray.find((c:any) => c.iBranchId == element.iBranchId);
      if (branch.vBranchName == 'Other - if branch is not listed') {
        this.updateEducationForm.controls.vOtherBranch.setValidators([Validators.required]);
      }
      if ( branch.vBranchName && branch.vBranchName != 'Other - if branch is not listed') {
        this.updateEducationForm.controls.vOtherBranch.patchValue('');
        this.updateEducationForm.controls.vOtherBranch.clearValidators();
        this.updateEducationForm.controls.vOtherBranch.setErrors(null);
      }
      if(element.vDocUrl){
        this.updateEducationForm.controls.vDegCertificate.patchValue('');
        this.updateEducationForm.controls.vDegCertificate.clearValidators();
      } else {
        this.updateEducationForm.controls.vDegCertificate.clearValidators();
      }
      this.updateEducationForm.patchValue({
        vDegree: degree.vDegreeName,
        vBranch: branch.vBranchName,
        vOtherDegree: element.vOtherDegree,
        vOtherBranch: element.vOtherBranch,
        iJoiningYear: new Date(element.iJoin * 1000),
        iCompletionYear: new Date(element.iComplete * 1000),
        iCgpa: element.vPercentage,
        vCollege: element.vUniversity,
        iEduId: element.iEduId
      });
    }
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  AddEducationProcess() {
    this.addeducationSubmitted = true;
    if (this.addEducationForm.invalid) {
      return;
    }
    let reqData = {
      iDegreeId: this.iDegreeId,
      iBranchId: this.iBranchId,
      iJoin: this.addEducationForm.value.iJoiningYear,
      iComplete: this.addEducationForm.value.iCompletionYear,
      vPercentage: this.addEducationForm.value.iCgpa,
      vUniversity: this.addEducationForm.value.vCollege,
      vDocUrl: this.vDegCertificate,
      vOtherDegree: this.addEducationForm.value.vOtherDegree,
      vOtherBranch: this.addEducationForm.value.vOtherBranch
    }
    this.educationService.AddEducation(reqData,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.GetEducation();
        this.notifyService.showSuccess(result.message,'');
        this.modalService.dismissAll();
      }else{
        this.notifyService.showError(result.message,'');
      }
    });
  }

  UpdateEducationProcess() {
    this.editeducationSubmitted = true;
    if (this.updateEducationForm.invalid) {
      return;
    }

    let reqData = {
      iDegreeId: this.iDegreeId,
      iBranchId: this.iBranchId,
      iJoin: this.updateEducationForm.value.iJoiningYear,
      iComplete: this.updateEducationForm.value.iCompletionYear,
      vPercentage: this.updateEducationForm.value.iCgpa,
      vUniversity: this.updateEducationForm.value.vCollege,
      vDocUrl: this.vDegCertificate,
      iEduId: this.updateEducationForm.value.iEduId,
      vOtherDegree: this.updateEducationForm.value.vOtherDegree,
      vOtherBranch: this.updateEducationForm.value.vOtherBranch
    }
    this.educationService.UpdateEducation(reqData,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.vDegCertificate = '';
        this.GetEducation();
        this.updateEducationForm.reset();
        this.notifyService.showSuccess(result.message,'');
        this.modalService.dismissAll();
      }else{
        this.modalService.dismissAll();
      }
    });
  }

  DaterangeGreaterSelection(event:any) {
    if (new Date(this.updateEducationForm.controls['iCompletionYear'].value) <= new Date(event.target.value)) {
      this.updateEducationForm.controls['iCompletionYear'].setValue(null);
    }
  }

  crossClick() {

    if(this.vDegCertificate) {
      this.vDegCertificate = '';
      this.updateEducationForm.controls['vDegCertificate'].setValue(null);
      this.GetEducation();
    }
  }
}
