<div class="page-content profile-section">
    <div class="profile-top">
      <div class="d-flex justify-content-between align-items-end mb-4">
        <h1 class="title-large">View Profile</h1>
        <div>
          <button *ngIf="iProfilePublishCompleted == 1" name="publish-button" id="publish-button" class="primary-small-btn"
            type="button" name="button" (click)="PublishProfile(publishClientProfileActionPermission)" [ngClass]="publishClientProfileActionPermission ? '':'unauthorized-cursor'" placement="auto" [ngbTooltip]="publishClientProfileActionPermission ?  '':'Not Authorized'">Publish</button>
        </div>
        </div>
      <div class="progress-box" *ngIf="iProfilePublishCompleted == 1">
            <p class="text-main">Complete your profile to unlock the full potential</p>
            <p class="text-sub m-0">Completing your profile helps us understand your offerings thoroughly. Next step will be to onboard your team!</p>
            <div class="d-flex flex-column">
                <div class="text d-flex">
                    <div class="example-spacer">
                    </div>
                    <span class="text-color">{{progressValue}}%</span>/100%
                </div>
                <div class="progress">
                    <div class="progress-bar progress-bar-success" role="progressbar" [style.width]="this.progressValue+'%'">
                    </div>
                </div>
            </div>
      </div>
      <div class="link-group mt-2">
        <a (click)="goToLink(1)" class="link" [ngClass]="targetLink == 1 ? 'link-active':''">Primary Details
          <img id="primary-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
          alt="status-icon" *ngIf="!primaryDetailsCardCompleted" placement="auto" ngbTooltip="Pending"></a>
        <a (click)="goToLink(2)" class="link" [ngClass]="targetLink == 2 ? 'link-active':''">Company Details
        </a>
        <a (click)="goToLink(3)" class="link" [ngClass]="targetLink == 3 ? 'link-active':''">Executive Details
          <img id="executive-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
            alt="status-icon" *ngIf="!executiveDetailsCardCompleted" placement="auto" ngbTooltip="Pending">
        </a>
        <a (click)="goToLink(4)" class="link" [ngClass]="targetLink == 4 ? 'link-active':''">Company Documents
          <img id="company-documents-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
            alt="status-icon" *ngIf="!companyDocumentsCardCompleted" placement="auto" ngbTooltip="Pending">
        </a>
        <a (click)="goToLink(5)" class="link" [ngClass]="targetLink == 5 ? 'link-active':''">Certifications
          <img id="primary-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
            alt="status-icon" *ngIf="!certificationsCardCompleted" placement="auto" ngbTooltip="Pending">
        </a>
      </div>
    </div>
    <div class="profile-main" [ngClass]="{'profile-main-height': iProfilePublishCompleted != 1}">
      <perfect-scrollbar class="pf-scroll">
        <div #primaryDetails *ngIf="targetLink==1">
          <client-primary-details [clientUserId]="clientUserId"></client-primary-details>
        </div>
        <div #companyDetails *ngIf="targetLink==2">
          <client-company-details [clientUserId]="clientUserId"></client-company-details>
        </div>
        <div #executiveDetails *ngIf="targetLink==3">
          <client-executive-details [clientUserId]="clientUserId"></client-executive-details>
        </div>
        <div #companyDocuments *ngIf="targetLink==4">
          <client-company-documents [clientUserId]="clientUserId"></client-company-documents>
        </div>
        <div #certifications *ngIf="targetLink==5">
          <client-certifications [clientUserId]="clientUserId"></client-certifications>
        </div> 
      </perfect-scrollbar>
    </div>
</div>

