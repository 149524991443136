<div name="additional-details-box" id="additional-details-box" class="col-12">
  <div name="additional-details-card-container" id="additional-details-card-container" class="card-container" *ngIf="additionalDetailsToggle==false">
    <div name="additional-details-card-header" id="additional-details-card-header" class="card-header">
      <div name="additional-details-title-section" id="additional-details-title-section" class="title-section">
        <h4 name="additional-details-card-title" id="additional-details-card-title" class="card-title">Additional Details</h4>
        <div placement="right auto" [ngbTooltip]="editPartnerAdditionalDetailsActionPermission ? '':'Not Authorized'" [ngClass]="editPartnerAdditionalDetailsActionPermission ? '':'unauthorized-cursor'">
          <button name="additional-details-card-edit-button" id="additional-details-card-edit-button" class="card-edit-button" type="button" name="card-edit-button" (click)="toggleAdditionalDetails(editPartnerAdditionalDetailsActionPermission)" [ngClass]="editPartnerAdditionalDetailsActionPermission ? '':'unauthorized-action'">
            <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
          </button>
        </div>
      </div>
      <div name="additional-details-status-section" id="additional-details-status-section" class="status-section">
        <div name="additional-details-incomplete-status-box" id="additional-details-incomplete-status-box" class="status-box" *ngIf="!additionalDetailsCardCompleted">
          <img id="additional-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'" alt="status-icon">
          <label name="additional-details-incomplete-status-text" id="additional-details-incomplete-status-text" class="status-text incomplete">Pending</label>
        </div>
        <div name="additional-details-completed-status-box" id="additional-details-completed-status-box" class="status-box" *ngIf="additionalDetailsCardCompleted">
          <img id="additional-details-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'" alt="status-icon">
          <label name="additional-details-completed-status-text" id="additional-details-completed-status-text" class="status-text completed">Completed</label>
        </div>
      </div>
    </div>
    <div name="additional-details-card" id="additional-details-card" class="card" [ngClass]="additionalDetailsCardCompleted ? 'completed' : 'incomplete'">
      <div name="additional-details-card-content" id="additional-details-card-content" class="card-content">
        <div name="additional-details-row" id="additional-details-row" class="row">
          <div name="additional-details-col-1" id="additional-details-col-1" class="col-12 col-lg-3 col-sm-6">
            <label name="additional-details-col-1-label" id="additional-details-col-1-label" class="label">
              Contract to Hire
            </label>
            <label name="additional-details-col-1-text" id="additional-details-col-1-text" *ngIf="additionalDetailsArray.iContractHireName" class="text bottom-spacing">
              {{ additionalDetailsArray?.iContractHireName }}
            </label>
            <label name="additional-details-col-1-text" id="additional-details-col-1-text" class="text bottom-spacing" *ngIf="!additionalDetailsArray.iContractHireName">Not Available</label>
          </div>
          <div name="additional-details-col-2" id="additional-details-col-2" class="col-12 col-lg-3 col-sm-6">
            <label name="additional-details-col-2-label" id="additional-details-col-2-label" class="label">
              Employee Relocation
            </label>
            <label name="additional-details-col-2-text" id="additional-details-col-2-text" class="text bottom-spacing" *ngIf="additionalDetailsArray.iEmployeeRelocationName">
              {{ additionalDetailsArray?.iEmployeeRelocationName }}
            </label>
            <label name="additional-details-col-2-text" id="additional-details-col-2-text" class="text bottom-spacing" *ngIf="!additionalDetailsArray.iEmployeeRelocationName">Not Available</label>
          </div>
          <div name="additional-details-col-3" id="additional-details-col-3" class="col-12">
            <label name="additional-details-col-3-label" id="additional-details-col-3-label" class="label">
              Working Hours
            </label>
            <div class="row">
              <div class="text weektime-margin">
                <label class="weekday-width">Monday : </label>
                <label *ngIf="!additionalDetailsArray?.iMondayStatus">Not working on this day</label>
                <label *ngIf="additionalDetailsArray?.iMondayStatus">{{additionalDetailsArray?.tMondayStart}} - {{additionalDetailsArray?.tMondayEnd}}</label>
              </div>
              <div class="text weektime-margin">
                <label class="weekday-width">Tuesday : </label>
                <label *ngIf="!additionalDetailsArray?.iTuesdayStatus">Not working on this day</label>
                <label *ngIf="additionalDetailsArray?.iTuesdayStatus">{{additionalDetailsArray?.tTuesdayStart}} - {{additionalDetailsArray?.tTuesdayEnd}}</label>
              </div>
              <div class="text weektime-margin">
                <label class="weekday-width">Wednesday : </label>
                <label *ngIf="!additionalDetailsArray?.iWednesdayStatus">Not working on this day</label>
                <label *ngIf="additionalDetailsArray?.iWednesdayStatus">{{additionalDetailsArray?.tWednesdayStart}} - {{additionalDetailsArray?.tWednesdayEnd}}</label>
              </div>
              <div class="text weektime-margin">
                <label class="weekday-width">Thursday : </label>
                <label *ngIf="!additionalDetailsArray?.iThursdayStatus">Not working on this day</label>
                <label *ngIf="additionalDetailsArray?.iThursdayStatus">{{additionalDetailsArray?.tThursdayStart}} - {{additionalDetailsArray?.tThursdayEnd}}</label>
              </div>
              <div class="text weektime-margin">
                <label class="weekday-width">Friday : </label>
                <label *ngIf="!additionalDetailsArray?.iFridayStatus">Not working on this day</label>
                <label *ngIf="additionalDetailsArray?.iFridayStatus">{{additionalDetailsArray?.tFridayStart}} - {{additionalDetailsArray?.tFridayEnd}}</label>
              </div>
              <div class="text weektime-margin">
                <label class="weekday-width">Saturday : </label>
                <label *ngIf="!additionalDetailsArray?.iSaturdayStatus">Not working on this day</label>
                <label *ngIf="additionalDetailsArray?.iSaturdayStatus">{{additionalDetailsArray?.tSaturdayStart}} - {{additionalDetailsArray?.tSaturdayEnd}}</label>
              </div>
              <div class="text">
                <label class="weekday-width">Sunday : </label>
                <label *ngIf="!additionalDetailsArray?.iSundayStatus">Not working on this day</label>
                <label *ngIf="additionalDetailsArray?.iSundayStatus">{{additionalDetailsArray?.tSundayStart}} - {{additionalDetailsArray?.tSundayEnd}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="card-container" *ngIf="additionalDetailsToggle==true">
  <div name="additional-details-card-header" id="additional-details-card-header" class="card-header">
    <div name="additional-details-title-section" id="additional-details-title-section" class="title-section">
      <h4 name="additional-details-card-title" id="additional-details-card-title" class="card-title">Additional Details</h4>
    </div>
  </div>
  <div class="edit-profile">
    <div name="additional-details-modal-body" id="additional-details-modal-body" class="modal-body modal-scroll">
      <form id="additionalDetailsForm" class="form" [formGroup]="additionalDetailsForm" (ngSubmit)="UpdateAdditionalDetails()">
        <div name="additional-details-row" id="additional-details-row" class="row">
          <div name="additional-details-col-1" id="additional-details-col-1" class="col-12 col-md-6">
            <div>
              <label for="vContractToHire" name="additional-details-col-1-bold-title" id="additional-details-col-1-bold-title" class="custom-label mb-2">Contract to Hire <span class="text-danger">*</span></label>
            </div>
            <div>
              <mat-radio-group aria-label="Contract to Hire" id="vContractToHire" formControlName="vContractToHire">
                <mat-radio-button name="additional-details-col-1-radio-true" id="additional-details-col-1-radio-true" value="1" [checked] ="additionalDetailsForm.get('vContractToHire')?.value == 1">True</mat-radio-button>
                <mat-radio-button name="additional-details-col-1-radio-false" id="additional-details-col-1-radio-false" value="2" [checked] ="additionalDetailsForm.get('vContractToHire')?.value == 2">False</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="d-flex">
              <mat-error name="additional-details-col-1-error-0" id="additional-details-col-1-error-0" class="radio-btn-error" *ngIf="additionalDetailsSubmitted && additionalDetailsForm.controls['vContractToHire'].invalid">
                <mat-error name="additional-details-col-1-error-1" id="additional-details-col-1-error-1" class="radio-btn-error" *ngIf="additionalDetailsForm.controls['vContractToHire'].errors!.required">
                  Contract to Hire is required
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div name="additional-details-col-2" id="additional-details-col-2" class="col-12 col-md-6">
            <div>
              <label for="vEmployeeRelocation" name="additional-details-col-2-bold-title" id="additional-details-col-2-bold-title" class="custom-label mt-4 mt-md-auto mb-2">Employee Relocation <span class="text-danger">*</span></label>
            </div>
            <div>
              <mat-radio-group aria-label="Employee Relocation" id="vEmployeeRelocation" formControlName="vEmployeeRelocation">
                <mat-radio-button name="additional-details-col-2-radio-domestic" id="additional-details-col-2-radio-domestic" value="1" [checked] ="additionalDetailsForm.get('vEmployeeRelocation')?.value == 1">Domestic</mat-radio-button>
                <mat-radio-button name="additional-details-col-2-radio-international" id="additional-details-col-2-radio-international" value="2" [checked] ="additionalDetailsForm.get('vEmployeeRelocation')?.value == 2">International</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="d-flex">
              <mat-error name="additional-details-col-2-error-0" id="additional-details-col-2-error-0" class="radio-btn-error" *ngIf="additionalDetailsSubmitted && additionalDetailsForm.controls['vEmployeeRelocation'].invalid">
                <mat-error name="additional-details-col-2-error-0" id="additional-details-col-2-error-0" class="radio-btn-error" *ngIf="additionalDetailsForm.controls['vEmployeeRelocation'].errors!.required">
                  Employee Relocation is required
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div name="additional-details-working-hours-container" id="additional-details-working-hours-container" class="working-hours-container">
            <label for="vWorkingHours" name="additional-details-working-hours-container-title" id="additional-details-working-hours-container-title" class="label top-spacing mb-2">Working Hours<span class="label text-secondary"> (Please use 24-hour format)</span></label>
            <div name="additional-details-week-time-responsive" id="additional-details-week-time-responsive" class="week-time-responsive justify-content-between">
              <div name="additional-details-monday-week-copytime-class" id="additional-details-monday-week-copytime-class" class="week-copytime-class">
                <div name="additional-details-monday-weekday-width" id="additional-details-monday-weekday-width" class="weekday-width">
                  <mat-checkbox name="additional-details-monday" id="additional-details-monday" class="workinghoursCheckbox" formControlName="iMondayStatus" [checked]="additionalDetailsArray?.iMondayStatus">Monday</mat-checkbox>
                </div>
                <div name="additional-details-monday-working-hours" id="additional-details-monday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iMondayStatus == 0">
                  <div name="additional-details-monday-hour-to" id="additional-details-monday-hour-to" class="hour-to p-0">Not working on this day</div>
                </div>
                <div name="additional-details-monday-working-hours" id="additional-details-monday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iMondayStatus == 1">
                  <mat-form-field appearance="fill" name="additional-details-vMondayStartHour" id="additional-details-vMondayStartHour" class="hour-container">
                    <input type="text" matInput id="vMondayStartHour" formControlName="vMondayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                  </mat-form-field>
                  <mat-form-field appearance="fill" name="additional-details-vMondayStartMinute" id="additional-details-vMondayStartMinute" class="minute-container">
                    <input type="text" matInput id="vMondayStartMinute" formControlName="vMondayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                  </mat-form-field>
                  <div class="hour-to">to</div>
                  <mat-form-field appearance="fill" name="additional-details-vMondayEndHour" id="additional-details-vMondayEndHour" class="hour-container">
                    <input type="text" matInput id="vMondayEndHour" formControlName="vMondayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                  </mat-form-field>
                  <mat-form-field appearance="fill" name="additional-details-vMondayEndMinute" id="additional-details-vMondayEndMinute" class="minute-container">
                    <input type="text" matInput id="vMondayEndMinute" formControlName="vMondayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                  </mat-form-field>
                  <div class="error-box">
                    <mat-error class="reduce-bottom-space" *ngIf="(additionalDetailsForm.controls['vMondayStartHour']?.value>=additionalDetailsForm.controls['vMondayEndHour']?.value) || (additionalDetailsForm.controls['vMondayStartHour'].invalid && (additionalDetailsForm.controls['vMondayStartHour'].errors!.pattern || additionalDetailsForm.controls['vMondayStartHour'].errors!.required)) || (additionalDetailsForm.controls['vMondayStartMinute'].invalid && (additionalDetailsForm.controls['vMondayStartMinute'].errors!.pattern || additionalDetailsForm.controls['vMondayStartMinute'].errors!.required)) || (additionalDetailsForm.controls['vMondayEndHour'].invalid && (additionalDetailsForm.controls['vMondayEndHour'].errors!.pattern || additionalDetailsForm.controls['vMondayEndHour'].errors!.required)) || (additionalDetailsForm.controls['vMondayEndMinute'].invalid && (additionalDetailsForm.controls['vMondayEndMinute'].errors!.pattern || additionalDetailsForm.controls['vMondayEndMinute'].errors!.required))">
                      Please Enter Valid Time
                    </mat-error>
                  </div>
                </div>
              </div>
              <div name="additional-details-copy-time-container-copy-icon" id="additional-details-copy-time-container-copy-icon" class="copy-time-container">
                <a name="additional-details-copy-time-container-copy-icon" id="additional-details-copy-time-container-copy-icon" class="copy-time-btn" href="javascript:void(0)" (click)="copyToAll()">
                  <img [src]="imagePath + 'copy_hours_icon.svg'" class="copy-icon" alt="copy icon">
                  <span name="additional-details-copy-time-container-time-text" id="additional-details-copy-time-container-time-text" class="time-text">Copy time to all</span>
                </a>
              </div>
            </div>
            <div name="additional-details-tuesday-week-time-row" id="additional-details-tuesday-week-time-row" class="week-time-row spacing-top">
              <div name="additional-details-tuesday-weekday-width" id="additional-details-tuesday-weekday-width" class="weekday-width">
                <mat-checkbox name="additional-details-tuesday" id="additional-details-tuesday" class="workinghoursCheckbox" formControlName="iTuesdayStatus" [checked]="additionalDetailsArray?.iTuesdayStatus">Tuesday</mat-checkbox>
              </div>
              <div name="additional-details-tuesday-working-hours" id="additional-details-tuesday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iTuesdayStatus == 0">
                <div name="additional-details-tuesday-hour-to" id="additional-details-tuesday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="additional-details-tuesday-working-hours" id="additional-details-tuesday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iTuesdayStatus == 1">
                <mat-form-field appearance="fill" name="additional-details-tuesday-vTuesdayStartHour" id="additional-details-tuesday-vTuesdayStartHour" class="hour-container">
                  <input type="text" matInput id="vTuesdayStartHour" formControlName="vTuesdayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-tuesday-vTuesdayStartMinute" id="additional-details-tuesday-vTuesdayStartMinute" class="minute-container">
                  <input type="text" matInput id="vTuesdayStartMinute" formControlName="vTuesdayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="additional-details-tuesday-vTuesdayEndHour" id="additional-details-tuesday-vTuesdayEndHour" class="hour-container">
                  <input type="text" matInput id="vTuesdayEndHour" formControlName="vTuesdayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-tuesday-vTuesdayEndMinute" id="additional-details-tuesday-vTuesdayEndMinute" class="minute-container">
                  <input type="text" matInput id="vTuesdayEndMinute" formControlName="vTuesdayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(additionalDetailsForm.controls['vTuesdayStartHour']?.value>=additionalDetailsForm.controls['vTuesdayEndHour']?.value) || (additionalDetailsForm.controls['vTuesdayStartHour'].invalid && (additionalDetailsForm.controls['vTuesdayStartHour'].errors!.pattern || additionalDetailsForm.controls['vTuesdayStartHour'].errors!.required)) || (additionalDetailsForm.controls['vTuesdayStartMinute'].invalid && (additionalDetailsForm.controls['vTuesdayStartMinute'].errors!.pattern || additionalDetailsForm.controls['vTuesdayStartMinute'].errors!.required)) || (additionalDetailsForm.controls['vTuesdayEndHour'].invalid && (additionalDetailsForm.controls['vTuesdayEndHour'].errors!.pattern || additionalDetailsForm.controls['vTuesdayEndHour'].errors!.required)) || (additionalDetailsForm.controls['vTuesdayEndMinute'].invalid && (additionalDetailsForm.controls['vTuesdayEndMinute'].errors!.pattern || additionalDetailsForm.controls['vTuesdayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
            <div name="additional-details-wednesday-week-time-row" id="additional-details-wednesday-week-time-row" class="week-time-row spacing-top">
              <div name="additional-details-wednesday-weekday-width" id="additional-details-wednesday-weekday-width" class="weekday-width">
                <mat-checkbox name="additional-details-wednesday" id="additional-details-wednesday" class="workinghoursCheckbox" formControlName="iWednesdayStatus" [checked]="additionalDetailsArray?.iWednesdayStatus">Wednesday</mat-checkbox>
              </div>
              <div name="additional-details-wednesday-working-hours" id="additional-details-wednesday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iWednesdayStatus == 0">
                <div name="additional-details-wednesday-hour-to" id="additional-details-wednesday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="additional-details-wednesday-working-hours" id="additional-details-wednesday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iWednesdayStatus == 1">
                <mat-form-field appearance="fill" name="additional-details-wednesday-vWednesdayStartHour" id="additional-details-wednesday-vWednesdayStartHour" class="hour-container">
                  <input type="text" matInput id="vWednesdayStartHour" formControlName="vWednesdayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-wednesday-vWednesdayStartMinute" id="additional-details-wednesday-vWednesdayStartMinute" class="minute-container">
                  <input type="text" matInput id="vWednesdayStartMinute" formControlName="vWednesdayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="additional-details-wednesday-vWednesdayEndHour" id="additional-details-wednesday-vWednesdayEndHour" class="hour-container">
                  <input type="text" matInput id="vWednesdayEndHour" formControlName="vWednesdayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-wednesday-vWednesdayEndMinute" id="additional-details-wednesday-vWednesdayEndMinute" class="minute-container">
                  <input type="text" matInput id="vWednesdayEndMinute" formControlName="vWednesdayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(additionalDetailsForm.controls['vWednesdayStartHour']?.value>=additionalDetailsForm.controls['vWednesdayEndHour']?.value) || (additionalDetailsForm.controls['vWednesdayStartHour'].invalid && (additionalDetailsForm.controls['vWednesdayStartHour'].errors!.pattern || additionalDetailsForm.controls['vWednesdayStartHour'].errors!.required)) || (additionalDetailsForm.controls['vWednesdayStartMinute'].invalid && (additionalDetailsForm.controls['vWednesdayStartMinute'].errors!.pattern || additionalDetailsForm.controls['vWednesdayStartMinute'].errors!.required)) || (additionalDetailsForm.controls['vWednesdayEndHour'].invalid && (additionalDetailsForm.controls['vWednesdayEndHour'].errors!.pattern || additionalDetailsForm.controls['vWednesdayEndHour'].errors!.required)) || (additionalDetailsForm.controls['vWednesdayEndMinute'].invalid && (additionalDetailsForm.controls['vWednesdayEndMinute'].errors!.pattern || additionalDetailsForm.controls['vWednesdayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
            <div name="additional-details-thrusday-week-time-row" id="additional-details-thrusday-week-time-row" class="week-time-row spacing-top">
              <div name="additional-details-thrusday-weekday-width" id="additional-details-thrusday-weekday-width" class="weekday-width">
                <mat-checkbox name="additional-details-thrusday" id="additional-details-thrusday" class="workinghoursCheckbox" formControlName="iThursdayStatus" [checked]="additionalDetailsArray?.iThursdayStatus">Thursday</mat-checkbox>
              </div>
              <div name="additional-details-thrusday-working-hours" id="additional-details-thrusday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iThursdayStatus == 0">
                <div name="additional-details-thrusday-hour-to" id="additional-details-thrusday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="additional-details-thrusday-working-hours" id="additional-details-thrusday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iThursdayStatus == 1">
                <mat-form-field appearance="fill" name="additional-details-thrusday-vThrusdayStartHour" id="additional-details-thrusday-vThrusdayStartHour" class="hour-container">
                  <input type="text" matInput id="vThrusdayStartHour" formControlName="vThursdayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-thrusday-vThrusdayStartMinute" id="additional-details-thrusday-vThrusdayStartMinute" class="minute-container">
                  <input type="text" matInput id="vThrusdayStartMinute" formControlName="vThursdayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="additional-details-thrusday-vThrusdayEndHour" id="additional-details-thrusday-vThrusdayEndHour" class="hour-container">
                  <input type="text" matInput id="vThrusdayEndHour" formControlName="vThursdayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-thrusday-vThrusdayEndMinute" id="additional-details-thrusday-vThrusdayEndMinute"  class="minute-container">
                  <input type="text" matInput id="vThrusdayEndMinute" formControlName="vThursdayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(additionalDetailsForm.controls['vThursdayStartHour']?.value>=additionalDetailsForm.controls['vThursdayEndHour']?.value) || (additionalDetailsForm.controls['vThursdayStartHour'].invalid && (additionalDetailsForm.controls['vThursdayStartHour'].errors!.pattern || additionalDetailsForm.controls['vThursdayStartHour'].errors!.required)) || (additionalDetailsForm.controls['vThursdayStartMinute'].invalid && (additionalDetailsForm.controls['vThursdayStartMinute'].errors!.pattern || additionalDetailsForm.controls['vThursdayStartMinute'].errors!.required)) || (additionalDetailsForm.controls['vThursdayEndHour'].invalid && (additionalDetailsForm.controls['vThursdayEndHour'].errors!.pattern || additionalDetailsForm.controls['vThursdayEndHour'].errors!.required)) || (additionalDetailsForm.controls['vThursdayEndMinute'].invalid && (additionalDetailsForm.controls['vThursdayEndMinute'].errors!.pattern || additionalDetailsForm.controls['vThursdayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
            <div name="additional-details-friday-week-time-row" id="additional-details-friday-week-time-row" class="week-time-row spacing-top">
              <div name="additional-details-friday-weekday-width" id="additional-details-friday-weekday-width" class="weekday-width">
                <mat-checkbox name="additional-details-friday" id="additional-details-friday" class="workinghoursCheckbox" formControlName="iFridayStatus" [checked]="additionalDetailsArray?.iFridayStatus">Friday</mat-checkbox>
              </div>
              <div name="additional-details-friday-working-hours" id="additional-details-friday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iFridayStatus == 0">
                <div name="additional-details-friday-hour-to" id="additional-details-friday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="additional-details-friday-working-hours" id="additional-details-friday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iFridayStatus == 1">
                <mat-form-field appearance="fill" name="additional-details-friday-vFridayStartHour" id="additional-details-friday-vFridayStartHour" class="hour-container">
                  <input type="text" matInput id="vFridayStartHour" formControlName="vFridayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-friday-vFridayStartMinute" id="additional-details-friday-vFridayStartMinute" class="minute-container">
                  <input type="text" matInput id="vFridayStartMinute" formControlName="vFridayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="additional-details-friday-vFridayEndHour" id="additional-details-friday-vFridayEndHour" class="hour-container">
                  <input type="text" matInput id="vFridayEndHour" formControlName="vFridayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-friday-vFridayEndMinute" id="additional-details-friday-vFridayEndMinute" class="minute-container">
                  <input type="text" matInput id="vFridayEndMinute" formControlName="vFridayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(additionalDetailsForm.controls['vFridayStartHour']?.value>=additionalDetailsForm.controls['vFridayEndHour']?.value) || (additionalDetailsForm.controls['vFridayStartHour'].invalid && (additionalDetailsForm.controls['vFridayStartHour'].errors!.pattern || additionalDetailsForm.controls['vFridayStartHour'].errors!.required)) || (additionalDetailsForm.controls['vFridayStartMinute'].invalid && (additionalDetailsForm.controls['vFridayStartMinute'].errors!.pattern || additionalDetailsForm.controls['vFridayStartMinute'].errors!.required)) || (additionalDetailsForm.controls['vFridayEndHour'].invalid && (additionalDetailsForm.controls['vFridayEndHour'].errors!.pattern || additionalDetailsForm.controls['vFridayEndHour'].errors!.required)) || (additionalDetailsForm.controls['vFridayEndMinute'].invalid && (additionalDetailsForm.controls['vFridayEndMinute'].errors!.pattern || additionalDetailsForm.controls['vFridayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
            <div name="additional-details-saturday-week-time-row" id="additional-details-saturday-week-time-row" class="week-time-row spacing-top">
              <div name="additional-details-saturday-weekday-width" id="additional-details-saturday-weekday-width" class="weekday-width">
                <mat-checkbox name="additional-details-saturday" id="additional-details-saturday" class="workinghoursCheckbox" formControlName="iSaturdayStatus" [checked]="additionalDetailsArray?.iSaturdayStatus">Saturday</mat-checkbox>
              </div>
              <div name="additional-details-saturday-working-hours" id="additional-details-saturday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iSaturdayStatus == 0">
                <div name="additional-details-saturday-hour-to" id="additional-details-saturday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="additional-details-saturday-working-hours" id="additional-details-saturday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iSaturdayStatus == 1">
                <mat-form-field appearance="fill" name="additional-details-saturday-vSaturdayStartHour" id="additional-details-saturday-vSaturdayStartHour" class="hour-container">
                  <input type="text" matInput id="vSaturdayStartHour" formControlName="vSaturdayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-saturday-vSaturdayStartMinute" id="additional-details-saturday-vSaturdayStartMinute" class="minute-container">
                  <input type="text" matInput id="vSaturdayStartMinute" formControlName="vSaturdayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="additional-details-saturday-vSaturdayEndHour" id="additional-details-saturday-vSaturdayEndHour" class="hour-container">
                  <input type="text" matInput id="vSaturdayEndHour" formControlName="vSaturdayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-saturday-vSaturdayEndMinute" id="additional-details-saturday-vSaturdayEndMinute" class="minute-container">
                  <input type="text" matInput id="vSaturdayEndMinute" formControlName="vSaturdayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(additionalDetailsForm.controls['vSaturdayStartHour']?.value>=additionalDetailsForm.controls['vSaturdayEndHour']?.value) || (additionalDetailsForm.controls['vSaturdayStartHour'].invalid && (additionalDetailsForm.controls['vSaturdayStartHour'].errors!.pattern || additionalDetailsForm.controls['vSaturdayStartHour'].errors!.required)) || (additionalDetailsForm.controls['vSaturdayStartMinute'].invalid && (additionalDetailsForm.controls['vSaturdayStartMinute'].errors!.pattern || additionalDetailsForm.controls['vSaturdayStartMinute'].errors!.required)) || (additionalDetailsForm.controls['vSaturdayEndHour'].invalid && (additionalDetailsForm.controls['vSaturdayEndHour'].errors!.pattern || additionalDetailsForm.controls['vSaturdayEndHour'].errors!.required)) || (additionalDetailsForm.controls['vSaturdayEndMinute'].invalid && (additionalDetailsForm.controls['vSaturdayEndMinute'].errors!.pattern || additionalDetailsForm.controls['vSaturdayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
            <div name="additional-details-sunday-week-time-row" id="additional-details-sunday-week-time-row" class="week-time-row spacing-top mb-4 mb-md-0">
              <div name="additional-details-sunday-weekday-width" id="additional-details-sunday-weekday-width" class="weekday-width">
                <mat-checkbox name="additional-details-sunday" id="additional-details-sunday" class="workinghoursCheckbox" formControlName="iSundayStatus" [checked]="additionalDetailsArray?.iSundayStatus">Sunday</mat-checkbox>
              </div>
              <div name="additional-details-sunday-working-hours" id="additional-details-sunday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iSundayStatus == 0">
                <div name="additional-details-sunday-hour-to" id="additional-details-sunday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="additional-details-sunday-working-hours" id="additional-details-sunday-working-hours" class="d-flex working-hours" *ngIf="additionalDetailsForm?.value.iSundayStatus == 1">
                <mat-form-field appearance="fill" name="additional-details-sunday-vSundayStartHour" id="additional-details-sunday-vSundayStartHour" class="hour-container">
                  <input type="text" matInput id="vSundayStartHour" formControlName="vSundayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-sunday-vSundayStartMinute" id="additional-details-sunday-vSundayStartMinute" class="minute-container">
                  <input type="text" matInput id="vSundayStartMinute" formControlName="vSundayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="additional-details-sunday-vSundayEndHour" id="additional-details-sunday-vSundayEndHour" class="hour-container">
                  <input type="text" matInput id="vSundayEndHour" formControlName="vSundayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-sunday-vSundayEndMinute" id="additional-details-sunday-vSundayEndMinute" class="minute-container">
                  <input type="text" matInput id="vSundayEndMinute" formControlName="vSundayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(additionalDetailsForm.controls['vSundayStartHour']?.value>=additionalDetailsForm.controls['vSundayEndHour']?.value) || (additionalDetailsForm.controls['vSundayStartHour'].invalid && (additionalDetailsForm.controls['vSundayStartHour'].errors!.pattern || additionalDetailsForm.controls['vSundayStartHour'].errors!.required)) || (additionalDetailsForm.controls['vSundayStartMinute'].invalid && (additionalDetailsForm.controls['vSundayStartMinute'].errors!.pattern || additionalDetailsForm.controls['vSundayStartMinute'].errors!.required)) || (additionalDetailsForm.controls['vSundayEndHour'].invalid && (additionalDetailsForm.controls['vSundayEndHour'].errors!.pattern || additionalDetailsForm.controls['vSundayEndHour'].errors!.required)) || (additionalDetailsForm.controls['vSundayEndMinute'].invalid && (additionalDetailsForm.controls['vSundayEndMinute'].errors!.pattern || additionalDetailsForm.controls['vSundayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div name="additional-details-modal-footer" id="additional-details-modal-footer" class="modal-footer">
      <button type="button" name="additional-details-btn-cancel" id="additional-details-btn-cancel" class="blue-secondary-small-btn mx-2" (click)="this.additionalDetailsToggle=false; this.additionalDetailsSubmitted = false; this.GetAdditionalDetails();">Cancel</button>
      <button type="submit" name="additional-details-btn-submit" id="additional-details-btn-submit" class="blue-primary-small-btn" form="additionalDetailsForm">Save</button>
    </div>
  </div>
</div>
</div>

