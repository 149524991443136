import { Component, OnInit, ViewChild } from '@angular/core';
import { TalentService } from './talent.service';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from 'src/app/config/event-emitter.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Permission } from 'src/app/utils/enums/permission.enum';
import { UserPermissionService } from 'src/app/utils/services/user-permission.service';
import { NotificationService } from 'src/app/config/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-talent',
  templateUrl: './talent.component.html'
})
export class TalentComponent implements OnInit {

  // toggle actions permission
  viewTalentProfileActionPermission: boolean = false;
  activeEmployeeUserActionPermission: boolean = false;
  inactiveEmployeeUserActionPermission: boolean = false;

  constructor(
    private service : TalentService,
    public eventEmitterService: EventEmitterService,
    private router:Router,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private userPermissionService: UserPermissionService
  ){
    // assign individual permissions
    const ViewTalentProfileAction: Permission = Permission.ViewTalentProfileAction;
    const ActiveEmployeeUserAction: Permission = Permission.ActiveEmployeeUserAction;
    const InactiveEmployeeUserAction: Permission = Permission.InactiveEmployeeUserAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ViewTalentProfileAction)) {
      this.viewTalentProfileActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ActiveEmployeeUserAction)) {
      this.activeEmployeeUserActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(InactiveEmployeeUserAction)) {
      this.inactiveEmployeeUserActionPermission = true;
    }
  }

  displayedColumnsTableOne: string[] = ['name', 'email', 'number' , 'publishStatus', 'create', 'actions', 'toggleAction'];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

  ngOnInit(): void {
    this.getUserList();
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  getUserList() {
    this.service.getUserList().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      }
    });
  }

  toggleStatus(event: any, userId: any) {
    // console.log(event.target.checked);
    if(event.target.checked == true && this.activeEmployeeUserActionPermission) {
      this.changeActiveInactiveUser(userId,1,this.activeEmployeeUserActionPermission)
    }else if(event.target.checked == false && this.inactiveEmployeeUserActionPermission) {
      this.changeActiveInactiveUser(userId,2,this.inactiveEmployeeUserActionPermission)
    }else{
      event.target.checked = !event.target.checked;
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  changeActiveInactiveUser(userId: any, accStatus: any, hasPermission: boolean) {
    if (hasPermission) {
      let accType=5;
      this.service.changeActiveInactiveUser({userId:userId,accStatus:accStatus,tiAccountType:accType}).then((result: any) => {
        if (result['code'] == 200) {
          this.notifyService.showSuccess(result.message,'');
          this.getUserList();
        }
      });
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }


  public iProfilePublishCompleted:any = 0;
  public progressValue:any;
  public partnerCompanyName:any;
  GetProfileStatus(iUserId:any){
    this.service.GetProfileStatus(iUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result['data']['iProfilePublishCompleted'];
        this.progressValue = result['data']['progressValue'];
        this.partnerCompanyName = result['data']['partnerCompanyName'];
        this.eventEmitterService.GetProfileStatusCallMethod(result['data']);
      }
    });
  }

  viewProfile(data: any, hasPermission: boolean){
    if (hasPermission) {
      this.router.navigate([`/talent/${data.userId}`]);
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }
}

