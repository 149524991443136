import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  DashboardCount() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/dashboard`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }


  GetClientTotaldetails() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/client-data`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetPartnerTotaldetails() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/partner-data`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetTalentTotaldetails() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/talent-data`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetTotalTalentCountStatusOverviewdetails() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/talent-overview`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetRecentJoinClientdetails() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/recent-join-client`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetRecentJoinPartnerdetails() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/recent-join-partner`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetRecentJoinTalentdetails() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/recent-join-talent`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  UpdateMilestoneReviseDate() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v2/updateMileStoneRevisedDate`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UpdateTaskStatus() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/admin/v2/pms-task-status`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UpdateOldToNew() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v2/replace-employee-logs?resource=0&iProjectId=12&oldEmployee=88&updatedEmployee=125`;
      this.http
        .put(apiURL,'')
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  ChangeTaskStatusNameForCompleted() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/admin/v2/pms-task-status-update`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  dataTransfer(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/admin/v2/stackholder-data-transfer`;
        this.http
          .put(apiURL,'')
          .toPromise()
          .then((res) => {
            // Success
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    return promise;
  }

  ValueAdditiondataTransfer(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/admin/v2/value-addition-data-transfer`;
        this.http
          .put(apiURL,'')
          .toPromise()
          .then((res) => {
            // Success
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    return promise;
  }

  SetCityStatetiStatus(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/admin/v2/set-state-city-tistatus`;
        this.http
          .post(apiURL,'')
          .toPromise()
          .then((res) => {
            // Success
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    return promise;
  }

  SetCityStatetiStatusPG(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/admin/v2/set-state-city-tistatus`;
        this.http
          .post(apiURL,'')
          .toPromise()
          .then((res) => {
            // Success
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    return promise;
  }

  SetAllColumnToIntegerPG(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/admin/v2/set-all-column-to-integer`;
        this.http
          .post(apiURL,'')
          .toPromise()
          .then((res) => {
            // Success
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    return promise;
  }

  SetSequenceValuePG(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/admin/v2/set-sequence-value`;
        this.http
          .post(apiURL,'')
          .toPromise()
          .then((res) => {
            // Success
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    return promise;
  }

  activityAccountTypeDataTransfer(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v2/activity-account-type-transfer`;
        this.http
          .put(apiURL,'')
          .toPromise()
          .then((res) => {
            // Success
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    return promise;
  }
}
