import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { PublishService } from './publish.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from 'src/app/config/event-emitter.service';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/utils/enums/permission.enum';
import { UserPermissionService } from 'src/app/utils/services/user-permission.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/config/notification.service';

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html'
})
export class PublishComponent implements OnInit {

  // toggle actions permission
  viewPartnerProfileActionPermission: boolean = false;
  viewClientProfileActionPermission: boolean = false;
  viewTalentProfileActionPermission: boolean = false;

  viewPartnerPublishActionPermission: boolean = false;
  approvePartnerPublishActionPermission: boolean = false;
  rejectPartnerPublishActionPermission: boolean = false;

  viewClientPublishActionPermission: boolean = false;
  approveClientPublishActionPermission: boolean = false;
  rejectClientPublishActionPermission: boolean = false;

  viewEmployeePublishActionPermission: boolean = false;
  approveEmployeePublishActionPermission: boolean = false;
  rejectEmployeePublishActionPermission: boolean = false;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;
  ticket: any;

  displayedColumnsTableOne: string[] = ['indexnumber','vEmailId','actions'];
  dataSourceTableOne = new MatTableDataSource();

  view: any = 0;

  constructor(
    private Service: PublishService,
    public ngxSmartModalService: NgxSmartModalService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private router:Router,
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  ) {
    // assign individual permissions
    const ViewPartnerProfileAction: Permission = Permission.ViewPartnerProfileAction;
    const ViewClientProfileAction: Permission = Permission.ViewClientProfileAction;
    const ViewTalentProfileAction: Permission = Permission.ViewTalentProfileAction;

    const ViewPartnerPublishAction: Permission = Permission.ViewPartnerPublishAction;
    const ApprovePartnerPublishAction: Permission = Permission.ApprovePartnerPublishAction;
    const RejectPartnerPublishAction: Permission = Permission.RejectPartnerPublishAction;

    const ViewClientPublishAction: Permission = Permission.ViewClientPublishAction;
    const ApproveClientPublishAction: Permission = Permission.ApproveClientPublishAction;
    const RejectClientPublishAction: Permission = Permission.RejectClientPublishAction;

    const ViewEmployeePublishAction: Permission = Permission.ViewEmployeePublishAction;
    const ApproveEmployeePublishAction: Permission = Permission.ApproveEmployeePublishAction;
    const RejectEmployeePublishAction: Permission = Permission.RejectEmployeePublishAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ViewPartnerProfileAction)) {
      this.viewPartnerProfileActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ViewClientProfileAction)) {
      this.viewClientProfileActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ViewTalentProfileAction)) {
      this.viewTalentProfileActionPermission = true;
    }

    if (this.userPermissionService.isAuthorizedFor(ViewPartnerPublishAction)) {
      this.viewPartnerPublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ApprovePartnerPublishAction)) {
      this.approvePartnerPublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(RejectPartnerPublishAction)) {
      this.rejectPartnerPublishActionPermission = true;
    }

    if (this.userPermissionService.isAuthorizedFor(ViewClientPublishAction)) {
      this.viewClientPublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ApproveClientPublishAction)) {
      this.approveClientPublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(RejectClientPublishAction)) {
      this.rejectClientPublishActionPermission = true;
    }

    if (this.userPermissionService.isAuthorizedFor(ViewEmployeePublishAction)) {
      this.viewEmployeePublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ApproveEmployeePublishAction)) {
      this.approveEmployeePublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(RejectEmployeePublishAction)) {
      this.rejectEmployeePublishActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.getRefreshAPIData();
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  getRefreshAPIData() {
    if(this.viewPartnerPublishActionPermission==false && this.viewClientPublishActionPermission){
      this.clientDetails(this.viewClientPublishActionPermission);
    }else if(this.viewPartnerPublishActionPermission==false && this.viewClientPublishActionPermission==false && this.viewEmployeePublishActionPermission){
      this.talentDetails(this.viewEmployeePublishActionPermission);
    }else{
      this.partnerDetails(this.viewPartnerPublishActionPermission);
    }
  }


  partnerDetails(hasPermission:boolean) {
    if (hasPermission) {
    this.view = "0";
    this.GetPartnerPublish();
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  clientDetails(hasPermission:boolean) {
    if (hasPermission) {
    this.view = "1";
    this.GetPublish();
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  talentDetails(hasPermission:boolean) {
    if (hasPermission) {
    this.view = "2";
    this.GetEmployeePublish();
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  public primarySkillData: any = [];
  GetPartnerPublish() {
    this.Service.GetPartnerPublish().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    });
  }

  GetPublish() {
    this.Service.GetPublish().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    });
  }

  GetEmployeePublish() {
    this.Service.GetEmployeePublish().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    });
  }

  acceptPartnerPublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.Service.acceptPartnerPublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetPartnerPublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  acceptPublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.Service.acceptPublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetPublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  acceptEmployeePublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.Service.acceptEmployeePublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetEmployeePublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  rejectPartnerPublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.Service.rejectPartnerPublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetPartnerPublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  rejectPublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.Service.rejectPublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetPublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  rejectEmployeePublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.Service.rejectEmployeePublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetEmployeePublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }


  viewClientProfile(data: any, hasPermission: boolean){
    if (hasPermission) {
      this.router.navigate([`/client/${data.iUserId}`]);
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  viewPartnerProfile(data: any, hasPermission: boolean){
    if (hasPermission) {
      this.router.navigate([`/partner/${data.iUserId}`]);
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }
  viewTalentProfile(data: any, hasPermission: boolean){
    if (hasPermission) {
      this.router.navigate([`/talent/${data.iUserId}`]);
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }


  public progressValue:any = 0;
  public iProfilePublishCompleted:any = 0;
  GetProfileStatus(iUserId:any){
    this.Service.GetProfileStatus(iUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result['data']['iProfilePublishCompleted'];
        this.progressValue = result['data']['progressValue'];
        this.eventEmitterService.GetProfileStatusCallMethod(result['data']);
      }
    });
  }

  GetClientProfileStatus(iUserId:any){
    this.Service.GetClientProfileStatus(iUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result['data']['iProfilePublishCompleted'];
        this.progressValue = result['data']['progressValue'];
        this.partnerCompanyName = result['data']['partnerCompanyName'];
        this.eventEmitterService.GetProfileStatusCallMethod(result['data']);
      }
    });
  }

  public partnerCompanyName:any;
  GetEmployeeProfileStatus(iUserId:any){
    this.Service.GetEmployeeProfileStatus(iUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result['data']['iProfilePublishCompleted'];
        this.progressValue = result['data']['progressValue'];
        this.partnerCompanyName = result['data']['partnerCompanyName'];
        this.eventEmitterService.GetProfileStatusCallMethod(result['data']);
      }
    });
  }

}
