<div class="container-fluid skill-management">
  <div class="row">
    <div class="col-12">
      <div class="wrapper">
        <div class="tab-content flex-grow-1 w-100">
          <div class="tab-pane fade show active" aria-labelledby="skill-management-tab">
            <div class="pagetitle-wrapper">
              <div
                class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                <div class="d-flex align-items-center">
                  <i class="pagetitle-icon"><span class="material-icons">account_box</span></i>
                  <h2 class="pagetitle">Partner Publish Ticket</h2>
                </div>
              </div>
              <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                <button matSuffix mat-button>
                  <mat-icon>search</mat-icon>
                </button>
                <input matInput placeholder="Ex: support@rapidise.com" (keyup)="applyFilterOne($event)" />
              </mat-form-field>
              <div class="table-responsive">
                <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                </div>
                <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }"
                  [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                  <ng-container matColumnDef="indexnumber">
                    <th mat-header-cell *matHeaderCellDef> No </th>
                    <td mat-cell *matCellDef="let i = index;">
                      {{ this.TableOnePaginator.pageIndex == 0 ? i+1 : i+ 1 + this.TableOnePaginator.pageIndex *
                      this.TableOnePaginator.pageSize}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vEmailId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.vEmailId}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex flex-wrap">
                        <button class="status-btn lightblue-bg blue-text text-center" placement="left auto" [ngbTooltip]="viewPartnerPublishActionPermission ? 'View Profile':'Not Authorized'" [ngClass]="viewPartnerPublishActionPermission ? '':'unauthorized-cursor'"
                          (click)="publishView(element,partnerProfileView,viewPartnerPublishActionPermission)">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button class="status-btn lightgreen-bg green-text" placement="left auto" [ngbTooltip]="approvePartnerPublishActionPermission ? 'Approve':'Not Authorized'" [ngClass]="approvePartnerPublishActionPermission ? '':'unauthorized-cursor'"
                          (click)="acceptPartnerPublish(element,approvePartnerPublishActionPermission)">
                          <span class="material-icons">check_circle</span>
                        </button>
                        <button class="status-btn lightpink-bg pink-text" placement="left auto" [ngbTooltip]="rejectPartnerPublishActionPermission ? 'Reject':'Not Authorized'" [ngClass]="rejectPartnerPublishActionPermission ? '':'unauthorized-cursor'"
                          (click)="rejectPartnerPublish(element,rejectPartnerPublishActionPermission)">
                          <span class="material-icons">cancel</span>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                </table>
              </div>
              <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
                #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #partnerProfileView let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-view">
    <button class="modal-close-btn" (click)="d('Cross click')">
      <mat-icon>close</mat-icon>
    </button>

    <div name="main-container" id="main-container" class="d-flex flex-column main-container h-100 mb-5">
      <div name="container-fluid" id="container-fluid" class="container-fluid">
        <div name="main-content-box" id="main-content-box" class="main-content-box">
          <div name="main-container-header" id="main-container-header" class="main-container-header responsive-spacing">
            <div class="d-flex align-items-baseline">
              <h1 name="title" id="title" class="title">View Profile</h1>
            </div>
          </div>
          <div name="main-row" id="main-row" class="row gy-4 gy-lg-1 gx-1">
            <div name="left-column" id="left-column" class="col-12 col-lg-6">
              <div name="left-column-inner-row" id="left-column-inner-row" class="row g-4">
                <app-primary-details></app-primary-details>
                <app-executive-details></app-executive-details>
                <app-additional-details></app-additional-details>
              </div>
            </div>
            <div name="right-column" id="right-column" class="col-12 col-lg-6">
              <div name="right-column-inner-row" id="right-column-inner-row" class="row g-4">
                <app-company-details></app-company-details>
                <app-company-documents></app-company-documents>
                <app-certifications></app-certifications>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
