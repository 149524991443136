import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DealEngineService {

  constructor(private http: HttpClient) { }

  CreateDeal(data:any,DealOpeningDate:any,DealClosingDate:any,vClientCompanySize:any) {
    data.iDealOpeningDate=DealOpeningDate;
    data.iDealClosingDate=DealClosingDate;
    data.vClientCompanySize= vClientCompanySize;

    // console.log(data,"data");
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/deal`;
      this.http
        .post(apiURL,data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }


  GetAllDealData(filterFlag:any,searchKey:any, iDealTypeId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/deal?searchKey=${searchKey}&filterFlag=${filterFlag}&iDealTypeId=${iDealTypeId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetDealDataByID(id:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/deal/${id}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UpdateDeal(id:any,data:any,DealOpeningDate:any,DealClosingDate:any){
    data.iDealOpeningDate=DealOpeningDate;
    data.iDealClosingDate=DealClosingDate;
    // console.log(data,"data");

    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/update-deal/${id}`;
      this.http
        .put(apiURL,data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  DismissDealDataByID(id:any,data:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/deal/${id}`;
      this.http
        .put(apiURL,data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }


DealCount(){
  const promise = new Promise((resolve, reject) => {
    let apiURL = environment.baseUrl + `/admin/v1/deal-engine-count`;
    this.http
      .get(apiURL)
      .toPromise()
      .then((res: any) => {
        // Success
        resolve(res);
      })
      .catch((err: any) => {
        console.log(err);
        reject(err);
      });
  });
  return promise;
}

DealType(){
  const promise = new Promise((resolve, reject) => {
    let apiURL = environment.baseUrl + `/admin/v1/deal-types`;
    this.http
      .get(apiURL)
      .toPromise()
      .then((res: any) => {
        // Success
        resolve(res);
      })
      .catch((err: any) => {
        console.log(err);
        reject(err);
      });
  });
  return promise;
}


EarnedPartner(partnerid:any,dealid:any){
  let data ='';
  const promise = new Promise((resolve, reject) => {
    let apiURL = environment.baseUrl + `/admin/v1/earned-partner/${dealid}?userid=${partnerid}`;
    this.http
      .put(apiURL,data)
      .toPromise()
      .then((res: any) => {
        // Success
        resolve(res);
      })
      .catch((err: any) => {
        console.log(err);
        reject(err);
      });
  });
  return promise;
}



GetPartnerDealDatabyId(id:any, iDealId:any){
  const promise = new Promise((resolve, reject) => {
    let apiURL = environment.baseUrl + `/admin/v1/partner-deal/${id}/${iDealId}`;
    this.http
      .get(apiURL)
      .toPromise()
      .then((res: any) => {
        // Success
        resolve(res);
      })
      .catch((err: any) => {
        console.log(err);
        reject(err);
      });
  });
  return promise;
}

GetCountryList() {
  const promise = new Promise((resolve, reject) => {
    let apiURL = environment.baseUrl + `/common/v1/countryList`;
    this.http
      .get(apiURL)
      .toPromise()
      .then((res) => {
        // Success
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return promise;
}

GetIndustryList() {
  const promise = new Promise((resolve, reject) => {
    let apiURL = environment.baseUrl + `/common/v1/list/industry`;
    this.http
      .get(apiURL)
      .toPromise()
      .then((res) => {
        // Success
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return promise;
}


}
