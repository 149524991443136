<div class="container-fluid skill-management">
    <div class="row">
      <div class="col-12">
        <div class="wrapper">

          <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="title" role="tabpanel" aria-labelledby="skill-management-tab">

              <div class="pagetitle-wrapper">
                <div class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                  <div class="d-flex align-items-center">
                    <i class="pagetitle-icon"><span class="material-icons">ballot</span></i>
                    <h2 class="pagetitle">Billing</h2>
                  </div>
          
                </div>
                <div class="d-flex">
                <mat-form-field class="w-75" floatLabel="never" class="table-filter">
                  <button matSuffix mat-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <input matInput placeholder="Search Billing" (keyup)="applyFilterOnSearch($event)" />
                </mat-form-field>
                
                </div>
                <div class="table-responsive">
                  <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                  </div>
                  <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }" [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                    <ng-container matColumnDef="indexnumber">
                      <th mat-header-cell *matHeaderCellDef> No </th>
                      <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableOnePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.TableOnePaginator.pageIndex * this.TableOnePaginator.pageSize}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vContractName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract Name </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.vContractName}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vEmployeeName">
                        <th mat-header-cell *matHeaderCellDef> Employee Name </th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{element.vEmployeeName}}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="vClientName">
                        <th mat-header-cell *matHeaderCellDef> Client Name </th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{element.vClientName}}</span>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="paidInvoiceCount">
                        <th mat-header-cell *matHeaderCellDef> Paid Invoice</th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{element.paidInvoiceCount}}/{{element.totalInvoiceCount}}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="dueInvoiceCount">
                        <th mat-header-cell *matHeaderCellDef> Due Invoice </th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{element.dueInvoiceCount}}/{{element.totalInvoiceCount}}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex flex-wrap">
                            <button
                              class="status-btn lightblue-bg blue-text text-center mb-2" placement="left auto" [ngbTooltip]="viewBillingActionPermission ? 'View Invoice Details':'Not Authorized'" [ngClass]="viewBillingActionPermission ? '':'unauthorized-cursor'"
                              (click)="
                                OpenModelViewInvoiceDetails(viewInvoiceDetails, element, viewBillingActionPermission)
                              "
                            >
                              <mat-icon>remove_red_eye</mat-icon>
                            </button>
                            <!-- <button class="status-btn lightpink-bg pink-text text-center mb-2" (click)="DeleteRequirement(element)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete">
                                <mat-icon>delete</mat-icon>
                              </button> -->
                          </div>
                        </td>
                      </ng-container>
                      <!-- <ng-container matColumnDef="tiPaymentStatus">
                        <th mat-header-cell *matHeaderCellDef> Payment Status </th>
                        <td mat-cell *matCellDef="let element">
                          <div *ngFor="let obj of billingStatus"><span *ngIf="obj.value==element.tiPaymentStatus" [ngClass]="{'grey-text':element.tiPaymentStatus=='0' , 'green-text':element.tiPaymentStatus=='1', 'pink-text': element.tiPaymentStatus=='2' || element.tiPaymentStatus=='3' }">{{obj.viewValue}}</span></div>
                          </td>
                      </ng-container> -->

                    <!-- <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> Actions </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex flex-wrap">
                          <button class="status-btn lightblue-bg blue-text text-center mb-2"(click)="OpenModelViewBilling(viewBilling,element)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit">
                            <mat-icon>visibility</mat-icon>
                          </button>
                       
                        </div>
                      </td>
                    </ng-container> -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                  </table>
                </div>
                <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }" #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
                </mat-paginator>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <ng-template #viewInvoiceDetails let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <button class="modal-close-btn" (click)="d('Cross click');this.filterType='All';this.filterValueInner='';">
        <mat-icon>close</mat-icon>
      </button>
      <div class="content">
        <div class="w-100">
          <h1 class="title">{{ ParentContractName }}</h1>
          <mat-chip-list>
            <mat-chip class="blue-chip">
              Total : {{TotalInvoiceCount}}
            </mat-chip>
            <mat-chip class="blue-chip">
              Paid : {{PaidInvoiceCount}}
            </mat-chip>
            <mat-chip class="red-chip">
              Due : {{DueInvoiceCount}}
            </mat-chip>
            <mat-chip class="red-chip">
              Failed : {{FailedInvoiceCount}}
            </mat-chip>
          </mat-chip-list>
          
        </div>
        <div class="pagetitle-wrapper">
          <div class="d-flex flex-column">
          <mat-form-field  appearance="outline" floatLabel="never" class="table-filter p-0 my-2">
            <!-- <button matSuffix>
              <mat-icon>search</mat-icon>
            </button> -->
            <input matInput placeholder="Search Invoice" (keyup)="applyFilterTwo($event)" />
          </mat-form-field>
          <mat-form-field  appearance="outline" class="table-filter p-0 my-2">
            <mat-select placeholder="Select Payment Status" [(value)]="filterType" (selectionChange)="GetInvoiceDetailsById(ParentContractId)">
              <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let billing of billingStatus" [value]="billing.value">
                  {{billing.viewValue}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <h2 class="pagetitle">List of Invoices</h2>
          <div class="table-responsive my-3">
            <div *ngIf="dataSourceTableTwo?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
            </div>
            <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableTwo?.filteredData?.length <= 0 }" [dataSource]="dataSourceTableTwo" matSort class="datatable">
              <ng-container matColumnDef="indexnumber">
                <th mat-header-cell *matHeaderCellDef> No </th>
                <td mat-cell *matCellDef="let i = index;">
                  {{i+1}}
                </td>
              </ng-container>
              <ng-container matColumnDef="iUniqueId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unique Id </th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.iUniqueId">{{element.iUniqueId}}</span>
                  <span *ngIf="element.iUniqueId=='' || element.iUniqueId==undefined"><mat-icon>
                    horizontal_rule
                    </mat-icon></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="iInvoiceId">
                  <th mat-header-cell *matHeaderCellDef> Invoice Id </th>
                  <td mat-cell *matCellDef="let element">
                      <span>{{element.iInvoiceId}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="iInvoiceStartDate">
                  <th mat-header-cell *matHeaderCellDef> Start Date </th>
                  <td mat-cell *matCellDef="let element">
                      <span>{{element.iInvoiceStartDate*1000 | date : 'MMM,dd yyyy'}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="iInvoiceEndDate">
                  <th mat-header-cell *matHeaderCellDef> End Date </th>
                  <td mat-cell *matCellDef="let element">
                      <span>{{element.iInvoiceEndDate*1000 | date : 'MMM,dd yyyy'}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="fAmount">
                  <th mat-header-cell *matHeaderCellDef> Amount </th>
                  <td mat-cell *matCellDef="let element">
                      <span>${{element.fAmount | number}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="tiPaymentStatus">
                  <th mat-header-cell *matHeaderCellDef> Payment Status </th>
                  <td mat-cell *matCellDef="let element">
                    <div *ngFor="let obj of billingStatus"><span *ngIf="obj.value==element.tiPaymentStatus" [ngClass]="{'grey-text':element.tiPaymentStatus=='0' , 'green-text':element.tiPaymentStatus=='1', 'pink-text': element.tiPaymentStatus=='2' || element.tiPaymentStatus=='3' }">{{obj.viewValue}}</span></div>
                    </td>
                </ng-container>

              <!-- <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex flex-wrap">
                    <button class="status-btn lightblue-bg blue-text text-center mb-2"(click)="OpenModelViewBilling(viewBilling,element)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit">
                      <mat-icon>visibility</mat-icon>
                    </button>
                 
                  </div>
                </td>
              </ng-container> -->
              <tr mat-header-row *matHeaderRowDef="displayedColumnsTableTwo; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTableTwo;"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-template>




