import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EventEmitterService } from "../../config/event-emitter.service";
import { ServiceService } from "./service.service";
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";

@Component({
  selector: "app-service",
  templateUrl: "./service.component.html",
})
export class ServiceComponent implements OnInit {
  addServiceForm!: FormGroup;
  editServiceForm!: FormGroup;

  displayedColumnsTableOne: string[] = [
    "indexnumber",
    "vServiceName",
    "iUpdatedAt",
    "actions",
  ];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild("TableOnePaginator", { static: true })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort", { static: true }) tableOneSort: MatSort;

  // toggle actions permission
  addServiceActionPermission: boolean = false;
  editServiceActionPermission: boolean = false;
  deleteServiceActionPermission: boolean = false;

  constructor(
    private serviceService: ServiceService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private userPermissionService: UserPermissionService
  ) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const AddServiceAction: Permission = Permission.AddServiceAction;
    const EditServiceAction: Permission = Permission.EditServiceAction;
    const DeleteServiceAction: Permission = Permission.DeleteServiceAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddServiceAction)) {
      this.addServiceActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditServiceAction)) {
      this.editServiceActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeleteServiceAction)) {
      this.deleteServiceActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetSerive();
    this.addServiceForm = new FormGroup({
      serviceName: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
    });

    this.editServiceForm = new FormGroup({
      iMasterServiceId: new FormControl("", []),
      editedServiceName: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
    });
  }

  OpenModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  OpenModelEditService(modelName: any, element: any, hasPermission: boolean) {
    if (hasPermission) {
      this.editServiceForm.patchValue({
        iMasterServiceId: element["iMasterServiceId"],
        editedServiceName: element["vServiceName"],
      });
      this.modalService.open(modelName, { centered: true });
    }
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value
      .trim()
      .toLowerCase();
  }

  GetSerive() {
    this.serviceService
      .GetSerive()
      .then((result: any) => {
        if (result["code"] == 200) {
          this.dataSourceTableOne.data = result["data"];
        } else {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  AddService() {
    if (this.addServiceForm.invalid) {
      return;
    }
    let reqData = {
      vServiceName: this.addServiceForm.value.serviceName,
    };
    this.serviceService
      .AddService(reqData)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
          this.GetSerive();
        } else {
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err, "AddService");
      });
  }

  EditService() {
    if (this.editServiceForm.invalid) {
      return;
    }

    let reqData = {
      iMasterServiceId: this.editServiceForm.value.iMasterServiceId,
      vServiceName: this.editServiceForm.value.editedServiceName,
    };

    this.serviceService
      .EditService(reqData)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
          this.modalService.dismissAll();
          this.GetSerive();
        } else {
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err, "EditService");
      });
  }

  DeleteSerive(item: any, hasPermission: boolean): void {
    if (hasPermission) {
      this.serviceService
        .DeleteService(item.iMasterServiceId)
        .then((result: any) => {
          this.GetSerive();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
        })
        .catch((err) => {
          console.log(err, "DeleteSerive");
        });
    }
  }
}
