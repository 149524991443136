<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-center align-items-center height-100vh">
        <div class="sign-in-block">
          <img src="assets/images/rapidise-logo.svg" alt="Logo">
          <h1 class="sign-in-title">Hello! let's get started<span>Sign in to continue.</span></h1>
          <form [formGroup]="LoginForm" class="signin-form" (ngSubmit)="CheckLoginEmail()">
            <mat-form-field floatLabel="never">
              <mat-label>Email Address</mat-label>
              <input matInput formControlName="vEmailId">
              <mat-error *ngIf="isLoginSubmit && LoginForm.controls.vEmailId.hasError('required')">
                Email is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="isLoginSubmit && LoginForm.controls.vEmailId.hasError('pattern') && !LoginForm.controls.vEmailId.hasError('required')">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="has.vEmailId.errors?.maxlength"> Must not exceed <strong>70</strong> characters </mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="never">
              <mat-label>Password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="txPassword">
              <button matSuffix mat-icon-button type="button" (click)="hide = !hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="isLoginSubmit && LoginForm.controls.txPassword.hasError('required')">
                Password is <strong>required</strong>
              </mat-error>
                <mat-error *ngIf="has.txPassword.errors?.minlength || has.txPassword.errors?.maxlength"> Password must be in between <strong>6 - 15</strong> characters </mat-error>
            </mat-form-field>
            <div class="example-button-row">
              <button type="submit" class="btn signin-btn">Sign In</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<app-error-modal></app-error-modal>
