import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { PartnerPublishService } from './partner-publish.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../../config/event-emitter.service';
import { environment } from '../../../../environments/environment';
import { Permission } from "./../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

@Component({
  selector: 'app-partner-publish',
  templateUrl: './partner-publish.component.html',
})
export class PartnerPublishComponent implements OnInit {
  // toggle actions permission
  viewPartnerPublishActionPermission: boolean = false;
  approvePartnerPublishActionPermission: boolean = false;
  rejectPartnerPublishActionPermission: boolean = false;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;
  ticket: any;

  displayedColumnsTableOne: string[] = ['indexnumber','vEmailId','actions'];
  dataSourceTableOne = new MatTableDataSource();

  constructor(
    private partnerService: PartnerPublishService,
    public ngxSmartModalService: NgxSmartModalService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  ) {
    // assign individual permissions
    const ViewPartnerPublishAction: Permission = Permission.ViewPartnerPublishAction;
    const ApprovePartnerPublishAction: Permission = Permission.ApprovePartnerPublishAction;
    const RejectPartnerPublishAction: Permission = Permission.RejectPartnerPublishAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ViewPartnerPublishAction)) {
      this.viewPartnerPublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ApprovePartnerPublishAction)) {
      this.approvePartnerPublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(RejectPartnerPublishAction)) {
      this.rejectPartnerPublishActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetPartnerPublish();
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  public primarySkillData: any = [];
  GetPartnerPublish() {
    this.partnerService.GetPartnerPublish().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    });
  }

  acceptPartnerPublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.partnerService.acceptPartnerPublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetPartnerPublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }
  }

  rejectPartnerPublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.partnerService.rejectPartnerPublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetPartnerPublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }
  }

  publishView(data: any, modalToOpen: any, hasPermission: boolean){
    if (hasPermission) {
      this.GetProfileStatus(data['iUserId']);
      this.modalService.open(modalToOpen, { centered: true, windowClass: 'wide-modal' });
      setTimeout(() => {
        this.eventEmitterService.viewPartnerPublish_Pub(data['iUserId']);
      }, environment.view_open_model);
    }
  }

  public progressValue:any = 0;
  public iProfilePublishCompleted:any = 0;
  GetProfileStatus(iUserId:any){
    this.partnerService.GetProfileStatus(iUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result['data']['iProfilePublishCompleted'];
        this.progressValue = result['data']['progressValue'];
        this.eventEmitterService.GetProfileStatusCallMethod(result['data']);
      }
    });
  }
}
