<div class="page-content admin-section">

    <h1 class="admin-title">Requirements</h1>

    <div>
        <div class="view-heading-container">
            <div class="button-container">
                <button mat-button (click)="allDetails()" [ngClass]="{'active-tab-btn' : view == 2}"
                    class="inactive-tab-btn">All</button>
                <button mat-button (click)="fulfilledDetails()" [ngClass]="{'active-tab-btn' : view == 1}"
                    class="inactive-tab-btn space-left">Fulfilled</button>
                <button mat-button (click)="unfulfilledDetails()" [ngClass]="{'active-tab-btn' : view == 0}"
                    class="inactive-tab-btn space-left">Unfulfilled</button>
            </div>
            <mat-form-field class="custom-input custom-search-input spacing-input" floatLabel="never" floatPlaceholder="never">
                <mat-label>Search Email Address</mat-label>
                <input matInput type="text" (keyup)="applyFilterOne($event)">
                <button matSuffix>
                    <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        viewBox="0 0 20 20">
                        <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
                        <path id="Path_690" data-name="Path 690"
                            d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
                            transform="translate(0)" fill="#464646" />
                    </svg>
                </button>
            </mat-form-field>
        </div>
    </div>

    <div class="table-border">
        <div class="table-responsive">
            <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">
                No Records Found
            </div>
            <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0}" [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                <ng-container matColumnDef="indexnumber">
                    <th mat-header-cell *matHeaderCellDef>No</th>
                    <td mat-cell *matCellDef="let i = index">
                        {{
                        this.TableOnePaginator.pageIndex == 0
                        ? i + 1
                        : i +
                        1 +
                        this.TableOnePaginator.pageIndex *
                        this.TableOnePaginator.pageSize
                        }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="vDisplayUniqueId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Request Code
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{ element.vDisplayUniqueId }}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="vClientName">
                    <th mat-header-cell *matHeaderCellDef>Client Name</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{ element.vClientName }}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="iCreatedAt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Requirement Created Date </th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="vReqStatus">
                    <th mat-header-cell *matHeaderCellDef>Requirement Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{ element.hiredCount }}/{{ element.talentCount }}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex align-items-center">
                            <button class="status-btn action-btn bg-blue" placement="left auto"
                                [ngbTooltip]="viewRequirementActionPermission ? 'View Requirement':'Not Authorized'"
                                [ngClass]="viewRequirementActionPermission ? '':'unauthorized-cursor'" (click)=" OpenModelViewRequirement(element, viewRequirementActionPermission)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="12.5"
                                    viewBox="0 0 18.333 12.5">
                                    <path id="Path_9273" data-name="Path 9273"
                                        d="M10.167,4.5A9.856,9.856,0,0,0,1,10.75a9.847,9.847,0,0,0,18.333,0A9.856,9.856,0,0,0,10.167,4.5Zm0,10.417a4.167,4.167,0,1,1,4.167-4.167A4.168,4.168,0,0,1,10.167,14.917Zm0-6.667a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,10.167,8.25Z"
                                        transform="translate(-1 -4.5)" fill="#48acf0" />
                                </svg>
                            </button>
                            <button class="action-btn bg-red">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.333" height="15" viewBox="0 0 13.333 15">
                                    <path id="delete_FILL0_wght400_GRAD0_opsz48" d="M10.1,21a1.282,1.282,0,0,1-1.25-1.25V7.875H8.625a.625.625,0,1,1,0-1.25h3.292A.606.606,0,0,1,12.542,6h4.25a.606.606,0,0,1,.625.625h3.292a.625.625,0,1,1,0,1.25h-.229V19.75A1.282,1.282,0,0,1,19.229,21Zm0-13.125V19.75h9.125V7.875Zm2.208,9.458a.625.625,0,1,0,1.25,0V10.271a.625.625,0,1,0-1.25,0Zm3.458,0a.625.625,0,1,0,1.25,0V10.271a.625.625,0,1,0-1.25,0ZM10.1,7.875v0Z" transform="translate(-8 -6)" fill="#d2474e"/>
                                </svg>    
                            </button>
                            <!-- <button class="status-btn lightpink-bg pink-text text-center mb-2" (click)="DeleteRequirement(element)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete">
                                <mat-icon>delete</mat-icon>
                            </button> -->
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne"></tr>
            </table>
        </div>
        <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0}" #TableOnePaginator="matPaginator" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons>
        </mat-paginator>
    </div>

</div>

<!-- <ng-template #viewRequirement let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
        <button class="modal-close-btn" (click)="d('Cross click');this.filterType='All'">
            <mat-icon>close</mat-icon>
        </button>
        <div class="content">
            <div class="w-100">
                <h1 class="title">{{ ParentReqName }}</h1>
                <h2 class="pagetitle">List of added talents</h2>
                <div class="my-3">
                    <mat-form-field appearance="outline">
                        <mat-select [(value)]="filterType" placeholder="Select Skill"
                            (selectionChange)="applyFilterTwo()">
                            <mat-option value="All">All</mat-option>
                            <mat-option *ngFor="let skill of vPrimarySkillArray" [value]="skill.iPrimarySkillId">
                                {{ skill.vPrimarySkill }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngFor="let item of RequirementTalents" class="outer-box">
                <label class="fw-bold">Designation : {{ item.vPrimarySkill }}</label>
                <label class="fw-bold" *ngFor="let obj of experience"><span
                        *ngIf="obj.value == item.iExperienceLevel">Experience : {{ obj.viewValue }}</span></label>
                <label class="fw-bold">Required Resources : {{ item.iTalentNumber }}</label>
                <div *ngIf="item.RequirementTeam.length == 0">
                    <span class="label">No talents found in team</span>
                </div>
                <div *ngFor="let element of item.RequirementTeam" class="inner-box">
                    <div class="label">
                        <span class="fw-bold">Name : </span>{{ element.vFirstName }}
                        {{ element.vLastName }} ({{ element.vDisplayUniqueId }})
                    </div>
                    <div class="label">
                        <span class="fw-bold">Designation : </span>{{ element.iEmpDesignation }}
                        <span class="experience" *ngIf="element.fEmpExperience >= 1">
                            ({{ element.fEmpExperience.toString().split(".")[0] }}+)
                        </span>
                        <span class="experience" *ngIf="element.fEmpExperience < 1">
                            ({{ element.fEmpExperience * 10 | number: "1.1-2" }}+)
                        </span>
                    </div>
                    <div class="label">
                        <span class="fw-bold">Price : </span>${{
                        element.dEmployeePrice
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->