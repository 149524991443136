import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpDegreeService {

  constructor( private http: HttpClient ) { }

  GET(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/degree`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  POST(data: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v1/degree`;
        this.http
          .post(apiURL,data)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }

  PUT(data: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v1/degree`;
        this.http
          .put(apiURL,data)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }

  DELETE(id: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + '/admin/v1/degree/' + id;
        this.http
          .delete(apiURL)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }
}
