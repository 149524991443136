import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequirementService {

  constructor(private http: HttpClient) { }


  GetRequirements(){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v1/get-requirements`;
        this.http
          .get(apiURL)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }

  GetRequirementDetailsById(id:any){
    const promise = new Promise((resolve, reject) => {
    let apiURL = environment.baseUrl + '/admin/v1/get-requirement-detail/' + id;
    this.http
          .get(apiURL)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }
  
}
