import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private http:HttpClient) { }

  GetLocalTimeZone(){
    const localtimezone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    return localtimezone;
  }

  Signup(data:any){
    return this.http.post(`${environment.baseUrl}/partner/v1/signup`,data);
  }

  SkillList() {
    return this.http.get(`${environment.baseUrl}/common/v1/skillsList`);
  }

  ResendEmailVerification(data:any){
    return this.http.post(`${environment.baseUrl}/partner/v1/resendEmailVerification`,data);
  }

  EmployeeList(skill:any) {
    return this.http.get(`${environment.baseUrl}/employee/v1/employee-list?skill=${skill}`)
  }

  loginUserInformation(data:any){
    return this.http.get(`${environment.baseUrl}/common/v1/login-user-information`,data);
  }

  logoutAPI(data:any){
    return this.http.post(`${environment.baseUrl}/common/v1/Logout`,data);
  }

  Logout(){
    localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_type");
        localStorage.removeItem("admin_user_email");
        localStorage.removeItem("sub_admin_account_type");
        localStorage.removeItem("sub_admin_email");
        localStorage.removeItem("sub_admin_role");
        localStorage.removeItem("admin_zendesk_token");
        localStorage.removeItem("admin_device_id");
  }

  ContactUs(data:any){
    return this.http.post(`${environment.baseUrl}/common/v1/contact-us`,data);
  }

  SubscriptionRequest(data:any){
    return this.http.post(`${environment.baseUrl}/common/v1/subscription-request`,data);
  }

  ClientSignup(data:any){
    return this.http.post(`${environment.baseUrl}/client/v1/signup`,data);
  }

  PartnerNewSignup(data:any){
    return this.http.post(`${environment.baseUrl}/partner/v1/new-signup`,data);
  }

  SubscriptionPlan(){
    return this.http.get(`${environment.baseUrl}/common/v1/subscription-plan`);
  }

}
