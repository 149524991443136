import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DocumentsService {
  constructor(private http: HttpClient) {}

  GetCompanyDocuments(partnerUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/company-documents?iUserId=${partnerUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateDocuments(data: any,partnerUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/company-documents?iUserId=${partnerUserId}`;
      const formData: FormData = new FormData();
      formData.append("vCorporateIdentityNumber", data.vCorporateIdentityNumber);
      formData.append("vCertificateOfIncorporationPdf", data.vCertificateOfIncorporationPdf);
      formData.append("vShopEstablishmentCertificateNumber", data.vShopEstablishmentCertificateNumber);
      formData.append("vCertificateOfShopEstablishmentPdf", data.vCertificateOfShopEstablishmentPdf);
      formData.append("vUdyogAadhaarNumber", data.vUdyogAadhaarNumber);
      formData.append("vUdyogAadhaarPdf", data.vUdyogAadhaarPdf);
      formData.append("vUtilityBillPdf", data.vUtilityBillPdf);
      formData.append("vTANNumber", data.vTANNumber);
      formData.append("vTANPdf", data.vTANPdf);
      formData.append("vPANNumber", data.vPANNumber);
      formData.append("vPANPdf", data.vPANPdf);
      formData.append("vCancelledBankChequePdf", data.vCancelledBankChequePdf);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteDocument(document: any,partnerUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v1/company-document/${document}?iUserId=${partnerUserId}`;
      this.http
        .delete(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
