<div class="page-content admin-section contract-container">

    <h1 class="admin-title">Interviews</h1>

    <div>
        <div class="view-heading-container">
            <div class="contract-button-container d-xxl-flex">
              <div class="d-sm-flex bottom-space">
                <button mat-button (click)="allDetails()" [ngClass]="{'active-tab-btn' : view == 0}"
                    class="inactive-tab-btn block-space">All</button>
                <button mat-button (click)="upcomingDetails()" [ngClass]="{'active-tab-btn' : view == 1}" class="inactive-tab-btn btn-width space-left">Upcoming/Require Action</button>
              </div>
              <div class="d-sm-flex bottom-space">
                <button mat-button (click)="historyDetails()" [ngClass]="{'active-tab-btn' : view == 2}"
                    class="inactive-tab-btn space-left block-space">History</button>
              </div>
            </div>
            <mat-form-field class="custom-input custom-search-input spacing-input" floatLabel="never"
                floatPlaceholder="never">
                <mat-label>Search Email Address</mat-label>
                <input matInput type="text" (keyup)="applyFilterOnSearch($event)">
                <button matSuffix>
                    <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        viewBox="0 0 20 20">
                        <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
                        <path id="Path_690" data-name="Path 690"
                            d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
                            transform="translate(0)" fill="#464646" />
                    </svg>
                </button>
            </mat-form-field>
        </div>
    </div>

    <div class="table-border">
        <div class="table-responsive">
            <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
            </div>
            <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }" [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
              <ng-container matColumnDef="indexnumber">
                <th mat-header-cell *matHeaderCellDef> No </th>
                <td mat-cell *matCellDef="let i = index;">
                  {{ this.TableOnePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.TableOnePaginator.pageIndex * this.TableOnePaginator.pageSize}}
                </td>
              </ng-container>
              <ng-container matColumnDef="vEmpName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Employee Name </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element.vEmpFirstName}} {{element.vEmpLastName}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="vEmpDesignation">
                  <th mat-header-cell *matHeaderCellDef> Designation </th>
                  <td mat-cell *matCellDef="let element">
                      <span>{{element.vEmpDesignation}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="fEmpExperience">
                  <th mat-header-cell *matHeaderCellDef> Experience </th>
                  <td mat-cell *matCellDef="let element">
                      <!-- <span>{{element.fEmpExperience}}</span> -->
                      <span  *ngIf="element.fEmpExperience >= 1">
                          {{ (element.fEmpExperience).toString().split(".")[0] }}+
                        </span>
                        <span *ngIf="element.fEmpExperience < 1">
                          {{ (element.fEmpExperience * 10) | number:'1.1-2' }}+
                        </span>
                  </td>
                </ng-container>
          
                <ng-container matColumnDef="iDateEpoch">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Interview Time </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{element.iDateEpoch * 1000 | date:'dd MMMM, yyyy'}} from {{element.iStartTime * 1000 | date:'h:mm a'}} to {{element.iEndTime * 1000 | date:'h:mm a'}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="iRoundNumber">
                  <th mat-header-cell *matHeaderCellDef> Round</th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.iRoundNumber==1">1st</span>
                    <span *ngIf="element.iRoundNumber==2">2nd</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="vClientCompanyName">
                  <th mat-header-cell *matHeaderCellDef> Client Name </th>
                  <td mat-cell *matCellDef="let element">
                      <span>{{element.vClientCompanyName}}</span>
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="vPartnerCompanyName">
                  <th mat-header-cell *matHeaderCellDef> Partner Name </th>
                  <td mat-cell *matCellDef="let element">
                      <span>{{element.vPartnerCompanyName}}</span>
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="tiInterviewStatus">
                  <th mat-header-cell *matHeaderCellDef> Interview Status </th>
                  <td mat-cell *matCellDef="let element" style="min-width: 320px">
                    <div *ngFor="let obj of interviewStatus">
                      <label class="status-tag" *ngIf="obj.value==element.tiInterviewStatus" [ngClass]="{'bg-green':element.tiInterviewStatus=='1' || element.tiInterviewStatus=='7', 'bg-red': element.tiInterviewStatus=='5' || element.tiInterviewStatus=='10' || element.tiInterviewStatus=='2', 'bg-yellow': element.tiInterviewStatus=='4'}">
                        {{obj.viewValue}}
                      </label>
                    </div>
                  </td>
                </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex align-items-center">
                    <div placement="left auto" [ngbTooltip]="accessVideoConferencePermission ? 'Join Conference':'Not Authorized'" [ngClass]="accessVideoConferencePermission ? '':'unauthorized-cursor'">
                      <button class="status-btn lightblue-bg blue-text text-center mb-2" [ngClass]="accessVideoConferencePermission ? '':'unauthorized-action'" routerLink="/video-conference/{{element.iInterviewDetailId}}" *ngIf="element.tiInterviewStatus == 6">
                        <mat-icon>video_call</mat-icon>
                      </button>
                    </div>
                    <span *ngIf="element.tiInterviewStatus != 6" class="status-btn lightgray-bg gray-text"><span
                      class="material-icons me-1">hide_source</span>No Action</span>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
            </table>
          </div>
        <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
            #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
        </mat-paginator>
    </div>

</div>

<ng-template #viewInterview let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-edit add-skill-modal">
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h1 class="title">View Interview Detail</h1>
        <div class="card">
          <div class="image-box">
            <img class="card-image" src="" alt="Profile Image" />
            <div class="text-box">
              <label class="title">{{ apiResponse.vEmpFirstName }}</label>
              <label class="sub-title">{{ apiResponse.vEmpDesignation }}
                <span class="text-highlight" *ngIf="employeeData.fEmpExperience >= 1">
                  ({{ apiResponse.fEmpExperience.toString().split(".")[0] }}+)
                </span>
                <span class="text-highlight" *ngIf="employeeData.fEmpExperience < 1">
                  ({{ apiResponse.fEmpExperience * 10 | number: "1.1-2" }}+)
                </span>
              </label>
              <label class="text">{{ apiResponse.vEmpDisplayUniqueId }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
