import { Component, OnInit, ViewChild } from '@angular/core';
import { PartnerService } from './partner.service';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from 'src/app/config/event-emitter.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Permission } from 'src/app/utils/enums/permission.enum';
import { UserPermissionService } from 'src/app/utils/services/user-permission.service';
import { NotificationService } from 'src/app/config/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html'
})
export class PartnerComponent implements OnInit {

  // toggle actions permission
  viewPartnerProfileActionPermission: boolean = false;
  activePartnerUserActionPermission: boolean = false;
  inactivePartnerUserActionPermission: boolean = false;

  constructor(
    private service : PartnerService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private router:Router,
    private userPermissionService: UserPermissionService,
    private notifyService: NotificationService
  ){
    // assign individual permissions
    const ViewPartnerProfileAction: Permission = Permission.ViewPartnerProfileAction;
    const ActivePartnerUserAction: Permission = Permission.ActivePartnerUserAction;
    const InactivePartnerUserAction: Permission = Permission.InactivePartnerUserAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ViewPartnerProfileAction)) {
      this.viewPartnerProfileActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ActivePartnerUserAction)) {
      this.activePartnerUserActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(InactivePartnerUserAction)) {
      this.inactivePartnerUserActionPermission = true;
    }
  }

  displayedColumnsTableOne: string[] = ['indexnumber', 'name', 'email', 'number', 'uniqueId', 'publishStatus', 'create', 'actions', 'toggleAction'];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

  ngOnInit(): void {
    this.getUserList();
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  getUserList() {
    this.service.getUserList().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      }
    });
  }

  toggleStatus(event: any, userId: any) {
    if(event.target.checked == true && this.activePartnerUserActionPermission) {
      this.changeActiveInactiveUser(userId,1,this.activePartnerUserActionPermission)
    }else if(event.target.checked == false && this.inactivePartnerUserActionPermission) {
      this.changeActiveInactiveUser(userId,2,this.inactivePartnerUserActionPermission)
    }else{
      event.target.checked = !event.target.checked;
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  changeActiveInactiveUser(userId: any, accStatus: any, hasPermission: boolean) {
    if (hasPermission) {
      let accType=3;
      this.service.changeActiveInactiveUser({userId:userId,accStatus:accStatus,tiAccountType:accType}).then((result: any) => {
        if (result['code'] == 200) {
          this.notifyService.showSuccess(result.message,'');
          this.getUserList();
        }
      });
    }else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }


  public progressValue:any = 0;
  public iProfilePublishCompleted:any = 0;
  GetProfileStatus(iUserId:any){
    this.service.GetProfileStatus(iUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result['data']['iProfilePublishCompleted'];
        this.progressValue = result['data']['progressValue'];
        this.eventEmitterService.GetProfileStatusCallMethod(result['data']);
      }
    });
  }

  viewProfile(data: any, hasPermission: boolean){
    if (hasPermission) {
      this.router.navigate([`/partner/${data.userId}`]);
    }else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

}

