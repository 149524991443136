import { Component, OnInit , ViewChild,AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

const ELEMENT_DATA: any[] = [
  {project:'Bender Project', type: 'DTM', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 1},
  {project:'Tracksun', type: 'In-House', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 4},
  {project:'Tracksun', type: 'In-House', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 2},
  {project:'Casanova', type: 'Fixed Cost', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 3},
  {project:'Hornets', type: 'DTM', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 1},
  {project:'Matadors', type: 'In-House', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 2},
  {project:'Whistler', type: 'Fixed Cost', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 3},
  {project:'Bender Project', type: 'DTM', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 1},
  {project:'Tracksun', type: 'In-House', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 4},
  {project:'Tracksun', type: 'In-House', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 2},
  {project:'Casanova', type: 'Fixed Cost', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 3},
  {project:'Hornets', type: 'DTM', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 1},
  {project:'Matadors', type: 'In-House', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 2},
  {project:'Whistler', type: 'Fixed Cost', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 3},
  {project:'Bender Project', type: 'DTM', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 1},
  {project:'Tracksun', type: 'In-House', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 4},
  {project:'Tracksun', type: 'In-House', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 2},
  {project:'Casanova', type: 'Fixed Cost', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 3},
  {project:'Hornets', type: 'DTM', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 1},
  {project:'Matadors', type: 'In-House', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 2},
  {project:'Whistler', type: 'Fixed Cost', iStartDate: '2021-09-09',iEndDate: '2021-12-17', status: 3},
];

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styles: [
  ]
})
export class DatatableComponent implements OnInit {

  displayedColumns: string[] = ['project', 'type', 'iStartDate', 'iEndDate','status','actions'];
  dataSource: any;
  data:any;
  TableSize: number = 10;
  TablePageIndex = 0;
  TableCurrentPage: number = 1;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {
    this.data=ELEMENT_DATA;
    // Assign the data to the data source for the table to render
    // this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.slice(0, this.TableSize));
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  paginate(event: any) {
    this.TablePageIndex = event;
    this.dataSource.data = this.data.slice(event * this.TableSize - this.TableSize, event * this.TableSize);
  }

  applyFilter(event: Event) {
    this.dataSource.data=this.data;
    const filterValue = (event.target as HTMLInputElement).value;
    if(filterValue){
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }else{
      this.dataSource = new MatTableDataSource(this.data.slice(0, this.TableSize));
    }
    
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

}
