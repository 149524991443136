<div class="page-content admin-section deal-engine-section">
  <div class="">
    <div
      class="d-flex justify-content-between flex-column flex-sm-row -align-items-center"
    >
      <div>
        <app-ng7-dynamic-breadcrumb></app-ng7-dynamic-breadcrumb>
        <h1 class="admin-title mb-4">Deal Engine</h1>
      </div>
      <div>
        <button
          class="primary-default-btn create-deal-btn mb-3 d-flex align-items-center w-sm-100" [ngClass]="createNewDealActionPermission ? '':'unauthorized-cursor'" placement="bottom auto" [ngbTooltip]="createNewDealActionPermission ? '':'Not Authorized'"
          (click)="OpenCreateDealModal(createDealModel, createNewDealActionPermission)"
        >
          <mat-icon>add</mat-icon> Create New Deal
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-between row order-1 billing-card-gap">
      <div class="col-12 mt-md-0 col-md-6 col-xl-6 col-xxl-4 mb-4">
        <section class="main-section">
          <div class="card talent-card talent-card-profile-section">
            <div class="profile-talent-card">
              <div class="gap-flex-10">
                <div class="talent-profile-section">
                  <div class="admin-section-wrapper">
                    <div class="admin-section-header">
                      <div class="admin-section-wrapper-left-section">
                        <span class="card-section-subtitle">Open Deals</span>
                        <div class="totel-onboard-number-gap total-open-vDealName">
                          <label for="" class="card-section-title">{{countDeals.openDealCount}}</label>
                          <div *ngIf="countDeals.openDealCurrentMonthData > 0">
                            <span class="total-progress-chip p-0"> + {{countDeals.openDealCurrentMonthData}} </span>
                          </div>
                        </div>
                      </div>
                      <div class="admin-section-wrapper-right-section">
                        <div>
                          <img src="./assets/images/opendeals.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="col-12 mt-md-0 col-md-6 col-xl-6 col-xxl-4 mb-4">
        <section class="main-section">
          <div class="card talent-card talent-card-profile-section">
            <div class="profile-talent-card">
              <div class="gap-flex-10">
                <div class="talent-profile-section">
                  <div class="admin-section-wrapper">
                    <div class="admin-section-header">
                      <div class="admin-section-wrapper-left-section">
                        <span class="card-section-subtitle">Closed Deals</span>
                        <div class="totel-onboard-number-gap total-open-vDealName">
                          <label for="" class="card-section-title">{{countDeals.closeDealCount}}</label>
                          <div *ngIf="countDeals.closedDealCurrentMonthData > 0">
                            <span class="total-progress-chip p-0"> + {{countDeals.closedDealCurrentMonthData}} </span>
                          </div>
                        </div>
                      </div>
                      <div class="admin-section-wrapper-right-section">
                        <div>
                          <img src="./assets/images/totalpartner.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="col-12 mt-md-0 col-md-6 col-xl-6 col-xxl-4 mb-4">
        <section class="main-section">
          <div class="card talent-card talent-card-profile-section">
            <div class="profile-talent-card">
              <div class="gap-flex-10">
                <div class="talent-profile-section">
                  <div class="admin-section-wrapper">
                    <div class="admin-section-header">
                      <div class="admin-section-wrapper-left-section">
                        <span class="card-section-subtitle"
                          >Total Partners</span
                        >
                        <div class="totel-onboard-number-gap">
                          <label for="" class="card-section-title">{{countDeals.totalPartners}}</label>
                          <div *ngIf="countDeals.totalPartnersCurrentMonthData > 0">
                            <span class="total-progress-chip p-0"> + {{countDeals.totalPartnersCurrentMonthData}} </span>
                          </div>
                        </div>
                      </div>
                      <div class="admin-section-wrapper-right-section">
                        <div>
                          <img src="./assets/images/totaltelent.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- tab section -->
    <!-- <div class="d-flex justify-content-between"> -->
    <div class="tab-container">
      <div
        class="filter-section filter-section-wrapper filter-with-search-and-dropdown search-and-dropdown-end"
      >
        <mat-form-field class="custom-dropdown all filter-deals position-static" [floatLabel]="'never'"
        floatPlaceholder="'never'"
        >
          <mat-select
            disableRipple
            [(value)]="selectedDealFilter"
            (selectionChange)="filterValue()"
          >
            <mat-option [value]="0">All</mat-option>
            <mat-option
              *ngFor="let option of dealType"
              [value]="option.id"
              >{{ option.vDealText }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="custom-input custom-search-input fliter-size mb-3 position-static"
          [floatLabel]="'never'"
          floatPlaceholder="'never'"
        >
          <mat-label>Search Deal</mat-label>
          <input matInput type="text" (keyup)="filterOnKeyUp($event.target.value)" />
          <button matSuffix>
            <svg
              id="search_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                id="Path_689"
                data-name="Path 689"
                d="M0,0H20V20H0Z"
                fill="none"
              />
              <path
                id="Path_690"
                data-name="Path 690"
                d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
                transform="translate(0)"
                fill="#464646"
              />
            </svg>
          </button>
        </mat-form-field>
      </div>
      <mat-tab-group mat-align-tabs="start" class="custom-tab-group w-100">
        <!-- Open Deals tab section  -->
        <mat-tab label="Open Deals">
          <ng-template mat-tab-label>
            <button class="tab-btn" id="OpenDealID" (click)="getAllDealData(1,'Open Deals')"><span>Open Deals</span></button>
          </ng-template>
          <div *ngIf="this.dataSource.data.length == 0">
            <p class="no-data-found d-flex justify-content-center align-items-center my-4 h-100">No Data Available!</p>
          </div>
          
          <section class="main-section" *ngIf="this.dataSource.data.length > 0">
          <div class="opendeals">
            <perfect-scrollbar [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
              <table
                mat-table
                [dataSource]="dataSource| paginate
                : {
                    id: 'no',
                    itemsPerPage: TableSize,
                    currentPage: TableCurrentPage,
                    totalItems: tableLength
                  }
          "
                matSort
                class="deal-table"
              >
                <!-- No row Column -->
                <ng-container matColumnDef="no">
                  <th mat-header-cell *matHeaderCellDef >No.</th>
                  <td mat-cell *matCellDef="let i = index">{{TableCurrentPage == 1 ? i+1 : i+1+(1*TableSize*(TableCurrentPage-1)) }}</td>
                </ng-container>

                <!-- Deal Code  Column -->
                <ng-container matColumnDef="vDealCode">
                  <th mat-header-cell *matHeaderCellDef >
                    Deal Code
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.vDealCode }}</td>
                </ng-container>

                <!-- Deal Name  Column -->
                  <ng-container matColumnDef="vDealName">
                    <th mat-header-cell *matHeaderCellDef >
                      Deal Name (Type)
                    </th>
                    <td mat-cell *matCellDef="let row"><span class="d-flex gap-2"><span><b>{{ row.vDealName }} </b> ({{ row.iDealType }})</span> <span class="count-icon" *ngIf="row.dealCount > 0">{{row.dealCount}}</span>  </span> </td>
                  </ng-container>

                <!-- Type Column -->
                <!-- <ng-container matColumnDef="iDealType">
                  <th mat-header-cell *matHeaderCellDef >Type</th>
                  <td mat-cell *matCellDef="let row">{{ row.iDealType }}</td>
                </ng-container> -->
                <!-- Team Column -->

                <ng-container matColumnDef="vCompanyName">
                  <th mat-header-cell *matHeaderCellDef >
                    Client
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.vCompanyName }}</td>
                </ng-container>

                <!-- Start Date Column -->
                <ng-container matColumnDef="iDealOpeningDate">
                  <th mat-header-cell *matHeaderCellDef >
                    Opening Date
                  </th>
                  <td mat-cell *matCellDef="let row" [style.color]="row.color">
                    {{ row.iDealOpeningDate * 1000 | date: 'yyyy-MM-dd' }}
                  </td>
                </ng-container>

                <!-- End Date Column -->
                <ng-container matColumnDef="iDealClosingDate">
                  <th mat-header-cell *matHeaderCellDef >
                    Closed Date
                  </th>
                  <td mat-cell *matCellDef="let row" [style.color]="row.color">
                    {{ row.iDealClosingDate * 1000 | date: 'yyyy-MM-dd' }}
                  </td>
                </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                      >
                        Status
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-chip-list>
                          <mat-chip class="open-chip" *ngIf="row.iDealStatus == 1">
                            Open
                          </mat-chip>
                          <mat-chip class="upcoming-chip" *ngIf="row.iDealStatus == 4 ">
                            Upcoming
                          </mat-chip>                 
                        </mat-chip-list>
                      </td>
                    </ng-container>
      


                <!-- Action Colume -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex gap-3 flex-wrap">
                      <button
                        class="status-btn m-0 p-0 text-center"
                        placement="left auto" [ngbTooltip]="editDealActionPermission ? 'Edit Deal':'Not Authorized'" [ngClass]="editDealActionPermission ? '':'unauthorized-cursor'"
                        (click)="OpenEditDealAsParDealIDModal(editDealAsParDaleIDModel,element.iDealId, editDealActionPermission);" 
                      >
                        <img src="./assets/images/editdeal.svg" alt="" />
                      </button>
                      <button
                        class="status-btn m-0 p-0 text-center"
                        placement="left auto" [ngbTooltip]="cancelDealActionPermission ? 'Cancel Deal':'Not Authorized'" [ngClass]="cancelDealActionPermission ? '':'unauthorized-cursor'"
                        (click)="OpenDismissDealAsParDealIDModal(dismissDealAsParIDModel, cancelDealActionPermission); getIdForDismiss(element);"
                      >
                        <img src="./assets/images/canceldeal.svg" alt="" />
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsOpenDeals"></tr>
                <tr
                  mat-row
                  *matRowDef="
                    let row;
                    columns: displayedColumnsOpenDeals
                      | paginate
                        : { 
                            itemsPerPage: TableSize,
                            currentPage: TableCurrentPage,
                            totalItems: tableLength
                          }
                  "
                ></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <!-- <td class="mat-cell" colspan="4">
                    No data matching the filter "{{ input.value }}"
                  </td> -->
                </tr>
              </table>
            </perfect-scrollbar>
            <pagination-controls
              class="custom-pagination"
              previousLabel="Prev"
              nextLabel="Next"
              (pageChange)="
                TableCurrentPage = $event; paginate(TableCurrentPage)
              "
            ></pagination-controls>
            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
          </div>
          </section>
        </mat-tab>
        <!-- Requests tab section -->
        <mat-tab label="Requests">
          <ng-template mat-tab-label>
            <button class="tab-btn" (click)="getAllDealData(3,'Requests')"><span class="count-wrapper">Requests<span class="count-icon" *ngIf="countDeals.totalRequests > 0">{{countDeals.totalRequests}}</span></span></button>
          </ng-template>
          <div *ngIf="this.dataSource.data.length == 0">
            <p class="no-data-found d-flex justify-content-center align-items-center my-4 h-100">No Data Available!</p>
          </div>
          <section class="main-section" *ngIf="this.dataSource.data.length > 0">
            <div class="request">
              <perfect-scrollbar [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
                <table 
                  mat-table
                  [dataSource]="dataSource| paginate
                  : {
                      id: 'no',
                      itemsPerPage: TableSize,
                      currentPage: TableCurrentPage,
                      totalItems: tableLength
                    }
            "
                  matSort
                  class="deal-table"
                >
                  <!-- No row Column -->
                  <ng-container matColumnDef="no">
                    <th mat-header-cell *matHeaderCellDef >No.</th>
                    <td mat-cell *matCellDef="let i = index">{{TableCurrentPage == 1 ? i+1 : i+1+(1*TableSize*(TableCurrentPage-1)) }}</td>
                  </ng-container>
              
                  <!-- Deal Code  Column -->
                  <ng-container matColumnDef="vDealCode">
                    <th mat-header-cell *matHeaderCellDef >
                      Deal Code
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.vDealCode }}</td>
                  </ng-container>
    
                  <!-- Deal Name  Column -->
                  <ng-container matColumnDef="vDealName">
                    <th mat-header-cell *matHeaderCellDef >
                      Deal Name (Type)
                    </th>
                    <td mat-cell *matCellDef="let row"><b>{{ row.vDealName }}</b> ({{ row.iDealType }})</td>
                  </ng-container>
                    
                  <!-- Type Column -->
                  <ng-container matColumnDef="iDealType">
                      <th mat-header-cell *matHeaderCellDef >Type</th>
                      <td mat-cell *matCellDef="let row">{{ row.iDealType }}</td>
                    </ng-container>

                    <!-- Participators Column -->
                    <ng-container matColumnDef="vPartnerName">
                      <th mat-header-cell *matHeaderCellDef >
                        Participators
                      </th>
                    <td mat-cell *matCellDef="let row">
                            <div class="partnername-with-img" *ngIf="row.partnerDetails.length > 0">
                                <img class="partnername-with-img-size" src="{{row.vCompanyLogoUrl}}" alt=""> 
                                <span class="">  {{ row.vPartner }}</span> 
                            </div>
                            <div class="partnername-with-img" *ngIf="row.partnerDetails.length == 0 && row.iDealStatus ==2">
                              <span>NA</span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Location Column -->
                    <ng-container matColumnDef="vPartnerCompanyLocation">
                      <th mat-header-cell *matHeaderCellDef >
                        Location
                      </th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.vPartnerCountry }}
                        </td>
                    </ng-container>

                    <!-- Request Date & Time Column -->
                    <ng-container matColumnDef="iRequestDateTime">
                      <th mat-header-cell *matHeaderCellDef >
                        Request Date & Time
                      </th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.iCreatedAt * 1000 | date: 'MMM d, h:mm a' }}
                        </td>
                    </ng-container>


                  <!-- Status Column -->
                  <ng-container matColumnDef="status">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      
                    >
                      Status
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-chip-list>
                        <mat-chip class="earned-chip" *ngIf="row.tiPartnerDealStatus == 3 && row.iDealStatus !== 2">
                          Earned
                        </mat-chip>
                        <mat-chip class="missed-chip" *ngIf="row.tiPartnerDealStatus == 4 && row.iDealStatus !== 2">
                          Missed
                        </mat-chip>
                        <mat-chip class="open-chip" *ngIf="row.iDealStatus == 1">
                          Open
                        </mat-chip>
                        <mat-chip class="lost-chip" *ngIf="row.iDealStatus == 2">
                          Lost
                        </mat-chip>
                      </mat-chip-list>
                    </td>
                  </ng-container>
    

                      <!-- Action Colume -->
                      <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex gap-3 flex-wrap">
                            <button
                              class="status-btn m-0 p-0 text-center"
                              placement="left auto" [ngbTooltip]="viewParticipatorActionPermission ? 'View Deal':'Not Authorized'" [ngClass]="viewParticipatorActionPermission ? '':'unauthorized-cursor'"
                              (click)="OpenViewDealAsParDealIDModal(viewDealAsParDaleIDModel,element.vPartnerId, element.iDealId, viewParticipatorActionPermission)"
                            >
                              <img src="./assets/images/view_deal.svg" alt="" />
                            </button>
                            <button
                              class="status-btn m-0 p-0 text-center disabled-btn"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Earn Deal"
                              *ngIf="element.tiPartnerDealStatus == 3"
                            >
                              <img src="./assets/images/earneddeal.svg" alt="" />
                            </button>
                            <button
                              class="status-btn m-0 p-0 text-center"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Deactive Deal"
                              *ngIf="element.tiPartnerDealStatus == 2 || element.iDealStatus ==2"
                              
                            >
                              <img src="./assets/images/inactivedeal.svg" alt="" />
                            </button>
                            <button
                              class="status-btn m-0 p-0 text-center"
                              placement="left auto" [ngbTooltip]="activeDealActionPermission ? 'Active Deal':'Not Authorized'" [ngClass]="activeDealActionPermission ? '':'unauthorized-cursor'"
                              *ngIf="element.tiPartnerDealStatus == 1 && element.iDealStatus !=2"
                              (click)="ActionToWinParticipate(element.vPartnerId,element.iDealId,'Requests', activeDealActionPermission)"
                            >
                              <img src="./assets/images/activedeal.svg" alt="" />
                            </button>
                            <button
                              class="status-btn m-0 p-0 text-center disabled-btn"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Deactive Deal"
                              *ngIf="element.tiPartnerDealStatus == 4"
                            >
                              <img src="./assets/images/inactivedeal.svg" alt="" />
                            </button>
                          </div>
                        </td>
                      </ng-container>
              
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsRequests"></tr>
                  <tr
                    mat-row
                    *matRowDef="
                      let row;
                      columns: displayedColumnsRequests
                        | paginate
                          : {
                              itemsPerPage: TableSize,
                              currentPage: TableCurrentPage,
                              totalItems: tableLength
                            }
                    "
                  ></tr>
    
                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <!-- <td class="mat-cell" colspan="4">
                      No data matching the filter "{{ input.value }}"
                    </td> -->
                  </tr>
                </table>
              </perfect-scrollbar>
              <pagination-controls
                class="custom-pagination"
                previousLabel="Prev"
                nextLabel="Next"UpdateFlag
                (pageChange)="
                  TableCurrentPage = $event; paginate(TableCurrentPage)
                "
              ></pagination-controls>
              <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            </div>
          </section>
        </mat-tab>
        <!-- Closed Deals Tab section  -->
        <mat-tab label="Deals' History">
          <ng-template mat-tab-label>
            <button class="tab-btn" (click)="getAllDealData(2,'History')"><span>Deals' History</span></button>
          </ng-template>
          <div *ngIf="this.dataSource.data.length == 0">
            <p class="no-data-found d-flex justify-content-center align-items-center my-4 h-100">No Data Available!</p>
          </div>

          <section class="main-section" *ngIf="this.dataSource.data.length > 0">
          <div class="closed">
            <perfect-scrollbar [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
              <table
                mat-table
                [dataSource]="dataSource| paginate
                : {
                    id: 'no',
                    itemsPerPage: TableSize,
                    currentPage: TableCurrentPage,
                    totalItems: tableLength
                  }
          "
                matSort
                class="deal-table history"
              >
                <!-- No row Column -->
                <ng-container matColumnDef="no">
                  <th mat-header-cell *matHeaderCellDef >No.</th>
                  <td mat-cell *matCellDef="let i = index">{{TableCurrentPage == 1 ? i+1 : i+1+(1*TableSize*(TableCurrentPage-1)) }}</td>
                </ng-container>
  
                <!-- Deal Code  Column -->
                <ng-container matColumnDef="vDealCode">
                  <th mat-header-cell *matHeaderCellDef >
                    Deal Code
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.vDealCode }}</td>
                </ng-container>
  
                <!-- ID Column -->
                <ng-container matColumnDef="vDealName">
                  <th mat-header-cell *matHeaderCellDef >
                    Deal Name (Type)
                  </th>
                  <td mat-cell *matCellDef="let row"><b>{{ row.vDealName }}</b> ({{ row.iDealType }}) </td>
                </ng-container>
  
                <!-- Type Column -->
                <!--  <ng-container matColumnDef="iDealType">
                  <th mat-header-cell *matHeaderCellDef >Type</th>
                  <td mat-cell *matCellDef="let row">{{ row.iDealType }}</td>
                </ng-container> -->
                <!-- Team Column  -->
  
                <ng-container matColumnDef="vCompanyName">
                  <th mat-header-cell *matHeaderCellDef >
                    Client
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.vCompanyName }}</td>
                </ng-container>
  
                <!-- Start Date Column -->
                <ng-container matColumnDef="iDealOpeningDate">
                  <th mat-header-cell *matHeaderCellDef >
                    Opening Date
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.iDealOpeningDate * 1000 | date: 'yyyy-MM-dd'}}
                  </td>
                </ng-container>
  
                <!-- End Date Column -->
                <ng-container matColumnDef="iDealClosingDate">
                  <th mat-header-cell *matHeaderCellDef >
                    Closed Date
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.iDealClosingDate * 1000 | date: 'yyyy-MM-dd'}}
                  </td>
                </ng-container>

                    <!-- Partner Column -->
                    <ng-container matColumnDef="vPartnerName">
                      <th mat-header-cell *matHeaderCellDef >
                        Partner
                      </th>
                      <td mat-cell *matCellDef="let row">
                      
                      <div class="partnername-with-img" *ngIf="row.partnerDetails.length > 0">
                        <img class="partnername-with-img-size" src="{{row.vCompanyLogoUrl}}" alt=""> 
                        <span class="">  {{ row.vPartner }}</span> 
                      </div>
                      <div class="partnername-with-img" *ngIf="row.partnerDetails.length == 0 && row.iDealStatus ==2">
                        <span>NA</span>
                      </div>

                      </td>
                    </ng-container>
  
                          <!-- Status Column -->
                <ng-container matColumnDef="status">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    
                  >
                    Status
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-chip-list>
                      <mat-chip class="open-chip" *ngIf="row.iDealStatus == 1">
                        Open
                      </mat-chip>
                      <mat-chip class="lost-chip" *ngIf="row.iDealStatus == 2">
                        Lost
                      </mat-chip>
                      <mat-chip class="earned-chip" *ngIf="row.iDealStatus == 3">
                        Won
                      </mat-chip>
                      <!-- <mat-chip class="missed-chip" *ngIf="row.iDealStatus == 2">
                        Missed
                      </mat-chip> -->
                    </mat-chip-list>
                  </td>
                </ng-container>
              
  
                <!-- Action Colume -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex gap-3 flex-wrap">
                      <!-- <div [ngClass]="element.iDealStatus== 1 || element.iDealStatus== 2 || element.iDealStatus== 3 ? '':'hide-btn'">
                         <button
                          class="status-btn m-0 p-0 text-center"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="View Deal"
                          (click)="OpenViewDealAsParDealIDModal(viewDealAsParDaleIDModel)"
                        >
                          <img src="./assets/images/view_deal.svg" alt="" />
                        </button>
                      </div> -->
                      <button
                        class="status-btn m-0 p-0 text-center"
                        placement="left auto" [ngbTooltip]="viewDealHistoryActionPermission ? 'View Deal':'Not Authorized'" [ngClass]="viewDealHistoryActionPermission ? '':'unauthorized-cursor'"
                        (click)="OpenEditDealAsParDealIDModal(viewDealIDModel,element.iDealId, viewDealHistoryActionPermission);"
                      >
                      <img src="./assets/images/view_deal.svg" alt="" />
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsHistory"></tr>
                <tr
                  mat-row
                  *matRowDef="
                    let row;
                    columns: displayedColumnsHistory
                      | paginate
                        : {
                            itemsPerPage: TableSize,
                            currentPage: TableCurrentPage,
                            totalItems: tableLength
                          }
                  "
                ></tr>
  
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                </tr>
              </table>
            </perfect-scrollbar>
              <pagination-controls
                class="custom-pagination"
                previousLabel="Prev"
                nextLabel="Next"
                (pageChange)="
                  TableCurrentPage = $event; paginate(TableCurrentPage)
                "
              ></pagination-controls>
              <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            </div>
            </section>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<ng-template #createDealModel let-c="close" let-d="dismiss">
  <div
    name="create-deal-modal-header"
    id="create-deal-modal-header"
    class="modal-header"
  >
    <h1
      name="create-deal-modal-title"
      id="create-deal-modal-title"
      class="modal-title"
    >
      Create New Deal
    </h1>
    <button (click)="CloseModal()" class="btn-close-custom"><mat-icon>close</mat-icon></button>
  </div>
  <perfect-scrollbar>
    <div
      name="create-deal-modal-body"
      id="create-deal-modal-body"
      class="modal-body modal-scroll"
    >
      <form
        id="createDealForm"
        name="createDealForm"
        class="form"
        [formGroup]="createDealForm"
        (ngSubmit)="CreateDealProcess()"
      >
        <div class="row">
          <div class="col-sm-12 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Deal Name<span class="text-danger">*</span></label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="vDealName"
                  placeholder="Type here"
                  #vDealName
                />
                <span
                  name="character-count-1"
                  id="character-count-1"
                  class="character-counter"
                  ><span
                    name="character-count-2"
                    id="character-count-2"
                    class="me-1"
                    [ngClass]="{ exceeded: vDealName.value.length > 150 }"
                    >{{ vDealName.value.length }}</span
                  >
                  / 150</span
                >
                <!-- <mat-error *ngIf="submitted && f.vDealName.invalid && (f.vDealName.dirty || f.vDealName.touched)"> -->
                  <mat-error *ngIf="f.vDealName.errors?.required">Deal Name is required</mat-error>
                  <mat-error *ngIf="f.vDealName.errors?.maxlength">Deal Name must not exceed 150 characters</mat-error>
                  <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Description<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here Description</mat-label>
                <textarea
                  matInput
                  rows="5"
                  formControlName="vDealDescription"
                  name="vDealDescription"
                  id="vDealDescription"
                  class="custom-textarea"
                  #vDealDescription
                ></textarea>
                <span
                  name="character-count-3"
                  id="character-count-3"
                  class="character-counter"
                  ><span
                    name="character-count-4"
                    id="character-count-4"
                    class="me-1"
                    [ngClass]="{
                      exceeded: vDealDescription.value.length > 255
                    }"
                    >{{ vDealDescription.value.length }}</span
                  >
                  / 255</span
                >
                <!-- <mat-error *ngIf="createDealForm.controls['vDealDescription'].invalid && (createDealForm.controls['vDealDescription'].dirty || createDealForm.controls['vDealDescription'].touched)"> -->
                  <mat-error *ngIf="f.vDealDescription.errors?.required">Deal Description is required</mat-error>
                  <mat-error *ngIf="f.vDealDescription.errors?.maxlength">Deal Description must not exceed 255 characters</mat-error>
                  <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Deal Type<span class="text-danger">*</span></label>
              <mat-form-field class="custom-dropdown form deal-count"  floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Choose a Deal Type</mat-label>

                <mat-select
                  disableRipple
                  formControlName="iDealTypeId"
                  [(value)]="iDealTypeId"
                 >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.id"
                    *ngFor="let option of dealType"
                    >{{ option.vDealText }}</mat-option
                  >
                </mat-select>
                <!-- <mat-error *ngIf="createDealForm.controls['iDealTypeId'].invalid && (createDealForm.controls['iDealTypeId'].dirty || createDealForm.controls['iDealTypeId'].touched)"> -->
                  <mat-error *ngIf="f.iDealTypeId.errors?.required">Deal Type Id is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Company Size<span class="text-danger">*</span></label
              >
              <mat-form-field class="custom-dropdown form deal-count" floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Choose a Client Company Size</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iClientCompanySize"
                  [(value)]="selectedCompanySize"
                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectCompanySize"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
                <!-- <mat-error *ngIf="createDealForm.controls['iClientCompanySize'].invalid && (createDealForm.controls['iClientCompanySize'].dirty || createDealForm.controls['iClientCompanySize'].touched)"> -->
                  <mat-error *ngIf="f.iClientCompanySize.errors?.required">Client CompanySize is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Opening Date<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input date-icon-field"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Choose a date</mat-label>
                <input
                  matInput
                  readonly
                  [min]="Today"
                  [matDatepicker]="picker1"
                  (dateChange)="DatechangeforUpcomingStatus($event)"
                  formControlName="iDealOpeningDate"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  class="date-toggle"
                  [for]="picker1"
                >
                  <mat-icon matDatepickerToggleIcon class="date-icon"
                    ><img src="./assets/images/date_range.svg" alt=""
                  /></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <!-- <mat-error *ngIf="createDealForm.controls['iDealOpeningDate'].invalid && (createDealForm.controls['iDealOpeningDate'].dirty || createDealForm.controls['iDealOpeningDate'].touched)"> -->
                  <mat-error *ngIf="f.iDealOpeningDate.errors?.required">Deal Opening Date is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Closing Date<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input date-icon-field"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Choose a date</mat-label>
                <input
                  matInput
                  readonly
                  [min]="TomorrowDate"
                  [matDatepicker]="picker2"
                  (dateChange)="DateCloseChange()"
                  formControlName="iDealClosingDate"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  class="date-toggle"
                  [for]="picker2"
                >
                  <mat-icon matDatepickerToggleIcon class="date-icon"
                    ><img src="./assets/images/date_range.svg" alt=""
                  /></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <!-- <mat-error *ngIf="createDealForm.controls['iDealClosingDate'].invalid && (createDealForm.controls['iDealClosingDate'].dirty || createDealForm.controls['iDealClosingDate'].touched)"> -->
                  <mat-error *ngIf="f.iDealClosingDate.errors?.required">Deal Closing Date is required</mat-error>
                  <mat-error *ngIf="errorFlag && !(f.iDealClosingDate.errors?.required)">Opening Date should not exceed Closing Date</mat-error>
               
                  <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client HQ Location<span class="text-danger">*</span></label
              >
              <mat-form-field class="custom-dropdown form" floatLabel="never"
              floatPlaceholder="never">
              <mat-label>Choose Client HQ Location</mat-label>

                <mat-select
                  disableRipple
                  formControlName="iClientHqLocation"
                  [(value)]="selectedClientHQLocation"
                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.iCountryId"
                    *ngFor="let option of selectClientHQLocation"
                    >{{ option.vName }}</mat-option
                  >
                </mat-select>
                <!-- <mat-error *ngIf="createDealForm.controls['iClientHqLocation'].invalid && (createDealForm.controls['iClientHqLocation'].dirty || createDealForm.controls['iClientHqLocation'].touched)"> -->
                  <mat-error *ngIf="f.iClientHqLocation.errors?.required">Client HQ Location is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Status<span class="text-danger">*</span></label
              >
              <mat-form-field class="custom-dropdown form arrow-display-none">
                <mat-select disabled="true"
                  disableRipple
                  formControlName="iDealStatus"
                  [(value)]="selectedDealStatus1"
                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealStatus"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
                <!-- <mat-error *ngIf="createDealForm.controls['iDealStatus'].invalid && (createDealForm.controls['iDealStatus'].dirty || createDealForm.controls['iDealStatus'].touched)"> -->
                  <mat-error *ngIf="f.iDealStatus.errors?.required">Deal Status is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Company Name<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vCompanyName"
                  
                  placeholder="Type here"
                  #vCompanyName
                />
                <span
                  name="character-count-5"
                  id="character-count-5"
                  class="character-counter"
                  ><span
                    name="character-count-6"
                    id="character-count-6"
                    class="me-1"
                    [ngClass]="{ exceeded: vCompanyName.value.length > 55 }"
                    >{{ vCompanyName.value.length }}</span
                  >
                  / 55</span
                >
                <!-- <mat-error *ngIf="createDealForm.controls['vCompanyName'].invalid && (createDealForm.controls['vCompanyName'].dirty || createDealForm.controls['vCompanyName'].touched)"> -->
                  <mat-error *ngIf="f.vCompanyName.errors?.required">Company Name is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Company Employees</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="iClientCompanyEmployees"
                  
                  placeholder="Type here"
                  [min]='1'
                  type="number"
                  #iClientCompanyEmployees
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Approximate Amount ($)</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="iDealAmount"
                  
                  placeholder="Type here"
                  #iDealAmount
                  [min]='1'
                  type="number"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Requirement</label
              >
              <mat-form-field class="custom-dropdown form" floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select Deal Requirement</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iDealRequirement"
                  [(value)]="selectedDealRequirement"
                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealRequirement"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Initiator Name</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vDealInitiatorName"
                  
                  placeholder="Type here"
                  #vDealInitiatorName
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Owner (Account Manager)</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vDealOwner"
                  
                  placeholder="Type here"
                  #vDealOwner
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">TRIF</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="iTRIF"
                  
                  placeholder="Type here"
                  #iTRIF
                  type="number"
                  [min]='1'
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Duration in Months</label
              >
              <mat-form-field class="custom-dropdown form" floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select Deal Duration</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iDealDuration"
                  [(value)]="selectedDealDurationInMonths"
                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealDurationInMonths"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Channel</label
              >
              <mat-form-field class="custom-dropdown form" floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select Deal Channel</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iDealChannel"
                  [(value)]="selectedDealChannel"
                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealChannel"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Website</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="txClientWebsite"
                  
                  placeholder="Type here"

                  #txClientWebsite
                />
                <mat-error *ngIf="f.txClientWebsite.errors?.pattern">Please enter valid URL</mat-error>

              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Industry</label
              >
              <mat-form-field class="custom-dropdown form" floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select Client Industry</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iClientIndustry"
                  [(value)]="selectedClientIndustry"
                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.iMasterIndustryId"
                    *ngFor="let option of selectClientIndustry"
                    >{{ option.vIndustryName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Prospect Name</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vProspectName"
                  
                  placeholder="Type here"
                  #vProspectName
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Job Title</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vJobTitle"
                  
                  placeholder="Type here"
                  #vJobTitle
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Email</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vEmail"
                  
                  placeholder="Type here"
                  #vEmail
                />
                <!-- <mat-error *ngIf="createDealForm.controls['vEmail'].invalid && (createDealForm.controls['vEmail'].dirty || createDealForm.controls['vEmail'].touched)"> -->
                  <mat-error *ngIf="f.vEmail.errors?.email">Please enter valid email address</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Phone Number</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vPhoneNumber"
                  minlength="6"
                  placeholder="Type here"
                  type="text"
                  inputmode="numeric" 
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                  #vPhoneNumber
                />
                <mat-error *ngIf="f.vPhoneNumber.errors?.minlength">Phone Number must 6 digit</mat-error>

              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Linkedin</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="txLinkedin"
                  
                  placeholder="Type here"
                  #txLinkedin
                />
                <!-- <mat-error *ngIf="createDealForm.controls['txLinkedin'].invalid && (createDealForm.controls['txLinkedin'].dirty || createDealForm.controls['txLinkedin'].touched)"> -->
                  <mat-error *ngIf="f.txLinkedin.errors?.pattern">Please enter valid LinkedIn</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">LSR Name</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vLSRName"
                  
                  placeholder="Type here"
                  #vLSRName
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Company Revenue</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vClientCompanyRevenue"
                  type="number"
                  placeholder="Type here"
                  #vClientCompanyRevenue
                  [min]="0"
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Comment</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here Comment</mat-label>
                <textarea
                  matInput
                  rows="5"
                  formControlName="txComments"
                  name="txComments"
                  id="txComments"
                  class="custom-textarea"
                  #txComments
                ></textarea>
                <span
                  name="character-count-3"
                  id="character-count-3"
                  class="character-counter"
                  ><span
                    name="character-count-4"
                    id="character-count-4"
                    class="me-1"
                    [ngClass]="{ exceeded: txComments.value.length > 255 }"
                    >{{ txComments.value.length }}</span
                  >
                  / 255</span
                >
                  <mat-error *ngIf="f.txComments.errors?.maxlength">Comments must not exceed 255 characters</mat-error>
                </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <div
    name="create-deal-modal-footer"
    id="create-deal-modal-footer"
    class="modal-footer"
  >
    <button
      type="submit"
      name="create-deal-modal-footer-btn-submit"
      id="create-deal-modal-footer-btn-submit"
      class="btn btn-active"
      form="createDealForm"
    >
      Publish
    </button>
    <!-- <button iDealType="button" name="create-deal-modal-footer-btn-cancel" id="create-deal-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="CancelDetail(); d('Cross click')">Cancel</button> -->
  </div>
</ng-template>

<ng-template #editDealAsParDaleIDModel let-c="close" let-d="dismiss">
  <div
    name="create-deal-modal-header"
    id="create-deal-modal-header"
    class="modal-header"
  >
    <h1
      name="create-deal-modal-title"
      id="create-deal-modal-title"
      class="modal-title"
    >
      Edit Deal
    </h1>
    <button (click)="CloseModal()" class="btn-close-custom"><mat-icon>close</mat-icon></button>
  </div>
  <perfect-scrollbar>
    <div
      name="create-deal-modal-body"
      id="create-deal-modal-body"
      class="modal-body modal-scroll"
    >
      <form
        id="updateDealForm"
        name="updateDealForm"
        class="form"
        [formGroup]="updateDealForm"
        (ngSubmit)="EditDealProcess()"
      >
        <div class="row">
          <div class="col-sm-12 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Deal Name<span class="text-danger">*</span></label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vDealName"
                  placeholder="Type here"
                  #vDealName (keyup)="UpdateFlag('vDealName')" 
                />
                <span
                  name="character-count-1"
                  id="character-count-1"
                  class="character-counter"
                  ><span
                    name="character-count-2"
                    id="character-count-2"
                    class="me-1"
                    [ngClass]="{ exceeded: vDealName.value.length > 150 }"
                    >{{ vDealName.value.length }}</span
                  >
                  / 150</span
                >
                <mat-error *ngIf="g.vDealName.errors?.required">Deal Name is required</mat-error>
                  <mat-error *ngIf="g.vDealName.errors?.maxlength">Deal Name must not exceed 150 characters</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Description<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here Description</mat-label>
                <textarea
                  matInput
                  rows="5"
                  formControlName="vDealDescription"
                  name="vDealDescription"
                  id="vDealDescription"
                  class="custom-textarea"
                  #vDealDescription
                  (keyup)="UpdateFlag('vDealDescription')"
                ></textarea>
                <span
                  name="character-count-3"
                  id="character-count-3"
                  class="character-counter"
                  ><span
                    name="character-count-4"
                    id="character-count-4"
                    class="me-1"
                    [ngClass]="{
                      exceeded: vDealDescription.value.length > 255
                    }"
                    >{{ vDealDescription.value.length }}</span
                  >
                  / 255</span
                >
                <mat-error *ngIf="g.vDealDescription.errors?.required">Deal Description is required</mat-error>
                  <mat-error *ngIf="g.vDealDescription.errors?.maxlength">Deal Description must not exceed 255 characters</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Deal Type<span class="text-danger">*</span></label>
              <mat-form-field class="custom-dropdown form" floatLabel="never"
              floatPlaceholder="never">
              <mat-label>Choose Deal Type</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iDealTypeId"
                  [(value)]="iDealTypeId"
                  (selectionChange)="UpdateFlag(iDealTypeId)"
                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.id"
                    *ngFor="let option of dealType"
                    >{{ option.vDealText }}</mat-option
                  >
                </mat-select>
                 <!-- <mat-error *ngIf="(updateDealForm.controls['iDealTypeId'].dirty || updateDealForm.controls['iDealTypeId'].touched)"> -->
                  <mat-error *ngIf="g.iDealTypeId.errors?.required">Deal TypeId is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Company Size<span class="text-danger">*</span></label
              >
              <mat-form-field class="custom-dropdown form"  floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Choose Client Company Size</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iClientCompanySize"
                  [(value)]="selectedCompanySize"
                  (selectionChange)="UpdateFlag('iClientCompanySize')"
                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectCompanySize"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
                <!-- <mat-error *ngIf="(updateDealForm.controls['iClientCompanySize'].dirty || updateDealForm.controls['iClientCompanySize'].touched)"> -->
                  <mat-error *ngIf="g.iClientCompanySize.errors?.required">Client CompanySize is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Opening Date<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input date-icon-field"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Choose a date</mat-label>
                <input
                  matInput
                  readonly
                  [min]="Today"
                  [matDatepicker]="picker1"
                  formControlName="iDealOpeningDate"
                  (dateChange)="DatechangeforUpcomingStatus($event)"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  class="date-toggle"
                  [for]="picker1"
                >
                  <mat-icon matDatepickerToggleIcon class="date-icon"
                    ><img src="./assets/images/date_range.svg" alt=""
                  /></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <!-- <mat-error *ngIf="(updateDealForm.controls['iDealOpeningDate'].dirty || updateDealForm.controls['iDealOpeningDate'].touched)"> -->
                  <mat-error *ngIf="g.iDealOpeningDate.errors?.required">Deal Opening Date is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Closing Date<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input date-icon-field"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Choose a date</mat-label>
                <input
                  matInput
                  readonly
                  [min]="TomorrowDate"
                  [matDatepicker]="picker2"
                  (dateChange)="EditDateCloseChange()"
                  formControlName="iDealClosingDate"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  class="date-toggle"
                  [for]="picker2"
                
                >
                  <mat-icon matDatepickerToggleIcon class="date-icon"
                    ><img src="./assets/images/date_range.svg" alt=""
                  /></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <!-- <mat-error *ngIf="(updateDealForm.controls['iDealClosingDate'].dirty || updateDealForm.controls['iDealClosingDate'].touched)"> -->
                  <mat-error *ngIf="g.iDealClosingDate.errors?.required">Deal Closing Date is required</mat-error>
                  <mat-error *ngIf="editErrorFlag  && !(g.iDealClosingDate.errors?.required)">Opening Date should not exceed Closing Date</mat-error>
                  <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client HQ Location<span class="text-danger">*</span></label
              >
              <mat-form-field class="custom-dropdown form" floatLabel="never"
              floatPlaceholder="never">
              <mat-label>Choose Client HQ Location</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iClientHqLocation"
                  [(value)]="selectedClientHQLocation"
                  (selectionChange)="UpdateFlag('iClientHqLocation')"

                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.iCountryId"
                    *ngFor="let option of selectClientHQLocation"
                    >{{ option.vName }}</mat-option
                  >
                </mat-select>
                <!-- <mat-error *ngIf="(updateDealForm.controls['iClientHqLocation'].dirty || updateDealForm.controls['iClientHqLocation'].touched)"> -->
                  <mat-error *ngIf="g.iClientHqLocation.errors?.required">Client HQ Location is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Status<span class="text-danger">*</span></label
              >
              <mat-form-field class="custom-dropdown form arrow-display-none" floatLabel="never"
              floatPlaceholder="never">
                <mat-select disabled="true"
                  disableRipple
                  formControlName="iDealStatus"
                  [(value)]="selectedDealStatus"
                  (selectionChange)="UpdateFlag('iDealStatus')"
                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealStatus"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
                <!-- <mat-error *ngIf="(updateDealForm.controls['iDealStatus'].dirty || updateDealForm.controls['iDealStatus'].touched)"> -->
                  <mat-error *ngIf="g.iDealStatus.errors?.required">Deal Status is required</mat-error>
                  
                  <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Company Name<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Select One</mat-label>
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vCompanyName"
                  
                  placeholder="Type here"
                  #vCompanyName
                  (keyup)="UpdateFlag('vCompanyName')"

                />
                <span
                  name="character-count-5"
                  id="character-count-5"
                  class="character-counter"
                  ><span
                    name="character-count-6"
                    id="character-count-6"
                    class="me-1"
                    [ngClass]="{ exceeded: vCompanyName.value.length > 55 }"
                    >{{ vCompanyName.value.length }}</span
                  >
                  / 55</span
                >
                <!-- <mat-error *ngIf="(updateDealForm.controls['vCompanyName'].dirty || updateDealForm.controls['vCompanyName'].touched)"> -->
                  <mat-error *ngIf="g.vCompanyName.errors?.required">Company Name is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Company Employees</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="iClientCompanyEmployees"
                  
                  placeholder="Type here"
                  (keyup)="UpdateFlag('iClientCompanyEmployees')"
                  #iClientCompanyEmployees
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Approximate Amount ($)</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="iDealAmount"
                  
                  placeholder="Type here"
                  (keyup)="UpdateFlag('iDealAmount')"
                  #iDealAmount
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Requirement</label
              >
              <mat-form-field class="custom-dropdown form deal-count" floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select Deal Requirement</mat-label>
                <mat-select
                  disableRipple
                  (selectionChange)="UpdateFlag('iDealRequirement')"
                  formControlName="iDealRequirement"
                  [(value)]="selectedDealRequirement"
                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealRequirement"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Initiator Name</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vDealInitiatorName"
                  (keyup)="UpdateFlag('vDealInitiatorName')"
                  
                  placeholder="Type here"
                  #vDealInitiatorName
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Owner (Account Manager)</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vDealOwner"
                  (keyup)="UpdateFlag('vDealOwner')"
                  
                  placeholder="Type here"
                  #vDealOwner
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">TRIF</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="iTRIF"
                  (keyup)="UpdateFlag('iTRIF')"
                  
                  placeholder="Type here"
                  #iTRIF
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Duration in Months</label
              >
              <mat-form-field class="custom-dropdown form deal-count" floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select Deal Duration</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iDealDuration"
                  [(value)]="selectedDealDurationInMonths"
                  (selectionChange)="UpdateFlag('iDealDuration')"
                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealDurationInMonths"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Channel</label
              >
              <mat-form-field class="custom-dropdown form deal-count" floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select Deal Channel</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iDealChannel"
                  [(value)]="selectedDealChannel"
                  (selectionChange)="UpdateFlag('iDealChannel')"

                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealChannel"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Website</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="txClientWebsite"
                  
                  (keyup)="UpdateFlag('txClientWebsite')"
                  placeholder="Type here"
                  #txClientWebsite
                />
                <mat-error *ngIf="g.txClientWebsite.errors?.pattern">Please enter valid URL</mat-error>

              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Industry</label
              >
              <mat-form-field class="custom-dropdown form deal-count" floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select Client Industry</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iClientIndustry"
                  [(value)]="selectedClientIndustry"
                  (selectionChange)="UpdateFlag('iClientIndustry')"

                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.iMasterIndustryId"
                    *ngFor="let option of selectClientIndustry"
                    >{{ option.vIndustryName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Prospect Name</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vProspectName"
                  (keyup)="UpdateFlag('vProspectName')"
                  
                  placeholder="Type here"
                  #vProspectName
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Job Title</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vJobTitle"
                  
                  placeholder="Type here"
                  #vJobTitle
                  (keyup)="UpdateFlag('vJobTitle')"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Email</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vEmail"
                  
                  placeholder="Type here"
                  #vEmail
                  (keyup)="UpdateFlag('vEmail')"
                />
                <!-- <mat-error *ngIf="(updateDealForm.controls['vEmail'].dirty || updateDealForm.controls['vEmail'].touched)"> -->
                  <mat-error *ngIf="g.vEmail.errors?.email">Please enter valid email address</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Phone Number</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vPhoneNumber"
                  placeholder="Type here"
                  (keyup)="UpdateFlag('vPhoneNumber')"
                  minlength="6"
                  type="text"
                  inputmode="numeric" 
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                  #vPhoneNumber
                />
                <mat-error *ngIf="g.vPhoneNumber.errors?.minlength">Phone Number must 6 digit</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Linkedin</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="txLinkedin"
                  
                  placeholder="Type here"
                  (keyup)="UpdateFlag('txLinkedin')"
                  #txLinkedin
                />
                  <mat-error *ngIf="g.txLinkedin.errors?.pattern">Please enter valid Linkedin address</mat-error>
                </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">LSR Name</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vLSRName"
                  
                  placeholder="Type here"
                  (keyup)="UpdateFlag('vLSRName')"
                  #vLSRName
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Company Revenue</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vClientCompanyRevenue"
                  type="number"
                  placeholder="Type here"
                  (keyup)="UpdateFlag('vClientCompanyRevenue')"
                  #vClientCompanyRevenue
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Comment</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here Comment</mat-label>
                <textarea
                  matInput
                  rows="5"
                  formControlName="txComments"
                  name="txComments"
                  id="txComments"
                  class="custom-textarea"
                  (keyup)="UpdateFlag('txComments')"
                  #txComments
                ></textarea>
                <span
                  name="character-count-3"
                  id="character-count-3"
                  class="character-counter"
                  ><span
                    name="character-count-4"
                    id="character-count-4"
                    class="me-1"
                    [ngClass]="{ exceeded: txComments.value.length > 255 }"
                    >{{ txComments.value.length }}</span
                  >
                  / 255</span
                >
                  <mat-error *ngIf="g.txComments.errors?.maxlength">Comments must not exceed 255 characters</mat-error>
                </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <div
    name="create-deal-modal-footer"
    id="create-deal-modal-footer"
    class="modal-footer"
  >
    <button
      type="submit"
      name="create-deal-modal-footer-btn-submit"
      id="create-deal-modal-footer-btn-submit"
      class="btn btn-active"
      form="updateDealForm"
      [disabled]="!updateDealForm.valid"
    >
      Update
    </button>
    <button type="button" name="create-deal-modal-footer-btn-cancel" id="create-deal-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="CancelDetail(); d('Cross click')">Cancel</button>
  </div>
</ng-template>

<ng-template #viewDealIDModel let-c="close" let-d="dismiss">
  <div
    name="create-deal-modal-header"
    id="create-deal-modal-header"
    class="modal-header"
  >
    <h1
      name="create-deal-modal-title"
      id="create-deal-modal-title"
      class="modal-title"
    >
      View Deal
    </h1>
    <button (click)="CloseModal()" class="btn-close-custom"><mat-icon>close</mat-icon></button>
  </div>
  <perfect-scrollbar>
    <div
      name="create-deal-modal-body"
      id="create-deal-modal-body"
      class="modal-body modal-scroll"
    >
      <form
        id="updateDealForm"
        name="updateDealForm"
        class="form view-deal-form pe-none"
        [formGroup]="updateDealForm"
        (ngSubmit)="EditDealProcess()"
      >
        <div class="row">
          <div class="col-sm-12 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Deal Name<span class="text-danger">*</span></label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vDealName"
                  
                  placeholder="Type here"
                  #vDealName (keyup)="UpdateFlag('vDealName')" 
                />
                <span
                  name="character-count-1"
                  id="character-count-1"
                  class="character-counter"
                  ><span
                    name="character-count-2"
                    id="character-count-2"
                    class="me-1"
                    [ngClass]="{ exceeded: vDealName.value.length > 150 }"
                    >{{ vDealName.value.length }}</span
                  >
                  / 150</span
                >
                <mat-error *ngIf="g.vDealName.errors?.required">Deal Name is required</mat-error>
                  <mat-error *ngIf="g.vDealName.errors?.maxlength">Deal Name must not exceed 150 characters</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Description<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here Description</mat-label>
                <textarea
                  matInput
                  rows="5"
                  formControlName="vDealDescription"
                  name="vDealDescription"
                  id="vDealDescription"
                  class="custom-textarea"
                  #vDealDescription
                  (keyup)="UpdateFlag('vDealDescription')"
                ></textarea>
                <span
                  name="character-count-3"
                  id="character-count-3"
                  class="character-counter"
                  ><span
                    name="character-count-4"
                    id="character-count-4"
                    class="me-1"
                    [ngClass]="{
                      exceeded: vDealDescription.value.length > 255
                    }"
                    >{{ vDealDescription.value.length }}</span
                  >
                  / 255</span
                >
                <mat-error *ngIf="g.vDealDescription.errors?.required">Deal Description is required</mat-error>
                  <mat-error *ngIf="g.vDealDescription.errors?.maxlength">Deal Description must not exceed 255 characters</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Deal Type<span class="text-danger">*</span></label>
              <mat-form-field class="custom-dropdown form"  floatLabel="never"
              floatPlaceholder="never">
                <mat-select
                  disableRipple
                  formControlName="iDealTypeId"
                  [(value)]="iDealTypeId"
                  (selectionChange)="UpdateFlag(iDealTypeId)"
                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.id"
                    *ngFor="let option of dealType"
                    >{{ option.vDealText }}</mat-option
                  >
                </mat-select>
                 <!-- <mat-error *ngIf="(updateDealForm.controls['iDealTypeId'].dirty || updateDealForm.controls['iDealTypeId'].touched)"> -->
                  <mat-error *ngIf="g.iDealTypeId.errors?.required">Deal TypeId is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Company Size<span class="text-danger">*</span></label
              >
              <mat-form-field class="custom-dropdown form" floatLabel="never"
              floatPlaceholder="never">
                <mat-select
                  disableRipple
                  formControlName="iClientCompanySize"
                  [(value)]="selectedCompanySize"
                  (selectionChange)="UpdateFlag('iClientCompanySize')"
                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectCompanySize"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
                <!-- <mat-error *ngIf="(updateDealForm.controls['iClientCompanySize'].dirty || updateDealForm.controls['iClientCompanySize'].touched)"> -->
                  <mat-error *ngIf="g.iClientCompanySize.errors?.required">Client CompanySize is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Opening Date<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input date-icon-field"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Choose a date</mat-label>
                <input
                  matInput
                  readonly
                  [min]="Today"
                  [matDatepicker]="picker1"
                  formControlName="iDealOpeningDate"
                  (dateChange)="DatechangeforUpcomingStatus($event)"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  class="date-toggle"
                  [for]="picker1"
                >
                  <mat-icon matDatepickerToggleIcon class="date-icon"
                    ><img src="./assets/images/date_range.svg" alt=""
                  /></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <!-- <mat-error *ngIf="(updateDealForm.controls['iDealOpeningDate'].dirty || updateDealForm.controls['iDealOpeningDate'].touched)"> -->
                  <mat-error *ngIf="g.iDealOpeningDate.errors?.required">Deal Opening Date is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Closing Date<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input date-icon-field"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Choose a date</mat-label>
                <input
                  matInput
                  readonly
                  [min]="Today"
                  [matDatepicker]="picker2"
                  (dateChange)="DateCloseChange()"
                  formControlName="iDealClosingDate"
                  (dateChange)="UpdateFlag('iDealClosingDate')"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  class="date-toggle"
                  [for]="picker2"
                
                >
                  <mat-icon matDatepickerToggleIcon class="date-icon"
                    ><img src="./assets/images/date_range.svg" alt=""
                  /></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <!-- <mat-error *ngIf="(updateDealForm.controls['iDealClosingDate'].dirty || updateDealForm.controls['iDealClosingDate'].touched)"> -->
                  <mat-error *ngIf="g.iDealClosingDate.errors?.required">Deal Closing Date is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client HQ Location<span class="text-danger">*</span></label
              >
              <mat-form-field class="custom-dropdown form">
                <mat-select
                  disableRipple
                  formControlName="iClientHqLocation"
                  [(value)]="selectedClientHQLocation"
                  (selectionChange)="UpdateFlag('iClientHqLocation')"

                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.iCountryId"
                    *ngFor="let option of selectClientHQLocation"
                    >{{ option.vName }}</mat-option
                  >
                </mat-select>
                <!-- <mat-error *ngIf="(updateDealForm.controls['iClientHqLocation'].dirty || updateDealForm.controls['iClientHqLocation'].touched)"> -->
                  <mat-error *ngIf="g.iClientHqLocation.errors?.required">Client HQ Location is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Status<span class="text-danger">*</span></label
              >
              <mat-form-field class="custom-dropdown form">
                <mat-select
                  disableRipple
                  formControlName="iDealStatus"
                  [(value)]="selectedDealStatus"
                  (selectionChange)="UpdateFlag('iDealStatus')"
                >
                  <!-- <mat-option value="0">Select</mat-option> -->
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealStatus"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
                <!-- <mat-error *ngIf="(updateDealForm.controls['iDealStatus'].dirty || updateDealForm.controls['iDealStatus'].touched)"> -->
                  <mat-error *ngIf="g.iDealStatus.errors?.required">Deal Status is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Company Name<span class="text-danger">*</span></label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vCompanyName"
                  
                  placeholder="Type here"
                  #vCompanyName
                  (keyup)="UpdateFlag('vCompanyName')"

                />
                <span
                  name="character-count-5"
                  id="character-count-5"
                  class="character-counter"
                  ><span
                    name="character-count-6"
                    id="character-count-6"
                    class="me-1"
                    [ngClass]="{ exceeded: vCompanyName.value.length > 55 }"
                    >{{ vCompanyName.value.length }}</span
                  >
                  / 55</span
                >
                <!-- <mat-error *ngIf="(updateDealForm.controls['vCompanyName'].dirty || updateDealForm.controls['vCompanyName'].touched)"> -->
                  <mat-error *ngIf="g.vCompanyName.errors?.required">Company Name is required</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Company Employees</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="iClientCompanyEmployees"
                  
                  placeholder="Type here"
                  (keyup)="UpdateFlag('iClientCompanyEmployees')"
                  #iClientCompanyEmployees
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Approximate Amount ($)</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="iDealAmount"
                  
                  placeholder="Type here"
                  (keyup)="UpdateFlag('iDealAmount')"
                  #iDealAmount
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Requirement</label
              >
              <mat-form-field class="custom-dropdown form">
                <mat-label>Select One</mat-label>
                <mat-select
                  disableRipple
                  (selectionChange)="UpdateFlag('iDealRequirement')"
                  formControlName="iDealRequirement"
                  [(value)]="selectedDealRequirement"
                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealRequirement"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Initiator Name</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vDealInitiatorName"
                  (keyup)="UpdateFlag('vDealInitiatorName')"
                  
                  placeholder="Type here"
                  #vDealInitiatorName
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Owner (Account Manager)</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vDealOwner"
                  (keyup)="UpdateFlag('vDealOwner')"
                  
                  placeholder="Type here"
                  #vDealOwner
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">TRIF</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="iTRIF"
                  (keyup)="UpdateFlag('iTRIF')"
                  
                  placeholder="Type here"
                  #iTRIF
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Duration in Months</label
              >
              <mat-form-field class="custom-dropdown form"  floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select One</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iDealDuration"
                  [(value)]="selectedDealDurationInMonths"
                  (selectionChange)="UpdateFlag('iDealDuration')"
                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealDurationInMonths"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Deal Channel</label
              >
              <mat-form-field class="custom-dropdown form" floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select One</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iDealChannel"
                  [(value)]="selectedDealChannel"
                  (selectionChange)="UpdateFlag('iDealChannel')"

                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectDealChannel"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Website</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="txClientWebsite"
                  
                  (keyup)="UpdateFlag('txClientWebsite')"
                  placeholder="Type here"
                  #txClientWebsite
                />
                <mat-error *ngIf="g.txClientWebsite.errors?.pattern">Please enter valid URL</mat-error>

              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Industry</label
              >
              <mat-form-field class="custom-dropdown form"   floatLabel="never"
              floatPlaceholder="never">
                <mat-label>Select Client Industry</mat-label>
                <mat-select
                  disableRipple
                  formControlName="iClientIndustry"
                  [(value)]="selectedClientIndustry"
                  (selectionChange)="UpdateFlag('iClientIndustry')"

                >
                  <mat-option>Select</mat-option>
                  <mat-option
                    [value]="option.value"
                    *ngFor="let option of selectClientIndustry"
                    >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Prospect Name</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vProspectName"
                  (keyup)="UpdateFlag('vProspectName')"
                  
                  placeholder="Type here"
                  #vProspectName
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Job Title</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vJobTitle"
                  
                  placeholder="Type here"
                  #vJobTitle
                  (keyup)="UpdateFlag('vJobTitle')"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Email</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vEmail"
                  
                  placeholder="Type here"
                  #vEmail
                  (keyup)="UpdateFlag('vEmail')"
                />
                <!-- <mat-error *ngIf="(updateDealForm.controls['vEmail'].dirty || updateDealForm.controls['vEmail'].touched)"> -->
                  <mat-error *ngIf="g.vEmail.errors?.email">Please enter valid email address</mat-error>
                <!-- </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Phone Number</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vPhoneNumber"
                  
                  placeholder="Type here"
                  (keyup)="UpdateFlag('vPhoneNumber')"
                  type="number"
                  #vPhoneNumber
                />
                <mat-error *ngIf="g.vPhoneNumber.errors?.minlength">Phone Number must 6 digit</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">Linkedin</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="txLinkedin"
                  
                  placeholder="Type here"
                  (keyup)="UpdateFlag('txLinkedin')"
                  #txLinkedin
                />
                  <mat-error *ngIf="g.txLinkedin.errors?.pattern">Please enter valid Linkedin address</mat-error>
                </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label">LSR Name</label>
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vLSRName"
                  
                  placeholder="Type here"
                  (keyup)="UpdateFlag('vLSRName')"
                  #vLSRName
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Client Company Revenue</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here</mat-label>
                <input
                  matInput
                  formControlName="vClientCompanyRevenue"
                  type="number"
                  placeholder="Type here"
                  (keyup)="UpdateFlag('vClientCompanyRevenue')"
                  #vClientCompanyRevenue
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-12">
            <div class="field-box">
              <label for="custom-input" class="custom-label"
                >Comment</label
              >
              <mat-form-field
                class="custom-input deal-count"
                floatLabel="never"
                floatPlaceholder="never"
              >
                <mat-label>Type here Comment</mat-label>
                <textarea
                  matInput
                  rows="5"
                  formControlName="txComments"
                  name="txComments"
                  id="txComments"
                  class="custom-textarea"
                  (keyup)="UpdateFlag('txComments')"
                  #txComments
                ></textarea>
                <span
                  name="character-count-3"
                  id="character-count-3"
                  class="character-counter"
                  ><span
                    name="character-count-4"
                    id="character-count-4"
                    class="me-1"
                    [ngClass]="{ exceeded: txComments.value.length > 255 }"
                    >{{ txComments.value.length }}</span
                  >
                  / 255</span
                >
                  <mat-error *ngIf="g.txComments.errors?.maxlength">Comments must not exceed 255 characters</mat-error>
                </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <!-- <div
    name="create-deal-modal-footer"
    id="create-deal-modal-footer"
    class="modal-footer"
  >
    <button
      type="submit"
      name="create-deal-modal-footer-btn-submit"
      id="create-deal-modal-footer-btn-submit"
      class="btn btn-active"
      form="updateDealForm"
      [disabled]="!updateDealForm.valid"
    >
      Update
    </button>
    <button type="button" name="create-deal-modal-footer-btn-cancel" id="create-deal-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="CancelDetail(); d('Cross click')">Cancel</button>
  </div> -->
</ng-template>


<ng-template #dismissDealAsParIDModel let-c="close" let-d="dismiss">
  <div name="dismiss-deal-modal-header" id="dismiss-deal-modal-header" class="modal-header">
    <h1 name="dismiss-deal-modal-title" id="dismiss-deal-modal-title" class="modal-title">
      Dismiss Deal
    </h1>
    
  </div>
  <div name="dismiss-deal-modal-body" id="dismiss-deal-modal-body" class="modal-body">

    <div class="dismiss-deal-confirmation-text">
      <img src="./assets/images/dismiss.svg" alt=""> <span>Are you sure you want to dismiss <span class="id">{{getElementDataById.vDealCode}}</span></span> 
    </div>
    
    <div
    name="dismiss-deal-modal-footer"
    id="dismiss-deal-modal-footer"
    class="modal-footer"
  >
  <div class="btn-box">
    <button class="btn-secondary"
      (click)="dismissDealModal.close(); 
      ">
      Cancel
    </button>
    <button class="btn-danger" (click)="dismissDealDataById(getElementDataById.iDealId)">
      Dismiss
    </button>
  </div>  
</div>

  </div>
</ng-template>


<ng-template #viewDealAsParDaleIDModel let-c="close" let-d="dismiss">
  <div
    name="view-deal-modal-header"
    id="view-deal-modal-header"
    class="modal-header"
  >
    <h1
      name="view-deal-modal-title"
      id="view-deal-modal-title"
      class="modal-title"
    >
    Participator Details
    </h1>
    <button (click)="CloseModal()" class="btn-close-custom"><mat-icon>close</mat-icon></button>
 
  </div>
  
  <div name="view-deal-modal-body" id="view-deal-modal-body" class="modal-body">
      <div class="d-flex flex-column gap-4">
        <div class="card">
            <div class="card-wrapper-view-modal"> 
              <div class="img-size">
                <img class="img-size" src="{{getDataOfPartner.vCompanyLogoUrl}}" alt="">
              </div> 
                <div class="view-deal-confirmation-text d-flex flex-column">
                <span class="id">{{getDataOfPartner.vPartner}}</span> 
                <span class="blue-link-text">{{getDataOfPartner.vEmailId}}</span>
                <span class="blue-link-text">{{getDataOfPartner.vPhoneNo}}</span> 
              </div>
    
            </div>
          </div> 
    
            <div class="card">
            <div class="card-wrapper-view-modal"> 
              <div class="partificate-comment">
                    <span class="partificate-comment-header">Participant's Comment</span>
                    <span class="partificate-comment-data">{{getDataOfPartner.txComments}}</span>
              </div>
    
            </div>
          </div>  
        
      </div>  
 
  </div>
</ng-template>
