export const StaticMsg = {
  SomethingWentWrong: "Something went wrong.",
  NewAndOldPasswordMatch: "The new password you entered is the same as your old password. Enter a different password.",
  IntroDeckFileLargerError: "File too Big, please select a file less than 10mb",
  PdfFileLargerError: "File too Big, please select a file less than 10mb",
  FileLargerError: "File too Big, please select a file less than 4mb",
  FileLargerThan1MbError: "File too Big, please select a file less than 1mb",
  ImageLargerError: "File too Big, please select a image less than 4mb",
  NotPdfFileError: "Uploaded file is not a valid pdf. Only PDF allowed!",
  VideoLargerError: "Video too Big, please select a video less than 100mb",
  VideoLargerThan50MbError: "Video too Big, please select a video less than 50mb",
  NotValidVideoTypeError: "Only MP4 files are allowed",
  NotValidImageTypeError: "Uploaded file is not a valid image. Only JPG, PNG & JPEG allowed!",
  ImageOutOfRangeError: "only 5 Images Allowed!",
  ProfileCompleteError: "Please Complete Your Profile 100%",
  PortfolioImageOutOfRangeError: "Per Portfolio only 5 Images Allowed!",
  NotCsvTypeError: "Uploaded file is not a valid CSV. Only CSV allowed!",
  UploadAllDocument: "Please upload all required documents",
  ImageLimitError: "Maximum 5 images are allowed here",
  NotPdfImageFileError: "Only PDF & Images are allowed!",
};

export const DocumentType = [
  { value: "0", viewValue: "Contract" },
  { value: "1", viewValue: "NDA" },
  { value: "2", viewValue: "Proposal" },
  { value: "3", viewValue: "PO" },
];

export const RecipientType = [
  { value: "1", viewValue: "Sender 1" },
  { value: "2", viewValue: "Sender 2" },
  { value: "3", viewValue: "Receiver 1" },
  { value: "4", viewValue: "Receiver 2" }
];