<div name="main-container" id="main-container" class="d-flex flex-column main-container h-100">
    <div name="container-fluid" id="container-fluid" class="container-fluid">
      <div name="main-content-box" id="main-content-box" class="main-content-box">
        <app-ng7-dynamic-breadcrumb></app-ng7-dynamic-breadcrumb>
        <div name="main-container-header" id="main-container-header" class="main-container-header">
          <!-- <h1 name="main-title" id="main-title" class="title">Sub User</h1> -->
          <div class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
            <div class="d-flex align-items-center">
              <i class="pagetitle-icon"><span class="material-icons">extension</span></i>
              <h2 class="pagetitle">Role Management - Admin</h2>
            </div>
          </div>
        </div>
        <div name="main-row" id="main-row" class="row g-4">
          <div name="main-col" id="main-col" class="col">
            <mat-tab-group name="user-tab" id="user-tab" class="employee-tab" animationDuration="0ms">
              <mat-tab name="-role-label" id="-role-label" label="User List">
                <div name="-role-card" id="-role-card" class="card h-auto">
                  <div name="-role-card-head" id="-role-card-head" class="card-head">
                    <form name="user-onboard-form" id="user-onboard-form" class="form">
                      <mat-form-field name="mat-form-field-onboard-user-id" id="mat-form-field-onboard-user-id"
                        class="custom-input" floatLabel="never" floatPlaceholder="never" hideRequiredMarker>
                        <input matInput type="text" name="userId" id="userId" placeholder="User Name"
                          (keyup)="SearchFilter($event)" />
                      </mat-form-field>
                      <mat-form-field name="mat-formRoleUserIdFilter-field-onboard-user-availability"
                        id="mat-form-field-onboard-user-availability" hideRequiredMarker
                        class="custom-dropdown custom-input form filter-dropdown-width" floatLabel="never"
                        floatPlaceholder="never">
                        <mat-select name="mat-select-availability" id="mat-select-availability" disableRipple
                          placeholder="Status" [(value)]="statusFilterValue" (selectionChange)="StatusFilter($event)">
                          <mat-option disableRipple *ngFor="let list of userStatusList" [value]="list.id"
                           selected="">{{ list.vName }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </form>
                    <div class="d-flex align-items-start flex-column flex-sm-row flex-wrap full-width">
                      <div placement="bottom auto" [ngbTooltip]="addSubUserActionPermission ? 'View Profile':'Not Authorized'">
                        <a id="onboard-add-user-btn" class="btn-with-icon" href="javascript:void(0)" [ngClass]="addSubUserActionPermission ? '':'unauthorized-action'" (click)="AddUsersModal(addUsers, addSubUserActionPermission)"><img class="btn-icon" [src]="imagePath + 'add-btn.png'" alt="view-profile" />Add User</a>
                      </div>
                    </div>
                  </div>
                  <label class="no-data-found d-flex justify-content-center align-items-center my-4 h-100"
                    *ngIf="RoleTableDataSource.length == 0">
                    No Users Found
                  </label>
                  <div name="user-role-tab" id="user-role-tab" class="card-body -role-tab mt-0">
                    <div name="user-role-table-box" id="user-role-table-box" class="table-box w-100"
                      *ngIf="RoleTableDataSource.length > 0">
                      <perfect-scrollbar [config]="config">
                        <table name="user-role-table" id="user-role-table" mat-table class="w-100"
                          [dataSource]="
                          RoleTableDataSource
                              | paginate
                                : {
                                    id: 'userRole',
                                    itemsPerPage: RoleTableSize,
                                    currentPage: RoleTableCurrentPage,
                                    totalItems: RoleTableDataSource.length
                                  }
                          ">
                          <ng-container matColumnDef="sr">
                            <th name="user-role-num-header" id="user-role-num-header" mat-header-cell
                              *matHeaderCellDef>
                              Sr.
                            </th>
                            <td name="user-role-num-data" id="user-role-num-data" mat-cell
                              *matCellDef="let element; let i = index">
                              {{
                              RoleTableSize * (RoleTableCurrentPage - 1) +
                              i +
                              1
                              }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="userName">
                            <th name="user-role-name-header" id="user-role-name-header" mat-header-cell
                              *matHeaderCellDef>
                              User Name
                            </th>
                            <td name="user-role-name-data" id="user-role-name-data" mat-cell
                              *matCellDef="let element">
                              {{ element.vFirstName }} {{ element.vLastName }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="vEmailId">
                            <th name="user-role-name-header" id="user-role-name-header" mat-header-cell
                              *matHeaderCellDef>
                            Email
                            </th>
                            <td name="user-role-name-data" id="user-role-name-data" mat-cell
                              *matCellDef="let element">
                              {{ element.vEmailId }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="dateOfInvitation">
                            <th name="user-role-name-header" id="user-role-name-header" mat-header-cell
                              *matHeaderCellDef>
                              Date Of Invitation
                            </th>
                            <td name="user-role-name-data" id="user-role-name-data" mat-cell
                              *matCellDef="let element">
                              {{ element.iCreatedAt *1000 | date : 'dd-MMM-yyyy' }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="vRoleName">
                            <th name="user-role-name-header" id="user-role-name-header" mat-header-cell
                              *matHeaderCellDef>
                              Role
                            </th>
                            <td name="user-role-name-data" id="user-role-name-data" mat-cell
                              *matCellDef="let element">
                              {{ element.vRoleName }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="tiSubUserStatus">
                            <th name="user-role-weight-header" id="user-role-weight-header"
                              mat-header-cell *matHeaderCellDef>
                              Status
                            </th>
                            <td name="user-role-weight-data" id="user-role-weight-data" mat-cell
                              *matCellDef="let element">
                              <span *ngIf="element.tiSubUserStatus == 1" class="status-pending">Pending</span>
                              <span *ngIf="element.tiSubUserStatus == 2" class="status-active">Active</span>
                              <span *ngIf="element.tiSubUserStatus == 3" class="status-reject">Inactive</span>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="action">
                            <th name="user-role-symbol-header" id="user-role-symbol-header"
                              mat-header-cell *matHeaderCellDef>
                              Action
                            </th>
                            <td name="user-role-symbol-data" id="user-role-symbol-data" mat-cell
                              *matCellDef="let element">
                              <div class="d-flex align-items-start gap-4">
                                <button name="user-edit" id="user-edit" class="action-btn me-3" placement="left auto" [ngbTooltip]="editSubUserActionPermission ? 'Edit User':'Not Authorized'" [ngClass]="editSubUserActionPermission ? '':'unauthorized-cursor'" (click)="EditUsersModal(editUsers,element, editSubUserActionPermission)">
                                  <img class="icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit user" />
                                </button>
                                <button name="user-resend" id="user-resend" *ngIf="element.tiSubUserStatus == 1" class="action-btn me-3" placement="left auto" [ngbTooltip]="resendSubUserInviteLinkActionPermission ? 'Resend Invite':'Not Authorized'" [ngClass]="resendSubUserInviteLinkActionPermission ? '':'unauthorized-cursor'" (click)="ResendLinkModal(userResendInviteLink,element, resendSubUserInviteLinkActionPermission)">
                                  <img class="icon" [src]="imagePath + 'replay-black.svg'" alt="resend invite"/>
                                </button>
                                <button name="user-resend" id="user-resend" *ngIf="element.tiSubUserStatus == 3" class="action-btn me-3" placement="left auto" [ngbTooltip]="activateSubUserActionPermission ? 'Activate':'Not Authorized'" [ngClass]="activateSubUserActionPermission ? '':'unauthorized-cursor'" (click)="ActivateUserModal(ActivateUser,element,activateSubUserActionPermission)">
                                  <mat-icon>thumb_up</mat-icon>
                                </button>
                                <button name="user-resend" id="user-resend" *ngIf="element.tiSubUserStatus == 2" class="action-btn me-3" placement="left auto" [ngbTooltip]="deactivateSubUserActionPermission ? 'Deactivate':'Not Authorized'" [ngClass]="deactivateSubUserActionPermission ? '':'unauthorized-cursor'" (click)="DeactivateUserModal(DeactivateUser,element,deactivateSubUserActionPermission)">
                                  <mat-icon>thumb_down</mat-icon>
                                </button>
                                <button name="user-delete" id="user-delete" class="action-btn me-3" placement="left auto" [ngbTooltip]="deleteSubUserActionPermission ? 'Delete User':'Not Authorized'" [ngClass]="deleteSubUserActionPermission ? '':'unauthorized-cursor'" (click)="DeleteUserModal(DeleteUser,element,deleteSubUserActionPermission)">
                                  <img class="icon" [src]="imagePath + 'trash-black.svg'" alt="delete user" />
                                </button>
                              </div>
                            </td>
                          </ng-container>
                          <tr name="user-role-header-row" id="user-role-header-row" mat-header-row
                            *matHeaderRowDef="userTableColumns"></tr>
                          <tr name="user-role-data-row" id="user-role-data-row" mat-row
                            *matRowDef="let row; columns: userTableColumns"></tr>
                        </table>
                      </perfect-scrollbar>
                      <div class="d-flex justify-content-between align-items-end flex-wrap flex-column-reverse flex-sm-row">
                        <mat-form-field name="mat-form-field-onboard-user-availability"
                          id="mat-form-field-onboard-user-availability" hideRequiredMarker
                          class="custom-dropdown custom-input form filter-dropdown-width pagination" floatLabel="never"
                          floatPlaceholder="never">
                          <mat-select name="mat-select-availability" id="mat-select-availability" disableRipple (selectionChange)="RoleTableItemSize($event)" [(ngModel)]="defaultRoleTableDropdown">
                            <mat-option disableRipple *ngFor="let list of RoleTableItemCount" [value]="list.id" selected>{{ list.vName }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <pagination-controls name="userRole" id="userRole" class="custom-pagination"
                          previousLabel="Prev" nextLabel="Next" (pageChange)="RoleTableCurrentPage = $event">
                        </pagination-controls>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #addUsers let-c="close" let-d="dismiss">
    <div name="add-users-modal-header" id="add-users-modal-header" class="modal-header">
      <h1 name="add-users-modal-title" id="add-users-modal-title" class="modal-title">
        Add User
      </h1>
    </div>
    <perfect-scrollbar>
      <div name="add-users-modal-body" id="add-users-modal-body" class="modal-body modal-scroll">
        <form name="addUsersForm" id="addUsersForm" class="form" [formGroup]="addUsersForm"
          (ngSubmit)="AddUser()">
          <div name="add-single-user-row" id="add-single-user-row" class="row">
            <div name="first-name-col" id="first-name-col" class="col-12 col-md-6">
              <label for="vFirstName" name="first-name-label" id="first-name-label" class="custom-label">First Name
                <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="add-users-col-2-input" id="add-users-col-2-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput name="vFirstName" id="vFirstName" formControlName="vFirstName"
                  placeholder="First Name" required />
                <mat-error name="add-users-col-2-error-0" id="add-users-col-2-error-0"
                  class="reduce-bottom-space" *ngIf="addUsersForm.controls['vFirstName'].invalid">
                  <mat-error name="add-users-col-2-error-1" id="add-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      addUsersForm.controls['vFirstName'].errors!.required
                    ">
                    First Name is required
                  </mat-error>
                  <mat-error name="add-users-col-2-error-1" id="add-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      addUsersForm.controls['vFirstName'].errors!
                        .minlength &&
                      !addUsersForm.controls['vFirstName'].errors!.pattern
                    ">
                    Must have minimum 2 characters
                  </mat-error>
                  <mat-error name="add-users-col-2-error-1" id="add-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      addUsersForm.controls['vFirstName'].errors!
                        .maxlength &&
                      !addUsersForm.controls['vFirstName'].errors!.pattern
                    ">
                    Must not exceed 46 characters
                  </mat-error>
                  <mat-error name="add-users-col-2-error-1" id="add-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      addUsersForm.controls['vFirstName'].errors!.pattern
                    ">
                    Only characters are allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="last-name-col" id="last-name-col" class="col-12 col-md-6">
              <label for="vLastName" name="last-name-label" id="last-name-label" class="custom-label">Last Name
                <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="add-users-col-2-input" id="add-users-col-2-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput name="vLastName" id="vLastName" formControlName="vLastName"
                  placeholder="Last Name" required />
                <mat-error name="add-users-col-2-error-0" id="add-users-col-2-error-0"
                  class="reduce-bottom-space" *ngIf="addUsersForm.controls['vLastName'].invalid">
                  <mat-error name="add-users-col-2-error-1" id="add-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      addUsersForm.controls['vLastName'].errors!.required
                    ">
                    Last Name is required
                  </mat-error>
                  <mat-error name="add-users-col-2-error-1" id="add-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      addUsersForm.controls['vLastName'].errors!
                        .minlength &&
                      !addUsersForm.controls['vLastName'].errors!.pattern
                    ">
                    Must have minimum 2 characters
                  </mat-error>
                  <mat-error name="add-users-col-2-error-1" id="add-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      addUsersForm.controls['vLastName'].errors!
                        .maxlength &&
                      !addUsersForm.controls['vLastName'].errors!.pattern
                    ">
                    Must not exceed 46 characters
                  </mat-error>
                  <mat-error name="add-users-col-2-error-1" id="add-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      addUsersForm.controls['vLastName'].errors!.pattern
                    ">
                    Only characters are allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="add-users-col-3" id="add-users-col-3" class="col-12 col-md-6">
              <label for="vEmailId" name="add-users-col-3-label" id="executive-details-col-3-label"
                class="custom-label">Email Address <span class="text-danger">*</span><img class="info-tooltip mx-2" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="top"
                ngbTooltip="It is not editable once requested" />
              </label>
              <mat-form-field hideRequiredMarker name="add-users-col-3-input" id="executive-details-col-3-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput name="vEmailId" id="vEmailId" formControlName="vEmailId"
                  placeholder="user@mail.com" required />
                <mat-error name="add-users-col-3-error-0" id="add-users-col-3-error-0"
                  class="reduce-bottom-space" *ngIf="addUsersForm.controls['vEmailId'].invalid">
                  <mat-error name="add-users-col-3-error-1" id="add-users-col-3-error-1"
                    class="reduce-bottom-space" *ngIf="
                      addUsersForm.controls['vEmailId'].errors!.required
                    ">
                    Email is required
                  </mat-error>
                  <mat-error name="add-users-col-3-error-1" id="add-users-col-3-error-1"
                    class="reduce-bottom-space" *ngIf="
                      addUsersForm.controls['vEmailId'].errors!.email ||
                      addUsersForm.controls['vEmailId'].errors!.pattern
                    ">
                    Enter valid email
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="add-users-col-3" id="add-users-col-3" class="col-12 col-md-6">
              <label for="iRoleId" name="add-users-col-3-label" id="executive-details-col-3-label"
                class="custom-label">Role <span class="text-danger">*</span></label>
                <mat-form-field name="mat-formRoleUserIdFilter-field-onboard-user-availability"
                id="mat-form-field-onboard-user-availability" hideRequiredMarker
                class="custom-dropdown custom-input form filter-dropdown-width mt-0" floatLabel="never"
                floatPlaceholder="never">
                <mat-select name="mat-select-availability" id="mat-select-availability" disableRipple
                  placeholder="Select Role" (selectionChange)="onRoleSelection($event)" formControlName="iRoleId">
                  <mat-option disableRipple *ngFor="let list of roleList" [value]="list.iRoleId"
                    placeholder="Select Role" selected>{{ list.vRoleName }}</mat-option>
                </mat-select>
                <mat-error name="add-users-col-3-error-0" id="add-users-col-3-error-0"
                  class="reduce-bottom-space" *ngIf="addUsersForm.controls['iRoleId'].invalid">
                <mat-error name="add-users-col-3-error-1" id="add-users-col-3-error-1"
                    class="reduce-bottom-space" *ngIf="addUsersForm.controls['iRoleId'].errors?.required
                    ">
                    Role is required
                  </mat-error>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="my-2" *ngIf="addUsersForm.controls['iRoleId'].value">
            <span class="my-1 fw-bold">Permissions for Selected Role : {{permissionList.vRoleName}}</span>
            <!-- <perfect-scrollbar style="height: 300px;padding:8px 16px;"> -->
            <ol class="list-group list-group-numbered custom-label" *ngFor="let main of permissionList.userFeatures">
              <span class="sub-title my-2 fw-bold">{{main.vParentFeature}}</span>
              <li class="list-group-item" *ngFor="let sub of main.child">
                <span>{{sub.vChildFeature}}</span>
              </li>
            </ol>
            <!-- </perfect-scrollbar> -->
          </div>
        </form>
      </div>
    </perfect-scrollbar>
    <div name="add-users-modal-footer" id="add-users-modal-footer"
            class="modal-footer">
      <button type="submit" name="add-users-modal-footer-btn-submit"
        id="add-users-modal-footer-btn-submit" class="btn btn-active" form="addUsersForm">
        Submit
      </button>
      <button type="button" name="add-users-modal-footer-btn-cancel"
        id="add-users-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="
          d('Cross click');
          this.addUsersForm.reset();
        ">
        Cancel
      </button>
    </div>
  </ng-template>

  <ng-template #editUsers let-c="close" let-d="dismiss">
    <div name="edit-users-modal-header" id="edit-users-modal-header" class="modal-header">
      <h1 name="edit-users-modal-title" id="edit-users-modal-title" class="modal-title">
        Edit User
      </h1>
    </div>
    <perfect-scrollbar>
      <div name="edit-users-modal-body" id="edit-users-modal-body" class="modal-body modal-scroll">
        <form name="editUsersForm" id="editUsersForm" class="form" [formGroup]="editUsersForm"
          (ngSubmit)="EditUser()">
          <div name="edit-single-user-row" id="edit-single-user-row" class="row">
            <div name="first-name-col" id="first-name-col" class="col-12 col-md-6">
              <label for="vFirstName" name="first-name-label" id="first-name-label" class="custom-label">First Name
                <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="edit-users-col-2-input" id="edit-users-col-2-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput name="vFirstName" id="vFirstName" formControlName="vFirstName"
                  placeholder="First Name" required />
                <mat-error name="edit-users-col-2-error-0" id="edit-users-col-2-error-0"
                  class="reduce-bottom-space" *ngIf="editUsersForm.controls['vFirstName'].invalid">
                  <mat-error name="edit-users-col-2-error-1" id="edit-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      editUsersForm.controls['vFirstName'].errors!.required
                    ">
                    First Name is required
                  </mat-error>
                  <mat-error name="edit-users-col-2-error-1" id="edit-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      editUsersForm.controls['vFirstName'].errors!
                        .minlength &&
                      !editUsersForm.controls['vFirstName'].errors!.pattern
                    ">
                    Must have minimum 2 characters
                  </mat-error>
                  <mat-error name="edit-users-col-2-error-1" id="edit-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      editUsersForm.controls['vFirstName'].errors!
                        .maxlength &&
                      !editUsersForm.controls['vFirstName'].errors!.pattern
                    ">
                    Must not exceed 46 characters
                  </mat-error>
                  <mat-error name="edit-users-col-2-error-1" id="edit-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      editUsersForm.controls['vFirstName'].errors!.pattern
                    ">
                    Only characters are allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="last-name-col" id="last-name-col" class="col-12 col-md-6">
              <label for="vLastName" name="last-name-label" id="last-name-label" class="custom-label">Last Name
                <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="edit-users-col-2-input" id="edit-users-col-2-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput name="vLastName" id="vLastName" formControlName="vLastName"
                  placeholder="Last Name" required />
                <mat-error name="edit-users-col-2-error-0" id="edit-users-col-2-error-0"
                  class="reduce-bottom-space" *ngIf="editUsersForm.controls['vLastName'].invalid">
                  <mat-error name="edit-users-col-2-error-1" id="edit-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      editUsersForm.controls['vLastName'].errors!.required
                    ">
                    Last Name is required
                  </mat-error>
                  <mat-error name="edit-users-col-2-error-1" id="edit-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      editUsersForm.controls['vLastName'].errors!
                        .minlength &&
                      !editUsersForm.controls['vLastName'].errors!.pattern
                    ">
                    Must have minimum 2 characters
                  </mat-error>
                  <mat-error name="edit-users-col-2-error-1" id="edit-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      editUsersForm.controls['vLastName'].errors!
                        .maxlength &&
                      !editUsersForm.controls['vLastName'].errors!.pattern
                    ">
                    Must not exceed 46 characters
                  </mat-error>
                  <mat-error name="edit-users-col-2-error-1" id="edit-users-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="
                      editUsersForm.controls['vLastName'].errors!.pattern
                    ">
                    Only characters are allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="edit-users-col-3" id="edit-users-col-3" class="col-12 col-md-6">
              <div class="view-profile-not-allowed">
                <label name="edit-users-col-3-label" id="executive-details-col-3-label"
                  class="custom-label">Email Address <span class="text-danger">*</span><img class="info-tooltip mx-2" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="top"
                  ngbTooltip="It is not editable once requested" /></label>
                <mat-form-field hideRequiredMarker name="edit-users-col-3-input" id="executive-details-col-3-input"
                  class="custom-input pe-none" floatLabel="never" floatPlaceholder="never">
                  <input type="text" matInput name="vEmailId" id="vEmailId" formControlName="vEmailId"
                    placeholder="user@mail.com" required readonly="true" />
                  <mat-error name="edit-users-col-3-error-0" id="edit-users-col-3-error-0"
                    class="reduce-bottom-space" *ngIf="editUsersForm.controls['vEmailId'].invalid">
                    <mat-error name="edit-users-col-3-error-1" id="edit-users-col-3-error-1"
                      class="reduce-bottom-space" *ngIf="
                        editUsersForm.controls['vEmailId'].errors!.required
                      ">
                      Email is required
                    </mat-error>
                    <mat-error name="edit-users-col-3-error-1" id="edit-users-col-3-error-1"
                      class="reduce-bottom-space" *ngIf="
                        editUsersForm.controls['vEmailId'].errors!.email ||
                        editUsersForm.controls['vEmailId'].errors!.pattern
                      ">
                      Enter valid email
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div name="edit-users-col-3" id="edit-users-col-3" class="col-12 col-md-6">
              <label for="iRoleId" name="edit-users-col-3-label" id="executive-details-col-3-label"
                class="custom-label">Role <span class="text-danger">*</span></label>
                <mat-form-field name="mat-formRoleUserIdFilter-field-onboard-user-availability"
                id="mat-form-field-onboard-user-availability" hideRequiredMarker
                class="custom-dropdown custom-input form filter-dropdown-width mt-0" floatLabel="never"
                floatPlaceholder="never">
                <mat-select name="mat-select-availability" id="mat-select-availability" disableRipple
                  placeholder="Select Role" (selectionChange)="onRoleSelectionInEdit($event)" formControlName="iRoleId">
                  <mat-option disableRipple *ngFor="let list of roleList" [value]="list.iRoleId"
                    placeholder="Select Role" selected>{{ list.vRoleName }}</mat-option>
                </mat-select>
                <mat-error name="edit-users-col-3-error-0" id="edit-users-col-3-error-0"
                  class="reduce-bottom-space" *ngIf="editUsersForm.controls['iRoleId'].invalid">
                <mat-error name="edit-users-col-3-error-1" id="edit-users-col-3-error-1"
                    class="reduce-bottom-space" *ngIf="editUsersForm.controls['iRoleId'].errors?.required
                    ">
                    Role is required
                  </mat-error>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="my-2" *ngIf="editUsersForm.controls['iRoleId'].value">
            <span class="my-1 fw-bold">Permissions for Selected Role : {{permissionList.vRoleName}}</span>
            <!-- <perfect-scrollbar style="height: 300px;padding:8px 16px;"> -->
            <ol class="list-group list-group-numbered custom-label" *ngFor="let main of permissionList.userFeatures">
              <span class="sub-title my-2 fw-bold">{{main.vParentFeature}}</span>
              <li class="list-group-item" *ngFor="let sub of main.child">
                <span>{{sub.vChildFeature}}</span>
              </li>
            </ol>
            <!-- </perfect-scrollbar> -->
          </div>
        </form>
      </div>
    </perfect-scrollbar>
    <div name="edit-users-modal-footer" id="edit-users-modal-footer"
            class="modal-footer">
      <button type="submit" name="edit-users-modal-footer-btn-submit"
        id="edit-users-modal-footer-btn-submit" class="btn btn-active" form="editUsersForm">
        Submit
      </button>
      <button type="button" name="edit-users-modal-footer-btn-cancel"
        id="edit-users-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="
          d('Cross click');
          this.editUsersForm.reset();
        ">
        Cancel
      </button>
    </div>
  </ng-template>

  <ng-template #userResendInviteLink let-c="close" let-d="dismiss">
    <div name="request-resend-modal-header" id="request-resend-modal-header" class="modal-header">
      <h1 name="request-resend-modal-title" id="request-resend-modal-title" class="modal-title">
        Invite Confirmation
      </h1>
    </div>
    <div name="request-resend-modal-body" id="request-resend-modal-body" class="modal-body">
      <h1 name="request-resend-modal-title" id="request-resend-modal-title" class="modal-title">
        Are you sure you want to resend invite link?
      </h1>
    </div>
    <div name="request-resend-modal-footer" id="request-resend-modal-footer" class="modal-footer">
      <button type="submit" name="request-resend-modal-footer-btn-submit" id="request-resend-modal-footer-btn-submit me-4"
        class="btn btn-active" (click)="ResendInvite()">
        Resend
      </button>
      <button type="button" name="request-resend-modal-footer-btn-cancel" id="request-resend-modal-footer-btn-cancel"
        class="btn btn-outline-active" (click)="d('cross click');this.resendUserEmail=undefined">
        Cancel
      </button>
    </div>
  </ng-template>

  <ng-template #ActivateUser let-c="close" let-d="dismiss">
    <div name="request-activate-modal-header" id="request-activate-modal-header" class="modal-header">
      <h1 name="request-activate-modal-title" id="request-activate-modal-title" class="modal-title">
        Activate User
      </h1>
    </div>
    <div name="request-activate-modal-body" id="request-activate-modal-body" class="modal-body">
      <h1 name="request-activate-modal-title" id="request-activate-modal-title" class="modal-title">
        Are you sure you want to activate user?
      </h1>
    </div>
    <div name="request-activate-modal-footer" id="request-activate-modal-footer" class="modal-footer">
      <button type="submit" name="request-activate-modal-footer-btn-submit" id="request-activate-modal-footer-btn-submit me-4"
        class="btn btn-active" (click)="ActivateSubUser()">
        Yes
      </button>
      <button type="button" name="request-activate-modal-footer-btn-cancel" id="request-activate-modal-footer-btn-cancel"
        class="btn btn-outline-active" (click)="d('cross click');this.statusChangeId=undefined;">
        No
      </button>
    </div>
  </ng-template>

  <ng-template #DeactivateUser let-c="close" let-d="dismiss">
    <div name="request-activate-modal-header" id="request-activate-modal-header" class="modal-header">
      <h1 name="request-activate-modal-title" id="request-activate-modal-title" class="modal-title">
        Deactivate User
      </h1>
    </div>
    <div name="request-activate-modal-body" id="request-activate-modal-body" class="modal-body">
      <h1 name="request-activate-modal-title" id="request-activate-modal-title" class="modal-title">
        Are you sure you want to deactivate user?
      </h1>
    </div>
    <div name="request-activate-modal-footer" id="request-activate-modal-footer" class="modal-footer">
      <button type="submit" name="request-activate-modal-footer-btn-submit" id="request-activate-modal-footer-btn-submit me-4"
        class="btn btn-active" (click)="DeactivateSubUser();">
        Yes
      </button>
      <button type="button" name="request-activate-modal-footer-btn-cancel" id="request-activate-modal-footer-btn-cancel"
        class="btn btn-outline-active" (click)="d('cross click');this.statusChangeId=undefined">
        No
      </button>
    </div>
  </ng-template>

  <ng-template #DeleteUser let-c="close" let-d="dismiss">
    <div name="request-delete-modal-header" id="request-delete-modal-header" class="modal-header">
      <h1 name="request-delete-modal-title" id="request-delete-modal-title" class="modal-title">
        Delete User
      </h1>
    </div>
    <div name="request-delete-modal-body" id="request-delete-modal-body" class="modal-body">
      <h1 name="request-delete-modal-title" id="request-delete-modal-title" class="modal-title">
        Are you sure you want to delete user?
      </h1>
    </div>
    <div name="request-delete-modal-footer" id="request-delete-modal-footer" class="modal-footer">
      <button type="submit" name="request-delete-modal-footer-btn-submit" id="request-delete-modal-footer-btn-submit me-4"
        class="btn btn-active" (click)="DeleteSubUser()">
        Yes
      </button>
      <button type="button" name="request-delete-modal-footer-btn-cancel" id="request-delete-modal-footer-btn-cancel"
        class="btn btn-outline-active" (click)="d('cross click');this.deleteUserId=undefined">
        No
      </button>
    </div>
  </ng-template>