import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor( private http: HttpClient ) { }

  GetSerive(){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v1/serviceList`;
        this.http
          .get(apiURL)
          .toPromise()
          .then((res: any) => {
            // Success
            resolve(res);
          })
          .catch((err: any) => {
            console.log(err);
            reject(err);
          });
      });
      return promise;
  }

  AddService(data: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v1/addService`;
        this.http
          .post(apiURL,data)
          .toPromise()
          .then((res: any) => {
            // Success
            resolve(res);
          })
          .catch((err: any) => {
            console.log(err);
            reject(err);
          });
      });
      return promise;
  }

  EditService(data: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v1/editService`;
        this.http
          .put(apiURL,data)
          .toPromise()
          .then((res: any) => {
            // Success
            resolve(res);
          })
          .catch((err: any) => {
            console.log(err);
            reject(err);
          });
      });
      return promise;
  }

  DeleteService(iMasterServiceId: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + '/admin/v1/deleteService/' + iMasterServiceId;
        this.http
          .delete(apiURL)
          .toPromise()
          .then((res: any) => {
            // Success
            resolve(res);
          })
          .catch((err: any) => {
            console.log(err);
            reject(err);
          });
      });
      return promise;
  }
}
