import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ViewPartnerService } from "../view-partner.service";
import { NotificationService } from "../../../../config/notification.service";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { StaticMsg } from "src/app/config/en";
import { environment } from "../../../../../environments/environment";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Permission } from "../../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../../utils/services/user-permission.service";

@Component({
  selector: "partner-company-details",
  templateUrl: "./company-details.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class PartnerCompanyDetailsComponent implements OnInit {
  @Input() partnerUserId:any;
  companyDetailsToggle:boolean=false;
  
  imagePath = environment.image_path;

  // toggle actions permission
  editPartnerCompanyDetailsActionPermission: boolean = false;

  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  companyDetailsForm!: FormGroup;
  companyDetailsSubmitted: any = false;

  sizeListArray: any = [
    { id: 1, name: "1 - 10" },
    { id: 2, name: "10 - 25" },
    { id: 3, name: "25 - 100" },
    { id: 4, name: "100 - 500" },
    { id: 5, name: "More than 500+" },
  ];

  industryListArray: any = [];
  serviceListArray: any = [];
  technologyListArray: any = [];
  companyDetailsArray: any = [];
  introDeck: any;
  video: any;
  mediaArray: any = [];
  imagesUploadError: any;
  count: any;

  @ViewChild("uploadFile") fileInput: any;
  fileAttr: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  technologies: any = [];
  allTechnologies: any = [];
  filterTechnologies: any;

  industries: any = [];
  allIndustries: any = [];
  filterIndustries: any;

  services: any = [];
  allServices: any = [];
  filterServices: any;

  constructor(config: NgbModalConfig, private modalService: NgbModal, private partnerService: ViewPartnerService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService, private userPermissionService: UserPermissionService) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const EditPartnerCompanyDetailsAction: Permission = Permission.EditPartnerCompanyDetailsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(EditPartnerCompanyDetailsAction)) {
      this.editPartnerCompanyDetailsActionPermission = true;
    }

    this.filterTechnologies = this.technologyListArray;
    this.filterIndustries = this.industryListArray;
    this.filterServices = this.serviceListArray;
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });
    this.GetIndustryList();
    this.GetServiceList();
    this.GetTechnologyList();
    this.GetCompanyDetails();

    this.companyDetailsForm = new FormGroup({
      vIntroDeck: new FormControl("", [Validators.required]),
      vVideo: new FormControl(""),
      vImages: new FormControl(""),
      vSize: new FormControl(""),
      vTechnology: new FormControl("", [Validators.required]),
      vIndustryDetails: new FormControl("", [Validators.required]),
      vServiceDetails: new FormControl("", [Validators.required]),
    });
  }

  public companyDetailsCardCompleted: any = false;
  public iProfilePublishCompleted: any = false;
  updateProfileStatus(data: any) {
    this.companyDetailsCardCompleted = data["companyDetails"];
    this.iProfilePublishCompleted = data["iProfilePublishCompleted"];
  }


  // MultiSelectTechnologyCompareFn(item1: any, item2: any): boolean {
  //   return item1 && item2 ? item1.iMasterTechnologyId === item2.iMasterTechnologyId : item1 === item2;
  // }

  // MultiSelectIndustryCompareFn(item1: any, item2: any): boolean {
  //   return item1 && item2 ? item1.iMasterIndustryId === item2.iMasterIndustryId : item1 === item2;
  // }

  // MultiSelectServiceCompareFn(item1: any, item2: any): boolean {
  //   return item1 && item2 ? item1.iMasterServiceId === item2.iMasterServiceId : item1 === item2;
  // }

  FileBrowseHandler(event: any, type: any) {
    if (type == 1) {
      if (this.companyDetailsArray?.imagesUrl.length < 5) {
        if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg") {
          let fileSize = event["target"]["files"][0]["size"] / 1024;
          if (fileSize > 4 * 1024) {
            this.notifyService.showError(StaticMsg.ImageLargerError, "");
            return;
          } else {
            if (this.mediaArray.length == 5 || event.target.files.length > 5) {
              this.notifyService.showError(StaticMsg.ImageOutOfRangeError, "");
              return;
            }
            if (this.mediaArray.length < 5) {
              for (let i = 0; i < event.target.files.length; i++) {
                if (this.count < 5) {
                  this.mediaArray.push(event.target.files[i]);
                  this.count++;
                } else {
                  this.notifyService.showError(StaticMsg.ImageLimitError, "");
                }
              }
            }
          }
        } else {
          this.notifyService.showError(StaticMsg.NotValidImageTypeError, "");
        }
      } else {
        this.notifyService.showError(StaticMsg.ImageLimitError, "");
      }
    }
    if (type == 2) {
      if (event.target.files[0].type == "video/mp4") {
        let fileSize = event["target"]["files"][0]["size"] / 1024;
        if (fileSize > 1024 * 50) {
          this.notifyService.showError(StaticMsg.VideoLargerThan50MbError, "");
          if (type == 2) {
            this.video = "";
            this.companyDetailsForm.controls.vVideo.patchValue("");
          }      
          return;
        } else {
          this.video = event.target.files[0];
        }
      } else {
        this.notifyService.showError(StaticMsg.NotValidVideoTypeError, "");
      }
    }
    if (type == 3) {
      if (event.target.files[0].type == "application/pdf") {
        let fileSize = event["target"]["files"][0]["size"] / 1024;
        if (fileSize > 10 * 1024) {
          this.notifyService.showError(StaticMsg.IntroDeckFileLargerError, "");
          if (type == 3) {
            this.introDeck = "";
            this.companyDetailsForm.controls.vIntroDeck.patchValue("");
          }      
          return;
        } else {
          this.introDeck = event.target.files[0];
        }
      } else {
        this.notifyService.showError(StaticMsg.NotPdfFileError, "");
      }
    }
  }

  CancelMedia(index: any, type: any) {
    if (type == 3) {
      this.introDeck = "";
      this.companyDetailsForm.controls.vIntroDeck.patchValue("");
    }

    if (type == 2) {
      this.video = "";
      this.companyDetailsForm.controls.vVideo.patchValue("");
    }

    if (type == 1) {
      this.mediaArray.splice(index, 1);
      if (this.mediaArray.length < 1) {
        this.companyDetailsForm.controls.vImages.patchValue("");
      }
      this.count = this.companyDetailsArray.imagesUrl.length;
    }
  }

  GetIndustryList() {
    this.partnerService.GetIndustryList().then((result: any) => {
      if (result.code == 200) {
        this.industryListArray = result["data"];
      }
    });
  }

  GetServiceList() {
    this.partnerService.GetServiceList().then((result: any) => {
      if (result.code == 200) {
        this.serviceListArray = result["data"];
      }
    });
  }

  GetTechnologyList() {
    this.partnerService.GetTechnologyList().then((result: any) => {
      if (result.code == 200) {
        this.technologyListArray = result["data"];
      }
    });
  }

  // OnIndustryRemoved(industry: string) {
  //   const industries = this.companyDetailsForm.value.vIndustryDetails as string[];
  //   this.removeFirst(industries, industry);
  //   this.companyDetailsForm.controls.vIndustryDetails.setValue(industries); // To trigger change detection
  // }

  // OnServiceRemoved(service: string) {
  //   const services = this.companyDetailsForm.value.vServiceDetails as string[];
  //   this.removeFirst(services, service);
  //   this.companyDetailsForm.controls.vServiceDetails.setValue(services); // To trigger change detection
  // }

  // OnTechnologyRemoved(technology: string) {
  //   const technologies = this.companyDetailsForm.value.vTechnology as string[];
  //   this.removeFirst(technologies, technology);
  //   this.companyDetailsForm.controls.vTechnology.setValue(technologies); // To trigger change detection
  // }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  OnTechnologyRemoved(technology: string) {
    const technologies = this.technologies;
    this.removeFirst(technologies, technology);
    this.technologies = technologies; // To trigger change detection
    this.technologyListArray.push(technology);
    if (this.technologies.length == 0) {
      this.companyDetailsForm.controls["vTechnology"].setValue("");
    }
  }

  AddTechnology(technology: any): void {
    if (technology) {
      let index = this.technologies.findIndex((x: any) => x.iMasterTechnologyId === technology.iMasterTechnologyId);
      if (index > -1) {
        return;
      } else {
        const iMasterTechnologyId = technology.iMasterTechnologyId;
        const state = technology.state;
        const vTechnologyName = technology.vTechnologyName;
        if (vTechnologyName) {
          this.technologies.push({ iMasterTechnologyId: iMasterTechnologyId, state: state, vTechnologyName: vTechnologyName.trim() });
        }
        this.companyDetailsForm.controls["vTechnology"].setValue(this.technologies);
      }
      this.filterTechnologies = this.technologyListArray;
    }
  }

  DisplayFnTechnology(technology: any): string {
    return technology && technology.vTechnologyName ? technology.vTechnologyName : "";
  }

  _filterTechnology(name: string): any {
    const filterValue = name?.toLowerCase();
    this.filterTechnologies = this.technologyListArray;
    this.filterTechnologies = this.filterTechnologies.filter((technology: { vTechnologyName: string }) => technology.vTechnologyName.toLowerCase().startsWith(filterValue));
  }

  OnIndustryRemoved(industry: string) {
    const industries = this.industries;
    this.removeFirst(industries, industry);
    this.industries = industries; // To trigger change detection
    this.industryListArray.push(industry);
    if (this.industries.length == 0) {
      this.companyDetailsForm.controls["vIndustryDetails"].setValue("");
    }
  }

  AddIndustry(industry: any): void {
    if (industry) {
      let index = this.industries.findIndex((x: any) => x.iMasterIndustryId === industry.iMasterIndustryId);
      if (index > -1) {
        return;
      } else {
        const iMasterIndustryId = industry.iMasterIndustryId;
        const state = industry.state;
        const vIndustryName = industry.vIndustryName;
        if (vIndustryName) {
          this.industries.push({ iMasterIndustryId: iMasterIndustryId, state: state, vIndustryName: vIndustryName.trim() });
        }
        this.companyDetailsForm.controls["vIndustryDetails"].setValue(this.industries);
      }
      this.filterIndustries = this.industryListArray;
    }
  }

  DisplayFnIndustry(industry: any): string {
    return industry && industry.vIndustryName ? industry.vIndustryName : "";
  }

  _filterIndustry(name: string): any {
    const filterValue = name?.toLowerCase();
    this.filterIndustries = this.industryListArray;
    this.filterIndustries = this.filterIndustries.filter((industry: { vIndustryName: string }) => industry.vIndustryName.toLowerCase().startsWith(filterValue));
  }

  OnServiceRemoved(service: string) {
    const services = this.services;
    this.removeFirst(services, service);
    this.services = services; // To trigger change detection
    this.serviceListArray.push(service);
    if (this.services.length == 0) {
      this.companyDetailsForm.controls["vServiceDetails"].setValue("");
    }
  }

  AddService(service: any): void {
    if (service) {
      let index = this.services.findIndex((x: any) => x.iMasterServiceId === service.iMasterServiceId);
      if (index > -1) {
        return;
      } else {
        const iMasterServiceId = service.iMasterServiceId;
        const state = service.state;
        const vServiceName = service.vServiceName;
        if (vServiceName) {
          this.services.push({ iMasterServiceId: iMasterServiceId, state: state, vServiceName: vServiceName.trim() });
        }
        this.companyDetailsForm.controls["vServiceDetails"].setValue(this.services);
      }
      this.filterServices = this.serviceListArray;
    }
  }

  DisplayFnService(service: any): string {
    return service && service.vServiceName ? service.vServiceName : "";
  }

  _filterService(name: string): any {
    const filterValue = name?.toLowerCase();
    this.filterServices = this.serviceListArray;
    this.filterServices = this.filterServices.filter((service: { vServiceName: string }) => service.vServiceName.toLowerCase().startsWith(filterValue));
  }

  GetCompanyDetails() {
    this.partnerService.GetCompanyDetails(this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        let industriesName: any = [];
        let servicesName: any = [];
        let technologiesName: any = [];
        result["data"]["industries"].forEach((element: any) => {
          industriesName.push(element.vIndustryName);
        });
        result["data"]["services"].forEach((element: any) => {
          servicesName.push(element.vServiceName);
        });
        if (result["data"]["technology"].length > 0) {
          result["data"]["technology"].forEach((element: any) => {
            technologiesName.push(element.vTechnologyName);
          });
        }
        result["data"]["industriesName"] = industriesName.toString().replace(/,/g, ", ");
        result["data"]["servicesName"] = servicesName.toString().replace(/,/g, ", ");
        result["data"]["technologiesName"] = technologiesName.length > 0 ? technologiesName.toString().replace(/,/g, ", ") : result["data"]["technology"];

        this.companyDetailsArray = result["data"];
        this.count = this.companyDetailsArray.imagesUrl.length;
        if (this.companyDetailsArray["intro_deck"] && this.companyDetailsArray["intro_deck_url"]) {
          this.companyDetailsForm.controls.vIntroDeck.clearValidators();
        }
        if (this.companyDetailsArray["video"] && this.companyDetailsArray["video_url"]) {
          this.companyDetailsForm.controls.vVideo.clearValidators();
        }
        // console.log(this.companyDetailsArray)
      }
    });
  }

  UpdateCompanyDetails() {
    this.companyDetailsSubmitted = true;
    if (this.companyDetailsForm.invalid) {
      return;
    }

    let industryIds: any = [];
    let serviceIds: any = [];
    let technologyIds: any = [];

    this.companyDetailsForm.value.vTechnology.forEach((element: any) => {
      technologyIds.push(element.iMasterTechnologyId);
    });

    this.companyDetailsForm.value.vIndustryDetails.forEach((element: any) => {
      industryIds.push(element.iMasterIndustryId);
    });

    this.companyDetailsForm.value.vServiceDetails.forEach((element: any) => {
      serviceIds.push(element.iMasterServiceId);
    });
    let reqData = {
      vIntroDeck: this.introDeck,
      vVideo: this.video,
      media: this.mediaArray[0],
      media1: this.mediaArray[1],
      media2: this.mediaArray[2],
      media3: this.mediaArray[3],
      media4: this.mediaArray[4],
      vSize: this.companyDetailsForm.value.vSize,
      vTechnology: technologyIds,
      vIndustryDetails: industryIds,
      vServiceDetails: serviceIds,
    };
    this.partnerService.UpdateCompanyDetails(reqData,this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.notifyService.showSuccess(result.message, "");
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.GetCompanyDetails();
        this.companyDetailsToggle=false;
      } else {
        this.companyDetailsToggle=false;
      }
    });
  }

  companyDetailImageThumbnail(url: any) {
    let reqObj = {
      url: url,
    };
    this.partnerService.DeleteCompanyDetailImages(reqObj,this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.notifyService.showSuccess(result.message, "");
        this.GetCompanyDetails();
      }
    });
  }

  companyDetailVideo(url: any) {
    let reqObj = {
      url: url,
    };
    this.partnerService.DeleteCompanyDetailVideo(reqObj,this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.notifyService.showSuccess(result.message, "");
        this.GetCompanyDetails();
      }
    });
  }


  toggleCompanyDetails(hasPermission:boolean){
    if (hasPermission) {
      this.companyDetailsForm.reset();
      this.companyDetailsForm.patchValue({
        vSize: this.companyDetailsArray["size"],
        vTechnology: this.companyDetailsArray["technology"],
        vIndustryDetails: this.companyDetailsArray["industries"],
        vServiceDetails: this.companyDetailsArray["services"],
      });
      this.introDeck = "";
      this.video = "";
      this.mediaArray = [];
      this.count = this.companyDetailsArray.imagesUrl?.length;
      this.technologies = this.companyDetailsArray["technology"];
      this.industries = this.companyDetailsArray["industries"];
      this.services = this.companyDetailsArray["services"];
      this.companyDetailsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }
}
