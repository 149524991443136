<div class="page-content admin-section">

    <h1 class="admin-title">Requirement Details Page</h1>

    <div class="border-top-container">
        <div>
            <p class="top-text">Requirement Code</p>
            <p class="sub-text">{{getRequirementDetailsHeaderDataByID.vDisplayUniqueIdofRequirement}}</p>
        </div>
        <div>
            <p class="top-text">Client Name</p>
            <p class="sub-text">{{getRequirementDetailsHeaderDataByID.vClientName}}</p>
        </div>
        <div>
            <p class="top-text">Req. Created Date</p>
            <p class="sub-text">{{getRequirementDetailsHeaderDataByID.ireqCreatedDate * 1000 | date: 'dd/MM/yyyy'}}</p>
        </div>
        <div>
            <p class="top-text">Req. Status</p>
            <p class="sub-text">{{getRequirementDetailsHeaderDataByID.talentCount}}</p>
        </div>
        <div>
            <p class="top-text">Added in Team</p>
            <p class="sub-text">{{getRequirementDetailsHeaderDataByID.addedTeamCount}}</p>
        </div>
    </div>

    <section class="main-section" *ngFor="let items of getRequirementDetailsSectionByID">
        <div class="base-card" >
            <div class="card-header">
                <div>
                    <p class="card-top-text">Talent</p>
                    <p class="card-sub-text">{{items.vPrimarySkill}}</p>
                </div>
                <div *ngFor="let skills of items?.SkillArray">
                    <p class="card-top-text">Skills</p>
                    <p class="card-sub-text">{{skills.RequirementSkills}}</p>
                </div>
                <div>
                    <p class="card-top-text">Level</p>
                    <p class="card-sub-text">
                        <span class="text-highlight" *ngIf="items.iExperienceLevel == 0">
                            Junior (0-2 Years)
                        </span>
                        <span class="text-highlight" *ngIf="items.iExperienceLevel == 1">
                            Senior (3-8 Years)
                        </span>
                        <span class="text-highlight" *ngIf="items.iExperienceLevel == 2">
                            Expert (8+ Years)
                        </span>
                    </p>
                </div>
                <div>
                    <p class="card-top-text">Resources</p>
                    <p class="card-sub-text">{{items.iTalentNumber}}</p>
                </div>
            </div>
            <div class="row card-gap">
                <div class="col-12 col-sm-6 col-lg-4" *ngFor="let employeeDetail of items?.RequirementTeam">
                    <div class="card card-border">
                        <div class="image-box">
                            <img class="card-image" src="{{employeeDetail.vEmployeeProfilePhoto}}" alt="Profile Image">
                            <div class="text-box">
                                <div class="d-flex justify-content-between">
                                    <label class="title">{{employeeDetail.vFirstName}}</label>
                                    <label class="side-title"><span class="text-bold">${{employeeDetail.dEmployeePrice}}</span> / Mo</label>
                                </div>
                                <label class="side-title">{{employeeDetail.iEmpDesignation}}
                                    <span class="text-highlight" *ngIf="employeeDetail?.fEmpExperience >= 1">
                                        ({{ (employeeDetail?.fEmpExperience).toString().split(".")[0] }}+)
                                    </span>
                                    <span class="text-highlight" *ngIf="employeeDetail?.fEmpExperience < 1">
                                    ({{ (employeeDetail?.fEmpExperience * 10) | number:'1.1-2' }}+)
                                    </span>
                                </label>
                                <label class="side-title">{{employeeDetail.vDisplayUniqueId}}</label>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="remove-btn" id="remove-btn">Partner: 
                                <span class="card-img-spacing">
                                    <img class="logo-width" src="{{employeeDetail.vPartnerProfilePhoto}}" alt="Partner Logo">
                                    {{employeeDetail.vPartnerName}}
                                </span>
                            </div>
                            <div class="status-badge remove-btn" *ngIf="employeeDetail.tiInterviewStatus == 8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.8" viewBox="0 0 16 12.8">
                                    <g id="Group_6429" data-name="Group 6429" transform="translate(-2 -4)">
                                        <path id="Path_14223" data-name="Path 14223"
                                            d="M14.8,9.184V5.6A1.6,1.6,0,0,0,13.2,4H3.6A1.6,1.6,0,0,0,2,5.6v9.6a1.6,1.6,0,0,0,1.6,1.6h9.6a1.6,1.6,0,0,0,1.6-1.6V11.616L18,14.8V6ZM8.4,7.2A1.6,1.6,0,1,1,6.8,8.8,1.6,1.6,0,0,1,8.4,7.2Zm3.2,6.4H5.2v-.456a1.609,1.609,0,0,1,.976-1.48,5.562,5.562,0,0,1,4.448,0,1.609,1.609,0,0,1,.976,1.48Z"
                                            fill="#ffbc42" />
                                    </g>
                                </svg>
                                <label>Interviewing</label>
                            </div>
                            <div class="status-badge remove-btn" *ngIf="employeeDetail.tiInterviewStatus == 7">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.4" height="16" viewBox="0 0 14.4 16">
                                    <path id="Path_14485" data-name="Path 14485"
                                        d="M15.8,2.6H12.456a2.39,2.39,0,0,0-4.512,0H4.6A1.6,1.6,0,0,0,3,4.2V15.4A1.6,1.6,0,0,0,4.6,17H15.8a1.6,1.6,0,0,0,1.6-1.6V4.2A1.6,1.6,0,0,0,15.8,2.6Zm-5.6,0a.8.8,0,1,1-.8.8A.8.8,0,0,1,10.2,2.6ZM8.6,13.8,5.4,10.6,6.528,9.472,8.6,11.536l5.272-5.272L15,7.4Z"
                                        transform="translate(-3 -1)" fill="#48acf0" />
                                </svg>
                                <label>Contracted</label>
                            </div>
                            <div class="status-badge remove-btn" *ngIf="employeeDetail.tiInterviewStatus == 1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="Path_14505" data-name="Path 14505"
                                        d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2ZM8.4,14l-4-4L5.528,8.872,8.4,11.736l6.072-6.072L15.6,6.8Z"
                                        transform="translate(-2 -2)" fill="#58b47c" />
                                </svg>
                                <label>Hired</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>