import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient) { }

  GetTickets(data: any) {
    let NewUrl = environment.baseUrl + `/admin/v1/ticket-list?pageIndex=${data.pageIndex}&perPage=${data.perPage}`;
    if(data.tiType !== undefined && data.tiType !== null ) {
      NewUrl += `&tiType=${data.tiType}`
    }
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(NewUrl)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  TicketApproval(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/resolve-ticket`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  InterviewSchedule(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/schedule-interview`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  interviewTicketList() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.baseUrl + '/admin/v1/interview-ticket')
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  contractTicketList() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.baseUrl + '/admin/v1/contract-ticket')
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  partnerTicketList() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.baseUrl + '/admin/v1/partner-ticket')
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  clientTicketList() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.baseUrl + '/admin/v1/client-ticket')
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  employeeTicketList() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.baseUrl + '/admin/v1/employee-ticket')
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  interviewApproveRejectAPICalled(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/resolve-interview-ticket`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
