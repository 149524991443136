import { Injectable } from "@angular/core";
import { Permission } from "./../enums/permission.enum";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import * as CryptoJS from 'crypto-js';
import { EventEmitterService } from '../../config/event-emitter.service';

const ENCRYPTION_KEY: string = "89701bb10aa7913";

@Injectable({
  providedIn: "root",
})
export class UserPermissionService {
  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService, public eventEmitterService: EventEmitterService,) {}

  // fill from API
  permissions: Permission[] = [
    // Default Permission
    Permission.AccessDashboard,

    // // Side Menu
    // Permission.AccessDashboard,
    // // Onboard
    // Permission.AccessOnboard,
    // // Publish
    // Permission.AccessPublish,

    // // User Management
    // Permission.AccessPartnerUserManagement,
    // Permission.AccessEmployeeUserManagement,
    // Permission.AccessClientUserManagement,
    // // General
    // Permission.AccessTitleAndSkill,
    // Permission.AccessTechnology,
    // Permission.AccessReasons,
    // Permission.AccessContact,
    // Permission.AccessIndustry,
    // Permission.AccessService,
    // Permission.AccessGrowth,
    // // Employee
    // Permission.AccessLanguage,
    // Permission.AccessHobbie,
    // Permission.AccessDegree,
    // Permission.AccessBranch,
    // Permission.AccessAward,
    // Permission.AccessCertificate,
    // // Deal Engine
    // Permission.AccessDealEngine,
    // // Billing
    // Permission.AccessBilling,
    // // Contracts
    // Permission.AccessContracts,
    // // Interviews
    // Permission.AccessInterviews,
    // // Requirements
    // Permission.AccessRequirements,
    // // Access Management
    // Permission.AccessRole,
    // Permission.AccessPermissionManagement,
    // Permission.AccessRoleManagement,

    // // Dashboard
    // Permission.ViewDashboard,

    // // Onboard
    // Permission.ViewPartnerOnboardAction,
    // Permission.ResendInviteLinkPartnerOnboardAction,
    // Permission.ApprovePartnerOnboardAction,
    // Permission.RejectPartnerOnboardAction,

    // Permission.ViewClientOnboardAction,
    // Permission.ResendInviteLinkClientOnboardAction,
    // Permission.ApproveClientOnboardAction,
    // Permission.RejectClientOnboardAction,

    // // Publish
    // Permission.ViewPartnerPublishAction,
    // Permission.ApprovePartnerPublishAction,
    // Permission.RejectPartnerPublishAction,

    // Permission.ViewEmployeePublishAction,
    // Permission.ApproveEmployeePublishAction,
    // Permission.RejectEmployeePublishAction,

    // Permission.ViewClientPublishAction,
    // Permission.ApproveClientPublishAction,
    // Permission.RejectClientPublishAction,


    // // ViewProfile
    // Permission.ViewPartnerProfile,
    // Permission.ViewClientProfile,
    // Permission.ViewTalentProfile,

    // // ClientProfile
    // Permission.PublishClientProfileAction,
    // Permission.EditClientPrimaryDetailsAction,
    // Permission.EditClientExecutiveDetailsAction,
    // Permission.EditClientCompanyDetailsAction,
    // Permission.EditClientCompanyDocumentsAction,
    // Permission.EditClientCertificationsAction,

    // // PartnerProfile
    // Permission.PublishPartnerProfileAction,
    // Permission.EditPartnerPrimaryDetailsAction,
    // Permission.EditPartnerAdditionalDetailsAction,
    // Permission.EditPartnerExecutiveDetailsAction,
    // Permission.EditPartnerCompanyDetailsAction,
    // Permission.EditPartnerCompanyDocumentsAction,
    // Permission.EditPartnerCertificationsAction,

    // // TalentProfile
    // Permission.PublishTalentProfileAction,
    // Permission.EditTalentPersonalDetailsAction,
    // Permission.EditTalentProfessionalDetailsAction,
    // Permission.EditTalentDocumentsAction,
    // Permission.AddTalentProjectPortfolioAction,
    // Permission.EditTalentProjectPortfolioAction,
    // Permission.AddTalentEducationAction,
    // Permission.EditTalentEducationAction,
    // Permission.AddTalentRewardsAction,
    // Permission.EditTalentRewardsAction,
    // Permission.AddTalentCertificationsAction,
    // Permission.EditTalentCertificationsAction,

    // // Partner
    // Permission.ActivePartnerUserAction,
    // Permission.InactivePartnerUserAction,

    // // Talent
    // Permission.ActiveEmployeeUserAction,
    // Permission.InactiveEmployeeUserAction,

    // // Client
    // Permission.ActiveClientUserAction,
    // Permission.InactiveClientUserAction,

    // // General
    // Permission.ViewTitleAndSkill,
    // Permission.AddPrimarySkillAction,
    // Permission.EditPrimarySkillAction,
    // Permission.DeletePrimarySkillAction,

    // Permission.AddSecondarySkillAction,
    // Permission.EditSecondarySkillAction,
    // Permission.DeleteSecondarySkillAction,

    // Permission.ViewTechnology,
    // Permission.AddTechnologyAction,
    // Permission.EditTechnologyAction,
    // Permission.DeleteTechnologyAction,

    // Permission.ViewReasons,
    // Permission.AddReasonsAction,
    // Permission.EditReasonsAction,
    // Permission.DeleteReasonsAction,

    // Permission.ViewContact,

    // Permission.ViewIndustry,
    // Permission.AddIndustryAction,
    // Permission.EditIndustryAction,
    // Permission.DeleteIndustryAction,

    // Permission.ViewService,
    // Permission.AddServiceAction,
    // Permission.EditServiceAction,
    // Permission.DeleteServiceAction,

    // // Talent Details
    // Permission.ViewLanguage,
    // Permission.AddLanguageAction,
    // Permission.EditLanguageAction,
    // Permission.DeleteLanguageAction,

    // Permission.ViewHobbie,
    // Permission.AddHobbieAction,
    // Permission.EditHobbieAction,
    // Permission.DeleteHobbieAction,

    // Permission.ViewDegree,
    // Permission.AddDegreeAction,
    // Permission.EditDegreeAction,
    // Permission.DeleteDegreeAction,

    // Permission.ViewBranch,
    // Permission.AddBranchAction,
    // Permission.EditBranchAction,
    // Permission.DeleteBranchAction,

    // Permission.ViewAward,
    // Permission.AddAwardAction,
    // Permission.EditAwardAction,
    // Permission.DeleteAwardAction,

    // Permission.ViewCertificate,
    // Permission.AddCertificateAction,
    // Permission.EditCertificateAction,
    // Permission.DeleteCertificateAction,

    // // Deal Engine
    // Permission.ViewDealEngine,
    // Permission.CreateNewDealAction,
    // Permission.EditDealAction,
    // Permission.CancelDealAction,
    // Permission.ViewParticipatorAction,
    // Permission.ActiveDealAction,
    // Permission.ViewDealHistoryAction,

    // // Billing
    // Permission.ViewBilling,
    // Permission.ViewBillingAction,

    // //Contract
    // Permission.ViewContracts,
    // Permission.ApproveContractAction,
    // Permission.RejectContractAction,
    // Permission.DownloadContractPDFAction,
    // Permission.SignClientContractAction,
    // Permission.SignPartnerContractAction,

    // // Interview
    // Permission.ViewInterviews,
    // Permission.AccessVideoConference,

    // // Requirement
    // Permission.ViewRequirements,
    // Permission.ViewRequirementAction,

    // // Role Management
    // Permission.ViewRole,
    // Permission.AddRoleAction,
    // Permission.EditRoleAction,
    // Permission.DeleteRoleAction,

    // Permission.ViewParentPermission,
    // Permission.AddParentPermissionAction,
    // Permission.EditParentPermissionAction,
    // Permission.DeleteParentPermissionAction,

    // Permission.ViewChildPermission,
    // Permission.AddChildPermissionAction,
    // Permission.EditChildPermissionAction,
    // Permission.DeleteChildPermissionAction,

    // Permission.ViewPermissionManagement,
    // Permission.EditRolePermissionAction,

    // Permission.ViewRoleManagement,
    // Permission.AddSubUserAction,
    // Permission.EditSubUserAction,
    // Permission.DeleteSubUserAction,
    // Permission.ResendSubUserInviteLinkAction,
    // Permission.ActivateSubUserAction,
    // Permission.DeactivateSubUserAction,

    // Permission.ViewGrowthParameters,
    // Permission.AddGrowthParameterAction,
    // Permission.EditGrowthParameterAction,
    // Permission.ActivateDeactivateGrowthParameterAction,
  ];

  public isAuthorizedFor(permission: Permission): boolean {
    return this.permissions.indexOf(permission) >= 0;
  }

  ResetPermissions() {
    this.permissions = [
      // Default Permission
      Permission.AccessDashboard,
    ];
  }

  GetEncryptionKey() {
    return ENCRYPTION_KEY;
  }

  GetCookieData() {
    if (this.cookieService.check("admin_user")) {
      let cookieData = this.cookieService.get("admin_user");
      const bytes = CryptoJS.AES.decrypt(cookieData, this.GetEncryptionKey());
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    }
  }

  AssignUserPermissions() {
    let userCookie = this.GetCookieData();
    let userType = localStorage.getItem("admin_user_type");
    let userEmail = localStorage.getItem("admin_user_email");
    let subUserRoleId = localStorage.getItem("sub_admin_role");
    let subUserType = localStorage.getItem("sub_admin_account_type");
    let subUserEmail = localStorage.getItem("sub_admin_email");

    if (subUserRoleId && subUserType && subUserEmail) {
      if (!userCookie || userCookie.loggedInUser != 2 || subUserRoleId != userCookie.roleId || subUserType != userCookie.userType || subUserEmail != userCookie.userEmail || userType != userCookie.userType || userEmail != userCookie.userEmail) {
        localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_type");
        localStorage.removeItem("admin_user_email");
        localStorage.removeItem("sub_admin_account_type");
        localStorage.removeItem("sub_admin_email");
        localStorage.removeItem("sub_admin_role");
        localStorage.removeItem("admin_zendesk_token");
        localStorage.removeItem("admin_device_id");
        this.cookieService.delete("admin_user");
        this.router.navigate(["login"]);
        return false;
      }

      let reqJSON = {
        roleId: subUserRoleId,
        userType: subUserType,
      };
      this.GetUserPermissions(reqJSON).then((result: any) => {
        if (result.code == 200) {
          // console.log(result.data.userFeatures,"userFeatures");
          result.data.userFeatures.forEach((parentItem: any) => {
            this.FillParentPermission(parentItem.vParentFeature);

            parentItem.child.forEach((childItem: any) => {
              this.FillChildPermission(childItem.vChildFeature);
            });
          });

          if (this.router.url == "/login") {
            this.router.navigate(["dashboard"]);
          }
        }
      });
      return true;
    } else {      
      if (!userCookie || userCookie.loggedInUser != 1 || userType != userCookie.userType || userEmail != userCookie.userEmail) {
        localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_type");
        localStorage.removeItem("admin_user_email");
        localStorage.removeItem("sub_admin_account_type");
        localStorage.removeItem("sub_admin_email");
        localStorage.removeItem("sub_admin_role");
        localStorage.removeItem("admin_zendesk_token");
        localStorage.removeItem("admin_device_id");
        this.cookieService.delete("admin_user");
        this.router.navigate(["login"]);
        return false;
      }

      this.permissions = [];

      // push all permissions for main user login
      this.permissions.push(
        // Side Menu
        Permission.AccessDashboard,
        // Onboard
        Permission.AccessOnboard,
        // Publish
        Permission.AccessPublish,
        // User Management
        Permission.AccessPartnerUserManagement,
        Permission.AccessEmployeeUserManagement,
        Permission.AccessClientUserManagement,
        // General
        Permission.AccessTitleAndSkill,
        Permission.AccessTechnology,
        Permission.AccessReasons,
        Permission.AccessContact,
        Permission.AccessIndustry,
        Permission.AccessService,
        Permission.AccessGrowth,
        // Employee
        Permission.AccessLanguage,
        Permission.AccessHobbie,
        Permission.AccessDegree,
        Permission.AccessBranch,
        Permission.AccessAward,
        Permission.AccessCertificate,
        // Deal Engine
        Permission.AccessDealEngine,
        // Billing
        Permission.AccessBilling,
        // Contracts
        Permission.AccessContracts,
        // Interviews
        Permission.AccessInterviews,
        // Requirements
        Permission.AccessRequirements,
        // Access Management
        Permission.AccessRole,
        Permission.AccessPermissionManagement,
        Permission.AccessRoleManagement,

        // Dashboard
        Permission.ViewDashboard,

        // Onboard
        Permission.ViewPartnerOnboardAction,
        Permission.ResendInviteLinkPartnerOnboardAction,
        Permission.ApprovePartnerOnboardAction,
        Permission.RejectPartnerOnboardAction,

        Permission.ViewClientOnboardAction,
        Permission.ResendInviteLinkClientOnboardAction,
        Permission.ApproveClientOnboardAction,
        Permission.RejectClientOnboardAction,


        // Publish
        Permission.ViewPartnerPublishAction,
        Permission.ApprovePartnerPublishAction,
        Permission.RejectPartnerPublishAction,

        Permission.ViewEmployeePublishAction,
        Permission.ApproveEmployeePublishAction,
        Permission.RejectEmployeePublishAction,

        Permission.ViewClientPublishAction,
        Permission.ApproveClientPublishAction,
        Permission.RejectClientPublishAction,

        // ViewProfile
        Permission.ViewPartnerProfileAction,
        Permission.ViewClientProfileAction,
        Permission.ViewTalentProfileAction,

        // ClientProfile
        Permission.PublishClientProfileAction,
        Permission.EditClientPrimaryDetailsAction,
        Permission.EditClientExecutiveDetailsAction,
        Permission.EditClientCompanyDetailsAction,
        Permission.EditClientCompanyDocumentsAction,
        Permission.EditClientCertificationsAction,

        // PartnerProfile
        Permission.PublishPartnerProfileAction,
        Permission.EditPartnerPrimaryDetailsAction,
        Permission.EditPartnerAdditionalDetailsAction,
        Permission.EditPartnerExecutiveDetailsAction,
        Permission.EditPartnerCompanyDetailsAction,
        Permission.EditPartnerCompanyDocumentsAction,
        Permission.EditPartnerCertificationsAction,

        // TalentProfile
        Permission.PublishTalentProfileAction,
        Permission.EditTalentPersonalDetailsAction,
        Permission.EditTalentProfessionalDetailsAction,
        Permission.EditTalentDocumentsAction,
        Permission.AddTalentProjectPortfolioAction,
        Permission.EditTalentProjectPortfolioAction,
        Permission.AddTalentEducationAction,
        Permission.EditTalentEducationAction,
        Permission.AddTalentRewardsAction,
        Permission.EditTalentRewardsAction,
        Permission.AddTalentCertificationsAction,
        Permission.EditTalentCertificationsAction,

        // Partner
        Permission.ActivePartnerUserAction,
        Permission.InactivePartnerUserAction,

        // Talent
        Permission.ActiveEmployeeUserAction,
        Permission.InactiveEmployeeUserAction,

        // Client
        Permission.ActiveClientUserAction,
        Permission.InactiveClientUserAction,


        // User Management
        Permission.ActivePartnerUserAction,
        Permission.InactivePartnerUserAction,

        Permission.ActiveEmployeeUserAction,
        Permission.InactiveEmployeeUserAction,

        Permission.ActiveClientUserAction,
        Permission.InactiveClientUserAction,

        // General
        Permission.ViewTitleAndSkill,
        Permission.AddPrimarySkillAction,
        Permission.EditPrimarySkillAction,
        Permission.DeletePrimarySkillAction,

        Permission.AddSecondarySkillAction,
        Permission.EditSecondarySkillAction,
        Permission.DeleteSecondarySkillAction,

        Permission.ViewTechnology,
        Permission.AddTechnologyAction,
        Permission.EditTechnologyAction,
        Permission.DeleteTechnologyAction,

        Permission.ViewReasons,
        Permission.AddReasonsAction,
        Permission.EditReasonsAction,
        Permission.DeleteReasonsAction,

        Permission.ViewContact,

        Permission.ViewIndustry,
        Permission.AddIndustryAction,
        Permission.EditIndustryAction,
        Permission.DeleteIndustryAction,

        Permission.ViewService,
        Permission.AddServiceAction,
        Permission.EditServiceAction,
        Permission.DeleteServiceAction,

        // Employee
        Permission.ViewLanguage,
        Permission.AddLanguageAction,
        Permission.EditLanguageAction,
        Permission.DeleteLanguageAction,

        Permission.ViewHobbie,
        Permission.AddHobbieAction,
        Permission.EditHobbieAction,
        Permission.DeleteHobbieAction,

        Permission.ViewDegree,
        Permission.AddDegreeAction,
        Permission.EditDegreeAction,
        Permission.DeleteDegreeAction,

        Permission.ViewBranch,
        Permission.AddBranchAction,
        Permission.EditBranchAction,
        Permission.DeleteBranchAction,

        Permission.ViewAward,
        Permission.AddAwardAction,
        Permission.EditAwardAction,
        Permission.DeleteAwardAction,

        Permission.ViewCertificate,
        Permission.AddCertificateAction,
        Permission.EditCertificateAction,
        Permission.DeleteCertificateAction,

        // Deal Engine
        Permission.ViewDealEngine,
        Permission.CreateNewDealAction,
        Permission.EditDealAction,
        Permission.CancelDealAction,
        Permission.ViewParticipatorAction,
        Permission.ActiveDealAction,
        Permission.ViewDealHistoryAction,

        // Billing
        Permission.ViewBilling,
        Permission.ViewBillingAction,

        //Contract
        Permission.ViewContracts,
        Permission.ApproveContractAction,
        Permission.RejectContractAction,
        Permission.DownloadContractPDFAction,
        Permission.SignClientContractAction,
        Permission.SignPartnerContractAction,

        // Interview
        Permission.ViewInterviews,
        Permission.AccessVideoConference,

        // Requirement
        Permission.ViewRequirements,
        Permission.ViewRequirementAction,

        // Role Management
        Permission.ViewRole,
        Permission.AddRoleAction,
        Permission.EditRoleAction,
        Permission.DeleteRoleAction,

        Permission.ViewParentPermission,
        Permission.AddParentPermissionAction,
        Permission.EditParentPermissionAction,
        Permission.DeleteParentPermissionAction,

        Permission.ViewChildPermission,
        Permission.AddChildPermissionAction,
        Permission.EditChildPermissionAction,
        Permission.DeleteChildPermissionAction,

        Permission.ViewPermissionManagement,
        Permission.EditRolePermissionAction,

        Permission.ViewRoleManagement,
        Permission.AddSubUserAction,
        Permission.EditSubUserAction,
        Permission.DeleteSubUserAction,
        Permission.ResendSubUserInviteLinkAction,
        Permission.ActivateSubUserAction,
        Permission.DeactivateSubUserAction,

        Permission.ViewGrowthParameters,
        Permission.AddGrowthParameterAction,
        Permission.EditGrowthParameterAction,
        Permission.ActivateDeactivateGrowthParameterAction,

      );
      return true;
    }
  }

  FillParentPermission(vParentFeature: any) {
    switch (vParentFeature) {
      case "Dashboard":
        if (this.permissions.includes(Permission.AccessDashboard)) {
          // dont push dashboard permission
        } else {
          // push dashboard permission
          this.permissions.push(Permission.AccessDashboard);
        }
        break;
      case "Partner Profile":
        this.permissions.push(Permission.ViewPartnerProfileAction);
        break;
      case "Client Profile":
        this.permissions.push(Permission.ViewClientProfileAction);
        break;
      case "Talent Profile":
        this.permissions.push(Permission.ViewTalentProfileAction);
        break;
      case "Onboard":
        this.permissions.push(Permission.AccessOnboard);
        break;
      case "Publish":
        this.permissions.push(Permission.AccessPublish);
        break;
      case "Partner User Management":
        this.permissions.push(Permission.AccessPartnerUserManagement);
        break;
      case "Employee User Management":
        this.permissions.push(Permission.AccessEmployeeUserManagement);
        break;
      case "Client User Management":
        this.permissions.push(Permission.AccessClientUserManagement);
        break;
      case "Title and Skill":
        this.permissions.push(Permission.AccessTitleAndSkill);
        break;
      case "Technology":
        this.permissions.push(Permission.AccessTechnology);
        break;
      case "Reasons":
        this.permissions.push(Permission.AccessReasons);
        break;
      case "Contact":
        this.permissions.push(Permission.AccessContact);
        break;
      case "Industry":
        this.permissions.push(Permission.AccessIndustry);
        break;
      case "Service":
        this.permissions.push(Permission.AccessService);
        break;
      case "Language":
        this.permissions.push(Permission.AccessLanguage);
        break;
      case "Hobbie":
        this.permissions.push(Permission.AccessHobbie);
        break;
      case "Degree":
        this.permissions.push(Permission.AccessDegree);
        break;
      case "Branch":
        this.permissions.push(Permission.AccessBranch);
        break;
      case "Award":
        this.permissions.push(Permission.AccessAward);
        break;
      case "Certificate":
        this.permissions.push(Permission.AccessCertificate);
        break;
      case "Deal Engine":
        this.permissions.push(Permission.AccessDealEngine);
        break;
      case "Billing":
        this.permissions.push(Permission.AccessBilling);
        break;
      case "Contracts":
        this.permissions.push(Permission.AccessContracts);
        break;
      case "Interviews":
        this.permissions.push(Permission.AccessInterviews);
        break;
      case "Requirements":
        this.permissions.push(Permission.AccessRequirements);
        break;
      case "Role":
        this.permissions.push(Permission.AccessRole);
        break;
      case "Permission Management":
        this.permissions.push(Permission.AccessPermissionManagement);
        break;
      case "Role Management":
        this.permissions.push(Permission.AccessRoleManagement);
        break;
      case "Growth":
        this.permissions.push(Permission.AccessGrowth);
        this.permissions.push(Permission.ViewGrowthParameters);
        break;
      default:
        this.permissions.push(Permission.AccessDashboard);
    }
  }

  FillChildPermission(vChildFeature: any) {
    switch (vChildFeature) {
      case "View Dashboard":
        this.permissions.push(Permission.ViewDashboard);
        break;
      case "View Partner Onboard":
        this.permissions.push(Permission.ViewPartnerOnboardAction);
        break;
      case "View Partner Profile":
        if (this.permissions.includes(Permission.ViewPartnerProfileAction)) {
          // dont push permission
        } else {
          // push permission
          this.permissions.push(Permission.ViewPartnerProfileAction);
        }
        break;
      case "Resend Invite Link Partner Onboard":
        this.permissions.push(Permission.ResendInviteLinkPartnerOnboardAction);
        break;
      case "Approve Partner Onboard":
        this.permissions.push(Permission.ApprovePartnerOnboardAction);
        break;
      case "Reject Partner Onboard":
        this.permissions.push(Permission.RejectPartnerOnboardAction);
        break;
      case "View Client Onboard":
        this.permissions.push(Permission.ViewClientOnboardAction);
        break;
      case "View Client Profile":
        if (this.permissions.includes(Permission.ViewClientProfileAction)) {
          // dont push permission
        } else {
          // push permission
          this.permissions.push(Permission.ViewClientProfileAction);
        }
        break;
      case "Resend Invite Link Client Onboard":
        this.permissions.push(Permission.ResendInviteLinkClientOnboardAction);
        break;
      case "Approve Client Onboard":
        this.permissions.push(Permission.ApproveClientOnboardAction);
        break;
      case "Reject Client Onboard":
        this.permissions.push(Permission.RejectClientOnboardAction);
        break;
      case "View Partner Publish":
        this.permissions.push(Permission.ViewPartnerPublishAction);
        break;
      case "Approve Partner Publish":
        this.permissions.push(Permission.ApprovePartnerPublishAction);
        break;
      case "Reject Partner Publish":
        this.permissions.push(Permission.RejectPartnerPublishAction);
        break;
      case "View Employee Publish":
        this.permissions.push(Permission.ViewEmployeePublishAction);
        break;
      case "View Employee Profile":
        if (this.permissions.includes(Permission.ViewTalentProfileAction)) {
          // dont push permission
        } else {
          // push permission
          this.permissions.push(Permission.ViewTalentProfileAction);
        }
        break;
      case "Approve Employee Publish":
        this.permissions.push(Permission.ApproveEmployeePublishAction);
        break;
      case "Reject Employee Publish":
        this.permissions.push(Permission.RejectEmployeePublishAction);
        break;
      case "View Client Publish":
        this.permissions.push(Permission.ViewClientPublishAction);
        break;
      case "Approve Client Publish":
        this.permissions.push(Permission.ApproveClientPublishAction);
        break;
      case "Reject Client Publish":
        this.permissions.push(Permission.RejectClientPublishAction);
        break;
      case "View Partner User Management":
        if (this.permissions.includes(Permission.AccessPartnerUserManagement)) {
          // dont push permission
        } else {
          // push permission
          this.permissions.push(Permission.AccessPartnerUserManagement);
        }
        break;
      case "Activate Partner User":
        this.permissions.push(Permission.ActivePartnerUserAction);
        break;
      case "Deactivate Partner User":
        this.permissions.push(Permission.InactivePartnerUserAction);
        break;
      case "View Employee User Management":
        if (this.permissions.includes(Permission.AccessEmployeeUserManagement)) {
          // dont push permission
        } else {
          // push permission
          this.permissions.push(Permission.AccessEmployeeUserManagement);
        }
        break;
      case "Activate Employee User":
        this.permissions.push(Permission.ActiveEmployeeUserAction);
        break;
      case "Inactivate Employee User":
        this.permissions.push(Permission.InactiveEmployeeUserAction);
        break;
      case "View Client User Management":
        if (this.permissions.includes(Permission.AccessClientUserManagement)) {
          // dont push permission
        } else {
          // push permission
          this.permissions.push(Permission.AccessClientUserManagement);
        }
        break;
      case "Activate Client User":
        this.permissions.push(Permission.ActiveClientUserAction);
        break;
      case "Deactivate Client User":
        this.permissions.push(Permission.InactiveClientUserAction);
        break;
      case "View Title and Skills":
        this.permissions.push(Permission.ViewTitleAndSkill);
        break;
      case "Add Primary Skill":
        this.permissions.push(Permission.AddPrimarySkillAction);
        break;
      case "Edit Primary Skill":
        this.permissions.push(Permission.EditPrimarySkillAction);
        break;
      case "Delete Primary Skill":
        this.permissions.push(Permission.DeletePrimarySkillAction);
        break;
      case "Add Secondary Skill":
        this.permissions.push(Permission.AddSecondarySkillAction);
        break;
      case "Edit Secondary Skill":
        this.permissions.push(Permission.EditSecondarySkillAction);
        break;
      case "Delete Secondary Skill":
        this.permissions.push(Permission.DeleteSecondarySkillAction);
        break;
      case "View Technology":
        this.permissions.push(Permission.ViewTechnology);
        break;
      case "Add Technology":
        this.permissions.push(Permission.AddTechnologyAction);
        break;
      case "Edit Technology":
        this.permissions.push(Permission.EditTechnologyAction);
        break;
      case "Delete Technology":
        this.permissions.push(Permission.DeleteTechnologyAction);
        break;
      case "View Reasons":
        this.permissions.push(Permission.ViewReasons);
        break;
      case "Add Reasons":
        this.permissions.push(Permission.AddReasonsAction);
        break;
      case "Edit Reasons":
        this.permissions.push(Permission.EditReasonsAction);
        break;
      case "Delete Reasons":
        this.permissions.push(Permission.DeleteReasonsAction);
        break;
      case "View Contact":
        this.permissions.push(Permission.ViewContact);
        break;
      case "View Industry":
        this.permissions.push(Permission.ViewIndustry);
        break;
      case "Add Industry":
        this.permissions.push(Permission.AddIndustryAction);
        break;
      case "Edit Industry":
        this.permissions.push(Permission.EditIndustryAction);
        break;
      case "Delete Industry":
        this.permissions.push(Permission.DeleteIndustryAction);
        break;
      case "View Service":
        this.permissions.push(Permission.ViewService);
        break;
      case "Add Service":
        this.permissions.push(Permission.AddServiceAction);
        break;
      case "Edit Service":
        this.permissions.push(Permission.EditServiceAction);
        break;
      case "Delete Service":
        this.permissions.push(Permission.DeleteServiceAction);
        break;
      case "View Language":
        this.permissions.push(Permission.ViewLanguage);
        break;
      case "Add Language":
        this.permissions.push(Permission.AddLanguageAction);
        break;
      case "Edit Language":
        this.permissions.push(Permission.EditLanguageAction);
        break;
      case "Delete Language":
        this.permissions.push(Permission.DeleteLanguageAction);
        break;
      case "View Hobbie":
        this.permissions.push(Permission.ViewHobbie);
        break;
      case "Add Hobbie":
        this.permissions.push(Permission.AddHobbieAction);
        break;
      case "Edit Hobbie":
        this.permissions.push(Permission.EditHobbieAction);
        break;
      case "Delete Hobbie":
        this.permissions.push(Permission.DeleteHobbieAction);
        break;
      case "View Degree":
        this.permissions.push(Permission.ViewDegree);
        break;
      case "Add Degree":
        this.permissions.push(Permission.AddDegreeAction);
        break;
      case "Edit Degree":
        this.permissions.push(Permission.EditDegreeAction);
        break;
      case "Delete Degree":
        this.permissions.push(Permission.DeleteDegreeAction);
        break;
      case "View Branch":
        this.permissions.push(Permission.ViewBranch);
        break;
      case "Add Branch":
        this.permissions.push(Permission.AddBranchAction);
        break;
      case "Edit Branch":
        this.permissions.push(Permission.EditBranchAction);
        break;
      case "Delete Branch":
        this.permissions.push(Permission.DeleteBranchAction);
        break;
      case "View Award":
        this.permissions.push(Permission.ViewAward);
        break;
      case "Add Award":
        this.permissions.push(Permission.AddAwardAction);
        break;
      case "Edit Award":
        this.permissions.push(Permission.EditAwardAction);
        break;
      case "Delete Award":
        this.permissions.push(Permission.DeleteAwardAction);
        break;
      case "View Certificate":
        this.permissions.push(Permission.ViewCertificate);
        break;
      case "Add Certificate":
        this.permissions.push(Permission.AddCertificateAction);
        break;
      case "Edit Certificate":
        this.permissions.push(Permission.EditCertificateAction);
        break;
      case "Delete Certificate":
        this.permissions.push(Permission.DeleteCertificateAction);
        break;
      case "View Deal Engine":
        this.permissions.push(Permission.ViewDealEngine);
        break;
      case "Create Deal":
        this.permissions.push(Permission.CreateNewDealAction);
        break;
      case "Edit Deal":
        this.permissions.push(Permission.EditDealAction);
        break;
      case "Cancel Deal":
        this.permissions.push(Permission.CancelDealAction);
        break;
      case "View Participator":
        this.permissions.push(Permission.ViewParticipatorAction);
        break;
      case "Activate Deal":
        this.permissions.push(Permission.ActiveDealAction);
        break;
      case "View Deal History":
        this.permissions.push(Permission.ViewDealHistoryAction);
        break;
      case "View Billing":
        this.permissions.push(Permission.ViewBilling);
        break;
      case "ViewBillingAction":
        this.permissions.push(Permission.ViewBillingAction);
        break;
      case "View Contracts":
        this.permissions.push(Permission.ViewContracts);
        break;
      case "Approve Contract":
        this.permissions.push(Permission.ApproveContractAction);
        break;
      case "Reject Contract":
        this.permissions.push(Permission.RejectContractAction);
        break;
      case "Download Contract":
        this.permissions.push(Permission.DownloadContractPDFAction);
        break;
      case "Sign Client Contract":
        this.permissions.push(Permission.SignClientContractAction);
        break;
      case "Sign Partner Contract":
        this.permissions.push(Permission.SignPartnerContractAction);
        break;
      case "View Interviews":
        this.permissions.push(Permission.ViewInterviews);
        break;
      case "Join Video Conference":
        this.permissions.push(Permission.AccessVideoConference);
        break;
      case "View Requirements":
        this.permissions.push(Permission.ViewRequirements);
        break;
      case "View Requirement Details":
        this.permissions.push(Permission.ViewRequirementAction);
        break;
      case "View Role":
        this.permissions.push(Permission.ViewRole);
        break;
      case "Add Role":
        this.permissions.push(Permission.AddRoleAction);
        break;
      case "Edit Role":
        this.permissions.push(Permission.EditRoleAction);
        break;
      case "Delete Role":
        this.permissions.push(Permission.DeleteRoleAction);
        break;
      case "View Permission Management":
        this.permissions.push(Permission.ViewPermissionManagement);
        break;
      case "Edit Role PermissionAction":
        this.permissions.push(Permission.EditRolePermissionAction);
        break;
      case "View Role Management":
        this.permissions.push(Permission.ViewRoleManagement);
        break;
      case "Add Sub User":
        this.permissions.push(Permission.AddSubUserAction);
        break;
      case "Edit Sub User":
        this.permissions.push(Permission.EditSubUserAction);
        break;
      case "Delete Sub User":
        this.permissions.push(Permission.DeleteSubUserAction);
        break;
      case "Resend Sub User Invite Link":
        this.permissions.push(Permission.ResendSubUserInviteLinkAction);
        break;
      case "Activate Sub User":
        this.permissions.push(Permission.ActivateSubUserAction);
        break;
      case "Deactivate Sub User":
        this.permissions.push(Permission.DeactivateSubUserAction);
        break;
      case "Deactivate Sub User":
        this.permissions.push(Permission.DeactivateSubUserAction);
        break;
      case "Publish Partner Profile":
      this.permissions.push(Permission.PublishPartnerProfileAction);
      break;
      case "Edit Partner Primary Details":
      this.permissions.push(Permission.EditPartnerPrimaryDetailsAction);
      break;
      case "Edit Partner Additional Details":
      this.permissions.push(Permission.EditPartnerAdditionalDetailsAction);
      break;
      case "Edit Partner Executive Details":
      this.permissions.push(Permission.EditPartnerExecutiveDetailsAction);
      break;
      case "Edit Partner Company Details":
      this.permissions.push(Permission.EditPartnerCompanyDetailsAction);
      break;
      case "Edit Partner Company Documents":
      this.permissions.push(Permission.EditPartnerCompanyDocumentsAction);
      break;
      case "Edit Partner Certifications":
      this.permissions.push(Permission.EditPartnerCertificationsAction);
      break;
      case "Publish Client Profile":
      this.permissions.push(Permission.PublishClientProfileAction);
      break;
      case "Edit Client Primary Details":
      this.permissions.push(Permission.EditClientPrimaryDetailsAction);
      break;
      case "Edit Client Executive Details":
      this.permissions.push(Permission.EditClientExecutiveDetailsAction);
      break;
      case "Edit Client Company Details":
      this.permissions.push(Permission.EditClientCompanyDetailsAction);
      break;
      case "Edit Client Company Documents":
      this.permissions.push(Permission.EditClientCompanyDocumentsAction);
      break;
      case "Edit Client Certifications":
      this.permissions.push(Permission.EditClientCertificationsAction);
      break;
      case "View Talent Profile":
        if (this.permissions.includes(Permission.ViewTalentProfileAction)) {
          // dont push permission
        } else {
          // push permission
          this.permissions.push(Permission.ViewTalentProfileAction);
        }
      break;
      case "Publish Talent Profile":
      this.permissions.push(Permission.PublishTalentProfileAction);
      break;
      case "Edit Talent Personal Details":
      this.permissions.push(Permission.EditTalentPersonalDetailsAction);
      break;
      case "Edit Talent Professional Details":
      this.permissions.push(Permission.EditTalentProfessionalDetailsAction);
      break;
      case "Edit Talent Documents":
      this.permissions.push(Permission.EditTalentDocumentsAction);
      break;
      case "Add Talent Project Portfolio":
      this.permissions.push(Permission.AddTalentProjectPortfolioAction);
      break;
      case "Edit Talent Project Portfolio":
      this.permissions.push(Permission.EditTalentProjectPortfolioAction);
      break;
      case "Add Talent Education":
      this.permissions.push(Permission.AddTalentEducationAction);
      break;
      case "Edit Talent Education":
      this.permissions.push(Permission.EditTalentEducationAction);
      break;
      case "Add Talent Rewards":
      this.permissions.push(Permission.AddTalentRewardsAction);
      break;
      case "Edit Talent Rewards":
      this.permissions.push(Permission.EditTalentRewardsAction);
      break;
      case "Add Talent Certifications":
      this.permissions.push(Permission.AddTalentCertificationsAction);
      break;
      case "Edit Talent Certifications":
      this.permissions.push(Permission.EditTalentCertificationsAction);
      break;
      case "View Growth Parameters":
        if (this.permissions.includes(Permission.ViewGrowthParameters)) {
          // dont push permission
        } else {
          // push permission
          this.permissions.push(Permission.ViewGrowthParameters);
        }
      break;
      case "Add Growth Parameter":
      this.permissions.push(Permission.AddGrowthParameterAction);
      break;
      case "Edit Growth Parameter":
      this.permissions.push(Permission.EditGrowthParameterAction);
      break;
      case "Activate/Deactivate Growth Parameter":
      this.permissions.push(Permission.ActivateDeactivateGrowthParameterAction);
      break;
    }
  }

  GetRoles(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-roles/${data.userType}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetUserPermissions(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-user-feature?iRoleId=${data.roleId}&tiUserFeatureType=${data.userType}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
