<div class="container-fluid skill-management">
    <div class="row">
      <div class="col-12">
        <div class="wrapper">
  
          <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="title" role="tabpanel" aria-labelledby="skill-management-tab">
  
              <div class="pagetitle-wrapper">
                <div
                  class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                  <div class="d-flex align-items-center">
                    <i class="pagetitle-icon"><span class="material-icons">extension</span></i>
                    <h2 class="pagetitle">Growth</h2>
                  </div>
                  <div placement="bottom auto" [ngbTooltip]="addGrowthParameterActionPermission ? 'Add Parameter':'Not Authorized'"
                    [ngClass]="addGrowthParameterActionPermission ? '':'unauthorized-cursor'">
                    <button class="status-btn lightpurple-bg purple-text text-center ml-0 ml-sm-3 mr-0 mt-3 mt-sm-0 px-3"
                      [ngClass]="addGrowthParameterActionPermission ? '':'unauthorized-action'"
                      (click)="OpenModal(addGrowthParameter, addGrowthParameterActionPermission)">
                      <mat-icon class="mr-2">library_add</mat-icon> Add
                    </button>
                  </div>
                </div>
                <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                  <button matSuffix mat-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <input matInput placeholder="" (keyup)="applyFilterOne($event)" />
                </mat-form-field>
                <div class="table-responsive">
                  <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                  </div>
                  <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }"
                    [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                    <ng-container matColumnDef="indexnumber">
                      <th mat-header-cell *matHeaderCellDef> No </th>
                      <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableOnePaginator.pageIndex == 0 ? i+1 : i+ 1 + this.TableOnePaginator.pageIndex *
                        this.TableOnePaginator.pageSize}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vPmsGrowthName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.vPmsGrowthName}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="tiPmsGrowthType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                      <td mat-cell *matCellDef="let element">
                        <mat-chip-list>
                          <mat-chip class="blue-chip" *ngIf="element.tiPmsGrowthType == 1">
                            CSAT
                          </mat-chip>
                          <mat-chip class="blue-chip" *ngIf="element.tiPmsGrowthType == 2">
                            NPS
                          </mat-chip>
                          <mat-chip class="blue-chip" *ngIf="element.tiPmsGrowthType == 3">
                            WSR
                          </mat-chip>
                        </mat-chip-list>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="iCreatedAt">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Time </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="tiStatus">
                      <th name="user-GrowthParameter-status-header" id="user-GrowthParameter-status-header" mat-header-cell *matHeaderCellDef>
                        Status
                      </th>
                      <td name="user-GrowthParameter-status" id="user-GrowthParameter-status" mat-cell *matCellDef="let element">
                        <span *ngIf="element.tiStatus == 1" class="status-active">Active</span>
                        <span *ngIf="element.tiStatus == 0" class="status-reject">Inactive</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="checked">
                      <th mat-header-cell *matHeaderCellDef>
                        Status Update
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <mat-checkbox (change)="EditStatusGrowthParameter($event, element,activateDeactivateGrowthParameterActionPermission)"  [ngbTooltip]="activateDeactivateGrowthParameterActionPermission ? '':'Not Authorized'" [checked]="element.checked" [ngClass]="activateDeactivateGrowthParameterActionPermission ? '':'unauthorized-cursor'" [disabled]="!activateDeactivateGrowthParameterActionPermission"></mat-checkbox>
                      </td>
                    </ng-container>
  
  
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> Actions </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex flex-wrap">
                          <!-- <button class="status-btn lightpink-bg pink-text text-center mb-2" placement="left auto"
                            [ngbTooltip]="activateDeactivateGrowthParameterActionPermission ? 'Delete':'Not Authorized'"
                            [ngClass]="activateDeactivateGrowthParameterActionPermission ? '':'unauthorized-cursor'"
                            (click)="OpenDeleteGrowthParameter(deleteGrowthParameter, element,activateDeactivateGrowthParameterActionPermission)"
                            *ngIf="element.tiStatus == 0">
                            <mat-icon>delete</mat-icon>
                          </button> -->
                          <button class="status-btn lightblue-bg blue-text text-center mb-2" placement="left auto"
                            [ngbTooltip]="editGrowthParameterActionPermission ? 'Edit':'Not Authorized'"
                            [ngClass]="editGrowthParameterActionPermission ? '':'unauthorized-cursor'"
                            (click)="OpenModelEditGrowthParameter(editGrowthParameter,element,editGrowthParameterActionPermission)">
                            <mat-icon>create</mat-icon>
                          </button>
                          <span *ngIf="element.tiStatus == 1" class="status-btn lightgray-bg gray-text">
                            <span class="material-icons me-1">hide_source</span>
                            No Action
                          </span>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                  </table>
                </div>
                <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
                  #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
                </mat-paginator>
              </div>
  
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #addGrowthParameter let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Add Growth Parameter</h1>
          <form class="form" [formGroup]="addGrowthParameterForm" (ngSubmit)="AddGrowthParameter()">
            <div class="row">
              <div class="col-12 mb-4">
                <label class="label">Parameter Name</label>
                <mat-form-field hideRequiredMarker appearance="outline">
                  <input type="text" formControlName="vPmsGrowthName" matInput placeholder="Enter Parameter">
                  <mat-error *ngIf="addGrowthParameterForm.controls['vPmsGrowthName'].invalid">
                    <mat-error *ngIf="addGrowthParameterForm.controls['vPmsGrowthName'].errors.required">Parameter Name is required</mat-error>
                    <mat-error *ngIf="addGrowthParameterForm.controls['vPmsGrowthName'].errors?.minlength"> Minimum 3 character required
                    </mat-error>
                    <mat-error *ngIf="addGrowthParameterForm.controls['vPmsGrowthName'].errors?.maxlength"> Maximum 100 character allowed
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 mb-4">
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Select Parameter Type" formControlName="tiPmsGrowthType">
                    <!-- <mat-option value="All">All</mat-option> -->
                    <mat-option *ngFor="let GrowthParameter of GrowthParameterTypeList" [value]="GrowthParameter.id">
                      {{ GrowthParameter.tiPmsGrowthType }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="addGrowthParameterForm.controls['tiPmsGrowthType'].invalid">
                    <mat-error *ngIf="addGrowthParameterForm.controls['tiPmsGrowthType'].errors.required">Parameter Type is
                      required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn">Add</button>
              <button type="button" class="modal-btn-outline"
                (click)="d('Cross click'); this.addGrowthParameterForm.reset(); this.submitted = false;">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #editGrowthParameter let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Edit Growth Parameter</h1>
          <form class="form" [formGroup]="editGrowthParameterForm" (ngSubmit)="EditGrowthParameter()">
            <div class="row">
              <div class="col-12 mb-4">
                <label class="label">Parameter Name</label>
                <mat-form-field hideRequiredMarker appearance="outline">
                  <input type="text" formControlName="vPmsGrowthName" matInput placeholder="Enter Parameter">
                  <mat-error *ngIf="addGrowthParameterForm.controls['vPmsGrowthName'].invalid">
                    <mat-error *ngIf="addGrowthParameterForm.controls['vPmsGrowthName'].errors.required">GrowthParameter Name is required</mat-error>
                    <mat-error *ngIf="addGrowthParameterForm.controls['vPmsGrowthName'].errors?.minlength"> Minimum 3 character required
                    </mat-error>
                    <mat-error *ngIf="addGrowthParameterForm.controls['vPmsGrowthName'].errors?.maxlength"> Maximum 100 character allowed
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 mb-4">
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Select Parameter Type" formControlName="tiPmsGrowthType">
                    <!-- <mat-option value="All">All</mat-option> -->
                    <mat-option *ngFor="let GrowthParameter of GrowthParameterTypeList" [value]="GrowthParameter.id">
                      {{ GrowthParameter.tiPmsGrowthType }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="addGrowthParameterForm.controls['tiPmsGrowthType'].invalid">
                    <mat-error *ngIf="addGrowthParameterForm.controls['tiPmsGrowthType'].errors.required">Parameter Type is
                      required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn">Edit</button>
              <button type="button" class="modal-btn-outline"
                (click)="d('Cross click'); this.editGrowthParameterForm.reset();">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #deleteGrowthParameter let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title d-flex justify-content-center">Are you sure?</h1>
          <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
            <button type="submit" class="modal-btn" (click)="DeleteGrowthParameter(iPmsGrowthId)">Yes</button>
            <button type="button" class="modal-btn-outline px-4" (click)="d('Cross click')">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>