import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewPartnerService } from './view-partner.service';
import { NotificationService } from "../../../config/notification.service";
import { EventEmitterService } from "../../../config/event-emitter.service";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StaticMsg } from "src/app/config/en";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { Permission } from "../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../utils/services/user-permission.service";


@Component({
  selector: 'app-view-partner',
  templateUrl: './view-partner.component.html',
  providers: [NgbModalConfig, NgbModal],
})
export class ViewPartnerComponent implements OnInit {
  public primaryDetailsCardCompleted: any = false;
  public companyDetailsCardCompleted: any = false;
  public executiveDetailsCardCompleted: any = false;
  public companyDocumentsCardCompleted: any = false;
  public certificationsCardCompleted: any = false;
  public additionalDetailsCardCompleted: any = false;

  imagePath = environment.image_path;

  partnerUserId:any;
  targetLink:any=1;

  goToLink(targetLink:any) {
    this.targetLink=targetLink;
    this.GetProfileStatus();
  }

  publishPartnerProfileActionPermission: boolean = false;

  constructor( private cdr: ChangeDetectorRef,public eventEmitterService: EventEmitterService, private notifyService: NotificationService,private activatedroute: ActivatedRoute,private router:Router,config: NgbModalConfig, private userPermissionService: UserPermissionService, private modalService: NgbModal, private service: ViewPartnerService) {

    // assign individual permissions
    const PublishPartnerProfileAction: Permission = Permission.PublishPartnerProfileAction;

    // check what user is authorized

    if (this.userPermissionService.isAuthorizedFor(PublishPartnerProfileAction)) {
      this.publishPartnerProfileActionPermission = true;
    }

    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {

    this.partnerUserId = this.activatedroute.snapshot.paramMap.get("partnerUserId");
    this.GetProfileStatus();
    this.eventEmitterService.GetProfileStatusAPISubMethod.subscribe(() => {
      this.GetProfileStatus();
    });

  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  public progressValue: any = 0;
  public iProfilePublishCompleted: any = 0;
  GetProfileStatus() {
    this.service.GetProfileStatus(this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result["data"]["iProfilePublishCompleted"];
        this.progressValue = result["data"]["progressValue"];
        this.primaryDetailsCardCompleted = result["data"]["primaryDetails"];
        this.companyDetailsCardCompleted = result["data"]["companyDetails"];
        this.executiveDetailsCardCompleted = result["data"]["executiveDetails"];
        this.companyDocumentsCardCompleted = result["data"]["companyDocuments"];
        this.certificationsCardCompleted = result["data"]["compnayCertifications"];
        this.additionalDetailsCardCompleted = result["data"]["additionalDetails"];
        this.eventEmitterService.GetProfileStatusCallMethod(result["data"]);
      }
    });
  }

  PublishProfile(hasPermission:boolean) {
    if(hasPermission){
    if (this.progressValue < 100) {
      this.notifyService.showError("Please Complete Your Profile 100%", "");
    } else {
      this.service.PublishProfile({},this.partnerUserId).then((result: any) => {
        if (result.code == 200) {
          this.GetProfileStatus();
          this.notifyService.showSuccess(result.message, "");
          this.eventEmitterService.MenuListCallMethod();
        } else {
          this.notifyService.showError(result.message, "");
        }
      });
    }
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }


}
