import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class ContractService {
  constructor(private http: HttpClient) {}

  GetContractsData() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/get-contracts`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  ContractAccept(data:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/admin/v1/contract-accept`;
        this.http
          .put(apiURL,data)
          .toPromise()
          .then((res) => {
            // Success
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    return promise;
  }

  ContractReject(data:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/admin/v1/contract-reject`;
        this.http
          .put(apiURL,data)
          .toPromise()
          .then((res) => {
            // Success
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    return promise;
  }

  UpdateClientSignedStatus(data: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v1/update-client-signed-status`;
        this.http
          .post(apiURL, data)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }

  UpdatePartnerSignedStatus(data: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v1/update-partner-signed-status`;
        this.http
          .post(apiURL, data)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }
}
