export enum Permission {
      // Side Menu
      AccessDashboard = "AccessDashboard",
      // Onboard
      AccessOnboard = "AccessOnboard",
      // Publish
      AccessPublish = "AccessPublish",

      // User Management
      AccessPartnerUserManagement = "AccessPartnerUserManagement",
      AccessEmployeeUserManagement = "AccessEmployeeUserManagement",
      AccessClientUserManagement = "AccessClientUserManagement",
      // General
      AccessTitleAndSkill = "AccessTitleAndSkill",
      AccessTechnology = "AccessTechnology",
      AccessReasons = "AccessReasons",
      AccessContact = "AccessContact",
      AccessIndustry = "AccessIndustry",
      AccessService = "AccessService",
      AccessGrowth = "AccessGrowth",
      // Employee
      AccessLanguage = "AccessLanguage",
      AccessHobbie = "AccessHobbie",
      AccessDegree = "AccessDegree",
      AccessBranch = "AccessBranch",
      AccessAward = "AccessAward",
      AccessCertificate = "AccessCertificate",
      // Deal Engine
      AccessDealEngine = "AccessDealEngine",
      // Billing
      AccessBilling = "AccessBilling",
      // Contracts
      AccessContracts = "AccessContracts",
      // Interviews
      AccessInterviews = "AccessInterviews",
      // Requirements
      AccessRequirements = "AccessRequirements",
      // Access Management
      AccessRole = "AccessRole",
      AccessPermissionManagement = "AccessPermissionManagement",
      AccessRoleManagement = "AccessRoleManagement",

      // Dashboard
      ViewDashboard = "ViewDashboard",


      // Onboard
      ViewPartnerOnboardAction = "ViewPartnerOnboardAction",
      ResendInviteLinkPartnerOnboardAction = "ResendInviteLinkPartnerOnboardAction",
      ApprovePartnerOnboardAction = "ApprovePartnerOnboardAction",
      RejectPartnerOnboardAction = "RejectPartnerOnboardAction",

      ViewClientOnboardAction = "ViewClientOnboardAction",
      ResendInviteLinkClientOnboardAction = "ResendInviteLinkClientOnboardAction",
      ApproveClientOnboardAction = "ApproveClientOnboardAction",
      RejectClientOnboardAction = "RejectClientOnboardAction",

      // Publish
      ViewPartnerPublishAction = "ViewPartnerPublishAction",
      ApprovePartnerPublishAction = "ApprovePartnerPublishAction",
      RejectPartnerPublishAction = "RejectPartnerPublishAction",

      ViewEmployeePublishAction = "ViewEmployeePublishAction",
      ApproveEmployeePublishAction = "ApproveEmployeePublishAction",
      RejectEmployeePublishAction = "RejectEmployeePublishAction",

      ViewClientPublishAction = "ViewClientPublishAction",
      ApproveClientPublishAction = "ApproveClientPublishAction",
      RejectClientPublishAction = "RejectClientPublishAction",


      // ViewProfile
      ViewPartnerProfileAction = "ViewPartnerProfileAction",
      ViewClientProfileAction = "ViewClientProfileAction",
      ViewTalentProfileAction = "ViewTalentProfileAction",

      // ClientProfile
      PublishClientProfileAction = "PublishClientProfileAction",
      EditClientPrimaryDetailsAction = "EditClientPrimaryDetailsAction",
      EditClientExecutiveDetailsAction = "EditClientExecutiveDetailsAction",
      EditClientCompanyDetailsAction = "EditClientCompanyDetailsAction",
      EditClientCompanyDocumentsAction = "EditClientCompanyDocumentsAction",
      EditClientCertificationsAction = "EditClientCertificationsAction",

      // PartnerProfile
      PublishPartnerProfileAction = "PublishPartnerProfileAction",
      EditPartnerPrimaryDetailsAction = "EditPartnerPrimaryDetailsAction",
      EditPartnerAdditionalDetailsAction = "EditPartnerAdditionalDetailsAction",
      EditPartnerExecutiveDetailsAction = "EditPartnerExecutiveDetailsAction",
      EditPartnerCompanyDetailsAction = "EditPartnerCompanyDetailsAction",
      EditPartnerCompanyDocumentsAction = "EditPartnerCompanyDocumentsAction",
      EditPartnerCertificationsAction = "EditPartnerCertificationsAction",

      // TalentProfile
      PublishTalentProfileAction = "PublishTalentProfileAction",
      EditTalentPersonalDetailsAction = "EditTalentPersonalDetailsAction",
      EditTalentProfessionalDetailsAction = "EditTalentProfessionalDetailsAction",
      EditTalentDocumentsAction = "EditTalentDocumentsAction",
      AddTalentProjectPortfolioAction = "AddTalentProjectPortfolioAction",
      EditTalentProjectPortfolioAction = "EditTalentProjectPortfolioAction",
      AddTalentEducationAction = "AddTalentEducationAction",
      EditTalentEducationAction = "EditTalentEducationAction",
      AddTalentRewardsAction = "AddTalentRewardsAction",
      EditTalentRewardsAction = "EditTalentRewardsAction",
      AddTalentCertificationsAction = "AddTalentCertificationsAction",
      EditTalentCertificationsAction = "EditTalentCertificationsAction",

      // User Management
      ActivePartnerUserAction = "ActivePartnerUserAction",
      InactivePartnerUserAction = "InactivePartnerUserAction",

      ActiveEmployeeUserAction = "ActiveEmployeeUserAction",
      InactiveEmployeeUserAction = "InactiveEmployeeUserAction",

      ActiveClientUserAction = "ActiveClientUserAction",
      InactiveClientUserAction = "InactiveClientUserAction",

      // General
      ViewTitleAndSkill = "ViewTitleAndSkill",
      AddPrimarySkillAction = "AddPrimarySkillAction",
      EditPrimarySkillAction = "EditPrimarySkillAction",
      DeletePrimarySkillAction = "DeletePrimarySkillAction",

      AddSecondarySkillAction = "AddSecondarySkillAction",
      EditSecondarySkillAction = "EditSecondarySkillAction",
      DeleteSecondarySkillAction = "DeleteSecondarySkillAction",

      ViewTechnology = "ViewTechnology",
      AddTechnologyAction = "AddTechnologyAction",
      EditTechnologyAction = "EditTechnologyAction",
      DeleteTechnologyAction = "DeleteTechnologyAction",

      ViewReasons = "ViewReasons",
      AddReasonsAction = "AddReasonsAction",
      EditReasonsAction = "EditReasonsAction",
      DeleteReasonsAction = "DeleteReasonsAction",

      ViewContact = "ViewContact",

      ViewIndustry = "ViewIndustry",
      AddIndustryAction = "AddIndustryAction",
      EditIndustryAction = "EditIndustryAction",
      DeleteIndustryAction = "DeleteIndustryAction",

      ViewService = "ViewService",
      AddServiceAction = "AddServiceAction",
      EditServiceAction = "EditServiceAction",
      DeleteServiceAction = "DeleteServiceAction",

      // Employee
      ViewLanguage = "ViewLanguage",
      AddLanguageAction = "AddLanguageAction",
      EditLanguageAction = "EditLanguageAction",
      DeleteLanguageAction = "DeleteLanguageAction",

      ViewHobbie = "ViewHobbie",
      AddHobbieAction = "AddHobbieAction",
      EditHobbieAction = "EditHobbieAction",
      DeleteHobbieAction = "DeleteHobbieAction",

      ViewDegree = "ViewDegree",
      AddDegreeAction = "AddDegreeAction",
      EditDegreeAction = "EditDegreeAction",
      DeleteDegreeAction = "DeleteDegreeAction",

      ViewBranch = "ViewBranch",
      AddBranchAction = "AddBranchAction",
      EditBranchAction = "EditBranchAction",
      DeleteBranchAction = "DeleteBranchAction",

      ViewAward = "ViewAward",
      AddAwardAction = "AddAwardAction",
      EditAwardAction = "EditAwardAction",
      DeleteAwardAction = "DeleteAwardAction",

      ViewCertificate = "ViewCertificate",
      AddCertificateAction = "AddCertificateAction",
      EditCertificateAction = "EditCertificateAction",
      DeleteCertificateAction = "DeleteCertificateAction",

      // Deal Engine
      ViewDealEngine = "ViewDealEngine",
      CreateNewDealAction = "CreateNewDealAction",
      EditDealAction = "EditDealAction",
      CancelDealAction = "CancelDealAction",
      ViewParticipatorAction = "ViewParticipatorAction",
      ActiveDealAction = "ActiveDealAction",
      ViewDealHistoryAction = "ViewDealHistoryAction",

      // Billing
      ViewBilling = "ViewBilling",
      ViewBillingAction = "ViewBillingAction",

      //Contract
      ViewContracts = "ViewContracts",
      ApproveContractAction = "ApproveContractAction",
      RejectContractAction = "RejectContractAction",
      DownloadContractPDFAction = "DownloadContractPDFAction",
      SignClientContractAction = "SignClientContractAction",
      SignPartnerContractAction = "SignPartnerContractAction",

      // Interview
      ViewInterviews = "ViewInterviews",
      AccessVideoConference = "AccessVideoConference",

      // Requirement
      ViewRequirements = "ViewRequirements",
      ViewRequirementAction = "ViewRequirementAction",

      // Role Management
      ViewRole = "ViewRole",
      AddRoleAction = "AddRoleAction",
      EditRoleAction = "EditRoleAction",
      DeleteRoleAction = "DeleteRoleAction",

      ViewParentPermission = "ViewParentPermission",
      AddParentPermissionAction = "AddParentPermissionAction",
      EditParentPermissionAction = "EditParentPermissionAction",
      DeleteParentPermissionAction = "DeleteParentPermissionAction",

      ViewChildPermission = "ViewChildPermission",
      AddChildPermissionAction = "AddChildPermissionAction",
      EditChildPermissionAction = "EditChildPermissionAction",
      DeleteChildPermissionAction = "DeleteChildPermissionAction",

      ViewPermissionManagement = "ViewPermissionManagement",
      EditRolePermissionAction = "EditRolePermissionAction",

      ViewRoleManagement = "ViewRoleManagement",
      AddSubUserAction = "AddSubUserAction",
      EditSubUserAction = "EditSubUserAction",
      DeleteSubUserAction = "DeleteSubUserAction",
      ResendSubUserInviteLinkAction = "ResendSubUserInviteLinkAction",
      ActivateSubUserAction = "ActivateSubUserAction",
      DeactivateSubUserAction = "DeactivateSubUserAction",

      //Growth
      ViewGrowthParameters = "ViewGrowthParameters",
      AddGrowthParameterAction = "AddGrowthParameterAction",
      EditGrowthParameterAction = "EditGrowthParameterAction",
      ActivateDeactivateGrowthParameterAction = "ActivateDeactivateGrowthParameterAction",

}
