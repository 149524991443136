import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { CertificationsService } from "./certifications.service";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { NotificationService } from "src/app/config/notification.service";
import { StaticMsg } from "../../../../config/en";
import { environment } from "../../../../../environments/environment";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Permission } from "src/app/utils/enums/permission.enum";
import { UserPermissionService } from "src/app/utils/services/user-permission.service";

@Component({
  selector: "talent-certifications",
  templateUrl: "./certifications.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class TalentCertificationsComponent implements OnInit {
  @Input() talentUserId:any;
  certificationsToggle:boolean=false;

  // toggle actions permission
  addTalentCertificationsActionPermission: boolean = false;
  editTalentCertificationsActionPermission: boolean = false;
 
  
  imagePath = environment.image_path;
  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  certificationForm!: FormGroup;
  certficationSubmitted: any = false;
  certificationEditForm!: FormGroup;
  certficationEditSubmitted: any = false;
  certificateList: any = [];
  uploadedCertificateList: any = [];
  specificCertificateList: any = [];
  certificateName: any;
  iMasterCerId: any;
  vCerDocument: any;
  public certificateDetailsCardCompleted: any = false;
  public disableCertification: any = false;
  certificationTypeListArray: any = ["ABC", "Other"];
  checkCertificate = new FormControl(false);
  checkCertiApplicable: any;


  filteredCerti!: Observable<any[]>;

  constructor(private userPermissionService: UserPermissionService,config: NgbModalConfig, private modalService: NgbModal, private certificationsService: CertificationsService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const AddTalentCertificationsAction: Permission = Permission.AddTalentCertificationsAction;
    const EditTalentCertificationsAction: Permission = Permission.EditTalentCertificationsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddTalentCertificationsAction)) {
      this.addTalentCertificationsActionPermission = true;
    }

    if (this.userPermissionService.isAuthorizedFor(EditTalentCertificationsAction)) {
      this.editTalentCertificationsActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });
    this.GetUploadedCertificate();
    this.GetCheckApplicable();
    this.certificationForm = new FormGroup({
      vCerId: new FormControl("", [Validators.required]),
      vCerDocument: new FormControl("", [Validators.required]),
      vOther: new FormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(50), Validators.pattern("^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]),
    });

    this.certificationEditForm = new FormGroup({
      vType: new FormControl(""),
      vCerDocument: new FormControl("", [Validators.required]),
      vOther: new FormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(50), Validators.pattern("^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]),
    });

    this.filteredCerti = this.certificationForm.controls["vCerId"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value, this.certificateList, "vName") : this.certificateList.slice()))
    );
  }

  private _filter(value: string, array: any, key: any): string[] {
    let filterValue: string;
    if (value) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = "";
    }

    return array.filter((option: any) => option[key].toLowerCase().indexOf(filterValue) === 0);
  }

  GetCertiId(iCertiId: number) {
    this.iMasterCerId = iCertiId;
  }

  updateProfileStatus(data: any) {
    this.certificateDetailsCardCompleted = data["certificateDetailsCardCompleted"];
  }

  CertificationModal(certification: any,hasPermission:boolean) {
    if(hasPermission){
    this.modalService.open(certification, { centered: true, windowClass: "profile-modal" });
    this.certificationForm.reset();
    this.vCerDocument='';
    this.GetCertificate();
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  CertificationEditModal(certification: any, element: any,hasPermission:boolean) {
    if(hasPermission){
    this.modalService.open(certification, { centered: true, windowClass: "profile-modal" });
    this.GetTypeofCertificate(element.iMasterCerId);
    this.iMasterCerId = element.iMasterCerId;
    this.certificateName = element.vName;
    if (element.vName == "Other") {
      this.certificationEditForm.controls.vOther.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(50), Validators.pattern("^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]);
    } else {
      this.certificationEditForm.controls.vOther.clearValidators();
      this.certificationEditForm.controls.vOther.setErrors(null);
    }
  } else {
    this.notifyService.showError("Not Authorized!", "");
  }
  }

  GetCertificate() {
    this.certificationsService.GetCertificate().then((result: any) => {
      if (result.code == 200) {
        this.certificateList = result["data"];
      }
    });
  }

  FileBrowseHandler(event: any) {
    let fileSize = event["target"]["files"][0]["size"] / 1024;
    if (fileSize > 4 * 1024) {
      this.notifyService.showError(StaticMsg.FileLargerError, "");
      this.certificationForm.controls["vCerDocument"].setValue('');
    } else {
      if (event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/vnd.ms-excel" || event.target.files[0].type == "image/x-png" || event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg") {
        this.vCerDocument = event.target.files[0];
      } else {
        this.notifyService.showError(StaticMsg.NotPdfImageFileError, "");
      }
    }
  }

  CertificateChange(event: any) {
    if (event.source.value == "Other") {
      this.certificationForm.controls.vOther.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(50), Validators.pattern("^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]);
    } else {
      this.certificationForm.controls.vOther.clearValidators();
      this.certificationForm.controls.vOther.setErrors(null);
    }
  }

  GetUploadedCertificate() {
    this.certificationsService.GetUploadedCertificate(this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.uploadedCertificateList = result["data"];
      }
    });
  }

  DeleteUploadedCertificate() {
    this.certificationsService.DeleteUploadedCertificate(this.iMasterCerId,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.GetUploadedCertificate();
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message, "");
      } else {
        this.modalService.dismissAll();
      }
    });
  }

  GetTypeofCertificate(type: any) {
    this.certificationsService.GetTypeofCertificate(type,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.specificCertificateList = result["data"];
      }
    });
  }

  DeleteTypeofCertificate(id: any, iMasterCerId: any) {
    this.certificationsService.DeleteTypeofCertificate(id,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.GetTypeofCertificate(iMasterCerId);
        this.GetUploadedCertificate();
        this.vCerDocument = "";
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message, "");
      }
    });
  }

  UploadCertficate() {
    if(this.iMasterCerId){ 
    }else{
      this.notifyService.showError("Please select Certification from the list", "");
      this.certificationForm.controls["vCerId"].setValue('');
      return;
    }

    this.certficationSubmitted = true;
    if (this.certificationForm.invalid) {
      return;
    }
    let reqData = {
      vCerId: this.iMasterCerId,
      vCerDocument: this.vCerDocument,
      vValue: this.certificationForm.value.vOther,
    };
    this.certificationsService.UploadCertficate(reqData,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.modalService.dismissAll();
        this.GetUploadedCertificate();
        this.iMasterCerId=undefined;
        this.vCerDocument = "";
        this.notifyService.showSuccess("Certificate Added Successfully!", "");
        this.certificationForm.reset();
        // this.notifyService.showSuccess(result.message,'');
      }
    });
  }

  EditCertification() {
    this.certficationEditSubmitted = true;
    if (this.certificationEditForm.invalid && this.uploadedCertificateList.length == 0) {
      return;
    }
    let reqData = {
      vCerId: this.iMasterCerId,
      vCerDocument: this.vCerDocument,
      vValue: this.certificationEditForm.value.vOther,
    };
    this.certificationsService.UploadCertficate(reqData,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.certificationEditForm.controls.vCerDocument.patchValue("");
        this.certificationEditForm.controls.vOther.patchValue("");
        this.GetTypeofCertificate(this.iMasterCerId);
        this.vCerDocument = "";
        this.modalService.dismissAll();
        this.notifyService.showSuccess("Certificate Updated Successfully!", "");
        this.certificationEditForm.reset();
        // this.notifyService.showSuccess(result.message,'');
      } else {
        this.modalService.dismissAll();
      }
    });
  }

  CheckApplicable(event: any) {
    let reqData = {
      iEmpCertificateRequired: event.checked,
    };
    this.certificationsService.CheckApplicable(reqData,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.GetCheckApplicable();
      }
    });
  }

  GetCheckApplicable() {
    this.certificationsService.GetCheckApplicable(this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.checkCertiApplicable = result.data.iEmpCertificateRequired;
      }
    });
  }

  crossClick() {
    if (this.vCerDocument) {
      this.vCerDocument = "";
    }
  }
}
