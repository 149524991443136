<div name="awards-box" id="awards-box" class="col-12">
  <div name="awards-card-container" id="awards-card-container" class="card-container">
    <div name="awards-card-header" id="awards-card-header" class="card-header">
      <div name="awards-title-section" id="awards-title-section" class="title-section custom-title">
        <div class="d-flex w-100 custom-title-flex">
          <div class="order-1">
            <h4 name="awards-card-title" id="awards-card-title" class="card-title award-margin">Rewards</h4>
          </div>
          <div class="order-2" placement="right auto" [ngbTooltip]="
          addTalentRewardsActionPermission ? '' : 'Not Authorized'
        " [ngClass]="
        addTalentRewardsActionPermission ? '' : 'unauthorized-cursor'
        ">
            <button name="awards-card-edit-button" id="awards-card-edit-button" class="card-edit-button ecard-add-button" type="button" [disabled]="checkAwdsApplicable" (click)="AwardsModal(awards,addTalentRewardsActionPermission)" [ngClass]="checkAwdsApplicable ? 'opacity-4' : ''"  [ngClass]="
            addTalentRewardsActionPermission ? '' : 'unauthorized-action'
          "> 
              <img class="card-add-button-icon" [src]="imagePath + 'green-add.svg'" alt="add-button">Add New
            </button>
          </div>
        </div>
        <div class="d-flex w-100 custom-title-flex-end">
          <div class="checkbox-main">
            <mat-checkbox class="custom-checkbox" [(ngModel)]="disableAwards" [formControl]="checkAwards" (change)="CheckApplicable($event)" *ngIf="uploadedAwardsList.length <= 0" [checked]="checkAwdsApplicable">Check if not applicable</mat-checkbox>
          </div>
          <div name="awards-incomplete-status-section" id="awards-incomplete-status-section" class="status-section status-margin">
            <div name="awards-incomplete-status-box" id="awards-incomplete-status-box" class="status-box" *ngIf="!awardDetailsCardCompleted">
              <img id="awards-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'" alt="status-icon">
              <label name="awards-incomplete-status-text" id="awards-incomplete-status-text" class="status-text incomplete">Pending</label>
            </div>
            <div name="awards-completed-status-box" id="awards-completed-status-box" class="status-box" *ngIf="awardDetailsCardCompleted">
              <img id="awards-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'" alt="status-icon">
              <label name="awards-completed-status-text" id="awards-completed-status-text" class="status-icon" class="status-text completed">Completed</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div name="awards-card" id="awards-card" class="card" [ngClass]="[(awardDetailsCardCompleted == true)?'completed':'', (awardDetailsCardCompleted == false || checkAwdsApplicable == false ) ? 'incomplete':'', checkAwdsApplicable ? 'completed opacity-4' : '' ]">
      <perfect-scrollbar>
        <div name="awards-card-content" id="awards-card-content" class="card-content" *ngIf="checkAwdsApplicable">
          <div name="awards-text-box-with-icon" id="awards-text-box-with-icon" class="text-box-with-icon">
            <img id="awards-icon" class="icon" [src]="imagePath + 'info-icon.svg'" alt="info-tick">
            <label name="awards-text" id="awards-text" class="text">Not Applicable</label>
          </div>
        </div>
        <div class="card-content" *ngIf="uploadedAwardsList.length == 0 && !checkAwdsApplicable">
          <label class="text">No Rewards Found</label>
        </div>
        <div name="awards-card-content" id="awards-card-content" class="card-content scroll-card" *ngIf="uploadedAwardsList.length > 0">
          <div name="awards-text-box-with-icon" id="awards-text-box-with-icon" class="text-box-with-icon" *ngFor = "let item of uploadedAwardsList">
            <img id="awards-icon" class="icon" [src]="imagePath + 'green-tick.svg'" alt="green-tick">
            <label name="awards-text" id="awards-text" class="text">{{ item.vAwardName }}</label>
            <button name="awards-card-edit-button" id="awards-card-edit-button" class="'card-edit-button" type="button" (click)="AwardsEditModal(awardsEdit,item,editTalentRewardsActionPermission)" [ngClass]="
            editTalentRewardsActionPermission ? '' : 'unauthorized-cursor'" placement="right auto" [ngbTooltip]="editTalentRewardsActionPermission ? '' : 'Not Authorized'">
              <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
            </button>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>

<ng-template #awards let-c="close" let-d="dismiss">
  <div name="awards-modal-header" id="awards-modal-header" class="modal-header">
    <h1 name="awards-modal-title" id="awards-modal-title" class="modal-title">Add Rewards</h1>
  </div>
  <perfect-scrollbar>
    <div name="awards-modal-body" id="awards-modal-body" class="modal-body modal-scroll">
      <form name="awardsForm" id="awardsForm" class="form pb-0" [formGroup]="awardsForm" (ngSubmit)="UploadAwards()">
        <div name="awards-modal-row" id="awards-modal-row" class="row">
          <div name="awards-col-1" id="awards-col-1" class="col-12 col-md-6">
            <label for="vAwardId" name="awards-col-1-label" id="awards-col-1-label" class="custom-label">Reward Type <span class="text-danger">*</span></label>
            <img class="info-tooltip mx-2" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="top"
          ngbTooltip="Select 'Other' option, if option you want is not there in the list" placement="right"/>
            <mat-form-field hideRequiredMarker name="education-col-7-input" id="education-col-7-input" class="custom-dropdown custom-input form" floatLabel="never" floatPlaceholder="never">
              <input type="text" placeholder="Select Type" matInput formControlName="vAwardId" [matAutocomplete]="autoAward1" required>
              <mat-autocomplete autoActiveFirstOption #autoAward1="matAutocomplete">
                <mat-option *ngFor="let option of filteredAward | async" [value]="option.vAwardName" (onSelectionChange)="GetAwardId(option?.iAwardId);AwardChange($event)" title="{{option?.vAwardName}}">
                  {{option?.vAwardName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="awards-col-1-error-0" id="awards-col-1-error-0" class="reduce-bottom-space" *ngIf="awardsForm.controls['vAwardId'].invalid">
                <mat-error name="awards-col-1-error-1" id="awards-col-1-error-1" class="reduce-bottom-space" *ngIf="awardsForm.controls['vAwardId'].errors!.required">
                  Reward Type is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <label for="vAwardDocument" name="awards-video-label" id="awards-video-label" class="custom-label">Upload Document <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="awards-video-input" id="awards-video-input" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngIf="vAwardDocument">
                        {{ vAwardDocument.name }} <button type="button" name="button" (click)="crossClick()" class="btn"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="awards-video-input-vVideo-1" id="awards-video-input-vVideo-1" matInput readonly formControlName="vAwardDocument" [value]="vAwardDocument ? ' ' : ''" placeholder="Upload File" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="awards-video-input-vVideo-2"
                id="vAwardDocument"
                class="file-upload-input"
                (change)="FileBrowseHandler($event)"
                formControlName="vAwardDocument"
                accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel"
              />
              <button name="awards-video-input-upload-btn" id="awards-video-input-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <mat-error class="reduce-bottom-space" *ngIf="awardsForm.controls['vAwardDocument'].invalid">
                <mat-error class="reduce-bottom-space" *ngIf="awardsForm.controls['vAwardDocument'].errors!.required">
                  Document is required
                </mat-error>
              </mat-error>
              <mat-hint class="text">Max 4MB</mat-hint>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf = "awardsForm.controls['vAwardId'].value == 'Other'">
            <label for="vOther" name="other-col-2-label" id="other-col-2-label" class="custom-label">Other <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="other-col-2-input" id="other-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="vOther" formControlName="vOther" placeholder="Other...">
              <mat-error name="other-col-2-error-0" id="other-col-2-error-0" class="reduce-bottom-space" *ngIf="awardsForm.controls['vOther'].invalid">
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="awardsForm.controls['vOther'].errors!.required">
                  Award name is required
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="awardsForm.controls['vOther'].errors!.minlength">
                  Minimum 5 characters required
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="awardsForm.controls['vOther'].errors!.maxlength">
                  Minimum 50 characters allow
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="awardsForm.controls['vOther'].errors!.pattern">
                  Please enter valid text
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <div name="awards-modal-footer" id="awards-modal-footer" class="modal-footer">
    <button type="submit" name="awards-modal-submit-btn" id="awards-modal-submit-btn" class="btn btn-active" form="awardsForm">Submit</button>
    <button type="button" name="awards-modal-cancel-btn" id="awards-modal-cancel-btn" class="btn btn-outline-active" (click)="d('Cross click')">Cancel</button>
  </div>
</ng-template>

<ng-template #awardsEdit let-c="close" let-d="dismiss">
  <div name="awards-modal-header" id="awards-modal-header" class="modal-header with-button">
    <h1 name="awards-modal-title" id="awards-modal-title" class="modal-title">Edit Rewards</h1>
    <button type="button" name="button" class="delete-btn" (click) = DeleteUploadedAwards()>
      <img [src]="imagePath + 'delete_image.svg'" alt="Delete" class="delete-img"> Remove
    </button>
  </div>
  <perfect-scrollbar>
    <div name="awards-modal-body" id="awards-modal-body" class="modal-body modal-scroll">
      <form name="awardsEditForm" id="awardsEditForm" class="form pb-0" [formGroup]="awardsEditForm" (ngSubmit)="EditAwards()">
        <div name="awards-modal-row" id="awards-modal-row" class="row">
          <div name="awards-col-1" id="awards-col-1" class="col-12 col-md-6">
            <label for="vType" name="awards-col-1-label" id="awards-col-1-label" class="custom-label">Reward Type <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="awards-col-1-select" id="awards-col-1-select" class="custom-input">
              <input type="text" matInput id="vType" formControlName="vType" placeholder="" [(ngModel)] = "vAwardName" readonly>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <label for="vAwardDocument" name="awards-video-label" id="awards-video-label" class="custom-label">Upload Document <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="awards-video-input" id="awards-video-input" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngIf="vAwardDocument">
                        {{ vAwardDocument.name }} <button type="button" name="button" (click)="crossClick()" class="btn"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="awards-video-input-vVideo-1" id="awards-video-input-vVideo-1" matInput readonly formControlName="vAwardDocument" [value]="vAwardDocument ? ' ' : ''" placeholder="Upload File" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="awards-video-input-vVideo-2"
                id="vAwardDocument"
                class="file-upload-input"
                (change)="FileBrowseHandler($event)"
                formControlName="vAwardDocument"
                accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel"
              />
              <button name="awards-video-input-upload-btn" id="awards-video-input-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <mat-error class="reduce-bottom-space" *ngIf="awardsEditForm.controls['vAwardDocument'].invalid">
                <mat-error class="reduce-bottom-space" *ngIf="awardsEditForm.controls['vAwardDocument'].errors!.required">
                  Document is required
                </mat-error>
              </mat-error>
              <div class="input-details-box">
                <p class="text">Max 4MB</p>
              </div>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf = "vAwardName == 'Other'">
            <label for="vOther" name="other-col-2-label" id="other-col-2-label" class="custom-label">Other <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="other-col-2-input" id="other-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="vOther" formControlName="vOther" placeholder="Other...">
              <mat-error name="other-col-2-error-0" id="other-col-2-error-0" class="reduce-bottom-space" *ngIf="awardsEditForm.controls['vOther'].invalid">
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="awardsEditForm.controls['vOther'].errors!.required">
                  Reward name is required
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="awardsEditForm.controls['vOther'].errors!.minlength">
                  Minimum 5 characters required
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="awardsEditForm.controls['vOther'].errors!.maxlength">
                  Minimum 50 characters allow
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="awardsEditForm.controls['vOther'].errors!.pattern">
                  Please enter valid text
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="awards-class" id="awards-class" class="col-12 mt-2">
            <div name="awards-class-1" id="awards-1" class="image-display-container pb-2" *ngFor = " let item of specificAwardsList; let i = index;" >
              <div name="award-1" id="award-1" class="image-name">
                <span>{{i+1}})</span>
                <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                <a [href]="item.awardUrl" target="_blank">{{ item.vAwardUrl }}</a> <span *ngIf = "item.vValue" class="text-dark"> - {{ item.vValue }} </span>
              </div>
              <button type="button" name="button" id="award-1-btn" (click) = "DeleteTypeofAward(item.iUserAwardId, item.iAwardId)">
                <img id="award-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <div name="awards-modal-footer" id="awards-modal-footer" class="modal-footer">
    <button type="submit" name="awards-modal-submit-btn" id="awards-modal-submit-btn" class="btn btn-active" form="awardsEditForm">Submit</button>
    <button type="button" name="awards-modal-cancel-btn" id="awards-modal-cancel-btn" class="btn btn-outline-active" (click)="d('Cross click')">Cancel</button>
  </div>
</ng-template>
