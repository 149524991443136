<div name="certifications-box" id="certifications-box" class="col-12">
  <div name="certifications-card-container" id="certifications-card-container" class="card-container" *ngIf="certificationsToggle==false">
    <div name="certifications-card-header" id="certifications-card-header" class="card-header">
      <div name="certifications-title-section" id="certifications-title-section" class="title-section">
        <h4 name="certifications-card-title" id="certifications-card-title" class="card-title">Certifications</h4>
        <div placement="right auto" [ngbTooltip]="editPartnerCertificationsActionPermission ? '':'Not Authorized'" [ngClass]="editPartnerCertificationsActionPermission ? '':'unauthorized-cursor'">
          <button name="certifications-card-edit-button" id="certifications-card-edit-button" class="card-edit-button" type="button" (click)="toggleCertifications(editPartnerCertificationsActionPermission)" [ngClass]="editPartnerCertificationsActionPermission ? '':'unauthorized-action'">
            <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
          </button>
        </div>
      </div>
      <div name="certifications-incomplete-status-section" id="certifications-incomplete-status-section"
        class="status-section">
        <div name="certifications-incomplete-status-box" id="certifications-incomplete-status-box" class="status-box"
          *ngIf="!certificationsCardCompleted">
          <img id="certifications-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
            alt="status-icon">
          <label name="certifications-incomplete-status-text" id="certifications-incomplete-status-text"
            class="status-text incomplete">Pending</label>
        </div>
        <div name="certifications-completed-status-box" id="certifications-completed-status-box" class="status-box"
          *ngIf="certificationsCardCompleted">
          <img id="certifications-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'"
            alt="status-icon">
          <label name="certifications-completed-status-text" id="certifications-completed-status-text"
            class="status-icon" class="status-text completed">Completed</label>
        </div>
      </div>
    </div>
    <div name="certifications-card" id="certifications-card" class="card"
      [ngClass]="certificationsCardCompleted ? 'completed' : 'incomplete'">
      <div name="certifications-card-content" id="certifications-card-content" class="card-content">
        <div name="certifications-iso-text-box-with-icon" id="certifications-iso-text-box-with-icon"
          class="text-box-with-icon bottom-spacing">
          <img id="certifications-iso-icon" class="icon" alt="tick"
            [src]="(certificateStatus?.isoStatus || iParnterISORequired) ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'">
          <label name="certifications-iso-text" id="certifications-text" class="text"
            [ngClass]="{'disabled': !certificateStatus?.isoStatus}">ISO Certificate
            <span class="text-danger"
              [ngClass]="{'d-none': certificateStatus?.isoStatus}">*</span></label>
        </div>
        <div name="certifications-iec-text-box-with-icon" id="certifications-iec-text-box-with-icon"
          class="text-box-with-icon bottom-spacing">
          <img id="certifications-iec-icon" class="icon" alt="tick"
            [src]="certificateStatus?.iecStatus ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'">
          <label name="certifications-iec-text" id="certifications-iec-text" class="text"
            [ngClass]="{'disabled': !certificateStatus?.iecStatus}">Import and Export Certificate (IEC)</label>
        </div>
        <div name="certifications-cmmi-level-text-box-with-icon" id="certifications-cmmi-level-text-box-with-icon"
          class="text-box-with-icon bottom-spacing">
          <img id="certifications-cmmi-level-icon" class="icon"
            [src]="(certificateStatus?.cmmiStatus || iParnterCMMIRequired) ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="tick">
          <label name="certifications-cmmi-level-text" id="certifications-cmmi-level-text" class="text"
            [ngClass]="{'disabled': !certificateStatus?.cmmiStatus}">CMMI Level Certificate
            <span class="text-danger"
            [ngClass]="{'d-none': certificateStatus?.cmmiStatus}">*</span></label>
        </div>
        <div name="certifications-trademark-text-box-with-icon" id="certifications-trademark-text-box-with-icon"
          class="text-box-with-icon bottom-spacing">
          <img id="certifications-trademark-icon" class="icon"
            [src]="certificateStatus?.tradmarkStatus ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="tick">
          <label name="certifications-trademark-text" id="certifications-trademark-text" class="text"
            [ngClass]="{'disabled': !certificateStatus?.tradmarkStatus}">Trademark Certificate</label>
        </div>
        <div name="certifications-rewards-text-box-with-icon" id="certifications-rewards-text-box-with-icon"
          class="text-box-with-icon">
          <img id="certifications-rewards-icon" class="icon"
            [src]="certificateStatus?.rewardStatus ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="tick">
          <label name="certifications-rewards-text" id="certifications-rewards-text" class="text"
            [ngClass]="{'disabled': !certificateStatus?.rewardStatus}">Awards</label>
        </div>
      </div>
    </div>
  </div>
  <div class="card-container" *ngIf="certificationsToggle==true">
    <div name="company-certification-card-header" id="company-certification-card-header" class="card-header">
      <div name="company-certification-title-section" id="company-certification-title-section" class="title-section">
        <h4 name="company-certification-card-title" id="company-certification-card-title" class="card-title">Company Certifications</h4>
      </div>
    </div>
    <div class="edit-profile">
      <div name="company-certification-modal-body" id="company-certification-modal-body" class="modal-body modal-scroll">
        <form name="isoCertificationForm" id="isoCertificationForm" class="form" [formGroup]="isoCertificationForm"
          (ngSubmit)="UploadISOCertificate()">
          <div name="company-certification-modal-row" id="company-certification-modal-row" class="row">
            <div name="certification-modal-iso-certificate-title-class"
              id="certification-modal-iso-certificate-title-class" class="col-12">
              <div class="bold-title d-flex flex-column flex-sm-row">
                <label name="certification-modal-iso-certificate-title" id="certification-modal-iso-certificate-title">
                  ISO Certificate
                </label>
                <mat-checkbox name="certification-modal-iso-certificate-checkbox"
                  id="certification-modal-iso-certificate-checkbox" class="custom-checkbox mx-0 mx-sm-3"
                  (change)="CheckApplicable($event,1)" [checked]="iParnterISORequired"
                  *ngIf="isoCertificateList.length == 0">Check if not applicable</mat-checkbox>
              </div>
            </div>
            <div name="certification-modal-standard" id="certification-modal-standard" class="col-12 col-md-6"
              [ngClass]="iParnterISORequired ? 'opacity-4' : ''">
              <label for="vISOId" name="certification-modal-standard-label" id="certification-modal-standard-label"
                class="custom-label">Select Standard <span class="text-danger">*</span></label>
              <mat-form-field name="mat-form-field-certification-modal-standard"
                id="mat-form-field-certification-modal-standard" hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <mat-select name="vISOId" id="vISOId" formControlName="vISOId" placeholder="ISO 9001:2015">
                  <mat-option name="mat-option-certification-modal-standard" id="mat-option-certification-modal-standard"
                    *ngFor="let item of ISOList; let i = index" [value]="item.iMasterCerId">{{ item.vName }}</mat-option>
                </mat-select>
                <mat-error name="mat-error-certification-modal-standard" id="mat-error-certification-modal-standard"
                  class="reduce-bottom-space" *ngIf="isoCertificationForm.controls['vISOId'].invalid">
                  <mat-error name="mat-error-certification-modal-standard-text-1"
                    id="mat-error-certification-modal-standard-text-1" class="reduce-bottom-space"
                    *ngIf="isoCertificationForm.controls['vISOId'].errors!.required">
                    ISO Standard is required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="certification-modal-iso-certi-num" id="certification-modal-iso-certi-num" class="col-12 col-md-6"
              [ngClass]="iParnterISORequired ? 'opacity-4' : ''">
              <label for="vISOCerNumber" name="certification-modal-iso-certi-num-label"
                id="certification-modal-iso-certi-num-label" class="custom-label">ISO Certificate Number <span
                  class="text-danger">*</span></label>
              <mat-form-field name="mat-form-field-certification-modal-iso-certi-num"
                id="mat-form-field-certification-modal-iso-certi-num" hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput name="vISOCerNumber" id="vISOCerNumber" formControlName="vISOCerNumber"
                  placeholder="0000000000">
                <mat-error name="mat-error-certification-modal-iso-certi-num"
                  id="mat-error-certification-modal-iso-certi-num" class="reduce-bottom-space"
                  *ngIf="isoCertificationForm.controls['vISOCerNumber'].invalid">
                  <mat-error name="mat-error-certification-modal-iso-certi-num-text-1"
                    id="mat-error-certification-modal-iso-certi-num-text-1" class="reduce-bottom-space"
                    *ngIf="isoCertificationForm.controls['vISOCerNumber'].errors!.required">
                    ISO Certificate Number is required
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="(isoCertificationForm.controls['vISOCerNumber'].errors!.minlength || isoCertificationForm.controls['vISOCerNumber'].errors!.maxlength) && !isoCertificationForm.controls['vISOCerNumber'].errors!.pattern">
                    Length must be between 6-19
                  </mat-error>
                  <mat-error name="mat-error-certification-modal-iso-certi-num-text-1"
                    id="mat-error-certification-modal-iso-certi-num-text-1" class="reduce-bottom-space"
                    *ngIf="isoCertificationForm.controls['vISOCerNumber'].errors!.pattern">
                    No white space allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="certification-modal-iso-certi-pdf" id="certification-modal-iso-certi-pdf" class="col-12 col-md-6"
              [ngClass]="iParnterISORequired ? 'opacity-4' : ''">
              <label for="vISOCertificatePdf" name="certification-modal-iso-certi-pdf-label"
                id="certification-modal-iso-certi-pdf-label" class="custom-label">Upload File <span
                  class="text-danger">*</span></label>
              <mat-form-field name="mat-form-field-certification-modal-iso-certi-pdf"
                id="mat-form-field-certification-modal-iso-certi-pdf" class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div name="certification-modal-iso-certi-pdf-file-container"
                  id="certification-modal-iso-certi-pdf-file-container" class="file-container">
                  <div name="certification-modal-iso-certi-pdf-files" id="certification-modal-iso-certi-pdf-files"
                    class="files">
                    <div name="certification-modal-iso-certi-pdf-files-scroll"
                      id="certification-modal-iso-certi-pdf-files-scroll" class="file-scroll-box" *ngIf="isoCertificate">
                      <perfect-scrollbar [config]="config">
                        <div name="certification-modal-iso-certi-pdf-show-file"
                          id="certification-modal-iso-certi-pdf-show-file" class="file">
                          {{ isoCertificate.name }} <button name="certification-modal-iso-certi-pdf-close-btn"
                            id="certification-modal-iso-certi-pdf-close-btn" type="button" name="button" class="btn"
                            (click)="CancelMedia(1)">
                            <mat-icon name="certification-modal-iso-certi-pdf-close-icon"
                              id="certification-modal-iso-certi-pdf-close-icon">close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="certification-modal-iso-certi-pdf-vISOCertificatePdf-1"
                  id="certification-modal-iso-certi-pdf-vISOCertificatePdf-1" matInput readonly
                  formControlName="vISOCertificatePdf" [value]="isoCertificate ? ' ' : ''" placeholder="Upload File" />
                <input name="certification-modal-iso-certi-pdf-vISOCertificatePdf-2"
                  id="certification-modal-iso-certi-pdf-vISOCertificatePdf-2" matSuffix type="file" #uploadFile
                  id="uploadFile" class="file-upload-input" (change)='FileBrowseHandler($event,1)'
                  formControlName="vISOCertificatePdf"
                  accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel" />
                <button name="certification-modal-iso-certi-pdf-upload-btn"
                  id="certification-modal-iso-certi-pdf-upload-btn" class="upload-btn" matSuffix><img
                    id="certification-modal-iso-certi-pdf-upload-img" [src]="imagePath + 'file-upload-icon.svg'"
                    alt="file upload"></button>
                <mat-error name="mat-error-certification-modal-iso-certi-pdf"
                  id="mat-error-certification-modal-iso-certi-pdf" class="reduce-bottom-space"
                  *ngIf="isoCertificationForm.controls['vISOCertificatePdf'].invalid">
                  <mat-error name="mat-error-certification-modal-iso-certi-pdf-text-1"
                    id="mat-error-certification-modal-iso-certi-pdf-text-1" class="reduce-bottom-space"
                    *ngIf="isoCertificationForm.controls['vISOCertificatePdf'].errors!.required">
                    File is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 4MB file required</mat-hint>
              </mat-form-field>
            </div>
  
            <div name="certification-modal-add-certificate-class" id="certification-modal-add-certificate-class"
              class="col-12">
              <button name="certification-modal-add-certificate-btn" id="certification-modal-add-certificate-btn"
                class="add-certificate-btn" type="submit" form="isoCertificationForm" [disabled]="iParnterISORequired"
                [ngClass]="iParnterISORequired ? 'opacity-4' : ''">Add Certificate</button>
            </div>
            <mat-form-field floatLabel="never" class="btn-error mt-1 mb-3">
              <input type="text" hidden="true" matInput formControlName="isoCertificateList"
                [value]="isoCertificateList.length">
              <mat-error class="reduce-bottom-space" *ngIf="isoCertificateList.length===0">
                Please add atleast One ISO certificate
              </mat-error>
              <!-- <mat-hint>Click on Add Certificate to add ISO certificate</mat-hint> -->
            </mat-form-field>
            <div name="certification-modal-iec-image-display" id="certification-modal-iec-image-display" class="col-12"
              *ngIf="isoCertificateList.length > 0">
              <div name="certification-modal-iec-image-class-1" id="certification-modal-iec-image-class-1"
                class="image-display-container" *ngFor="let item of isoCertificateList; let i = index">
                <div name="certification-modal-iec-name-1" id="certification-modal-iec-name-1" class="image-name">
                  <label>{{i+1}}</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="item?.cerUrl" target="_blank">{{item.MasterPartnerCert}}</a>
                </div>
                <button type="button" name="certification-modal-iec-delete-button"
                  id="certification-modal-iec-delete-button" (click)="DeleteAllCertificate(item.iUserCertificateId)">
                  <img id="certification-modal-iec-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button>
              </div>
            </div>
          </div>
        </form>
        <form name="certificationForm" id="certificationForm" class="form" [formGroup]="certificationForm"
          (ngSubmit)="UploadCertificate()">
          <div name="company-certification-modal-row" id="company-certification-modal-row" class="row">
            <div name="certification-modal-import-export-certificate-title-class"
              id="certification-modal-import-export-certificate-title-class" class="col-12">
              <label name="certification-modal-import-export-certificate-title"
                id="certification-modal-import-export-certificate-title" class="bold-title top-spacing">
                Import and Export Certificate
              </label>
            </div>
            <div name="certification-modal-iec-num" id="certification-modal-iec-num" class="col-12 col-md-6">
              <label for="vIECNumber" name="certification-modal-iec-num-label" id="certification-modal-iec-num-label"
                class="custom-label">IEC Number</label>
              <mat-form-field name="mat-form-field-certification-modal-iec-num"
                id="mat-form-field-certification-modal-iec-num" hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput name="vIECNumber" id="vIECNumber" formControlName="vIECNumber"
                  placeholder="0000000000" [readonly]="otherCertificatesList?.iecPdf?.vCerId">
                <mat-error class="reduce-bottom-space"
                  *ngIf="(certificationForm.controls['vIECNumber'].valid || certificationForm.controls['vIECNumber'].invalid)">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="(certificationForm.controls['vIECNumber'].errors?.minlength || certificationForm.controls['vIECNumber'].errors?.maxlength) && !certificationForm.controls['vIECNumber'].errors?.pattern">
                    Length must be 10
                  </mat-error>
                  <mat-error class="reduce-bottom-space" *ngIf="certificationForm.controls['vIECNumber'].errors?.pattern">
                    Allow Alphanumerics only
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="certification-modal-iec-pdf" id="certification-modal-iec-pdf" class="col-12 col-md-6">
              <label for="vIECNumberPdf" name="certification-modal-iec-pdf-label" id="certification-modal-iec-pdf-label"
                class="custom-label">Upload File</label>
              <mat-form-field name="mat-form-field-certification-modal-iec-pdf"
                id="mat-form-field-certification-modal-iec-pdf" hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div name="certification-modal-iec-pdf-file-container" id="certification-modal-iec-pdf-file-container"
                  class="file-container">
                  <div name="certification-modal-iec-pdf-files" id="certification-modal-iec-pdf-files" class="files">
                    <div name="certification-modal-iec-pdf-file-scroll" id="certification-modal-iec-pdf-file-scroll"
                      class="file-scroll-box" *ngIf="iecCertificate">
                      <perfect-scrollbar [config]="config">
                        <div name="certification-modal-iec-pdf-show-file" id="certification-modal-iec-pdf-show-file"
                          class="file">
                          {{ iecCertificate.name }} <button name="certification-modal-iec-pdf-close-btn"
                            id="certification-modal-iec-pdf-close-btn" class="btn" type="button" name="button"
                            (click)="CancelMedia(2)">
                            <mat-icon name="certification-modal-iec-pdf-close-icon"
                              id="certification-modal-iec-pdf-close-icon">close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="certification-modal-iec-pdf-vIECNumberPdf-input-1"
                  id="certification-modal-iec-pdf-vIECNumberPdf-input-1" matInput readonly formControlName="vIECNumberPdf"
                  [value]="iecCertificate ? ' ' : ''" placeholder="Upload File" />
                <input name="certification-modal-iec-pdf-vIECNumberPdf-input-1"
                  id="certification-modal-iec-pdf-vIECNumberPdf-input-1" matSuffix type="file" #uploadFile id="uploadFile"
                  class="file-upload-input" (change)="FileBrowseHandler($event,2)" formControlName="vIECNumberPdf"
                  accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel" />
                <button name="certification-modal-iec-pdf-upload-btn" id="certification-modal-iec-pdf-upload-btn"
                  class="upload-btn" matSuffix><img id="certification-modal-iec-pdf-upload-img"
                    [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-hint>Max 4MB file required</mat-hint>
              </mat-form-field>
            </div>
            <div name="certification-modal-iec-image-display" id="certification-modal-iec-image-display" class="col-12"
              *ngIf="otherCertificatesList?.iecPdf">
              <div name="certification-modal-iec-image-class-1" id="certification-modal-iec-image-class-1"
                class="image-display-container margin-class">
                <div name="certification-modal-iec-name-1" id="certification-modal-iec-name-1" class="image-name">
                  <label>1)</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="otherCertificatesList?.iecPdf?.cerUrl" target="_blank">{{
                    otherCertificatesList?.iecPdf?.MasterPartnerCert }}</a>
                </div>
                <button type="button" name="certification-modal-iec-delete-button"
                  id="certification-modal-iec-delete-button"
                  (click)="DeleteAllCertificate(otherCertificatesList?.iecPdf?.iUserCertificateId)">
                  <img id="certification-modal-iec-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button>
              </div>
            </div>
            <div name="certification-modal-cmmi-level-certificate-title-class"
              id="certification-modal-cmmi-level-certificate-title-class" class="col-12">
              <div class="bold-title top-spacing d-flex flex-column flex-sm-row">
                <label name="certification-modal-cmmi-level-certificate-title"
                  id="certification-modal-cmmi-level-certificate-title">
                  CMMI Level Certificate
                </label>
                <mat-checkbox name="certification-modal-cmmi-level-certificate-checkbox"
                  id="certification-modal-cmmi-level-certificate-checkbox" class="custom-checkbox mx-0 mx-sm-3"
                  (change)="CheckApplicable($event,2)" [checked]="iParnterCMMIRequired"
                  *ngIf="cmmiCertificates.length < 1">Check if not applicable</mat-checkbox>
              </div>
            </div>
            <div name="certification-modal-cmmi-certificate" id="certification-modal-cmmi-certificate"
              class="col-12 col-md-6" [ngClass]="iParnterCMMIRequired ? 'opacity-4' : ''">
              <label for="vCMMIId" name="certification-modal-cmmi-certificate-label"
                id="certification-modal-cmmi-certificate-label" class="custom-label">Certificate Level <span
                  class="text-danger">*</span></label>
              <mat-form-field name="mat-form-field-certification-modal-cmmi-certificate"
                id="mat-form-field-certification-modal-cmmi-certificate" hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <mat-select name="vCMMIId" id="vCMMIId" formControlName="vCMMIId" placeholder="CMMI">
                  <mat-option name="mat-option-certification-modal-cmmi-certificate"
                    id="mat-option-certification-modal-cmmi-certificate" *ngFor="let item of CMMIList; let i = index"
                    [value]="item.iMasterCerId">{{ item.vName }}</mat-option>
                </mat-select>
                <mat-error name="mat-error-certification-modal-cmmi-certificate"
                  id="mat-error-certification-modal-cmmi-certificate" class="reduce-bottom-space"
                  *ngIf="certificationForm.controls['vCMMIId'].invalid">
                  <mat-error name="mat-error-certification-modal-cmmi-certificate-text-1"
                    id="mat-error-certification-modal-cmmi-certificate-text-1" class="reduce-bottom-space"
                    *ngIf="certificationForm.controls['vCMMIId'].errors?.required">
                    CMMI Level Certificate is required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="certification-modal-cmmi-certificate-name" id="certification-modal-cmmi-certificate-name"
              class="col-12 col-md-6" [ngClass]="iParnterCMMIRequired ? 'opacity-4' : ''">
              <label for="vCMMICerNumber" name="certification-modal-cmmi-certificate-name-label"
                id="certification-modal-cmmi-certificate-name-label" class="custom-label">Certificate Name <span
                  class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput name="vCMMICerNumber" id="vCMMICerNumber" formControlName="vCMMICerNumber"
                  placeholder="0000000000">
                <mat-error name="mat-error-certification-modal-cmmi-certificate-name"
                  id="mat-error-certification-modal-cmmi-certificate-name" class="reduce-bottom-space"
                  *ngIf="certificationForm.controls['vCMMICerNumber'].invalid">
                  <mat-error name="mat-error-certification-modal-cmmi-certificate-name-text-1"
                    id="mat-error-certification-modal-cmmi-certificate-name-text-1" class="reduce-bottom-space"
                    *ngIf="certificationForm.controls['vCMMICerNumber'].errors!.required">
                    Certificate Name is required
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="(certificationForm.controls['vCMMICerNumber'].errors!.minlength || certificationForm.controls['vCMMICerNumber'].errors!.maxlength) && !certificationForm.controls['vCMMICerNumber'].errors!.pattern">
                    Length must be between 3-40
                  </mat-error>
                  <mat-error name="mat-error-certification-modal-cmmi-certificate-name-text-1"
                    id="mat-error-certification-modal-cmmi-certificate-name-text-1" class="reduce-bottom-space"
                    *ngIf="certificationForm.controls['vCMMICerNumber'].errors!.pattern">
                    No white space allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="certification-modal-cmmi-certificate-pdf" id="certification-modal-cmmi-certificate-pdf"
              class="col-12 col-md-6" [ngClass]="iParnterCMMIRequired ? 'opacity-4' : ''">
              <label name="certification-modal-cmmi-certificate-pdf-label"
                id="certification-modal-cmmi-certificate-pdf-label" for="vCMMICertificatePdf"
                class="custom-label">Upload File <span class="text-danger">*</span></label>
              <mat-form-field name="mat-form-field-certification-modal-cmmi-certificate-pdf"
                id="mat-form-field-certification-modal-cmmi-certificate-pdf" hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div name="certification-modal-cmmi-certificate-pdf-file-container"
                  id="certification-modal-cmmi-certificate-pdf-file-container" class="file-container">
                  <div name="certification-modal-cmmi-certificate-pdf-files"
                    id="certification-modal-cmmi-certificate-pdf-files" class="files">
                    <div name="certification-modal-cmmi-certificate-pdf-file-scroll"
                      id="certification-modal-cmmi-certificate-pdf-file-scroll" class="file-scroll-box"
                      *ngIf="cmmiCertificate">
                      <perfect-scrollbar [config]="config">
                        <div name="certification-modal-cmmi-certificate-pdf-show-file"
                          id="certification-modal-cmmi-certificate-pdf-show-file" class="file">
                          {{ cmmiCertificate.name }} <button name="certification-modal-cmmi-certificate-pdf-close-btn"
                            id="certification-modal-cmmi-certificate-pdf-close-btn" class="btn" type="button"
                            name="button" (click)="CancelMedia(3)">
                            <mat-icon name="certification-modal-cmmi-certificate-pdf-close-icon"
                              id="certification-modal-cmmi-certificate-pdf-close-icon">close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="certification-modal-cmmi-certificate-pdf-input-1"
                  id="certification-modal-cmmi-certificate-pdf-input-1" matInput readonly
                  formControlName="vCMMICertificatePdf" [value]="cmmiCertificate ? ' ' : ''" placeholder="Upload File" />
                <input name="certification-modal-cmmi-certificate-pdf-input-1"
                  id="certification-modal-cmmi-certificate-pdf-input-1" matSuffix type="file" #uploadFile id="uploadFile"
                  class="file-upload-input" (change)="FileBrowseHandler($event,3)" formControlName="vCMMICertificatePdf"
                  accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel" />
                <button name="certification-modal-cmmi-certificate-pdf-upload-btn"
                  id="certification-modal-cmmi-certificate-pdf-upload-btn" class="upload-btn" matSuffix><img
                    id="certification-modal-cmmi-certificate-pdf-upload-img" [src]="imagePath + 'file-upload-icon.svg'"
                    alt="file upload"></button>
                <mat-error name="mat-error-certification-modal-cmmi-certificate-pdf"
                  id="mat-error-certification-modal-cmmi-certificate-pdf" class="reduce-bottom-space"
                  *ngIf="certificationForm.controls['vCMMICertificatePdf'].invalid">
                  <mat-error name="mat-error-certification-modal-cmmi-certificate-pdf-text-1"
                    id="mat-error-certification-modal-cmmi-certificate-pdf-text-1" class="reduce-bottom-space"
                    *ngIf="certificationForm.controls['vCMMICertificatePdf'].errors!.required">
                    File is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 4MB file required</mat-hint>
              </mat-form-field>
            </div>
            <div name="cmmi-certi-image-display" id="cmmi-certi-image-display" class="col-12" *ngIf="cmmiCertificates">
              <div name="cmmi-certi-image-class-1" id="cmmi-certi-image-class-1"
                class="image-display-container margin-class" *ngFor="let cmmi of cmmiCertificates; let i = index">
                <div name="cmmi-certi-name-1" id="cmmi-certi-name-1" class="image-name">
                  <label>{{i+1}}</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="otherCertificatesList?.cmmiPdf?.cerUrl" target="_blank">{{ cmmi?.MasterPartnerCert }}</a>
                </div>
                <button type="button" name="button" (click)="DeleteAllCertificate(cmmi?.iUserCertificateId)">
                  <img id="cmmi-certi-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button>
              </div>
            </div>
            <div name="trademark-certificate-title-class" id="trademark-certificate-title-class" class="col-12">
              <label name="trademark-certificate-title" id="trademark-certificate-title" class="bold-title top-spacing">
                Trademark Certificate
              </label>
            </div>
            <div name="trademark-certificate-pdf" id="trademark-certificate-pdf" class="col-12 col-md-6">
              <label name="trademark-certificate-pdf-label" id="trademark-certificate-pdf-label"
                for="vTrademarkCertificatePdf" class="custom-label">Upload File</label>
              <mat-form-field name="mat-form-field-trademark-certificate-pdf"
                id="mat-form-field-trademark-certificate-pdf" hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div name="trademark-certificate-pdf-file-container" id="trademark-certificate-pdf-file-container"
                  class="file-container">
                  <div name="trademark-certificate-pdf-files" id="trademark-certificate-pdf-files" class="files">
                    <div name="trademark-certificate-pdf-file-scroll" id="trademark-certificate-pdf-file-scroll"
                      class="file-scroll-box" *ngIf="trademarkCertifiacate">
                      <perfect-scrollbar [config]="config">
                        <div name="trademark-certificate-pdf-show-file" id="trademark-certificate-pdf-show-file"
                          class="file">
                          {{ trademarkCertifiacate.name }} <button name="trademark-certificate-pdf-close-btn"
                            id="trademark-certificate-pdf-close-btn" class="btn" type="button" name="button"
                            (click)="CancelMedia(4)">
                            <mat-icon name="trademark-certificate-pdf-close-icon"
                              id="trademark-certificate-pdf-close-icon">close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="trademark-certificate-input-1" id="trademark-certificate-input-1" matInput readonly
                  formControlName="vTrademarkCertificatePdf" [value]="trademarkCertifiacate ? ' ' : ''"
                  placeholder="Upload File" />
                <input name="trademark-certificate-input-1" id="trademark-certificate-input-1" matSuffix type="file"
                  #uploadFile id="uploadFile" class="file-upload-input" (change)="FileBrowseHandler($event,4)"
                  formControlName="vTrademarkCertificatePdf"
                  accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel" />
                <button name="trademark-certificate-upload-btn" id="trademark-certificate-upload-btn" class="upload-btn"
                  matSuffix><img id="trademark-certificate-upload-img" [src]="imagePath + 'file-upload-icon.svg'"
                    alt="file upload"></button>
                <mat-hint>Max 4MB file required</mat-hint>
              </mat-form-field>
            </div>
            <div name="trademark-certificate-upload-image-display" id="trademark-certificate-upload-image-display"
              class="col-12" *ngIf="otherCertificatesList?.trademarkCerPdf">
              <div name="trademark-certificate-upload-image-class-1" id="trademark-certificate-upload-image-class-1"
                class="image-display-container margin-class">
                <div name="trademark-certificate-upload-name-1" id="trademark-certificate-upload-name-1"
                  class="image-name">
                  <label>1)</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="otherCertificatesList?.trademarkCerPdf?.cerUrl" target="_blank">{{
                    otherCertificatesList?.trademarkCerPdf?.MasterPartnerCert }}</a>
                </div>
                <button type="button" name="trademark-certificate-upload-delete-button"
                  id="trademark-certificate-upload-delete-button"
                  (click)="DeleteAllCertificate(otherCertificatesList?.trademarkCerPdf?.iUserCertificateId)">
                  <img id="trademark-certificate-upload-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button>
              </div>
            </div>
            <div name="awards-title-class" id="awards-title-class" class="col-12">
              <label name="awards-title" id="awards-title" class="bold-title top-spacing">
                Awards
              </label>
            </div>
            <div name="reward-photo-pdf" id="reward-photo-pdf" class="col-12 col-md-6">
              <label name="reward-photo-pdf-label" id="reward-photo-pdf-label" for="reward"
                class="custom-label">Award Photo</label>
              <mat-form-field name="mat-form-field-reward-photo-pdf" id="mat-form-field-reward-photo-pdf"
                hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div name="reward-photo-pdf-file-container" id="reward-photo-pdf-file-container" class="file-container">
                  <div name="reward-photo-pdf-files" id="reward-photo-pdf-files" class="files">
                    <div name="reward-photo-pdf-file-scroll" id="reward-photo-pdf-file-scroll" class="file-scroll-box"
                      *ngIf="reward">
                      <perfect-scrollbar [config]="config">
                        <div name="reward-photo-pdf-show-file" id="reward-photo-pdf-show-file" class="file">
                          {{ reward.name }} <button name="reward-photo-pdf-close-btn" id="reward-photo-pdf-close-btn"
                            class="btn" type="button" name="button" (click)="CancelMedia(5)">
                            <mat-icon name="reward-photo-pdf-close-icon" id="reward-photo-pdf-close-icon">close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="reward-photo-pdf-input-1" id="reward-photo-pdf-input-1" matInput readonly
                  formControlName="reward" [value]="reward ? ' ' : ''" placeholder="Upload Photo" />
                <input name="reward-photo-pdf-input-1" id="reward-photo-pdf-input-1" matSuffix type="file" #uploadFile
                  id="uploadFile" class="file-upload-input" (change)="FileBrowseHandler($event,5)"
                  formControlName="reward" accept="image/x-png,image/jpeg" />
                <button name="reward-photo-pdf-upload-btn" id="reward-photo-pdf-upload-btn" class="upload-btn"
                  matSuffix><img id="reward-photo-pdf-upload-img" [src]="imagePath + 'file-upload-icon.svg'"
                    alt="file upload"></button>
                <mat-hint>Max 4MB Image required</mat-hint>
              </mat-form-field>
            </div>
            <div name="rewards-image-display" id="rewards-image-display" class="col-12"
              *ngIf="otherCertificatesList?.rewardsPdf">
              <div name="rewards-image-class-1" id="rewards-image-class-1" class="image-display-container margin-class">
                <div name="rewards-name-1" id="rewards-name-1" class="image-name">
                  <label>1)</label>
                  <img class="image-thumbnail" [src]="otherCertificatesList?.rewardsPdf?.cerUrl" alt="images">
                  <a [href]="otherCertificatesList?.rewardsPdf?.cerUrl" target="_blank">{{
                    otherCertificatesList?.rewardsPdf?.MasterPartnerCert }}</a>
                </div>
                <button type="button" name="button"
                  (click)="DeleteAllCertificate(otherCertificatesList?.rewardsPdf?.iUserCertificateId)">
                  <img id="rewards-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div name="company-certification-modal-footer" id="company-certification-modal-footer" class="modal-footer">
        <button type="button" name="company-certification-modal-cancel-btn" id="company-certification-modal-cancel-btn"
          class="blue-secondary-small-btn mx-2"
          (click)="CancelCertificationsModal(); this.certificationSubmitted = false;">Cancel</button>
        <button type="submit" name="company-certification-modal-submit-btn" id="company-certification-modal-submit-btn"
          class="blue-primary-small-btn" form="certificationForm">Save</button>
      </div>
    </div>
  </div>
</div>

