<h1 class="fs-3 fw-bolder m-3">Text Classes</h1>
<div class="m-3 p-3 border border-2">
  <p class="title">title</p>
  <p class="heading-1">heading-1</p>
  <p class="heading-2">heading-2</p>
  <p class="heading-3">heading-3</p>
  <p class="body-text-1">body-text-1</p>
  <p class="body-text-2">body-text-2</p>
  <p class="body-text-3">body-text-3</p>
  <p class="caption">caption</p>
</div>

<br />

<h1 class="fs-3 fw-bolder m-3">Form Field Classes</h1>
<div class="m-3 p-3 border border-2">

  <mat-radio-group class="custom-radio">
    <mat-radio-button value="1">Option 1</mat-radio-button>
    <mat-radio-button value="2" selected>Option 2</mat-radio-button>
  </mat-radio-group>

  <br><br>

  <mat-form-field class="custom-dropdown skill">
    <mat-select disableRipple [(value)]="selectedSkills">
      <mat-option value="0">Select Skills</mat-option>
      <mat-option [value]="option.value" *ngFor="let option of skills">{{ option.viewValue }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="custom-dropdown experience">
    <mat-select disableRipple [(value)]="selectedSkills">
      <mat-option value="0">Select Exp.</mat-option>
      <mat-option [value]="option.value" *ngFor="let option of skills">{{ option.viewValue }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="custom-dropdown sort">
    <mat-select disableRipple [(value)]="selectedSkills">
      <mat-option value="0">Sort by</mat-option>
      <mat-option [value]="option.value" *ngFor="let option of skills">{{ option.viewValue }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="custom-dropdown all">
    <mat-select disableRipple [(value)]="selectedSkills">
      <mat-option value="0">All</mat-option>
      <mat-option [value]="option.value" *ngFor="let option of skills">{{ option.viewValue }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="custom-input custom-search-input" floatLabel="never" floatPlaceholder="never">
    <mat-label>Enter your password</mat-label>
    <input matInput type="text">
    <button matSuffix>
      <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
        <path id="Path_690" data-name="Path 690"
          d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
          transform="translate(0)" fill="#464646" />
      </svg>
    </button>
  </mat-form-field>

  <br><br>

  <div class="dropdown-search">
    <mat-form-field class="custom-dropdown">
      <mat-select disableRipple [(value)]="selectedSkills">
        <mat-option value="0">Requirements</mat-option>
        <mat-option [value]="option.value" *ngFor="let option of skills">{{ option.viewValue }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="custom-input custom-search-input" floatLabel="never" floatPlaceholder="never">
      <mat-label>Search</mat-label>
      <input matInput type="text">
      <button matSuffix>
        <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
          <path id="Path_690" data-name="Path 690"
            d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
            transform="translate(0)" fill="#464646" />
        </svg>
      </button>
    </mat-form-field>
  </div>

  <br>

  <div class="field-box">
    <label for="custom-input" class="custom-label">First Name</label>
    <mat-form-field class="custom-input" floatLabel="never" floatPlaceholder="never">
      <mat-label>Type here</mat-label>
      <input matInput id="custom-input" placeholder="Type here">
    </mat-form-field>
  </div>

  <br>

  <mat-form-field class="custom-input" floatLabel="never" floatPlaceholder="never">
    <mat-label>Type here</mat-label>
    <input matInput placeholder="Type here">
  </mat-form-field>

  <br>

  <mat-form-field class="custom-input textarea" floatLabel="never" floatPlaceholder="never">
    <mat-label>Type here</mat-label>
    <textarea matInput placeholder="Type here"></textarea>
  </mat-form-field>

  <br>

  <mat-form-field class="custom-input" floatLabel="never" floatPlaceholder="never">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" readonly>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <br>

  <mat-form-field class="custom-dropdown form">
    <mat-select disableRipple [(value)]="selectedSkills">
      <mat-option value="0">All</mat-option>
      <mat-option [value]="option.value" *ngFor="let option of skills">{{ option.viewValue }}</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-icon-button [matMenuTriggerFor]="menu" class="custom-dropdown custom-menu">
    <svg class="icon" id="more_vert_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
      viewBox="0 0 24 24">
      <path id="Path_14241" data-name="Path 14241" d="M0,0H24V24H0Z" fill="none" />
      <path id="Path_14242" data-name="Path 14242"
        d="M12,8a2,2,0,1,0-2-2A2.006,2.006,0,0,0,12,8Zm0,2a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Zm0,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,16Z"
        fill="#717682" />
    </svg>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <span>Propose a new time</span>
    </button>
    <button mat-menu-item>
      <span>Cancel Interview</span>
    </button>
  </mat-menu>

  <br>

  <mat-checkbox class="custom-checkbox">Check me!</mat-checkbox>

</div>

<br>

<h1 class="fs-3 fw-bolder m-3">Tab Classes</h1>
<div class="m-3 p-3 border border-2">
  <div class="tab-container">
    <mat-form-field class="custom-input custom-search-input" floatLabel="never" floatPlaceholder="never">
      <mat-label>Search by requirement name</mat-label>
      <input matInput type="text">
      <button matSuffix>
        <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
          <path id="Path_690" data-name="Path 690"
            d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
            transform="translate(0)" fill="#464646" />
        </svg>
      </button>
    </mat-form-field>
    <mat-tab-group mat-align-tabs="start" class="custom-tab-group">
      <mat-tab label="First">Content 1</mat-tab>
      <mat-tab label="Second">Content 2</mat-tab>
      <mat-tab disabled>
        <ng-template mat-tab-label>
          <button class="borderless-btn with-icon">
            <span class="btn-content">
              <svg class="icon left" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                <path id="Path_14128" data-name="Path 14128"
                  d="M18,13H13v5a1,1,0,0,1-2,0V13H6a1,1,0,0,1,0-2h5V6a1,1,0,0,1,2,0v5h5a1,1,0,0,1,0,2Z"
                  transform="translate(-4.5 -4.5)" fill="#595e6c" stroke="rgba(0,0,0,0)" stroke-width="1" />
              </svg>
              Add New Talent
            </span>
          </button>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="tab-container">
    <mat-tab-group mat-align-tabs="start" class="custom-tab-group">
      <mat-tab label="First">Content 1</mat-tab>
      <mat-tab label="Second">Content 2</mat-tab>
    </mat-tab-group>
  </div>
</div>

<br>

<h1 class="fs-3 fw-bolder m-3">Button Classes</h1>
<div class="m-3 p-3 border border-2">
  <button class="primary-btn">ABCD</button>

  <br />

  <button class="primary-btn" disabled>ABCD</button>

  <br /><br />

  <button class="primary-small-btn">ABCD</button>

  <br />

  <button class="primary-small-btn" disabled>ABCD</button>

  <br /><br />

  <button class="secondary-btn">ABCD</button>

  <br />

  <button class="secondary-btn" disabled>ABCD</button>

  <br /><br />

  <button class="secondary-small-btn">ABCD</button>

  <br />

  <button class="secondary-small-btn" disabled>ABCD</button>

  <!-- <br /><br />

  <button class="primary-btn" (click)="UpdateMilestoneReviseDate()">Milestone Revise Date Update</button>

  <br /><br />

  <button class="primary-btn" (click)="UpdateTaskStatus()">Update Task Status</button>


  <br /><br />
  <button class="primary-btn" (click)="ChangeTaskStatusNameForCompleted()">Update Done to Completed</button>
 -->

  <!-- <br /><br />

  <button class="primary-btn" (click)="UpdateOldToNew()">Update Old to New</button>


  <br /><br /> -->

  <!-- <br /><br />

  <button class="primary-btn" (click)="onclick()">Data Transfer</button>


  <br /><br /> -->

  <br /><br />
  <!-- API Calls for MySQLtoPGSQL -->
  <button class="primary-btn my-1" (click)="setSequenceValuePG()">SetSEQPG</button>
  <button class="primary-btn my-1" (click)="setAllColumnToIntegerPG()">SetIntPG</button>
  <button class="primary-btn my-1" (click)="setCityStatetiStatusPG()">SetCityStateStatusPG</button>
  <br /><br />

  <button class="primary-btn with-icon">
    <span class="btn-content">
      ABCD
      <svg class="icon right" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_14090" data-name="Path 14090"
          d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
          transform="translate(-2 -2)" fill="#8a8d97" />
      </svg>
    </span>
  </button>

  <br />

  <button class="primary-btn with-icon" disabled>
    <span class="btn-content">
      ABCD
      <svg class="icon right" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_14090" data-name="Path 14090"
          d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
          transform="translate(-2 -2)" fill="#8a8d97" />
      </svg>
    </span>
  </button>

  <br /><br />

  <button class="secondary-btn with-icon">
    <span class="btn-content">
      <svg class="icon left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_14090" data-name="Path 14090"
          d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
          transform="translate(-2 -2)" fill="#8a8d97" />
      </svg>
      ABCD
    </span>
  </button>

  <br />

  <button class="secondary-btn with-icon" disabled>
    <span class="btn-content">
      <svg class="icon left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_14090" data-name="Path 14090"
          d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
          transform="translate(-2 -2)" fill="#8a8d97" />
      </svg>
      ABCD
    </span>
  </button>

  <br /><br />

  <button class="gray-small-btn">ABCD</button>

  <br />

  <button class="gray-small-btn" disabled>ABCD</button>

  <br />

  <button class="gray-small-btn with-icon">
    <span class="btn-content">
      <svg class="icon left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_14090" data-name="Path 14090"
          d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
          transform="translate(-2 -2)" fill="#8a8d97" />
      </svg>
      ABCD
    </span>
  </button>

  <br />

  <button class="gray-small-btn with-icon" disabled>
    <span class="btn-content">
      <svg class="icon left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_14090" data-name="Path 14090"
          d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
          transform="translate(-2 -2)" fill="#8a8d97" />
      </svg>
      ABCD
    </span>
  </button>

  <br><br>

  <button class="talent-button">
    <span class="btn-content">
      <span class="icon-background left">
        <svg class="icon" id="groups_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
          viewBox="0 0 24 24">
          <rect id="Rectangle_3442" data-name="Rectangle 3442" width="24" height="24" fill="none" />
          <g id="Group_4968" data-name="Group 4968" transform="translate(0 6)">
            <path id="Path_14143" data-name="Path 14143"
              d="M12,12.75a10.611,10.611,0,0,1,4.24.9A2.984,2.984,0,0,1,18,16.38V17a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1v-.61a2.963,2.963,0,0,1,1.76-2.73A10.44,10.44,0,0,1,12,12.75ZM4,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,4,13Zm1.13,1.1A6.983,6.983,0,0,0,4,14a6.95,6.95,0,0,0-2.78.58A2.011,2.011,0,0,0,0,16.43V17a1,1,0,0,0,1,1H4.5V16.39A4.5,4.5,0,0,1,5.13,14.1ZM20,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,20,13Zm4,3.43a2.011,2.011,0,0,0-1.22-1.85,6.8,6.8,0,0,0-3.91-.48,4.5,4.5,0,0,1,.63,2.29V18H23a1,1,0,0,0,1-1ZM12,6A3,3,0,1,1,9,9,3,3,0,0,1,12,6Z"
              transform="translate(0 -6)" fill="#8a8d97" />
          </g>
        </svg>
      </span>
      No Talent Added
    </span>
  </button>

  <br />

  <button class="talent-button active">
    <span class="btn-content">
      <span class="icon-background left">
        <svg class="icon" id="groups_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
          viewBox="0 0 24 24">
          <rect id="Rectangle_3442" data-name="Rectangle 3442" width="24" height="24" fill="none" />
          <g id="Group_4968" data-name="Group 4968" transform="translate(0 6)">
            <path id="Path_14143" data-name="Path 14143"
              d="M12,12.75a10.611,10.611,0,0,1,4.24.9A2.984,2.984,0,0,1,18,16.38V17a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1v-.61a2.963,2.963,0,0,1,1.76-2.73A10.44,10.44,0,0,1,12,12.75ZM4,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,4,13Zm1.13,1.1A6.983,6.983,0,0,0,4,14a6.95,6.95,0,0,0-2.78.58A2.011,2.011,0,0,0,0,16.43V17a1,1,0,0,0,1,1H4.5V16.39A4.5,4.5,0,0,1,5.13,14.1ZM20,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,20,13Zm4,3.43a2.011,2.011,0,0,0-1.22-1.85,6.8,6.8,0,0,0-3.91-.48,4.5,4.5,0,0,1,.63,2.29V18H23a1,1,0,0,0,1-1ZM12,6A3,3,0,1,1,9,9,3,3,0,0,1,12,6Z"
              transform="translate(0 -6)" fill="#8a8d97" />
          </g>
        </svg>
      </span>
      1 Talent
    </span>
  </button>

</div>

<br>

<h1 class="fs-3 fw-bolder m-3">Status Classes</h1>
<div class="m-3 p-3 border border-2">

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.224" viewBox="0 0 16 14.224">
      <g id="Group_5814" data-name="Group 5814" transform="translate(-3 -3.998)">
        <g id="Group_5815" data-name="Group 5815" transform="translate(3 3.997)">
          <path id="Path_14263" data-name="Path 14263"
            d="M14.556,16,11,19.556h7.111a1.783,1.783,0,0,0,1.778-1.778h0A1.783,1.783,0,0,0,18.111,16Z"
            transform="translate(-3.889 -5.331)" fill="#58b47c" />
          <path id="Path_14264" data-name="Path 14264"
            d="M11.053,7.19l-7.8,7.8A.9.9,0,0,0,3,15.608v2.08a.892.892,0,0,0,.889.889h2.08a.918.918,0,0,0,.631-.258l7.8-7.8Z"
            transform="translate(-3 -4.352)" fill="#58b47c" />
          <path id="Path_14265" data-name="Path 14265"
            d="M18.09,7.591a.885.885,0,0,0,0-1.253l-2.08-2.08a.885.885,0,0,0-1.253,0L13.13,5.884l3.333,3.333Z"
            transform="translate(-4.126 -3.997)" fill="#58b47c" />
        </g>
      </g>
    </svg>
    <label>Signed</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Path_14245" data-name="Path 14245"
        d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2ZM7.832,13.432,4.96,10.56A.8.8,0,1,1,6.088,9.432l2.312,2.3,5.5-5.5A.8.8,0,0,1,15.032,7.36L8.96,13.432a.8.8,0,0,1-1.128,0Z"
        transform="translate(-2 -2)" fill="#717682" />
    </svg>
    <label>Completed</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Path_14246" data-name="Path 14246"
        d="M10,2a8,8,0,1,0,8,8A7.993,7.993,0,0,0,10,2Zm3.44,11.44a.8.8,0,0,1-1.128,0L10,11.128,7.688,13.44A.8.8,0,0,1,6.56,12.312L8.872,10,6.56,7.688A.8.8,0,0,1,7.688,6.56L10,8.872,12.312,6.56A.8.8,0,0,1,13.44,7.688L11.128,10l2.312,2.312a.812.812,0,0,1,0,1.128Z"
        transform="translate(-2 -2)" fill="#e94f57" />
    </svg>
    <label>Dismissed</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Path_14245" data-name="Path 14245"
        d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2ZM7.832,13.432,4.96,10.56A.8.8,0,1,1,6.088,9.432l2.312,2.3,5.5-5.5A.8.8,0,0,1,15.032,7.36L8.96,13.432a.8.8,0,0,1-1.128,0Z"
        transform="translate(-2 -2)" fill="#48acf0" />
    </svg>
    <label>Active</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.275" viewBox="0 0 16 14.275">
      <path id="warning_black_24dp"
        d="M4.148,18.268h12.64a1.678,1.678,0,0,0,1.452-2.518L11.921,4.83a1.678,1.678,0,0,0-2.9,0L2.7,15.75A1.678,1.678,0,0,0,4.148,18.268Zm6.32-5.875a.842.842,0,0,1-.839-.839V9.874a.839.839,0,1,1,1.679,0v1.679A.842.842,0,0,1,10.469,12.392Zm.839,3.357H9.629V14.071h1.679Z"
        transform="translate(-2.469 -3.993)" fill="#e94f57" />
    </svg>
    <label>Payment Due</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Path_14352" data-name="Path 14352"
        d="M17.222,4.778H15.444V3.889A.892.892,0,0,0,14.556,3H7.444a.892.892,0,0,0-.889.889v.889H4.778A1.783,1.783,0,0,0,3,6.556v.889a4.437,4.437,0,0,0,3.9,4.391,4.453,4.453,0,0,0,3.209,2.631v2.756H7.444a.892.892,0,0,0-.889.889h0A.892.892,0,0,0,7.444,19h7.111a.892.892,0,0,0,.889-.889h0a.892.892,0,0,0-.889-.889H11.889V14.467A4.453,4.453,0,0,0,15.1,11.836,4.437,4.437,0,0,0,19,7.444V6.556A1.783,1.783,0,0,0,17.222,4.778ZM4.778,7.444V6.556H6.556v3.4A2.676,2.676,0,0,1,4.778,7.444Zm12.444,0a2.676,2.676,0,0,1-1.778,2.507v-3.4h1.778Z"
        transform="translate(-3 -3)" fill="#ffbc42" />
    </svg>
    <label>Earned</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9.092" viewBox="0 0 16 9.092">
      <path id="Path_14358" data-name="Path 14358"
        d="M3.674,8.92l6.906,6.906a.906.906,0,0,0,1.283,0L17.6,10.094v3.276a.91.91,0,1,0,1.82,0V7.91a.913.913,0,0,0-.91-.91H13.046a.91.91,0,0,0,0,1.82h3.266l-5.086,5.086L4.957,7.637a.906.906,0,0,0-1.283,0A.923.923,0,0,0,3.674,8.92Z"
        transform="translate(-3.415 -7)" fill="#e94f57" />
    </svg>
    <label>Missed</label>
  </div>

  <br>

  <div class="status-badge">
    <svg id="waving_hand_black_24dp" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <rect id="Rectangle_3745" data-name="Rectangle 3745" width="16" height="16" fill="none" />
      <path id="Path_14314" data-name="Path 14314"
        d="M15.665,11.667a4,4,0,0,1-4,4v-1a3,3,0,0,0,3-3ZM1,5A4,4,0,0,1,5,1V2A3,3,0,0,0,2,5ZM5.672,3.213,2.605,6.28a5.5,5.5,0,0,0,7.78,7.78L15.1,9.347a.834.834,0,1,0-1.18-1.18l-2.947,2.947L10.5,10.64l4.36-4.36a.834.834,0,1,0-1.18-1.18L9.792,8.987l-.473-.473L13.912,3.92a.834.834,0,1,0-1.18-1.18L8.138,7.333l-.46-.467,3.653-3.653a.834.834,0,0,0-1.18-1.18l-5.08,5.08a2.668,2.668,0,0,1-.22,3.52l-.473-.473a2,2,0,0,0,0-2.827L4.145,7.1,6.858,4.387a.833.833,0,0,0,0-1.18A.847.847,0,0,0,5.672,3.213Z"
        transform="translate(-0.332 -0.333)" fill="#48acf0" />
    </svg>
    <label>Open</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Path_14505" data-name="Path 14505"
        d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2ZM8.4,14l-4-4L5.528,8.872,8.4,11.736l6.072-6.072L15.6,6.8Z"
        transform="translate(-2 -2)" fill="#58b47c" />
    </svg>
    <label>Hired</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Path_14246" data-name="Path 14246"
        d="M10,2a8,8,0,1,0,8,8A7.993,7.993,0,0,0,10,2Zm3.44,11.44a.8.8,0,0,1-1.128,0L10,11.128,7.688,13.44A.8.8,0,0,1,6.56,12.312L8.872,10,6.56,7.688A.8.8,0,0,1,7.688,6.56L10,8.872,12.312,6.56A.8.8,0,0,1,13.44,7.688L11.128,10l2.312,2.312a.812.812,0,0,1,0,1.128Z"
        transform="translate(-2 -2)" fill="#e5a93b" />
    </svg>
    <label>Cancelled by You</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Path_14246" data-name="Path 14246"
        d="M10,2a8,8,0,1,0,8,8A7.993,7.993,0,0,0,10,2Zm3.44,11.44a.8.8,0,0,1-1.128,0L10,11.128,7.688,13.44A.8.8,0,0,1,6.56,12.312L8.872,10,6.56,7.688A.8.8,0,0,1,7.688,6.56L10,8.872,12.312,6.56A.8.8,0,0,1,13.44,7.688L11.128,10l2.312,2.312a.812.812,0,0,1,0,1.128Z"
        transform="translate(-2 -2)" fill="#e94f57" />
    </svg>
    <label>Rejected</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="14.4" height="16" viewBox="0 0 14.4 16">
      <path id="Path_14485" data-name="Path 14485"
        d="M15.8,2.6H12.456a2.39,2.39,0,0,0-4.512,0H4.6A1.6,1.6,0,0,0,3,4.2V15.4A1.6,1.6,0,0,0,4.6,17H15.8a1.6,1.6,0,0,0,1.6-1.6V4.2A1.6,1.6,0,0,0,15.8,2.6Zm-5.6,0a.8.8,0,1,1-.8.8A.8.8,0,0,1,10.2,2.6ZM8.6,13.8,5.4,10.6,6.528,9.472,8.6,11.536l5.272-5.272L15,7.4Z"
        transform="translate(-3 -1)" fill="#48acf0" />
    </svg>
    <label>Contracted</label>
  </div>

  <br>

  <div class="status-badge">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.8" viewBox="0 0 16 12.8">
      <g id="Group_6429" data-name="Group 6429" transform="translate(-2 -4)">
        <path id="Path_14223" data-name="Path 14223"
          d="M14.8,9.184V5.6A1.6,1.6,0,0,0,13.2,4H3.6A1.6,1.6,0,0,0,2,5.6v9.6a1.6,1.6,0,0,0,1.6,1.6h9.6a1.6,1.6,0,0,0,1.6-1.6V11.616L18,14.8V6ZM8.4,7.2A1.6,1.6,0,1,1,6.8,8.8,1.6,1.6,0,0,1,8.4,7.2Zm3.2,6.4H5.2v-.456a1.609,1.609,0,0,1,.976-1.48,5.562,5.562,0,0,1,4.448,0,1.609,1.609,0,0,1,.976,1.48Z"
          fill="#ffbc42" />
      </g>
    </svg>
    <label>Interviewing</label>
  </div>

</div>

<br>

<h1 class="fs-3 fw-bolder m-3">Action Button Classes</h1>
<div class="m-3 p-3 border border-2">

  <button class="action-btn bg-blue">
    <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="12.5" viewBox="0 0 18.333 12.5">
      <path id="Path_9273" data-name="Path 9273"
        d="M10.167,4.5A9.856,9.856,0,0,0,1,10.75a9.847,9.847,0,0,0,18.333,0A9.856,9.856,0,0,0,10.167,4.5Zm0,10.417a4.167,4.167,0,1,1,4.167-4.167A4.168,4.168,0,0,1,10.167,14.917Zm0-6.667a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,10.167,8.25Z"
        transform="translate(-1 -4.5)" fill="#48acf0" />
    </svg>
  </button>

  <br>

  <button class="action-btn bg-green">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="check_circle_FILL1_wght600_GRAD200_opsz40"
        d="M9.142,13.987,14.7,8.41,13.442,7.132l-4.3,4.3L7.02,9.311,5.743,10.588ZM10.25,18.25a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z"
        transform="translate(-2.25 -2.25)" fill="#58b47c" />
    </svg>
  </button>

  <br>

  <button class="action-btn bg-red">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="cancel_FILL1_wght600_GRAD200_opsz40"
        d="M7.546,14.025l2.7-2.685,2.7,2.685,1.07-1.07-2.685-2.7,2.685-2.7-1.07-1.07-2.7,2.685-2.7-2.685-1.07,1.07,2.685,2.7-2.685,2.7Zm2.7,4.225a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z"
        transform="translate(-2.25 -2.25)" fill="#ee6060" />
    </svg>
  </button>

  <br>

  <button class="action-btn bg-yellow">
    <svg xmlns="http://www.w3.org/2000/svg" width="12.954" height="12.965" viewBox="0 0 12.954 12.965">
      <path id="Path_14498" data-name="Path 14498"
        d="M15.06,5.9a6.484,6.484,0,1,0,1.685,6.2H15.06a4.859,4.859,0,1,1-4.577-6.482A4.791,4.791,0,0,1,13.9,7.063L11.293,9.672h5.671V4Z"
        transform="translate(-4.01 -4)" fill="#e5a93b" />
    </svg>
  </button>

  <br>

  <button class="action-btn bg-blue">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.8" viewBox="0 0 16 12.8">
      <path id="Path_14499" data-name="Path 14499"
        d="M14.8,9.184V5.6A1.6,1.6,0,0,0,13.2,4H3.6A1.6,1.6,0,0,0,2,5.6v9.6a1.6,1.6,0,0,0,1.6,1.6h9.6a1.6,1.6,0,0,0,1.6-1.6V11.616L18,14.8V6ZM8.4,7.2A1.6,1.6,0,1,1,6.8,8.8,1.6,1.6,0,0,1,8.4,7.2Zm3.2,6.4H5.2v-.456a1.609,1.609,0,0,1,.976-1.48,5.562,5.562,0,0,1,4.448,0,1.609,1.609,0,0,1,.976,1.48Z"
        transform="translate(-2 -4)" fill="#48acf0" />
    </svg>
  </button>

  <br>

  <button class="action-btn bg-yellow">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.998" viewBox="0 0 16 15.998">
      <path id="Path_14500" data-name="Path 14500"
        d="M12.83,8.35l.818.818L5.6,17.22H4.778V16.4L12.83,8.35M16.029,3a.89.89,0,0,0-.622.258L13.781,4.884l3.333,3.333L18.74,6.591a.885.885,0,0,0,0-1.253l-2.08-2.08A.873.873,0,0,0,16.029,3Zm-3.2,2.835L3,15.665V19H6.333l9.83-9.83L12.83,5.835Z"
        transform="translate(-3 -3)" fill="#e5a93b" />
    </svg>
  </button>

  <br>

  <button class="action-btn bg-red">
    <svg xmlns="http://www.w3.org/2000/svg" width="13.333" height="15" viewBox="0 0 13.333 15">
      <path id="delete_FILL0_wght400_GRAD0_opsz48"
        d="M10.1,21a1.282,1.282,0,0,1-1.25-1.25V7.875H8.625a.625.625,0,1,1,0-1.25h3.292A.606.606,0,0,1,12.542,6h4.25a.606.606,0,0,1,.625.625h3.292a.625.625,0,1,1,0,1.25h-.229V19.75A1.282,1.282,0,0,1,19.229,21Zm0-13.125V19.75h9.125V7.875Zm2.208,9.458a.625.625,0,1,0,1.25,0V10.271a.625.625,0,1,0-1.25,0Zm3.458,0a.625.625,0,1,0,1.25,0V10.271a.625.625,0,1,0-1.25,0ZM10.1,7.875v0Z"
        transform="translate(-8 -6)" fill="#d2474e" />
    </svg>
  </button>

</div>

<br>

<h1 class="fs-3 fw-bolder m-3">Toggle Button Classes</h1>
<div class="m-3 p-3 border border-2">

  <label class="toggle-btn">
    <input type="checkbox">
    <span class="slider round"></span>
  </label>

  <label class="toggle-btn">
    <input type="checkbox" checked>
    <span class="slider round"></span>
  </label>

</div>

<br>

<h1 class="fs-3 fw-bolder m-3">Status Tag Classes</h1>
<div class="m-3 p-3 border border-2">

  <label class="status-tag">
    Tags
  </label>

  <br>

  <label class="status-tag bg-blue">
    Web Developer
  </label>

  <br>

  <label class="status-tag bg-green">
    Success
  </label>

  <br>

  <label class="status-tag bg-red">
    Reject
  </label>

  <br>

  <label class="status-tag bg-yellow">
    Pending
  </label>

  <br>
  <br>

  <h6>Small Tags</h6>

  <br>

  <label class="status-tag small-tag">
    Primary
  </label>

  <br>

  <label class="status-tag bg-blue small-tag">
    Secondary
  </label>

</div>

<div>
  <button (click)="valueAdditionDataTransfer()">value Addition DataTransfer</button>
</div>

<div class="mt-3">
  <button (click)="activityDataTransfer()">Correct_AC_Type_In_Activities</button>
</div>
