<div name="certifications-box" id="certifications-box" class="col-12">
  <div name="certifications-card-container" id="certifications-card-container" class="card-container">
    <div name="certifications-card-header" id="certifications-card-header" class="card-header header-bottom-spacing">
      <div name="certifications-title-section" id="certifications-title-section" class="title-section custom-title">
        <div class="d-flex w-100 custom-title-flex">
          <div class="order-1">
            <h4 name="certifications-card-title" id="certifications-card-title" class="card-title award-margin">Certification</h4>
          </div>
          <div class="order-2" placement="right auto" [ngbTooltip]="
          addTalentCertificationsActionPermission ? '' : 'Not Authorized'
        " [ngClass]="
        addTalentCertificationsActionPermission ? '' : 'unauthorized-cursor'
        ">
            <button name="certifications-card-edit-button" id="certifications-card-edit-button" class="card-edit-button ecard-add-button" type="button" [disabled]="checkCertiApplicable" (click)="CertificationModal(certification,addTalentCertificationsActionPermission)" [ngClass]="checkCertiApplicable ? 'opacity-4' : ''"  [ngClass]="
            addTalentCertificationsActionPermission ? '' : 'unauthorized-action'
          ">
              <img class="card-add-button-icon" [src]="imagePath + 'green-add.svg'" alt="add-button">Add New
            </button>
          </div>
        </div>
        <div class="d-flex w-100 custom-title-flex-end">
          <div class="checkbox-main">
            <mat-checkbox class="custom-checkbox" [(ngModel)]="disableCertification" [formControl]="checkCertificate" (change)="CheckApplicable($event)" *ngIf="uploadedCertificateList.length <= 0" [checked]="checkCertiApplicable">Check if not applicable</mat-checkbox>
          </div>
          <div name="certifications-incomplete-status-section" id="certifications-incomplete-status-section" class="status-section status-margin">
            <div name="certifications-incomplete-status-box" id="certifications-incomplete-status-box" class="status-box" *ngIf="!certificateDetailsCardCompleted">
              <img id="certifications-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'" alt="status-icon">
              <label name="certifications-incomplete-status-text" id="certifications-incomplete-status-text" class="status-text incomplete">Pending</label>
            </div>
            <div name="certifications-completed-status-box" id="certifications-completed-status-box" class="status-box" *ngIf="certificateDetailsCardCompleted">
              <img id="certifications-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'" alt="status-icon">
              <label name="certifications-completed-status-text" id="certifications-completed-status-text" class="status-icon" class="status-text completed">Completed</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div name="certifications-card" id="certifications-card" class="card" [ngClass]="[(certificateDetailsCardCompleted == true)?'completed':'', (certificateDetailsCardCompleted == false || checkCertiApplicable == false ) ? 'incomplete':'', checkCertiApplicable ? 'completed opacity-4' : '' ]">
      <perfect-scrollbar>
        <div name="awards-card-content" id="awards-card-content" class="card-content scroll-card" *ngIf="checkCertiApplicable">
          <div name="awards-text-box-with-icon" id="awards-text-box-with-icon" class="text-box-with-icon">
            <img id="awards-icon" class="icon" [src]="imagePath + 'info-icon.svg'" alt="info-tick">
            <label name="awards-text" id="awards-text" class="text">Not Applicable</label>
          </div>
        </div>
        <div class="card-content" *ngIf="uploadedCertificateList.length == 0  && !checkCertiApplicable">
          <label class="text">No Certification Found</label>
        </div>
        <div name="certifications-card-content" id="certifications-card-content" class="card-content" *ngIf="uploadedCertificateList.length > 0">
          <div name="certifications-text-box-with-icon" id="certifications-text-box-with-icon" class="text-box-with-icon" *ngFor = "let item of uploadedCertificateList">
            <img id="certifications-icon" class="icon" [src]="imagePath + 'green-tick.svg'" alt="green-tick">
            <label name="certifications-text" id="certifications-text" class="text">{{ item.vName }}</label>
            <button name="certifications-card-edit-button" id="certifications-card-edit-button" class="card-edit-button" type="button" (click)="CertificationEditModal(certificationEdit,item,editTalentCertificationsActionPermission)" [ngClass]="
            editTalentCertificationsActionPermission ? '' : 'unauthorized-cursor'" placement="right auto" [ngbTooltip]="editTalentCertificationsActionPermission ? '' : 'Not Authorized'">
              <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
            </button>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>

<ng-template #certification let-c="close" let-d="dismiss">
  <div name="certification-modal-header" id="certification-modal-header" class="modal-header">
    <h1 name="certification-modal-title" id="certification-modal-title" class="modal-title">Add Certification</h1>
  </div>
  <perfect-scrollbar>
    <div name="certification-modal-body" id="certification-modal-body" class="modal-body modal-scroll">
      <form name="certificationForm" id="certificationForm" class="form pb-0" [formGroup]="certificationForm" (ngSubmit)="UploadCertficate()">
        <div name="certification-modal-row" id="certification-modal-row" class="row">
          <div name="certification-col-1" id="certification-col-1" class="col-12 col-md-6">
            <label for="vCerId" name="certification-col-1-label" id="certification-col-1-label" class="custom-label">Certification Type <span class="text-danger">*</span></label>
            <img class="info-tooltip mx-2" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="top"
          ngbTooltip="Select 'Other' option, if option you want is not there in the list" placement="right"/>
            <mat-form-field hideRequiredMarker name="education-col-7-input" id="education-col-7-input" class="custom-dropdown custom-input form" floatLabel="never" floatPlaceholder="never">
              <input type="text" placeholder="Select Certificate" matInput formControlName="vCerId" [matAutocomplete]="autoCerti1" required>
              <mat-autocomplete autoActiveFirstOption #autoCerti1="matAutocomplete">
                <mat-option *ngFor="let option of filteredCerti | async" [value]="option.vName" (onSelectionChange)="GetCertiId(option?.iMasterCerId);CertificateChange($event)" title="{{option?.vName}}">
                  {{option?.vName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="certification-col-1-error-0" id="certification-col-1-error-0" class="reduce-bottom-space" *ngIf="certificationForm.controls['vCerId'].invalid">
                <mat-error name="certification-col-1-error-1" id="certification-col-1-error-1" class="reduce-bottom-space" *ngIf="certificationForm.controls['vCerId'].errors!.required">
                  Certification Type is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <label for="vCerDocument" name="certification-video-label" id="certification-video-label" class="custom-label">Upload Document <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="certification-video-input" id="certification-video-input" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box" *ngIf="vCerDocument">
                    <perfect-scrollbar [config]="config">
                      <div class="file">
                        {{ vCerDocument.name }} <button type="button" name="button" (click)="crossClick()" class="btn"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="certification-video-input-vVideo-1" id="certification-video-input-vVideo-1" matInput readonly formControlName="vCerDocument" [value]="vCerDocument ? ' ' : ''" placeholder="Upload File" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="certification-video-input-vVideo-2"
                id="vCerDocument"
                class="file-upload-input"
                (change)="FileBrowseHandler($event)"
                formControlName="vCerDocument"
                accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel"
              />
              <button name="certification-video-input-upload-btn" id="certification-video-input-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <mat-error class="reduce-bottom-space" *ngIf="certificationForm.controls['vCerDocument'].invalid">
                <mat-error class="reduce-bottom-space" *ngIf="certificationForm.controls['vCerDocument'].errors!.required">
                  Document is required
                </mat-error>
              </mat-error>
              <mat-hint class="text">Max 4MB</mat-hint>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf = "certificationForm.controls['vCerId'].value == 'Other'">
            <label for="vOther" name="other-col-2-label" id="other-col-2-label" class="custom-label">Other <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="other-col-2-input" id="other-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="vOther" formControlName="vOther" placeholder="Other...">
              <mat-error name="other-col-2-error-0" id="other-col-2-error-0" class="reduce-bottom-space" *ngIf="certificationForm.controls['vOther'].invalid">
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="certificationForm.controls['vOther'].errors!.required">
                  Certificate name is required
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="certificationForm.controls['vOther'].errors!.minlength">
                  Minimum 5 characters required
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="certificationForm.controls['vOther'].errors!.maxlength">
                  Minimum 50 characters allow
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="certificationForm.controls['vOther'].errors!.pattern">
                  Please enter valid text
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <div name="certification-modal-footer" id="certification-modal-footer" class="modal-footer">
    <button type="submit" name="certification-modal-submit-btn" id="certification-modal-submit-btn" class="btn btn-active" form="certificationForm">Submit</button>
    <button type="button" name="certification-modal-cancel-btn" id="certification-modal-cancel-btn" class="btn btn-outline-active" (click)="d('Cross click')">Cancel</button>
  </div>
</ng-template>

<ng-template #certificationEdit let-c="close" let-d="dismiss">
  <div name="certification-modal-header" id="certification-modal-header" class="modal-header with-button">
    <h1 name="certification-modal-title" id="certification-modal-title" class="modal-title">Edit Certification</h1>
    <button type="button" name="button" class="delete-btn" (click) = DeleteUploadedCertificate()>
      <img [src]="imagePath + 'delete_image.svg'" alt="Delete" class="delete-img"> Remove
    </button>
  </div>
  <perfect-scrollbar>
    <div name="certification-modal-body" id="certification-modal-body" class="modal-body modal-scroll">
      <form name="certificationEditForm" id="certificationEditForm" class="form pb-0" [formGroup]="certificationEditForm" (ngSubmit)="EditCertification()">
        <div name="certification-modal-row" id="certification-modal-row" class="row">
          <div class="col-12 col-md-6">
            <label for="vType" name="certification-col-1-label" id="certification-col-1-label" class="custom-label">Certification Type <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="certification-col-1-select" id="certification-col-1-select" class="custom-input">
              <input type="text" matInput id="vType" formControlName="vType" placeholder="" [(ngModel)]="certificateName" readonly>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <label for="vCerDocument" name="certification-video-label" id="certification-video-label" class="custom-label">Upload Document <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="certification-video-input" id="certification-video-input" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngIf= "vCerDocument" >
                        {{ vCerDocument.name }} <button type="button" name="button" (click)="crossClick()" class="btn"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="certification-video-input-vVideo-1" id="certification-video-input-vVideo-1" matInput readonly formControlName="vCerDocument" [value]="vCerDocument ? ' ' : ''" placeholder="Upload File" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="certification-video-input-vVideo-2"
                id="vCerDocument"
                class="file-upload-input"
                (change)="FileBrowseHandler($event)"
                formControlName="vCerDocument"
                accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel"
              />
              <button name="certification-video-input-upload-btn" id="certification-video-input-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <mat-error class="reduce-bottom-space" *ngIf="certificationEditForm.controls['vCerDocument'].invalid">
                <mat-error class="reduce-bottom-space" *ngIf="certificationEditForm.controls['vCerDocument'].errors!.required">
                  Document is required
                </mat-error>
              </mat-error>
              <mat-hint class="text">Max 4MB</mat-hint>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf = "certificateName == 'Other'">
            <label for="vOther" name="other-col-2-label" id="other-col-2-label" class="custom-label">Other <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="other-col-2-input" id="other-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="vOther" formControlName="vOther" placeholder="Other...">
              <mat-error name="other-col-2-error-0" id="other-col-2-error-0" class="reduce-bottom-space" *ngIf="certificationEditForm.controls['vOther'].invalid">
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="certificationEditForm.controls['vOther'].errors!.required">
                  Award name is required
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="certificationEditForm.controls['vOther'].errors!.minlength">
                  Minimum 5 characters required
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="certificationEditForm.controls['vOther'].errors!.maxlength">
                  Minimum 50 characters allow
                </mat-error>
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="certificationEditForm.controls['vOther'].errors!.pattern">
                  Please enter valid text
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="certification-class" id="certification-class" class="col-12 mt-2">
            <div name="certification-class-1" id="certification-1" class="image-display-container pb-2" *ngFor = " let item of specificCertificateList; let i = index;">
              <div name="award-1" id="award-1" class="image-name">
                <span>{{i+1}})</span>
                <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                <a [href]="item.cerUrl" target="_blank">{{ item.vCerUrl }}</a> <span *ngIf = "item.vValue" class="text-dark"> - {{ item.vValue }} </span>
              </div>
              <button type="button" name="button" id="award-1-btn" (click) = "DeleteTypeofCertificate(item.iUserCertificateId, item.iMasterCerId)">
                <img id="award-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <div name="certification-modal-footer" id="certification-modal-footer" class="modal-footer">
    <button type="submit" name="certification-modal-submit-btn" id="certification-modal-submit-btn" class="btn btn-active" form="certificationEditForm">Submit</button>
    <button type="button" name="certification-modal-cancel-btn" id="certification-modal-cancel-btn" class="btn btn-outline-active" (click)="d('Cross click')">Cancel</button>
  </div>
</ng-template>
