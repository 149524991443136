import { Component, OnInit } from '@angular/core';
import { NgxLoaderService } from './modules/ngxloader.service';
import { UserPermissionService } from "./utils/services/user-permission.service";
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit  {
  title = 'super-admin';

  constructor(private appService: AppService,public ngxloaderService: NgxLoaderService, private userPermissionService: UserPermissionService) {
    let accessToken = localStorage.getItem("admin_user_token");
    
    if (accessToken) {
      this.AssignUserPermissions();
    }
  }

  ngOnInit() {
    this.CreateRequiredHeadTags();
  }


  AssignUserPermissions() {
    this.userPermissionService.AssignUserPermissions();
  }

  CreateRequiredHeadTags() {
    this.appService.CreateScriptTagForAmplitude();
  }
}
