import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExectiveDetailsService {

  constructor(
    private http: HttpClient
  ) { }

  GetExecutiveDetails(clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/executive-details?iUserId=${clientUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdatePartnerExecutive(data:any,clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/client/v1/executive-details?iUserId=${clientUserId}`;
        this.http
          .put(apiURL,data)
          .toPromise()
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    return promise;
  }
}