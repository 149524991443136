<div class="page-content admin-section">
  <div class="dashboard">
    <app-ng7-dynamic-breadcrumb></app-ng7-dynamic-breadcrumb>

    <h1 class="admin-title">DataTable</h1>

    <mat-form-field
      class="custom-input custom-search-input my-4"
      floatLabel="never"
      floatPlaceholder="never"
    >
      <mat-label>Search</mat-label>
      <input matInput type="text" (keyup)="applyFilter($event)" />
      <button matSuffix>
        <svg
          id="search_black_24dp"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <path
            id="Path_689"
            data-name="Path 689"
            d="M0,0H20V20H0Z"
            fill="none"
          />
          <path
            id="Path_690"
            data-name="Path 690"
            d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
            transform="translate(0)"
            fill="#464646"
          />
        </svg>
      </button>
    </mat-form-field>
  </div>
  <div class="">
    <table mat-table [dataSource]="dataSource" matSort class="datatable">
      <!-- ID Column -->
      <ng-container matColumnDef="project">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Project</th>
        <td mat-cell *matCellDef="let row">{{ row.project }}</td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let row">{{ row.type }}</td>
      </ng-container>

      <!-- Team Column -->
      <!-- <ng-container matColumnDef="team">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Team </th>
          <td mat-cell *matCellDef="let row"> {{row.team}} </td>
        </ng-container>
     -->
      <!-- Start Date Column -->
      <ng-container matColumnDef="iStartDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color">
          {{ row.iStartDate }}
        </td>
      </ng-container>

      <!-- End Date Column -->
      <ng-container matColumnDef="iEndDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color">
          {{ row.iEndDate }}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let row">
          <mat-chip-list>
            <mat-chip class="blue-chip" *ngIf="row.status==1">
              Success
            </mat-chip>
            <mat-chip class="yellow-chip" *ngIf="row.status==2">
              Warning
            </mat-chip>
            <mat-chip class="red-chip" *ngIf="row.status==3">
              Error
            </mat-chip>
            <mat-chip class="purple-chip" *ngIf="row.status==4">
              Archive
            </mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex flex-wrap">
            <button class="status-btn green-text text-center"
             data-bs-toggle="tooltip"
              data-bs-placement="bottom" title="View">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button class="status-btn blue-text text-center"
             data-bs-toggle="tooltip"
              data-bs-placement="bottom" title="Edit">
              <mat-icon>edit</mat-icon>
            </button>
            <button class="status-btn pink-text"
               data-bs-toggle="tooltip"
              data-bs-placement="bottom" title="Block">
              <span class="material-icons">block</span>
            </button>
            <button class="status-btn lightgreen-bg blue-text"
               data-bs-toggle="tooltip"
              data-bs-placement="bottom" title="Approve">
              <span class="material-icons">check_circle</span>
            </button>
            <span class="status-btn lightgray-bg gray-text"><span class="material-icons">hide_source</span>No Action</span>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns | paginate: { itemsPerPage: TableSize, currentPage: TableCurrentPage, totalItems: data.length}"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>
    <pagination-controls class="custom-pagination" previousLabel="Prev" nextLabel="Next" (pageChange)="TableCurrentPage = $event;paginate(TableCurrentPage)"></pagination-controls>
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
  </div>
</div>
