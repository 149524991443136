import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrimaryDetailsService } from "./primary-details.service";
import { SearchCountryField, CountryISO, PhoneNumberFormat } from "ngx-intl-tel-input";
import { NotificationService } from "../../../../config/notification.service";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { StaticMsg } from "src/app/config/en";
import { environment } from "../../../../../environments/environment";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Permission } from "../../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../../utils/services/user-permission.service";

@Component({
  selector: "client-primary-details",
  templateUrl: "./primary-details.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class ClientPrimaryDetailsComponent implements OnInit {

  @Input() clientUserId:any;
  primaryDetailsToggle:boolean=false;


  editClientPrimaryDetailsActionPermission: boolean = false;

  imagePath = environment.image_path;

  primaryDetailsForm!: FormGroup;
  primaryDetailsSubmitted: any = false;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];

  profileImageError = false;
  maxLength: any = "15";
  countryListArray: any = [];
  countryfilteredOptions!: Observable<any[]>;
  stateListArray: any = [];
  statefilteredOptions!: Observable<any[]>;
  cityListArray: any = [];
  cityfilteredOptions!: Observable<any[]>;
  companyDetailsArray: any = [];
  introDeck: any;
  video: any;
  mediaArray: any = [];

  @ViewChild("uploadFile") fileInput: any;
  fileAttr: any;

  today = new Date();
  countryId: any;
  stateId: any;
  cityId:any;
  tempProfilePic: any;

  constructor(config: NgbModalConfig, private userPermissionService: UserPermissionService, private modalService: NgbModal, private service: PrimaryDetailsService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService) {
    const EditClientPrimaryDetailsAction: Permission =  Permission.EditClientPrimaryDetailsAction;

    if (this.userPermissionService.isAuthorizedFor(EditClientPrimaryDetailsAction)) {
      this.editClientPrimaryDetailsActionPermission = true;
    }

    
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });

    this.GetCountryList();
    this.GetPrimaryDetails();

    this.primaryDetailsForm = new FormGroup({
      newImage: new FormControl(""),
      vCompanyName: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46)]),
      vEmailId: new FormControl("", [Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]),
      vAltEmailId: new FormControl("", [Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]),
      vDialCode: new FormControl(""),
      vPhoneNo: new FormControl("",[Validators.required, Validators.minLength(7), Validators.maxLength(15)]),
      vCompanyWebsiteUrl: new FormControl("", [Validators.required, Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]),
      vAddress: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(255)]),
      vCountryName: new FormControl(""),
      vStateName: new FormControl(""),
      vCityName: new FormControl(""),
      iCountryId: new FormControl("", [Validators.required]),
      iStateId: new FormControl("", [Validators.required]),
      iCityId: new FormControl("", [Validators.required]),
      iZipCode: new FormControl("", [Validators.required, Validators.pattern("^[a-zA-z0-9]+$"), Validators.minLength(5), Validators.maxLength(10)]),
      iCompanyFoundedDate: new FormControl("", [Validators.required]),
      displayCompanyFoundedDate: new FormControl(""),
      iCompanyBio: new FormControl("", [Validators.required, Validators.minLength(150)]),
      txLinkedinUrl: new FormControl("", [Validators.required]),
      txFacebookUrl: new FormControl("", []),
      txTwitterUrl: new FormControl("", []),
      txInstagramUrl: new FormControl("", []),
    });

    this.countryfilteredOptions = this.primaryDetailsForm.controls["iCountryId"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value,this.countryListArray,'vName') : this.countryListArray.slice()))
    );

    this.statefilteredOptions = this.primaryDetailsForm.controls["iStateId"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value,this.stateListArray,'vName') : this.stateListArray.slice()))
    );
    
    this.cityfilteredOptions = this.primaryDetailsForm.controls["iCityId"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value,this.cityListArray,'vName') : this.cityListArray.slice()))
    );
  }

  private _filter(value: string,array:any,key:any): string[] {
    let filterValue: string;
    if (value) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = "";
    }

    return array.filter((option: any) => option[key].toLowerCase().indexOf(filterValue) === 0);
  }

  GetCountryList() {
    this.service.GetCountryList().then((result: any) => {
      if (result.code == 200) {
        this.countryListArray = result["data"];
      }
    });
  }

  GetStateListClick(countryId: any,fromHtml?:boolean) {
    if(fromHtml){
      this.primaryDetailsForm.controls['iStateId'].setValue('');
      this.primaryDetailsForm.controls['iCityId'].setValue('');
      this.stateId=undefined;
      this.cityId=undefined;
      this.cityfilteredOptions = this.primaryDetailsForm.controls["iCityId"].valueChanges.pipe(
        startWith(""),
        map((value: string) => (value ? this._filter(value,this.cityListArray,'vName') : this.cityListArray.slice()))
      );
    }
    if (countryId != "default") {
      if(this.stateId=='' || this.stateId==undefined){
        this.primaryDetailsForm.patchValue({
          iCityId: "",
          iStateId: "",
        });
      }
      this.countryId=countryId;
      this.cityListArray = [];
      this.GetStateList(countryId);
    }
  }

  GetStateList(countryId: any) {
    this.service.GetStateList(countryId).then((result: any) => {
      if (result.code == 200) {
        this.stateListArray = result["data"];
        if(this.stateId){
          this.stateListArray.map((obj:any)=>{
            if(this.stateId==obj.iStateId){
              this.primaryDetailsForm.controls['iStateId'].setValue(obj.vName);
            }
          });
        }
        this.statefilteredOptions = this.primaryDetailsForm.controls["iStateId"].valueChanges.pipe(
          startWith(""),
          map((value: string) => (value ? this._filter(value,this.stateListArray,'vName') : this.stateListArray.slice()))
        );
      }
    });
  }

  GetCityListClick(stateId: any, fromHtml?:boolean) {
    if(fromHtml){
      this.primaryDetailsForm.controls['iCityId'].setValue('');
    }
    if (stateId != "default") {
      this.stateId=stateId;
      this.GetCityList(stateId);
    }
  }

  GetCityList(stateId: any) {
    this.service.GetCityList(stateId).then((result: any) => {
      if (result.code == 200) {
        this.cityListArray = result["data"];
        if(this.cityId){
          this.cityListArray.map((obj:any)=>{
            if(this.cityId==obj.iCityId){
              this.primaryDetailsForm.controls['iCityId'].setValue(obj.vName);
            }
          });
        }
        this.cityfilteredOptions = this.primaryDetailsForm.controls["iCityId"].valueChanges.pipe(
          startWith(""),
          map((value: string) => (value ? this._filter(value,this.cityListArray,'vName') : this.cityListArray.slice()))
        );
      }
    });
  }

  AssignCity(cityId:any){
    this.cityId=cityId;
  }

  countryChange(event: any) {
    if (event) {
      this.primaryDetailsForm.controls.vPhoneNo.patchValue("");
    }
  }

  public primaryDetailsCardCompleted: any = false;
  updateProfileStatus(data: any) {
    this.primaryDetailsCardCompleted = data["primaryDetails"];
  }

  public oldImage: any = "";
  GetPrimaryDetails() {
    this.service.GetPrimaryDetails(this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.oldImage = result["data"]["vdisplayImage"];
        let time;
        if (result["data"]["iCompanyFoundedDate"] > 0) {
          time = new Date(result["data"]["iCompanyFoundedDate"] * 1000);
        }
        this.countryId=result["data"]["iCountryId"];
        this.stateId=result["data"]["iStateId"];
        this.cityId=result["data"]["iCityId"];
        this.GetStateListClick(this.countryId);
        this.countryListArray.map((obj:any)=>{
          if(this.countryId==obj.iCountryId){
            this.primaryDetailsForm.controls['iCountryId'].setValue(obj.vName);
          }
        });
        

        this.GetCityList(this.stateId);
        this.primaryDetailsForm.patchValue({
          vCompanyName: result["data"]["vCompanyName"],
          vEmailId: result["data"]["vEmailId"],
          vAltEmailId: result["data"]["vAltEmailId"],
          vCompanyWebsiteUrl: result["data"]["vCompanyWebsiteUrl"],
          vAddress: result["data"]["vAddress"],
          iCompanyBio: result["data"]["iCompanyBio"],
          vDialCode: result["data"]["vDialCode"],
          vPhoneNo: {
            countryCode: result["data"]["vCountryCode"],
            dialCode: result["data"]["vDialCode"],
            number: result["data"]["vPhoneNo"],
          },
          // vPhoneNo: result["data"]["vDialCode"] + "-" + result["data"]["vPhoneNo"],
          vCountryName: result["data"]["vCountryName"],
          vStateName: result["data"]["vStateName"],
          vCityName: result["data"]["vCityName"],
          // iCountryId: result["data"]["iCountryId"],
          // iStateId: result["data"]["iStateId"],
          // iCityId: result["data"]["iCityId"],
          iZipCode: result["data"]["iZipCode"],
          txLinkedinUrl: result["data"]["txLinkedinUrl"],
          txFacebookUrl: result["data"]["txFacebookUrl"],
          txTwitterUrl: result["data"]["txTwitterUrl"],
          txInstagramUrl: result["data"]["txInstagramUrl"],
          iCompanyFoundedDate: time,
          displayCompanyFoundedDate: result["data"]["iCompanyFoundedDate"],
        });
        // this.profileImageFile=this.primaryDetailsForm.controls.newImage.value;
        this.profileImageFile = this.oldImage;
      }
    });
  }


  PrimaryDetailsProcess() {
    if (this.profileImageFile) {
      this.profileImageError = false;
    } else {
      this.profileImageError = true;
    }
    if (this.profileImageFile)
      if (this.primaryDetailsForm.invalid) {
        // this.primaryDetailsSubmitted = true;
        return;
      }
    if (this.profileImageError) {
      return;
    }

    if(this.countryId){
      if(this.stateId){
        if(this.cityId){
        }else {
          this.notifyService.showError("Please select city from the list", "");
          this.primaryDetailsForm.controls["iCityId"].setValue('');
          return;
        }
      }else {
        this.notifyService.showError("Please select state from the list", "");
        this.primaryDetailsForm.controls["iStateId"].setValue('');
        this.primaryDetailsForm.controls["iCityId"].setValue('');
        return;
      }
    }else {
      this.notifyService.showError("Please select country from the list", "");
      this.primaryDetailsForm.controls["iCountryId"].setValue('');
      this.primaryDetailsForm.controls["iStateId"].setValue('');
      this.primaryDetailsForm.controls["iCityId"].setValue('');
      return;
    }
    
    let date = new Date(this.primaryDetailsForm.value.iCompanyFoundedDate).getTime();
    this.service.UpdatePrimaryDetails(this.primaryDetailsForm.value, this.profileImageFile, date / 1000,this.countryId,this.stateId,this.cityId,this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetPrimaryDetails();
        this.eventEmitterService.MenuListCallMethod();
        this.notifyService.showSuccess(result.message, "");
        this.primaryDetailsToggle=false;
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.eventEmitterService.GetDynamicDoucmentsCallMethod();
      }
    });
  }

  imageChangedEvent: any = "";
  croppedImage: any = "";
  profileImageFile: any;
  fileChangeEvent(event: any, modelName: any): void {
    let fileSize = event["target"]["files"][0]["size"] / 1024;
    if (fileSize > 1024) {
      this.notifyService.showError(StaticMsg.FileLargerError, "");
      return;
    }
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg") {
      this.imageChangedEvent = event;
      this.modalService.open(modelName, { centered: true, windowClass: "profile-modal" });
    } else {
      this.notifyService.showError(StaticMsg.NotValidImageTypeError, "");
      return;
    }
  }

  async ImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    let base64Response = await fetch(this.croppedImage);
    let blob = await base64Response.blob();
    let fileType = blob.type.split("/")[1];
    this.profileImageFile = new File([blob], "file." + fileType);
  }
  CancelCropModel() {
    this.profileImageFile = this.oldImage;
    this.imageChangedEvent = "";
    this.croppedImage = "";
  }

  togglePrimaryDetails(hasPermission:boolean){
    if(hasPermission){
    this.primaryDetailsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
    
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
