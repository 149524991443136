import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalConfig,NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../config/event-emitter.service';
import { BillingService } from './billing.service';
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

interface Common {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styles: [
  ]
})
export class BillingComponent implements OnInit {
  // toggle actions permission
  viewBillingActionPermission: boolean = false;

  billingStatus: Common[] = [
    {value: 0, viewValue: 'Unpaid'},
    {value: 1, viewValue: 'Paid'},
    {value: 2, viewValue: 'Failed'},
    {value: 3, viewValue: 'Due'},
  ];

apiResponse!:any;
apiResponseInvoices!:any;
ParentContractId!:any;
ParentContractName!:any;
PaidInvoiceCount!:any;
DueInvoiceCount!:any;
FailedInvoiceCount!:any;
TotalInvoiceCount!:any;
// EmployeeName!:any;
// ClientName!:any;

filterType:any='All';
filterValue:String='';
filterValueInner:String='';
dataSourceTableTwo = new MatTableDataSource();
displayedColumnsTableTwo: string[] = ['indexnumber','iUniqueId','iInvoiceId','iInvoiceStartDate','iInvoiceEndDate','fAmount','tiPaymentStatus'];

displayedColumnsTableOne: string[] = ['indexnumber','vContractName','vEmployeeName','vClientName','paidInvoiceCount','dueInvoiceCount','actions'];
dataSourceTableOne = new MatTableDataSource();

@ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
@ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

constructor
(
  private billingService: BillingService,
  public eventEmitterService: EventEmitterService, 
  private modalService: NgbModal,
  config: NgbModalConfig,
  private notifyService: NotificationService,
  private userPermissionService: UserPermissionService
) {
  config.backdrop = 'static';
  config.keyboard = false;

  // assign individual permissions
  const ViewBillingAction: Permission = Permission.ViewBillingAction;

  // check what user is authorized
  if (this.userPermissionService.isAuthorizedFor(ViewBillingAction)) {
    this.viewBillingActionPermission = true;
  }
}

ngOnInit(): void {
this.GetBillingData();
}

OpenModal(modalToOpen: any) {
this.modalService.open(modalToOpen, { centered: true });
}

OpenModelViewInvoiceDetails(modelName:any, element:any, hasPermission: boolean) {
  if (hasPermission) {
    this.modalService.open(modelName, { centered: true , size:'lg' });
    this.GetInvoiceDetailsById(element.iContractId)
  }
}

ngAfterViewInit() {
this.dataSourceTableOne.paginator = this.tableOnePaginator;
this.dataSourceTableOne.sort = this.tableOneSort;
}

applyFilterOnSearch(event: any) {
  this.filterValue=event.target.value;
  this.GetBillingData();
}

applyFilterTwo(event: any) {
  this.filterValueInner=event.target.value;
  this.GetInvoiceDetailsById(this.ParentContractId);
}


GetInvoiceDetailsById(id:any){
  this.billingService.GetInvoiceDetailsById(id).then((result: any) => {
    if (result['code'] == 200) {
      // console.log(result['data'],"result");
      this.apiResponseInvoices=result['data'][0].Invoices;
      this.dataSourceTableTwo.data = result['data'][0].Invoices;
      this.ParentContractId=result['data'][0].iContractId;
      this.ParentContractName=result['data'][0].vContractName;  
      this.PaidInvoiceCount=result['data'][0].paidInvoiceCount;
      this.DueInvoiceCount=result['data'][0].dueInvoiceCount;
      this.FailedInvoiceCount=result['data'][0].failedInvoiceCount;
      this.TotalInvoiceCount=result['data'][0].totalInvoiceCount;
      // this.EmployeeName=result['data'][0].vEmployeeName;
      // this.ClientName=result['data'][0].vClientName;

      if(this.filterType=='All'){
      this.dataSourceTableTwo.data=this.apiResponseInvoices;
      this.dataSourceTableTwo.filter = this.filterValueInner.trim().toLowerCase();
    }else{
      let filteredData = this.apiResponseInvoices.filter((item:any)=>{
        return item.tiPaymentStatus==this.filterType;
      });
      this.dataSourceTableTwo.data=filteredData;
      this.dataSourceTableTwo.filter = this.filterValueInner.trim().toLowerCase();
    }
    } else {
      this.notifyService.showError(result.message,'');
    }
  }).catch((err: any) => {
    console.log(err);
    this.ParentContractId=undefined;
    this.ParentContractName=undefined;
    this.PaidInvoiceCount=undefined;
    this.DueInvoiceCount=undefined;
    this.FailedInvoiceCount=undefined;
    this.TotalInvoiceCount=undefined;
    // this.EmployeeName=undefined;
    // this.ClientName=undefined;
  });
}

GetBillingData() {
this.billingService.GetBillingData().then((result: any) => {
  if (result['code'] == 200) {
    this.apiResponse=result['data'];
    this.dataSourceTableOne.data = result['data'];
    this.dataSourceTableOne.filter = this.filterValue.trim().toLowerCase();
  } else {
    this.notifyService.showError(result.message,'');
  }
}).catch((err: any) => {console.log(err)});
}

}
