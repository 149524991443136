import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EventEmitterService } from "../../config/event-emitter.service";
import { ChildPermissionManagementService } from "./child-permission-management.service";
import { ParentPermissionManagementService } from "./../parent-permission-management/parent-permission-management.service";
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { NotificationService } from "src/app/config/notification.service";

@Component({
  selector: 'app-child-permission-management',
  templateUrl: './child-permission-management.component.html'
})
export class ChildPermissionManagementComponent implements OnInit {
  // toggle actions permission
  addChildPermissionActionPermission: boolean = false;
  editChildPermissionActionPermission: boolean = false;
  deleteChildPermissionActionPermission: boolean = false;


  typeList: any = [
    { id: 1, typeName: "Admin" },
    { id: 3, typeName: "Partner" },
    { id: 4, typeName: "Client" }
  ];

  parentData: any = [];
  iChildFeatureId: number;
  addChildPermissionForm!: FormGroup;
  editChildPermissionForm!: FormGroup;

  displayedColumnsTableOne: string[] = ["indexNumber", "parentPermissionName", "childPermissionName", "createdAt", "actions"];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild("TableOnePaginator", { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort", { static: true }) tableOneSort: MatSort;

  constructor(
    private childPermissionService: ChildPermissionManagementService,
    private parentPermissionManagementService: ParentPermissionManagementService,
    public eventEmitterService: EventEmitterService, 
    private modalService: NgbModal,
    config: NgbModalConfig,
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  ) 
  { 
    config.backdrop = 'static';
    config.keyboard = false;

    // assign individual permissions
    const AddChildPermissionAction: Permission = Permission.AddChildPermissionAction;
    const EditChildPermissionAction: Permission = Permission.EditChildPermissionAction;
    const DeleteChildPermissionAction: Permission = Permission.DeleteChildPermissionAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddChildPermissionAction)) {
      this.addChildPermissionActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditChildPermissionAction)) {
      this.editChildPermissionActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeleteChildPermissionAction)) {
      this.deleteChildPermissionActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetChildPermissions();

    this.addChildPermissionForm = new FormGroup({
      childPermissionName: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      parentPermissionType: new FormControl("", [Validators.required]),
      parentPermission: new FormControl('', [Validators.required])
    });

    this.editChildPermissionForm = new FormGroup({
      iChildFeatureId: new FormControl('', []), 
      childPermissionName: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      parentPermission: new FormControl('', [Validators.required])
    });
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  OpenAddChildPermissionModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.GetParentPermissions();
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  OpenEditChildPermissionModel(modelName: any, element: any, hasPermission: boolean) {
    if (hasPermission) {
      this.GetParentPermissions();
      this.editChildPermissionForm.patchValue({
        iChildFeatureId: element['iChildFeatureId'],
        childPermissionName: element['vChildFeature'],
        parentPermission: element['iParentFeatureId']
      })
      this.modalService.open(modelName, { centered: true });
    }
  }

  OpenDeleteChildPermission(modalToOpen: any, element: any, hasPermission: boolean){
    if (hasPermission) {
      // console.log(element);
      this.iChildFeatureId = element['iChildFeatureId'];
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  GetChildPermissions() {
    this.childPermissionService.GetChildPermissions().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    }).catch((err: any) => {console.log(err)});
  }

  GetParentPermissions() {
    this.parentPermissionManagementService.GetParentPermissions().then((result: any) => {
      if (result["code"] == 200) {
        this.parentData = result['data'];        
      } else {
        this.notifyService.showError(result.message,'');
      }
    })
    .catch((err: any) => { console.log(err) });
  }

  AddChildPermission() {
    if (this.addChildPermissionForm.invalid) {
      return;
    }
    let reqData = {
      vChildFeature: this.addChildPermissionForm.value.childPermissionName,
      iParentFeatureId: this.addChildPermissionForm.value.parentPermission
    } 
    this.childPermissionService.AddChildPermission(reqData).then((result: any) => {
      if (result['code'] == 200) {
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message,'');
        this.GetChildPermissions();
      } else {
        this.modalService.dismissAll();
        this.notifyService.showError(result.message,'');
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  EditChildPermission() {
    if (this.editChildPermissionForm.invalid) {
      return;
    }
    let reqData = {
      iChildFeatureId: this.editChildPermissionForm.value.iChildFeatureId,
      vChildFeature: this.editChildPermissionForm.value.childPermissionName,
      iParentFeatureId: this.editChildPermissionForm.value.parentPermission
    } 
    this.childPermissionService.EditChildPermission(reqData).then((result: any) => {
      if (result['code'] == 200) {
        this.notifyService.showSuccess(result.message,'');
        this.addChildPermissionForm.reset();
        this.modalService.dismissAll();
        this.GetChildPermissions();
      } else {
        this.notifyService.showError(result.message,'');
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  DeleteChildPermission(childPermissionId: any): void {
    this.childPermissionService.DeleteChildPermission(childPermissionId).then((result: any) => {
      this.notifyService.showSuccess(result.message,'');
      this.modalService.dismissAll();
      this.GetChildPermissions();
    }).catch((err) => {
      console.log(err);
    })
  }

}
