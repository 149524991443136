<div name="executive-details-box" id="executive-details-box" class="col-12">
  <div name="executive-details-card-container" id="executive-details-card-container" class="card-container" *ngIf="executiveDetailsToggle==false">
    <div name="executive-details-card-header" id="executive-details-card-header" class="card-header">
      <div name="executive-details-title-section" id="executive-details-title-section" class="title-section">
        <h4 name="executive-details-card-title" id="executive-details-card-title" class="card-title">Executive Details
        </h4>
        <div placement="right auto" [ngbTooltip]="editPartnerExecutiveDetailsActionPermission ? '':'Not Authorized'" [ngClass]="editPartnerExecutiveDetailsActionPermission ? '':'unauthorized-cursor'">
          <button name="executive-details-card-edit-button" id="executive-details-card-edit-button"
            class="card-edit-button" type="button" (click)="toggleExecutiveDetails(editPartnerExecutiveDetailsActionPermission)" [ngClass]="editPartnerExecutiveDetailsActionPermission ? '':'unauthorized-action'">
            <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
          </button>
        </div>
      </div>
      <div name="executive-details-status-section" id="executive-details-status-section" class="status-section">
        <div name="executive-details-incomplete-status-box" id="executive-details-incomplete-status-box"
          class="status-box" *ngIf="!executiveDetailsCardCompleted">
          <img id="executive-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
            alt="status-icon">
          <label name="executive-details-incomplete-status-text" id="executive-details-incomplete-status-text"
            class="status-text incomplete">Pending</label>
        </div>
        <div name="executive-details-completed-status-box" id="executive-details-completed-status-box"
          class="status-box" *ngIf="executiveDetailsCardCompleted">
          <img id="executive-details-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'"
            alt="status-icon">
          <label name="executive-details-completed-status-text" id="executive-details-completed-status-text"
            class="status-text completed">Completed</label>
        </div>
      </div>
    </div>
    <div name="executive-details-card" id="executive-details-card" class="card"
      [ngClass]="executiveDetailsCardCompleted ? 'completed' : 'incomplete'">
      <div name="executive-details-card-content" id="executive-details-card-content" class="card-content">
        <div name="executive-details-card-content-row" id="executive-details-card-content-row" class="row">
          <div name="executive-details-card-content-col-0" id="executive-details-card-content-col-0" class="col-12">
            <label name="executive-details-card-content-col-0-highlighted-title" class="highlighted-title">
              Managing Director/CEO/President
            </label>
          </div>
          <div name="managing-director-not-available" id="managing-director-not-available" class="text bottom-spacing"
            *ngIf="!executiveDetailsForm?.value.vMDFullname">
            Not Available
          </div>
          <div class="row" *ngIf="executiveDetailsForm?.value.vMDFullname">
            <div name="executive-details-card-content-col-1" id="executive-details-card-content-col-1"
              class="col-12 col-lg-3 col-sm-6">
              <label name="executive-details-card-content-col-1-label" id="executive-details-card-content-col-1-label"
                class="label">
                Full Name
              </label>
              <label name="executive-details-card-content-col-1-text" id="executive-details-card-content-col-1-text"
                class="text bottom-spacing">
                {{ executiveDetailsForm.value.vMDFullname }}
              </label>
            </div>
            <div name="executive-details-card-content-col-2" id="executive-details-card-content-col-2"
              class="col-12 col-lg-3 col-sm-6">
              <label name="executive-details-card-content-col-2-label" id="executive-details-card-content-col-2-label"
                class="label">
                Email Address
              </label>
              <label name="executive-details-card-content-col-2-text" id="executive-details-card-content-col-2-text"
                class="text bottom-spacing">
                {{ executiveDetailsForm.value.vMDEmailId }}
              </label>
            </div>
          </div>
          <div name="executive-details-card-content-col-3" id="executive-details-card-content-col-3" class="col-12">
            <label name="executive-details-card-content-col-3-highlighted-title"
              id="executive-details-card-content-col-3-highlighted-title" class="highlighted-title">
              Escalation Manager
            </label>
          </div>
          <div name="escalation-manager-not-available" id="escalation-manager-not-available" class="text bottom-spacing"
            *ngIf="!executiveDetailsForm?.value.vEMFullname">
            Not Available
          </div>
          <div class="row" *ngIf="executiveDetailsForm?.value.vEMFullname">
            <div name="executive-details-card-content-col-4" id="executive-details-card-content-col-4"
              class="col-12 col-lg-3 col-sm-6">
              <label name="executive-details-card-content-col-4-label" id="executive-details-card-content-col-4-label"
                class="label">
                Full Name
              </label>
              <label name="executive-details-card-content-col-4-text" id="executive-details-card-content-col-4-text"
                class="text bottom-spacing">
                {{ executiveDetailsForm.value.vEMFullname }}
              </label>
            </div>
            <div name="executive-details-card-content-col-5" id="executive-details-card-content-col-5"
              class="col-12 col-lg-3 col-sm-6">
              <label name="executive-details-card-content-col-5-label" id="executive-details-card-content-col-5-label"
                class="label">
                Email Address
              </label>
              <label name="executive-details-card-content-col-5-text" id="executive-details-card-content-col-5-text"
                class="text bottom-spacing">
                {{ executiveDetailsForm.value.vEMEmailId }}
              </label>
            </div>
          </div>
          <div name="executive-details-card-content-col-6" id="executive-details-card-content-col-6" class="col-12">
            <label name="executive-details-card-content-col-6-highlighted-title"
              id="executive-details-card-content-col-6-highlighted-title" class="highlighted-title">
              Finance Manager
            </label>
          </div>
          <div name="finance-manager-not-available" id="finance-manager-not-available" class="text bottom-spacing"
            *ngIf="!executiveDetailsForm?.value.vFMFullname">
            Not Available
          </div>
          <div class="row" *ngIf="executiveDetailsForm?.value.vFMFullname">
            <div name="executive-details-card-content-col-7" id="executive-details-card-content-col-7"
              class="col-12 col-lg-3 col-sm-6">
              <label name="executive-details-card-content-col-7-label" id="executive-details-card-content-col-7-label"
                class="label">
                Full Name
              </label>
              <label name="executive-details-card-content-col-7-text" id="executive-details-card-content-col-7-text"
                class="text bottom-spacing">
                {{ executiveDetailsForm.value.vFMFullname }}
              </label>
            </div>
            <div name="executive-details-card-content-col-8" id="executive-details-card-content-col-8"
              class="col-12 col-lg-3 col-sm-6">
              <label name="executive-details-card-content-col-8-label" id="executive-details-card-content-col-8-label"
                class="label">
                Email Address
              </label>
              <label name="executive-details-card-content-col-8-text" id="executive-details-card-content-col-8-text"
                class="text bottom-spacing">
                {{ executiveDetailsForm.value.vFMEmailId }}
              </label>
            </div>
          </div>
          <div name="executive-details-card-content-col-9" id="executive-details-card-content-col-9" class="col-12">
            <label name="executive-details-card-content-col-9-highlighted-title"
              id="executive-details-card-content-col-9-highlighted-title" class="highlighted-title">
              Sales Manager
            </label>
          </div>
          <div name="sales-manager-not-available" id="sales-manager-not-available" class="text"
            *ngIf="!executiveDetailsForm?.value.vSMFullname">
            Not Available
          </div>
          <div class="row" *ngIf="executiveDetailsForm?.value.vSMFullname">
            <div name="executive-details-card-content-col-10" id="executive-details-card-content-col-10"
              class="col-12 col-lg-3 col-sm-6">
              <label name="executive-details-card-content-col-10-label" id="executive-details-card-content-col-10-label"
                class="label">
                Full Name
              </label>
              <label name="executive-details-card-content-col-10-text" id="executive-details-card-content-col-10-text"
                class="text text-padding">
                {{ executiveDetailsForm.value.vSMFullname }}
              </label>
            </div>
            <div name="executive-details-card-content-col-11" id="executive-details-card-content-col-11"
              class="col-12 col-lg-3 col-sm-6">
              <label name="executive-details-card-content-col-11-label" id="executive-details-card-content-col-11-label"
                class="label">
                Email Address
              </label>
              <label name="executive-details-card-content-col-11-text" id="executive-details-card-content-col-11-text"
                class="text">
                {{ executiveDetailsForm.value.vSMEmailId }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-container" *ngIf="executiveDetailsToggle==true">
    <div name="executive-details-card-header" id="executive-details-card-header" class="card-header">
      <div name="executive-details-title-section" id="executive-details-title-section" class="title-section">
        <h4 name="executive-details-card-title" id="executive-details-card-title" class="card-title">Executive Details</h4>
      </div>
    </div>
    <div class="edit-profile">
      <div name="executive-details-modal-body" id="executive-details-modal-body" class="modal-body modal-scroll">
        <form id="executiveDetailsForm" name="executiveDetailsForm" class="form" [formGroup]="executiveDetailsForm"
          (ngSubmit)="UpdatePartnerExecutive()">
          <div name="executive-details-row" id="executive-details-row" class="row">
            <div name="executive-details-col-1" id="executive-details-col-1" class="col-12">
              <label name="executive-details-col-1-bold-title" id="executive-details-col-1-bold-title" class="bold-title">
                Managing Director/CEO/President
              </label>
            </div>
            <div name="executive-details-col-2" id="executive-details-col-2" class="col-12 col-md-6">
              <label for="vMDFullname" name="executive-details-col-2-label" id="executive-details-col-2-label"
                class="custom-label">Full Name <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="executive-details-col-2-input" id="executive-details-col-2-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vMDFullname" formControlName="vMDFullname" placeholder="Jolie Reichert"
                  required>
                <mat-error name="executive-details-col-2-error-0" id="executive-details-col-2-error-0"
                  class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vMDFullname'].invalid">
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vMDFullname'].errors!.required">
                    Managing Director Name is required
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vMDFullname'].errors!.minlength ">
                    Minimum 3 character required
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vMDFullname'].errors!.maxlength">
                    Maximum 50 character allowed
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space"
                    *ngIf="executiveDetailsForm.controls['vMDFullname'].errors!.pattern && !(executiveDetailsForm.controls['vMDFullname'].errors!.minlength) && !(executiveDetailsForm.controls['vMDFullname'].errors!.maxlength)">
                    Please enter a valid full name
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="executive-details-col-3" id="executive-details-col-3" class="col-12 col-md-6">
              <label for="vMDEmailId" name="executive-details-col-3-label" id="executive-details-col-3-label"
                class="custom-label">Email Address <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="executive-details-col-3-input" id="executive-details-col-3-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vMDEmailId" formControlName="vMDEmailId" placeholder="toptech@inc.com"
                  required>
                <mat-error name="executive-details-col-3-error-0" id="executive-details-col-3-error-0"
                  class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vMDEmailId'].invalid">
                  <mat-error name="executive-details-col-3-error-1" id="executive-details-col-3-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vMDEmailId'].errors!.required">
                    Email is required
                  </mat-error>
                  <mat-error name="executive-details-col-3-error-1" id="executive-details-col-3-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vMDEmailId'].errors!.maxlength">
                    Must not exceed <strong>70</strong> characters
                  </mat-error>
                  <mat-error name="executive-details-col-3-error-1" id="executive-details-col-3-error-1"
                    class="reduce-bottom-space"
                    *ngIf="executiveDetailsForm.controls['vMDEmailId'].errors!.email || executiveDetailsForm.controls['vMDEmailId'].errors!.pattern">
                    Email Address is invalid
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="executive-details-col-4" id="executive-details-col-4" class="col-12">
              <label name="executive-details-col-4-bold-title" id="executive-details-col-4-bold-title"
                class="bold-title top-spacing">
                Escalation Manager
              </label>
            </div>
            <div name="executive-details-col-5" id="executive-details-col-5" class="col-12 col-md-6">
              <label for="vEMFullname" name="executive-details-col-5-label" id="executive-details-col-5-label"
                class="custom-label">Full Name <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="executive-details-col-3-input" id="executive-details-col-3-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vEMFullname" formControlName="vEMFullname" placeholder="Joany Heller"
                  required>
                <mat-error name="executive-details-col-5-error-0" id="executive-details-col-5-error-0"
                  class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vEMFullname'].invalid">
                  <mat-error name="executive-details-col-5-error-1" id="executive-details-col-5-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vEMFullname'].errors!.required">
                    Escalation Manager Name is required
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vEMFullname'].errors!.minlength">
                    Minimum 3 character required
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vEMFullname'].errors!.maxlength">
                    Maximum 50 character allowed
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space"
                    *ngIf="executiveDetailsForm.controls['vEMFullname'].errors!.pattern && !(executiveDetailsForm.controls['vEMFullname'].errors!.minlength) && !(executiveDetailsForm.controls['vEMFullname'].errors!.maxlength)">
                    Please enter a valid full name
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="executive-details-col-6" id="executive-details-col-6" class="col-12 col-md-6">
              <label for="vEMEmailId" name="executive-details-col-6-label" id="executive-details-col-6-label"
                class="custom-label">Email Address <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="executive-details-col-6-input" id="executive-details-col-6-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vEMEmailId" formControlName="vEMEmailId"
                  placeholder="joany.heller@toptech.com" required>
                <mat-error name="executive-details-col-6-error-0" id="executive-details-col-6-error-0"
                  class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vEMEmailId'].invalid">
                  <mat-error name="executive-details-col-6-error-1" id="executive-details-col-6-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vEMEmailId'].errors!.required">
                    Email is required
                  </mat-error>
                  <mat-error name="executive-details-col-3-error-1" id="executive-details-col-3-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vEMEmailId'].errors!.maxlength">
                    Must not exceed <strong>70</strong> characters
                  </mat-error>
                  <mat-error name="executive-details-col-3-error-1" id="executive-details-col-3-error-1"
                    class="reduce-bottom-space"
                    *ngIf="executiveDetailsForm.controls['vEMEmailId'].errors!.email || executiveDetailsForm.controls['vEMEmailId'].errors!.pattern">
                    Email Address is invalid
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="executive-details-col-7" id="executive-details-col-7" class="col-12">
              <label name="executive-details-col-7-bold-title" id="executive-details-col-7-bold-title"
                class="bold-title top-spacing">
                Finance Manager
              </label>
            </div>
            <div name="executive-details-col-8" id="executive-details-col-8" class="col-12 col-md-6">
              <label for="vFMFullname" name="executive-details-col-8-label" id="executive-details-col-8-label"
                class="custom-label">Full Name <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="executive-details-col-8-input" id="executive-details-col-8-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vFMFullname" formControlName="vFMFullname" placeholder="Carlos Lubowitz"
                  required>
                <mat-error name="executive-details-col-8-error-0" id="executive-details-col-8-error-0"
                  class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vFMFullname'].invalid">
                  <mat-error name="executive-details-col-8-error-1" id="executive-details-col-8-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vFMFullname'].errors!.required">
                    Finance Manager Name is required
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vFMFullname'].errors!.minlength">
                    Minimum 3 character requred
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vFMFullname'].errors!.maxlength">
                    Maximum 50 character allowed
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space"
                    *ngIf="executiveDetailsForm.controls['vFMFullname'].errors!.pattern && !(executiveDetailsForm.controls['vFMFullname'].errors!.minlength) && !(executiveDetailsForm.controls['vFMFullname'].errors!.maxlength)">
                    Please enter a valid full name
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="executive-details-col-9" id="executive-details-col-9" class="col-12 col-md-6">
              <label for="vFMEmailId" name="executive-details-col-9-label" id="executive-details-col-9-label"
                class="custom-label">Email Address <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="executive-details-col-9-input" id="executive-details-col-9-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vFMEmailId" formControlName="vFMEmailId"
                  placeholder="carlos.lubowitz@toptech.com" required>
                <mat-error name="executive-details-col-9-error-0" id="executive-details-col-9-error-0"
                  class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vFMEmailId'].invalid">
                  <mat-error name="executive-details-col-9-error-1" id="executive-details-col-9-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vFMEmailId'].errors!.required">
                    Email is required
                  </mat-error>
                  <mat-error name="executive-details-col-3-error-1" id="executive-details-col-3-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vFMEmailId'].errors!.maxlength">
                    Must not exceed <strong>70</strong> characters
                  </mat-error>
                  <mat-error name="executive-details-col-3-error-1" id="executive-details-col-3-error-1"
                    class="reduce-bottom-space"
                    *ngIf="executiveDetailsForm.controls['vFMEmailId'].errors!.email || executiveDetailsForm.controls['vFMEmailId'].errors!.pattern">
                    Email Address is invalid
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="executive-details-col-10" id="executive-details-col-10" class="col-12">
              <label name="executive-details-col-10-bold-title" id="executive-details-col-10-bold-title"
                class="bold-title top-spacing">
                Sales Manager
              </label>
            </div>
            <div name="executive-details-col-11" id="executive-details-col-11" class="col-12 col-md-6">
              <label for="vSMFullname" name="executive-details-col-11-label" id="executive-details-col-11-label"
                class="custom-label">Full Name <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="executive-details-col-11-input" id="executive-details-col-11-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vSMFullname" formControlName="vSMFullname" placeholder="Amya Kreiger"
                  required>
                <mat-error name="executive-details-col-11-error-0" id="executive-details-col-11-error-0"
                  class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vSMFullname'].invalid">
                  <mat-error name="executive-details-col-11-error-1" id="executive-details-col-11-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vSMFullname'].errors!.required">
                    Sales Manager Name is required
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vSMFullname'].errors!.minlength">
                    Minimum 3 character required
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vSMFullname'].errors!.maxlength">
                    Maximum 50 character allowed
                  </mat-error>
                  <mat-error name="executive-details-col-2-error-1" id="executive-details-col-2-error-1"
                    class="reduce-bottom-space"
                    *ngIf="executiveDetailsForm.controls['vSMFullname'].errors!.pattern && !(executiveDetailsForm.controls['vSMFullname'].errors!.minlength) && !(executiveDetailsForm.controls['vSMFullname'].errors!.maxlength)">
                    Please enter a valid full name
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="executive-details-col-12" id="executive-details-col-12" class="col-12 col-md-6">
              <label for="vSMEmailId" name="executive-details-col-12-label" id="executive-details-col-12-label"
                class="custom-label">Email Address <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="executive-details-col-12-input" id="executive-details-col-12-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vSMEmailId" formControlName="vSMEmailId"
                  placeholder="amya.kreiger@toptech.com" required>
                <mat-error name="executive-details-col-12-error-0" id="executive-details-col-12-error-0"
                  class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vSMEmailId'].invalid">
                  <mat-error name="executive-details-col-12-error-1" id="executive-details-col-12-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vSMEmailId'].errors!.required">
                    Email is required
                  </mat-error>
                  <mat-error name="executive-details-col-3-error-1" id="executive-details-col-3-error-1"
                    class="reduce-bottom-space" *ngIf="executiveDetailsForm.controls['vSMEmailId'].errors!.maxlength">
                    Must not exceed <strong>70</strong> characters
                  </mat-error>
                  <mat-error name="executive-details-col-3-error-1" id="executive-details-col-3-error-1"
                    class="reduce-bottom-space"
                    *ngIf="executiveDetailsForm.controls['vSMEmailId'].errors!.email || executiveDetailsForm.controls['vSMEmailId'].errors!.pattern">
                    Email Address is invalid
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div name="executive-details-modal-footer" id="executive-details-modal-footer" class="modal-footer">
        <button type="button" name="executive-details-modal-footer-btn-cancel" id="executive-details-modal-footer"
          class="blue-secondary-small-btn mx-2"
          (click)="this.executiveDetailsToggle=false; this.executiveDetailsForm.reset(); this.executiveDetailsSubmitted = false; executiveCancel();">Cancel</button>
        <button type="submit" name="executive-details-modal-footer-btn-submit" id="executive-details-modal-footer"
          class="blue-primary-small-btn" form="executiveDetailsForm">Save</button>
      </div>
    </div>
  </div>
</div>

