import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { EventEmitterService } from '../../config/event-emitter.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, PluginServiceGlobalRegistrationAndOptions } from 'ng2-charts';
import * as moment from 'moment';
import { Permission } from 'src/app/utils/enums/permission.enum';
import { UserPermissionService } from 'src/app/utils/services/user-permission.service';
import { NotificationService } from 'src/app/config/notification.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})

export class DashboardComponent implements OnInit {

  // toggle actions permission
  viewRequirementsPermission: boolean = false;
  viewInterviewsPermission: boolean = false;
  viewContractsPermission: boolean = false;
  viewBillingPermission: boolean = false;
  viewDealEnginePermission: boolean = false;
  accessPartnerUserManagementPermission: boolean = false;
  accessEmployeeUserManagementPermission: boolean = false;
  accessClientUserManagementPermission: boolean = false;

  countClient: any;
  countPartner: any;
  countTalent: any;
  countContracts: any;
  countRequirements: any;
  countInterviews: any;
  totalBilling: any;
  totalRequirements: any;
  dealsOverview: any;
  countryProfileOverview: any;
  interviewsOverview: any;
  talentOverview: any;
  startDate: any;
  endDate: any;
  profileCountryList: any = [];
  currentDate = moment().format('MMM');

  clientOverviewColumns: string[] = ['vCompanyName', 'requirementCount', 'fullFilledCount'];
  clientOverviewSource = [];

  totalRequirementColumns: string[] = ['talentRequirement', 'hiredTalent', 'totalAvailableTalent'];
  requirementSource = [];

  recentContractColumns: string[] = ['contractName', 'talentName', 'startDate', 'price', 'duration'];
  recentContractSource = [];

  vRevenueArray: any = [
    { iRevenueId: 1, vRevenueValue: 'Total Revenue' }
  ];
  selectedRevenue = this.vRevenueArray[0].iRevenueId;

  requirementFilterArray: any = [
    { requirementId: 1, requirementValue: 'Month' },
    { requirementId: 2, requirementValue: 'Year' }
  ];
  requirementFilterType: any = this.requirementFilterArray[0].requirementId;

  vProfileMonthArray: any = [
    { iMonthId: 0, vMonthValue: 'Jan' },
    { iMonthId: 1, vMonthValue: 'Feb' },
    { iMonthId: 2, vMonthValue: 'Mar' },
    { iMonthId: 3, vMonthValue: 'Apr' },
    { iMonthId: 4, vMonthValue: 'May' },
    { iMonthId: 5, vMonthValue: 'Jun' },
    { iMonthId: 6, vMonthValue: 'Jul' },
    { iMonthId: 7, vMonthValue: 'Aug' },
    { iMonthId: 8, vMonthValue: 'Sep' },
    { iMonthId: 9, vMonthValue: 'Oct' },
    { iMonthId: 10, vMonthValue: 'Nov' },
    { iMonthId: 11, vMonthValue: 'Dec' }
  ];
  selectedProfileMonth: any;

  profileFilterArray: any = [
    { profileId: 1, profileValue: 'Month' },
    { profileId: 2, profileValue: 'Year' }
  ];
  profileFilterType: any = this.profileFilterArray[0].profileId;

  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: false,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  // Revenue Bar Chart
  public revenueBarChartType: ChartType = 'bar';
  public revenueBarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          min: 0
        }
      }]
    },
    tooltips: {
      backgroundColor: '#ffffff',
      bodyFontColor: '#0F172A',
      titleFontColor: '#0F172A',
      borderColor: '#8A8D97',
      borderWidth: 0.5
    },
  };
  public revenueBarChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public revenueBarChartLegend = false;
  public revenueBarChartPlugins = [];
  public revenueBarChartData: ChartDataSets[] = [
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      stack: 'a',
      backgroundColor: '#58B47C',
      hoverBackgroundColor: '#58B47C',
      barThickness: 20
    }
  ];

  // Billing Bar Chart
  public billingBarChartType: ChartType = 'bar';
  public billingBarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          min: 0
        }
      }]
    },
    tooltips: {
      backgroundColor: '#ffffff',
      bodyFontColor: '#0F172A',
      titleFontColor: '#0F172A',
      borderColor: '#8A8D97',
      borderWidth: 0.5
    },
  };
  public billingBarChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public billingBarChartLegend = false;
  public billingBarChartPlugins = [];
  public billingBarChartData: ChartDataSets[] = [
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      stack: 'a',
      backgroundColor: '#48ACF0',
      hoverBackgroundColor: '#48ACF0',
      barThickness: 10
    }
  ];

  // Profiles By Country Bar Chart
  public profilesByCountryBarChartType: ChartType = 'horizontalBar';
  public profilesByCountryBarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        display: true,
        ticks: {
          min: 0
        }
      }]
    },
    tooltips: {
      backgroundColor: '#ffffff',
      bodyFontColor: '#0F172A',
      titleFontColor: '#0F172A',
      borderColor: '#8A8D97',
      borderWidth: 0.5
    },
  };
  public profilesByCountryBarChartLabels: Label[] = ['India', 'Australia'];
  public profilesByCountryBarChartLegend = false;
  public profilesByCountryBarChartPlugins = [];
  public profilesByCountryBarChartData: ChartDataSets[] = [
    {
      data: [0, 0, 0],
      stack: 'a',
      backgroundColor: '#FFBC42',
      hoverBackgroundColor: '#FFBC42',
      barThickness: 15
    },
    {
      data: [0, 0, 0],
      stack: 'a',
      backgroundColor: '#EC407A',
      hoverBackgroundColor: '#EC407A',
      barThickness: 15
    },
    {
      data: [0, 0, 0],
      stack: 'a',
      backgroundColor: '#48ACF0',
      hoverBackgroundColor: '#48ACF0',
      barThickness: 15
    }
  ];

  // Talent Donut Chart
  public talentDonutChartType: ChartType = 'doughnut';
  public talentDonutChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    tooltips: {
      backgroundColor: '#ffffff',
      bodyFontColor: '#0F172A',
      titleFontColor: '#0F172A',
      borderColor: '#8A8D97',
      borderWidth: 0.5
    },
    animation: {
      animateRotate: true
    }
  };
  public talentDonutChartLabels: Label[] = ['Hired', 'Shortlisted', 'Available'];
  public talentDonutChartLegend = false;
  public talentDonutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{
    afterDraw(chart: any) {
      const ctx = chart.ctx;
      var txt1 = '';
      var txt2 = '';
      try {
        var check = chart.active ? chart.tooltip._active[0]._datasetIndex : "None";
        if (check !== "None") {
          txt1 = chart.tooltip._data.labels[chart.tooltip._active[0]._index];
          txt2 = chart.tooltip._data.datasets[0].data[chart.tooltip._active[0]._index];
        }
        else {
          txt1 = chart.tooltip._data.labels[0];
          txt2 = chart.tooltip._data.datasets[0].data[0];
        }
      }
      catch (err) {
        txt1 = chart.tooltip._data.labels[0]
        txt2 = chart.tooltip._data.datasets[0].data[0];
      }
      const sidePaddingCalculated = (60 / 100) * (chart.innerRadius * 2)
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      const stringWidth = ctx.measureText(txt1).width;
      const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor(30 * widthRatio);
      const elementHeight = (chart.innerRadius * 2);
      ctx.font = 28 + 'px Montserrat';
      ctx.fillStyle = '#0F172A';
      ctx.fillText(txt2, centerX, centerY - 10);
      ctx.font = 10 + 'px Montserrat';
      ctx.fillText(txt1, centerX, centerY + 10);
    }
  }];
  public talentDonutChartData: ChartDataSets[] = [
    {
      data: [0, 0, 0],
      backgroundColor: ['#58B47C', '#419BD8', '#CACAD4'],
      hoverBackgroundColor: ['#58B47C', '#419BD8', '#CACAD4'],
      hoverBorderColor: ['#58B47C', '#419BD8', '#CACAD4'],
      hoverBorderWidth: 4
    }
  ];

  // Deals Donut Chart
  public dealsDonutChartType: ChartType = 'doughnut';
  public dealsDonutChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    tooltips: {
      backgroundColor: '#ffffff',
      bodyFontColor: '#0F172A',
      titleFontColor: '#0F172A',
      borderColor: '#8A8D97',
      borderWidth: 0.5
    },
    animation: {
      animateRotate: true
    }
  };
  public dealsDonutChartLabels: Label[] = ['Active Deal', 'Inactive Deal'];
  public dealsDonutChartLegend = false;
  public dealsDonutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{
    afterDraw(chart: any) {
      const ctx = chart.ctx;
      var txt1 = '';
      var txt2 = '';
      try {
        var check = chart.active ? chart.tooltip._active[0]._datasetIndex : "None";
        if (check !== "None") {
          txt1 = chart.tooltip._data.labels[chart.tooltip._active[0]._index];
          txt2 = chart.tooltip._data.datasets[0].data[chart.tooltip._active[0]._index];
        }
        else {
          txt1 = chart.tooltip._data.labels[0];
          txt2 = chart.tooltip._data.datasets[0].data[0];
        }
      }
      catch (err) {
        txt1 = chart.tooltip._data.labels[0]
        txt2 = chart.tooltip._data.datasets[0].data[0];
      }
      const sidePaddingCalculated = (60 / 100) * (chart.innerRadius * 2)
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      const stringWidth = ctx.measureText(txt1).width;
      const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor(30 * widthRatio);
      const elementHeight = (chart.innerRadius * 2);
      ctx.font = 28 + 'px Montserrat';
      ctx.fillStyle = '#0F172A';
      ctx.fillText(txt2, centerX, centerY - 10);
      ctx.font = 10 + 'px Montserrat';
      ctx.fillText(txt1, centerX, centerY + 10);
    }
  }];
  public dealsDonutChartData: ChartDataSets[] = [
    {
      data: [0, 0],
      backgroundColor: ['#5EC090', '#BBBDC3'],
      hoverBackgroundColor: ['#5EC090', '#BBBDC3'],
      hoverBorderColor: ['#5EC090', '#BBBDC3'],
      hoverBorderWidth: 4
    }
  ];

  // Interviews Donut Chart
  public interviewsDonutChartType: ChartType = 'doughnut';
  public interviewsDonutChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    tooltips: {
      backgroundColor: '#ffffff',
      bodyFontColor: '#0F172A',
      titleFontColor: '#0F172A',
      borderColor: '#8A8D97',
      borderWidth: 0.5
    },
    animation: {
      animateRotate: true
    }
  };
  public interviewsDonutChartLabels: Label[] = ['Hired', '2nd Round', 'Rejected'];
  public interviewsDonutChartLegend = false;
  public interviewsDonutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{
    afterDraw(chart: any) {
      const ctx = chart.ctx;
      var txt1 = '';
      var txt2 = '';
      try {
        var check = chart.active ? chart.tooltip._active[0]._datasetIndex : "None";
        if (check !== "None") {
          txt1 = chart.tooltip._data.labels[chart.tooltip._active[0]._index];
          txt2 = chart.tooltip._data.datasets[0].data[chart.tooltip._active[0]._index];
        }
        else {
          txt1 = chart.tooltip._data.labels[0];
          txt2 = chart.tooltip._data.datasets[0].data[0];
        }
      }
      catch (err) {
        txt1 = chart.tooltip._data.labels[0]
        txt2 = chart.tooltip._data.datasets[0].data[0];
      }
      const sidePaddingCalculated = (60 / 100) * (chart.innerRadius * 2)
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      const stringWidth = ctx.measureText(txt1).width;
      const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor(30 * widthRatio);
      const elementHeight = (chart.innerRadius * 2);
      ctx.font = 28 + 'px Montserrat';
      ctx.fillStyle = '#0F172A';
      ctx.fillText(txt2, centerX, centerY - 10);
      ctx.font = 10 + 'px Montserrat';
      ctx.fillText(txt1, centerX, centerY + 10);
    }
  }];
  public interviewsDonutChartData: ChartDataSets[] = [
    {
      data: [0, 0, 0],
      backgroundColor: ['#FFBC42', '#58B47C', '#D2474E'],
      hoverBackgroundColor: ['#FFBC42', '#58B47C', '#D2474E'],
      hoverBorderColor: ['#FFBC42', '#58B47C', '#D2474E'],
      hoverBorderWidth: 4
    }
  ];

  constructor(
    private dashboardService: DashboardService,
    public eventEmitterService: EventEmitterService,
    private userPermissionService: UserPermissionService,
    private notifyService : NotificationService
  ) { 
    setTimeout(() => {
      // assign individual permissions
      const ViewRequirements: Permission = Permission.ViewRequirements;
      const ViewInterviews: Permission = Permission.ViewInterviews;
      const ViewContracts: Permission = Permission.ViewContracts;
      const ViewBilling: Permission = Permission.ViewBilling;
      const ViewDealEngine: Permission = Permission.ViewDealEngine;
      const AccessPartnerUserManagement: Permission = Permission.AccessPartnerUserManagement;
      const AccessEmployeeUserManagement: Permission = Permission.AccessEmployeeUserManagement;
      const AccessClientUserManagement: Permission = Permission.AccessClientUserManagement;

      // check what user is authorized
      if (this.userPermissionService.isAuthorizedFor(ViewRequirements)) {
        this.viewRequirementsPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(ViewInterviews)) {
        this.viewInterviewsPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(ViewContracts)) {
        this.viewContractsPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(ViewBilling)) {
        this.viewBillingPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(ViewDealEngine)) {
        this.viewDealEnginePermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(AccessPartnerUserManagement)) {
        this.accessPartnerUserManagementPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(AccessEmployeeUserManagement)) {
        this.accessEmployeeUserManagementPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(AccessClientUserManagement)) {
        this.accessClientUserManagementPermission = true;
      }
    }, 500);
  }

  ngOnInit(): void {
    this.vProfileMonthArray.forEach((element: any) => {
      if (this.currentDate == element.vMonthValue) {
        this.selectedProfileMonth = element.iMonthId;
      }
    });
    if (this.profileFilterType == 1) {
      this.startDate = moment().month(this.selectedProfileMonth).startOf('month').unix();
      this.endDate = moment().month(this.selectedProfileMonth).endOf('month').unix();
    }
    else {
      this.startDate = moment().startOf('year').unix();
      this.endDate = moment().endOf('year').unix();
    }
    this.GeCountClients();
    this.GetCountPartner();
    this.GetCountTalent();
    this.GetCountContracts();
    this.GetCountRequirements();
    this.GetCountInterviews();
    this.GetClientOverview();
    this.GetTotalBilling();
    this.GetTotalRequirements();
    this.GetDealOverview();
    this.GetCountryProfileOverview();
    this.GetInterviewsOverview();
    this.GetTalentOverview();
    this.GetRecentContracts();
  }

  GeCountClients() {
    this.dashboardService.GeCountClients().then((res: any) => {
      if (res['code'] == 200) {
        this.countClient = res.data;
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");
      }
    }).catch((err: any) => { });
  }

  GetCountPartner() {
    this.dashboardService.GetCountPartner().then((res: any) => {
      if (res['code'] == 200) {
        this.countPartner = res.data;
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }

  GetCountTalent() {
    this.dashboardService.GetCountTalent().then((res: any) => {
      if (res['code'] == 200) {
        this.countTalent = res.data;
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }

  GetCountContracts() {
    this.dashboardService.GetCountContracts().then((res: any) => {
      if (res['code'] == 200) {
        this.countContracts = res.data;
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");
      }
    }).catch((err: any) => { });
  }

  GetCountRequirements() {
    this.dashboardService.GetCountRequirements().then((res: any) => {
      if (res['code'] == 200) {
        this.countRequirements = res.data;
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }

  GetCountInterviews() {
    this.dashboardService.GetCountInterviews().then((res: any) => {
      if (res['code'] == 200) {
        this.countInterviews = res.data;
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");
      }
    }).catch((err: any) => { });
  }

  GetClientOverview() {
    this.dashboardService.GetClientOverview().then((res: any) => {
      if (res['code'] == 200) {
        this.clientOverviewSource = res.data;
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }

  GetTotalBilling() {
    this.dashboardService.GetTotalBilling().then((res: any) => {
      if (res['code'] == 200) {
        this.totalBilling = res.data;
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }

  GetTotalRequirements() {
    this.dashboardService.GetTotalRequirements(this.requirementFilterType).then((res: any) => {
      if (res['code'] == 200) {
        this.totalRequirements = res.data;
        this.requirementSource = this.totalRequirements.clientArray;
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }

  OnRequirementFilterChange(event: any) {
    this.requirementFilterType = event.value;
    this.GetTotalRequirements();
  }

  GetDealOverview() {
    this.dashboardService.GetDealOverview().then((res: any) => {
      if (res['code'] == 200) {
        this.dealsOverview = res.data;
        this.dealsDonutChartData[0].data = [res.data.dealsArray[0].activePl, res.data.dealsArray[0].inActivePl];
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }

  GetCountryProfileOverview() {
    this.dashboardService.GetCountryProfileOverview(this.startDate, this.endDate).then((res: any) => {
      if (res['code'] == 200) {
        this.countryProfileOverview = res.data;
        this.profilesByCountryBarChartLabels = this.countryProfileOverview.countryArray.map((ele: any) => ele.vCountryName);
        this.profilesByCountryBarChartData[0].data = this.countryProfileOverview.countryArray.map((ele: any) => ele.clientUserCount);
        this.profilesByCountryBarChartData[1].data = this.countryProfileOverview.countryArray.map((ele: any) => ele.partnerUserCount);
        this.profilesByCountryBarChartData[2].data = this.countryProfileOverview.countryArray.map((ele: any) => ele.employeeUserCount);
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }

  OnProfileMonthChange(event: any) {
    this.selectedProfileMonth = event.value;
    this.startDate = moment().month(this.selectedProfileMonth).startOf('month').unix();
    this.endDate = moment().month(this.selectedProfileMonth).endOf('month').unix();
    this.GetCountryProfileOverview();
  }

  OnProfileFilterChange(event: any) {
    this.profileFilterType = event.value;
    if (this.profileFilterType == 1) {
      this.startDate = moment().month(this.selectedProfileMonth).startOf('month').unix();
      this.endDate = moment().month(this.selectedProfileMonth).endOf('month').unix();
    }
    else {
      this.startDate = moment().startOf('year').unix();
      this.endDate = moment().endOf('year').unix();
    }
    this.GetCountryProfileOverview();
  }

  GetInterviewsOverview() {
    this.dashboardService.GetInterviewsOverview().then((res: any) => {
      if (res['code'] == 200) {
        this.interviewsOverview = res.data;
        this.interviewsDonutChartData[0].data = [res.data.hiredInterviewsCount, res.data.secondRoundInterviewsCount, res.data.rejectInterviewsCount];
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }

  GetTalentOverview() {
    this.dashboardService.GetTalentOverview().then((res: any) => {
      if (res['code'] == 200) {
        this.talentOverview = res.data;
        this.talentDonutChartData[0].data = [res.data.talentArray[0].hired, res.data.talentArray[0].shortListed, res.data.talentArray[0].available];
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }

  GetRecentContracts() {
    this.dashboardService.GetRecentContracts().then((res: any) => {
      if (res['code'] == 200) {
        this.recentContractSource = res.data;
      } else {
        // this.eventEmitterService.modelMessage({ "msg": res.message, "status": 1 });
        this.notifyService.showError(res.message, "");

      }
    }).catch((err: any) => { });
  }
}