<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="wrapper">
        <div class="pagetitle-wrapper">
          <div class="pagetitle-block">
            <i class="pagetitle-icon"><span class="material-icons">contact_support</span></i>
            <h2 class="pagetitle">Contact Us</h2>
          </div>
        </div>
        <mat-form-field class="w-100" floatLabel="never" class="table-filter">
          <button matSuffix mat-button>
            <mat-icon>search</mat-icon>
          </button>
          <input matInput (keyup)="applyFilterOne($event)" placeholder="Filter : Name, Email, Phone No, Message" />
        </mat-form-field>
        <div class="table-responsive">
          <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
          </div>
          <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }" [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
            <ng-container matColumnDef="indexnumber">
              <th mat-header-cell *matHeaderCellDef> No </th>
              <td mat-cell *matCellDef="let i = index;">
                {{ this.TableOnePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.TableOnePaginator.pageIndex * this.TableOnePaginator.pageSize}}
              </td>
            </ng-container>
            <ng-container matColumnDef="vName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let element">{{element.vName}}</td>
            </ng-container>
            <ng-container matColumnDef="vEmailId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
              <td mat-cell *matCellDef="let element">{{element.vEmailId}}</td>
            </ng-container>
            <ng-container matColumnDef="vPhoneNo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone No </th>
              <td mat-cell *matCellDef="let element">
                <span>{{element.vPhoneNo}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="txMessage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Message </th>
              <td mat-cell *matCellDef="let element">
                <span>{{element.txMessage}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="iCreatedAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
              <td mat-cell *matCellDef="let element">{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
          </table>
        </div>
        <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }" #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
