import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../modules/dashboard/dashboard.service';
import { NotificationService } from '../config/notification.service';



@Component({
  selector: 'app-design-system',
  templateUrl: './design-system.component.html'
})
export class DesignSystemComponent implements OnInit {
  skills: any = [
    { value: "1", viewValue: 'Frontend' },
    { value: "2", viewValue: 'Backend' },
    { value: "3", viewValue: 'Fullstack' },
  ];
  selectedSkills = "0";

  constructor(public updateService: DashboardService, private notifyService: NotificationService) { }

  ngOnInit(): void {
  }

  UpdateMilestoneReviseDate() {
    this.updateService.UpdateMilestoneReviseDate().then((result: any) => {
      if (result.code == 200) { }
    })
  }

  UpdateTaskStatus() {
    this.updateService.UpdateTaskStatus().then((result: any) => {
      if (result.code == 200) { 
        this.notifyService.showSuccess(result.message, "");
      }
    })
  }

  UpdateOldToNew(){
    this.updateService.UpdateOldToNew().then((result: any) => {
      if (result.code == 200) { 
        this.notifyService.showSuccess(result.message, "");
      }
    })
  }


  ChangeTaskStatusNameForCompleted() {
    this.updateService.ChangeTaskStatusNameForCompleted().then((result: any) => {
      if (result.code == 200) { 
        this.notifyService.showSuccess(result.message, "");
      }
    })
  }

  onclick(){
    this.updateService.dataTransfer().then((result: any) => {
      if (result.code == 200) { 
        this.notifyService.showSuccess(result.message, "");
      }
    })
  }

  valueAdditionDataTransfer(){
    this.updateService.ValueAdditiondataTransfer().then((result: any) => {
      if (result.code == 200) { 
        this.notifyService.showSuccess(result.message, "");
      }
    })
  }

  activityDataTransfer(){
    this.updateService.activityAccountTypeDataTransfer().then((result: any) => {
      if (result.code == 200) { 
        this.notifyService.showSuccess(result.message, "");
      }else{
        this.notifyService.showError(result.message, "");
      }
    })
  }

  // API Calls for MySQLtoPGSQL

  setSequenceValuePG(){
    this.updateService.SetSequenceValuePG().then((result: any) => {
      if (result.code == 200) { 
        this.notifyService.showSuccess(result.message, "");
      }else{
        this.notifyService.showError(result.message, "");
      }
    })
  }

  setAllColumnToIntegerPG(){
    this.updateService.SetAllColumnToIntegerPG().then((result: any) => {
      if (result.code == 200) { 
        this.notifyService.showSuccess(result.message, "");
      }else{
        this.notifyService.showError(result.message, "");
      }
    })
  }

  setCityStatetiStatusPG(){
    this.updateService.SetCityStatetiStatusPG().then((result: any) => {
      if (result.code == 200) { 
        this.notifyService.showSuccess(result.message, "");
      }else{
        this.notifyService.showError(result.message, "");
      }
    })
  }

}
