<div class="page-content profile-section">
  <div class="profile-top">
    <div class="d-flex justify-content-between align-items-end mb-4">
      <h1 class="title-large">View Profile</h1>
      <div>
        <button *ngIf="iProfilePublishCompleted == 1" name="publish-button" id="publish-button"
          class="primary-small-btn" type="button" name="button"
          (click)="PublishProfile(publishTalentProfileActionPermission)"
          [ngClass]="publishTalentProfileActionPermission ? '':'unauthorized-cursor'" placement="bottom auto"
          [ngbTooltip]="publishTalentProfileActionPermission ?  '':'Not Authorized'">Publish</button>
      </div>
    </div>
    <div class="progress-box" *ngIf="iProfilePublishCompleted == 1">
      <p class="text-main">Complete your profile to unlock the full potential</p>
      <p class="text-sub m-0">Completing your profile helps us understand your offerings thoroughly. Next step will be
        to onboard your team!</p>
      <div class="d-flex flex-column">
        <div class="text d-flex">
          <div class="example-spacer">
          </div>
          <span class="text-color">{{progressValue}}%</span>/100%
        </div>
        <div class="progress">
          <div class="progress-bar progress-bar-success" role="progressbar" [style.width]="this.progressValue+'%'">
          </div>
        </div>
      </div>
    </div>
    <div class="link-group mt-2">
      <a (click)="goToLink(1)" class="link" [ngClass]="targetLink == 1 ? 'link-active':''">Personal Details
        <img id="primary-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
          alt="status-icon" *ngIf="!personalDetailsCardCompleted" placement="auto" ngbTooltip="Pending"></a>
      <a (click)="goToLink(2)" class="link" [ngClass]="targetLink == 2 ? 'link-active':''">Professional Details
        <img id="company-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
          alt="status-icon" *ngIf="!professionalDetailsCardCompleted" placement="auto" ngbTooltip="Pending">
      </a>
      <a (click)="goToLink(3)" class="link" [ngClass]="targetLink == 3 ? 'link-active':''">Education
        <img id="company-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
          alt="status-icon" *ngIf="!educationCardCompleted" placement="auto" ngbTooltip="Pending">
      </a>
      <a (click)="goToLink(4)" class="link" [ngClass]="targetLink == 4 ? 'link-active':''">Documents
      </a>
      <a (click)="goToLink(5)" class="link" [ngClass]="targetLink == 5 ? 'link-active':''">Project Portfolio
        <!-- <img id="additional-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
            alt="status-icon" *ngIf="!projectPortfolioCardCompleted" placement="auto" ngbTooltip="Pending"> -->
      </a>
      <a (click)="goToLink(6)" class="link" [ngClass]="targetLink == 6 ? 'link-active':''">Rewards
        <img id="certifications-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
          alt="status-icon" *ngIf="!awardsDetailsCardCompleted" placement="auto" ngbTooltip="Pending">
      </a>
      <a (click)="goToLink(7)" class="link" [ngClass]="targetLink == 7 ? 'link-active':''">Certifications
        <img id="certifications-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
          alt="status-icon" *ngIf="!certificateDetailsCardCompleted" placement="auto" ngbTooltip="Pending">
      </a>
    </div>
  </div>
  <div class="profile-main" [ngClass]="{'profile-main-height': iProfilePublishCompleted != 1}">
    <perfect-scrollbar class="pf-scroll">
      <div #personalDetails *ngIf="targetLink==1">
        <talent-personal-details [talentUserId]="talentUserId"></talent-personal-details>
      </div>
      <div #professionalDetails *ngIf="targetLink==2">
        <talent-professional-details [talentUserId]="talentUserId"></talent-professional-details>
      </div>
      <div #education *ngIf="targetLink==3">
        <talent-education [talentUserId]="talentUserId"></talent-education>
      </div>
      <div #documents *ngIf="targetLink==4">
        <talent-documents [talentUserId]="talentUserId"></talent-documents>
      </div>
      <div #projectPortfolio *ngIf="targetLink==5">
        <talent-project-portfolio [talentUserId]="talentUserId"></talent-project-portfolio>
      </div>
      <div #awards *ngIf="targetLink==6">
        <talent-awards [talentUserId]="talentUserId"></talent-awards>
      </div>
      <div #certifications *ngIf="targetLink==7">
        <talent-certifications [talentUserId]="talentUserId"></talent-certifications>
      </div>
    </perfect-scrollbar>
  </div>
</div>