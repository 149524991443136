<div name="certifications-box" id="certifications-box" class="col-12">
  <div name="certifications-card-container" id="certifications-card-container" class="card-container" *ngIf="certificationsToggle==false">
    <div name="certifications-card-header" id="certifications-card-header" class="card-header">
      <div name="certifications-title-section" id="certifications-title-section" class="title-section">
        <h4 name="certifications-card-title" id="certifications-card-title" class="card-title">Certifications</h4>
        <button name="certifications-card-edit-button" id="certifications-card-edit-button" class="card-edit-button" type="button" (click)="toggleCertifications(editClientCertificationsActionPermission)" [ngClass]="editClientCertificationsActionPermission ? '':'unauthorized-cursor'" placement="right auto" [ngbTooltip]="editClientCertificationsActionPermission ?  '':'Not Authorized'"><img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button"></button>
      </div>
      <div name="certifications-incomplete-status-section" id="certifications-incomplete-status-section" class="status-section">
        <div name="certifications-incomplete-status-box" id="certifications-incomplete-status-box" class="status-box" *ngIf="!certificationsCardCompleted">
          <img id="certifications-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'" alt="status-icon">
          <label name="certifications-incomplete-status-text" id="certifications-incomplete-status-text" class="status-text incomplete">Pending</label>
        </div>
        <div name="certifications-completed-status-box" id="certifications-completed-status-box" class="status-box" *ngIf="certificationsCardCompleted">
          <img id="certifications-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'" alt="status-icon">
          <label name="certifications-completed-status-text" id="certifications-completed-status-text" class="status-icon" class="status-text completed">Completed</label>
        </div>
      </div>
    </div>
    <div name="certifications-card" id="certifications-card" class="card" [ngClass]="certificationsCardCompleted ? 'completed' : 'incomplete'">
      <div name="certifications-card-content" id="certifications-card-content" class="card-content">
        <div name="certifications-cmmi-level-text-box-with-icon" id="certifications-cmmi-level-text-box-with-icon" class="text-box-with-icon">
          <img id="certifications-cmmi-level-icon" alt="certificate-icons" class="icon" [src]="certificationsCardCompleted ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'">
          <label name="certifications-cmmi-level-text" id="certifications-cmmi-level-text" class="text" [ngClass]="{'disabled': !cmmiStatus}">CMMI Level Certificate</label>
        </div>
      </div>
    </div>
  </div>
  <div class="card-container" *ngIf="certificationsToggle==true">
    <div name="company-certification-card-header" id="company-certification-card-header" class="card-header">
      <div name="company-certification-title-section" id="company-certification-title-section" class="title-section">
        <h4 name="company-certification-card-title" id="company-certification-card-title" class="card-title">Company Certifications</h4>
      </div>
    </div>
    <div class="edit-profile">
      <div name="company-certification-modal-body" id="company-certification-modal-body" class="modal-body modal-scroll">
        <form name="certificationForm" id="certificationForm" class="form" [formGroup]="certificationForm" (ngSubmit)="UploadCertificate()">
          <div name="company-certification-modal-row" id="company-certification-modal-row" class="row">
            <div name="certification-modal-cmmi-level-certificate-title-class" id="certification-modal-cmmi-level-certificate-title-class" class="col-12">
              <div class="bold-title d-flex flex-column flex-sm-row">
                <label name="certification-modal-cmmi-level-certificate-title" id="certification-modal-cmmi-level-certificate-title">
                  CMMI Level Certificate
                </label>
                <mat-checkbox name="certification-modal-cmmi-level-certificate-checkbox" id="certification-modal-cmmi-level-certificate-checkbox" class="custom-checkbox mx-0 mx-sm-3" (change)="CheckApplicable($event)" [checked]="iClientCMMIRequired" *ngIf="cmmiCertificates.length < 1">Check if not applicable</mat-checkbox>
              </div>
            </div>
            <div name="certification-modal-cmmi-certificate" id="certification-modal-cmmi-certificate" class="col-12 col-md-6 field-box" [ngClass]="iClientCMMIRequired ? 'opacity-4' : ''">
              <label for="vCMMIId" name="certification-modal-cmmi-certificate-label" id="certification-modal-cmmi-certificate-label" class="custom-label">Certificate Level <span class="text-danger">*</span></label>
              <mat-form-field name="mat-form-field-certification-modal-cmmi-certificate" id="mat-form-field-certification-modal-cmmi-certificate" hideRequiredMarker floatLabel="never" floatPlaceholder="never" class="custom-dropdown form">
                <mat-select name="vCMMIId" id="vCMMIId" formControlName="vCMMIId" placeholder="CMMI">
                  <mat-option name="mat-option-certification-modal-cmmi-certificate" id="mat-option-certification-modal-cmmi-certificate" *ngFor="let item of CMMIList; let i = index" [value]="item.iMasterCerId">
                    {{ item.vName }}
                  </mat-option>
                </mat-select>
                <mat-error name="mat-error-certification-modal-cmmi-certificate" id="mat-error-certification-modal-cmmi-certificate" class="reduce-bottom-space" *ngIf="certificationForm.controls['vCMMIId'].invalid">
                  <mat-error name="mat-error-certification-modal-cmmi-certificate-text-1" id="mat-error-certification-modal-cmmi-certificate-text-1" class="reduce-bottom-space" *ngIf="certificationForm.controls['vCMMIId'].errors?.required">
                    Certificate Level is required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="certification-modal-cmmi-certificate-name" id="certification-modal-cmmi-certificate-name" class="col-12 col-md-6 field-box" [ngClass]="iClientCMMIRequired ? 'opacity-4' : ''">
              <label for="vCMMICerNumber" name="certification-modal-cmmi-certificate-name-label" id="certification-modal-cmmi-certificate-name-label" class="custom-label">Certificate Number <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput name="vCMMICerNumber" id="vCMMICerNumber" formControlName="vCMMICerNumber" placeholder="0000000000">
                <mat-error name="mat-error-certification-modal-cmmi-certificate-name" id="mat-error-certification-modal-cmmi-certificate-name" class="reduce-bottom-space" *ngIf="certificationForm.controls['vCMMICerNumber'].invalid">
                  <mat-error name="mat-error-certification-modal-cmmi-certificate-name-text-1" id="mat-error-certification-modal-cmmi-certificate-name-text-1" class="reduce-bottom-space" *ngIf="certificationForm.controls['vCMMICerNumber'].errors!.required">
                    Certificate Number is required
                  </mat-error>
                  <mat-error class="reduce-bottom-space" *ngIf="(certificationForm.controls['vCMMICerNumber'].errors!.minlength || certificationForm.controls['vCMMICerNumber'].errors!.maxlength) && !certificationForm.controls['vCMMICerNumber'].errors!.pattern">
                    Length must be between 5-12
                  </mat-error>
                  <mat-error name="mat-error-certification-modal-cmmi-certificate-name-text-1" id="mat-error-certification-modal-cmmi-certificate-name-text-1" class="reduce-bottom-space" *ngIf="certificationForm.controls['vCMMICerNumber'].errors!.pattern">
                    No white space allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="certification-modal-cmmi-certificate-pdf" id="certification-modal-cmmi-certificate-pdf" class="col-12 col-md-6 field-box" [ngClass]="iClientCMMIRequired ? 'opacity-4' : ''">
              <label name="certification-modal-cmmi-certificate-pdf-label" id="certification-modal-cmmi-certificate-pdf-label" for="vCMMICertificatePdf" class="custom-label">Upload PDF <span class="text-danger">*</span></label>
              <mat-form-field name="mat-form-field-certification-modal-cmmi-certificate-pdf" id="mat-form-field-certification-modal-cmmi-certificate-pdf" hideRequiredMarker class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div name="certification-modal-cmmi-certificate-pdf-file-container" id="certification-modal-cmmi-certificate-pdf-file-container" class="file-container">
                  <div name="certification-modal-cmmi-certificate-pdf-files" id="certification-modal-cmmi-certificate-pdf-files" class="files">
                    <div name="certification-modal-cmmi-certificate-pdf-file-scroll" id="certification-modal-cmmi-certificate-pdf-file-scroll" class="file-scroll-box" *ngIf="cmmiCertificate">
                      <perfect-scrollbar [config]="config">
                        <div name="certification-modal-cmmi-certificate-pdf-show-file" id="certification-modal-cmmi-certificate-pdf-show-file" class="file">
                          {{ cmmiCertificate.name }}
                          <button name="certification-modal-cmmi-certificate-pdf-close-btn" id="certification-modal-cmmi-certificate-pdf-close-btn" class="btn" type="button" name="button" (click)="CancelMedia()"><mat-icon name="certification-modal-cmmi-certificate-pdf-close-icon" id="certification-modal-cmmi-certificate-pdf-close-icon">close</mat-icon></button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="certification-modal-cmmi-certificate-pdf-input-1" id="certification-modal-cmmi-certificate-pdf-input-1" matInput readonly formControlName="vCMMICertificatePdf" [value]="cmmiCertificate ? ' ' : ''" placeholder="Upload PDF" />
                <input
                  name="certification-modal-cmmi-certificate-pdf-input-1" id="certification-modal-cmmi-certificate-pdf-input-1"
                  matSuffix
                  type="file"
                  #uploadFile
                  id="uploadFile"
                  class="file-upload-input"
                  (change)="FileBrowseHandler($event,3)"
                  formControlName="vCMMICertificatePdf"
                  accept="application/pdf"
                />
                <button name="certification-modal-cmmi-certificate-pdf-upload-btn" id="certification-modal-cmmi-certificate-pdf-upload-btn" class="upload-btn" matSuffix>
                  <img id="certification-modal-cmmi-certificate-pdf-upload-img" [src]="imagePath + 'file-upload-icon.svg'" alt="file upload">
                </button>
                <mat-error name="mat-error-certification-modal-cmmi-certificate-pdf" id="mat-error-certification-modal-cmmi-certificate-pdf" class="reduce-bottom-space" *ngIf="certificationForm.controls['vCMMICertificatePdf'].invalid">
                  <mat-error name="mat-error-certification-modal-cmmi-certificate-pdf-text-1" id="mat-error-certification-modal-cmmi-certificate-pdf-text-1" class="reduce-bottom-space" *ngIf="certificationForm.controls['vCMMICertificatePdf'].errors!.required">
                    Pdf is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 1MB PDF required</mat-hint>
              </mat-form-field>
            </div>
            <div name="cmmi-certi-image-display" id="cmmi-certi-image-display" class="col-12" *ngIf = "cmmiCertificates">
              <div name="cmmi-certi-image-class-1" id="cmmi-certi-image-class-1" class="image-display-container margin-class" *ngFor = "let cmmi of cmmiCertificates; let i = index">
                <div name="cmmi-certi-name-1" id="cmmi-certi-name-1" class="image-name">
                  <label>{{i+1}}</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="cmmi?.cerUrl" target="_blank">{{ cmmi?.MasterPartnerCert }}</a>
                </div>
                <button type="button" name="button" (click) = "DeleteCertificate(cmmi?.iUserCertificateId)">
                  <img id="cmmi-certi-img-1" alt="delete-certificate" [src]="imagePath + 'delete_image.svg'">
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div name="company-certification-modal-footer" id="company-certification-modal-footer" class="modal-footer">
        <button type="button" name="company-certification-modal-cancel-btn" id="company-certification-modal-cancel-btn" class="blue-secondary-small-btn mx-3" (click)="this.certificationsToggle=false; this.certificationSubmitted = false;GetCMMICertificates()">Cancel</button>
        <button type="submit" name="company-certification-modal-submit-btn" id="company-certification-modal-submit-btn" class="blue-primary-small-btn" form="certificationForm">Save</button>
      </div>
    </div>
  </div>
</div>


