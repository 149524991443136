import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientPublishService {

  constructor(private http: HttpClient) { }

  GetPublish(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/client-publish`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  acceptPublish(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/client-publish-accept`;
      this.http
        .post(apiURL,data)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  rejectPublish(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v1/client-publish-reject`;
      this.http
        .post(apiURL,data)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  GetProfileStatus(iUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/client-profile-status/?iUserId=`+iUserId;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
