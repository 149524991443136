import { Component, OnInit } from '@angular/core';
import { RequirementsService } from '../requirements.service';

import { EventEmitterService } from 'src/app/config/event-emitter.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-requirement-details',
  templateUrl: './requirement-details.component.html'
})
export class RequirementDetailsComponent implements OnInit {

  requirementId: any;
  getExperience: any;

  constructor(private reqService: RequirementsService,
    public eventEmitterService: EventEmitterService,
    private activatedroute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.requirementId = this.activatedroute.snapshot.paramMap.get("requirementID");
    this.GetRequirementDetailsById();


  }

  getRequirementDetailsHeaderDataByID: any = [];
  getRequirementDetailsSectionByID: any = [];
  RequirementTeam: any = [];
  skillsArray: any = [];

  GetRequirementDetailsById() {
    this.reqService.GetRequirementDetailsById(this.requirementId).then((result: any) => {
      // console.log(result);

      if (result['code'] == 200) {
        this.skillsArray = result.data.SkillArray;
        this.getRequirementDetailsHeaderDataByID = result.data;
        this.getRequirementDetailsSectionByID = result.data.RequirementTalents;
        this.RequirementTeam = result.data.RequirementTeam;
      }
      else {
        this.eventEmitterService.modelMessage({ "msg": result.message, "status": 1 });
      }
    }).catch((err: any) => {
      console.log(err);
      // this.ParentReqId = undefined;
      // this.ParentReqName = undefined;
    });
  }

}
