import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PmsGrowthService } from './pms-growth.service';
import { EventEmitterService } from '../../config/event-emitter.service';
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';
import { element } from 'protractor';

@Component({
  selector: 'app-pms-growth',
  templateUrl: './pms-growth.component.html',
  styles: [
  ]
})
export class PmsGrowthComponent implements OnInit {

    // toggle actions permission
    addGrowthParameterActionPermission: boolean = false;
    editGrowthParameterActionPermission: boolean = false;
    activateDeactivateGrowthParameterActionPermission: boolean = false;
  
    iPmsGrowthId: number;
    GrowthParameterTypeList: any = [
      { id: 1, tiPmsGrowthType: "CSAT" },
      { id: 2, tiPmsGrowthType: "NPS" },
      { id: 3, tiPmsGrowthType: "WSR" }
    ];
  
    addGrowthParameterForm!: FormGroup;
    editGrowthParameterForm!: FormGroup;
  
    displayedColumnsTableOne: string[] = ['indexnumber', 'vPmsGrowthName', 'tiPmsGrowthType', 'iCreatedAt', 'checked', 'actions'];
    dataSourceTableOne = new MatTableDataSource();
  
    @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
    @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;
  
    constructor(
      private pmsGrowthService: PmsGrowthService,
      public eventEmitterService: EventEmitterService,
      private modalService: NgbModal,
      private notifyService: NotificationService,
      config: NgbModalConfig,
      private userPermissionService: UserPermissionService
    ) {
      config.backdrop = 'static';
      config.keyboard = false;
  
      // assign individual permissions
      const AddGrowthParameterAction: Permission = Permission.AddGrowthParameterAction;
      const EditGrowthParameterAction: Permission = Permission.EditGrowthParameterAction;
      const ActivateDeactivateGrowthParameterAction: Permission = Permission.ActivateDeactivateGrowthParameterAction;
  
      // check what user is authorized
      if (this.userPermissionService.isAuthorizedFor(AddGrowthParameterAction)) {
        this.addGrowthParameterActionPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(EditGrowthParameterAction)) {
        this.editGrowthParameterActionPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(ActivateDeactivateGrowthParameterAction)) {
        this.activateDeactivateGrowthParameterActionPermission = true;
      }
    }
  
    ngOnInit(): void {
      this.GetGrowthParameter();
  
      this.addGrowthParameterForm = new FormGroup({
        vPmsGrowthName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
        tiPmsGrowthType: new FormControl('', [Validators.required])
      });
  
      this.editGrowthParameterForm = new FormGroup({
        iPmsGrowthId: new FormControl('', []),
        vPmsGrowthName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
        tiPmsGrowthType: new FormControl('', [Validators.required])
      });
    }

    ngAfterViewInit() {
      this.dataSourceTableOne.paginator = this.tableOnePaginator;
      this.dataSourceTableOne.sort = this.tableOneSort;
    }
  
  
    OpenModal(modalToOpen: any, hasPermission: boolean) {
      if (hasPermission) {
        this.modalService.open(modalToOpen, { centered: true });
      }
      else {
        this.addGrowthParameterForm.reset();
      }
    }
  
    OpenModelEditGrowthParameter(modelName: any, element: any, hasPermission: boolean) {
      if (hasPermission) {
        this.editGrowthParameterForm.patchValue({
          iPmsGrowthId: element['iPmsGrowthId'],
          vPmsGrowthName: element['vPmsGrowthName'],
          tiPmsGrowthType: element['tiPmsGrowthType']
        })
        this.modalService.open(modelName, { centered: true });
      }
    }
  
  
    applyFilterOne(filterValue: any) {
      this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
    }
  
    GetGrowthParameter() {
      this.pmsGrowthService.GetGrowthParameter().then((result: any) => {
        if (result['code'] == 200) {
          this.dataSourceTableOne.data = result['data'];
        } else {
          this.notifyService.showError(result.message, '');
        }
      }).catch((err: any) => { console.log(err) });
    }
  
    AddGrowthParameter() {
      if (this.addGrowthParameterForm.invalid) {
        return;
      }
      let reqData = {
        vPmsGrowthName: this.addGrowthParameterForm.value.vPmsGrowthName,
        tiPmsGrowthType: this.addGrowthParameterForm.value.tiPmsGrowthType
      }
      this.pmsGrowthService.AddGrowthParameter(reqData).then((result: any) => {
        if (result['code'] == 201) {
          this.modalService.dismissAll();
          this.addGrowthParameterForm.reset();
          this.notifyService.showSuccess(result.message, '');
          this.GetGrowthParameter();
        } else {
          this.notifyService.showError(result.message, '');
        }
      }).catch((err:any) => {
        console.log(err, 'AddGrowthParameter');
      });
    }
  
    EditGrowthParameter() {
      if (this.editGrowthParameterForm.invalid) {
        return;
      }
      let reqData = {
        iPmsGrowthId: this.editGrowthParameterForm.value.iPmsGrowthId,
        vPmsGrowthName: this.editGrowthParameterForm.value.vPmsGrowthName,
        tiPmsGrowthType: this.editGrowthParameterForm.value.tiPmsGrowthType
      }
      this.pmsGrowthService.EditGrowthParameter(reqData).then((result: any) => {
        if (result['code'] == 200) {
          this.notifyService.showSuccess(result.message, '');
          this.modalService.dismissAll();
          this.GetGrowthParameter();
        } else {
          this.modalService.dismissAll();
          this.notifyService.showError(result.message, '');
        }
      }).catch((err:any) => {
        console.log(err, "EditGrowthParameter");
      });
    }
  

    EditStatusGrowthParameter(event:any, element: any,hasPermission:any){
      if(hasPermission){
      let iPmsGrowthId= element.iPmsGrowthId;
      let reqData = {
        iPmsGrowthId:iPmsGrowthId,
        tiStatus: event.checked ? 1: 0
      }
       this.pmsGrowthService.EditStatusGrowthParameter(reqData).then((result: any) => {
          if (result['code'] == 200) {
            this.notifyService.showSuccess(result.message, '');
           this.modalService.dismissAll();
            this.GetGrowthParameter();
         } else {
           this.modalService.dismissAll();
            this.notifyService.showError(result.message, '');
          }
          }).catch((err:any) => {
            console.log(err, "EditAdminGrowthParameter");
          });

        } else{
         event.checked = !event.checked;
          this.notifyService.showError("Not Authorized!", '');
        }
    }

}
