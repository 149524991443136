import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private http:HttpClient) { }

  ChangePassword(data:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v1/change-password`;
        this.http
          .put(apiURL,data)
          .toPromise()
          .then((res) => {
            // Success
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    return promise;
  }

  SubUserChangePassword(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v2/change-subuser-password`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
