import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { EventEmitterService } from '../../../../config/event-emitter.service';
import { DocumentsService } from './documents.service';
import { NotificationService } from '../../../../config/notification.service';
import { StaticMsg } from '../../../../config/en';
import { environment } from "../../../../../environments/environment";
import { UserPermissionService } from "src/app/utils/services/user-permission.service";
import { Permission } from 'src/app/utils/enums/permission.enum';

@Component({
  selector: 'talent-documents',
  templateUrl: './documents.component.html',
  providers: [
    NgbModalConfig,
    NgbModal
  ]
})
export class TalentDocumentsComponent implements OnInit {
  @Input() talentUserId:any;
  documentsToggle:boolean=false;

  editTalentDocumentsActionPermission: boolean = false;

  imagePath = environment.image_path;
  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false
  };

  documentsForm!: FormGroup;
  documentsSubmitted: any = false;

  @ViewChild('uploadFile') fileInput: any;
  vAadharPdf: any;
  vPanPdf: any;
  vPassportPdf: any;
  vDrivingPdf : any;
  documentsData: any = [];
  documentsStatus: any;
  documentsFormError: any = false;

  constructor(
    private userPermissionService: UserPermissionService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private documentsService: DocumentsService,
    public eventEmitterService: EventEmitterService,
    private notifyService : NotificationService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;

     // assign individual permissions
    const EditTalentDocumentsAction: Permission = Permission.EditTalentDocumentsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(EditTalentDocumentsAction)) {
      this.editTalentDocumentsActionPermission = true;
    }

  }

  ngOnInit(): void {
    this.GetDocuments();
    // this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data:any) => {
    //   this.updateProfileStatus(data);
    // });

    // Old Form with required validation
    // this.documentsForm = new FormGroup({
    //   iAadharCardNumber: new FormControl('', []),
    //   vAadharPdf: new FormControl('', [Validators.required]),
    //   iPanNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}') ]),
    //   vPanPdf: new FormControl('', [Validators.required]),
    //   iUanNumber: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.maxLength(12),Validators.minLength(12),Validators.pattern('^[a-zA-Z\\d]*$') ]),
    //   iPassportNumber: new FormControl('', [Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[A-PR-WYa-pr-wy][1-9]\\d\\s?\\d{4}[1-9]$') ]),
    //   vPassportPdf: new FormControl('', []),
    //   iDrivingLicenseNumber: new FormControl(''),
    //   vDrivingPdf: new FormControl('',[])
    // });

    this.documentsForm = new FormGroup({
      iAadharCardNumber: new FormControl('', []),
      vAadharPdf: new FormControl(''),
      iPanNumber: new FormControl('', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}') ]),
      vPanPdf: new FormControl(''),
      iUanNumber: new FormControl('', [Validators.minLength(12), Validators.maxLength(12), Validators.maxLength(12),Validators.minLength(12),Validators.pattern('^[a-zA-Z\\d]*$') ]),
      iPassportNumber: new FormControl('', [Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[A-PR-WYa-pr-wy][1-9]\\d\\s?\\d{4}[1-9]$') ]),
      vPassportPdf: new FormControl('', []),
      iDrivingLicenseNumber: new FormControl(''),
      vDrivingPdf: new FormControl('',[])
    });
  }

  // public documentsCardCompleted:any = false;
  // updateProfileStatus(data:any){
  //   this.documentsCardCompleted = data['documentsCardCompleted'];
  // }

  FileBrowseHandler(event: any, type: any) {
    let fileSize = event['target']['files'][0]['size'] / 1024;
    if ( fileSize < 4*1024 ) {
      if ( event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/vnd.ms-excel" || event.target.files[0].type == "image/x-png" || event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" ) {
        this.documentsFormError = false;
        if ( type == 1 ) {
          this.vAadharPdf = event.target.files[0];
        }
        if ( type == 2 ) {
          this.vPanPdf = event.target.files[0];
        }
        if ( type == 3 ) {
          this.vPassportPdf = event.target.files[0];
        }
        if ( type == 4 ) {
          this.vDrivingPdf = event.target.files[0];
        }
      } else {
        this.documentsFormError = true;
        this.notifyService.showError(StaticMsg.NotPdfImageFileError,'');
        return;
      } 
    } else {
      this.documentsFormError = true;
      this.notifyService.showError(StaticMsg.FileLargerError,'');
      if ( type == 1 ) {
        this.vAadharPdf = '';
        this.documentsForm.controls['vAadharPdf'].patchValue('');
      }
      if ( type == 2 ) {
        this.vPanPdf = '';
        this.documentsForm.controls['vPanPdf'].patchValue('');
      }
      if ( type == 3 ) {
        this.vPassportPdf = '';
        this.documentsForm.controls['vPassportPdf'].patchValue('');
      }
      if ( type == 4 ) {
        this.vDrivingPdf = '';
        this.documentsForm.controls['vDrivingPdf'].patchValue('');
      }
      return;
    }
  }

  RemoveMedia(type: any) {
    if ( type == 1 ) {
      this.vAadharPdf = '';
      this.documentsForm.controls['vAadharPdf'].patchValue('');
    }
    if ( type == 2 ) {
      this.vPanPdf = '';
      this.documentsForm.controls['vPanPdf'].patchValue('');
    }
    if ( type == 3 ) {
      this.vPassportPdf = '';
      this.documentsForm.controls['vPassportPdf'].patchValue('');
    }
    if ( type == 4 ) {
      this.vDrivingPdf = '';
      this.documentsForm.controls['vDrivingPdf'].patchValue('');
    }
  }



  CancelDocumentsModal() {
    this.documentsToggle=false;
  }

  GetDocuments(){
    this.documentsService.GetDocuments(this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.documentsData = result['data'];
        this.documentsStatus = result['documentsStatus'];
        if(this.documentsStatus.vAadharPdf) {
          this.documentsForm.controls['vAadharPdf'].clearValidators();
        } else {
          // this.documentsForm.controls['vAadharPdf'].setValidators([Validators.required]);
          this.documentsForm.controls['vAadharPdf'].patchValue('');
        }
        if(this.documentsStatus.vPanPdf) {
          this.documentsForm.controls['vPanPdf'].clearValidators();
        } else {
          // this.documentsForm.controls['vPanPdf'].setValidators([Validators.required]);
          this.documentsForm.controls['vPanPdf'].patchValue('');
        }
        if(this.documentsStatus.vDrivingPdf) {
          this.documentsForm.controls['vDrivingPdf'].clearValidators();
        } else {
          // this.documentsForm.controls['vDrivingPdf'].setValidators();
          this.documentsForm.controls['vDrivingPdf'].patchValue('');
        }
        if(this.documentsStatus.vPassportPdf) {
          this.documentsForm.controls['vPassportPdf'].clearValidators();
        } else {
          this.documentsForm.controls['vPassportPdf'].setValidators([]);
          this.documentsForm.controls['vPassportPdf'].patchValue('');
        }
        this.documentsForm.patchValue({
          iAadharCardNumber: result['vAadhaar'],
          iPanNumber: result['data']['iPanNumber'],
          iUanNumber: result['data']['iUanNumber'],
          iPassportNumber: (result['data']['iPassportNumber']=== undefined)?'':result['data']['iPassportNumber'],
          iDrivingLicenseNumber: (result['data']['iDrivingLicenseNumber']== undefined)?'':result['data']['iDrivingLicenseNumber']
        })
      }
    });
  }
  // DocumentUpdat
  UploadDocuments() {
    this.documentsSubmitted = true;
    if (this.documentsForm.invalid || this.documentsFormError) {
      return;
    }
    let reqData = {
      iAadharCardNumber: this.documentsForm.value.iAadharCardNumber,
      vAadharPdf: this.vAadharPdf,
      iPanNumber: this.documentsForm.value.iPanNumber?this.documentsForm.value.iPanNumber:'',
      vPanPdf: this.vPanPdf,
      iUanNumber: this.documentsForm.value.iUanNumber?this.documentsForm.value.iUanNumber:'',
      iPassportNumber: this.documentsForm.value.iPassportNumber,
      vPassportPdf: this.vPassportPdf,
      iDrivingLicenseNumber: this.documentsForm.value.iDrivingLicenseNumber,
      vDrivingPdf: this.vDrivingPdf
    }
    this.documentsService.UpdateDocuments(reqData,this.talentUserId).then((result:any) => {
      if (result.code == 200) {
        this.documentsForm.reset();
        this.notifyService.showSuccess(result.message,'');
        this.GetDocuments();
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.documentsToggle=false;
      }
    },
    (err:any) => {
      console.log(err,"UpdateCompanyDetails");
    })
  }

  DeleteDocument(document:any){
    this.documentsService.DeleteDocument(document,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetDocuments();
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.notifyService.showSuccess(result.message,'');
      }
    });
  }

  toggleDocuments(hasPermission:boolean){
    if(hasPermission){
      this.documentsForm.reset();
      this.GetDocuments();
      this.vAadharPdf = '';
      this.vPanPdf = ''; 
      this.vPassportPdf = '';
      this.vDrivingPdf = '';
    this.documentsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }
}
