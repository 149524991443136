import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor( private http: HttpClient ) { }

  UpdateDocuments(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/documents?iUserId=${talentUserId}`;
      const formData: FormData = new FormData();
      formData.append("iAadharCardNumber", data.iAadharCardNumber);
      formData.append("vAadharPdf", data.vAadharPdf);
      formData.append("iPanNumber", data.iPanNumber);
      formData.append("vPanPdf", data.vPanPdf);
      formData.append("iUanNumber", data.iUanNumber);
      formData.append("iPassportNumber", data.iPassportNumber);
      formData.append("vPassportPdf", data.vPassportPdf);
      formData.append("iDrivingLicenseNumber", data.iDrivingLicenseNumber);
      formData.append("vDrivingPdf", data.vDrivingPdf);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetDocuments(talentUserId:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/documents?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteDocument(document:any,talentUserId:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/document/${document}?iUserId=${talentUserId}`;
      this.http
        .delete(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
