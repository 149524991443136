<mat-sidenav-container class="ins-sidenav partner mat-drawer-transition">

  <mat-sidenav #appDrawer mode="over" opened="false" class="side-nav-start">
    <div class="side-menu-logo">
      <span class="logo-desktop">
        <img src="./assets/images/rapidise-logo.svg" alt="Rapidise" routerLink="/dashboard" />
      </span>
      <span class="logo-mobile">
        <img src="./assets/images/rapidise-logo-icon.svg" alt="Rapidise" routerLink="/dashboard" />
      </span>
    </div>
    <perfect-scrollbar>
      <mat-nav-list class="menu-items">
        <app-menu-list-item *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
        <button mat-list-item class="menu-list-item" (click)="Logout()">
          <mat-icon class="routeIcon">
            <img src="./assets/images/logout.svg" alt="logout" width="24px" height="24px">
          </mat-icon>
          Sign out
        </button>
      </mat-nav-list>
    </perfect-scrollbar>
  </mat-sidenav>

  <mat-sidenav-content class="main-container">
    <mat-toolbar class="header d-flex justify-content-between w-100">
      <div class="side-content-logo">
        <span class="logo-desktop">
          <img src="./assets/images/rapidise-logo.svg" alt="Rapidise" routerLink="/dashboard" />
        </span>
        <span class="logo-mobile">
          <img src="./assets/images/rapidise-logo-icon.svg" alt="Rapidise" routerLink="/dashboard" />
        </span>
      </div>
      <div class="w-100">
        <div class="d-flex">
          <app-top-nav class="w-100 d-flex"></app-top-nav>
        </div>
      </div>
    </mat-toolbar>

    <perfect-scrollbar>
      <div class="inner-page">
        <router-outlet></router-outlet>
      </div>
    </perfect-scrollbar>
  </mat-sidenav-content>

</mat-sidenav-container>