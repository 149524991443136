import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PmsGrowthService {

  
  constructor( private http: HttpClient ) { }

  GetGrowthParameter(){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v2/get-growth-parameter`;
        this.http
          .get(apiURL)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }

  AddGrowthParameter(data: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v2/add-growth-parameter`;
        this.http
          .post(apiURL,data)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }

  EditGrowthParameter(data: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v2/update-growth-parameter`;
        this.http
          .put(apiURL,data)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }

  EditStatusGrowthParameter(data: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v2/change-growth-status`;
        this.http
          .put(apiURL,data)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }
}
