<div class="container-fluid skill-management">
  <div class="row">
    <div class="col-12">
      <div class="wrapper">
        <div class="pagetitle-wrapper">
          <div class="pagetitle-block d-flex justify-content-between flex-column flex-sm-row">
            <div class="d-flex align-items-center mb-3 mb-sm-0">
              <i class="pagetitle-icon"><span class="material-icons">fact_check</span></i>
              <h2 class="pagetitle">Ticket Management</h2>
            </div>
          </div>
        </div>

        <div class="nav flex-row nav-pills row w-100 d-flex justify-content-between ml-0" id="v-pills-tab" role="tablist" aria-orientation="horizontal">
          <a class="nav-link active col-12 col-sm-6 col-lg-4 col-xl-2" id="partner-tab" data-toggle="pill" href="#partner" role="tab" aria-controls="partner" aria-selected="false">Partner Profile</a>
          <a class="nav-link col-12 col-sm-6 col-lg-4 col-xl-2" id="client-tab" data-toggle="pill" href="#client" role="tab" aria-controls="client" aria-selected="false">Client Profile</a>
          <a class="nav-link col-12 col-sm-6 col-lg-4 col-xl-2" id="employee-tab" data-toggle="pill" href="#employee" role="tab" aria-controls="employee" aria-selected="false">Employee Profile</a>
          <a class="nav-link col-12 col-sm-6 col-lg-6 col-xl-2" id="interview-tab" data-toggle="pill" href="#interview" role="tab" aria-controls="interview" aria-selected="true">Interview</a>
          <a class="nav-link col-12 col-lg-6 col-xl-2" id="contract-tab" data-toggle="pill" href="#contract" role="tab" aria-controls="contract" aria-selected="false">Contract</a>
        </div>

        <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="partner" role="tabpanel" aria-labelledby="ticket-management-tab">
            <mat-form-field class="w-100" floatLabel="never" class="table-filter">
              <button matSuffix mat-button>
                <mat-icon>search</mat-icon>
              </button>
              <input matInput (keyup)="applyFilterPartner($event)" placeholder="Filter : Ticket Type, Email" />
            </mat-form-field>
            <div class="table-responsive">
              <div *ngIf="dataSourcePartnerTable?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
              </div>
              <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourcePartnerTable?.filteredData?.length <= 0 }" [dataSource]="dataSourcePartnerTable" matSort #PartnerTableSort="matSort" class="datatable">
                <ng-container matColumnDef="indexnumber">
                  <th mat-header-cell *matHeaderCellDef> No </th>
                  <td mat-cell *matCellDef="let i = index;">
                    {{ this.PartnerTablePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.PartnerTablePaginator.pageIndex * this.PartnerTablePaginator.pageSize}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="vDisplayUniqueId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> User ID </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.vDisplayUniqueId}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="vEmailId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket For </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                      <div>{{element.vEmailId}}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="createdBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                      <div>{{element.createdByName}}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="tiStatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Status </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.tiStatus == '1'" class="status status-pending"><span class="material-icons">hourglass_empty</span>Pending</span>
                    <span *ngIf="element.tiStatus == '2'" class="status status-active"><span class="material-icons">done</span>Approved</span>
                    <span *ngIf="element.tiStatus == '3'" class="status status-inactive"><span class="material-icons">thumb_down_off_alt</span>Rejected</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="iCreatedAt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td mat-cell *matCellDef="let element">{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-wrap">
                      <div>
                        <button class="status-btn lightblue-bg blue-text text-center mb-2" (click)="openModalWithData(employeeProfileView,element)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Details">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                      </div>
                      <div class="d-flex">
                        <button *ngIf="element.tiStatus == 1" class="status-btn lightgreen-bg green-text" (click)="clickApproveReject(null,element,'accept')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Approve"><span
                            class="material-icons">check_circle</span></button>
                        <button *ngIf="element.tiStatus == 1" class="status-btn lightpink-bg pink-text" (click)="clickApproveReject(rejectReason,element,'reject')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Reject"><span
                            class="material-icons">cancel</span></button>
                        <button *ngIf="element.tiStatus == 2 || element.tiStatus == 3" class="status-btn lightgray-bg gray-text" data-bs-toggle="tooltip" data-bs-placement="bottom" title="No Action"><span
                            class="material-icons">hide_source</span></button>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsPartnerTable; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsPartnerTable;"></tr>
              </table>
            </div>
            <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourcePartnerTable?.filteredData?.length <= 0 }" #PartnerTablePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
            </mat-paginator>

          </div>

          <div class="tab-pane fade" id="client" role="tabpanel" aria-labelledby="ticket-management-tab">

            <div class="table-responsive">
              <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                <button matSuffix mat-button>
                  <mat-icon>search</mat-icon>
                </button>
                <input matInput (keyup)="applyFilterClient($event)" placeholder="Filter : Ticket Type, Email" />
              </mat-form-field>
              <div *ngIf="dataSourceClientTable?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
              </div>
              <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceClientTable?.filteredData?.length <= 0 }" [dataSource]="dataSourceClientTable" matSort #ClientTableSort="matSort" class="datatable">
                <ng-container matColumnDef="indexnumber">
                  <th mat-header-cell *matHeaderCellDef> No </th>
                  <td mat-cell *matCellDef="let i = index;">
                    {{ this.ClientTablePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.ClientTablePaginator.pageIndex * this.ClientTablePaginator.pageSize}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="vDisplayUniqueId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> User ID </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.vDisplayUniqueId}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="vEmailId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket For </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                      <div>{{element.vEmailId}}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="createdBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                      <div>{{element.createdByName}}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="tiStatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Status </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.tiStatus == '1'" class="status status-pending"><span class="material-icons">hourglass_empty</span>Pending</span>
                    <span *ngIf="element.tiStatus == '2'" class="status status-active"><span class="material-icons">done</span>Approved</span>
                    <span *ngIf="element.tiStatus == '3'" class="status status-inactive"><span class="material-icons">thumb_down_off_alt</span>Rejected</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="iCreatedAt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td mat-cell *matCellDef="let element">{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-wrap">
                      <div>
                        <button class="status-btn lightblue-bg blue-text text-center mb-2" (click)="openModalWithData(employeeProfileView,element)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Details">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                      </div>
                      <div class="d-flex">
                        <button *ngIf="element.tiStatus == 1" class="status-btn lightgreen-bg green-text" (click)="clickApproveReject(null,element,'accept')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Approve"><span
                            class="material-icons">check_circle</span></button>
                        <button *ngIf="element.tiStatus == 1" class="status-btn lightpink-bg pink-text" (click)="clickApproveReject(rejectReason,element,'reject')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Reject"><span
                            class="material-icons">cancel</span></button>
                        <button *ngIf="element.tiStatus == 2 || element.tiStatus == 3" class="status-btn lightgray-bg gray-text" data-bs-toggle="tooltip" data-bs-placement="bottom" title="No Action"><span
                            class="material-icons">hide_source</span></button>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsClientTable; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsClientTable;"></tr>
              </table>
            </div>
            <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceClientTable?.filteredData?.length <= 0 }" #ClientTablePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
            </mat-paginator>

          </div>

          <div class="tab-pane fade" id="employee" role="tabpanel" aria-labelledby="ticket-management-tab">

            <div class="table-responsive">
              <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                <button matSuffix mat-button>
                  <mat-icon>search</mat-icon>
                </button>
                <input matInput (keyup)="applyFilterEmployee($event)" placeholder="Filter : Ticket Type, Email" />
              </mat-form-field>
              <div *ngIf="dataSourceEmployeeTable?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
              </div>
              <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceEmployeeTable?.filteredData?.length <= 0 }" [dataSource]="dataSourceEmployeeTable" matSort #EmployeeTableSort="matSort" class="datatable">
                <ng-container matColumnDef="indexnumber">
                  <th mat-header-cell *matHeaderCellDef> No </th>
                  <td mat-cell *matCellDef="let i = index;">
                    {{ this.EmployeeTablePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.EmployeeTablePaginator.pageIndex * this.EmployeeTablePaginator.pageSize}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="vDisplayUniqueId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> User ID </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.vDisplayUniqueId}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="vEmailId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket For </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                      <div>{{element.vEmailId}}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="createdBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                      <div>{{element.createdByName}}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="tiStatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Status </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.tiStatus == '1'" class="status status-pending"><span class="material-icons">hourglass_empty</span>Pending</span>
                    <span *ngIf="element.tiStatus == '2'" class="status status-active"><span class="material-icons">done</span>Approved</span>
                    <span *ngIf="element.tiStatus == '3'" class="status status-inactive"><span class="material-icons">thumb_down_off_alt</span>Rejected</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="iCreatedAt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td mat-cell *matCellDef="let element">{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-wrap">
                      <div>
                        <button class="status-btn lightblue-bg blue-text text-center mb-2" (click)="openModalWithData(employeeProfileView,element)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Details">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                      </div>
                      <div class="d-flex">
                        <button *ngIf="element.tiStatus == 1" class="status-btn lightgreen-bg green-text" (click)="clickApproveReject(null,element,'accept')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Approve"><span
                            class="material-icons">check_circle</span></button>
                        <button *ngIf="element.tiStatus == 1" class="status-btn lightpink-bg pink-text" (click)="clickApproveReject(rejectReason,element,'reject')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Reject"><span
                            class="material-icons">cancel</span></button>
                        <button *ngIf="element.tiStatus == 2 || element.tiStatus == 3" class="status-btn lightgray-bg gray-text" data-bs-toggle="tooltip" data-bs-placement="bottom" title="No Action"><span
                            class="material-icons">hide_source</span></button>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsEmployeeTable; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsEmployeeTable;"></tr>
              </table>
            </div>
            <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceEmployeeTable?.filteredData?.length <= 0 }" #EmployeeTablePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
            </mat-paginator>

          </div>

          <div class="tab-pane fade" id="interview" role="tabpanel" aria-labelledby="ticket-management-tab">

            <div class="table-responsive">
              <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                <button matSuffix mat-button>
                  <mat-icon>search</mat-icon>
                </button>
                <input matInput (keyup)="applyFilterInterview($event)" placeholder="Filter : Ticket Type, Email" />
              </mat-form-field>
              <div *ngIf="dataSourceInterviewTable?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
              </div>
              <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceInterviewTable?.filteredData?.length <= 0 }" [dataSource]="dataSourceInterviewTable" matSort #InterviewTableSort="matSort" class="datatable">
                <ng-container matColumnDef="indexnumber">
                  <th mat-header-cell *matHeaderCellDef> No </th>
                  <td mat-cell *matCellDef="let i = index;">
                    {{ this.InterviewTablePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.InterviewTablePaginator.pageIndex * this.InterviewTablePaginator.pageSize}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="tiTicketType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Type </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.vTicketType}}
                  </td>
                </ng-container>}
                <ng-container matColumnDef="vEmailId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket For </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                      <div>Client Email: {{element.vEmailId}}</div>
                      <div *ngIf="element.empEmail">Employee Email: {{element.empEmail}}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="createdBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                      <div>Client Name: {{element.createdByName}}</div>
                      <div *ngIf="element.empName">Employee Name: {{element.empName}}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="tiStatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Status </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.tiStatus == '1'" class="status status-pending"><span class="material-icons">hourglass_empty</span>Pending</span>
                    <span *ngIf="element.tiStatus == '2'" class="status status-active"><span class="material-icons">done</span>Approved</span>
                    <span *ngIf="element.tiStatus == '3'" class="status status-inactive"><span class="material-icons">thumb_down_off_alt</span>Rejected</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="iCreatedAt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td mat-cell *matCellDef="let element">{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-wrap">
                      <div>
                        <button class="status-btn lightblue-bg blue-text text-center mb-2" (click)="openModalWithData(employeeProfileView,element)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Details">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                      </div>
                      <div class="d-flex">
                        <button *ngIf="element.tiStatus == 1" class="status-btn lightgreen-bg green-text" (click)="interviewApproveReject(null,element,'accept')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Approve"><span
                            class="material-icons">check_circle</span></button>
                        <button *ngIf="element.tiStatus == 1" class="status-btn lightpink-bg pink-text" (click)="interviewApproveReject(rejectInterviewReason,element,'reject')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Reject"><span
                            class="material-icons">cancel</span></button>
                        <button *ngIf="element.tiStatus == 2" class="status-btn lightpurple-bg purple-text" (click)="OpenInterviewPopup(addinterview, element.iTicketId)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Schedule"><span
                            class="material-icons">query_builder</span></button>
                        <button *ngIf="element.tiStatus == 3" class="status-btn lightgray-bg gray-text" data-bs-toggle="tooltip" data-bs-placement="bottom" title="No Action"><span class="material-icons">hide_source</span></button>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsInterviewTable; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsInterviewTable;"></tr>
              </table>
            </div>
            <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceInterviewTable?.filteredData?.length <= 0 }" #InterviewTablePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
            </mat-paginator>

          </div>

          <div class="tab-pane fade" id="contract" role="tabpanel" aria-labelledby="ticket-management-tab">

            <div class="table-responsive">
              <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                <button matSuffix mat-button>
                  <mat-icon>search</mat-icon>
                </button>
                <input matInput (keyup)="applyFilterContract($event)" placeholder="Filter : Ticket Type, Email" />
              </mat-form-field>
              <div *ngIf="dataSourceContractTable?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
              </div>
              <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceContractTable?.filteredData?.length <= 0 }" [dataSource]="dataSourceContractTable" matSort #ContractTableSort="matSort" class="datatable">
                <ng-container matColumnDef="indexnumber">
                  <th mat-header-cell *matHeaderCellDef> No </th>
                  <td mat-cell *matCellDef="let i = index;">
                    {{ this.ContractTablePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.ContractTablePaginator.pageIndex * this.ContractTablePaginator.pageSize}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="tiTicketType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Type </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.vTicketType}}
                  </td>
                </ng-container>}
                <ng-container matColumnDef="vEmailId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket For </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                      <div>{{element.vEmailId}}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="createdBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-column">
                      <div>{{element.createdByName}}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="tiStatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Status </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.tiStatus == '1'" class="status status-pending"><span class="material-icons">hourglass_empty</span>Pending</span>
                    <span *ngIf="element.tiStatus == '2'" class="status status-active"><span class="material-icons">done</span>Approved</span>
                    <span *ngIf="element.tiStatus == '3'" class="status status-inactive"><span class="material-icons">thumb_down_off_alt</span>Rejected</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="iCreatedAt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td mat-cell *matCellDef="let element">{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-wrap">
                      <div>
                        <button class="status-btn lightblue-bg blue-text text-center mb-2" (click)="openModalWithData(employeeProfileView,element)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Details">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                      </div>
                      <div class="d-flex">
                        <button *ngIf="element.tiStatus == 1" class="status-btn lightgreen-bg green-text" (click)="clickApproveReject(null,element,'accept')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Approve"><span
                            class="material-icons">check_circle</span></button>
                        <button *ngIf="element.tiStatus == 1" class="status-btn lightpink-bg pink-text" (click)="clickApproveReject(rejectReason,element,'reject')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Reject"><span
                            class="material-icons">cancel</span></button>
                        <button *ngIf="element.tiStatus == 2 || element.tiStatus == 3" class="status-btn lightgray-bg gray-text" data-bs-toggle="tooltip" data-bs-placement="bottom" title="No Action"><span
                            class="material-icons">hide_source</span></button>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsContractTable; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsContractTable;"></tr>
              </table>
            </div>
            <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceContractTable?.filteredData?.length <= 0 }" #ContractTablePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
            </mat-paginator>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #rejectReason let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-view">
    <button class="modal-close-btn" (click)="d('Cross click')">
      <mat-icon>close</mat-icon>
    </button>
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h2 class="modal-form-title">Reject Ticket</h2>
        <form class="edit-user-form interview-schedule d-flex flex-column" [formGroup]="TicketRejectForm" (ngSubmit)="TicketApproval()">
          <label>Reject Reason</label>
          <mat-form-field floatLabel="never">
            <input matInput formControlName="reason">
          </mat-form-field>
          <div class="error-box">
            <mat-error *ngIf="isTicketReasonSubmit && TicketRejectForm.controls.reason.hasError('required')">
              Reason is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="isTicketReasonSubmit && TicketRejectForm.controls.reason.hasError('minlength')">
              Reason is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="isTicketReasonSubmit && TicketRejectForm.controls.reason.hasError('maxlength')">
              Reason should be upto 150 characters
            </mat-error>
          </div>
          <div class="modal-form-footer justify-content-end mt-3">
            <div class="d-flex">
              <button type="submit" class="btn btn-sm update-btn">Update</button>
              <button type="button" class="btn btn-sm cancel-btn" (click)="closeReasonPopUp()">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rejectInterviewReason let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-view">
    <button class="modal-close-btn" (click)="d('Cross click')">
      <mat-icon>close</mat-icon>
    </button>
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h2 class="modal-form-title">Reject Ticket</h2>
        <form class="edit-user-form interview-schedule d-flex flex-column" [formGroup]="TicketRejectForm" (ngSubmit)="interviewRejectReson()">
          <label>Reject Reason</label>
          <mat-form-field floatLabel="never">
            <input matInput formControlName="reason">
          </mat-form-field>
          <div class="error-box">
            <mat-error *ngIf="isTicketReasonSubmit && TicketRejectForm.controls.reason.hasError('required')">
              Reason is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="isTicketReasonSubmit && TicketRejectForm.controls.reason.hasError('minlength')">
              Reason is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="isTicketReasonSubmit && TicketRejectForm.controls.reason.hasError('maxlength')">
              Reason should be upto 150 characters
            </mat-error>
          </div>
          <div class="modal-form-footer justify-content-end mt-3">
            <div class="d-flex">
              <button type="submit" class="btn btn-sm update-btn">Update</button>
              <button type="button" class="btn btn-sm cancel-btn" (click)="closeReasonPopUp()">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addinterview let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-view">
    <button class="modal-close-btn" (click)="d('Cross click')">
      <mat-icon>close</mat-icon>
    </button>
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h2 class="modal-form-title">Interview Schedule</h2>
        <form class="add-user-form interview-schedule d-flex flex-column" [formGroup]="AddInterviewForm" (ngSubmit)="InterviewSchedule()">
          <label>Subject</label>
          <mat-form-field floatLabel="never">
            <mat-label>Subject</mat-label>
            <input matInput formControlName="vSubject">
          </mat-form-field>
          <div class="error-box">
            <mat-error *ngIf="isAddInterviewSubmit && AddInterviewForm.controls.vSubject.hasError('required')">
              Subject is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="isAddInterviewSubmit && AddInterviewForm.controls.vSubject.hasError('minlength')">
              Minimum 3 character required
            </mat-error>
            <mat-error *ngIf="isAddInterviewSubmit && AddInterviewForm.controls.vSubject.hasError('maxlength')">
              Maximum 30 character required
            </mat-error>
          </div>
          <label>Start Time</label>
          <mat-form-field floatLabel="never">
            <input matInput formControlName="startInterview" [ngxMatDatetimePicker]="startInterview" placeholder="Start Time" [min]="minDate" [disabled]="disabled" readonly/>
            <mat-datepicker-toggle matSuffix [for]="startInterview"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #startInterview [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color">
            </ngx-mat-datetime-picker>
          </mat-form-field>
          <div class="error-box">
            <mat-error *ngIf="isAddInterviewSubmit && AddInterviewForm.controls.startInterview.hasError('required')">
              Start Time is <strong>required</strong>
            </mat-error>
          </div>
          <label>End Time</label>
          <mat-form-field floatLabel="never">
            <input matInput formControlName="endInterview" [ngxMatDatetimePicker]="endInterview" placeholder="End Time" [min]="minDate" [disabled]="disabled" readonly/>
            <mat-datepicker-toggle matSuffix [for]="endInterview"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #endInterview [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color">
            </ngx-mat-datetime-picker>
          </mat-form-field>
          <div class="error-box">
            <mat-error *ngIf="isAddInterviewSubmit && AddInterviewForm.controls.endInterview.hasError('required')">
              End Time is <strong>required</strong>
            </mat-error>
          </div>
          <div class="modal-form-footer w-100">
            <div class="d-flex ml-auto mt-4">
              <button type="submit" class="btn btn-sm add-btn">Schedule</button>
              <button type="button" class="btn btn-sm cancel-btn" (click)="CloseInterviewPopup()">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #employeeProfileView let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-view">
    <button class="modal-close-btn" (click)="d('Cross click')">
      <mat-icon>close</mat-icon>
    </button>
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h1 class="title" *ngIf="selectedEmployeeData?.tiAccountType == '3' && selectedEmployeeData?.tiTicketType == '2'">Partner Profile Ticket</h1>
        <h1 class="title" *ngIf="selectedEmployeeData?.tiAccountType == '4' && selectedEmployeeData?.tiTicketType == '2'">Client Profile Ticket</h1>
        <h1 class="title" *ngIf="selectedEmployeeData?.tiAccountType == '5' && selectedEmployeeData?.tiTicketType == '2'">Employee Profile Ticket</h1>
        <h1 class="title" *ngIf="selectedEmployeeData?.tiTicketType == '1'">Interview Ticket</h1>
        <h1 class="title" *ngIf="selectedEmployeeData?.tiTicketType == '3'">Contract Ticket</h1>
        <div class="d-flex align-items-center img-box" *ngIf="selectedEmployeeData?.tiTicketType == '2'">
          <img class="profile-photo employee-profile-photo" *ngIf="selectedEmployeeData?.vProfilePhoto" src="{{selectedEmployeeData?.vProfilePhoto}}" alt="Profile Photo" width="60px" />
          <span class="profile-photo employee-profile-photo" *ngIf="!selectedEmployeeData?.vProfilePhoto">{{selectedEmployeeData?.dis_short_name}}</span>
          <div class="d-flex flex-column">
            <p class="text text-space mb-0">{{ selectedEmployeeData?.createdByName }}</p>
            <div class="d-flex flex-wrap text text-space">
              <div class="d-flex" *ngIf="selectedEmployeeData?.tiTicketType == '2'">
                <button *ngIf="selectedEmployeeData?.tiStatus == 1" class="status-btn lightgreen-bg green-text" (click)="clickApproveReject(null,selectedEmployeeData,'accept')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Approve"><span
                    class="material-icons">check_circle</span></button>
                <button *ngIf="selectedEmployeeData?.tiStatus == 1" class="status-btn lightpink-bg pink-text" (click)="clickApproveReject(rejectReason,selectedEmployeeData,'reject'); d('Cross click')" data-bs-toggle="tooltip"
                  data-bs-placement="bottom" title="Reject"><span class="material-icons">cancel</span></button>
              </div>
            </div>
          </div>
        </div>
        <div class="content-box">
        <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '2'">
          <div class="col-12 col-sm-3">
            <p class="text">User ID:</p>
          </div>
          <div class="col">
            <p class="sub-text" *ngIf="selectedEmployeeData?.vDisplayUniqueId">{{selectedEmployeeData?.vDisplayUniqueId}}</p>
            <p class="sub-text" *ngIf="!selectedEmployeeData?.vDisplayUniqueId">Not Available</p>
          </div>
        </div>
        <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '2'">
          <div class="col-12 col-sm-3">
            <p class="text">Ticket For:</p>
          </div>
          <div class="col">
            <p class="sub-text" *ngIf="selectedEmployeeData?.vEmailId">{{selectedEmployeeData?.vEmailId}}</p>
            <p class="sub-text" *ngIf="!selectedEmployeeData?.vEmailId">Not Available</p>
          </div>
        </div>
        <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '1'">
          <div class="col-12 col-sm-4">
            <p class="text">Client:</p>
          </div>
          <div class="col">
            <p class="sub-text" *ngIf="selectedEmployeeData?.createdByName">{{selectedEmployeeData?.createdByName}}</p>
            <p class="sub-text" *ngIf="!selectedEmployeeData?.createdByName">Not Available</p>
          </div>
        </div>
        <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '1'">
          <div class="col-12 col-sm-4">
            <p class="text">Client Email:</p>
          </div>
          <div class="col">
            <p class="sub-text" *ngIf="selectedEmployeeData?.vEmailId">{{selectedEmployeeData?.vEmailId}}</p>
            <p class="sub-text" *ngIf="!selectedEmployeeData?.vEmailId">Not Available</p>
          </div>
        </div>
        <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '1'">
          <div class="col-12 col-sm-4">
            <p class="text">Employee:</p>
          </div>
          <div class="col">
            <p class="sub-text" *ngIf="selectedEmployeeData?.empName">{{selectedEmployeeData?.empName}}</p>
            <p class="sub-text" *ngIf="!selectedEmployeeData?.empName">Not Available</p>
          </div>
        </div>
        <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '1'">
          <div class="col-12 col-sm-4">
            <p class="text">Employee Email:</p>
          </div>
          <div class="col">
            <p class="sub-text" *ngIf="selectedEmployeeData?.empEmail">{{selectedEmployeeData?.empEmail}}</p>
            <p class="sub-text" *ngIf="!selectedEmployeeData?.empEmail">Not Available</p>
          </div>
        </div>
        <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '3'">
          <div class="col-12 col-sm-3">
            <p class="text">Ticket For:</p>
          </div>
          <div class="col">
            <p class="sub-text" *ngIf="selectedEmployeeData?.vEmailId">{{selectedEmployeeData?.vEmailId}}</p>
            <p class="sub-text" *ngIf="!selectedEmployeeData?.vEmailId">Not Available</p>
          </div>
        </div>
        <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '3'">
          <div class="col-12 col-sm-3">
            <p class="text">Created By:</p>
          </div>
          <div class="col">
            <p class="sub-text" *ngIf="selectedEmployeeData?.createdByName">{{selectedEmployeeData?.createdByName}}</p>
            <p class="sub-text" *ngIf="!selectedEmployeeData?.createdByName">Not Available</p>
          </div>
        </div>
        <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '3'">
          <div class="col-12 col-sm-3">
            <p class="text">Description:</p>
          </div>
          <div class="col">
            <p class="sub-text" *ngIf="selectedEmployeeData?.txBio">{{selectedEmployeeData?.txBio}}</p>
            <p class="sub-text" *ngIf="!selectedEmployeeData?.txBio">Not Available</p>
          </div>
        </div>
        <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '2'">
          <div class="col-12 col-sm-3">
            <p class="text">Location:</p>
          </div>
          <div class="col">
            <p class="sub-text" *ngIf="selectedEmployeeData?.iCityName">{{selectedEmployeeData?.iCityName}}, {{selectedEmployeeData?.iStateName}}, {{selectedEmployeeData?.iCountryName}}</p>
            <p class="sub-text" *ngIf="!selectedEmployeeData?.iCityName">Not Available</p>
          </div>
        </div>
        <div class="row" *ngIf="selectedEmployeeData?.txMessage == 'Employee_profile_approve'">
          <div class="col-12 col-sm-3">
            <p class="text">Experience:</p>
          </div>
          <div class="col">
            <span *ngIf="!selectedEmployeeData?.vExperienceYear && !selectedEmployeeData?.vExperienceMonth" class="sub-text">Not Available</span>
            <span *ngIf="selectedEmployeeData?.vExperienceYear == 1" class="sub-text">{{selectedEmployeeData?.vExperienceYear}} Year </span>
            <span *ngIf="selectedEmployeeData?.vExperienceYear > 1" class="sub-text">{{selectedEmployeeData?.vExperienceYear}} Years </span>
            <span *ngIf="selectedEmployeeData?.vExperienceMonth == 1" class="sub-text"> {{selectedEmployeeData?.vExperienceMonth}} Month</span>
            <span *ngIf="selectedEmployeeData?.vExperienceMonth > 1" class="sub-text"> {{selectedEmployeeData?.vExperienceMonth}} Months</span>
          </div>
          </div>
          <div class="row" *ngIf="selectedEmployeeData?.tiTicketType == '2'">
            <div class="col-12 col-sm-3">
              <p class="text">Bio:</p>
            </div>
            <div class="col">
              <p class="sub-text">{{ selectedEmployeeData?.txBio }}</p>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap text justify-content-end mb-2">
          <div class="d-flex" *ngIf="selectedEmployeeData?.tiTicketType == '1' || selectedEmployeeData?.tiTicketType == '3'">
            <button *ngIf="selectedEmployeeData?.tiStatus == 1" class="status-btn lightgreen-bg green-text" (click)="clickApproveReject(null,selectedEmployeeData,'accept')" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Approve"><span
                class="material-icons">check_circle</span></button>
            <button *ngIf="selectedEmployeeData?.tiStatus == 1" class="status-btn lightpink-bg pink-text" (click)="clickApproveReject(rejectReason,selectedEmployeeData,'reject'); d('Cross click')" data-bs-toggle="tooltip"
              data-bs-placement="bottom" title="Reject"><span class="material-icons">cancel</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
