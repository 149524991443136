import { Component, Input, OnInit } from "@angular/core";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ViewPartnerService } from "../view-partner.service";
import { NotificationService } from "../../../../config/notification.service";
import { environment } from "../../../../../environments/environment";
import { Permission } from "../../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../../utils/services/user-permission.service";

@Component({
  selector: "partner-executive-details",
  templateUrl: "./executive-details.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class PartnerExecutiveDetailsComponent implements OnInit {
  @Input() partnerUserId:any;
  executiveDetailsToggle:boolean=false;
  
  imagePath = environment.image_path;

  // toggle actions permission
  editPartnerExecutiveDetailsActionPermission: boolean = false;

  executiveDetailsForm!: FormGroup;
  executiveDetailsSubmitted: any = false;

  executiveDetailsArray: any = [];

  constructor(config: NgbModalConfig, private modalService: NgbModal, private partnerService: ViewPartnerService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService, private userPermissionService: UserPermissionService) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const EditPartnerExecutiveDetailsAction: Permission = Permission.EditPartnerExecutiveDetailsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(EditPartnerExecutiveDetailsAction)) {
      this.editPartnerExecutiveDetailsActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });

    this.GetExecutiveDetails();

    this.executiveDetailsForm = new FormGroup({
      vMDFullname: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern("^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]),
      vMDEmailId: new FormControl("", [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(70), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      vEMFullname: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern("^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]),
      vEMEmailId: new FormControl("", [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(70), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      vFMFullname: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern("^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]),
      vFMEmailId: new FormControl("", [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(70), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      vSMFullname: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern("^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]),
      vSMEmailId: new FormControl("", [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(70), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    });
  }

  public executiveDetailsCardCompleted: any = false;
  updateProfileStatus(data: any) {
    this.executiveDetailsCardCompleted = data["executiveDetails"];
  }


  GetExecutiveDetails() {
    this.partnerService.GetExecutiveDetails(this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.executiveDetailsForm.patchValue({
          vMDFullname: result["data"]["vMDFullname"],
          vMDEmailId: result["data"]["vMDEmailId"],
          vEMFullname: result["data"]["vEMFullname"],
          vEMEmailId: result["data"]["vEMEmailId"],
          vFMFullname: result["data"]["vFMFullname"],
          vFMEmailId: result["data"]["vFMEmailId"],
          vSMFullname: result["data"]["vSMFullname"],
          vSMEmailId: result["data"]["vSMEmailId"],
        });
      }
    });
  }

  executiveCancel() {
    this.GetExecutiveDetails();
  }

  UpdatePartnerExecutive() {
    this.executiveDetailsSubmitted = true;
    if (this.executiveDetailsForm.invalid) {
      return;
    }
    this.partnerService.UpdatePartnerExecutive(this.executiveDetailsForm.value,this.partnerUserId).then((result: any) => {
      if (result["code"] == 200) {
        this.GetExecutiveDetails();
        this.GetExecutiveDetails();
        this.notifyService.showSuccess(result.message, "");
        this.executiveDetailsToggle=false;
        this.eventEmitterService.GetProfileStatusAPICallMethod();
      } else {
        this.executiveDetailsToggle=false;
        this.notifyService.showError(result.message,'');
      }
    });
  }

  toggleExecutiveDetails(hasPermission:boolean){
    if(hasPermission){
      this.executiveDetailsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }
}
