<mat-sidenav-container class="ins-sidenav partner mat-drawer-transition">

  <mat-sidenav #appDrawer id="appDrawer" mode="over" opened="false" class="side-nav-start">
    <mat-toolbar class="header">
      <div class="side-menu-logo">
        <span class="logo-desktop"><img src="./assets/images/rapidise-logo.svg" alt="Rapidise" routerLink="/admin" /></span>
        <span class="logo-mobile"><img src="./assets/images/rapidise-logo-icon.svg" alt="Rapidise" routerLink="/admin" /></span>
      </div>
    </mat-toolbar>
    <perfect-scrollbar>
      <mat-nav-list class="menu-items">
        <app-menu-list-item *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
        <button mat-list-item class="menu-list-item" (click)="logout()">
          <mat-icon class="routeIcon"><img src="./assets/images/logout.svg" alt="logout" width="24px" height="24px"></mat-icon>
          Sign out
        </button>
      </mat-nav-list>
    </perfect-scrollbar>
  </mat-sidenav>

  <mat-sidenav-content class="main-container">
    <mat-toolbar class="header d-flex justify-content-between w-100">
      <div class="me-md-4">
        <div class="side-content-logo">
          <span class="pe-3 fs-5 logo-desktop"><img src="./assets/images/rapidise-logo.svg" alt="Rapidise" routerLink="/admin" /></span>
          <span class="pe-3 fs-5 logo-mobile"><img src="./assets/images/rapidise-logo-icon.svg" alt="Rapidise" routerLink="/admin" /></span>
          <button mat-icon-button id="menu" class="d-flex justify-content-center" (click)="navService.openNav()" *ngIf="!router.isActive('/', true) && !router.isActive('/admin-onboarding', true) && !router.isActive('/my-roster', true)">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
      </div>
      <div class="ms-2 ms-md-4">
        <div class="d-flex">
          <a *ngIf="!json_user_data.login_status" routerLink="/login" href="javascript:void(0)" class="d-flex justify-content-center align-items-center me-2 login-link flex-grow-0">
            <mat-icon class="d-flex justify-content-center align-items-center flex-grow-0 me-2"><img src="./assets/images/login.svg" alt="login" width="18px" height="18px"></mat-icon>
            Login
          </a>
          <div class="ms-2" *ngIf="json_user_data.login_status">
            <span *ngIf="!vCompanyLogoUrl" class="header-profile-img" mat-button [matMenuTriggerFor]="menu">{{json_user_data.dis_short_name}}</span>
            <img *ngIf="vCompanyLogoUrl" [src]="vCompanyLogoUrl" class="header-profile-img" alt="profile picture" mat-button [matMenuTriggerFor]="menu" />
            <mat-menu #menu="matMenu" class="header-matmenu header-matmenu_box">
              <button mat-menu-item (click)="topMenuClick()">
                Dashboard
              </button>
              <button mat-menu-item routerLink="/admin/view-profile">
                Profile Settings
              </button>
              <button mat-menu-item routerLink="/admin/change-password">
                Change Password
              </button>
              <button mat-menu-item (click)="logout()">
                Sign Out
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </mat-toolbar>

    <perfect-scrollbar>
      <div class="inner-page">
        <router-outlet></router-outlet>
      </div>
    </perfect-scrollbar>
  </mat-sidenav-content>

</mat-sidenav-container>

<app-error-modal></app-error-modal>
