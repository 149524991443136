import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProjectPortfolioService {
  constructor(private http: HttpClient) {}

  AddPortfolio(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/portfolio?iUserId=${talentUserId}`;
      const formData: FormData = new FormData();
      formData.append("vTitile", data.vProjectTitle);
      formData.append("txDescription", data.vDescription);
      formData.append("txContribution", data.vMyContribution);
      formData.append("txTechnology", data.vTechnology);
      formData.append("vURL", data.vUrl);
      formData.append("media", data.media);
      formData.append("media1", data.media1);
      formData.append("media2", data.media2);
      formData.append("media3", data.media3);
      formData.append("media4", data.media4);
      this.http
        .post(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  EditPortfolio(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/portfolio?iUserId=${talentUserId}`;
      const formData: FormData = new FormData();
      formData.append("vTitile", data.vProjectTitle);
      formData.append("txDescription", data.vDescription);
      formData.append("txContribution", data.vMyContribution);
      formData.append("txTechnology", data.vTechnology);
      formData.append("vURL", data.vUrl);
      formData.append("media", data.media);
      formData.append("media1", data.media1);
      formData.append("media2", data.media2);
      formData.append("media3", data.media3);
      formData.append("media4", data.media4);
      formData.append("iPortfolioId", data.iPortfolioId);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  RemovePortfolio(id: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/portfolio/${id}?iUserId=${talentUserId}`;
      this.http
        .delete(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  RemoveThumbNail(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/portfolio-thumbnail?iUserId=${talentUserId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
