import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RoleService } from './role.service';
import { EventEmitterService } from '../../config/event-emitter.service';
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';
import { element } from 'protractor';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html'
})
export class RoleComponent implements OnInit {
  // toggle actions permission
  addRoleActionPermission: boolean = false;
  editRoleActionPermission: boolean = false;
  deleteRoleActionPermission: boolean = false;

  iRoleId: number;
  roleTypeList: any = [
    { id: 1, tiRoleType: "Admin" },
    { id: 3, tiRoleType: "Partner" },
    { id: 4, tiRoleType: "Client" }
  ];

  addRoleForm!: FormGroup;
  editRoleForm!: FormGroup;

  displayedColumnsTableOne: string[] = ['indexnumber', 'vRoleName', 'tiRoleType', 'iCreatedAt', 'tiStatus', 'isAdminRole', 'actions'];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

  constructor(
    private roleService: RoleService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    config: NgbModalConfig,
    private userPermissionService: UserPermissionService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;

    // assign individual permissions
    const AddRoleAction: Permission = Permission.AddRoleAction;
    const EditRoleAction: Permission = Permission.EditRoleAction;
    const DeleteRoleAction: Permission = Permission.DeleteRoleAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddRoleAction)) {
      this.addRoleActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditRoleAction)) {
      this.editRoleActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeleteRoleAction)) {
      this.deleteRoleActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetRole();

    this.addRoleForm = new FormGroup({
      vRoleName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      tiRoleType: new FormControl('', [Validators.required])
    });

    this.editRoleForm = new FormGroup({
      iRoleId: new FormControl('', []),
      vRoleName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      tiRoleType: new FormControl('', [Validators.required])
    });
  }

  OpenModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modalToOpen, { centered: true });
    }
    else {
      this.addRoleForm.reset();
    }
  }

  OpenModelEditRole(modelName: any, element: any, hasPermission: boolean) {
    if (hasPermission) {
      this.editRoleForm.patchValue({
        iRoleId: element['iRoleId'],
        vRoleName: element['vRoleName'],
        tiRoleType: element['tiRoleType']
      })
      this.modalService.open(modelName, { centered: true });
    }
  }

  OpenDeleteRole(modalToOpen: any, element: any, hasPermission: boolean) {
    if (hasPermission) {
      this.iRoleId = element['iRoleId'];
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  GetRole() {
    this.roleService.GetRole().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message, '');
      }
    }).catch((err: any) => { console.log(err) });
  }

  AddRole() {
    if (this.addRoleForm.invalid) {
      return;
    }
    let reqData = {
      vRoleName: this.addRoleForm.value.vRoleName,
      tiRoleType: this.addRoleForm.value.tiRoleType
    }
    this.roleService.AddRole(reqData).then((result: any) => {
      if (result['code'] == 200) {
        this.modalService.dismissAll();
        this.addRoleForm.reset();
        this.notifyService.showSuccess(result.message, '');
        this.GetRole();
      } else {
        this.notifyService.showError(result.message, '');
      }
    }).catch((err) => {
      console.log(err, 'AddRole');
    });
  }

  EditRole() {
    if (this.editRoleForm.invalid) {
      return;
    }
    let reqData = {
      iRoleId: this.editRoleForm.value.iRoleId,
      vRoleName: this.editRoleForm.value.vRoleName,
      tiRoleType: this.editRoleForm.value.tiRoleType
    }
    this.roleService.EditRole(reqData).then((result: any) => {
      if (result['code'] == 200) {
        this.notifyService.showSuccess(result.message, '');
        this.modalService.dismissAll();
        this.GetRole();
      } else {
        this.modalService.dismissAll();
        this.notifyService.showError(result.message, '');
      }
    }).catch((err) => {
      console.log(err, "EditRole");
    });
  }

  DeleteRole(item: any): void {
    this.roleService.DeleteRole(item).then((result: any) => {
      this.GetRole();
      this.notifyService.showSuccess(result.message, '');
      this.modalService.dismissAll();
    }).catch((err) => {
      console.log(err, 'DeleteRole');
    })
  }

  EditAdminRole(event:any, element: any){
    let iRoleId= element.iRoleId;
    let reqData = {
      isAdminRole: event.checked
    }
     this.roleService.EditAdminRole(iRoleId, reqData).then((result: any) => {
        if (result['code'] == 200) {
          this.notifyService.showSuccess(result.message, '');
         this.modalService.dismissAll();
          this.GetRole();
       } else {
         this.modalService.dismissAll();
          this.notifyService.showError(result.message, '');
        }
        }).catch((err) => {
          console.log(err, "EditAdminRole");
        });
  }
}
