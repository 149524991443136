import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionManagementService {
  constructor( private http: HttpClient ) { }

  GetRole(){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v2/role`;
        this.http
          .get(apiURL)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }


  EditRolePermission(tiUserFeatureType:any,iRoleId:any,data: any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v2/update-permission?tiUserFeatureType=${tiUserFeatureType}&iRoleId=${iRoleId}`;
        this.http
          .post(apiURL,data)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }

  GetAllPermission(tiUserFeatureType:any,iRoleId:any){
    const promise = new Promise((resolve, reject) => {
        let apiURL = environment.baseUrl + `/admin/v2/get-all-permission?tiUserFeatureType=${tiUserFeatureType}&iRoleId=${iRoleId}`;
        this.http
          .get(apiURL)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
      return promise;
  }

}
