<div name="personal-details-box" id="personal-details-box" class="col-12">
  <div name="personal-details-card-container" id="personal-details-card-container" class="card-container" *ngIf="personalDetailsToggle == false">
    <div name="personal-details-card-header" id="personal-details-card-header" class="card-header">
      <div name="personal-details-title-section" id="personal-details-title-section" class="title-section">
        <h4 name="personal-details-card-title" id="personal-details-card-title" class="card-title">Personal Details</h4>
        <div placement="right auto" [ngbTooltip]="editTalentPersonalDetailsActionPermission ? '' : 'Not Authorized'" [ngClass]="
        editTalentPersonalDetailsActionPermission ? '' : 'unauthorized-cursor'">
        <button name="personal-details-card-edit-button" id="personal-details-card-edit-button" class="card-edit-button" type="button" (click)="togglePersonalDetails(editTalentPersonalDetailsActionPermission,PersonalDetail)" [ngClass]="
        editTalentPersonalDetailsActionPermission ? '' : 'unauthorized-action'">
          <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
        </button>
        </div>
      </div>

      <div name="personal-details-status-section" id="personal-details-status-section" class="status-section">
        <div name="personal-details-incomplete-status-box" id="personal-details-incomplete-status-box" class="status-box" *ngIf="!personalDetailsCardCompleted">
          <img id="personal-details-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'" alt="status-icon">
          <label name="personal-details-incomplete-status-text" id="personal-details-incomplete-status-text" class="status-text incomplete">Incomplete</label>
        </div>

        <div name="personal-details-completed-status-box" id="personal-details-completed-status-box" class="status-box" *ngIf="personalDetailsCardCompleted">
          <img id="personal-details-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'" alt="status-icon">
          <label name="personal-details-completed-status-text" id="personal-details-completed-status-text" class="status-text completed">Completed</label>
        </div>
      </div>
    </div>

    <div name="personal-details-card" id="personal-details-card" class="card" [ngClass]="personalDetailsCardCompleted ? 'completed' : 'incomplete'">
      <div class="personal-details">
        <div name="personal-details-card-top-section" id="personal-details-card-top-section" class="card-top-section">
          <div name="personal-details-card-profile-img-container" id="personal-details-card-profile-img-container" class="card-profile-img-container">
            <img id="personal-details-card-profile-img" class="card-profile-img" [src]="PersonalDetail?.vProfilePhoto" alt="Profile Image" *ngIf="profilePhotoShow">
            <div name="image-box" id="image-box" class="image-box" *ngIf="!profilePhotoShow">
              <img class="profile-image" [src]="imagePath + 'person-black.svg'" alt="profile image">
            </div>
          </div>
        </div>

        <div name="personal-details-card-content" id="personal-details-card-content" class="card-content bio-border card-bottom-section">
          <div name="personal-details-card-content-row" id="personal-details-card-content-row" class="row">
            <div name="personal-details-card-content-col-1" id="personal-details-card-content-col-1" class="col-12 col-lg-3 col-sm-6">
              <label name="personal-details-card-content-col-1-label" id="personal-details-card-content-col-1-label" class="label">
                Name
              </label>
              <label name="personal-details-card-content-col-1-text" id="personal-details-card-content-col-1-text" class="text bottom-spacing">
                {{ PersonalDetail?.vFirstName }} {{ PersonalDetail?.vLastName }}
              </label>
            </div>

            <div name="personal-details-card-content-2" id="personal-details-card-content-2"
            class="col-12 col-lg-3 col-sm-6">
              <label name="personal-details-card-content-2-label" id="personal-details-card-content-2-label"
                class="label">
                Email Address
              </label>
              <label name="personal-details-card-content-2-text" id="personal-details-card-content-2-text"
                class="text bottom-spacing">
                {{ PersonalDetail?.vEmailId }}
              </label>
            </div>

            <div name="personal-details-card-content-col-4" id="personal-details-card-content-col-4" class="col-12 col-lg-3 col-sm-6">
              <label name="personal-details-card-content-col-4-label" id="personal-details-card-content-col-4-label" class="label">
                Date of Birth
              </label>
              <label *ngIf="PersonalDetail?.iDob" name="personal-details-card-content-col-4-text" id="personal-details-card-content-col-4-text" class="text bottom-spacing">
                {{ PersonalDetail?.iDob * 1000 | date }}
              </label>
              <label *ngIf="!PersonalDetail?.iDob" name="personal-details-card-content-col-4-text" id="personal-details-card-content-col-4-text" class="text bottom-spacing">
                Not Available
              </label>
            </div>

            <div name="personal-details-card-content-col-5" id="personal-details-card-content-col-5" class="col-12 col-lg-3 col-sm-6">
              <label name="personal-details-card-content-col-5-label" id="personal-details-card-content-col-5-label" class="label">
                Phone Number
              </label>
              <label *ngIf="PersonalDetail?.vPhoneNo" name="personal-details-card-content-col-5-text" id="personal-details-card-content-col-5-text" class="text bottom-spacing">
                {{ PersonalDetail?.vDialCode }} {{ PersonalDetail?.vPhoneNo }}
              </label>
              <label *ngIf="!PersonalDetail?.vPhoneNo" name="personal-details-card-content-col-5-text" id="personal-details-card-content-col-5-text" class="text bottom-spacing">
                Not Available
              </label>
            </div>

            <div name="personal-details-card-content-col-6" id="personal-details-card-content-col-6" class="col-12 col-lg-3 col-sm-6">
              <label name="personal-details-card-content-col-6-text" id="personal-details-card-content-col-6-text" class="label">
                Address
              </label>
              <p *ngIf="PersonalDetail?.vAddress" name="personal-details-card-content-col-6-text" id="personal-details-card-content-col-6-text" class="text bottom-spacing">
                {{ PersonalDetail?.vAddress }}
              </p>
              <p *ngIf="!PersonalDetail?.vAddress" name="personal-details-card-content-col-6-text" id="personal-details-card-content-col-6-text" class="text bottom-spacing">
                Not Available
              </p>
            </div>

            <div name="personal-details-card-content-col-3" id="personal-details-card-content-col-3" class="col-12 col-lg-3 col-sm-6">
              <label name="personal-details-card-content-col-3-label" id="personal-details-card-content-col-3-label" class="label">
                Introduction Video
              </label>
              <label *ngIf="PersonalDetail?.introVideoUrl" name="personal-details-card-content-col-3-text" id="personal-details-card-content-col-3-text" class="text bottom-spacing">
                <a name="personal-details-card-content-col-4-link" id="personal-details-card-content-col-4-link" target="_blank" href="{{ PersonalDetail?.introVideoUrl }}">{{ PersonalDetail?.vFirstName }}_{{ PersonalDetail?.vLastName }}_intro.mp4</a>
              </label>
              <label *ngIf="!PersonalDetail?.introVideoUrl"   name="personal-details-card-content-col-3-text" id="personal-details-card-content-col-3-text" class="text bottom-spacing">
                Not Available
              </label>
            </div>

            <div name="personal-details-card-content-col-7" id="personal-details-card-content-col-7" class="col-12">
              <label name="personal-details-card-content-col-7-label" id="personal-details-card-content-col-7-label" class="label">
                Bio
              </label>
              <perfect-scrollbar *ngIf="PersonalDetail?.txBio">
                <p name="personal-details-card-content-col-7-text" id="personal-details-card-content-col-7-text" class="text with-scroll">
                  {{ PersonalDetail?.txBio }}
                </p>
              </perfect-scrollbar>
              <p *ngIf="!PersonalDetail?.txBio" name="personal-details-card-content-col-7-text" id="personal-details-card-content-col-7-text" class="text">
                Not Available
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div name="personal-details-card-container" id="personal-details-card-container" class="card-container"
    *ngIf="personalDetailsToggle == true">
    <div name="personal-details-card-header" id="personal-details-card-header" class="card-header">
      <div name="personal-details-title-section" id="personal-details-title-section" class="title-section">
        <h4 name="personal-details-card-title" id="personal-details-card-title" class="card-title">
          Personal Details
        </h4>
      </div>
    </div>
    <div class="edit-profile">
    <div name="personal-details-modal-body" id="personal-details-modal-body" class="modal-body modal-scroll">
      <form id="personalDetailsForm" name="personalDetailsForm" class="form" [formGroup]="personalDetailsForm" (ngSubmit)="PersonalDetailsProcess()">
        <div name="personal-details-profile-image-container" id="personal-details-profile-image-container" class="profile-image-container">
          <div name="personal-details-profile-image-block" id="personal-details-profile-image-block" class="profile-image-block">
            <input name="personal-details-image-input" id="personal-details-image-input" type="file" style="display:none" #fileInputComapnyLogo accept="image/*" formControlName="newImage" (change)="fileChangeEvent($event, Crop)"/>
            <img id="personal-details-image" class="profile-image-container-size" *ngIf="profilePhotoShow && !croppedImage" [src]="PersonalDetail?.vProfilePhoto" alt="profile picture">
            <img id="personal-details-image" class="profile-image-container-size" *ngIf="croppedImage" src="{{croppedImage}}" alt="profile picture">
            <div name="image-box" id="image-box" class="image-box" *ngIf="!profilePhotoShow && !croppedImage">
              <img class="profile-image" [src]="imagePath + 'person-black.svg'" alt="profile image">
            </div>
          </div>
          <div name="personal-details-profile-image-upload" id="personal-details-profile-image-upload" class="profile-image-upload">
            <div class="dark-shadow">
              <button type="button" name="personal-details-profile-upgreen-circle-logoload-button" id="personal-details-profile-upload-button" class="profile-upload-button" (click)="fileInputComapnyLogo.click()">
                <mat-icon class="upload-file-img">edit</mat-icon>
              </button>
            </div>
            <span name="personal-details-profile-upload-size-info" id="personal-details-profile-upload-size-info" class="profile-upload-size-info">(Max 4mb Upload Size) <span class="text-danger">*</span></span>
            <mat-error name="personal-details-col-1-error-0" id="personal-details-col-1-error-0" class="reduce-bottom-space" *ngIf="profileImageError && personalDetailsSubmitted">
              <mat-error name="personal-details-col-1-error-1" id="personal-details-col-1-error-1" class="reduce-bottom-space" *ngIf="profileImageError">
                Profile Picture is required
              </mat-error>
            </mat-error>
          </div>
        </div>
        <div name="personal-details-row" id="personal-details-row" class="row">
          <div name="personal-details-col-1" id="personal-details-col-1" class="col-12 col-md-6 field-box">
            <label for="vEmpFirstName" name="personal-details-col-1-label" id="personal-details-col-1-label" class="custom-label">First Name <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="personal-details-col-1-input" id="personal-details-col-1-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="vEmpFirstName" formControlName="vEmpFirstName" placeholder="John" required>
              <mat-error name="personal-details-col-1-error-0" id="personal-details-col-1-error-0" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmpFirstName'].invalid">
                <mat-error name="personal-details-col-1-error-1" id="personal-details-col-1-error-1" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmpFirstName'].errors!.required">
                  First Name is required
                </mat-error>
                <mat-error name="personal-details-col-1-error-2" id="personal-details-col-1-error-2" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmpFirstName'].errors!.pattern">
                  Only character allowed
                </mat-error>
                <mat-error name="personal-details-col-1-error-3" id="personal-details-col-1-error-3" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmpFirstName'].errors!.minlength && !(personalDetailsForm.controls['vEmpFirstName'].errors!.pattern)">
                  Must have minimum 2 characters
                </mat-error>
                <mat-error name="personal-details-col-1-error-4" id="personal-details-col-1-error-4" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmpFirstName'].errors!.maxlength && !(personalDetailsForm.controls['vEmpFirstName'].errors!.pattern)">
                  Must not exceed 46 characters
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="personal-details-col-2" id="personal-details-col-2" class="col-12 col-md-6 field-box">
            <label for="vEmpLastName" name="personal-details-col-2-label" id="personal-details-col-2-label" class="custom-label">Last Name <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="personal-details-col-2-input" id="personal-details-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="vEmpLastName" formControlName="vEmpLastName" placeholder="Doe" required>
              <mat-error name="personal-details-col-2-error-0" id="personal-details-col-2-error-0" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmpLastName'].invalid">
                <mat-error name="personal-details-col-2-error-1" id="personal-details-col-2-error-1" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmpLastName'].errors!.required">
                  Last Name is required
                </mat-error>
                <mat-error name="personal-details-col-2-error-2" id="personal-details-col-2-error-2" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmpLastName'].errors!.pattern">
                  Only character allowed
                </mat-error>
                <mat-error name="personal-details-col-2-error-3" id="personal-details-col-2-error-3" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmpLastName'].errors!.minlength && !(personalDetailsForm.controls['vEmpLastName'].errors!.pattern)">
                  Must have minimum 2 characters
                </mat-error>
                <mat-error name="personal-details-col-2-error-4" id="personal-details-col-2-error-4" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmpLastName'].errors!.maxlength && !(personalDetailsForm.controls['vEmpLastName'].errors!.pattern)">
                  Must not exceed 46 characters
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="personal-details-col-2" id="personal-details-col-2" class="col-12">
            <label for="vEmailId" name="personal-details-col-2-label" id="personal-details-col-2-label"
              class="custom-label">Email Address <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="personal-details-col-2-input" id="personal-details-col-2-input"
              class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="vEmailId" formControlName="vEmailId" placeholder="toptech@inc.com"
                />
                <mat-error name="personal-details-col-3-error-0" id="personal-details-col-3-error-0"
                class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vEmailId'].invalid">
                <mat-error name="personal-details-col-3-error-2" id="personal-details-col-3-error-2"
                  class="reduce-bottom-space" *ngIf="
                    personalDetailsForm.controls['vEmailId'].errors!
                      .email ||
                    personalDetailsForm.controls['vEmailId'].errors!.pattern
                  ">
                  Enter valid email
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="personal-details-col-7" id="personal-details-col-7" class="col-12 col-md-6">
            <label for="vGender" name="personal-details-col-7-label" id="personal-details-col-7-label" class="custom-label">Gender <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="personal-details-col-7-select" id="personal-details-col-7-select" class="custom-dropdown form">
              <mat-select id="vGender" formControlName="vGender" placeholder="Select Gender" required>
                <mat-option *ngFor="let item of genderListArray; let i = index"
                  [value]="item.id">{{ item.vName }}</mat-option>
              </mat-select>
              <mat-error name="personal-details-col-7-error-0" id="personal-details-col-7-error-0" class="reduce-bottom-space">
                <mat-error name="personal-details-col-7-error-1" id="personal-details-col-7-error-1" class="reduce-bottom-space">
                  Gender is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="personal-details-founded-date" id="personal-details-founded-date" class="col-12 col-md-6">
            <label for="iBirthDate" name="personal-details-founded-date-label" id="personal-details-founded-date-label" class="custom-label">Date of Birth <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="personal-details-founded-date-input" id="personal-details-founded-date-input" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never">
              <input matInput [matDatepicker]="picker" [max]="today" id="iBirthDate" formControlName="iBirthDate" placeholder="7/28/1996" required readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error name="personal-details-founded-date-error-0" id="personal-details-founded-date-error-0" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iBirthDate'].invalid">
                <mat-error name="personal-details-col-10-error-1" id="personal-details-founded-date-error-1" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iBirthDate'].errors!.required">
                  Date of Birth is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="personal-details-col-5" id="personal-details-col-5" class="col-12 col-md-6 position-relative mb-2 pb-2">
            <label for="iPhoneNumber" name="personal-details-col-5-label" id="personal-details-col-5-label" class="custom-label">Phone Number <span class="text-danger">*</span></label>
            <ngx-intl-tel-input [cssClass]="'custom'" [ngClass]="{'tel-input-error': personalDetailsSubmitted && personalDetailsForm.controls['iPhoneNumber']?.invalid}" name="input-field-company-phone-number" id="input-field-company-phone-number" class="custom-country-phone-code-dropdown input" [preferredCountries]="preferredCountries"
      				[enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
      				[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" (countryChange)="countryChange($event)"
      				[selectedCountryISO]="CountryISO.India" [maxLength]="maxLength"
      				[phoneValidation]="true" [separateDialCode]="false"
      				[numberFormat]="PhoneNumberFormat.National" id="iPhoneNumber" name="iPhoneNumber" formControlName="iPhoneNumber"
              (paste)="(false)" (copy)="false">
      			</ngx-intl-tel-input>
              <mat-error name="employee-details-col-4-error-0" id="employee-details-col-4-error-0" class="position-absolute w-100 increase-z-index reduce-bottom-space p-0" *ngIf="personalDetailsSubmitted && personalDetailsForm.controls['iPhoneNumber']?.invalid">
                <mat-error name="employee-details-col-4-error-1" id="employee-details-col-4-error-1" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iPhoneNumber'].errors!.required">
                  Phone Number is required
                </mat-error>
                <mat-error name="employee-details-col-4-error-1" id="employee-details-col-4-error-1" class="reduce-bottom-space" *ngIf="!personalDetailsForm.controls['iPhoneNumber'].errors!.required">
                  Kindly enter valid phone number
                </mat-error>
              </mat-error>
          </div>
          <div class="col-12 col-md-6 field-box">
            <label for="vVideo" name="personal-details-video-label" id="personal-details-video-label" class="custom-label">Introduction Video</label>
            <mat-form-field hideRequiredMarker name="personal-details-video-input" id="personal-details-video-input" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box intro-video-width" *ngIf="IntroVideo">
                    <perfect-scrollbar [config]="config">
                      <div class="file">
                        {{ IntroVideo.name }} <button type="button" name="button" (click)="crossClick(IntroVideo)" class="btn"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input matInput name="personal-details-video-input-vVideo-1" id="personal-details-video-input-vVideo-1" readonly formControlName="vVideo" [value]="IntroVideo ? ' ' : ''" placeholder="Upload Intro Video" />
              <!-- <mat-icon matSuffix class="camera-icon">camera_enhance</mat-icon> -->
              <input
                matSuffix
                type="file"
                #uploadFile
                name="personal-details-video-input-vVideo-2"
                id="personal-details-video-input-vVideo-2"
                class="file-upload-input"
                (change)="FileBrowseHandler($event,2)"
                formControlName="vVideo"
                accept="video/*"
              />
              <button name="personal-details-video-input-upload-btn" id="personal-details-video-input-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <div class="input-details-box">
                <p class="text">Max 50MB</p>
              </div>
            </mat-form-field>
            <div class="w-100" *ngIf="PersonalDetail?.introVideoUrl">
              <div name="personal-details-video-class-1" id="personal-details-video-1" class="image-display-container pb-2">
                <div name="personal-details-video-1" id="personal-details-video-1" class="image-name">
                  <a *ngIf="PersonalDetail?.introVideoUrl" name="personal-details-card-content-col-4-link" id="personal-details-card-content-col-4-link" target="_blank" href="{{ PersonalDetail?.introVideoUrl }}">{{ PersonalDetail?.vFirstName }}_{{ PersonalDetail?.vLastName }}_intro.mp4</a>
                </div>
                <button type="button" name="button" id="personal-details-video-1-btn" (click)="videoThumbnail(PersonalDetail.introVideoUrl)">
                  <img id="personal-details-video-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button>
              </div>
            </div>
          </div>
          <div name="personal-details-col-6" id="personal-details-col-6" class="col-12 col-md-12 field-box">
            <label for="vAddress" name="personal-details-col-6-label" id="personal-details-col-6-label" class="custom-label reduce-top-spacing">Address <span class="text-danger">*</span></label>
            <mat-form-field name="personal-details-col-6-input" id="personal-details-col-6-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="vAddress" formControlName="vAddress" placeholder="Address" required>
              <mat-error name="personal-details-col-6-error-0" id="personal-details-col-6-error-0" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vAddress'].invalid">
                <mat-error name="personal-details-col-6-error-1" id="personal-details-col-6-error-1" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vAddress'].errors!.required">
                  Address is required
                </mat-error>
                <mat-error name="personal-details-col-6-error-2" id="personal-details-col-6-error-2" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vAddress'].errors!.minlength">
                  Must have minimum 3 characters
                </mat-error>
                <mat-error name="personal-details-col-6-error-3" id="personal-details-col-6-error-3" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['vAddress'].errors!.maxlength">
                  Must not exceed 255 characters
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="personal-details-col-7" id="personal-details-col-7" class="col-12 col-md-6">
            <label for="iCountryId" name="personal-details-col-7-label" id="personal-details-col-7-label" class="custom-label">Country <span class="text-danger">*</span></label>

            <mat-form-field hideRequiredMarker name="personal-details-col-7-input" id="personal-details-col-7-input" class="custom-dropdown custom-input dropdown-section-city form" floatLabel="never" floatPlaceholder="never">
              <input type="text" placeholder="Search Country" matInput formControlName="iCountryId" [matAutocomplete]="autoCountry" required>
              <mat-autocomplete autoActiveFirstOption #autoCountry="matAutocomplete">
                <mat-option *ngFor="let option of filteredCountryListArray | async" [value]="option?.vName" (onSelectionChange)="GetStateListClick(option?.iCountryId, true)">
                  {{option?.vName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="personal-details-col-7-error-0" id="personal-details-col-7-error-0"
                class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iCountryId'].invalid">
                <mat-error name="personal-details-col-7-error-1" id="personal-details-col-7-error-1"
                  class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iCountryId'].errors?.required">
                  Please select a country from the list
                </mat-error>
              </mat-error>
            </mat-form-field>

          </div>
          <div name="personal-details-col-8" id="personal-details-col-8" class="col-12 col-md-6">
            <label for="iStateId" name="personal-details-col-8-label" id="personal-details-col-8-label" class="custom-label">State/Province/Region <span class="text-danger">*</span></label>

            <mat-form-field hideRequiredMarker name="personal-details-col-8-input" id="personal-details-col-8-input" class="custom-dropdown custom-input dropdown-section-city form" floatLabel="never" floatPlaceholder="never">
              <input type="text" placeholder="Search State" matInput formControlName="iStateId" [matAutocomplete]="autoState" required>
              <mat-autocomplete autoActiveFirstOption #autoState="matAutocomplete">
                <mat-option *ngFor="let option of filteredStateListArray | async" [value]="option?.vName" (onSelectionChange)="GetCityListClick(option?.iStateId, true)">
                  {{option?.vName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="personal-details-col-8-error-0" id="personal-details-col-8-error-0"
                class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iStateId'].invalid">
                <mat-error name="personal-details-col-8-error-1" id="personal-details-col-8-error-1"
                  class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iStateId'].errors?.required">
                  State is required
                </mat-error>
              </mat-error>
            </mat-form-field>

          </div>
          <div name="personal-details-col-9" id="personal-details-col-9" class="col-12 col-md-6 ">
            <label for="iCityId" name="personal-details-col-9-label" id="personal-details-col-9-label" class="custom-label">City/Town <span class="text-danger">*</span></label>

            <mat-form-field hideRequiredMarker name="personal-details-col-9-input" id="personal-details-col-9-input" class="custom-dropdown custom-input dropdown-section-city form" floatLabel="never" floatPlaceholder="never">
              <input type="text" placeholder="Search City" matInput formControlName="iCityId" [matAutocomplete]="autoCity" required>
              <mat-autocomplete autoActiveFirstOption #autoCity="matAutocomplete">
                <mat-option *ngFor="let option of filteredCityListArray | async" [value]="option?.vName" (onSelectionChange)="AssignCity(option?.iCityId)">
                  {{option?.vName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="personal-details-col-9-error-0" id="personal-details-col-9-error-0"
                class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iCityId'].invalid">
                <mat-error name="personal-details-col-9-error-1" id="personal-details-col-9-error-1"
                  class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iCityId'].errors?.required">
                  City is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="personal-details-col-10" id="personal-details-col-10" class="col-12 col-md-6 field-box">
            <label for="iZipCode" name="personal-details-col-10-label" id="personal-details-col-10-label" class="custom-label">Zip Code <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="personal-details-col-10-input" id="personal-details-col-10-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
              <input type="text" matInput id="iZipCode" formControlName="iZipCode" placeholder="Ex. 235647" required>
              <mat-error name="personal-details-col-10-error-0" id="personal-details-col-10-error-0" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iZipCode'].invalid">
                <mat-error name="personal-details-col-10-error-1" id="personal-details-col-10-error-1" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iZipCode'].errors!.required">
                  Zipcode is required
                </mat-error>
                <mat-error name="personal-details-col-10-error-2" id="personal-details-col-10-error-2" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iZipCode'].errors!.pattern">
                  Only numbers and characters are allowed
                </mat-error>
                <mat-error name="personal-details-col-10-error-3" id="personal-details-col-10-error-3" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iZipCode'].errors!.minlength">
                  Must have minimum 5 characters
                </mat-error>
                <mat-error name="personal-details-col-10-error-4" id="personal-details-col-10-error-4" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iZipCode'].errors!.maxlength">
                  Must not exceed 10 characters
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="personal-details-col-7" id="personal-details-col-7" class="col-12">
            <label for="iHobbies" name="personal-details-col-7-label" id="personal-details-col-7-label" class="custom-label">Hobbies <span class="text-danger">*</span></label>

            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never"
              name="personal-details-col-7-select" id="personal-details-col-7-select"
              class="custom-dropdown form">
              <mat-chip-list #hobbieChipList aria-label="Hobbie selection" class="custom-multi-chip-dropdown"
                formControlName="iHobbies">
                <mat-chip *ngFor="let hobbie of hobbies" [removable]="true" (removed)="OnHobbiesRemoved(hobbie)">
                  {{hobbie.vHobbiesName}}
                  <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input matInput #hobbieInput (keyup)="_filterHobbies(hobbieInput.value)" [matAutocomplete]="autoHobbie"
                  [matChipInputFor]="hobbieChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="hobbieInput.value = ''">
              </mat-chip-list>
              <mat-autocomplete #autoHobbie="matAutocomplete" [displayWith]="DisplayFnHobbies"
                (optionSelected)="AddHobbies($event.option.value); hobbieInput.value = ''">
                <mat-option *ngFor="let hobbie of filterHobbies" [value]="hobbie">
                  {{hobbie.vHobbiesName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="professional-details-col-7-error-0" id="professional-details-col-7-error-0" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iHobbies'].invalid">
                <mat-error name="professional-details-col-7-error-1" id="professional-details-col-7-error-1" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iHobbies'].errors?.required">
                  Hobbies are required
                </mat-error>
              </mat-error>
            </mat-form-field>

          </div>
          <div name="personal-details-col-11" id="personal-details-col-11" class="col-12 field-box">
            <label for="iEmpBio" name="personal-details-col-11-label" id="personal-details-col-11-label" class="custom-label">Bio <span class="text-danger">*</span></label>
            <mat-form-field floatPlaceholder="never" floatLabel="never" name="mat-form-field-company-bio" id="mat-form-field-company-bio" class="custom-input textarea">
              <textarea matInput rows="7" name="iEmpBio" id="iEmpBio" formControlName="iEmpBio" placeholder="Type here" #iEmpBio></textarea>
              <span name="bio-character-count-1" id="bio-character-count-1" class="bio-character-counter"><span name="bio-character-count-2" id="bio-character-count-2" class="me-1" [ngClass]="{'exceeded': iEmpBio.value.length < 150}">{{iEmpBio.value.length}}</span> / 150</span>
              <mat-error name="mat-error-company-bio" id="mat-error-company-bio" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iEmpBio'].invalid">
                <mat-error name="mat-error-company-bio-text-1" id="mat-error-company-bio-text-1" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iEmpBio'].errors!.required">
                  Bio is required
                </mat-error>
                <mat-error name="mat-error-company-bio-text-2" id="mat-error-company-bio-text-2" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iEmpBio'].errors!.minlength && !(personalDetailsForm.controls['iEmpBio'].errors!.pattern || personalDetailsForm.controls['iEmpBio'].errors!.pattern===null)">
                  Must have minimum 150 characters
                </mat-error>
                <mat-error name="mat-error-company-bio-text-3" id="mat-error-company-bio-text-3" class="reduce-bottom-space" *ngIf="personalDetailsForm.controls['iEmpBio'].errors!.pattern">
                  Please enter valid text
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div name="personal-details-modal-footer" id="personal-details-modal-footer" class="modal-footer">
      <button type="button" name="personal-details-modal-footer-btn-cancel" id="personal-details-modal-footer-btn-cancel" class="blue-secondary-small-btn mx-2" (click)="GetPersonalDetail(); this.personalDetailsToggle=false">Cancel</button>
      <button type="submit" name="personal-details-modal-footer-btn-submit" id="personal-details-modal-footer-btn-submit" class="blue-primary-small-btn" form="personalDetailsForm">Save</button>
    </div>
  </div>
</div>
</div>




<ng-template #Crop let-c="close" let-d="dismiss">
  <div name="crop-modal-header" id="crop-modal-header" class="modal-header">
    <h1 name="crop-modal-title" id="crop-modal-title" class="modal-title">Crop Image</h1>
  </div>
  <div class="">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="4 / 4"
      [roundCropper]="true"
      format="png"
      (imageCropped)="ImageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
  <div name="crop-modal-footer" id="crop-modal-footer" class="modal-footer">
    <button type="submit" name="crop-modal-footer-btn-submit" id="crop-modal-footer-btn-submit" class="btn btn-active" (click)="d('cross click'); ImageErrordisable()">Crop</button>
    <button type="button" name="crop-modal-footer-btn-cancel" id="crop-modal-footer-btn-cancel" class="blue" (click)="d('cross click'); CancelCropModel()">Cancel</button>
  </div>
</ng-template>
