import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ViewPartnerService } from "../view-partner.service";
import { NotificationService } from "../../../../config/notification.service";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { CertificationsService } from "./certifications.service";
import { StaticMsg } from "src/app/config/en";
import { environment } from "../../../../../environments/environment";
import { Permission } from "../../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../../utils/services/user-permission.service";

@Component({
  selector: "partner-certifications",
  templateUrl: "./certifications.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class PartnerCertificationsComponent implements OnInit {
  @Input() partnerUserId:any;
  certificationsToggle:boolean=false;
  
  imagePath = environment.image_path;

  // toggle actions permission
  editPartnerCertificationsActionPermission: boolean = false;

  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  certificationForm!: FormGroup;
  isoCertificationForm!: FormGroup;
  certificationSubmitted: any = false;
  @ViewChild("uploadFile") fileInput: any;
  ISOList: any = [];
  CMMIList: any = [];
  isoCertificateList: any = [];
  cmmiCertificates: any = [];
  otherCertificatesList: any;
  isoCertificate: any;
  iecCertificate: any;
  cmmiCertificate: any;
  trademarkCertifiacate: any;
  reward: any;
  certificateStatus: any;
  iParnterISORequired: any;
  iParnterCMMIRequired: any;

  constructor(config: NgbModalConfig, private modalService: NgbModal, private partnerService: ViewPartnerService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService, private certificationsService: CertificationsService, private userPermissionService: UserPermissionService) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const EditPartnerCertificationsAction: Permission = Permission.EditPartnerCertificationsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(EditPartnerCertificationsAction)) {
      this.editPartnerCertificationsActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });
    this.GetCertificate();
    this.GetCertificatesStatus();
    this.GetCheckApplicable();
    this.isoCertificationForm = new FormGroup({
      vISOId: new FormControl("", [Validators.required]),
      vISOCerNumber: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(19), Validators.pattern("^(?! ).*[^ ]$")]),
      vISOCertificatePdf: new FormControl("", [Validators.required]),
      isoCertificateList: new FormControl("", [Validators.required]),
    });

    this.certificationForm = new FormGroup({
      vIECNumber: new FormControl("", [Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[A-Z0-9]+$")]),
      vIECNumberPdf: new FormControl(""),
      vCMMIId: new FormControl("", [Validators.required]),
      vCMMICerNumber: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^(?! ).*[^ ]$")]),
      vCMMICertificatePdf: new FormControl("", [Validators.required]),
      vTrademarkCertificatePdf: new FormControl(""),
      reward: new FormControl(""),
    });
  }

  public certificationsCardCompleted: any = false;
  updateProfileStatus(data: any) {
    this.certificationsCardCompleted = data["compnayCertifications"];
  }


  CancelCertificationsModal() {
    this.certificationsToggle=false;
  }

  GetCertificate() {
    this.partnerService.GetCertificate(this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.ISOList = result["ISOArray"];
        this.CMMIList = result["data"];
      }
    });
  }

  FileBrowseHandler(event: any, type: any) {
    let fileSize = event["target"]["files"][0]["size"] / 1024;
    if (fileSize < 4 * 1024) {
      if (type != 5) {
        if (event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/vnd.ms-excel" || event.target.files[0].type == "image/x-png" || event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg") {
          if (type == 1) {
            this.isoCertificate = event.target.files[0];
          }
          if (type == 2) {
            this.iecCertificate = event.target.files[0];
          }
          if (type == 3) {
            this.cmmiCertificate = event.target.files[0];
          }
          if (type == 4) {
            this.trademarkCertifiacate = event.target.files[0];
          }
        } else {
          {
            this.notifyService.showError(StaticMsg.NotPdfImageFileError, "");
            return;
          }
        }
      } else if (type == 5 && (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg")) {
        this.reward = event.target.files[0];
      } else {
        this.notifyService.showError(StaticMsg.NotValidImageTypeError, "");
        return;
      }
    } else {
      this.notifyService.showError(StaticMsg.FileLargerError, "");
      return;
    }
  }

  CancelMedia(type: any) {
    if (type == 1) {
      this.isoCertificate = "";
      this.isoCertificationForm.controls["vISOCertificatePdf"].patchValue("");
    }
    if (type == 2) {
      this.iecCertificate = "";
      this.certificationForm.controls["vIECNumberPdf"].patchValue("");
    }
    if (type == 3) {
      this.cmmiCertificate = "";
      this.certificationForm.controls["vCMMICertificatePdf"].patchValue("");
    }
    if (type == 4) {
      this.trademarkCertifiacate = "";
      this.certificationForm.controls["vTrademarkCertificatePdf"].patchValue("");
    }
    if (type == 5) {
      this.reward = "";
      this.certificationForm.controls["reward"].patchValue("");
    }
  }

  GetCertificatesStatus() {
    this.partnerService.GetCertificatesStatus(this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.certificateStatus = result["data"];
        if (this.certificateStatus.cmmiStatus) {
          this.certificationForm.controls["vCMMICertificatePdf"].clearValidators();
          this.certificationForm.controls["vCMMIId"].clearValidators();
          this.certificationForm.controls["vCMMICerNumber"].clearValidators();
          this.certificationForm.controls["vCMMICertificatePdf"].setErrors(null);
          this.certificationForm.controls["vCMMIId"].setErrors(null);
          this.certificationForm.controls["vCMMICerNumber"].setErrors(null);
          this.certificationForm.controls["vCMMICerNumber"].setValidators([Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^(?! ).*[^ ]$")]);
        } else {
          this.certificationForm.controls["vCMMICertificatePdf"].setValidators([Validators.required]);
          this.certificationForm.controls["vCMMIId"].setValidators([Validators.required]);
          this.certificationForm.controls["vCMMICerNumber"].setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^(?! ).*[^ ]$")]);
          if (this.certificationForm.controls["vCMMIId"].value == null) {
            this.certificationForm.controls["vCMMIId"].patchValue(null);
          }
          if (this.certificationForm.controls["vCMMICerNumber"].value == null) {
            this.certificationForm.controls["vCMMICerNumber"].patchValue(null);
          }
          if (this.certificationForm.controls["vCMMICertificatePdf"].value == null) {
            this.certificationForm.controls["vCMMICertificatePdf"].patchValue(null);
          }
        }
        if (this.certificateStatus.isoStatus) {
          this.isoCertificationForm.controls["vISOCertificatePdf"].clearValidators();
          this.isoCertificationForm.controls["vISOCerNumber"].clearValidators();
          this.isoCertificationForm.controls["vISOCerNumber"].setValidators([Validators.minLength(6), Validators.maxLength(19), Validators.pattern("^(?! ).*[^ ]$")]);
          this.isoCertificationForm.controls["vISOId"].clearValidators();
        } else {
          this.isoCertificationForm.controls["vISOCertificatePdf"].setValidators([Validators.required]);
          this.isoCertificationForm.controls["vISOCerNumber"].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(19), Validators.pattern("^(?! ).*[^ ]$")]);
          this.isoCertificationForm.controls["vISOId"].setValidators([Validators.required]);
          this.isoCertificationForm.controls["vISOCertificatePdf"].patchValue(null);
          this.isoCertificationForm.controls["vISOCerNumber"].patchValue(null);
          this.isoCertificationForm.controls["vISOId"].patchValue(null);
          if (this.isoCertificateList.length > 1) {
            this.isoCertificationForm.controls["isoCertificateList"].setValue(this.isoCertificateList.length);
          }
        }
      }
    });
  }

  GetAllCertificate() {
    this.partnerService.GetAllCertificate(this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.isoCertificate = "";
        this.isoCertificateList = result["data"];
        if (this.isoCertificateList > 0) {
          this.isoCertificationForm.controls["isoCertificateList"].setValue(this.isoCertificateList.length);
        } else {
          this.isoCertificationForm.controls["isoCertificateList"].setValue(null);
        }
        this.cmmiCertificate = "";
        this.cmmiCertificates = result["cmmiCertificates"];
        this.otherCertificatesList = result["otherCertificates"];
        this.certificationForm.patchValue({
          vIECNumber: this.otherCertificatesList["iecPdf"] ? this.otherCertificatesList["iecPdf"]["vCerId"] : "",
        });
        this.GetCertificatesStatus();
      }
    });
  }

  PatchISOOnly() {
    this.partnerService.GetAllCertificate(this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.isoCertificate = "";
        this.isoCertificateList = result["data"];
        if (this.isoCertificateList > 0) {
          this.isoCertificationForm.controls["isoCertificateList"].setValue(this.isoCertificateList.length);
        } else {
          this.isoCertificationForm.controls["isoCertificateList"].setValue(null);
        }
        this.GetCertificatesStatus();
      }
    });
  }

  GetCheckApplicable() {
    this.certificationsService.GetCheckApplicable(this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.iParnterISORequired = result.data.iParnterISORequired;
        this.iParnterCMMIRequired = result.data.iParnterCMMIRequired;

        if (this.iParnterISORequired) {
          this.isoCertificationForm.controls["vISOCertificatePdf"].disable();
          this.isoCertificationForm.controls["vISOCerNumber"].disable();
          this.isoCertificationForm.controls["vISOId"].disable();
          this.isoCertificationForm.controls["isoCertificateList"].disable();
          this.isoCertificationForm.controls["vISOCertificatePdf"].patchValue("");
          this.isoCertificationForm.controls["vISOCerNumber"].patchValue("");
          this.isoCertificationForm.controls["vISOId"].patchValue("");
        } else {
          this.isoCertificationForm.controls["vISOCertificatePdf"].enable();
          this.isoCertificationForm.controls["vISOCerNumber"].enable();
          this.isoCertificationForm.controls["vISOId"].enable();
          this.isoCertificationForm.controls["isoCertificateList"].enable();
        }

        if (this.iParnterCMMIRequired) {
          this.certificationForm.controls["vCMMIId"].disable();
          this.certificationForm.controls["vCMMICerNumber"].disable();
          this.certificationForm.controls["vCMMICertificatePdf"].disable();
          this.certificationForm.controls["vCMMIId"].patchValue("");
          this.certificationForm.controls["vCMMICerNumber"].patchValue("");
          this.certificationForm.controls["vCMMICertificatePdf"].patchValue("");
        } else {
          this.certificationForm.controls["vCMMIId"].enable();
          this.certificationForm.controls["vCMMICerNumber"].enable();
          this.certificationForm.controls["vCMMICertificatePdf"].enable();
          this.certificationForm.controls["vCMMICertificatePdf"].setValidators([Validators.required]);
          this.certificationForm.controls["vCMMIId"].setValidators([Validators.required]);
          this.certificationForm.controls["vCMMICerNumber"].setValidators([Validators.required]);
        }
      }
    });
  }

  CheckApplicable(event: any, type: any) {
    let reqData: any = {};
    if (type == 1) {
      reqData.iParnterISORequired = event.checked;
      this.isoCertificationForm.controls["vISOCertificatePdf"].patchValue("");
      this.isoCertificationForm.controls["isoCertificateList"].setErrors(null);
      this.isoCertificate = "";
    } else {
      reqData.iParnterCMMIRequired = event.checked;
      this.certificationForm.controls["vCMMICertificatePdf"].patchValue("");
      this.cmmiCertificate = "";
    }
    this.certificationsService.CheckApplicable(reqData,this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetCheckApplicable();
        this.eventEmitterService.GetProfileStatusAPICallMethod();
      }
    });
  }

  DeleteAllCertificate(id: any) {
    this.partnerService.DeleteAllCertificate(id,this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.notifyService.showSuccess("Certificate deleted successfully!", "");
        this.GetAllCertificate();
        this.isoCertificationForm.controls["isoCertificateList"].markAsTouched();
        this.eventEmitterService.GetProfileStatusAPICallMethod();
      }
    });
  }

  UploadISOCertificate() {
    if (this.isoCertificationForm.controls["vISOId"].value && this.isoCertificationForm.controls["vISOCerNumber"].value && this.isoCertificationForm.controls["vISOCertificatePdf"].value) {
      this.isoCertificationForm.controls["isoCertificateList"].setErrors(null);
    }
    if (this.isoCertificationForm.invalid) {
      return;
    }
    if (this.isoCertificationForm.value.vISOId && this.isoCertificationForm.value.vISOCerNumber && this.isoCertificate) {
      let reqData = {
        vISOId: this.isoCertificationForm.value.vISOId,
        vISOCerNumber: this.isoCertificationForm.value.vISOCerNumber,
        isoCertificate: this.isoCertificate,
      };
      this.partnerService.UploadISOCertificate(reqData,this.partnerUserId).then((result: any) => {
        if (result.code == 200) {
          this.PatchISOOnly();
          this.isoCertificate = "";
          this.isoCertificationForm.reset();
          this.isoCertificationForm.controls.vISOId.setErrors(null);
          this.isoCertificationForm.controls.vISOCerNumber.setErrors(null);
          this.isoCertificationForm.controls.vISOCertificatePdf.setErrors(null);
          this.isoCertificationForm.controls.isoCertificateList.setErrors(null);
          if (this.isoCertificateList.length > 1) {
            this.isoCertificationForm.controls["isoCertificateList"].setValue(this.isoCertificateList.length);
          }
          this.eventEmitterService.GetProfileStatusAPICallMethod();
        }
      });
    }
  }

  UploadCertificate() {
    let tempflag = true;
    if (this.certificationForm.controls["vCMMIId"].value && this.certificationForm.controls["vCMMICerNumber"].value && this.certificationForm.controls["vCMMICertificatePdf"].value) {
      tempflag = false;
      this.isoCertificationForm.controls["isoCertificateList"].markAsTouched();
    } else {
      this.isoCertificationForm.controls["vISOId"].markAsTouched();
      this.isoCertificationForm.controls["vISOCerNumber"].markAsTouched();
      this.isoCertificationForm.controls["vISOCertificatePdf"].markAsTouched();
      this.isoCertificationForm.controls["isoCertificateList"].markAsTouched();
      this.certificationForm.controls["vCMMIId"].markAsTouched();
      this.certificationForm.controls["vCMMICerNumber"].markAsTouched();
      this.certificationForm.controls["vCMMICertificatePdf"].markAsTouched();
    }
    if (!this.iParnterCMMIRequired && this.cmmiCertificates.length === 0 && tempflag) {
      this.certificationForm.controls["vCMMIId"].markAsTouched();
      this.certificationForm.controls["vCMMICerNumber"].markAsTouched();
      this.certificationForm.controls["vCMMICertificatePdf"].markAsTouched();
      return;
    }
    if (!this.iParnterCMMIRequired && this.cmmiCertificates.length > 0) {
      this.certificationForm.controls["vCMMIId"].setErrors(null);
      this.certificationForm.controls["vCMMICerNumber"].setErrors(null);
      this.certificationForm.controls["vCMMICertificatePdf"].setErrors(null);
    }
    if (this.certificationForm.invalid || (this.isoCertificateList.length < 1 && this.iParnterISORequired === false)) {
      this.isoCertificationForm.controls["vISOId"].markAsTouched();
      this.isoCertificationForm.controls["vISOCerNumber"].markAsTouched();
      this.isoCertificationForm.controls["vISOCertificatePdf"].markAsTouched();
      this.isoCertificationForm.controls["isoCertificateList"].markAsTouched();
      return;
    } else {
      this.certificationForm.controls["vCMMIId"].setErrors(null);
      this.certificationForm.controls["vCMMICerNumber"].setErrors(null);
      this.certificationForm.controls["vCMMICertificatePdf"].setErrors(null);
    }
    if (this.isoCertificateList.length > 1) {
      this.isoCertificationForm.controls["isoCertificateList"].setValue(this.isoCertificateList.length);
    } else {
      this.isoCertificationForm.controls["isoCertificateList"].markAsTouched();
    }
    let reqData = {
      vIECNumber: this.certificationForm.value.vIECNumber,
      iecCertificate: this.iecCertificate,
      vCMMIId: this.certificationForm.value.vCMMIId,
      vCMMICerNumber: this.certificationForm.value.vCMMICerNumber,
      cmmiCertificate: this.cmmiCertificate,
      trademarkCertifiacate: this.trademarkCertifiacate,
      reward: this.reward,
    };
    this.partnerService.UploadCertificate(reqData,this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.certificationForm.reset();
        this.GetCertificatesStatus();
        this.GetAllCertificate();
        this.notifyService.showSuccess(result.message, "");
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.iecCertificate = "";
        this.cmmiCertificate = "";
        this.trademarkCertifiacate = "";
        this.reward = "";
        this.certificationsToggle=false;
      } else {
        this.certificationsToggle=false;
      }
    });
  }

  toggleCertifications(hasPermission:boolean){
    if (hasPermission) {
      this.GetCheckApplicable();
      this.isoCertificationForm.reset();
      this.certificationForm.reset();
      this.isoCertificate = "";
      this.iecCertificate = "";
      this.cmmiCertificate = "";
      this.trademarkCertifiacate = "";
      this.reward = "";
      this.GetAllCertificate();
      this.certificationsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }
}
