<div name="professional-details-box" id="professional-details-box" class="col-12">
  <div name="professional-details-card-container" id="professional-details-card-container" class="card-container" *ngIf="professionalDetailsToggle == false">
    <div name="professional-details-card-header" id="professional-details-card-header" class="card-header">
      <div name="professional-details-title-section" id="professional-details-title-section" class="title-section">
        <h4 name="professional-details-card-title" id="professional-details-card-title" class="card-title">Professional Details</h4>
        <div placement="right auto" [ngbTooltip]="editTalentProfessionalDetailsActionPermission ? '' : 'Not Authorized'" [ngClass]="
        editTalentProfessionalDetailsActionPermission ? '' : 'unauthorized-cursor'">
        <button name="professional-details-card-edit-button" id="professional-details-card-edit-button" class="card-edit-button mx-2 mx-sm-3" type="button" (click)="toggleProfessionalDetails(editTalentProfessionalDetailsActionPermission)" [ngClass]="
        editTalentProfessionalDetailsActionPermission ? '' : 'unauthorized-action'">
          <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
        </button>
        </div>
      </div>
      <div name="professional-details-status-section" id="professional-details-status-section" class="status-section">
        <div name="professional-details-status-incomplete-status-box" id="professional-details-status-incomplete-status-box" class="status-box" *ngIf="!professionalDetailsCardCompleted">
          <img id="professional-details-status-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'" alt="status-icon">
          <label name="professional-details-status-incomplete-status-text" id="professional-details-status-incomplete-status-text" class="status-text incomplete">Pending</label>
        </div>
        <div name="professional-details-status-completed-status-box" id="professional-details-status-completed-status-box" class="status-box" *ngIf="professionalDetailsCardCompleted">
          <img id="professional-details-status-incomplete-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'" alt="status-icon">
          <label name="professional-details-status-incomplete-status-text" id="professional-details-status-incomplete-status-text" class="status-text completed">Completed</label>
        </div>
      </div>
    </div>
    <div name="professional-details-card" id="professional-details-card" class="card" [ngClass]="professionalDetailsCardCompleted ? 'completed' : 'incomplete'">
      <div name="professional-details-card-content" id="professional-details-card-content" class="card-content">
        <div name="professional-details-row" id="professional-details-row" class="row">
          <div name="professional-details-card-content-col-3" id="professional-details-card-content-col-3" class="col-12 col-sm-6">
            <label name="professional-details-card-content-col-3-label" id="professional-details-card-content-col-3-label" class="label">
              Primary Skill
            </label>
            <label name="professional-details-card-content-col-3-text" id="professional-details-card-content-col-3-text" class="text bottom-spacing" *ngIf="professionalDetailsForm.value.vEmpDesignation">
              {{ professionalDetailsForm.value.vEmpDesignation }}
            </label>
            <label name="professional-details-card-content-col-3-text" id="professional-details-card-content-col-3-text" class="text bottom-spacing" *ngIf="!professionalDetailsForm.value.vEmpDesignation">
              Not Available
            </label>
          </div>
          <div name="professional-details-card-content-col-4" id="professional-details-card-content-col-4" class="col-12 col-sm-6">
            <label name="professional-details-card-content-col-4-label" id="professional-details-card-content-col-4-label" class="label">
              Experience
            </label>
            <label name="professional-details-card-content-col-4-text" id="professional-details-card-content-col-4-text" class="text bottom-spacing">
              <span *ngIf = "professionalDetailsArray.vExperienceYear == 1"> {{ professionalDetailsArray.vExperienceYear }} Year</span>
              <span *ngIf = "professionalDetailsArray.vExperienceYear > 1"> {{ professionalDetailsArray.vExperienceYear }} Years</span>
              <span *ngIf = "professionalDetailsArray.vExperienceMonth == 1"> {{ professionalDetailsForm.value.iEmpExMonths }} Month</span>
              <span *ngIf = "professionalDetailsArray.vExperienceMonth > 1"> {{ professionalDetailsForm.value.iEmpExMonths }} Months</span>
              <span *ngIf = "professionalDetailsArray.vExperienceYear == 0 && professionalDetailsArray.vExperienceMonth == 0 "> Not Available</span>
            </label>
          </div>
          <div name="professional-details-card-content-col-3" id="professional-details-card-content-col-3" class="col-12 col-sm-6">
            <label name="professional-details-card-content-col-3-label" id="professional-details-card-content-col-3-label" class="label">
              Designation
            </label>
            <label name="professional-details-card-content-col-3-text" id="professional-details-card-content-col-3-text" class="text bottom-spacing" *ngIf="professionalDetailsForm.value.vCmpDesignation">
              {{ professionalDetailsForm.value.vCmpDesignation }}
            </label>
            <label name="professional-details-card-content-col-3-text" id="professional-details-card-content-col-3-text" class="text bottom-spacing" *ngIf="!professionalDetailsForm.value.vCmpDesignation">
              Not Available
            </label>
          </div>
          <div name="professional-details-card-content-col-5" id="professional-details-card-content-col-5" class="col-12 col-sm-6">
            <label name="professional-details-card-content-col-5-label" id="professional-details-card-content-col-5-label" class="label">
              Known languages
            </label>
            <label name="professional-details-card-content-col-5-text" id="professional-details-card-content-col-5-text" class="text bottom-spacing" *ngIf="professionalDetailsArray.languagesName">
              {{ professionalDetailsArray.languagesName }}
            </label>
            <label name="professional-details-card-content-col-3-text" id="professional-details-card-content-col-3-text" class="text bottom-spacing" *ngIf="!professionalDetailsArray.languagesName">
              Not Available
            </label>
          </div>
          <div name="professional-details-card-content-col-7" id="professional-details-card-content-col-7" class="col-12">
            <label name="professional-details-card-content-col-7-label" id="professional-details-card-content-col-7-label" class="label">
              Technology
            </label>
            <label name="professional-details-card-content-col-7-text" id="professional-details-card-content-col-7-text" class="text bottom-spacing" *ngIf="professionalDetailsArray?.technologiesName?.length > 0">
              {{ professionalDetailsArray.technologiesName }}
            </label>
            <label name="professional-details-card-content-col-7-text" id="professional-details-card-content-col-7-text" class="text bottom-spacing" *ngIf="professionalDetailsArray?.technologiesName?.length == 0">
              Not Available
            </label>
          </div>
          <div name="professional-details-card-content-col-7" id="professional-details-card-content-col-7" class="col-12">
            <label name="professional-details-card-content-col-7-label" id="professional-details-card-content-col-7-label" class="label">
              Skills & Rating
            </label>
            <label name="professional-details-card-content-col-7-text" id="professional-details-card-content-col-7-text" class="text bottom-spacing skills-rating skills-rating-display" *ngIf="professionalDetailsArray.iSkills?.length > 0">
              <div class="rating-box">
                <div class="rating" *ngFor="let skill of professionalDetailsArray.iSkills; let i = index;">
                  <span class="me-2">{{skill.vName}}</span>
                  <div class="star-rating d-flex">
                    <span class="rating-text">{{skill.rating}}</span>
                  </div>
                </div>
              </div>
            </label>
            <label name="professional-details-card-content-col-7-text" id="professional-details-card-content-col-7-text" class="text bottom-spacing" *ngIf="!(professionalDetailsArray.iSkills?.length > 0)">
              Not Available
            </label>
          </div>
          <div name="professional-details-card-content-col-6" id="professional-details-card-content-col-6" class="col-12 col-sm-12">
            <label name="professional-details-card-content-col-6-text" id="professional-details-card-content-col-6-text" class="label">
              Availability
            </label>
            <div class="row">
              <div class="text weektime-margin">
                <label class="weekday-width">Monday : </label>
                <label *ngIf="!professionalDetailsArray?.iMondayStatus">Not working on this day</label>
                <label *ngIf="professionalDetailsArray?.iMondayStatus">{{professionalDetailsArray?.tMondayStart}} - {{professionalDetailsArray?.tMondayEnd}}</label>
              </div>
              <div class="text weektime-margin">
                <label class="weekday-width">Tuesday : </label>
                <label *ngIf="!professionalDetailsArray?.iTuesdayStatus">Not working on this day</label>
                <label *ngIf="professionalDetailsArray?.iTuesdayStatus">{{professionalDetailsArray?.tTuesdayStart}} - {{professionalDetailsArray?.tTuesdayEnd}}</label>
              </div>
              <div class="text weektime-margin">
                <label class="weekday-width">Wednesday : </label>
                <label *ngIf="!professionalDetailsArray?.iWednesdayStatus">Not working on this day</label>
                <label *ngIf="professionalDetailsArray?.iWednesdayStatus">{{professionalDetailsArray?.tWednesdayStart}} - {{professionalDetailsArray?.tWednesdayEnd}}</label>
              </div>
              <div class="text weektime-margin">
                <label class="weekday-width">Thursday : </label>
                <label *ngIf="!professionalDetailsArray?.iThursdayStatus">Not working on this day</label>
                <label *ngIf="professionalDetailsArray?.iThursdayStatus">{{professionalDetailsArray?.tThursdayStart}} - {{professionalDetailsArray?.tThursdayEnd}}</label>
              </div>
              <div class="text weektime-margin">
                <label class="weekday-width">Friday : </label>
                <label *ngIf="!professionalDetailsArray?.iFridayStatus">Not working on this day</label>
                <label *ngIf="professionalDetailsArray?.iFridayStatus">{{professionalDetailsArray?.tFridayStart}} - {{professionalDetailsArray?.tFridayEnd}}</label>
              </div>
              <div class="text weektime-margin">
                <label class="weekday-width">Saturday : </label>
                <label *ngIf="!professionalDetailsArray?.iSaturdayStatus">Not working on this day</label>
                <label *ngIf="professionalDetailsArray?.iSaturdayStatus">{{professionalDetailsArray?.tSaturdayStart}} - {{professionalDetailsArray?.tSaturdayEnd}}</label>
              </div>
              <div class="text">
                <label class="weekday-width">Sunday : </label>
                <label *ngIf="!professionalDetailsArray?.iSundayStatus">Not working on this day</label>
                <label *ngIf="professionalDetailsArray?.iSundayStatus">{{professionalDetailsArray?.tSundayStart}} - {{professionalDetailsArray?.tSundayEnd}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div name="professional-details-card-container" id="professional-details-card-container" class="card-container"
*ngIf="professionalDetailsToggle == true">
<div name="professional-details-card-header" id="professional-details-card-header" class="card-header">
  <div name="professional-details-title-section" id="professional-details-title-section" class="title-section">
    <h4 name="professional-details-card-title" id="professional-details-card-title" class="card-title">
      Professional Details
    </h4>
  </div>
</div>
<div class="edit-profile">
    <div name="professional-details-modal-body" id="professional-details-modal-body" class="modal-body modal-scroll">
      <form name="professionalDetailsForm" id="professionalDetailsForm" class="form" [formGroup]="professionalDetailsForm" (ngSubmit)="UploadProfessionalDetails()">
        <div name="professional-details-row" id="professional-details-row" class="row">
          <div name="professional-details-col-1" id="professional-details-col-1" class="col-12 col-md-6 professional-details">
            <label for="vEmpDesignation" name="professional-details-col-1-label" id="professional-details-col-1-label" class="custom-label">Primary Skill<span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="professional-details-col-1-input" id="professional-details-col-1-input" class="custom-dropdown custom-input form" floatLabel="never" floatPlaceholder="never">
              <input type="text" placeholder="Full Stack Developer" matInput formControlName="vEmpDesignation" [matAutocomplete]="auto" required>
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.vPrimarySkill" (onSelectionChange)="GetPrimarySkillId(option?.iPrimarySkillId)">
                  {{option?.vPrimarySkill}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="professional-details-col-1-error-0" id="professional-details-col-1-error-0" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['vEmpDesignation'].invalid">
                <mat-error name="professional-details-col-1-error-1" id="professional-details-col-1-error-1" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['vEmpDesignation'].errors?.required">
                  Designation is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="professional-details-col-2" id="professional-details-col-2" class="col-12 col-md-6">
            <label for="iEmpExperience" name="professional-details-col-2-label" id="professional-details-col-2-label" class="custom-label label-100">Experience <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="professional-details-col-7-select" id="professional-details-col-7-select" class="input-50 custom-dropdown form">
              <mat-select id="iEmpExYears" formControlName="iEmpExYears" placeholder="Years"  required>
                <mat-option *ngFor="let years of yearOptions; let i = index"
                      [value]="years.id">{{ years.value }} Year(s)</mat-option>
              </mat-select>
              <mat-error name="professional-details-col-7-error-0" id="professional-details-col-7-error-0" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['iEmpExYears'].invalid">
                <mat-error name="professional-details-col-7-error-1" id="professional-details-col-7-error-1" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['iEmpExYears'].errors?.required">
                  Year(s) is required
                </mat-error>
              </mat-error>
            </mat-form-field>
            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="professional-details-col-7-select" id="professional-details-col-7-select" class="input-50 custom-dropdown form">
              <mat-select id="iEmpExMonths" formControlName="iEmpExMonths" placeholder="Months" required>
                <mat-option *ngFor="let months of monthOptions; let i = index"
                      [value]="months.id">{{ months.value }} Month(s)</mat-option>
              </mat-select>
              <mat-error name="professional-details-col-7-error-0" id="professional-details-col-7-error-0" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['iEmpExMonths'].invalid">
                <mat-error name="professional-details-col-7-error-1" id="professional-details-col-7-error-1" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['iEmpExMonths'].errors?.required">
                  Months is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="professional-details-col-1" id="professional-details-col-1"
            class="col-12 professional-details">
            <label for="vCmpDesignation" name="professional-details-col-1-label" id="professional-details-col-1-label"
              class="custom-label">Designation <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="professional-details-col-1-input"
              id="professional-details-col-1-input" class="custom-dropdown custom-input form" floatLabel="never"
              floatPlaceholder="never">
              <input type="text" placeholder="Software Development Engineer-L1" matInput formControlName="vCmpDesignation"
                [matAutocomplete]="autoDesignation" required>
              <mat-autocomplete autoActiveFirstOption #autoDesignation="matAutocomplete">
                <mat-option *ngFor="let option of filteredCmpOptions | async" [value]="option.vCmpDesignation"
                (onSelectionChange)="GetCmpDesignation(option?.iCmpDesignationId)" [title]="option?.vCmpDesignation">
                  {{option?.vCmpDesignation}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="professional-details-col-1-error-0" id="professional-details-col-1-error-0"
                class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['vCmpDesignation'].invalid">
                <mat-error name="professional-details-col-1-error-1" id="professional-details-col-1-error-1"
                  class="reduce-bottom-space"
                  *ngIf="professionalDetailsForm.controls['vCmpDesignation'].errors?.required">
                  Designation is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="professional-details-col-7" id="professional-details-col-7" class="col-12">
            <label for="iTechnology" name="professional-details-col-7-label" id="professional-details-col-7-label" class="custom-label">Technology <span class="text-danger">*</span></label>

            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never"
              name="professional-details-input" id="professional-details-input"
              class="custom-dropdown custom-input form">
              <mat-chip-list #technologyChipList aria-label="Technology selection" class="custom-multi-chip-dropdown"
                formControlName="iTechnology">
                <mat-chip *ngFor="let technology of technologies" [removable]="true" (removed)="OnTechnologyRemoved(technology)">
                  {{technology.vTechnologyName}}
                  <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input matInput #technologyInput (keyup)="_filterTechnology(technologyInput.value)" [matAutocomplete]="autoTechnology"
                  [matChipInputFor]="technologyChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="technologyInput.value = ''">
              </mat-chip-list>
              <mat-autocomplete #autoTechnology="matAutocomplete" [displayWith]="DisplayFnTechnology"
                (optionSelected)="AddTechnology($event.option.value); technologyInput.value = ''">
                <mat-option *ngFor="let technology of filterTechnologies" [value]="technology">
                  {{technology.vTechnologyName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="professional-details-col-7-error-0" id="professional-details-col-7-error-0" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['iTechnology'].invalid">
                <mat-error name="professional-details-col-7-error-1" id="professional-details-col-7-error-1" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['iTechnology'].errors?.required">
                  Technology is required
                </mat-error>
              </mat-error>
            </mat-form-field>

          </div>
          <div name="professional-details-col-7" id="professional-details-col-7" class="col-12">
            <label for="iSkills" name="professional-details-col-7-label" id="professional-details-col-7-label" class="custom-label">Skills <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="professional-details-col-7-select" id="professional-details-col-7-select" class="custom-dropdown form">
              <mat-chip-list #chipList1 aria-label="Skill selection" class="custom-multi-chip-dropdown" formControlName="iSkills">
                <mat-chip *ngFor="let skill of skills" [removable]="true" (removed)="OnSkillRemoved(skill)">
                  {{skill.vName}}
                  <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input matInput
                #talentInput
                (keyup)="_filterTalent(talentInput.value)"
                [matAutocomplete]="auto1"
                [matChipInputFor]="chipList1"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="talentInput.value = ''">
              </mat-chip-list>
              <mat-autocomplete #auto1="matAutocomplete" [displayWith]="DisplayFnTalent" (optionSelected)="AddSkill($event.option.value); talentInput.value = ''">
                <mat-option *ngFor="let skill of filterSkills" [value]="skill">
                  {{skill.vName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="professional-details-col-7-error-1" id="professional-details-col-7-error-1" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['iSkills'].errors?.required || skills.length <= 0">
                  Skill is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="skills-rating">
            <div class="w-100" *ngFor="let skill of skills; let i = index">
              <div class="image-display-container pb-2">
                <div class="image-name">
                  <span>{{i+1}}) {{ skill.vName }}</span>
                </div>
                <div class="d-flex align-items-baseline">
                  <ngx-stars [size]="5" [initialStars]="skill.rating" [customPadding]="'0.3rem'" (ratingOutput)="OnRatingSet($event,i)"></ngx-stars>
                  <button type="button" name="button" id="rating-btn" (click)="OnSkillRemoved(skill)">
                    <img id="award-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div name="professional-details-col-7" id="professional-details-col-7" class="col-12">
            <label for="iLanguage" name="professional-details-col-7-label" id="professional-details-col-7-label" class="custom-label">Known Languages <span class="text-danger">*</span></label>

            <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never"
              name="professional-details-input" id="professional-details-input"
              class="custom-dropdown custom-input form">
              <mat-chip-list #languageChipList aria-label="Language selection" class="custom-multi-chip-dropdown"
                formControlName="iLanguage">
                <mat-chip *ngFor="let language of languages" [removable]="true" (removed)="OnLanguageRemoved(language)">
                  {{language.vLanguageName}}
                  <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input matInput #languageInput (keyup)="_filterLanguage(languageInput.value)" [matAutocomplete]="autoLanguage"
                  [matChipInputFor]="languageChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="languageInput.value = ''">
              </mat-chip-list>
              <mat-autocomplete #autoLanguage="matAutocomplete" [displayWith]="DisplayFnLanguage"
                (optionSelected)="AddLanguage($event.option.value); languageInput.value = ''">
                <mat-option *ngFor="let language of filterLanguages" [value]="language">
                  {{language.vLanguageName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="professional-details-col-7-error-0" id="professional-details-col-7-error-0" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['iLanguage'].invalid">
                <mat-error name="professional-details-col-7-error-1" id="professional-details-col-7-error-1" class="reduce-bottom-space" *ngIf="professionalDetailsForm.controls['iLanguage'].errors?.required">
                  Language is required
                </mat-error>
              </mat-error>
            </mat-form-field>

          </div>
          <div name="professional-details-working-hours-container" id="professional-details-working-hours-container" class="working-hours-container">
            <label for="vWorkingHours" name="professional-details-working-hours-container-title" id="professional-details-working-hours-container-title" class="bold-title">Availability <span class="text-danger">* </span> <span class="label text-secondary">(Please use 24-hour format)</span></label>
            <div name="professional-details-week-time-responsive" id="professional-details-week-time-responsive" class="week-time-responsive justify-content-between">
              <div name="professional-details-monday-week-copytime-class" id="professional-details-monday-week-copytime-class" class="week-copytime-class">
                <div name="professional-details-monday-weekday-width" id="professional-details-monday-weekday-width" class="weekday-width">
                  <mat-checkbox name="professional-details-monday" id="professional-details-monday" class="workinghoursCheckbox" formControlName="iMondayStatus" [checked]="professionalDetailsArray?.iMondayStatus">Monday</mat-checkbox>
                </div>
                <div name="professional-details-monday-working-hours" id="professional-details-monday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iMondayStatus == 0">
                  <div name="professional-details-monday-hour-to" id="professional-details-monday-hour-to" class="hour-to p-0">Not working on this day</div>
                </div>
                <div name="professional-details-monday-working-hours" id="professional-details-monday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iMondayStatus == 1">
                  <mat-form-field appearance="fill" name="professional-details-vMondayStartHour" id="professional-details-vMondayStartHour" class="hour-container">
                    <input type="text" matInput id="vMondayStartHour" formControlName="vMondayStartHour"  placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                  </mat-form-field>
                  <mat-form-field appearance="fill" name="professional-details-vMondayStartMinute" id="professional-details-vMondayStartMinute" class="minute-container">
                    <input type="text" matInput id="vMondayStartMinute" formControlName="vMondayStartMinute"placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                  </mat-form-field>
                  <div class="hour-to">to</div>
                  <mat-form-field appearance="fill" name="professional-details-vMondayEndHour" id="professional-details-vMondayEndHour" class="hour-container">
                    <input type="text" matInput id="vMondayEndHour" formControlName="vMondayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                  </mat-form-field>
                  <mat-form-field appearance="fill" name="professional-details-vMondayEndMinute" id="professional-details-vMondayEndMinute" class="minute-container">
                    <input type="text" matInput id="vMondayEndMinute" formControlName="vMondayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                  </mat-form-field>
                  <div class="error-box">
                    <mat-error class="reduce-bottom-space" *ngIf="(professionalDetailsForm.controls['vMondayStartHour']?.value>=professionalDetailsForm.controls['vMondayEndHour']?.value) || (professionalDetailsForm.controls['vMondayStartHour'].invalid && (professionalDetailsForm.controls['vMondayStartHour'].errors!.pattern || professionalDetailsForm.controls['vMondayStartHour'].errors!.required)) || (professionalDetailsForm.controls['vMondayStartMinute'].invalid && (professionalDetailsForm.controls['vMondayStartMinute'].errors!.pattern || professionalDetailsForm.controls['vMondayStartMinute'].errors!.required)) || (professionalDetailsForm.controls['vMondayEndHour'].invalid && (professionalDetailsForm.controls['vMondayEndHour'].errors!.pattern || professionalDetailsForm.controls['vMondayEndHour'].errors!.required)) || (professionalDetailsForm.controls['vMondayEndMinute'].invalid && (professionalDetailsForm.controls['vMondayEndMinute'].errors!.pattern || professionalDetailsForm.controls['vMondayEndMinute'].errors!.required))">
                      Please Enter Valid Time
                    </mat-error>
                  </div>
                </div>
              </div>
              <div name="professional-details-copy-time-container" id="professional-details-copy-time-container" class="copy-time-container">
                <a name="professional-details-copy-time-container-copy-icon" id="professional-details-copy-time-container-copy-icon" class="copy-time-btn" href="javascript:void(0)" (click)="copyToAll()">
                  <img [src]="imagePath + 'copy_hours_icon.svg'" class="copy-icon" alt="Copy icon">
                  <span name="professional-details-copy-time-container-time-text" id="professional-details-copy-time-container-time-text" class="time-text">Copy time to all</span>
                </a>
              </div>
            </div>
            <div name="professional-details-tuesday-week-time-row" id="professional-details-tuesday-week-time-row" class="week-time-row spacing-top">
              <div name="professional-details-tuesday-weekday-width" id="professional-details-tuesday-weekday-width" class="weekday-width">
                <mat-checkbox name="professional-details-tuesday" id="professional-details-tuesday" class="workinghoursCheckbox" formControlName="iTuesdayStatus" [checked]="professionalDetailsArray?.iTuesdayStatus">Tuesday</mat-checkbox>
              </div>
              <div name="professional-details-tuesday-working-hours" id="professional-details-tuesday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iTuesdayStatus == 0">
                <div name="professional-details-tuesday-hour-to" id="professional-details-tuesday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="professional-details-tuesday-working-hours" id="professional-details-tuesday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iTuesdayStatus == 1">
                <mat-form-field appearance="fill" name="professional-details-tuesday-vTuesdayStartHour" id="professional-details-tuesday-vTuesdayStartHour" class="hour-container">
                  <input type="text" matInput id="vTuesdayStartHour" formControlName="vTuesdayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                </mat-form-field>
                <mat-form-field appearance="fill" name="professional-details-tuesday-vTuesdayStartMinute" id="professional-details-tuesday-vTuesdayStartMinute" class="minute-container">
                  <input type="text" matInput id="vTuesdayStartMinute" formControlName="vTuesdayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="professional-details-tuesday-vTuesdayEndHour" id="professional-details-tuesday-vTuesdayEndHour" class="hour-container">
                  <input type="text" matInput id="vTuesdayEndHour" formControlName="vTuesdayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                </mat-form-field>
                <mat-form-field appearance="fill" name="professional-details-tuesday-vTuesdayEndMinute" id="professional-details-tuesday-vTuesdayEndMinute" class="minute-container">
                  <input type="text" matInput id="vTuesdayEndMinute" formControlName="vTuesdayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(professionalDetailsForm.controls['vTuesdayStartHour']?.value>=professionalDetailsForm.controls['vTuesdayEndHour']?.value) || (professionalDetailsForm.controls['vTuesdayStartHour'].invalid && (professionalDetailsForm.controls['vTuesdayStartHour'].errors!.pattern || professionalDetailsForm.controls['vTuesdayStartHour'].errors!.required)) || (professionalDetailsForm.controls['vTuesdayStartMinute'].invalid && (professionalDetailsForm.controls['vTuesdayStartMinute'].errors!.pattern || professionalDetailsForm.controls['vTuesdayStartMinute'].errors!.required)) || (professionalDetailsForm.controls['vTuesdayEndHour'].invalid && (professionalDetailsForm.controls['vTuesdayEndHour'].errors!.pattern || professionalDetailsForm.controls['vTuesdayEndHour'].errors!.required)) || (professionalDetailsForm.controls['vTuesdayEndMinute'].invalid && (professionalDetailsForm.controls['vTuesdayEndMinute'].errors!.pattern || professionalDetailsForm.controls['vTuesdayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
            <div name="professional-details-wednesday-week-time-row" id="professional-details-wednesday-week-time-row" class="week-time-row spacing-top">
              <div name="professional-details-wednesday-weekday-width" id="professional-details-wednesday-weekday-width" class="weekday-width">
                <mat-checkbox name="professional-details-wednesday" id="professional-details-wednesday" class="workinghoursCheckbox" formControlName="iWednesdayStatus" [checked]="professionalDetailsArray?.iWednesdayStatus">Wednesday</mat-checkbox>
              </div>
              <div name="professional-details-wednesday-working-hours" id="professional-details-wednesday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iWednesdayStatus == 0">
                <div name="professional-details-wednesday-hour-to" id="professional-details-wednesday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="professional-details-wednesday-working-hours" id="professional-details-wednesday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iWednesdayStatus == 1">
                <mat-form-field appearance="fill" name="professional-details-wednesday-vWednesdayStartHour" id="professional-details-wednesday-vWednesdayStartHour" class="hour-container">
                  <input type="text" matInput id="vWednesdayStartHour" formControlName="vWednesdayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                </mat-form-field>
                <mat-form-field appearance="fill" name="professional-details-wednesday-vWednesdayStartMinute" id="professional-details-wednesday-vWednesdayStartMinute" class="minute-container">
                  <input type="text" matInput id="vWednesdayStartMinute" formControlName="vWednesdayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="professional-details-wednesday-vWednesdayEndHour" id="professional-details-wednesday-vWednesdayEndHour" class="hour-container">
                  <input type="text" matInput id="vWednesdayEndHour" formControlName="vWednesdayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                </mat-form-field>
                <mat-form-field appearance="fill" name="professional-details-wednesday-vWednesdayEndMinute" id="professional-details-wednesday-vWednesdayEndMinute" class="minute-container">
                  <input type="text" matInput id="vWednesdayEndMinute" formControlName="vWednesdayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(professionalDetailsForm.controls['vWednesdayStartHour']?.value>=professionalDetailsForm.controls['vWednesdayEndHour']?.value) || (professionalDetailsForm.controls['vWednesdayStartHour'].invalid && (professionalDetailsForm.controls['vWednesdayStartHour'].errors!.pattern || professionalDetailsForm.controls['vWednesdayStartHour'].errors!.required)) || (professionalDetailsForm.controls['vWednesdayStartMinute'].invalid && (professionalDetailsForm.controls['vWednesdayStartMinute'].errors!.pattern || professionalDetailsForm.controls['vWednesdayStartMinute'].errors!.required)) || (professionalDetailsForm.controls['vWednesdayEndHour'].invalid && (professionalDetailsForm.controls['vWednesdayEndHour'].errors!.pattern || professionalDetailsForm.controls['vWednesdayEndHour'].errors!.required)) || (professionalDetailsForm.controls['vWednesdayEndMinute'].invalid && (professionalDetailsForm.controls['vWednesdayEndMinute'].errors!.pattern || professionalDetailsForm.controls['vWednesdayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
            <div name="professional-details-thrusday-week-time-row" id="professional-details-thrusday-week-time-row" class="week-time-row spacing-top">
              <div name="professional-details-thrusday-weekday-width" id="professional-details-thrusday-weekday-width" class="weekday-width">
                <mat-checkbox name="professional-details-thrusday" id="professional-details-thrusday" class="workinghoursCheckbox" formControlName="iThursdayStatus" [checked]="professionalDetailsArray?.iThursdayStatus">Thrusday</mat-checkbox>
              </div>
              <div name="professional-details-thrusday-working-hours" id="professional-details-thrusday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iThursdayStatus == 0">
                <div name="professional-details-thrusday-hour-to" id="professional-details-thrusday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="professional-details-thrusday-working-hours" id="professional-details-thrusday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iThursdayStatus == 1">
                <mat-form-field appearance="fill" name="professional-details-thrusday-vThrusdayStartHour" id="professional-details-thrusday-vThrusdayStartHour" class="hour-container">
                  <input type="text" matInput id="vThrusdayStartHour" formControlName="vThursdayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                </mat-form-field>
                <mat-form-field appearance="fill" name="professional-details-thrusday-vThrusdayStartMinute" id="professional-details-thrusday-vThrusdayStartMinute" class="minute-container">
                  <input type="text" matInput id="vThrusdayStartMinute" formControlName="vThursdayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="professional-details-thrusday-vThrusdayEndHour" id="professional-details-thrusday-vThrusdayEndHour" class="hour-container">
                  <input type="text" matInput id="vThrusdayEndHour" formControlName="vThursdayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                </mat-form-field>
                <mat-form-field appearance="fill" name="professional-details-thrusday-vThrusdayEndMinute" id="professional-details-thrusday-vThrusdayEndMinute"  class="minute-container">
                  <input type="text" matInput id="vThrusdayEndMinute" formControlName="vThursdayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(professionalDetailsForm.controls['vThursdayStartHour']?.value>=professionalDetailsForm.controls['vThursdayEndHour']?.value) || (professionalDetailsForm.controls['vThursdayStartHour'].invalid && (professionalDetailsForm.controls['vThursdayStartHour'].errors!.pattern || professionalDetailsForm.controls['vThursdayStartHour'].errors!.required)) || (professionalDetailsForm.controls['vThursdayStartMinute'].invalid && (professionalDetailsForm.controls['vThursdayStartMinute'].errors!.pattern || professionalDetailsForm.controls['vThursdayStartMinute'].errors!.required)) || (professionalDetailsForm.controls['vThursdayEndHour'].invalid && (professionalDetailsForm.controls['vThursdayEndHour'].errors!.pattern || professionalDetailsForm.controls['vThursdayEndHour'].errors!.required)) || (professionalDetailsForm.controls['vThursdayEndMinute'].invalid && (professionalDetailsForm.controls['vThursdayEndMinute'].errors!.pattern || professionalDetailsForm.controls['vThursdayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
            <div name="professional-details-friday-week-time-row" id="professional-details-friday-week-time-row" class="week-time-row spacing-top">
              <div name="professional-details-friday-weekday-width" id="professional-details-friday-weekday-width" class="weekday-width">
                <mat-checkbox name="professional-details-friday" id="professional-details-friday" class="workinghoursCheckbox" formControlName="iFridayStatus" [checked]="professionalDetailsArray?.iFridayStatus">Friday</mat-checkbox>
              </div>
              <div name="professional-details-friday-working-hours" id="professional-details-friday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iFridayStatus == 0">
                <div name="professional-details-friday-hour-to" id="professional-details-friday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="professional-details-friday-working-hours" id="professional-details-friday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iFridayStatus == 1">
                <mat-form-field appearance="fill" name="professional-details-friday-vFridayStartHour" id="professional-details-friday-vFridayStartHour" class="hour-container">
                  <input type="text" matInput id="vFridayStartHour" formControlName="vFridayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                </mat-form-field>
                <mat-form-field appearance="fill" name="professional-details-friday-vFridayStartMinute" id="professional-details-friday-vFridayStartMinute" class="minute-container">
                  <input type="text" matInput id="vFridayStartMinute"  formControlName="vFridayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="professional-details-friday-vFridayEndHour" id="professional-details-friday-vFridayEndHour" class="hour-container">
                  <input type="text" matInput id="vFridayEndHour" formControlName="vFridayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                </mat-form-field>
                <mat-form-field appearance="fill" name="professional-details-friday-vFridayEndMinute" id="professional-details-friday-vFridayEndMinute" class="minute-container">
                  <input type="text" matInput id="vFridayEndMinute" formControlName="vFridayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(professionalDetailsForm.controls['vFridayStartHour']?.value>=professionalDetailsForm.controls['vFridayEndHour']?.value) || (professionalDetailsForm.controls['vFridayStartHour'].invalid && (professionalDetailsForm.controls['vFridayStartHour'].errors!.pattern || professionalDetailsForm.controls['vFridayStartHour'].errors!.required)) || (professionalDetailsForm.controls['vFridayStartMinute'].invalid && (professionalDetailsForm.controls['vFridayStartMinute'].errors!.pattern || professionalDetailsForm.controls['vFridayStartMinute'].errors!.required)) || (professionalDetailsForm.controls['vFridayEndHour'].invalid && (professionalDetailsForm.controls['vFridayEndHour'].errors!.pattern || professionalDetailsForm.controls['vFridayEndHour'].errors!.required)) || (professionalDetailsForm.controls['vFridayEndMinute'].invalid && (professionalDetailsForm.controls['vFridayEndMinute'].errors!.pattern || professionalDetailsForm.controls['vFridayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
            <div name="professional-details-saturday-week-time-row" id="professional-details-saturday-week-time-row" class="week-time-row spacing-top">
              <div name="professional-details-saturday-weekday-width" id="professional-details-saturday-weekday-width" class="weekday-width">
                <mat-checkbox name="professional-details-saturday" id="professional-details-saturday" class="workinghoursCheckbox" formControlName="iSaturdayStatus" [checked]="professionalDetailsArray?.iSaturdayStatus">Saturday</mat-checkbox>
              </div>
              <div name="professional-details-saturday-working-hours" id="professional-details-saturday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iSaturdayStatus == 0">
                <div name="professional-details-saturday-hour-to" id="professional-details-saturday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="professional-details-saturday-working-hours" id="professional-details-saturday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iSaturdayStatus == 1">
                <mat-form-field appearance="fill" name="professional-details-saturday-vSaturdayStartHour" id="professional-details-saturday-vSaturdayStartHour" class="hour-container">
                  <input type="text" matInput id="vSaturdayStartHour" formControlName="vSaturdayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                </mat-form-field>
                <mat-form-field appearance="fill" name="professional-details-saturday-vSaturdayStartMinute" id="professional-details-saturday-vSaturdayStartMinute" class="minute-container">
                  <input type="text" matInput id="vSaturdayStartMinute" formControlName="vSaturdayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="professional-details-saturday-vSaturdayEndHour" id="professional-details-saturday-vSaturdayEndHour" class="hour-container">
                  <input type="text" matInput id="vSaturdayEndHour"  formControlName="vSaturdayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$" required>
                </mat-form-field>
                <mat-form-field appearance="fill" name="professional-details-saturday-vSaturdayEndMinute" id="professional-details-saturday-vSaturdayEndMinute" class="minute-container">
                  <input type="text" matInput id="vSaturdayEndMinute" formControlName="vSaturdayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$" required>
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(professionalDetailsForm.controls['vSaturdayStartHour']?.value>=professionalDetailsForm.controls['vSaturdayEndHour']?.value) || (professionalDetailsForm.controls['vSaturdayStartHour'].invalid && (professionalDetailsForm.controls['vSaturdayStartHour'].errors!.pattern || professionalDetailsForm.controls['vSaturdayStartHour'].errors!.required)) || (professionalDetailsForm.controls['vSaturdayStartMinute'].invalid && (professionalDetailsForm.controls['vSaturdayStartMinute'].errors!.pattern || professionalDetailsForm.controls['vSaturdayStartMinute'].errors!.required)) || (professionalDetailsForm.controls['vSaturdayEndHour'].invalid && (professionalDetailsForm.controls['vSaturdayEndHour'].errors!.pattern || professionalDetailsForm.controls['vSaturdayEndHour'].errors!.required)) || (professionalDetailsForm.controls['vSaturdayEndMinute'].invalid && (professionalDetailsForm.controls['vSaturdayEndMinute'].errors!.pattern || professionalDetailsForm.controls['vSaturdayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
            <div name="professional-details-sunday-week-time-row" id="professional-details-sunday-week-time-row" class="week-time-row spacing-top">
              <div name="professional-details-sunday-weekday-width" id="professional-details-sunday-weekday-width" class="weekday-width">
                <mat-checkbox name="professional-details-sunday" id="professional-details-sunday" class="workinghoursCheckbox" formControlName="iSundayStatus" [checked]="professionalDetailsArray?.iSundayStatus">Sunday</mat-checkbox>
              </div>
              <div name="professional-details-sunday-working-hours" id="professional-details-sunday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iSundayStatus == 0">
                <div name="professional-details-sunday-hour-to" id="professional-details-sunday-hour-to" class="hour-to p-0">Not working on this day</div>
              </div>
              <div name="additional-details-sunday-working-hours" id="additional-details-sunday-working-hours" class="d-flex working-hours" *ngIf="professionalDetailsForm?.value.iSundayStatus == 1">
                <mat-form-field appearance="fill" name="additional-details-sunday-vSundayStartHour" id="additional-details-sunday-vSundayStartHour" class="hour-container">
                  <input type="text" matInput id="vSundayStartHour" formControlName="vSundayStartHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-sunday-vSundayStartMinute" id="additional-details-sunday-vSundayStartMinute" class="minute-container">
                  <input type="text" matInput id="vSundayStartMinute" formControlName="vSundayStartMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="hour-to">to</div>
                <mat-form-field appearance="fill" name="additional-details-sunday-vSundayEndHour" id="additional-details-sunday-vSundayEndHour" class="hour-container">
                  <input type="text" matInput id="vSundayEndHour" formControlName="vSundayEndHour" placeholder="09" maxlength="2" pattern="^([01][0-9]|2[0-3])$">
                </mat-form-field>
                <mat-form-field appearance="fill" name="additional-details-sunday-vSundayEndMinute" id="additional-details-sunday-vSundayEndMinute" class="minute-container">
                  <input type="text" matInput id="vSundayEndMinute" formControlName="vSundayEndMinute" placeholder="30" maxlength="2" pattern="^[0-5][0-9]$">
                </mat-form-field>
                <div class="error-box">
                  <mat-error class="reduce-bottom-space" *ngIf="(professionalDetailsForm.controls['vSundayStartHour']?.value>=professionalDetailsForm.controls['vSundayEndHour']?.value) || (professionalDetailsForm.controls['vSundayStartHour'].invalid && (professionalDetailsForm.controls['vSundayStartHour'].errors!.pattern || professionalDetailsForm.controls['vSundayStartHour'].errors!.required)) || (professionalDetailsForm.controls['vSundayStartMinute'].invalid && (professionalDetailsForm.controls['vSundayStartMinute'].errors!.pattern || professionalDetailsForm.controls['vSundayStartMinute'].errors!.required)) || (professionalDetailsForm.controls['vSundayEndHour'].invalid && (professionalDetailsForm.controls['vSundayEndHour'].errors!.pattern || professionalDetailsForm.controls['vSundayEndHour'].errors!.required)) || (professionalDetailsForm.controls['vSundayEndMinute'].invalid && (professionalDetailsForm.controls['vSundayEndMinute'].errors!.pattern || professionalDetailsForm.controls['vSundayEndMinute'].errors!.required))">
                    Please Enter Valid Time
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div name="professional-details-modal-footer" id="professional-details-modal-footer" class="modal-footer">
      <button type="button" name="professional-details-modal-footer-btn-cancel" id="professional-details-modal-footer-btn-cancel" class="blue-secondary-small-btn mx-2" (click)="this.professionalDetailsToggle=false; GetProfessionalDetails()">Cancel</button>
      <button type="submit" name="professional-details-modal-footer-btn-submit" id="professional-details-modal-footer-btn-submit" class="blue-primary-small-btn" form="professionalDetailsForm">Save</button>
    </div>
  </div>
</div>
</div>

