<button id="menu" (click)="navService.openNav()"
  class="d-flex justify-content-center align-items-center flex-grow-0 ms-4">
  <mat-icon>menu</mat-icon>
</button>
<div class="d-flex justify-content-end align-items-center w-100" *ngIf="json_user_data.login_status">
  <span class="body-text-2 fw-semibold">
    {{json_user_data.vFirstName}} {{json_user_data.vLastName}}
  </span>
  <button mat-button [matMenuTriggerFor]="beforeMenu">
    <img class="header-profile-img" src="assets/images/profile.svg" alt="Profile">
  </button>
  <mat-menu #beforeMenu="matMenu" xPosition="before" class="header-matmenu header-matmenu_box">
    <button mat-menu-item routerLink="/user-profile" *ngIf="roleId && userType">
      <span class="material-icons">person</span>
      Profile
    </button>
    <button mat-menu-item routerLink="/profile" *ngIf="!roleId && !userType">
      <span class="material-icons">person</span>
      Profile
    </button>
    <button mat-menu-item routerLink="/change-password">
      <span class="material-icons">https</span>
      Change Password
    </button>
    <button mat-menu-item (click)="Logout()">
      <span class="material-icons">login</span>
      Logout
    </button>
  </mat-menu>
</div>
<div class="d-flex justify-content-end align-items-center w-100" *ngIf="json_sub_user_data.login_status">
  <span class="body-text-2 fw-semibold">
    {{json_sub_user_data.vFirstName}} {{json_sub_user_data.vLastName}}
  </span>
  <button mat-button [matMenuTriggerFor]="beforeMenu">
    <img *ngIf="json_sub_user_data.vSubUserProfilePhoto == ''" class="header-profile-img" src="assets/images/profile.svg"
    alt="Profile">
    <img *ngIf="json_sub_user_data.vSubUserProfilePhoto && json_sub_user_data.vSubUserProfilePhoto != ''"
    class="header-profile-img" [src]="json_sub_user_data.vSubUserProfilePhoto" alt="Profile">
  </button>
  <mat-menu #beforeMenu="matMenu" xPosition="before" class="header-matmenu">
    <button mat-menu-item routerLink="/user-profile" *ngIf="roleId && userType">
      <span class="material-icons">person</span>
      Profile
    </button>
    <button mat-menu-item routerLink="/profile" *ngIf="!roleId && !userType">
      <span class="material-icons">person</span>
      Profile
    </button>
    <button mat-menu-item routerLink="/change-password">
      <span class="material-icons">https</span>
      Change Password
    </button>
    <button mat-menu-item (click)="Logout()">
      <span class="material-icons">login</span>
      Logout
    </button>
  </mat-menu>
</div>