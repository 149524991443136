import { Component, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit, OnInit } from "@angular/core";
import { VERSION } from "@angular/material/core";
import { Router } from "@angular/router";
import { NavItem } from "./../nav-item";
import { NavService } from "./../nav.service";
import { EventEmitterService } from "./../../../config/event-emitter.service";
import { Permission } from "./../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../utils/services/user-permission.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-container",
  templateUrl: "./container.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ContainerComponent implements AfterViewInit, OnInit {
  @ViewChild("appDrawer") appDrawer?: ElementRef;
  version = VERSION;

  public disabledPublishDefaultProfile: any = false;
  public completePublishProfile: any = false;

  navItems: NavItem[] = [];

  constructor(private navService: NavService, private router: Router, public eventEmitterService: EventEmitterService, private userPermissionService: UserPermissionService, private cookieService: CookieService) {
    setTimeout(() => {
      this.AddNavItems();
    }, 500);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  AddNavItems() {
    // assign individual permissions
    const AccessDashboard: Permission = Permission.AccessDashboard;
    const AccessOnboard: Permission = Permission.AccessOnboard;
    const AccessPublish: Permission = Permission.AccessPublish;
    const AccessPartnerUserManagement: Permission = Permission.AccessPartnerUserManagement;
    const AccessEmployeeUserManagement: Permission = Permission.AccessEmployeeUserManagement;
    const AccessClientUserManagement: Permission = Permission.AccessClientUserManagement;
    const AccessTitleAndSkill: Permission = Permission.AccessTitleAndSkill;
    const AccessTechnology: Permission = Permission.AccessTechnology;
    const AccessReasons: Permission = Permission.AccessReasons;
    const AccessContact: Permission = Permission.AccessContact;
    const AccessIndustry: Permission = Permission.AccessIndustry;
    const AccessService: Permission = Permission.AccessService;
    const AccessGrowth: Permission = Permission.AccessGrowth;
    const AccessLanguage: Permission = Permission.AccessLanguage;
    const AccessHobbie: Permission = Permission.AccessHobbie;
    const AccessDegree: Permission = Permission.AccessDegree;
    const AccessBranch: Permission = Permission.AccessBranch;
    const AccessAward: Permission = Permission.AccessAward;
    const AccessCertificate: Permission = Permission.AccessCertificate;
    const AccessDealEngine: Permission = Permission.AccessDealEngine;
    const AccessBilling: Permission = Permission.AccessBilling;
    const AccessContracts: Permission = Permission.AccessContracts;
    const AccessInterviews: Permission = Permission.AccessInterviews;
    const AccessRequirements: Permission = Permission.AccessRequirements;
    const AccessRole: Permission = Permission.AccessRole;
    const AccessPermissionManagement: Permission = Permission.AccessPermissionManagement;
    const AccessRoleManagement: Permission = Permission.AccessRoleManagement;

    let roleId = localStorage.getItem("sub_admin_role");
    let userType = localStorage.getItem("sub_admin_account_type");

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AccessDashboard)) {
      this.navItems.push({
        displayName: "Dashboard",
        iconName: "dashboard.svg",
        route: "dashboard",
        activeIconName: "dashboard-active.svg",
        className: "",
      });
    }

    if (this.userPermissionService.isAuthorizedFor(AccessOnboard)) {
      this.navItems.push({
        displayName: "Onboard",
        iconName: "onboard.svg",
        activeIconName: "onboard-active.svg",
        className: "",
        route: "onboard",
        children: [],
      });

      // let navItemIndex = this.navItems.findIndex((obj: any) => obj.displayName == "Onboard");

      // if (this.userPermissionService.isAuthorizedFor(AccessPartnerOnboard)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Partner Onboard",
      //     iconName: "description.svg",
      //     route: "partner-onboard",
      //     activeIconName: "description-active.svg",
      //     className: "",
      //   });
      // }
      // if (this.userPermissionService.isAuthorizedFor(AccessClientOnboard)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Client Onboard",
      //     iconName: "description.svg",
      //     route: "client-onboard",
      //     activeIconName: "description-active.svg",
      //     className: "",
      //   });
      // }
    }

    if (this.userPermissionService.isAuthorizedFor(AccessPublish)) {
      this.navItems.push({
        displayName: "Publish",
        iconName: "publish.svg",
        activeIconName: "publish-active.svg",
        className: "",
        route: "publish",
        children: [],
      });

    }

    if (this.userPermissionService.isAuthorizedFor(AccessRequirements)) {
      this.navItems.push({
        displayName: "Requirements",
        iconName: "requirement.svg",
        route: "requirement",
        activeIconName: "requirement-active.svg",
        className: "",
      });
    }

    this.navItems.push({
      displayName: "Activities",
      iconName: "audit-trail.svg",
      activeIconName: "audit-trail-active.svg",
      route: "activity-tracking",
      className: "",
     })

    
    if (this.userPermissionService.isAuthorizedFor(AccessBilling)) {
      this.navItems.push({
        displayName: "Billing",
        iconName: "billing.svg",
        route: "billing",
        activeIconName: "billing-active.svg",
        className: "",
      });
    }

    if (this.userPermissionService.isAuthorizedFor(AccessContracts)) {
      this.navItems.push({
        displayName: "Contracts",
        iconName: "description.svg",
        route: "contract",
        activeIconName: "description-active.svg",
        className: "",
      });
    }

    if(this.userPermissionService.isAuthorizedFor(AccessContracts)){
      this.navItems.push({
        displayName: "Agreements",
        iconName: "description.svg",
        activeIconName: "description-active.svg",
        route: "contract-sandbox",
        className: "",
      });
    }

    if (this.userPermissionService.isAuthorizedFor(AccessInterviews)) {
      this.navItems.push({
        displayName: "Interviews",
        iconName: "interviews.svg",
        route: "interview",
        activeIconName: "interviews-active.svg",
        className: "",
      });
    }

    if (this.userPermissionService.isAuthorizedFor(AccessDealEngine)) {
      this.navItems.push({
        displayName: "Deal Engine",
        iconName: "deal-engine.svg",
        route: "dealengine",
        activeIconName: "deal-engine-active.svg",
        className: "",
      });
    }


    if (this.userPermissionService.isAuthorizedFor(AccessClientUserManagement)) {
      this.navItems.push({
        displayName: "Clients",
        iconName: "client-admin.svg",
        activeIconName: "client-admin-active.svg",
        route: "client",
        className: "",
      });
    }

    if (this.userPermissionService.isAuthorizedFor(AccessPartnerUserManagement)) {
      this.navItems.push({
        displayName: "Partners",
        iconName: "partner-admin.svg",
        activeIconName: "partner-admin-active.svg",
        route: "partner",
        className: "",
      });
    }

    if (this.userPermissionService.isAuthorizedFor(AccessEmployeeUserManagement)) {
      this.navItems.push({
        displayName: "Talent",
        iconName: "talent.svg",
        activeIconName: "talent-active.svg",
        route: "talent",
        className: "",
      });
    }
    
    


    if (
      this.userPermissionService.isAuthorizedFor(AccessTitleAndSkill) ||
      this.userPermissionService.isAuthorizedFor(AccessTechnology) ||
      this.userPermissionService.isAuthorizedFor(AccessReasons) ||
      this.userPermissionService.isAuthorizedFor(AccessContact) ||
      this.userPermissionService.isAuthorizedFor(AccessIndustry) ||
      this.userPermissionService.isAuthorizedFor(AccessService) ||
      this.userPermissionService.isAuthorizedFor(AccessGrowth)
    ) {
      this.navItems.push({
        displayName: "General",
        iconName: "general.svg",
        activeIconName: "general-active.svg",
        className: "",
        children: [],
      });

      let navItemIndex = this.navItems.findIndex((obj: any) => obj.displayName == "General");

      if (this.userPermissionService.isAuthorizedFor(AccessTitleAndSkill)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Title & Skill",
          iconName: "",
          activeIconName: "",
          route: "skill",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessTechnology)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Technology",
          iconName: "",
          activeIconName: "",
          route: "technology",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessReasons)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Reasons",
          iconName: "",
          activeIconName: "",
          route: "reasons",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessContact)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Contact",
          iconName: "",
          activeIconName: "",
          route: "contact",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessIndustry)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Industry",
          iconName: "",
          activeIconName: "",
          route: "industry",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessService)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Service",
          iconName: "",
          activeIconName: "",
          route: "service",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessGrowth)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Growth",
          iconName: "",
          activeIconName: "",
          route: "pms-growth",
          className: "",
        });
      }
    }

    if (
      this.userPermissionService.isAuthorizedFor(AccessLanguage) ||
      this.userPermissionService.isAuthorizedFor(AccessHobbie) ||
      this.userPermissionService.isAuthorizedFor(AccessDegree) ||
      this.userPermissionService.isAuthorizedFor(AccessBranch) ||
      this.userPermissionService.isAuthorizedFor(AccessAward) ||
      this.userPermissionService.isAuthorizedFor(AccessCertificate)
    ) {
      this.navItems.push({
        displayName: "Talent Details",
        iconName: "help-desk.svg",
        activeIconName: "help-desk-active.svg",
        className: "",
        children: [],
      });

      let navItemIndex = this.navItems.findIndex((obj: any) => obj.displayName == "Talent Details");

      if (this.userPermissionService.isAuthorizedFor(AccessLanguage)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Language",
          iconName: "",
          activeIconName: "",
          route: "emp-languages",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessHobbie)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Hobbie",
          iconName: "",
          activeIconName: "",
          route: "emp-hobbie",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessDegree)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Degree",
          iconName: "",
          activeIconName: "",
          route: "emp-degree",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessBranch)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Branch",
          iconName: "",
          activeIconName: "",
          route: "emp-branch",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessAward)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Award",
          iconName: "",
          activeIconName: "",
          route: "emp-award",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessCertificate)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Certificate",
          iconName: "",
          activeIconName: "",
          route: "emp-certificate",
          className: "",
        });
      }
    }

   
    if (
      this.userPermissionService.isAuthorizedFor(AccessRole) ||
      this.userPermissionService.isAuthorizedFor(AccessPermissionManagement) ||
      this.userPermissionService.isAuthorizedFor(AccessRoleManagement)
    ) {
      this.navItems.push({
        displayName: "Access Management",
        iconName: "rolemanagement.svg",
        activeIconName: "rolemanagement-active.svg",
        className: "",
        children: [],
      });

      let navItemIndex = this.navItems.findIndex((obj: any) => obj.displayName == "Access Management");

      if (this.userPermissionService.isAuthorizedFor(AccessRole)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Role",
          iconName: "",
          activeIconName: "",
          route: "role",
          className: "",
        });
      }
      // if (this.userPermissionService.isAuthorizedFor(AccessParentPermission)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Parent Permission",
      //     iconName: "rolemanagement.svg",
      //   activeIconName: "rolemanagement-active.svg",
      //     route: "parent-permission",
      //     className: "",
      //   });
      // }
      // if (this.userPermissionService.isAuthorizedFor(AccessChildPermission)) {
      //   this.navItems[navItemIndex].children?.push({
      //     displayName: "Child Permission",
      //     iconName: "rolemanagement.svg",
      //     activeIconName: "rolemanagement-active.svg",
      //     route: "child-permission",
      //     className: "",
      //   });
      // }
      if (this.userPermissionService.isAuthorizedFor(AccessPermissionManagement)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Permission Management",
          iconName: "",
          activeIconName: "",
          route: "permission-management",
          className: "",
        });
      }
      if (this.userPermissionService.isAuthorizedFor(AccessRoleManagement)) {
        this.navItems[navItemIndex].children?.push({
          displayName: "Role Management",
          iconName: "",
          activeIconName: "",
          route: "role-management",
          className: "",
        });
      }
    }

    if (roleId && userType) {
      this.navItems.push({
        displayName: "Profile",
        iconName: "account-circle.svg",
        activeIconName: "account-circle-active.svg",
        route: "/user-profile",
        disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
        completePublishProfile: this.completePublishProfile,
        className: "",
      });
    }
  }

  Logout() {
    let device_id = localStorage.getItem("admin_device_id");
    this.navService.logoutAPI({ vImei: device_id }).subscribe((res: any) => {
      this.navService.Logout();
      this.userPermissionService.ResetPermissions();
      localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_type");
        localStorage.removeItem("admin_user_email");
        localStorage.removeItem("sub_admin_account_type");
        localStorage.removeItem("sub_admin_email");
        localStorage.removeItem("sub_admin_role");
        localStorage.removeItem("admin_zendesk_token");
        localStorage.removeItem("admin_device_id");
      this.cookieService.delete("admin_user");
      this.router.navigate(["/login"]);
    });
  }
}
