import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import {Ng7DynamicBreadcrumbModule} from "ng7-dynamic-breadcrumb";
import { NgApexchartsModule } from "ng-apexcharts";
import {MatChipsModule} from '@angular/material/chips';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule
} from 'ngx-ui-loader';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "fgsSize": 30,
  "pbColor": "#5EC090",
  "fgsColor": "#5EC090",
  "overlayColor": "rgba(0, 0, 0, 0.1)",
  "hasProgressBar": false,
};

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { AppRoutingModule } from './app-routing.module';
import { AccessGuard } from './modules/login/authentication.service';
import { AppComponent } from './app.component';
import { MyInterceptor } from './default.request.header';
import { LoginComponent } from './modules/login/login.component';
import { DefaultlayoutComponent } from './modules/defaultLayout/defaultLayout.component';
// import { TopNavComponent } from './modules/topNav/topNav.component';
// import { NavService } from './modules/defaultLayout/nav.service';
import { TicketComponent } from './modules/ticket/ticket.component';

import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { ProfileComponent } from './modules/profile/profile.component';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { DisCertificatePipe } from './config/dis_pipe_certificate.pipe';
import { ErrorModalComponent } from './config/error-modal/error-modal.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { SkillComponent } from './modules/skill/skill.component';
import { TechnologyComponent } from './modules/technology/technology.component';
import { RapidiseContactComponent } from "./modules/rapidiseContact/rapidiseContact.component"

// Publish - Start
import { PartnerPublishComponent } from './modules/publish/publish-partner/partner-publish.component';
import { EmployeePublishComponent } from "./modules/publish/publish-employee/employee-publish.component"
import { ClientPublishComponent } from "./modules/publish/publish-client/client-publish.component";
// Publish - End

// Onboard - Start
import {OnboardPartnerComponent} from './modules/onboard/onboard-partner/onboard-partner.component';
import {OnboardClientComponent} from './modules/onboard/onboard-client/onboard-client.component';
// Onboard - End

// Partner - Start
import {IndustryComponent} from './modules/industry/industry.component';
import {ServiceComponent} from './modules/service/service.component';
// Partner - End

// Employee Component - Start
import {EmpLanguagesComponent} from "./modules/employee/user-employee/emp-language/emp-language.component";
import {EmpHobbieComponent} from "./modules/employee/user-employee/emp-hobbie/emp-hobbie.component";
import {EmpDegreeComponent} from "./modules/employee/user-employee/emp-degree/emp-degree.component";
import {EmpBranchComponent} from "./modules/employee/user-employee/emp-branch/emp-branch.component";
import {EmpAwardComponent} from "./modules/employee/user-employee/emp-award/emp-award.component";
import {EmpCertificateComponent} from "./modules/employee/user-employee/emp-certificate/emp-certificate.component";
import {EmpInterviewStatusComponent} from "./modules/employee/user-employee/emp-interview-status/emp-interview-status.component";
// Employee Component - End

//User Management - Start
import {UserPartnerComponent} from "./modules/user-management/user-partner/user-partner.component";
import {UserEmployeeComponent} from "./modules/user-management/user-employee/user-employee.component";
import {UserClientComponent} from "./modules/user-management/user-client/user-client.component";
//User Management - End

// Dashboard
import { HeaderComponent } from './modules/layouts/header/header.component';
// import { FooterComponent } from './modules/layouts/footer/footer.component';
// import { MenuListItemComponent } from './modules/layouts/menu-list-item/menu-list-item.component';

import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { DatatableComponent } from './modules/dashboard/datatable/datatable.component';
import { DealEngineComponent } from './modules/deal-engine/deal-engine.component';
import { MaterialModule } from './material.module';
import { DesignSystemComponent } from './design-system/design-system.component';

//Deal Engine


import { ReasonsComponent } from './modules/reasons/reasons.component';
import { ContractComponent } from './modules/contract/contract.component';
import { InterviewComponent } from './modules/interview/interview.component';
import { RequirementComponent } from './modules/requirement/requirement.component';
import { BillingComponent } from './modules/billing/billing.component';
import { RoleComponent } from './modules/role/role.component';
import { ChildPermissionManagementComponent } from './modules/child-permission-management/child-permission-management.component';
import { ParentPermissionManagementComponent } from './modules/parent-permission-management/parent-permission-management.component';
import { SubUserComponent } from './modules/sub-user/sub-user.component';
import { PermissionManagementComponent } from './modules/permission-management/permission-management.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { ContainerComponent } from './admin/layouts/container/container.component';
import { TopNavComponent } from './admin/layouts/top-nav/top-nav.component';
import { NavService } from './admin/layouts/nav.service';
import { FooterComponent } from './admin/layouts/footer/footer.component';
import { MenuListItemComponent } from './admin/layouts/menu-list-item/menu-list-item.component';
import { OnboardComponent } from './admin/onboard/onboard.component';

import { ViewClientComponent } from './admin/client/view-client/view-client.component';
import { ClientCompanyDocumentsComponent } from "./admin/client/view-client/company-documents/company-documents.component";
import { ClientPrimaryDetailsComponent } from "./admin/client/view-client/primary-details/primary-details.component";
import { ClientCompanyDetailsComponent } from "./admin/client/view-client/company-details/company-details.component";
import { ClientExecutiveDetailsComponent } from "./admin/client/view-client/executive-details/executive-details.component";
import { ClientCertificationsComponent } from "./admin/client/view-client/certifications/certifications.component";

import { ViewPartnerComponent } from './admin/partner/view-partner/view-partner.component';
import { PartnerCompanyDocumentsComponent } from "./admin/partner/view-partner/company-documents/company-documents.component";
import { PartnerPrimaryDetailsComponent } from "./admin/partner/view-partner/primary-details/primary-details.component";
import { PartnerCompanyDetailsComponent } from "./admin/partner/view-partner/company-details/company-details.component";
import { PartnerExecutiveDetailsComponent } from "./admin/partner/view-partner/executive-details/executive-details.component";
import { PartnerCertificationsComponent } from "./admin/partner/view-partner/certifications/certifications.component";
import { PartnerAdditionalDetailsComponent } from "./admin/partner/view-partner/additional-details/additional-details.component";

import { ViewTalentComponent } from './admin/talent/view-talent/view-talent.component';
import { TalentPersonalDetailsComponent } from "./admin/talent/view-talent/personal-details/personal-details.component";
import { TalentProjectPortfolioComponent } from "./admin/talent/view-talent/project-portfolio/project-portfolio.component";
import { TalentProfessionalDetailsComponent } from "./admin/talent/view-talent/professional-details/professional-details.component";
import { TalentDocumentsComponent } from "./admin/talent/view-talent/documents/documents.component";
import { TalentEducationComponent } from "./admin/talent/view-talent/education/education.component";
import { TalentCertificationsComponent } from "./admin/talent/view-talent/certifications/certifications.component";
import { TalentAwardsComponent } from "./admin/talent/view-talent/awards/awards.component";


import { PublishComponent } from './admin/publish/publish.component';
import { ClientComponent } from './admin/client/client.component';
import { PartnerComponent } from './admin/partner/partner.component';
import { TalentComponent } from './admin/talent/talent.component';
import { RequirementsComponent } from './admin/requirements/requirements.component';
import { ContractsComponent } from './admin/contracts/contracts.component';
import { InterviewsComponent } from './admin/interviews/interviews.component';
import { ChartsModule } from 'ng2-charts';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ImageCropperModule } from "ngx-image-cropper";
import { RequirementDetailsComponent } from './admin/requirements/requirement-details/requirement-details.component';
import { ChangePasswordComponent } from './admin/change-password/change-password.component';
import { environment } from 'src/environments/environment';
import { PmsGrowthComponent } from './admin/pms-growth/pms-growth.component';

// Sentry.init({
//   dsn: environment.sentry.dsn,
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ["https://admin.beta.rapidise.io/login"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 0.75,
// });

@NgModule({
  declarations: [
    DisCertificatePipe,
    AppComponent,
    DefaultlayoutComponent,
    LoginComponent,
    TicketComponent,
    ChangePasswordComponent,
    ProfileComponent,
    ErrorModalComponent,
    DashboardComponent,
    DesignSystemComponent,
    //Onboard Component - Start
    OnboardPartnerComponent,
    OnboardClientComponent,
    //Onboard Component - End
    PartnerPublishComponent,
    SkillComponent,
    TechnologyComponent,
    EmpLanguagesComponent,
    EmployeePublishComponent,
    RapidiseContactComponent,
    ClientPublishComponent,
    ClientCompanyDocumentsComponent,
    ClientPrimaryDetailsComponent,
    ClientCompanyDetailsComponent,
    ClientExecutiveDetailsComponent,
    ClientCertificationsComponent,
    // Partner Component - Start
    IndustryComponent,
    ServiceComponent,
    // Partner Component - End
    // Employee Component - Start
    EmpHobbieComponent,
    EmpDegreeComponent,
    EmpBranchComponent,
    EmpAwardComponent,
    HeaderComponent,
    EmpCertificateComponent,
    EmpInterviewStatusComponent,
    // Employee Component - End
    //User Management - Start
    UserPartnerComponent,
    UserEmployeeComponent,
    UserClientComponent,
    DatatableComponent,
    DealEngineComponent,
    ReasonsComponent,
    ContractComponent,
    InterviewComponent,
    RequirementComponent,
    BillingComponent,
    RoleComponent,
    ChildPermissionManagementComponent,
    ParentPermissionManagementComponent,
    SubUserComponent,
    PermissionManagementComponent,
    //User Management - End
    ContainerComponent,
    TopNavComponent,
    MenuListItemComponent,
    FooterComponent,
    OnboardComponent,
    ViewClientComponent,
    ViewPartnerComponent,
    ViewTalentComponent,
    PublishComponent,
    ClientComponent,
    PartnerComponent,
    TalentComponent,
    RequirementsComponent,
    ContractsComponent,
    InterviewsComponent,
    ClientCompanyDocumentsComponent,
    ClientPrimaryDetailsComponent,
    ClientCompanyDetailsComponent,
    ClientExecutiveDetailsComponent,
    ClientCertificationsComponent,
    PartnerCompanyDocumentsComponent,
    PartnerPrimaryDetailsComponent,
    PartnerCompanyDetailsComponent,
    PartnerExecutiveDetailsComponent,
    PartnerCertificationsComponent,
    PartnerAdditionalDetailsComponent,
    TalentPersonalDetailsComponent,
    TalentProjectPortfolioComponent,
    TalentProfessionalDetailsComponent,
    TalentDocumentsComponent,
    TalentEducationComponent,
    TalentCertificationsComponent,
    TalentAwardsComponent,
    RequirementDetailsComponent,
    PmsGrowthComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ImageCropperModule,
    HttpClientModule,
    CommonModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    PaginationModule.forRoot(),
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    NgxPaginationModule,
    MatCheckboxModule,
    MatTableModule,MatPaginatorModule,MatSortModule,
    MatMenuModule,
    MatCardModule,
    MatChipsModule,
    NgApexchartsModule,
    MaterialModule,
    NgxIntlTelInputModule,
    Ng7DynamicBreadcrumbModule,
    PerfectScrollbarModule,
    NgxSmartModalModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule, // import this module for showing loader automatically when navigating between app routes
    NgxUiLoaderHttpModule.forRoot({showForeground:true}),
    MatDatepickerModule,NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule , NgbModule,
    ChartsModule,
    RecaptchaV3Module
  ],
  exports: [MatCardModule],
  providers: [
    AccessGuard,
    NavService,
    {provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true},
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.api_key,
    },
    PaginationConfig
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
