import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { UserPermissionService } from "./user-permission.service";
import { Permission } from "./../enums/permission.enum";
import { NotificationService } from "./../../config/notification.service";

@Injectable({
  providedIn: "root",
})
export class PermissionGuard implements CanActivate {
  constructor(private userPermissionService: UserPermissionService, private notifyService: NotificationService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const permission: Permission = route.data ? route.data.permission : null;
    if (!permission) {
      return true;
    }

    if (this.userPermissionService.isAuthorizedFor(permission)) {
      return true;
    }

    this.notifyService.showError("Not Authorized!", "");
    this.router.navigate(['/dashboard']);
    return false;
  }
}
