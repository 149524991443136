<div class="container-fluid skill-management">
  <div class="row">
    <div class="col-12">
      <div class="wrapper">
        <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="title"
            role="tabpanel"
            aria-labelledby="skill-management-tab"
          >
            <div class="pagetitle-wrapper">
              <div
                class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row"
              >
                <div class="d-flex align-items-center">
                  <i class="pagetitle-icon"
                    ><span class="material-icons">ballot</span></i
                  >
                  <h2 class="pagetitle">Requirements</h2>
                </div>
              </div>
              <div class="d-flex">
                <mat-form-field
                  class="w-75"
                  floatLabel="never"
                  class="table-filter"
                >
                  <button matSuffix mat-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <input
                    matInput
                    placeholder="Search Requirement"
                    (keyup)="applyFilterOne($event)"
                  />
                </mat-form-field>
              </div>
              <div class="table-responsive">
                <div
                  *ngIf="dataSourceTableOne?.filteredData?.length == 0"
                  class="emptyRecordDiv"
                >
                  No Records Found
                </div>
                <table
                  mat-table
                  [ngClass]="{
                    'dm-mat-hide-table':
                      dataSourceTableOne?.filteredData?.length <= 0
                  }"
                  [dataSource]="dataSourceTableOne"
                  matSort
                  #TableOneSort="matSort"
                  class="datatable"
                >
                  <ng-container matColumnDef="indexnumber">
                    <th mat-header-cell *matHeaderCellDef>No</th>
                    <td mat-cell *matCellDef="let i = index">
                      {{
                        this.TableOnePaginator.pageIndex == 0
                          ? i + 1
                          : i +
                            1 +
                            this.TableOnePaginator.pageIndex *
                              this.TableOnePaginator.pageSize
                      }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vReqName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Requirement Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{ element.vReqName }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vClientName">
                    <th mat-header-cell *matHeaderCellDef>Client Name</th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{ element.vClientName }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex flex-wrap">
                        <button
                          class="status-btn lightblue-bg blue-text text-center mb-2" placement="left auto" [ngbTooltip]="viewRequirementActionPermission ? 'View Requirement':'Not Authorized'" [ngClass]="viewRequirementActionPermission ? '':'unauthorized-cursor'"
                          (click)="
                            OpenModelViewRequirement(viewRequirement, element, viewRequirementActionPermission)
                          "
                        >
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <!-- <button class="status-btn lightpink-bg pink-text text-center mb-2" (click)="DeleteRequirement(element)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete">
                            <mat-icon>delete</mat-icon>
                          </button> -->
                      </div>
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumnsTableOne; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumnsTableOne"
                  ></tr>
                </table>
              </div>
              <mat-paginator
                [ngClass]="{
                  'dm-mat-hide-paginator':
                    dataSourceTableOne?.filteredData?.length <= 0
                }"
                #TableOnePaginator="matPaginator"
                [pageSizeOptions]="[10, 25, 50]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #viewRequirement let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-edit add-skill-modal">
    <button class="modal-close-btn" (click)="d('Cross click');this.filterType='All'">
      <mat-icon>close</mat-icon>
    </button>
    <div class="content">
      <div class="w-100">
        <h1 class="title">{{ ParentReqName }}</h1>
        <h2 class="pagetitle">List of added talents</h2>
        <div class="my-3">
          <mat-form-field appearance="outline">
            <mat-select [(value)]="filterType" placeholder="Select Skill" (selectionChange)="applyFilterTwo()">
              <mat-option value="All">All</mat-option>
              <mat-option
                *ngFor="let skill of vPrimarySkillArray"
                [value]="skill.iPrimarySkillId"
              >
                {{ skill.vPrimarySkill }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngFor="let item of RequirementTalents" class="outer-box">
        <label class="fw-bold">Designation : {{ item.vPrimarySkill }}</label>
        <label class="fw-bold" *ngFor="let obj of experience"
          ><span *ngIf="obj.value == item.iExperienceLevel"
            >Experience : {{ obj.viewValue }}</span
          ></label
        >
        <label class="fw-bold">Required Resources : {{ item.iTalentNumber }}</label>
        <div *ngIf="item.RequirementTeam.length == 0">
          <span class="label">No talents found in team</span>
        </div>
        <div *ngFor="let element of item.RequirementTeam" class="inner-box">
          <div class="label">
            <span class="fw-bold">Name : </span>{{ element.vFirstName }}
            {{ element.vLastName }} ({{ element.vDisplayUniqueId }})
          </div>
          <div class="label">
            <span class="fw-bold">Designation : </span
            >{{ element.iEmpDesignation }}
            <span class="experience" *ngIf="element.fEmpExperience >= 1">
              ({{ element.fEmpExperience.toString().split(".")[0] }}+)
            </span>
            <span class="experience" *ngIf="element.fEmpExperience < 1">
              ({{ element.fEmpExperience * 10 | number: "1.1-2" }}+)
            </span>
          </div>
          <div class="label">
            <span class="fw-bold">Price : </span>${{
              element.dEmployeePrice
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
