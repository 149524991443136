import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../environments/environment';
import { TranslateFormService } from "./utils/services/translate-form.service";

@Injectable()
export class MyInterceptor implements HttpInterceptor {

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private tf:TranslateFormService
  ) { }

  public message: any;
  public nonce: any = '35A90B73C39D97';
  public path: any;
  public privateKey: any = "4AF8AC8E124B6484BDDC5A9EB3D49";
  public secretKey: any = "PXGuKjNtjzLiyLRE0GzykvGcaZO5uWE0";
  public timestamp: any = 1615971782;
  public accounttype!:any;

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     //Generate Nonce
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    this.nonce = '';
    
    for (let i = 0; i < 8; i++) {
        const rnum = Math.floor(Math.random() * chars.length);
        this.nonce += chars.substring(rnum, rnum + 1);
    }

    //Generate TimeStamp
    this.timestamp = Math.floor(new Date().getTime() / 1000);

    //Generate TOken
    var generateHmacToken = CryptoJS.HmacSHA256(this.secretKey+this.timestamp+this.nonce,this.privateKey).toString(CryptoJS.enc.Hex);

    //Get Account Type
    let tempacctype = localStorage.getItem("admin_user_type");
    this.accounttype = this.tf.staticPush(tempacctype);

    const token = localStorage.getItem("admin_user_token");
    if (token) {
        if (httpRequest.url.includes('/api/common')) {
          const authReq = httpRequest.clone({
            withCredentials: true,
            headers: httpRequest.headers.set("nonce", this.nonce.toString()).set("timestamp", this.timestamp.toString()).set("token", generateHmacToken.toString()).set("authorization", token).set("accounttype", this.accounttype).set("Cache-Control", 'no-cache'),
          });
          httpRequest = authReq;
          this.ngxLoader.start();
        }else{
          const authReq = httpRequest.clone({
            withCredentials: true,
            headers: httpRequest.headers.set("nonce", this.nonce.toString()).set("timestamp", this.timestamp.toString()).set("token", generateHmacToken.toString()).set("authorization", token).set("accounttype", this.accounttype),
          });
          httpRequest = authReq;
          this.ngxLoader.start();
        }
    } else{
      if (httpRequest.url.includes('/api/common')) {
        const authReq = httpRequest.clone({
          withCredentials: true,
          headers: httpRequest.headers.set('nonce',this.nonce.toString()).set('timestamp',this.timestamp.toString()).set('token',generateHmacToken.toString()).set("Cache-Control", 'no-cache')
        });
        httpRequest = authReq;
        this.ngxLoader.start();
      }else{
        const authReq = httpRequest.clone({
          withCredentials: true,
          headers: httpRequest.headers.set('nonce',this.nonce.toString()).set('timestamp',this.timestamp.toString()).set('token',generateHmacToken.toString())
        });
        httpRequest = authReq;
        this.ngxLoader.start();
      }
    }
    return next.handle(httpRequest).pipe(
      tap(
        event => this.handleResponse(httpRequest, event),
        error => this.handleError(httpRequest, error)
      )
    );
  }

  handleResponse(httpRequest: HttpRequest<any>, event:any) {
      if (event instanceof HttpResponse) {
        this.ngxLoader.stop();
        if(event.body.code == 401){
          localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_type");
        localStorage.removeItem("admin_user_email");
        localStorage.removeItem("sub_admin_account_type");
        localStorage.removeItem("sub_admin_email");
        localStorage.removeItem("sub_admin_role");
        localStorage.removeItem("admin_zendesk_token");
        localStorage.removeItem("admin_device_id");
          setTimeout(() => {
            window.location.href = environment.adminLogin;
          }, 1000);
        }
      }
  }

  handleError(httpRequest: HttpRequest<any>, error:any) {
    this.ngxLoader.stop();
    if (error.status == 400 || error.status == 401 || error.status == 404|| error.status == 500){
        localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_type");
        localStorage.removeItem("admin_user_email");
        localStorage.removeItem("sub_admin_account_type");
        localStorage.removeItem("sub_admin_email");
        localStorage.removeItem("sub_admin_role");
        localStorage.removeItem("admin_zendesk_token");
        localStorage.removeItem("admin_device_id");
        setTimeout(() => {
          window.location.href = environment.adminLogin;
        }, 1000);
    }
  }

}
