<div name="education-box" id="education-box" class="col-12">
  <div name="education-card-container" id="education-card-container" class="card-container">
    <div name="education-card-header" id="education-card-header" class="card-header">
      <div name="education-title-section" id="education-title-section" class="title-section custom-title">
        <div class="d-flex w-100 custom-title-flex">
          <div class="order-1">
            <h4 name="education-card-title" id="education-card-title" class="card-title award-margin">Education</h4>
          </div>
          <div class="order-2" placement="right auto" [ngbTooltip]="
          addTalentEducationActionPermission ? '' : 'Not Authorized'
        " [ngClass]="
        addTalentEducationActionPermission ? '' : 'unauthorized-cursor'
        ">
            <button name="education-card-edit-button" id="education-card-edit-button" class="card-edit-button ecard-add-button" type="button" (click)="AddEducationModal(addeducation,addTalentEducationActionPermission)" [ngClass]="
            addTalentEducationActionPermission ? '' : 'unauthorized-action'
          ">
              <img class="card-add-button-icon" [src]="imagePath + 'green-add.svg'" alt="add-button">Add New
            </button>
          </div>
        </div>
        <div class="d-flex w-100 custom-title-flex-end">
          <div name="education-status-section" id="education-status-section" class="status-section status-margin">
            <div name="education-incomplete-status-box" id="education-incomplete-status-box" class="status-box" *ngIf="!educationCardCompleted">
              <img id="education-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'" alt="status-icon">
              <label name="education-incomplete-status-text" id="education-incomplete-status-text" class="status-text incomplete">Pending</label>
            </div>
            <div name="education-completed-status-box" id="education-completed-status-box" class="status-box" *ngIf="educationCardCompleted">
              <img id="education-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'" alt="status-icon">
              <label name="education-completed-status-text" id="education-completed-status-text" class="status-text completed">Completed</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div name="education-card" id="education-card" class="card" [ngClass]="educationCardCompleted ? 'completed' : 'incomplete'">
      <perfect-scrollbar>
        <div class="card-content" *ngIf="educationArray.length == 0">
          <label class="text">No Education Details Found</label>
        </div>
        <div name="education-card-content" id="education-card-content" class="card-content scroll-card pb-0" *ngIf="educationArray.length > 0">
          <div name="education-card-content-row" id="education-card-content-row" class="row education-card-content" *ngFor="let element of educationArray">
            <div name="education-card-content-col-0" id="education-card-content-col-0" class="col-12 col-sm-6">
              <label name="education-card-content-col-0-highlighted-title" class="text mb-1" *ngIf="element.vDegreeName && element.vBranchName && element.vDegreeName != 'Other - if degree is not listed' && element.vBranchName != 'Other - if branch is not listed'">
                {{element.vDegreeName}} in {{element.vBranchName}}
              </label>
              <label name="education-card-content-col-0-highlighted-title" class="text mb-1" *ngIf="element.vOtherDegree && element.vOtherBranch">
                {{element.vOtherDegree}} in {{element.vOtherBranch}}
              </label>
              <label name="education-card-content-col-0-highlighted-title" class="text mb-1" *ngIf="element.vDegreeName && element.vOtherBranch && element.vDegreeName != 'Other - if degree is not listed'">
                {{element.vDegreeName}} in {{element.vOtherBranch}}
              </label>
              <label name="education-card-content-col-0-highlighted-title" class="text mb-1" *ngIf="element.vOtherDegree && element.vBranchName && element.vBranchName != 'Other - if branch is not listed'">
                {{element.vOtherDegree}} in {{element.vBranchName}}
              </label>
              <label name="education-card-content-col-1-label" id="education-card-content-col-1-label" class="label">
                {{element?.vUniversity}}
              </label>
            </div>
            <div name="education-card-content-col-2" id="education-card-content-col-2" class="col-12 col-sm-6">
              <div class="d-flex justify-content-end align-items-center">
                <label name="education-card-content-col-2-label" id="education-card-content-col-2-label" class="text text-right">
                  {{element.iJoin * 1000 | date:'MMM d, y'}} - {{element.iComplete * 1000 | date:'MMM d, y'}}
                </label>
                <button name="certifications-card-edit-button" id="certifications-card-edit-button" class="card-edit-button ms-1" type="button" (click)="UpdateEducationModal(updateducation, element,editTalentEducationActionPermission)" [ngClass]="
                editTalentEducationActionPermission ? '' : 'unauthorized-cursor'" placement="left auto" [ngbTooltip]="editTalentEducationActionPermission ? '' : 'Not Authorized'">
                  <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
                </button>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>

<ng-template #addeducation let-c="close" let-d="dismiss">
  <div name="education-modal-header" id="education-modal-header" class="modal-header">
    <h1 name="education-modal-title" id="education-modal-title" class="modal-title">Add Education</h1>
  </div>
  <perfect-scrollbar>
    <div name="education-modal-body" id="education-modal-body" class="modal-body modal-scroll">
      <form name="addEducationForm" id="addEducationForm" class="form" [formGroup]="addEducationForm" (ngSubmit)="AddEducationProcess()">
        <div name="education-row" id="education-row" class="row">
          <div name="education-col-7" id="education-col-7" class="col-12 col-md-6">
            <label for="vDegree" name="education-col-7-label" id="education-col-7-label" class="custom-label">Degree <span class="text-danger">*</span></label>
            <img class="info-tooltip mx-2" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="top"
          ngbTooltip="Select 'Other' option, if option you want is not there in the list" placement="right"/>
            <mat-form-field hideRequiredMarker name="education-col-7-input" id="education-col-7-input" class="custom-dropdown custom-input form" floatLabel="never" floatPlaceholder="never">
              <input type="text" placeholder="Select Degree" matInput formControlName="vDegree" [matAutocomplete]="autoDegree1" required>
              <mat-autocomplete autoActiveFirstOption #autoDegree1="matAutocomplete">
                <mat-option *ngFor="let option of filteredAddDegree | async" [value]="option.vDegreeName" (onSelectionChange)="GetDegreeId(option?.iDegreeId); SelectionChange(option?.vDegreeName)" title="{{option?.vDegreeName}}">
                  {{option?.vDegreeName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="education-col-7-error-0" id="education-col-7-error-0" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vDegree'].invalid">
                <mat-error name="education-col-7-error-1" id="education-col-7-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vDegree'].errors?.required">
                  Degree is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="education-col-7" id="education-col-7" class="col-12 col-md-6">
            <label for="vBranch" name="education-col-7-label" id="education-col-7-label" class="custom-label">Branch/Specialization <span class="text-danger">*</span></label>

            <mat-form-field hideRequiredMarker name="education-col-7-input" id="education-col-7-input" class="custom-dropdown custom-input form" floatLabel="never" floatPlaceholder="never">
              <input type="text" placeholder="Select Branch" matInput formControlName="vBranch" [matAutocomplete]="autoBranch2" required>
              <mat-autocomplete autoActiveFirstOption #autoBranch2="matAutocomplete">
                <mat-option *ngFor="let option of filteredAddBranch | async" [value]="option.vBranchName" (onSelectionChange)="GetBranchId(option?.iBranchId); SelectionChange(option?.vBranchName)" title="{{option?.vBranchName}}">
                  {{option?.vBranchName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="education-col-7-error-0" id="education-col-7-error-0" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vBranch'].invalid">
                <mat-error name="education-col-7-error-1" id="education-col-7-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vBranch'].errors?.required">
                  Branch is required
                </mat-error>
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="education-col-7-select" id="education-col-7-select" class="custom-dropdown form">
              <mat-select id="vBranch" formControlName="vBranch" placeholder="Select Branch" required (selectionChange)="SelectionChange($event)">
                <mat-option *ngFor="let item of branchListArray; let i = index"
                      [value]="item" title="{{ item.vBranchName }}">{{ item.vBranchName }}</mat-option>
              </mat-select>
              <mat-error name="education-col-7-error-0" id="education-col-7-error-0" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vBranch'].invalid">
                <mat-error name="education-col-7-error-1" id="education-col-7-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vBranch'].errors?.required">
                  Branch is required
                </mat-error>
              </mat-error>
            </mat-form-field> -->

          </div>
          <div class="col-12 col-md-6" *ngIf = "addEducationForm.value.vDegree?.vDegreeName == 'Other - if degree is not listed'">
            <label for="vOtherDegree" name="other-col-2-label" id="other-col-2-label" class="custom-label">Degree Name<span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="other-col-2-input" id="other-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="vOtherDegree" formControlName="vOtherDegree" placeholder="">
              <mat-error name="other-col-2-error-0" id="other-col-2-error-0" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vOtherDegree'].invalid">
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vOtherDegree'].errors!.required">
                  Degree name is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6" *ngIf = "addEducationForm.value.vBranch?.vBranchName == 'Other - if branch is not listed'">
            <label for="vOtherBranch" name="other-col-2-label" id="other-col-2-label" class="custom-label">Branch Name<span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="other-col-2-input" id="other-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="vOtherBranch" formControlName="vOtherBranch" placeholder="">
              <mat-error name="other-col-2-error-0" id="other-col-2-error-0" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vOtherBranch'].invalid">
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vOtherBranch'].errors!.required">
                  Branch name is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="education-founded-date" id="education-founded-date" class="col-12 col-md-6">
            <label for="iJoiningYear" name="education-founded-date-label" id="education-founded-date-label" class="custom-label">Year of Joining <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="education-founded-date-input" id="education-founded-date-input" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input matInput [matDatepicker]="joiningpicker" [max]="today" id="iJoiningYear" formControlName="iJoiningYear" placeholder="Choose Date" readonly required>
              <mat-datepicker-toggle matSuffix [for]="joiningpicker"></mat-datepicker-toggle>
              <mat-datepicker #joiningpicker></mat-datepicker>
              <mat-error name="education-founded-date-error-0" id="education-founded-date-error-0" class="reduce-bottom-space" *ngIf="addEducationForm.controls['iJoiningYear'].invalid">
                <mat-error name="education-col-10-error-1" id="education-founded-date-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['iJoiningYear'].errors!.required">
                  Joining date is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="education-founded-date" id="education-founded-date" class="col-12 col-md-6">
            <label for="iCompletionYear" name="education-founded-date-label" id="education-founded-date-label" class="custom-label">Year of Completion <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="education-founded-date-input" id="education-founded-date-input" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input matInput [matDatepicker]="completionpicker" [min]="addEducationForm.value.iJoiningYear" [max]="today" id="iCompletionYear" formControlName="iCompletionYear" placeholder="Choose Date" readonly required>
              <mat-datepicker-toggle matSuffix [for]="completionpicker"></mat-datepicker-toggle>
              <mat-datepicker #completionpicker></mat-datepicker>
              <mat-error name="education-founded-date-error-0" id="education-founded-date-error-0" class="reduce-bottom-space" *ngIf="addEducationForm.controls['iCompletionYear'].invalid">
                <mat-error name="education-col-10-error-1" id="education-founded-date-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['iCompletionYear'].errors!.required">
                  Completion date is required
                </mat-error>
                <mat-error name="education-col-10-error-1" id="education-founded-date-error-1" class="reduce-bottom-space"  *ngIf="addEducationForm.controls['iCompletionYear'].value">
                  Please enter a valid date range
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="education-col-1" id="education-col-1" class="col-12 col-md-6">
            <label for="iCgpa" name="education-col-1-label" id="education-col-1-label" class="custom-label">CGPA/Percentage <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="education-col-1-input" id="education-col-1-input" class="custom-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="iCgpa" formControlName="iCgpa" placeholder="5.18 / 78.00" required>
              <mat-error name="education-col-1-error-0" id="education-col-1-error-0" class="reduce-bottom-space" *ngIf="addEducationForm.controls['iCgpa'].invalid">
                <mat-error name="education-col-1-error-1" id="education-col-1-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['iCgpa'].errors!.required">
                  CGPA/Percentage is required
                </mat-error>
                <mat-error name="education-col-1-error-1" id="education-col-1-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['iCgpa'].errors!.pattern">
                  Enter valid CGPA/Percentage
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="education-col-2" id="education-col-2" class="col-12 col-md-6">
            <label for="vCollege" name="education-col-2-label" id="education-col-2-label" class="custom-label">University/College <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="education-col-2-input" id="education-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="vCollege" formControlName="vCollege" placeholder="SAL college of engineering" required>
              <mat-error name="education-col-2-error-0" id="education-col-2-error-0" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vCollege'].invalid">
                <mat-error name="education-col-2-error-1" id="education-col-2-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vCollege'].errors!.required">
                  University/College is required
                </mat-error>
                <mat-error name="education-col-2-error-1" id="education-col-2-error-1" class="reduce-bottom-space" *ngIf="addEducationForm.controls['vCollege'].errors!.pattern">
                  Only Character are allowed
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <label for="vDegCertificate" name="education-video-label" id="education-video-label" class="custom-label">Degree Certificate
              <!-- <span class="text-danger">*</span> -->
            </label>
            <mat-form-field hideRequiredMarker name="education-video-input" id="education-video-input" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngIf="vDegCertificate">
                        {{ vDegCertificate.name }} <button type="button" name="button" class="btn"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="education-video-input-vVideo-1" id="education-video-input-vVideo-1" matInput readonly formControlName="vDegCertificate" [value]="vDegCertificate ? ' ' : ''" placeholder="Upload File" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="education-video-input-vVideo-2"
                id="vDegCertificate"
                class="file-upload-input"
                (change)="UploadFileEvt($event)"
                formControlName="vDegCertificate"
                accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel"
              />
              <button name="education-video-input-upload-btn" id="education-video-input-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <!-- <mat-error class="reduce-bottom-space" *ngIf="addEducationForm.controls['vDegCertificate'].invalid">
                <mat-error class="reduce-bottom-space" *ngIf="addEducationForm.controls['vDegCertificate'].errors!.required">
                  Degree Certificate is required
                </mat-error>
              </mat-error> -->
              <div class="input-details-box">
                <p class="text">Max 4MB</p>
              </div>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <div name="education-modal-footer" id="education-modal-footer" class="modal-footer">
    <button type="submit" name="education-modal-footer-btn-submit" id="education-modal-footer-btn-submit" class="btn btn-active" form="addEducationForm">Submit</button>
    <button type="button" name="education-modal-footer-btn-cancel" id="education-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="d('Cross click')">Cancel</button>
  </div>
</ng-template>

<ng-template #updateducation let-c="close" let-d="dismiss">
  <div name="education-modal-header" id="education-modal-header" class="modal-header">
    <h1 name="education-modal-title" id="education-modal-title" class="modal-title">Edit Education</h1>
  </div>
  <perfect-scrollbar>
    <div name="education-modal-body" id="education-modal-body" class="modal-body modal-scroll">
      <form name="updateEducationForm" id="updateEducationForm" class="form" [formGroup]="updateEducationForm" (ngSubmit)="UpdateEducationProcess()">
        <div name="education-row" id="education-row" class="row">
          <div name="education-col-7" id="education-col-7" class="col-12 col-md-6">
            <label for="vDegree" name="education-col-7-label" id="education-col-7-label" class="custom-label">Degree<span class="text-danger">*</span></label>

            <mat-form-field hideRequiredMarker name="education-col-7-input" id="education-col-7-input" class="custom-dropdown custom-input form" floatLabel="never" floatPlaceholder="never">
              <input type="text" placeholder="Select Degree" matInput formControlName="vDegree" [matAutocomplete]="autoDegree2" required>
              <mat-autocomplete autoActiveFirstOption #autoDegree2="matAutocomplete">
                <mat-option *ngFor="let option of filteredEditDegree | async" [value]="option.vDegreeName" (onSelectionChange)="GetDegreeId(option?.iDegreeId); SelectionChange(option?.vDegreeName)" title="{{option?.vDegreeName}}">
                  {{option?.vDegreeName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="education-col-7-error-0" id="education-col-7-error-0" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vDegree'].invalid">
                <mat-error name="education-col-7-error-1" id="education-col-7-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vDegree'].errors?.required">
                  Degree is required
                </mat-error>
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="education-col-7-select" id="education-col-7-select" class="custom-dropdown form">
              <mat-select id="vDegree" formControlName="vDegree" placeholder="Select Degree" required (selectionChange)="SelectionChange($event)" >
                <mat-option *ngFor="let item of degreeListArray" [value]="item">{{ item.vDegreeName }}</mat-option>
              </mat-select>
              <mat-error name="education-col-7-error-0" id="education-col-7-error-0" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vDegree'].invalid">
                <mat-error name="education-col-7-error-1" id="education-col-7-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vDegree'].errors?.required">
                  Degree is required
                </mat-error>
              </mat-error>
            </mat-form-field> -->

          </div>
          <div name="education-col-7" id="education-col-7" class="col-12 col-md-6">
            <label for="vBranch" name="education-col-7-label" id="education-col-7-label" class="custom-label">Branch/Specialization <span class="text-danger">*</span></label>

            <mat-form-field hideRequiredMarker name="education-col-7-input" id="education-col-7-input" class="custom-dropdown custom-input form" floatLabel="never" floatPlaceholder="never">
              <input type="text" placeholder="Select Branch" matInput formControlName="vBranch" [matAutocomplete]="autoBranch2" required>
              <mat-autocomplete autoActiveFirstOption #autoBranch2="matAutocomplete">
                <mat-option *ngFor="let option of filteredEditBranch | async" [value]="option.vBranchName" (onSelectionChange)="GetBranchId(option?.iBranchId); SelectionChange(option?.vBranchName)" title="{{option?.vBranchName}}">
                  {{option?.vBranchName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error name="education-col-7-error-0" id="education-col-7-error-0" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vBranch'].invalid">
                <mat-error name="education-col-7-error-1" id="education-col-7-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vBranch'].errors?.required">
                  Branch is required
                </mat-error>
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never" name="education-col-7-select" id="education-col-7-select" class="custom-dropdown form">
              <mat-select id="vBranch" formControlName="vBranch" placeholder="Select Branch" required>
                <mat-option *ngFor="let item of branchListArray; let i = index"
                      [value]="item">{{ item.vBranchName }}</mat-option>
              </mat-select>
              <mat-error name="education-col-7-error-0" id="education-col-7-error-0" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vBranch'].invalid">
                <mat-error name="education-col-7-error-1" id="education-col-7-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vBranch'].errors?.required">
                  Branch is required
                </mat-error>
              </mat-error>
            </mat-form-field> -->

          </div>
          <div class="col-12 col-md-6" *ngIf = "updateEducationForm.value.vDegree?.vDegreeName == 'Other - if degree is not listed'">
            <label for="vOtherDegree" name="other-col-2-label" id="other-col-2-label" class="custom-label">Degree Name<span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="other-col-2-input" id="other-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="vOtherDegree" formControlName="vOtherDegree" placeholder="">
              <mat-error name="other-col-2-error-0" id="other-col-2-error-0" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vOtherDegree'].invalid">
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vOtherDegree'].errors!.required">
                  Degree name is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6" *ngIf = "updateEducationForm.value.vBranch?.vBranchName == 'Other - if branch is not listed'">
            <label for="vOtherBranch" name="other-col-2-label" id="other-col-2-label" class="custom-label">Branch Name<span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="other-col-2-input" id="other-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="vOtherBranch" formControlName="vOtherBranch" placeholder="">
              <mat-error name="other-col-2-error-0" id="other-col-2-error-0" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vOtherBranch'].invalid">
                <mat-error name="other-col-2-error-1" id="other-col-2-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vOtherBranch'].errors!.required">
                  Branch name is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="education-founded-date" id="education-founded-date" class="col-12 col-md-6">
            <label for="iJoiningYear" name="education-founded-date-label" id="education-founded-date-label" class="custom-label">Year of Joining<span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="education-founded-date-input" id="education-founded-date-input-1" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input matInput [matDatepicker]="joiningpicker" [max]="today" id="iJoiningYear-1" formControlName="iJoiningYear" placeholder="Choose Date" readonly required (dateChange)="DaterangeGreaterSelection($event)">
              <mat-datepicker-toggle matSuffix [for]="joiningpicker"></mat-datepicker-toggle>
              <mat-datepicker #joiningpicker></mat-datepicker>
              <mat-error name="education-founded-date-error-0" id="education-founded-date-error-0" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['iJoiningYear'].invalid">
                <mat-error name="education-col-10-error-1" id="education-founded-date-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['iJoiningYear'].errors!.required">
                  Joining date is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="education-founded-date" id="education-founded-date" class="col-12 col-md-6">
            <label for="iCompletionYear" name="education-founded-date-label" id="education-founded-date-label" class="custom-label">Year of Completion<span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="education-founded-date-input" id="education-founded-date-input-1" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input matInput [matDatepicker]="completionpicker" [min]="updateEducationForm.value.iJoiningYear" [max]="today" id="iCompletionYear-1" formControlName="iCompletionYear" placeholder="Choose Date" readonly required>
              <mat-datepicker-toggle matSuffix [for]="completionpicker"></mat-datepicker-toggle>
              <mat-datepicker #completionpicker></mat-datepicker>
              <mat-error name="education-founded-date-error-0" id="education-founded-date-error-0" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['iCompletionYear'].invalid">
                <mat-error name="education-col-10-error-1" id="education-founded-date-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['iCompletionYear'].errors!.required">
                  Completion date is required
                </mat-error>
                <!-- <mat-error name="education-col-10-error-1" id="education-founded-date-error-1" class="reduce-bottom-space"  *ngIf="updateEducationForm.controls['iCompletionYear'].value">
                  Please enter a valid date range
                </mat-error> -->
              </mat-error>
            </mat-form-field>
          </div>
          <div name="education-col-1" id="education-col-1" class="col-12 col-md-6">
            <label for="iCgpa" name="education-col-1-label" id="education-col-1-label" class="custom-label">CGPA/Percentage <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="education-col-1-input" id="education-col-1-input" class="custom-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="iCgpa" formControlName="iCgpa" placeholder="5.18 / 78.00" required>
              <mat-error name="education-col-1-error-0" id="education-col-1-error-0" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['iCgpa'].invalid">
                <mat-error name="education-col-1-error-1" id="education-col-1-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['iCgpa'].errors!.required">
                  CGPA/Percentage is required
                </mat-error>
                <mat-error name="education-col-1-error-1" id="education-col-1-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['iCgpa'].errors!.pattern">
                  Enter valid CGPA/Percentage
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div name="education-col-2" id="education-col-2" class="col-12 col-md-6">
            <label for="vCollege" name="education-col-2-label" id="education-col-2-label" class="custom-label">University/College <span class="text-danger">*</span></label>
            <mat-form-field hideRequiredMarker name="education-col-2-input" id="education-col-2-input" class="custom-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <input type="text" matInput id="vCollege" formControlName="vCollege" placeholder="SAL college of engineering" required>
              <mat-error name="education-col-2-error-0" id="education-col-2-error-0" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vCollege'].invalid">
                <mat-error name="education-col-2-error-1" id="education-col-2-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vCollege'].errors!.required">
                  University/College is required
                </mat-error>
                <mat-error name="education-col-2-error-1" id="education-col-2-error-1" class="reduce-bottom-space" *ngIf="updateEducationForm.controls['vCollege'].errors!.pattern">
                  Only Character are allowed
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <label for="vDegCertificate" name="education-video-label" id="education-video-label" class="custom-label">Degree Certificate</label>
            <mat-form-field hideRequiredMarker name="education-video-input" id="education-video-input" class="custom-input visibility-input" floatLabel="never" floatPlaceholder="never" floatLabel="never">
              <div class="file-container">
                <div class="files">
                  <div class="file-scroll-box">
                    <perfect-scrollbar [config]="config">
                      <div class="file" *ngIf="vDegCertificate">
                        {{ vDegCertificate.name }} <button type="button" name="button" (click)="crossClick()" class="btn"><mat-icon>close</mat-icon></button>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <input name="education-video-input-vVideo-1" id="education-video-input-vVideo-1" matInput readonly formControlName="vDegCertificate" placeholder="Upload File" />
              <input
                matSuffix
                type="file"
                #uploadFile
                name="education-video-input-vVideo-2"
                id="vDegCertificate"
                class="file-upload-input"
                (change)="UploadFileEvt($event)"
                formControlName="vDegCertificate"
                accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel"
              />
              <button name="education-video-input-upload-btn" id="education-video-input-upload-btn" class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
              <div class="input-details-box">
                <p class="text">Max 4MB</p>
              </div>
            </mat-form-field>
          </div>
          <div name="awards-class" id="awards-class" class="col-12 mt-2" *ngIf="selectedEducationCertUrl && selectedEducationCertName">
            <div name="awards-class-1" id="awards-1" class="image-display-container pb-2">
              <div name="award-1" id="award-1" class="image-name">
                <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                <a [href]="selectedEducationCertUrl" target="_blank">{{ selectedEducationCertName }}</a>
              </div>
              <!-- <button type="button" name="button" id="award-1-btn">
                <img id="award-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
              </button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <div name="education-modal-footer" id="education-modal-footer" class="modal-footer">
    <button type="submit" name="education-modal-footer-btn-submit" id="education-modal-footer-btn-submit" class="btn btn-active" form="updateEducationForm">Submit</button>
    <button type="button" name="education-modal-footer-btn-cancel" id="education-modal-footer-btn-cancel" class="btn btn-outline-active" (click)="d('Cross click'); 
    this.updateEducationForm.reset();">Cancel</button>
  </div>
</ng-template>
