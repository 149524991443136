import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NavService {
  public appDrawer: any;
  public currentUrl = new BehaviorSubject<string>(undefined!);

  constructor(private router: Router, private http: HttpClient) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  viewProfile() {
    return this.http.get(`${environment.baseUrl}/common/v1/login-user-information`);
  }

  logoutAPI(data: any) {
    return this.http.post(`${environment.baseUrl}/common/v1/Logout`, data);
  }

  Logout() {
    localStorage.removeItem("admin_user_token");
    localStorage.removeItem("admin_user_type");
    localStorage.removeItem("admin_user_email");
    localStorage.removeItem("sub_admin_account_type");
    localStorage.removeItem("sub_admin_email");
    localStorage.removeItem("sub_admin_role");
    localStorage.removeItem("admin_zendesk_token");
    localStorage.removeItem("admin_device_id");
  }

  public homeAppDrawer: any;

  public closeHomeNav() {
    this.homeAppDrawer.close();
  }

  public openHomeNav() {
    this.homeAppDrawer.open();
  }

  public closeNav() {
    this.appDrawer.close();
  }

  public openNav() {
    this.appDrawer.open();
  }

  LoginSubUserInformation(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v2/login-sub-user-information`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

}
