import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyDetailsService {

  constructor( private http: HttpClient ) { }

  UpdateCompanyDetails(data: any,clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/company-details?iUserId=${clientUserId}`;
      const formData: FormData = new FormData();
      formData.append("vIntroDeck", data.vIntroDeck);
      formData.append("vVideo", data.vVideo);
      formData.append("media", data.media);
      formData.append("media1", data.media1);
      formData.append("media2", data.media2);
      formData.append("media3", data.media3);
      formData.append("media4", data.media4);
      formData.append("vSize", data.vSize);
      formData.append("vTechnology", data.vTechnology);
      formData.append("vIndustryDetails", data.vIndustryDetails);
      formData.append("vServiceDetails", data.vServiceDetails);
      formData.append("vShippingAddress", data.vShippingAddress);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCompanyDetails(clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/company-details?iUserId=${clientUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteCompanyDetailImages(data: any,clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/company-detail-image?iUserId=${clientUserId}`;
      this.http
        .put(apiURL,data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
