<div class="d-flex flex-column main-container h-100">
  <div class="container-fluid change-password">
    <div class="wrapper">
      <div class="pagetitle-wrapper">
        <div class="pagetitle-block">
          <i class="pagetitle-icon"><span class="material-icons">supervisor_account</span></i>
          <h2 class="pagetitle">Change Password</h2>
        </div>
      </div>
      <div class="d-flex justify-content-start align-items-start height-100vh">
        <div class="sign-in-block">
          <form [formGroup]="changePasswordForm" (ngSubmit)="UserCheck()" class="signin-form">
            <label for="currentPassword" class="label">Current Password</label>
            <mat-form-field hideRequiredMarker floatLabel="never">
              <input matInput required [type]="hideCurrentPassword ? 'password' : 'text'" id="currentPassword" formControlName="currentPassword" placeholder="Current Password"/>
              <mat-icon matSuffix (click)="hideCurrentPassword = !hideCurrentPassword" class="d-flex justify-content-center align-items-center h-100">{{ hideCurrentPassword ? "visibility_off" : "visibility" }}
              </mat-icon>
              <div class="error-box">
                <mat-error *ngIf="submitted && changePasswordForm.controls['currentPassword'].invalid">
                  <mat-error *ngIf="changePasswordForm.controls['currentPassword'].errors.required">Current Password is required</mat-error>
                    <mat-error *ngIf="changePasswordForm.controls['currentPassword'].errors.minlength || changePasswordForm.controls['currentPassword'].errors?.maxlength"> Password must be in between <strong>6 - 15</strong> characters </mat-error>
                </mat-error>
              </div>
            </mat-form-field>

            <label for="newPassword" class="label">New Password</label>
            <mat-form-field hideRequiredMarker floatLabel="never">
              <input matInput required [type]="hideNewPassword ? 'password' : 'text'" id="newPassword" formControlName="newPassword" placeholder="New Password"/>
              <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword" class="d-flex justify-content-center align-items-center h-100">{{ hideNewPassword ? "visibility_off" : "visibility" }}
              </mat-icon>
              <div class="error-box">
                <mat-error *ngIf="submitted && changePasswordForm.controls['newPassword'].invalid">
                  <mat-error *ngIf="changePasswordForm.controls['newPassword'].errors.required">New Password is required</mat-error>
                    <mat-error *ngIf="changePasswordForm.controls['newPassword'].errors.minlength || changePasswordForm.controls['newPassword'].errors?.maxlength"> Password must be in between <strong>6 - 15</strong> characters </mat-error>
                </mat-error>
              </div>
            </mat-form-field>

            <label for="confirmPassword" class="label">Confirm Password</label>
            <mat-form-field hideRequiredMarker floatLabel="never">
              <input matInput required [type]="hideConfirmPassword ? 'password' : 'text'" id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm Password"/>
              <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" class="d-flex justify-content-center align-items-center h-100">{{ hideConfirmPassword ? "visibility_off" : "visibility" }}
              </mat-icon>
              <div class="error-box">
                <mat-error *ngIf="submitted && changePasswordForm.controls['confirmPassword'].invalid">
                  <mat-error *ngIf="changePasswordForm.controls['confirmPassword'].errors.required">Confirm Password is required</mat-error>
                  <mat-error *ngIf="changePasswordForm.controls['confirmPassword'].errors.matching">Confirm Password does not match with new password</mat-error>
                </mat-error>
              </div>
            </mat-form-field>
            <div class="mt-2">
              <button mat-flat-button disableRipple type="submit" class="btn signin-btn">
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
