import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { NgxSmartModalService } from "ngx-smart-modal";
import { SkillService } from "./skill.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EventEmitterService } from "../../config/event-emitter.service";
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";

@Component({
  selector: "app-skill",
  templateUrl: "./skill.component.html",
})
export class SkillComponent implements OnInit {
  @ViewChild("TableOnePaginator", { static: true })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort", { static: true }) tableOneSort: MatSort;

  @ViewChild("TableTwoPaginator", { static: true })
  tableTwoPaginator: MatPaginator;
  @ViewChild("TableTwoSort", { static: true }) tableTwoSort: MatSort;

  displayedColumnsTableOne: string[] = [
    "indexnumber",
    "vPrimarySkill",
    "iCreatedAt",
    "actions",
  ];
  dataSourceTableOne = new MatTableDataSource();

  displayedColumnsTableTwo: string[] = [
    "indexnumber",
    "vName",
    "iCreatedAt",
    "actions",
  ];
  dataSourceTableTwo = new MatTableDataSource();

  pageIndex: number = 1;
  perPage: number = 10;
  feesCount: number = 0;

  submitted = false;

  tabState: any = "title";

  // toggle actions permission
  addPrimarySkillActionPermission: boolean = false;
  editPrimarySkillActionPermission: boolean = false;
  deletePrimarySkillActionPermission: boolean = false;

  addSecondarySkillActionPermission: boolean = false;
  editSecondarySkillActionPermission: boolean = false;
  deleteSecondarySkillActionPermission: boolean = false;

  constructor(
    private titleSkillService: SkillService,
    private formBuilder: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private userPermissionService: UserPermissionService
  ) {
    // assign individual permissions
    const AddPrimarySkillAction: Permission = Permission.AddPrimarySkillAction;
    const EditPrimarySkillAction: Permission =
      Permission.EditPrimarySkillAction;
    const DeletePrimarySkillAction: Permission =
      Permission.DeletePrimarySkillAction;

    const AddSecondarySkillAction: Permission =
      Permission.AddSecondarySkillAction;
    const EditSecondarySkillAction: Permission =
      Permission.EditSecondarySkillAction;
    const DeleteSecondarySkillAction: Permission =
      Permission.DeleteSecondarySkillAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddPrimarySkillAction)) {
      this.addPrimarySkillActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditPrimarySkillAction)) {
      this.editPrimarySkillActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeletePrimarySkillAction)) {
      this.deletePrimarySkillActionPermission = true;
    }

    if (this.userPermissionService.isAuthorizedFor(AddSecondarySkillAction)) {
      this.addSecondarySkillActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditSecondarySkillAction)) {
      this.editSecondarySkillActionPermission = true;
    }
    if (
      this.userPermissionService.isAuthorizedFor(DeleteSecondarySkillAction)
    ) {
      this.deleteSecondarySkillActionPermission = true;
    }
  }

  rapidisefeesEditForm!: FormGroup;
  addPrimarySkillForm!: FormGroup;
  editPrimarySkillForm!: FormGroup;
  addSecondarySkillForm!: FormGroup;
  editSecondarySkillForm!: FormGroup;

  ngOnInit(): void {
    this.addPrimarySkillForm = new FormGroup({
      primarySkill: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
    });

    this.editPrimarySkillForm = new FormGroup({
      iPrimarySkillId: new FormControl("", []),
      primarySkill: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
    });

    this.addSecondarySkillForm = new FormGroup({
      // primarySkill: new FormControl('', [Validators.required]),
      secondarySkill: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(255),
      ]),
    });

    this.editSecondarySkillForm = new FormGroup({
      iSkillId: new FormControl("", []),
      // primarySkill: new FormControl('', [Validators.required]),
      secondarySkill: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(255),
      ]),
    });

    this.GetRefreshAPIData();

    if (this.route.snapshot.paramMap.get("state")) {
      this.tabState = this.route.snapshot.paramMap.get("state");
    }
  }

  GetRefreshAPIData() {
    this.GetPrimarySkill();
    this.GetSecondarySkill();
  }

  get addPrimarySkillFormHas(): { [key: string]: AbstractControl } {
    return this.addPrimarySkillForm.controls;
  }

  get editPrimarySkillFormHas(): { [key: string]: AbstractControl } {
    return this.editPrimarySkillForm.controls;
  }

  get addSecondarySkillFormHas(): { [key: string]: AbstractControl } {
    return this.addSecondarySkillForm.controls;
  }

  get editSecondarySkillFormHas(): { [key: string]: AbstractControl } {
    return this.editSecondarySkillForm.controls;
  }

  selectedPrimarySkill: any;
  OpenModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.selectedPrimarySkill = "default";
      this.addPrimarySkillForm.reset();
      this.addSecondarySkillForm.reset();
      this.submitted = false;
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
    this.dataSourceTableTwo.paginator = this.tableTwoPaginator;
    this.dataSourceTableTwo.sort = this.tableTwoSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value
      .trim()
      .toLowerCase();
    this.dataSourceTableTwo.filter = filterValue.target.value
      .trim()
      .toLowerCase();
  }

  selectedPrimarySkillData: any;
  OpenModelForEditPriSkill(
    modalToOpen: any,
    data: any,
    hasPermission: boolean
  ) {
    if (hasPermission) {
      this.modalService.open(modalToOpen, { centered: true });
      this.selectedPrimarySkillData = data;
      this.editPrimarySkillForm.patchValue({
        iPrimarySkillId: this.selectedPrimarySkillData["iPrimarySkillId"],
        primarySkill: this.selectedPrimarySkillData["vPrimarySkill"],
      });
    }
  }

  public primarySkillData: any = [];
  GetPrimarySkill() {
    let reqObject = { pageIndex: this.pageIndex - 1, perPage: this.perPage };
    this.titleSkillService
      .GetPrimarySkill(reqObject)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.dataSourceTableOne.data = result["data"];
          this.primarySkillData = result["data"];
        } else {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err: any) => {
        console.log(err, "GetPrimarySkill");
      });
  }

  AddPrimarySkill() {
    this.submitted = true;
    if (this.addPrimarySkillForm.invalid) {
      return;
    }
    let reqData = {
      vPrimarySkill: this.addPrimarySkillForm.value.primarySkill,
    };
    this.titleSkillService
      .AddPrimarySkill(reqData)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
          this.modalService.dismissAll();
          this.GetPrimarySkill();
        } else {
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err, "AddPrimarySkill");
      });
  }

  EditPrimarySkill() {
    this.submitted = true;
    if (this.editPrimarySkillForm.invalid) {
      return;
    }
    let reqData = {
      iPrimarySkillId: this.editPrimarySkillForm.value.iPrimarySkillId,
      vPrimarySkill: this.editPrimarySkillForm.value.primarySkill,
    };
    this.titleSkillService
      .UpdatePrimarySkill(reqData)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
          this.modalService.dismissAll();
          this.GetPrimarySkill();
        } else {
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err, "EditPrimarySkill");
      });
  }

  DeletePrimarySkill(item: any, hasPermission: boolean): void {
    if (hasPermission) {
      this.titleSkillService
        .DeletePrimarySkill(item.iPrimarySkillId)
        .then((result: any) => {
          this.GetRefreshAPIData();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
        })
        .catch((err) => {
          console.log(err, "DeletePrimarySkill");
        });
    }
  }

  selectedSecondarySkillData: any;
  OpenModelForEditSecSkill(
    modalToOpen: any,
    data: any,
    hasPermission: boolean
  ) {
    if (hasPermission) {
      this.modalService.open(modalToOpen, { centered: true });
      this.selectedSecondarySkillData = data;
      this.editSecondarySkillForm.patchValue({
        iSkillId: data["iSkillId"],
        // iPrimarySkillId: data['iPrimarySkillId'],
        secondarySkill: data["vName"],
      });
      this.selectedPrimarySkill = data.iPrimarySkillId;
    }
  }

  GetSecondarySkill() {
    this.titleSkillService
      .GetSecondarySkill({})
      .then((result: any) => {
        if (result["code"] == 200) {
          this.dataSourceTableTwo.data = result["data"];
        } else {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  AddSecondarySkill() {
    this.submitted = true;
    if (this.addSecondarySkillForm.invalid) {
      return;
    }
    let reqData = {
      // iPrimarySkillId: this.addSecondarySkillForm.value.primarySkill,
      vName: this.addSecondarySkillForm.value.secondarySkill,
    };
    this.titleSkillService
      .AddSecondarySkill(reqData)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
          this.modalService.dismissAll();
          this.GetRefreshAPIData();
        } else {
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err, "AddSecondarySkill");
      });
  }

  EditSecondarySkill() {
    this.submitted = true;
    if (this.editSecondarySkillForm.invalid) {
      return;
    }
    let reqData = {
      iSkillId: this.editSecondarySkillForm.value.iSkillId,
      // iPrimarySkillId: this.editSecondarySkillForm.value.iPrimarySkillId,
      vName: this.editSecondarySkillForm.value.secondarySkill,
    };
    this.titleSkillService
      .UpdatesecondarySkill(reqData)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
          this.modalService.dismissAll();
          this.GetRefreshAPIData();
        } else {
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err, "EditSecondarySkill");
      });
  }

  DeleteSecondarySkill(item: any, hasPermission: boolean): void {
    if (hasPermission) {
      this.titleSkillService
        .DeleteSecondarySkill(item.iSkillId)
        .then((result: any) => {
          this.GetRefreshAPIData();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
        })
        .catch((err) => {
          console.log(err, "DeleteSecondarySkill");
        });
    }
  }
}
