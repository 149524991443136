<div name="company-details-box" id="company-details-box" class="col-12">
  <div name="company-details-card-container" id="company-details-card-container" class="card-container" *ngIf="companyDetailsToggle==false">
    <div name="company-details-card-header" id="company-details-card-header" class="card-header">
      <div name="company-details-title-section" id="company-details-title-section" class="title-section">
        <h4 name="company-details-card-title" id="company-details-card-title" class="card-title">Company Details</h4>
        <div placement="right auto" [ngbTooltip]="editPartnerCompanyDetailsActionPermission ? '':'Not Authorized'" [ngClass]="editPartnerCompanyDetailsActionPermission ? '':'unauthorized-cursor'">
          <button name="company-details-card-edit-button" id="company-details-card-edit-button" class="card-edit-button" type="button" (click)="toggleCompanyDetails(editPartnerCompanyDetailsActionPermission)" [ngClass]="editPartnerCompanyDetailsActionPermission ? '':'unauthorized-action'">
            <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button">
          </button>
        </div>
        <!-- <img class="info-tooltip mx-2" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="top"
          ngbTooltip="It is not editable once approved by Rapidise." /> -->
      </div>
      <div name="company-details-status-section" id="company-details-status-section" class="status-section">
        <div name="company-details-status-incomplete-status-box" id="company-details-status-incomplete-status-box"
          class="status-box" *ngIf="!companyDetailsCardCompleted">
          <img id="company-details-status-incomplete-status-icon" class="status-icon"
            [src]="imagePath + 'pending-status.svg'" alt="status-icon">
          <label name="company-details-status-incomplete-status-text" id="company-details-status-incomplete-status-text"
            class="status-text incomplete">Pending</label>
        </div>
        <div name="company-details-status-completed-status-box" id="company-details-status-completed-status-box"
          class="status-box" *ngIf="companyDetailsCardCompleted">
          <img id="company-details-status-incomplete-status-icon" class="status-icon"
            [src]="imagePath + 'green-tick.svg'" alt="status-icon">
          <label name="company-details-status-incomplete-status-text" id="company-details-status-incomplete-status-text"
            class="status-text completed">Completed</label>
        </div>
      </div>
    </div>
    <div name="company-details-card" id="company-details-card" class="card"
      [ngClass]="companyDetailsCardCompleted ? 'completed' : 'incomplete'">
      <div name="company-details-card-content" id="company-details-card-content" class="card-content">
        <div name="company-details-row" id="company-details-row" class="row">
          <div name="company-details-col-1" id="company-details-col-1" class="col-12 col-lg-3 col-sm-6">
            <label name="company-details-col-1-label" id="company-details-col-1-label" class="label">
              Intro Deck
            </label>
            <label name="company-details-col-1-text" id="company-details-col-1-text" class="text link bottom-spacing"
              *ngIf="companyDetailsArray.intro_deck_url">
              <a target="_blank" href="{{companyDetailsArray.intro_deck_url}}">View Introdeck</a>
              <img class="info-tooltip" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="bottom"
                ngbTooltip="Click on View Intro Deck for view Company Information!" />
            </label>
            <label name="company-details-col-1-text" id="company-details-col-1-text" class="text bottom-spacing"
              *ngIf="!companyDetailsArray.intro_deck_url">
              Not Available
            </label>
          </div>
          <div name="company-details-col-2" id="company-details-col-2" class="col-12 col-lg-3 col-sm-6">
            <label name="company-details-col-2-label" id="company-details-col-2-label" class="label">
              Video
            </label>
            <label name="company-details-col-2-text" id="company-details-col-2-text" class="text link bottom-spacing"
              *ngIf="companyDetailsArray.video_url">
              <a target="_blank" href="{{companyDetailsArray.video_url}}">View Video</a>
              <img class="info-tooltip" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="bottom"
                ngbTooltip="Click on View Video!" />
            </label>
            <label name="company-details-col-2-text" id="company-details-col-2-text" class="text bottom-spacing"
              *ngIf="!companyDetailsArray.video_url">Not Available</label>
          </div>
          <div name="company-details-col-3" id="company-details-col-3" class="col-12 col-lg-3 col-sm-6">
            <label name="company-details-col-3-label" id="company-details-col-3-label" class="label">
              Images
            </label>
            <label name="company-details-col-3-text" id="company-details-col-3-text" class="text link bottom-spacing"
              *ngIf="companyDetailsArray.images">
              {{ companyDetailsArray.images }}
            </label>
            <label name="company-details-col-3-text" id="company-details-col-3-text" class="text bottom-spacing"
              *ngIf="!companyDetailsArray.images">Not Available</label>
          </div>
          <div name="company-details-col-4" id="company-details-col-4" class="col-12 col-lg-3 col-sm-6">
            <label name="company-details-col-4-label" id="company-details-col-4-label" class="label">
              Size
            </label>
            <label name="company-details-col-4-text" id="company-details-col-4-text" class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == 1"> 1 - 10 Employees </label>
            <label name="company-details-col-4-text" id="company-details-col-4-text" class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == 2"> 10-25 Employees </label>
            <label name="company-details-col-4-text" id="company-details-col-4-text" class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == 3"> 25-100 Employees </label>
            <label name="company-details-col-4-text" id="company-details-col-4-text" class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == 4"> 100-500 Employees </label>
            <label name="company-details-col-4-text" id="company-details-col-4-text" class="text bottom-spacing"
              *ngIf="companyDetailsArray.size == 5"> More than 500+ Employees </label>
            <label name="company-details-col-4-text" id="company-details-col-4-text" class="text bottom-spacing"
              *ngIf="!companyDetailsArray.size">Not Available</label>
          </div>
          <div name="company-details-col-5" id="company-details-col-5" class="col-12">
            <label name="company-details-col-5-label" id="company-details-col-5-label" class="label">
              Technology
            </label>
            <label name="company-details-col-5-text" id="company-details-col-5-text" class="text bottom-spacing"
              *ngIf="companyDetailsArray.technologiesName">{{ companyDetailsArray.technologiesName }}</label>
            <label name="company-details-col-5-text" id="company-details-col-5-text" class="text bottom-spacing"
              *ngIf="!companyDetailsArray.technologiesName">Not Available</label>
          </div>
          <div name="company-details-col-6" id="company-details-col-6" class="col-12">
            <label name="company-details-col-6-label" id="company-details-col-6-label" class="label">
              Industries
            </label>
            <label name="company-details-col-6-text" id="company-details-col-6-text" class="text bottom-spacing"
              *ngIf="companyDetailsArray.industriesName">
              {{ companyDetailsArray.industriesName }}
            </label>
            <label name="company-details-col-5-text" id="company-details-col-5-text" class="text bottom-spacing"
              *ngIf="!companyDetailsArray.industriesName">Not Available</label>
          </div>
          <div name="company-details-col-7" id="company-details-col-7" class="col-12">
            <label name="company-details-col-7-label" id="company-details-col-7-label" class="label">
              Specialties
            </label>
            <label name="company-details-col-7-text" id="company-details-col-7-text" class="text"
              *ngIf="companyDetailsArray.servicesName">
              {{ companyDetailsArray.servicesName }}
            </label>
            <label name="company-details-col-5-text" id="company-details-col-5-text" class="text"
              *ngIf="!companyDetailsArray.servicesName">Not Available</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-container" *ngIf="companyDetailsToggle==true">
    <div name="company-details-card-header" id="company-details-card-header" class="card-header">
      <div name="company-details-title-section" id="company-details-title-section" class="title-section">
        <h4 name="company-details-card-title" id="company-details-card-title" class="card-title">Company Details</h4>
      </div>
    </div>
    <div class="edit-profile">
      <div name="company-details-modal-body" id="company-details-modal-body" class="modal-body modal-scroll">
        <form id="companyDetailsForm" name="companyDetailsForm" class="form" [formGroup]="companyDetailsForm"
          (ngSubmit)="UpdateCompanyDetails()">
          <div class="row">
            <div class="col-12 col-md-6">
              <label for="vIntroDeck" name="company-details-label" id="company-details-intro-deck-label"
                 class="custom-label">Intro Deck <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-details-intro-deck-input"
                id="company-details-intro-deck-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div name="company-details-intro-deck-file-container" id="company-details-intro-deck-file-container"
                  class="file-container">
                  <div name="company-details-intro-deck-file-container" id="company-details-intro-deck-file-container"
                    class="files">
                    <div name="company-details-intro-deck-file-container" id="company-details-intro-deck-file-container"
                      class="file-scroll-box">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="introDeck">
                          {{ introDeck.name }} <button type="button" name="button" class="btn"
                            (click)="CancelMedia('',3)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="company-details-intro-deck-vIntroDeck-1" id="company-details-intro-deck-vIntroDeck-1"
                  matInput readonly [value]="introDeck ? ' ' : ''" formControlName="vIntroDeck"
                  placeholder="Upload Intro Deck" />
                <input matSuffix type="file" #uploadFile name="company-details-intro-deck-vIntroDeck-2"
                  id="company-details-intro-deck-vIntroDeck-2" class="file-upload-input"
                  (change)="FileBrowseHandler($event,3)" formControlName="vIntroDeck" accept="application/pdf" />
                <button name="company-details-intro-deck-upload-btn" id="company-details-intro-deck-upload-btn"
                  class="upload-btn" matSuffix><img id="company-details-file-upload-icon"
                    [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-error class="reduce-bottom-space" *ngIf="companyDetailsForm.controls['vIntroDeck'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDetailsForm.controls['vIntroDeck'].errors!.required">
                    Intro Deck is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 10MB PDF required</mat-hint>
              </mat-form-field>
              <div name="cin-certi-image-display" id="cin-certi-image-display" class="col-12"
              *ngIf="companyDetailsArray.intro_deck">
              <div name="cin-certi-image-class-1" id="cin-certi-image-class-1"
                class="image-display-container margin-class">
                <div name="cin-certi-name-1" id="cin-certi-name-1" class="image-name">
                  <!-- <label>1)</label> -->
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="companyDetailsArray.intro_deck_url" target="_blank">{{
                    companyDetailsArray.intro_deck }}</a>
                </div>
              </div>
            </div>
            </div>
            <div class="col-12 col-md-6">
              <label for="vSize" name="company-details-size-label" id="company-details-size-label"
                 class="custom-label">Size <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker class="custom-dropdown custom-input form" floatLabel="never" floatPlaceholder="never" name="company-details-size-select"
                id="company-details-size-select">
                <mat-select id="vSize" formControlName="vSize" placeholder="Select size" required>
                  <mat-option *ngFor="let item of sizeListArray; let i = index" [value]="item.id">{{ item.name }}
                  </mat-option>
                </mat-select>
                <mat-error class="reduce-bottom-space" *ngIf="companyDetailsForm.controls['vSize'].invalid">
                  <mat-error class="reduce-bottom-space" *ngIf="companyDetailsForm.controls['vSize'].errors?.required">
                    Size is required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <label for="vVideo" name="company-details-video-label" id="company-details-video-label"
                 class="custom-label">Video</label>
              <mat-form-field hideRequiredMarker name="company-details-video-input" id="company-details-video-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="video">
                          {{ video.name }} <button type="button" name="button" class="btn" (click)="CancelMedia('',2)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="company-details-video-input-vVideo-1" id="company-details-video-input-vVideo-1" matInput
                  readonly [value]="video ? ' ' : ''" formControlName="vVideo" placeholder="Upload Video" />
                <input matSuffix type="file" #uploadFile name="company-details-video-input-vVideo-2"
                  id="company-details-video-input-vVideo-2" class="file-upload-input"
                  (change)="FileBrowseHandler($event,2)" formControlName="vVideo" accept="video/*" />
                <button name="company-details-video-input-upload-btn" id="company-details-video-input-upload-btn"
                  class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <div class="input-details-box">
                  <label class="text">Max 50MB</label>
                </div>
              </mat-form-field>
            </div>
            <div name="company-details-video-display" id="company-details-video-display" class="col-12"
              *ngIf="companyDetailsArray?.video_url">
              <div name="company-details-video-class-1" id="company-details-video-class-1"
                class="image-display-container margin-class">
                <div name="company-details-name-1" id="company-details-name-1"
                  class="image-name  d-flex align-items-center">
                  <label for="video">1)</label>
                  <div class="image-thumbnail d-flex justify-content-center align-items-center">
                    <mat-icon>video_file</mat-icon>
                  </div>
                  <a href="{{companyDetailsArray?.video_url}}"> {{ companyDetailsArray?.video }}</a>
                </div>
                <button type="button" name="button" (click)="companyDetailVideo(companyDetailsArray.video)">
                  <img [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <label for="vImages" name="company-details-image-label" id="company-details-image-label"
                 class="custom-label">Images</label>
              <mat-form-field hideRequiredMarker name="company-details-image-input" id="company-details-image-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box" *ngIf="mediaArray?.length <= 5">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngFor="let file of mediaArray,let i = index">
                          {{ file.name }} <button type="button" name="button" class="btn" (click)="CancelMedia(i,1)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="company-details-image-vImages-1" id="company-details-image-vImages-1" matInput readonly
                  [value]="mediaArray?.length > 0 ? ' ' : ''" formControlName="vImages" placeholder="Upload Image" />
                <input matSuffix type="file" #uploadFile name="company-details-image-vImages-2"
                  id="company-details-image-vImages-2" class="file-upload-input" (change)="FileBrowseHandler($event,1)"
                  formControlName="vImages" accept="image/*" multiple />
                <button name="company-details-image-upload-btn" id="company-details-image-upload-btn" class="upload-btn"
                  matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <div class="input-details-box">
                  <label class="text">Max 5 images (4MB each)</label>
                </div>
              </mat-form-field>
            </div>
            <div name="company-details-image-display" id="company-details-image-display" class="col-12"
              *ngIf="companyDetailsArray?.imagesUrl?.length > 0">
              <div name="company-details-image-class-1" id="company-details-image-class-1"
                class="image-display-container margin-class"
                *ngFor="let ele of companyDetailsArray?.imagesUrl;let i=index">
                <div name="company-details-name-1" id="company-details-name-1" class="image-name">
                  <label for="image">{{i+1}})</label>
                  <img class="image-thumbnail" [src]="ele?.vMediaUrl" alt="images">
                  <label>{{ ele?.vMediaName }}</label>
                </div>
                <button type="button" name="button" (click)="companyDetailImageThumbnail(ele.vMediaName)">
                  <img id="company-details-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button>
              </div>
            </div>
            <div class="col-12">
              <label for="vTechnology" name="company-details-technology-label" id="company-details-technology-label"
                 class="custom-label">Technology <span class="text-danger">*</span></label>
  
              <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never"
                name="company-details-technology-input" id="company-details-technology-input"
                class="custom-dropdown custom-input form">
                <mat-chip-list #technologyChipList aria-label="Technology selection" class="custom-multi-chip-dropdown"
                  formControlName="vTechnology">
                  <mat-chip *ngFor="let technology of technologies" [removable]="true" (removed)="OnTechnologyRemoved(technology)">
                    {{technology.vTechnologyName}}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-chip>
                  <input matInput #technologyInput (keyup)="_filterTechnology(technologyInput.value)" [matAutocomplete]="autoTechnology"
                    [matChipInputFor]="technologyChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="technologyInput.value = ''">
                </mat-chip-list>
                <mat-autocomplete #autoTechnology="matAutocomplete" [displayWith]="DisplayFnTechnology"
                  (optionSelected)="AddTechnology($event.option.value); technologyInput.value = ''">
                  <mat-option *ngFor="let technology of filterTechnologies" [value]="technology">
                    {{technology.vTechnologyName}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error class="reduce-bottom-space" *ngIf="companyDetailsForm.controls['vTechnology'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDetailsForm.controls['vTechnology'].errors!.required">
                    Technology is required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <label for="vIndustryDetails" name="company-details-industry-details-label"
                id="company-details-industry-details-label"  class="custom-label">Industry details <span
                  class="text-danger">*</span></label>
              
              <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never"
                name="company-details-industry-details-input" id="company-details-industry-details-input"
                class="custom-dropdown custom-input form">
                <mat-chip-list #industryChipList aria-label="Industry selection" class="custom-multi-chip-dropdown"
                  formControlName="vIndustryDetails">
                  <mat-chip *ngFor="let industry of industries" [removable]="true" (removed)="OnIndustryRemoved(industry)">
                    {{industry.vIndustryName}}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-chip>
                  <input matInput #industryInput (keyup)="_filterIndustry(industryInput.value)" [matAutocomplete]="autoIndustry"
                    [matChipInputFor]="industryChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="industryInput.value = ''">
                </mat-chip-list>
                <mat-autocomplete #autoIndustry="matAutocomplete" [displayWith]="DisplayFnIndustry"
                  (optionSelected)="AddIndustry($event.option.value); industryInput.value = ''">
                  <mat-option *ngFor="let industry of filterIndustries" [value]="industry">
                    {{industry.vIndustryName}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error class="reduce-bottom-space" *ngIf="companyDetailsForm.controls['vIndustryDetails'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDetailsForm.controls['vIndustryDetails'].errors!.required">
                    Industry details is required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <label for="vServiceDetails" name="company-details-service-details-label"
                id="company-details-service-details-label"  class="custom-label">Service details <span
                  class="text-danger">*</span></label>
  
              <mat-form-field hideRequiredMarker floatPlaceholder="never" floatLabel="never"
                name="company-details-service-details-input" id="company-details-size-input"
                class="custom-dropdown custom-input form">
                <mat-chip-list #serviceChipList aria-label="Service selection" class="custom-multi-chip-dropdown"
                  formControlName="vServiceDetails">
                  <mat-chip *ngFor="let service of services" [removable]="true" (removed)="OnServiceRemoved(service)">
                    {{service.vServiceName}}
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-chip>
                  <input matInput #serviceInput (keyup)="_filterService(serviceInput.value)" [matAutocomplete]="autoService"
                    [matChipInputFor]="serviceChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="serviceInput.value = ''">
                </mat-chip-list>
                <mat-autocomplete #autoService="matAutocomplete" [displayWith]="DisplayFnService"
                  (optionSelected)="AddService($event.option.value); serviceInput.value = ''">
                  <mat-option *ngFor="let service of filterServices" [value]="service">
                    {{service.vServiceName}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error class="reduce-bottom-space" *ngIf="companyDetailsForm.controls['vServiceDetails'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDetailsForm.controls['vServiceDetails'].errors!.required">
                    Service details is required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div name="company-details-modal-footer" id="company-details-modal-footer" class="modal-footer">
        <button type="button" name="company-details-btn-cancel" id="company-details-btn-cancel"
          class="blue-secondary-small-btn mx-2"
          (click)="this.companyDetailsToggle=false; this.companyDetailsForm.reset(); this.companyDetailsSubmitted = false; this.GetCompanyDetails();">Cancel</button>
        <button type="submit" name="company-details-btn-submit" id="company-details-btn-submit" class="blue-primary-small-btn"
          form="companyDetailsForm">Save</button>
      </div>
    </div>
  </div>
</div>
