import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EventEmitterService } from "../../config/event-emitter.service";
import { ParentPermissionManagementService } from "./parent-permission-management.service";
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { NotificationService } from "src/app/config/notification.service";

@Component({
  selector: "app-parent-permission-management",
  templateUrl: "./parent-permission-management.component.html",
})
export class ParentPermissionManagementComponent implements OnInit {
  // toggle actions permission
  addParentPermissionActionPermission: boolean = false;
  editParentPermissionActionPermission: boolean = false;
  deleteParentPermissionActionPermission: boolean = false;

  addParentPermissionForm!: FormGroup;
  editParentPermissionForm!: FormGroup;
  parentPermissionId: number;

  typeList: any = [
    { id: 1, typeName: "Admin" },
    { id: 3, typeName: "Partner" },
    { id: 4, typeName: "Client" }
  ];

  displayedColumnsTableOne: string[] = ["indexNumber", "parentPermissionName", "parentPermissionType", "createdAt", "actions"];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild("TableOnePaginator", { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort", { static: true }) tableOneSort: MatSort;

  constructor(private parentPermissionManagementService: ParentPermissionManagementService, private notifyService: NotificationService,public eventEmitterService: EventEmitterService, private modalService: NgbModal, config: NgbModalConfig, private userPermissionService: UserPermissionService) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const AddParentPermissionAction: Permission = Permission.AddParentPermissionAction;
    const EditParentPermissionAction: Permission = Permission.EditParentPermissionAction;
    const DeleteParentPermissionAction: Permission = Permission.DeleteParentPermissionAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddParentPermissionAction)) {
      this.addParentPermissionActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditParentPermissionAction)) {
      this.editParentPermissionActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeleteParentPermissionAction)) {
      this.deleteParentPermissionActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetParentPermissions();
    this.addParentPermissionForm = new FormGroup({
      parentPermissionName: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      parentPermissionType: new FormControl("", [Validators.required]),
    });

    this.editParentPermissionForm = new FormGroup({
      parentPermissionName: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      parentPermissionType: new FormControl("", [Validators.required]),
    });
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  OpenAddParentPermissionModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  OpenEditTechnologyModel(modelName: any, element: any, hasPermission: boolean) {
    if (hasPermission) {
      this.parentPermissionId = element["iParentFeatureId"];
      this.editParentPermissionForm.patchValue({
        parentPermissionName: element["vParentFeature"],
        parentPermissionType: element["tiType"],
      });
      this.modalService.open(modelName, { centered: true });
    }
  }

  OpenDeleteParentPermission(modalToOpen: any, element: any, hasPermission: boolean){
    if (hasPermission) {
      // console.log(element);
      this.parentPermissionId = element["iParentFeatureId"];
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  GetParentPermissions() {
    this.parentPermissionManagementService
      .GetParentPermissions()
      .then((result: any) => {
        if (result["code"] == 200) {
          this.dataSourceTableOne.data = result['data'];
        } else {
          this.notifyService.showError(result.message,'');
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  AddParentPermission() {
    if (this.addParentPermissionForm.invalid) {
      return;
    }

    let reqData = {
      vParentFeature: this.addParentPermissionForm.value.parentPermissionName,
      tiType: this.addParentPermissionForm.value.parentPermissionType,
    };

    this.parentPermissionManagementService
      .AddParentPermission(reqData)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.addParentPermissionForm.reset();
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({ msg: result.message, status: 0 });
          this.GetParentPermissions();
        } else {
          this.notifyService.showError(result.message,'');
        }
      })
      .catch((err: any) => {
        console.log(err, "AddParentPermission");
      });
  }

  EditParentPermission() {
    if (this.editParentPermissionForm.invalid) {
      return;
    }

    let reqData = {
      iParentFeatureId: this.parentPermissionId,
      vParentFeature: this.editParentPermissionForm.value.parentPermissionName,
      tiType: this.editParentPermissionForm.value.parentPermissionType,
    };

    this.parentPermissionManagementService
      .EditParentPermission(reqData)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.eventEmitterService.modelMessage({ msg: result.message, status: 0 });
          this.modalService.dismissAll();
          this.GetParentPermissions();
        } else {
          this.modalService.dismissAll();
          this.notifyService.showError(result.message,'');
        }
      })
      .catch((err: any) => {
        console.log(err, "EditParentPermission");
      });
  }

  DeleteParentPermission(parentPermissionId: any): void {
    this.parentPermissionManagementService
      .DeleteParentPermission(parentPermissionId)
      .then((result: any) => {
        this.GetParentPermissions();
        this.eventEmitterService.modelMessage({ msg: result.message, status: 0 });
        this.modalService.dismissAll();
      })
      .catch((err: any) => {
        console.log(err, "DeleteParentPermission");
      });
  }
}
