<div class="container-fluid skill-management">
  <div class="row">
    <div class="col-12">
      <div class="wrapper">
        <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="title" role="tabpanel" aria-labelledby="skill-management-tab">
            <div class="pagetitle-wrapper">
              <div
                class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                <div class="d-flex align-items-center">
                  <i class="pagetitle-icon"><span class="material-icons">ballot</span></i>
                  <h2 class="pagetitle">Contract</h2>
                </div>
              </div>
              <div class="d-flex">
                <mat-form-field class="w-75" floatLabel="never" class="table-filter">
                  <button matSuffix mat-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <input matInput placeholder="Search Contract" (keyup)="applyFilterOne($event)" />
                </mat-form-field>
                <mat-form-field class="table-filter w-25">
                  <mat-select placeholder="Select Contract Status" [(value)]="filterType"
                    (selectionChange)="selectContractType()">
                    <mat-option value="0">All</mat-option>
                    <mat-option *ngFor="let status of contractStatus" [value]="status.id">
                      {{status.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="table-responsive">
                <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                </div>
                <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }"
                  [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                  <ng-container matColumnDef="indexNumber">
                    <th mat-header-cell *matHeaderCellDef> No </th>
                    <td mat-cell *matCellDef="let i = index;">
                      {{ this.TableOnePaginator.pageIndex == 0 ? i+1 : i+ 1 + this.TableOnePaginator.pageIndex *
                      this.TableOnePaginator.pageSize}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vContractName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract Name</th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.vContractName}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vClientCompanyName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.vClientCompanyName}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vPartnerCompanyName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Partner</th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.vPartnerCompanyName}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="vEmployeeName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee</th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.vFirstName}} {{element.vLastName}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="iCreatedAt">
                    <th mat-header-cell *matHeaderCellDef>Created On</th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.iCreatedAt * 1000 | date:'dd MMM, yyyy HH:mm'}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="iContractStartDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.iContractStartDate * 1000 | date:'dd MMM, yyyy'}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="iHiringDuration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
                    <td mat-cell *matCellDef="let element">
                      <span>{{element.iHiringDuration}} Month(s)</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="tiContractStatus">
                    <th mat-header-cell *matHeaderCellDef> Contract Status </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-chip-list>
                        <mat-chip class="blue-chip">
                          {{contractStatus[element.tiContractStatus].value}}
                        </mat-chip>
                      </mat-chip-list>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex flex-wrap">
                        <button *ngIf="element.tiContractStatus == 0 && element.tiStatus == 0" class="status-btn lightgreen-bg green-text mb-1" placement="left auto" [ngbTooltip]="approveContractActionPermission ? '':'Not Authorized'" [ngClass]="approveContractActionPermission ? '':'unauthorized-cursor'"
                          (click)="OpenAcceptContractModal(acceptContractModel, element, approveContractActionPermission)">
                          <span class="material-icons">check_circle</span>
                        </button>
                        <button *ngIf="element.tiContractStatus == 0 && element.tiStatus == 0" class="status-btn lightpink-bg pink-text" placement="left auto" [ngbTooltip]="rejectContractActionPermission ? '':'Not Authorized'" [ngClass]="rejectContractActionPermission ? '':'unauthorized-cursor'"
                          (click)="OpenRejectContractModal(rejectContractModel, element, rejectContractActionPermission)">
                          <span class="material-icons">cancel</span>
                        </button>
                        <span *ngIf="element.tiStatus == 1 && !element.uploadedContractStatus" class="status-btn lightgray-bg gray-text"><span
                            class="material-icons me-1">hide_source</span>No Action</span>
                        <button *ngIf="element.tiContractStatus == 0 && element.tiStatus == 2" class="status-btn lightpurple-bg purple-text"
                          data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{element.txRejectComment}}">
                          <span class="material-icons">info</span>
                        </button>
                        <button *ngIf="element.uploadedContractStatus" class="status-btn lightblue-bg blue-text my-1"
                        placement="left auto" [ngbTooltip]="downloadContractPDFActionPermission ? '':'Not Authorized'" [ngClass]="downloadContractPDFActionPermission ? '':'unauthorized-cursor'" (click)="DownloadUploadedContract(element.contractUrl,downloadContractPDFActionPermission)">
                          <span class="material-icons">cloud_download</span>
                        </button>
                        <button *ngIf="element.uploadedContractStatus && element.clientSignedStatus" class="status-btn lightpurple-bg purple-text my-1 d-flex align-items-center"
                        placement="left auto" [ngbTooltip]="signClientContractActionPermission ? '':'Not Authorized'" [ngClass]="signClientContractActionPermission ? '':'unauthorized-cursor'" (click)="OpenClientContractSignedModal(clientContractSignedModel, element, signClientContractActionPermission)">
                          <span class="material-icons me-1">assignment_turned_in</span> Client
                        </button>
                        <button *ngIf="element.uploadedContractStatus && element.partnerSignedStatus && !(element.clientSignedStatus)" class="status-btn lightpink-bg pink-text my-1 d-flex align-items-center"
                        placement="left auto" [ngbTooltip]="signPartnerContractActionPermission ? '':'Not Authorized'" [ngClass]="signPartnerContractActionPermission ? '':'unauthorized-cursor'" (click)="OpenPartnerContractSignedModal(partnerContractSignedModel, element,signPartnerContractActionPermission)">
                          <span class="material-icons me-1">assignment_turned_in</span> Partner
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                </table>
              </div>
              <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
                #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #acceptContractModel let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-edit add-skill-modal">
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h1 class="title d-flex justify-content-center">Are you sure?</h1>
        <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
          <button type="submit" class="modal-btn" (click)="ContractAccept(contractId)">Accept</button>
          <button type="button" class="modal-btn-outline px-4" (click)="d('Cross click')">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rejectContractModel let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-edit add-skill-modal">
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h1 class="title">Reject Contract</h1>
        <form class="form" [formGroup]="rejectContractForm" (ngSubmit)="HandleContractReject()">
          <div class="row">
            <div class="col-12">
              <label class="label">Reason To Reject</label>
              <mat-form-field hideRequiredMarker appearance="outline">
                <input type="text" formControlName="rejectComment" matInput placeholder="Reason...">
                <mat-error *ngIf="rejectContractForm.controls['rejectComment'].invalid && (rejectContractForm.controls['rejectComment'].dirty || rejectContractForm.controls['rejectComment'].touched)">
                  <mat-error *ngIf="rejectContractForm.controls['rejectComment'].errors.required">Reason is required</mat-error>
                  <mat-error *ngIf="rejectContractForm.controls['rejectComment'].errors?.minlength"> Minimum 3 character required </mat-error>
                  <mat-error *ngIf="rejectContractForm.controls['rejectComment'].errors?.maxlength"> Maximum 150 character allowed </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
            <button type="submit" class="modal-btn">Reject</button>
            <button type="button" class="modal-btn-outline px-4" (click)="d('Cross click'); this.rejectContractForm.reset();">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #clientContractSignedModel let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-edit add-skill-modal">
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h1 class="title d-flex justify-content-center">Are you sure?</h1>
        <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
          <button type="submit" class="modal-btn" (click)="UpdateClientSignedStatus(contractId)">Yes</button>
          <button type="button" class="modal-btn-outline px-4" (click)="d('Cross click')">No</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #partnerContractSignedModel let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-edit add-skill-modal">
    <div class="d-flex align-items-center content">
      <div class="w-100">
        <h1 class="title d-flex justify-content-center">Are you sure?</h1>
        <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
          <button type="submit" class="modal-btn" (click)="UpdatePartnerSignedStatus(contractId)">Yes</button>
          <button type="button" class="modal-btn-outline px-4" (click)="d('Cross click')">No</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>