import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CertificationsService {
  constructor(private http: HttpClient) {}

  GetCertificate() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/list/emp-certificate`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UploadCertficate(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/upload-certificate?iUserId=${talentUserId}`;
      const formData: FormData = new FormData();
      formData.append("vCerId", data.vCerId);
      formData.append("vCerDocument", data.vCerDocument);
      formData.append("vValue", data.vValue);
      this.http
        .post(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetUploadedCertificate(talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/certificate?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteUploadedCertificate(iMasterCerId: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/certificate/${iMasterCerId}?iUserId=${talentUserId}`;
      this.http
        .delete(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetTypeofCertificate(certificateId: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/type-wise-certificate/${certificateId}?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteTypeofCertificate(certificateId: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/type-wise-certificate/${certificateId}?iUserId=${talentUserId}`;
      this.http
        .delete(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  CheckApplicable(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/check-applicable?iUserId=${talentUserId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCheckApplicable(talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/check-applicable?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
