import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AbstractControl, FormGroup, FormControl, Validators } from "@angular/forms";
import Validation from "../../utils/validation";
import { ChangePasswordService } from "./change-password.service";
import { EventEmitterService } from "../../config/event-emitter.service";
import { NavService } from "../layouts/nav.service";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { CookieService } from "ngx-cookie-service";
import { NotificationService } from "src/app/config/notification.service";
import { TranslateFormService } from "src/app/utils/services/translate-form.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm!: FormGroup;
  submitted = false;
  hideCurrentPassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;

  constructor(private notifyService : NotificationService, private tf: TranslateFormService,private router: Router, private changePasswordService: ChangePasswordService, public eventEmitterService: EventEmitterService, private navService: NavService, private userPermissionService: UserPermissionService, private cookieService: CookieService) {}

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup(
      {
        currentPassword: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
        newPassword: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
        confirmPassword: new FormControl("", [Validators.required]),
        vEmailId: new FormControl(""),
      },
      { validators: [Validation.match("newPassword", "confirmPassword")] }
    );
  }

  get has(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls;
  }

  UserCheck() {
    let roleId = localStorage.getItem("sub_admin_role");
    let userType = localStorage.getItem("sub_admin_account_type");

    if (roleId && userType) {
      this.HandleSubUserChangePassword();
    } else {
      this.HandleChangePassword();
    }
  }

  async HandleChangePassword() {
    this.submitted = true;

    if (this.changePasswordForm.invalid) {
      return;
    }

    let eData:any= await this.tf.dynamicPush(JSON.stringify(this.changePasswordForm.value));

      let reqJSON = {
        data: eData,
      };
    this.changePasswordService
      .ChangePassword(reqJSON)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.notifyService.showSuccess(result.message, "");
          // this.eventEmitterService.modelMessage({ msg: result.message, status: 0 });
          setTimeout(() => {
            this.Logout();
          }, 3010);
        } else {
          this.notifyService.showError(result.message, "");
          // this.notifyService.showError(result.message,'');
          throw result;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  async HandleSubUserChangePassword() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    if (this.changePasswordForm.value.currentPassword == this.changePasswordForm.value.newPassword) {
      // this.eventEmitterService.modelMessage({ msg: "The new password you entered is the same as your old password. Enter a different password.", status: 1 });
      this.notifyService.showSuccess("The new password you entered is the same as your old password. Enter a different password.", "");
      return;
    }
    let subUserEmail = localStorage.getItem("sub_admin_email");
    this.changePasswordForm.controls["vEmailId"].setValue(subUserEmail);

    let eData:any= await this.tf.dynamicPush(JSON.stringify(this.changePasswordForm.value));

      let reqJSON = {
        data: eData,
      };
    this.changePasswordService
      .SubUserChangePassword(reqJSON)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.notifyService.showSuccess(result.message, "");
          // this.eventEmitterService.modelMessage({ msg: result.message, status: 0 });
          setTimeout(() => {
            this.Logout();
          }, 3010);
        } else {
          this.notifyService.showError(result.message, "");
          // this.notifyService.showError(result.message,'');
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  Logout() {
    let device_id = localStorage.getItem("admin_device_id");
    this.navService.logoutAPI({ vImei: device_id }).subscribe((res: any) => {
      this.navService.Logout();
      this.userPermissionService.ResetPermissions();
      localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_type");
        localStorage.removeItem("admin_user_email");
        localStorage.removeItem("sub_admin_account_type");
        localStorage.removeItem("sub_admin_email");
        localStorage.removeItem("sub_admin_role");
        localStorage.removeItem("admin_zendesk_token");
        localStorage.removeItem("admin_device_id");
      this.cookieService.delete("admin_user");
      this.router.navigate(["/login"]);
    });
  }
}
