import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventEmitterService } from "../../config/event-emitter.service";
import { NotificationService } from "../../config/notification.service";
import { environment } from "./../../../environments/environment";
import { SubUserService } from "./sub-user.service";
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";

@Component({
  selector: 'app-sub-user',
  templateUrl: './sub-user.component.html',
  styles: [
  ]
})
export class SubUserComponent implements OnInit {
  // toggle actions permission
  addSubUserActionPermission: boolean = false;
  editSubUserActionPermission: boolean = false;
  deleteSubUserActionPermission: boolean = false;
  resendSubUserInviteLinkActionPermission: boolean = false;
  activateSubUserActionPermission: boolean = false;
  deactivateSubUserActionPermission: boolean = false;

  imagePath = environment.image_path;

  data: any = [];

  userTableColumns: string[] = ["sr", "vEmailId", "userName", "dateOfInvitation","vRoleName","tiSubUserStatus", "action"];
  RoleTableDataSource: any=[];
  RoleTableCurrentPage: number = 1;
  RoleTableSize: number = 5;
  RoleTablePageIndex = 0;

  //Temporary Variables
  editUserId:any;
  resendUserEmail:any;
  deleteUserId:any;
  statusChangeId:any;

  //Filter Variables
  statusFilterValue:any="";
  searchFilterValue:any="";


  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  userStatusList: any = [
    { id: "", vName: "All" },
    { id: 1, vName: "Pending" },
    { id: 2, vName: "Active" }, 
    { id: 3, vName: "Inactive" },
  ];

  roleList: any = [];

  permissionList: any = {};

  RoleTableItemCount: any = [
    { id: 1, vName: "5" },
    { id: 2, vName: "10" },
    { id: 3, vName: "15" },
    { id: 4, vName: "20" }
  ];

  defaultRoleTableDropdown = 1;

  addUsersForm!: FormGroup;
  editUsersForm!: FormGroup;

  constructor(config: NgbModalConfig, private modalService: NgbModal, private roleService: SubUserService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService, private userPermissionService: UserPermissionService) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const AddSubUserAction: Permission = Permission.AddSubUserAction;
    const EditSubUserAction: Permission = Permission.EditSubUserAction;
    const DeleteSubUserAction: Permission = Permission.DeleteSubUserAction;
    const ResendSubUserInviteLinkAction: Permission = Permission.ResendSubUserInviteLinkAction;
    const ActivateSubUserAction: Permission = Permission.ActivateSubUserAction;
    const DeactivateSubUserAction: Permission = Permission.DeactivateSubUserAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddSubUserAction)) {
      this.addSubUserActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditSubUserAction)) {
      this.editSubUserActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeleteSubUserAction)) {
      this.deleteSubUserActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ResendSubUserInviteLinkAction)) {
      this.resendSubUserInviteLinkActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ActivateSubUserAction)) {
      this.activateSubUserActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeactivateSubUserAction)) {
      this.deactivateSubUserActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetAllSubUsers();
    this.GetRoles();

    this.addUsersForm = new FormGroup({
      vFirstName: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46), Validators.pattern("[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]),
      vLastName: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46), Validators.pattern("[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]),
      vEmailId: new FormControl("", [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      iRoleId: new FormControl("", Validators.required),
    });

    this.editUsersForm = new FormGroup({
      vFirstName: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46), Validators.pattern("[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]),
      vLastName: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46), Validators.pattern("[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$")]),
      vEmailId: new FormControl("", [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      iRoleId: new FormControl("", Validators.required),
    });
  }

  paginate(event: any) {
    this.RoleTablePageIndex = event;
    this.RoleTableDataSource = this.data.slice(event * this.RoleTableSize - this.RoleTableSize, event * this.RoleTableSize);
  }

  RoleTableItemSize(event: any) {
    switch (event.value) {
      case 1:
        this.RoleTableSize = 5;
        break;
      case 2:
        this.RoleTableSize = 10;
        break;
      case 3:
        this.RoleTableSize = 15;
        break;
      case 4:
        this.RoleTableSize = 20;
        break;
      default:
        this.RoleTableSize = 5;
    }
  }

  AddUser(){
    if(this.addUsersForm.invalid){
      return;
    }

    let reqJson={
      vEmailId: this.addUsersForm.controls['vEmailId'].value,
      vFirstName: this.addUsersForm.controls['vFirstName'].value,
      vLastName: this.addUsersForm.controls['vLastName'].value,
      iRoleId: this.addUsersForm.controls['iRoleId'].value,
    }
    this.roleService.AddSubUser(reqJson).then((result: any) => {
      if (result.code == 200) {
        this.addUsersForm.reset();
        this.modalService.dismissAll();
        this.GetAllSubUsers();
        this.notifyService.showSuccess("Sub User Created Successfully", "");
      }else{
        this.notifyService.showError(result.message, "");
      }
    });
  }

  EditUser(){
    if(this.editUsersForm.invalid){
      return;
    }

    let reqJson={
      vFirstName: this.editUsersForm.controls['vFirstName'].value,
      vLastName: this.editUsersForm.controls['vLastName'].value,
      iRoleId: this.editUsersForm.controls['iRoleId'].value,
    }
    this.roleService.EditSubUser(reqJson,this.editUserId).then((result: any) => {
      if (result.code == 200) {
        this.editUserId=undefined;
        this.editUsersForm.reset();
        this.modalService.dismissAll();
        this.GetAllSubUsers();
        this.notifyService.showSuccess("Sub User Edited Successfully", "");
      }
    });

  }

  AddUsersModal(modelName: any, hasPermission: boolean) {
    if (hasPermission) {
      this.permissionList={};
      this.modalService.open(modelName, { centered: true, windowClass: "profile-modal"});
    }
  }


  EditUsersModal(modelName: any,obj:any, hasPermission: boolean) {
    if (hasPermission) {
      this.editUserId=obj.iSubUserId;
      this.permissionList={};
      this.roleService.GetSubUserById(this.editUserId).then((result: any) => {
        if (result.code == 200) {
          this.editUsersForm.controls['vFirstName'].setValue(result.data.vFirstName);
          this.editUsersForm.controls['vLastName'].setValue(result.data.vLastName);
          this.editUsersForm.controls['vEmailId'].setValue(result.data.vEmailId);
          this.editUsersForm.controls['iRoleId'].setValue(result.data.iRoleId);
          this.GetPermissionsByRoleId(this.editUsersForm.controls['iRoleId'].value);
          this.modalService.open(modelName, { centered: true, windowClass: "profile-modal"});
        }
      });
    }
  }

  ResendLinkModal(modelName: any,obj:any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modelName, { centered: true, windowClass: "profile-modal" });
      this.resendUserEmail=obj.vEmailId;
    }
  }

  ResendInvite(){
    let reqJson={
      vEmailId:this.resendUserEmail
    }
    this.roleService.ResendInvite(reqJson).then((result: any) => {
      if (result.code == 200) {
        this.resendUserEmail=undefined;
        this.modalService.dismissAll();
        this.GetAllSubUsers();
        this.notifyService.showSuccess("Invite Sent Successfully", "");
      }
    });
  }

  DeleteUserModal(modelName: any,obj:any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modelName, { centered: true, windowClass: "profile-modal" });
      this.deleteUserId=obj.iSubUserId;
    }
  }

  DeleteSubUser(){
    this.roleService.DeleteSubUser(this.deleteUserId).then((result: any) => {
      if (result.code == 200) {
        this.deleteUserId=undefined;
        this.modalService.dismissAll();
        this.GetAllSubUsers();
        this.notifyService.showSuccess("User Deleted Successfully", "");
      }
    });
  }

  ActivateUserModal(modelName: any,obj:any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modelName, { centered: true, windowClass: "profile-modal" });
      this.statusChangeId=obj.iSubUserId;
    }
  }

  DeactivateUserModal(modelName: any,obj:any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modelName, { centered: true, windowClass: "profile-modal" });
      this.statusChangeId=obj.iSubUserId;
    }
  }

  ActivateSubUser(){
    let reqJson={
      tiSubUserStatus:2
    }

    this.roleService.ChangeSubUserStatus(reqJson,this.statusChangeId).then((result: any) => {
      if (result.code == 200) {
        this.statusChangeId=undefined;
        this.modalService.dismissAll();
        this.GetAllSubUsers();
        this.notifyService.showSuccess("User Activated Successfully", "");
      }
    });
  }

  DeactivateSubUser(){
    let reqJson={
      tiSubUserStatus:3
    }

    this.roleService.ChangeSubUserStatus(reqJson,this.statusChangeId).then((result: any) => {
      if (result.code == 200) {
        this.statusChangeId=undefined;
        this.modalService.dismissAll();
        this.GetAllSubUsers();
        this.notifyService.showSuccess("User Deactivated Successfully", "");
      }
    });
  }

  StatusFilter(event:any){
    this.GetAllSubUsers();
  }

  SearchFilter(event:any){
    this.searchFilterValue = event.target.value;
    this.GetAllSubUsers();
  }


  onRoleSelection(event:any){
    this.addUsersForm.controls['iRoleId'].setValue(event.value);
    this.GetPermissionsByRoleId(event.value);
  }

  onRoleSelectionInEdit(event:any){
    this.editUsersForm.controls['iRoleId'].setValue(event.value);
    this.GetPermissionsByRoleId(event.value);
  }

  GetAllSubUsers(){
    this.roleService.GetAllSubUsers(this.searchFilterValue,this.statusFilterValue).then((result: any) => {
      if (result.code == 200) {
        this.data=result.data;
        this.RoleTableDataSource=result.data;
      }
    });
  }

  GetRoles(){
    this.roleService.GetRoles().then((result: any) => {
      if (result.code == 200) {
        this.roleList=result['data'];
        console.log(this.roleList);
        
      }
    });
  }

  GetPermissionsByRoleId(iRoleId:any){
    this.roleService.GetPermissionsByRoleId(iRoleId).then((result: any) => {
      if (result.code == 200) {
        this.permissionList=result.data;
      }
    });
  }

}
