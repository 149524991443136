import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { PersonalDetailsService } from "./personal-details.service";
import { NotificationService } from "src/app/config/notification.service";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { SearchCountryField, CountryISO, PhoneNumberFormat } from "ngx-intl-tel-input";
import { StaticMsg } from "../../../../config/en";
import { environment } from "../../../../../environments/environment";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UserPermissionService } from "src/app/utils/services/user-permission.service";
import { Permission } from "src/app/utils/enums/permission.enum";

function subtractYears(numOfYears: any, date = new Date()) {
  const dateCopy = new Date(date.getTime());
  dateCopy.setFullYear(dateCopy.getFullYear() - numOfYears);
  return dateCopy;
}

@Component({
  selector: "talent-personal-details",
  templateUrl: "./personal-details.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class TalentPersonalDetailsComponent implements OnInit {
  @Input() talentUserId:any;
  personalDetailsToggle:boolean=false;

  editTalentPersonalDetailsActionPermission: boolean = false;

  imagePath = environment.image_path;
  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  personalDetailsForm!: FormGroup;
  personalDetailsSubmitted: any = false;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
  maxLength: any = "15";

  genderListArray: any = [
    { id: 1, vName: "Male" },
    { id: 2, vName: "Female" },
    { id: 3, vName: "Other" },
  ];
  countryListArray: any = [];
  filteredCountryListArray!: Observable<any[]>;
  countryId: any;
  stateListArray: any = [];
  filteredStateListArray!: Observable<any[]>;
  stateId: any;
  cityListArray: any = [];
  filteredCityListArray!: Observable<any[]>;
  cityId: any;
  companyDetailsArray: any = [];
  hobbiesListArray: any = [];
  ProfilePhoto: any;
  IntroVideo: any;
  @ViewChild("uploadFile") fileInput: any;
  fileAttr: any;
  videoError: any = false;
  PersonalDetail: any;
  profilePhotoShow: any;
  editPersonalData: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  hobbies: any = [];
  allHobbies: any = [];
  filterHobbies: any;

  public todaya: Date = new Date();
  public result = subtractYears(18, this.todaya);

  public today = this.result;

  profileImageError = true;

  constructor(private userPermissionService: UserPermissionService,config: NgbModalConfig, private modalService: NgbModal, public eventEmitterService: EventEmitterService, private personalDetailsService: PersonalDetailsService, private notifyService: NotificationService) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const EditTalentPersonalDetailsAction: Permission = Permission.EditTalentPersonalDetailsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(EditTalentPersonalDetailsAction)) {
      this.editTalentPersonalDetailsActionPermission = true;
    }

    this.filterHobbies = this.hobbiesListArray;
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });

    this.personalDetailsForm = new FormGroup({
      newImage: new FormControl("", []),
      vEmpFirstName: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46), Validators.pattern("^[a-zA-Z]+[a-zA-Z ]*$")]),
      vEmpLastName: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46), Validators.pattern("^[a-zA-Z]+[a-zA-Z ]*$")]),
      vEmailId: new FormControl("", [Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]),
      vGender: new FormControl("", [Validators.required]),
      iBirthDate: new FormControl("", [Validators.required]),
      iPhoneNumber: new FormControl("", [Validators.required, Validators.minLength(7), Validators.maxLength(15)]),
      vVideo: new FormControl("", []),
      vAddress: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(255)]),
      iCountryId: new FormControl("", [Validators.required]),
      iStateId: new FormControl("", [Validators.required]),
      iCityId: new FormControl("", [Validators.required]),
      iZipCode: new FormControl("", [Validators.required, Validators.pattern("^[a-zA-z0-9]+$"), Validators.minLength(5), Validators.maxLength(10)]),
      iHobbies: new FormControl("", [Validators.required]),
      iEmpBio: new FormControl("", [Validators.required, Validators.minLength(150)]),
    });

    this.GetCountryList();
    this.GetHobbyList();
    this.GetPersonalDetail();

    this.filteredCountryListArray = this.personalDetailsForm.controls["iCountryId"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value, this.countryListArray, "vName") : this.countryListArray.slice()))
    );

    this.filteredStateListArray = this.personalDetailsForm.controls["iStateId"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value, this.stateListArray, "vName") : this.stateListArray.slice()))
    );

    this.filteredCityListArray = this.personalDetailsForm.controls["iCityId"].valueChanges.pipe(
      startWith(""),
      map((value: string) => (value ? this._filter(value, this.cityListArray, "vName") : this.cityListArray.slice()))
    );
  }

  private _filter(value: string, array: any, key: any): string[] {
    let filterValue: string;
    if (value) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = "";
    }

    return array.filter((option: any) => option[key].toLowerCase().indexOf(filterValue) === 0);
  }

  public personalDetailsCardCompleted: any = false;
  updateProfileStatus(data: any) {
    this.personalDetailsCardCompleted = data["personalDetailsCardCompleted"];
  }

  UploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = [];
      Array.from(imgFile.target.files).forEach((file: any) => {
        this.fileAttr.push(file.name);
      });
      // HTML5 FileReader API
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          let imgBase64Path = e.target.result;
        };
      };
      reader.readAsDataURL(imgFile.target.files[0]);
      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = [];
    }
  }

  FileBrowseHandler(event: any, type: any) {
    this.videoError = false;
    if (type == 1 && (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg")) {
      this.ProfilePhoto = event.target.files[0];
    }
    if (type == 2) {
      if (event.target.files[0].type == "video/mp4") {
        if (event.target.files[0].size > 50 * 1024 * 1024) {
          // 50mb
          this.notifyService.showError(StaticMsg.VideoLargerThan50MbError, "");
          this.IntroVideo = "";
          this.personalDetailsForm.controls["vVideo"].setValue('');
          this.videoError = true;
        } else {
          this.IntroVideo = event.target.files[0];
        }
      } else {
        this.notifyService.showError(StaticMsg.NotValidVideoTypeError, "");
        this.videoError = true;
      }
    }
  }

  

  GetCountryList() {
    this.personalDetailsService.GetCountryList().then((result: any) => {
      if (result.code == 200) {
        this.countryListArray = result["data"];
      }
    });
  }

  GetStateListClick(countryId: any, fromHtml?: boolean) {
    if (fromHtml) {
      this.personalDetailsForm.controls["iStateId"].setValue("");
      this.personalDetailsForm.controls["iCityId"].setValue("");
      this.stateId = undefined;
      this.cityId = undefined;
      this.filteredCityListArray = this.personalDetailsForm.controls["iCityId"].valueChanges.pipe(
        startWith(""),
        map((value: string) => (value ? this._filter(value, this.cityListArray, "vName") : this.cityListArray.slice()))
      );
    }
    if (countryId != "default") {
      if (this.stateId == "" || this.stateId == undefined) {
        this.personalDetailsForm.patchValue({
          iCityId: "",
          iStateId: "",
        });
      }
      this.countryId = countryId;
      this.cityListArray = [];
      this.GetStateList(countryId);
    }
  }

  GetStateList(countryId: any) {
    this.personalDetailsService.GetStateList(countryId).then((result: any) => {
      if (result.code == 200) {
        this.stateListArray = result["data"];
        if (this.stateId) {
          this.stateListArray.map((obj: any) => {
            if (this.stateId == obj.iStateId) {
              this.personalDetailsForm.controls["iStateId"].setValue(obj.vName);
            }
          });
        }
        this.filteredStateListArray = this.personalDetailsForm.controls["iStateId"].valueChanges.pipe(
          startWith(""),
          map((value: string) => (value ? this._filter(value, this.stateListArray, "vName") : this.stateListArray.slice()))
        );
      }
    });
  }

  GetCityListClick(stateId: any, fromHtml?: boolean) {
    if (fromHtml) {
      this.personalDetailsForm.controls["iCityId"].setValue("");
    }
    if (stateId != "default") {
      this.stateId = stateId;
      this.GetCityList(stateId);
    }
  }

  GetCityList(stateId: any) {
    this.personalDetailsService.GetCityList(stateId).then((result: any) => {
      if (result.code == 200) {
        this.cityListArray = result["data"];
        if (this.cityId) {
          this.cityListArray.map((obj: any) => {
            if (this.cityId == obj.iCityId) {
              this.personalDetailsForm.controls["iCityId"].setValue(obj.vName);
            }
          });
        }
        this.filteredCityListArray = this.personalDetailsForm.controls["iCityId"].valueChanges.pipe(
          startWith(""),
          map((value: string) => (value ? this._filter(value,this.cityListArray,'vName') : this.cityListArray.slice()))
        );
      }
    });
  }

  AssignCity(cityId: any) {
    this.cityId = cityId;
  }

  GetHobbyList() {
    this.personalDetailsService.GetHobbyList().then((result: any) => {
      if (result.code == 200) {
        this.hobbiesListArray = result["data"];
      }
    });
  }

  countryChange(event: any) {
    if (event) {
      this.personalDetailsForm.controls.iPhoneNumber.patchValue("");
    }
  }

  // MultiSelectHobbiesCompareFn(item1: any, item2: any): boolean {
  //   return item1 && item2 ? item1.iHobbiesId === item2.iHobbiesId : item1 === item2;
  // }

  // OnHobbiesRemoved(iHobby: string) {
  //   const iHobbies = this.personalDetailsForm.value.iHobbies as string[];
  //   this.removeFirst(iHobbies, iHobby);
  //   this.personalDetailsForm.controls.iHobbies.setValue(iHobbies); // To trigger change detection
  // }

  // private removeFirst<T>(array: T[], toRemove: T): void {
  //   const index = array.indexOf(toRemove);
  //   if (index !== -1) {
  //     array.splice(index, 1);
  //   }
  // }

  private removeFirst<T>(array: any, toRemove: any): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  OnHobbiesRemoved(hobbie: string) {
    const hobbies = this.hobbies;
    this.removeFirst(hobbies, hobbie);
    this.hobbies = hobbies; // To trigger change detection

    this.hobbiesListArray.push(hobbie);

    if (this.hobbies.length == 0) {
      this.personalDetailsForm.controls["iHobbies"].setValue("");
    }
  }

  AddHobbies(hobbie: any): void {
    if (hobbie) {
      let index = this.hobbies.findIndex((x: any) => x.iHobbiesId === hobbie.iHobbiesId);
      if (index > -1) {
        return;
      } else {
        const vHobbiesName = hobbie.vHobbiesName;
        const iHobbiesId = hobbie.iHobbiesId;
        if (vHobbiesName) {
          this.hobbies.push({ vHobbiesName: vHobbiesName.trim(), iHobbiesId: iHobbiesId });
        }
        this.personalDetailsForm.controls["iHobbies"].setValue(this.hobbies);
      }
      this.filterHobbies = this.hobbiesListArray;
    }
  }

  DisplayFnHobbies(hobbie: any): string {
    return hobbie && hobbie.vHobbiesName ? hobbie.vHobbiesName : "";
  }

  _filterHobbies(name: string): any {
    const filterValue = name?.toLowerCase();
    this.filterHobbies = this.hobbiesListArray;
    this.filterHobbies = this.filterHobbies.filter((hobbie: { vHobbiesName: string }) => hobbie.vHobbiesName.toLowerCase().startsWith(filterValue));
  }

  PersonalDetailsProcess(): any {
    this.personalDetailsSubmitted = true;
    let hobbies: any = [];
    this.personalDetailsForm.value.iHobbies.forEach((element: any) => {
      hobbies.push(element.iHobbiesId);
    });
    if(this.countryId){
      if(this.stateId){
        if(this.cityId){
        }else {
          this.notifyService.showError("Please select city from the list", "");
          this.personalDetailsForm.controls["iCityId"].setValue('');
          return;
        }
      }else {
        this.notifyService.showError("Please select state from the list", "");
        this.personalDetailsForm.controls["iStateId"].setValue('');
        this.personalDetailsForm.controls["iCityId"].setValue('');
        return;
      }
    }else {
      this.notifyService.showError("Please select country from the list", "");
      this.personalDetailsForm.controls["iCountryId"].setValue('');
      this.personalDetailsForm.controls["iStateId"].setValue('');
      this.personalDetailsForm.controls["iCityId"].setValue('');
      return;
    }

    let reqObj = {
      newImage: this.profileImageFile,
      vEmpFirstName: this.personalDetailsForm.value.vEmpFirstName,
      vEmpLastName: this.personalDetailsForm.value.vEmpLastName,
      vEmailId: this.personalDetailsForm.value.vEmailId,
      vGender: this.personalDetailsForm.value.vGender,
      iBirthDate: Date.parse(this.personalDetailsForm.value.iBirthDate) / 1000,
      vDialCode: this.personalDetailsForm.value.iPhoneNumber.dialCode,
      iPhoneNumber: this.personalDetailsForm.value.iPhoneNumber.number,
      vCountryCode: this.personalDetailsForm.value.iPhoneNumber.countryCode,
      vVideo: this.IntroVideo,
      vAddress: this.personalDetailsForm.value.vAddress,
      iCountryId: this.countryId,
      iStateId: this.stateId,
      iCityId: this.cityId,
      iZipCode: this.personalDetailsForm.value.iZipCode,
      iHobbies: hobbies,
      iEmpBio: this.personalDetailsForm.value.iEmpBio,
    };

    if (this.personalDetailsForm.invalid) {
      return;
    }

    if ((this.profileImageFile == undefined || this.profileImageFile == "" || this.profileImageFile == null) && (this.PersonalDetail.vProfilePhoto == "" || this.PersonalDetail.vProfilePhoto == null || this.PersonalDetail.vProfilePhoto == undefined)) {
      this.personalDetailsForm.controls.newImage.setValidators([Validators.required]);
      this.profileImageError = true;
      return;
    }else{
      this.profileImageError = false;
    }
    if ((this.profileImageFile == undefined || this.profileImageFile == "" || this.profileImageFile == null) && (this.PersonalDetail.vProfilePhoto !== "" || this.PersonalDetail.vProfilePhoto !== null || this.PersonalDetail.vProfilePhoto !== undefined)) {
      delete reqObj["newImage"];
    }
    if (this.IntroVideo == undefined) {
      delete reqObj["vVideo"];
    }

    this.personalDetailsService.UpdatePersonalDetails(reqObj,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetPersonalDetail();
        this.eventEmitterService.MenuListCallMethod();
        this.notifyService.showSuccess(result.message, "");
        this.eventEmitterService.GetProfileStatusAPICallMethod();
        this.personalDetailsToggle=false;
      } else {
        // this.personalDetailsToggle=false;
        this.notifyService.showError(result.message, "");
      }
    });
  }

  GetPersonalDetail() {
    this.personalDetailsService.GetPersonalDetail(this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.PersonalDetail = result.data;
        if (this.PersonalDetail.vProfilePhoto) {
          this.profilePhotoShow = true;
        }else {
          this.profilePhotoShow = false;
        }
      } else {
        this.PersonalDetail = "";
      }
    });
  }

  videoThumbnail(url: any) {
    const myArray = url.split("/");
    let reqObj = {
      url: myArray[myArray.length - 1],
    };
    this.personalDetailsService.IntroVideoThumbDelete(reqObj,this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.GetPersonalDetail();
        this.notifyService.showSuccess("Video Deleted Successfully!", "");
      } else {
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message, "");
      }
    });
  }

  crossClick(file: any) {
    if (this.IntroVideo) {
      this.IntroVideo = "";
    }
  }

  imageChangedEvent: any = "";
  croppedImage: any = "";
  profileImageFile: any;
  fileChangeEvent(event: any, modelName: any): void {
    let fileSize = event["target"]["files"][0]["size"] / 1024;
    if (fileSize > 4 * 1024) {
      this.notifyService.showError(StaticMsg.FileLargerError, "");
      return;
    }
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg") {
      this.imageChangedEvent = event;
      this.modalService.open(modelName, { centered: true, windowClass: "profile-modal" });
    } else {
      this.notifyService.showError(StaticMsg.NotValidImageTypeError, "");
      return;
    }
  }
  async ImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    let base64Response = await fetch(this.croppedImage);
    let blob = await base64Response.blob();
    let fileType = blob.type.split("/")[1];
    this.profileImageFile = new File([blob], "file." + fileType);
  }
  CancelCropModel() {
    this.profileImageFile = undefined;
    this.imageChangedEvent = "";
    this.croppedImage = "";
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  ImageErrordisable() {
    this.profileImageError = false;
  }


  togglePersonalDetails(hasPermission:boolean, element: any){
    if(hasPermission){
      this.profileImageFile = undefined;
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.ProfilePhoto = "";
    this.IntroVideo = "";
    this.personalDetailsForm.reset();
    this.editPersonalData = element;
    this.personalDetailsSubmitted = false;
    this.countryId = element.iCountryId;
    this.stateId = element.iStateId;
    this.cityId = element.iCityId;
    this.GetStateListClick(this.countryId);
    this.countryListArray.map((obj: any) => {
      if (this.countryId == obj.iCountryId) {
        this.personalDetailsForm.controls["iCountryId"].setValue(obj.vName);
      }
    });
    this.GetCityList(this.stateId);

    this.personalDetailsForm.patchValue({
      vEmpFirstName: element.vFirstName,
      vEmpLastName: element.vLastName,
      vEmailId: element.vEmailId,
      iPhoneNumber: {
        countryCode: element.vCountryCode,
        dialCode: element.vDialCode,
        number: element.vPhoneNo,
      },
      vGender: element.iGeneder,
      vAddress: element.vAddress,
      // iCountryId: element.iCountryId,
      // iStateId: element.iStateId,
      // iCityId: element.iCityId,
      iZipCode: element.iZipCode,
      iHobbies: element.Emphobby,
      iEmpBio: element.txBio,
    });
    if (element.iDob) {
      this.personalDetailsForm.patchValue({
        iBirthDate: new Date(element.iDob * 1000),
      });
    }
    // this.GetStateList(this.personalDetailsForm.value.iCountryId);
    // this.GetCityList(this.personalDetailsForm.value.iStateId);
    this.hobbies = element.Emphobby;
    this.personalDetailsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }
}
