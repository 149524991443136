import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from "@angular/core";
import { VERSION } from "@angular/material/core";
import { NavItem } from "./../nav-item";
import { NavService } from "./../nav.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Router } from "@angular/router";
import { HeaderService } from "./header.service";
import { EventEmitterService } from "./../../../config/event-emitter.service";
import { NotificationService } from "./../../../config/notification.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @ViewChild("appDrawer") appDrawer?: ElementRef;
  @ViewChild("sideNavEnd") sideNavEnd?: ElementRef;

  public disabledPublishDefaultProfile: any = false;
  public completePublishProfile: any = false;

  version = VERSION;
  navItems: NavItem[] = [
    {
      displayName: "Dashboard",
      iconName: "dashboard.svg",
      activeIconName: "dashboard-active.svg",
      route: "/admin",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Deal Engine",
      iconName: "account-circle.svg",
      activeIconName: "account-circle-active.svg",
      route: "/admin/dealengine",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Contracts",
      iconName: "description.svg",
      activeIconName: "description-active.svg",
      route: "/admin/contracts",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Employees",
      iconName: "badge.svg",
      activeIconName: "badge-active.svg",
      route: "/admin/employees",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Clients",
      iconName: "portrait.svg",
      activeIconName: "portrait-active.svg",
      route: "/admin/clients",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Calendar",
      iconName: "event-note.svg",
      activeIconName: "event-note-active.svg",
      route: "/admin/calendar",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Interviews",
      iconName: "interviews.svg",
      activeIconName: "interviews-active.svg",
      route: "/admin/interviews",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Messages",
      iconName: "question-answer.svg",
      activeIconName: "question-answer-active.svg",
      route: "/admin/messages",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Reviews",
      iconName: "reviews.svg",
      activeIconName: "reviews-active.svg",
      route: "/admin/reviews",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Billing",
      iconName: "local-atm.svg",
      activeIconName: "local-atm-active.svg",
      route: "/admin/billings",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Profile",
      iconName: "account-circle.svg",
      activeIconName: "account-circle-active.svg",
      route: "/admin/view-profile",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },

    {
      displayName: "Refer a friend",
      iconName: "refer-a-friend.svg",
      activeIconName: "refer-a-friend-active.svg",
      route: "/admin/refer-friend",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
    {
      displayName: "Help Desk",
      iconName: "help-desk.svg",
      activeIconName: "help-desk-active.svg",
      route: "/admin/help-desk",
      disabledPublishDefaultProfile: this.disabledPublishDefaultProfile,
      completePublishProfile: this.completePublishProfile,
      className: "",
    },
  ];

  user: any;

  constructor(public navService: NavService, public ngxSmartModalService: NgxSmartModalService, public router: Router, private headerService: HeaderService, private notifyService: NotificationService, public eventEmitterService: EventEmitterService) {}

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  ngOnInit(): void {
    let user = localStorage.getItem("partner_account_type");
    let accessToken = localStorage.getItem("partner_access_token");
    if (user && accessToken) {
      this.loginUserInformation(user, accessToken);
    }

    this.eventEmitterService.MenuListSubMethod.subscribe(() => {
      this.loginUserInformation(user, accessToken);
    });
  }

  public json_user_data = {
    bIsEmailVerified: false,
    bIsProfileCompleted: false,
    bIsQuestionnaireCompleted: false,
    iUserId: 8,
    tiAccountStatus: 0,
    tiAccountType: 3,
    txAccessToken: "3312121541949a21ae31adf7dedd1677",
    vEmailId: "temp-mail@teksun.com",
    vFirstName: "NA",
    vLastName: "NA",
    login_status: false,
    dis_name: "NA",
    dis_short_name: "NA",
    vProfilePhoto: "",
    iProfilePublishCompleted: 1,
  };
  vCompanyLogoUrl: any;
  loginUserInformation(user: any, accessToken: any) {
    this.headerService.loginUserInformation({ tiAccountType: user, accessToken: accessToken }).subscribe((res: any) => {
      if (res.code == 200) {
        this.json_user_data = res.data;
        this.vCompanyLogoUrl = res.data.vCompanyLogoUrl;
        this.json_user_data.login_status = true;
        this.navItems.forEach((value, index) => {
          if (res.data.iProfilePublishCompleted == 1) {
            if (value.route == "/admin/view-profile") {
              this.navItems[index].disabledPublishDefaultProfile = true;
              this.navItems[index].completePublishProfile = true;
              this.navItems[index].className = "publish-pending";
            } else {
              this.navItems[index].className = "publish-disabled";
            }
          }
          if (res.data.iProfilePublishCompleted == 2) {
            if (value.route == "/admin" || value.route == "/admin/employees" || value.route == "/admin/view-profile") {
              this.navItems[index].completePublishProfile = true;
              this.navItems[index].className = "publish-complete";
            } else {
              this.navItems[index].className = "publish-disabled";
            }
          }
          if (res.data.iProfilePublishCompleted == 3) {
            this.navItems[index].disabledPublishDefaultProfile = true;
            this.navItems[index].completePublishProfile = true;
            this.navItems[index].className = "publish-active";
          }
        });
      } else {
        this.router.navigate(["/login"]);
      }
    });
  }

  logout() {
    this.headerService.logoutAPI({}).subscribe((res: any) => {
      if (res.code == 200) {
        this.json_user_data.login_status = false;
        this.headerService.Logout();
        this.router.navigate(["/login"]);
      } else {
        this.json_user_data.login_status = false;
        this.headerService.Logout();
        this.router.navigate(["/login"]);
      }
    });
  }

  topMenuClick() {
    if (this.json_user_data.iProfilePublishCompleted == 1) {
      this.notifyService.showError("Please Complete Your Profile 100%", "");
    } else if (this.json_user_data.iProfilePublishCompleted == 2) {
      this.router.navigate(["/admin"]);
    } else if (this.json_user_data.iProfilePublishCompleted == 3) {
      this.router.navigate(["/admin"]);
    }
  }
}
