<div class="container-fluid skill-management">
    <div class="row">
      <div class="col-12">
        <div class="wrapper">
          <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="title" role="tabpanel" aria-labelledby="skill-management-tab">
              <div class="pagetitle-wrapper">
                <div
                  class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                  <div class="d-flex align-items-center">
                    <i class="pagetitle-icon"><span class="material-icons">extension</span></i>
                    <h2 class="pagetitle">Child Permission Management</h2>
                  </div>
                  <div placement="bottom auto" [ngbTooltip]="addChildPermissionActionPermission ? '':'Not Authorized'" [ngClass]="addChildPermissionActionPermission ? '':'unauthorized-cursor'">
                    <button class="status-btn lightpurple-bg purple-text text-center ml-0 ml-sm-3 mr-0 mt-3 mt-sm-0 px-3" [ngClass]="addChildPermissionActionPermission ? '':'unauthorized-action'"
                      (click)="OpenAddChildPermissionModal(addChildPermission, addChildPermissionActionPermission)">
                      <mat-icon class="me-2">library_add</mat-icon> Add Permission
                    </button>
                  </div>
                </div>
                <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                  <button matSuffix mat-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <input matInput placeholder="Ex: Web Development" (keyup)="applyFilterOne($event)" />
                </mat-form-field>
                <div class="table-responsive">
                  <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                  </div>
                  <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }"
                    [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                    <ng-container matColumnDef="indexNumber">
                      <th mat-header-cell *matHeaderCellDef> No </th>
                      <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableOnePaginator.pageIndex == 0 ? i+1 : i+ 1 + this.TableOnePaginator.pageIndex *
                        this.TableOnePaginator.pageSize}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="parentPermissionName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent Permission Name</th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.vParentFeature}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="childPermissionName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Child Permission Name</th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.vChildFeature}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="createdAt">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Time</th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef>Actions</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex flex-wrap">
                          <button class="status-btn lightblue-bg blue-text text-center mb-2" placement="left auto" [ngbTooltip]="editChildPermissionActionPermission ? 'Edit':'Not Authorized'" [ngClass]="editChildPermissionActionPermission ? '':'unauthorized-cursor'"
                            (click)="OpenEditChildPermissionModel(editChildPermission, element, editChildPermissionActionPermission)">
                            <mat-icon>create</mat-icon>
                          </button>
                          <button class="status-btn lightpink-bg pink-text text-center mb-2" placement="left auto" [ngbTooltip]="deleteChildPermissionActionPermission ? 'Delete':'Not Authorized'" [ngClass]="deleteChildPermissionActionPermission ? '':'unauthorized-cursor'"
                            (click)="OpenDeleteChildPermission(deleteChildPermission, element, deleteChildPermissionActionPermission)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                  </table>
                </div>
                <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
                  #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #addChildPermission let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Add Child Permission</h1>
          <form class="form" [formGroup]="addChildPermissionForm" (ngSubmit)="AddChildPermission()">
            <div class="row">
              <div class="col-12 mb-2">
                <label class="label">Child Permission Name</label>
                <mat-form-field hideRequiredMarker appearance="outline">
                  <input type="text" formControlName="childPermissionName" matInput placeholder="Ex. Access Dashboard">
                  <mat-error *ngIf="addChildPermissionForm.controls['childPermissionName'].invalid">
                    <mat-error *ngIf="addChildPermissionForm.controls['childPermissionName'].errors.required">Permission Name is required</mat-error>
                    <mat-error *ngIf="addChildPermissionForm.controls['childPermissionName'].errors?.minlength">Minimum 3 character required </mat-error>
                    <mat-error *ngIf="addChildPermissionForm.controls['childPermissionName'].errors?.maxlength">Maximum 50 character allowed </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 mb-2">
                <label class="label">Permission Type</label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="parentPermissionType" placeholder="Select parent permission type">
                    <mat-option *ngFor="let list of typeList" [value]="list.id">
                      {{ list.typeName }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="addChildPermissionForm.controls['parentPermissionType'].invalid">
                    <mat-error *ngIf="addChildPermissionForm.controls['parentPermissionType'].errors.required">Permission
                      Type is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 mb-2" [ngClass]="addChildPermissionForm.controls['parentPermissionType'].value ? '':'unauthorized-cursor'"  placement="left auto" [ngbTooltip]="addChildPermissionForm.controls['parentPermissionType'].value ? '':'Please select the parent permission type'">
                <label class="label">Parent Permission</label>
                <mat-form-field appearance="outline" [ngClass]="addChildPermissionForm.controls['parentPermissionType'].value ? '':'unauthorized-action'">
                  <mat-select formControlName="parentPermission" placeholder="Select parent permission">
                    <mat-option *ngFor="let list of parentData" [value]="list.iParentFeatureId">
                      {{ list.vParentFeature }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="addChildPermissionForm.controls['parentPermission'].invalid">
                    <mat-error *ngIf="addChildPermissionForm.controls['parentPermission'].errors.required">Permission is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn">Add</button>
              <button type="button" class="modal-btn-outline"
                (click)="d('Cross click'); this.addChildPermissionForm.reset(); this.submitted = false;">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #editChildPermission let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Edit Child Permission</h1>
          <form class="form" [formGroup]="editChildPermissionForm" (ngSubmit)="EditChildPermission()">
            <div class="row">
              <div class="col-12 mb-2">
                <label class="label">Child Permission Name</label>
                <mat-form-field hideRequiredMarker appearance="outline">
                  <input type="text" formControlName="childPermissionName" matInput placeholder="Ex. Access Dashboard">
                  <mat-error *ngIf="editChildPermissionForm.controls['childPermissionName'].invalid">
                    <mat-error *ngIf="editChildPermissionForm.controls['childPermissionName'].errors.required">Permission Name is required</mat-error>
                    <mat-error *ngIf="editChildPermissionForm.controls['childPermissionName'].errors?.minlength">Minimum 3 character required </mat-error>
                    <mat-error *ngIf="editChildPermissionForm.controls['childPermissionName'].errors?.maxlength">Maximum 50 character allowed </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 mb-2">
                <label class="label">Parent Permission</label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="parentPermission" placeholder="Select parent permission">
                    <mat-option *ngFor="let list of parentData" [value]="list.iParentFeatureId">
                      {{ list.vParentFeature }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="editChildPermissionForm.controls['parentPermission'].invalid">
                    <mat-error *ngIf="editChildPermissionForm.controls['parentPermission'].errors.required">Permission is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn">Edit</button>
              <button type="button" class="modal-btn-outline" (click)="d('Cross click'); this.editChildPermissionForm.reset();">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #deleteChildPermission let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title d-flex justify-content-center">Are you sure?</h1>
          <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
            <button type="submit" class="modal-btn" (click)="DeleteChildPermission(iChildFeatureId)">Yes</button>
            <button type="button" class="modal-btn-outline px-4" (click)="d('Cross click')">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>