import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from 'src/app/config/event-emitter.service';
import { RequirementsService } from './requirements.service';
import { Permission } from 'src/app/utils/enums/permission.enum';
import { UserPermissionService } from 'src/app/utils/services/user-permission.service';
import { Router } from '@angular/router';

interface Common {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html'
})
export class RequirementsComponent implements OnInit {

  // toggle actions permission
  viewRequirementActionPermission: boolean = false;

  experience: Common[] = [
    { value: 0, viewValue: 'Junior(0-2 Years)' },
    { value: 1, viewValue: 'Senior(2-8 Years)' },
    { value: 2, viewValue: 'Senior(8+ Years)' },
  ];

  ParentReqId: any;
  ParentReqName: any;
  RequirementTalents: any;
  apiResponse!: any;
  apiResponseRequirementTalents!: any;
  vPrimarySkillArray: any[] = [];
  filterType: any = 'All';
  filterValue: String = '';
  view: any = 2;

  displayedColumnsTableOne: string[] = ['indexnumber', 'vDisplayUniqueId', 'vClientName', 'iCreatedAt', 'vReqStatus', 'actions'];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

  constructor(
    private reqService: RequirementsService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private router: Router,
    private userPermissionService: UserPermissionService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;

    // assign individual permissions
    const ViewRequirementAction: Permission = Permission.ViewRequirementAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ViewRequirementAction)) {
      this.viewRequirementActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetRequirements();
  }

  OpenModelViewRequirement(element: any, hasPermission: boolean) {
    if (hasPermission) {
    this.router.navigate([`/requirement-details/${element.iReqId}`]);

      // this.modalService.open(modelName, { centered: true });
      // this.GetRequirementDetailsById(element.iReqId)
    }
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  allDetails() {
    this.view = "2";
    this.GetRequirements();
  }

  fulfilledDetails() {
    this.view = "1";
    this.GetRequirements();
  }

  unfulfilledDetails() {
    this.view = "0";
    this.GetRequirements();
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  // applyFilterTwo() {
  //   this.GetRequirementDetailsById(this.ParentReqId);
  // }

  // GetRequirementDetailsById(id: any) {
  //   this.reqService.GetRequirementDetailsById(id).then((result: any) => {
  //     console.log(result);
      
  //     if (result['code'] == 200) {
  //       this.ParentReqId = result['data'].iReqId;
  //       this.ParentReqName = result['data'].vReqName;
  //       this.RequirementTalents = result['data'].RequirementTalents;
  //       this.apiResponseRequirementTalents = result['data'].RequirementTalents;
  //       this.vPrimarySkillArray = [...new Map(this.apiResponseRequirementTalents.map((item: any) => [item["iPrimarySkillId"], item])).values()];
  //       if (this.filterType == 'All') {
  //         this.RequirementTalents = this.apiResponseRequirementTalents;
  //       } else {
  //         let filteredData = this.apiResponseRequirementTalents.filter((item: any) => {
  //           return item.iPrimarySkillId == this.filterType;
  //         });
  //         this.RequirementTalents = filteredData;
  //       }
  //     } else {
  //       this.eventEmitterService.modelMessage({ "msg": result.message, "status": 1 });
  //     }
  //   }).catch((err: any) => {
  //     console.log(err);
  //     this.ParentReqId = undefined;
  //     this.ParentReqName = undefined;
  //   });
  // }

  // selectRequirementType() {
  //   if (this.filterType == '0') {
  //     this.dataSourceTableOne.data = this.apiResponse;
  //   } else {
  //     let filteredData = this.apiResponse.filter((item: any) => {
  //       return item.tiRequirementType == this.filterType;
  //     });
  //     this.dataSourceTableOne.data = filteredData;
  //   }
  // }

  GetRequirements() {
    this.reqService.GetRequirements(this.view).then((result: any) => {
      if (result['code'] == 200) {
        this.apiResponse = result['data'];
        // console.log(this.apiResponse,"apiResponsereqwwwwwwwwwww");
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.eventEmitterService.modelMessage({ "msg": result.message, "status": 1 });
      }
    }).catch((err: any) => { console.log(err) });
  }
}
