import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalConfig,NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../config/event-emitter.service';
import { RequirementService } from './requirement.service';
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

interface Common {
  value: number;
  viewValue: string;
}
@Component({
  selector: 'app-requirement',
  templateUrl: './requirement.component.html',
  styles: [
  ]
})
export class RequirementComponent implements OnInit {
  // toggle actions permission
  viewRequirementActionPermission: boolean = false;

  experience: Common[] = [
    {value: 0, viewValue: 'Junior(0-2 Years)'},
    {value: 1, viewValue: 'Senior(2-8 Years)'},
    {value: 2, viewValue: 'Senior(8+ Years)'},
  ];


ParentReqId:any;
ParentReqName:any;
RequirementTalents:any;
apiResponse!:any;
apiResponseRequirementTalents!:any;
vPrimarySkillArray:any[]=[];
filterType:any='All';
filterValue:String='';

displayedColumnsTableOne: string[] = ['indexnumber','vReqName','vClientName','actions'];
dataSourceTableOne = new MatTableDataSource();

@ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
@ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

constructor(
private reqService: RequirementService,
public eventEmitterService: EventEmitterService, 
private modalService: NgbModal,
private notifyService: NotificationService,
config: NgbModalConfig,
private userPermissionService: UserPermissionService
) {
  config.backdrop = 'static';
  config.keyboard = false;

  // assign individual permissions
  const ViewRequirementAction: Permission = Permission.ViewRequirementAction;

  // check what user is authorized
  if (this.userPermissionService.isAuthorizedFor(ViewRequirementAction)) {
    this.viewRequirementActionPermission = true;
  }
}

ngOnInit(): void {
this.GetRequirements();
}

OpenModelViewRequirement(modelName:any, element:any, hasPermission: boolean) {
  if (hasPermission) {
    this.modalService.open(modelName, { centered: true });
    this.GetRequirementDetailsById(element.iReqId)
  }
}

ngAfterViewInit() {
this.dataSourceTableOne.paginator = this.tableOnePaginator;
this.dataSourceTableOne.sort = this.tableOneSort;
}

applyFilterOne(filterValue: any) {
this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
}

applyFilterTwo() {
  this.GetRequirementDetailsById(this.ParentReqId);
}

GetRequirementDetailsById(id:any){
  this.reqService.GetRequirementDetailsById(id).then((result: any) => {
    if (result['code'] == 200) {
      this.ParentReqId=result['data'].iReqId;
      this.ParentReqName=result['data'].vReqName;
      this.RequirementTalents=result['data'].RequirementTalents;
      this.apiResponseRequirementTalents=result['data'].RequirementTalents;
      this.vPrimarySkillArray = [...new Map(this.apiResponseRequirementTalents.map((item:any) => [item["iPrimarySkillId"], item])).values()];
      if(this.filterType=='All'){
        this.RequirementTalents=this.apiResponseRequirementTalents;
      }else{
        let filteredData = this.apiResponseRequirementTalents.filter((item:any)=>{
          return item.iPrimarySkillId==this.filterType;
        });
        this.RequirementTalents=filteredData;
      }
    } else {
      this.notifyService.showError(result.message,'');
    }
  }).catch((err: any) => {
    console.log(err);
    this.ParentReqId=undefined;
    this.ParentReqName=undefined;
  });
}
  

selectRequirementType(){
if(this.filterType=='0'){
  this.dataSourceTableOne.data=this.apiResponse;
}else{
  let filteredData = this.apiResponse.filter((item:any)=>{
    return item.tiRequirementType==this.filterType;
  });
  this.dataSourceTableOne.data=filteredData;
}
}

GetRequirements() {
this.reqService.GetRequirements().then((result: any) => {
  if (result['code'] == 200) {
    this.apiResponse=result['data'];
    // console.log(this.apiResponse,"apiResponsereqwwwwwwwwwww");
    this.dataSourceTableOne.data = result['data'];
  } else {
    this.notifyService.showError(result.message,'');
  }
}).catch((err: any) => {console.log(err)});
}
}
