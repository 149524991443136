<div class="page-content admin-section contract-container">

    <h1 class="admin-title">Contracts</h1>
    <div>
        <div class="view-heading-container align-items-center">
            <div class="contract-button-container d-xxl-flex">
                <div class="d-sm-flex bottom-space">
                    <button mat-button (click)="allDetails()" [ngClass]="{'active-tab-btn' : view == 0}"
                        class="inactive-tab-btn block-space">All</button>
                    <button mat-button (click)="upcomingDetails()" [ngClass]="{'active-tab-btn' : view == 1}" class="inactive-tab-btn btn-width space-left">Upcoming/Require Action</button>
                </div>
                <div class="d-sm-flex">
                    <button mat-button (click)="clientDetails()" [ngClass]="{'active-tab-btn' : view == 3}"
                        class="inactive-tab-btn space-left block-space">Client</button>
                    <button mat-button (click)="historyDetails()" [ngClass]="{'active-tab-btn' : view == 2}"
                    class="inactive-tab-btn space-left">History</button>
                </div>
            </div>
            <mat-form-field class="custom-input custom-search-input spacing-input" floatLabel="never"
                floatPlaceholder="never">
                <mat-label>Search Email Address</mat-label>
                <input matInput type="text" (keyup)="applyFilterOne($event)">
                <button matSuffix>
                    <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        viewBox="0 0 20 20">
                        <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
                        <path id="Path_690" data-name="Path 690"
                            d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
                            transform="translate(0)" fill="#464646" />
                    </svg>
                </button>
            </mat-form-field>
        </div>
    </div>

    <div class="table-border">
        <div class="table-responsive">
            <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
            </div>
            <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }"
                [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                <ng-container matColumnDef="indexNumber">
                    <th mat-header-cell *matHeaderCellDef> No </th>
                    <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableOnePaginator.pageIndex == 0 ? i+1 : i+ 1 + this.TableOnePaginator.pageIndex *
                        this.TableOnePaginator.pageSize}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="vContractUniqueId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Unique ID</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.vContractUniqueId}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="vTalentName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Talent Name</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.vFirstName}} {{element.vLastName}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="iContractStartDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract Start Date</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.iContractStartDate * 1000 | date:'dd MMM, yyyy'}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="iHiringDuration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract Duration</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.iHiringDuration}} Months</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fAmount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Contract Amount</th>
                    <td mat-cell *matCellDef="let element">
                        <span>${{element.fAmount}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="tiContractStatus">
                    <th mat-header-cell *matHeaderCellDef> Contract Status </th>
                    <td mat-cell *matCellDef="let element">
                        <label class="status-tag bg-green" *ngIf="element.tiContractStatus == 4">
                            Active
                        </label>
                        <label class="status-tag bg-red" *ngIf="element.tiContractStatus != 4">
                            Inactive
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex align-items-center">
                            <button *ngIf="element.tiContractStatus == 6"
                                class="status-btn lightgreen-bg green-text my-1 width-max" 
                                (click)="CreateContract(element)">
                                <span class="material-icons me-1">note_add</span> Create Digital Contract
                            </button>
                            <button *ngIf="element.tiContractStatus == 1"
                                class="status-btn lightblue-bg blue-text my-1" placement="left auto"
                                [ngbTooltip]="downloadContractPDFActionPermission ? '':'Not Authorized'"
                                [ngClass]="downloadContractPDFActionPermission ? '':'unauthorized-cursor'"
                                (click)="DownloadUploadedContract(element.vContractUrl,downloadContractPDFActionPermission)">
                                <span class="material-icons">cloud_download</span>
                            </button>

                            <!-- <button *ngIf="element.tiContractStatus == 0 && element.tiStatus == 0"
                                class="status-btn action-btn bg-green" placement="left auto"
                                [ngbTooltip]="approveContractActionPermission ? '':'Not Authorized'"
                                [ngClass]="approveContractActionPermission ? '':'unauthorized-cursor'"
                                (click)="OpenAcceptContractModal(acceptContractModel, element, approveContractActionPermission)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="check_circle_FILL1_wght600_GRAD200_opsz40" d="M9.142,13.987,14.7,8.41,13.442,7.132l-4.3,4.3L7.02,9.311,5.743,10.588ZM10.25,18.25a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z" transform="translate(-2.25 -2.25)" fill="#58b47c"/>
                                </svg>
                            </button>
                            <button *ngIf="element.tiContractStatus == 0 && element.tiStatus == 0"
                                class="status-btn action-btn bg-red" placement="left auto"
                                [ngbTooltip]="rejectContractActionPermission ? '':'Not Authorized'"
                                [ngClass]="rejectContractActionPermission ? '':'unauthorized-cursor'"
                                (click)="OpenRejectContractModal(rejectContractModel, element, rejectContractActionPermission)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="cancel_FILL1_wght600_GRAD200_opsz40" d="M7.546,14.025l2.7-2.685,2.7,2.685,1.07-1.07-2.685-2.7,2.685-2.7-1.07-1.07-2.7,2.685-2.7-2.685-1.07,1.07,2.685,2.7-2.685,2.7Zm2.7,4.225a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z" transform="translate(-2.25 -2.25)" fill="#ee6060"/>
                                </svg>
                            </button> -->
                            <!-- <span *ngIf="element.tiStatus == 1 && !element.uploadedContractStatus"
                                class="status-btn lightgray-bg gray-text"><span
                                    class="material-icons me-1">hide_source</span>No Action</span> -->
                            <!-- <button *ngIf="element.tiContractStatus == 0 && element.tiStatus == 2"
                                class="status-btn lightpurple-bg purple-text" data-bs-toggle="tooltip"
                                data-bs-placement="bottom" title="{{element.txRejectComment}}">
                                <span class="material-icons">info</span>
                            </button>
                            <button *ngIf="element.uploadedContractStatus"
                                class="status-btn lightblue-bg blue-text my-1" placement="left auto"
                                [ngbTooltip]="downloadContractPDFActionPermission ? '':'Not Authorized'"
                                [ngClass]="downloadContractPDFActionPermission ? '':'unauthorized-cursor'"
                                (click)="DownloadUploadedContract(element.contractUrl,downloadContractPDFActionPermission)">
                                <span class="material-icons">cloud_download</span>
                            </button>
                            <button *ngIf="element.uploadedContractStatus && element.clientSignedStatus"
                                class="status-btn lightpurple-bg purple-text my-1 d-flex align-items-center"
                                placement="left auto"
                                [ngbTooltip]="signClientContractActionPermission ? '':'Not Authorized'"
                                [ngClass]="signClientContractActionPermission ? '':'unauthorized-cursor'"
                                (click)="OpenClientContractSignedModal(clientContractSignedModel, element, signClientContractActionPermission)">
                                <span class="material-icons me-1">assignment_turned_in</span> Client
                            </button>
                            <button
                                *ngIf="element.uploadedContractStatus && element.partnerSignedStatus && !(element.clientSignedStatus)"
                                class="status-btn lightpink-bg pink-text my-1 d-flex align-items-center"
                                placement="left auto"
                                [ngbTooltip]="signPartnerContractActionPermission ? '':'Not Authorized'"
                                [ngClass]="signPartnerContractActionPermission ? '':'unauthorized-cursor'"
                                (click)="OpenPartnerContractSignedModal(partnerContractSignedModel, element,signPartnerContractActionPermission)">
                                <span class="material-icons me-1">assignment_turned_in</span> Partner
                            </button> -->
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
            </table>
        </div>
        <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
            #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
        </mat-paginator>
    </div>

</div>

<ng-template #acceptContractModel let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
        <div class="d-flex align-items-center content">
            <div class="w-100">
                <h1 class="title d-flex justify-content-center">Are you sure?</h1>
                <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
                    <button type="submit" class="modal-btn" (click)="ContractAccept(contractId)">Accept</button>
                    <button type="button" class="modal-btn-outline px-4" (click)="d('Cross click')">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #rejectContractModel let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
        <div class="d-flex align-items-center content">
            <div class="w-100">
                <h1 class="title">Reject Contract</h1>
                <form class="form" [formGroup]="rejectContractForm" (ngSubmit)="HandleContractReject()">
                    <div class="row">
                        <div class="col-12">
                            <label class="label">Reason To Reject</label>
                            <mat-form-field hideRequiredMarker appearance="outline">
                                <input type="text" formControlName="rejectComment" matInput placeholder="Reason...">
                                <mat-error
                                    *ngIf="rejectContractForm.controls['rejectComment'].invalid && (rejectContractForm.controls['rejectComment'].dirty || rejectContractForm.controls['rejectComment'].touched)">
                                    <mat-error
                                        *ngIf="rejectContractForm.controls['rejectComment'].errors.required">Reason is
                                        required</mat-error>
                                    <mat-error *ngIf="rejectContractForm.controls['rejectComment'].errors?.minlength">
                                        Minimum 3 character required </mat-error>
                                    <mat-error *ngIf="rejectContractForm.controls['rejectComment'].errors?.maxlength">
                                        Maximum 150 character allowed </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
                        <button type="submit" class="modal-btn">Reject</button>
                        <button type="button" class="modal-btn-outline px-4"
                            (click)="d('Cross click'); this.rejectContractForm.reset();">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #clientContractSignedModel let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
        <div class="d-flex align-items-center content">
            <div class="w-100">
                <h1 class="title d-flex justify-content-center">Are you sure?</h1>
                <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
                    <button type="submit" class="modal-btn" (click)="UpdateClientSignedStatus(contractId)">Yes</button>
                    <button type="button" class="modal-btn-outline px-4" (click)="d('Cross click')">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #partnerContractSignedModel let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
        <div class="d-flex align-items-center content">
            <div class="w-100">
                <h1 class="title d-flex justify-content-center">Are you sure?</h1>
                <div class="d-flex justify-content-center align-items-center flex-row btn-box my-4">
                    <button type="submit" class="modal-btn" (click)="UpdatePartnerSignedStatus(contractId)">Yes</button>
                    <button type="button" class="modal-btn-outline px-4" (click)="d('Cross click')">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


