import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ViewPartnerService } from "../view-partner.service";
import { NotificationService } from "../../../../config/notification.service";
import { environment } from "../../../../../environments/environment";
import { Permission } from "../../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../../utils/services/user-permission.service";

@Component({
  selector: "partner-additional-details",
  templateUrl: "./additional-details.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class PartnerAdditionalDetailsComponent implements OnInit {
  @Input() partnerUserId:any;
  additionalDetailsToggle:boolean=false;
  
  imagePath = environment.image_path;

  // toggle actions permission
  editPartnerAdditionalDetailsActionPermission: boolean = false;

  additionalDetailsForm!: FormGroup;
  additionalDetailsSubmitted: any = false;

  introDeck: any;
  video: any;
  mediaArray: any = [];
  additionalDetailsArray: any = [];

  monday: any;
  tuesday: any;
  wednesday: any;
  thursday: any;
  friday: any;
  saturday: any;
  sunday: any;

  @ViewChild("uploadFile") fileInput: any;
  fileAttr: any;

  constructor(config: NgbModalConfig, private modalService: NgbModal, private partnerService: ViewPartnerService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService, private userPermissionService: UserPermissionService) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const EditPartnerAdditionalDetailsAction: Permission = Permission.EditPartnerAdditionalDetailsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(EditPartnerAdditionalDetailsAction)) {
      this.editPartnerAdditionalDetailsActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });

    this.GetAdditionalDetails();

    this.additionalDetailsForm = new FormGroup({
      vEmployeeRelocation: new FormControl("", [Validators.required]),
      vContractToHire: new FormControl("", [Validators.required]),
      vMondayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vMondayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vMondayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vMondayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vTuesdayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vTuesdayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vTuesdayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vTuesdayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vWednesdayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vWednesdayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vWednesdayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vWednesdayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vThursdayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vThursdayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vThursdayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vThursdayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vFridayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vFridayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vFridayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vFridayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vSaturdayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vSaturdayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vSaturdayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vSaturdayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vSundayStartHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vSundayStartMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      vSundayEndHour: new FormControl("", [Validators.required, Validators.pattern("^([01][0-9]|2[0-3])$")]),
      vSundayEndMinute: new FormControl("", [Validators.required, Validators.pattern("^[0-5][0-9]$")]),
      iMondayStatus: new FormControl("", [Validators.required]),
      iTuesdayStatus: new FormControl("", [Validators.required]),
      iWednesdayStatus: new FormControl("", [Validators.required]),
      iThursdayStatus: new FormControl("", [Validators.required]),
      iFridayStatus: new FormControl("", [Validators.required]),
      iSaturdayStatus: new FormControl("", [Validators.required]),
      iSundayStatus: new FormControl("", [Validators.required]),
    });
  }

  public additionalDetailsCardCompleted: any = false;
  updateProfileStatus(data: any) {
    this.additionalDetailsCardCompleted = data["additionalDetails"];
  }

  UploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = [];
      Array.from(imgFile.target.files).forEach((file: any) => {
        this.fileAttr.push(file.name);
      });
      // HTML5 FileReader API
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          let imgBase64Path = e.target.result;
        };
      };
      reader.readAsDataURL(imgFile.target.files[0]);
      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = [];
    }
  }

  copyToAll() {
    this.additionalDetailsForm.patchValue({
      vTuesdayStartHour: this.additionalDetailsForm.value.vMondayStartHour,
      vTuesdayStartMinute: this.additionalDetailsForm.value.vMondayStartMinute,
      vTuesdayEndHour: this.additionalDetailsForm.value.vMondayEndHour,
      vTuesdayEndMinute: this.additionalDetailsForm.value.vMondayEndMinute,
      vWednesdayStartHour: this.additionalDetailsForm.value.vMondayStartHour,
      vWednesdayStartMinute: this.additionalDetailsForm.value.vMondayStartMinute,
      vWednesdayEndHour: this.additionalDetailsForm.value.vMondayEndHour,
      vWednesdayEndMinute: this.additionalDetailsForm.value.vMondayEndMinute,
      vThursdayStartHour: this.additionalDetailsForm.value.vMondayStartHour,
      vThursdayStartMinute: this.additionalDetailsForm.value.vMondayStartMinute,
      vThursdayEndHour: this.additionalDetailsForm.value.vMondayEndHour,
      vThursdayEndMinute: this.additionalDetailsForm.value.vMondayEndMinute,
      vFridayStartHour: this.additionalDetailsForm.value.vMondayStartHour,
      vFridayStartMinute: this.additionalDetailsForm.value.vMondayStartMinute,
      vFridayEndHour: this.additionalDetailsForm.value.vMondayEndHour,
      vFridayEndMinute: this.additionalDetailsForm.value.vMondayEndMinute,
      vSaturdayStartHour: this.additionalDetailsForm.value.vMondayStartHour,
      vSaturdayStartMinute: this.additionalDetailsForm.value.vMondayStartMinute,
      vSaturdayEndHour: this.additionalDetailsForm.value.vMondayEndHour,
      vSaturdayEndMinute: this.additionalDetailsForm.value.vMondayEndMinute,
      vSundayStartHour: this.additionalDetailsForm.value.vMondayStartHour,
      vSundayStartMinute: this.additionalDetailsForm.value.vMondayStartMinute,
      vSundayEndHour: this.additionalDetailsForm.value.vMondayEndHour,
      vSundayEndMinute: this.additionalDetailsForm.value.vMondayEndMinute,
      iTuesdayStatus: this.additionalDetailsForm.value.iMondayStatus,
      iWednesdayStatus: this.additionalDetailsForm.value.iMondayStatus,
      iThursdayStatus: this.additionalDetailsForm.value.iMondayStatus,
      iFridayStatus: this.additionalDetailsForm.value.iMondayStatus,
      iSaturdayStatus: this.additionalDetailsForm.value.iMondayStatus,
      iSundayStatus: this.additionalDetailsForm.value.iMondayStatus,
    });
  }

  GetAdditionalDetails() {
    this.partnerService.GetAdditionalDetails(this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.additionalDetailsArray = result["data"];
        this.monday = (result.data.tMondayStart + ":" + result.data.tMondayEnd).split(":");
        this.tuesday = (result.data.tTuesdayStart + ":" + result.data.tTuesdayEnd).split(":");
        this.wednesday = (result.data.tWednesdayStart + ":" + result.data.tWednesdayEnd).split(":");
        this.thursday = (result.data.tThursdayStart + ":" + result.data.tThursdayEnd).split(":");
        this.friday = (result.data.tFridayStart + ":" + result.data.tFridayEnd).split(":");
        this.saturday = (result.data.tSaturdayStart + ":" + result.data.tSaturdayEnd).split(":");
        this.sunday = (result.data.tSundayStart + ":" + result.data.tSundayEnd).split(":");

        this.additionalDetailsForm.patchValue({
          vContractToHire: result["data"].iContractHire,
          vEmployeeRelocation: result["data"].iEmployeeRelocation,
          vMondayStartHour: this.monday[0],
          vMondayStartMinute: this.monday[1],
          vMondayEndHour: this.monday[3],
          vMondayEndMinute: this.monday[4],
          vTuesdayStartHour: this.tuesday[0],
          vTuesdayStartMinute: this.tuesday[1],
          vTuesdayEndHour: this.tuesday[3],
          vTuesdayEndMinute: this.tuesday[4],
          vWednesdayStartHour: this.wednesday[0],
          vWednesdayStartMinute: this.wednesday[1],
          vWednesdayEndHour: this.wednesday[3],
          vWednesdayEndMinute: this.wednesday[4],
          vThursdayStartHour: this.thursday[0],
          vThursdayStartMinute: this.thursday[1],
          vThursdayEndHour: this.thursday[3],
          vThursdayEndMinute: this.thursday[4],
          vFridayStartHour: this.friday[0],
          vFridayStartMinute: this.friday[1],
          vFridayEndHour: this.friday[3],
          vFridayEndMinute: this.friday[4],
          vSaturdayStartHour: this.saturday[0],
          vSaturdayStartMinute: this.saturday[1],
          vSaturdayEndHour: this.saturday[3],
          vSaturdayEndMinute: this.saturday[4],
          vSundayStartHour: this.sunday[0],
          vSundayStartMinute: this.sunday[1],
          vSundayEndHour: this.sunday[3],
          vSundayEndMinute: this.sunday[4],
          iMondayStatus: result["data"].iMondayStatus,
          iTuesdayStatus: result["data"].iTuesdayStatus,
          iWednesdayStatus: result["data"].iWednesdayStatus,
          iThursdayStatus: result["data"].iThursdayStatus,
          iFridayStatus: result["data"].iFridayStatus,
          iSaturdayStatus: result["data"].iSaturdayStatus,
          iSundayStatus: result["data"].iSundayStatus,
        });
      }
    });
  }

  UpdateAdditionalDetails() {
    this.additionalDetailsSubmitted = true;
    if (this.additionalDetailsForm.invalid) {
      return;
    }

    if (this.additionalDetailsForm.controls["vMondayStartHour"]?.value >= this.additionalDetailsForm.controls["vMondayEndHour"]?.value) {
      return;
    } else if (this.additionalDetailsForm.controls["vTuesdayStartHour"]?.value >= this.additionalDetailsForm.controls["vTuesdayEndHour"]?.value) {
      return;
    } else if (this.additionalDetailsForm.controls["vWednesdayStartHour"]?.value >= this.additionalDetailsForm.controls["vWednesdayEndHour"]?.value) {
      return;
    } else if (this.additionalDetailsForm.controls["vThursdayStartHour"]?.value >= this.additionalDetailsForm.controls["vThursdayEndHour"]?.value) {
      return;
    } else if (this.additionalDetailsForm.controls["vFridayStartHour"]?.value >= this.additionalDetailsForm.controls["vFridayEndHour"]?.value) {
      return;
    } else if (this.additionalDetailsForm.controls["vSaturdayStartHour"]?.value >= this.additionalDetailsForm.controls["vSaturdayEndHour"]?.value) {
      return;
    } else if (this.additionalDetailsForm.controls["vSundayStartHour"]?.value >= this.additionalDetailsForm.controls["vSundayEndHour"]?.value) {
      return;
    }

    let reqData = {
      iEmployeeRelocation: this.additionalDetailsForm.value.vEmployeeRelocation,
      iContractHire: this.additionalDetailsForm.value.vContractToHire,
      tMondayStart: this.additionalDetailsForm.value.vMondayStartHour + ":" + this.additionalDetailsForm.value.vMondayStartMinute,
      tMondayEnd: this.additionalDetailsForm.value.vMondayEndHour + ":" + this.additionalDetailsForm.value.vMondayEndMinute,
      tTuesdayStart: this.additionalDetailsForm.value.vTuesdayStartHour + ":" + this.additionalDetailsForm.value.vTuesdayStartMinute,
      tTuesdayEnd: this.additionalDetailsForm.value.vTuesdayEndHour + ":" + this.additionalDetailsForm.value.vTuesdayEndMinute,
      tWednesdayStart: this.additionalDetailsForm.value.vWednesdayStartHour + ":" + this.additionalDetailsForm.value.vWednesdayStartMinute,
      tWednesdayEnd: this.additionalDetailsForm.value.vWednesdayEndHour + ":" + this.additionalDetailsForm.value.vWednesdayEndMinute,
      tThursdayStart: this.additionalDetailsForm.value.vThursdayStartHour + ":" + this.additionalDetailsForm.value.vThursdayStartMinute,
      tThursdayEnd: this.additionalDetailsForm.value.vThursdayEndHour + ":" + this.additionalDetailsForm.value.vThursdayEndMinute,
      tFridayStart: this.additionalDetailsForm.value.vFridayStartHour + ":" + this.additionalDetailsForm.value.vFridayStartMinute,
      tFridayEnd: this.additionalDetailsForm.value.vFridayEndHour + ":" + this.additionalDetailsForm.value.vFridayEndMinute,
      tSaturdayStart: this.additionalDetailsForm.value.vSaturdayStartHour + ":" + this.additionalDetailsForm.value.vSaturdayStartMinute,
      tSaturdayEnd: this.additionalDetailsForm.value.vSaturdayEndHour + ":" + this.additionalDetailsForm.value.vSaturdayEndMinute,
      tSundayStart: this.additionalDetailsForm.value.vSundayStartHour + ":" + this.additionalDetailsForm.value.vSundayStartMinute,
      tSundayEnd: this.additionalDetailsForm.value.vSundayEndHour + ":" + this.additionalDetailsForm.value.vSundayEndMinute,
      iMondayStatus: this.additionalDetailsForm.value.iMondayStatus,
      iTuesdayStatus: this.additionalDetailsForm.value.iTuesdayStatus,
      iWednesdayStatus: this.additionalDetailsForm.value.iWednesdayStatus,
      iThursdayStatus: this.additionalDetailsForm.value.iThursdayStatus,
      iFridayStatus: this.additionalDetailsForm.value.iFridayStatus,
      iSaturdayStatus: this.additionalDetailsForm.value.iSaturdayStatus,
      iSundayStatus: this.additionalDetailsForm.value.iSundayStatus,
    };
    this.partnerService
      .UpdateAdditionalDetails(reqData,this.partnerUserId)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.GetAdditionalDetails();
          this.notifyService.showSuccess(result.message, "");
          this.eventEmitterService.GetProfileStatusAPICallMethod();
          this.additionalDetailsToggle=false;
        } else {
          this.additionalDetailsToggle=false;
          this.notifyService.showError(result.message,'');
        }
      })
      .catch((err: any) => {
        console.log(err, "AddTechnology");
      });
  }


  toggleAdditionalDetails(hasPermission: boolean) {
    if (hasPermission) {
      this.additionalDetailsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

}
