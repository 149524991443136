


<div
  name="view-profile-main-container"
  id="view-profile-main-container"
  class="d-flex flex-column view-profile-main-container sub-user-profile page-content h-100"
>
  <div name="container-class" id="container-class" class="container-fluid">
    <div name="main-content-box" id="main-content-box" class="main-content-box">
      <app-ng7-dynamic-breadcrumb></app-ng7-dynamic-breadcrumb>
      <div
        name="main-container-header"
        id="main-container-header"
        class="main-container-header"
      >
        <h1 name="main-container-title" id="main-container-title" class="title">
          Profile
        </h1>
        <!-- <div
          name="main-container-action-box"
          id="main-container-action-box"
          class="action-box"
        >
        <a name="view-profile-button" id="view-profile-button" class="btn-with-icon" href="javascript:void(0)"
        routerLink="/partner/sub-user-profile"><img id="view-profile-icon" class="btn-icon"
          [src]="imagePath + 'view-profile.svg'" alt="view-profile">View Profile</a>
        </div> -->
      </div>

      <!-- main section -->
      <div name="view-profile-row" id="view-profile-row" class="row">
        <div
          name="view-profile-column"
          id="view-profile-column"
          class="col-12 col-md-12"
        >
          <div name="card-container" id="card-container" class="card-container">
            <div name="card" id="card" class="card edit-sub-profile-card">
              <div name="card-content" id="card-content" class="card-content">
                <form
                  id="profileDetailsForm"
                  [formGroup]="profileDetailsForm"
                  (ngSubmit)="UpdateProfile()"
                >
                  <div class="profile-edit-card">
                    <div
                      name="profile-top"
                      id="profile-top"
                      class="profile-top flex-class"
                    >
                      <div class="card-view-profile-img">
                        <img
                        id="image"
                        class="profile-image-container-size"
                        *ngIf="oldImage && !croppedImage && profileBtnToggle == false"
                        src="{{ oldImage }}"
                        alt="profile picture"
                      />
                      <img
                        id="image"
                        class="profile-image-container-size"
                        *ngIf="croppedImage && profileBtnToggle == false"
                        src="{{ croppedImage }}"
                        alt="profile picture"
                      />
                       <div
                    name="green-circle-logo"
                    id="green-circle-logo"
                    class="image-box1"
                    *ngIf="!oldImage && !croppedImage && profileBtnToggle == false"
                  >
                    <img
                      class="profile-image"
                      [src]="imagePath + 'person-black.svg'"
                      alt="profile image"
                    />
                  </div>

                        <div *ngIf="profileBtnToggle == true"
                          name="profile-image-container"
                          id="profile-image-container"
                          class="profile-image-container"
                        >
                          <div
                            name="profile-image-block"
                            id="profile-image-block"
                            class="profile-image-block"
                          >
                            <input
                              name="image-input"
                              id="image-input"
                              type="file"
                              style="display: none"
                              #fileInputComapnyLogo
                              accept="image/*"
                              (change)="fileChangeEvent($event, Crop)"
                            />
                            <img
                              id="image"
                              class="profile-image-container-size"
                              *ngIf="oldImage && !croppedImage"
                              src="{{ oldImage }}"
                              alt="profile picture"
                            />
                            <img
                              id="image"
                              class="profile-image-container-size"
                              *ngIf="croppedImage"
                              src="{{ croppedImage }}"
                              alt="profile picture"
                            />
                            <div
                              name="green-circle-logo"
                              id="green-circle-logo"
                              class="image-box"
                              *ngIf="!oldImage && !croppedImage"
                            >
                              <img
                                class="profile-image"
                                [src]="imagePath + 'person-black.svg'"
                                alt="profile image"
                              />
                            </div>
                          </div>
                          <div
                            name="profile-image-upload"
                            id="profile-image-upload"
                            class="profile-image-upload"
                          >
                          <div class="dark-shadow">
                            <!-- placement="auto"
                            ngbTooltip="Upload Profile Pic" -->
                            <button
                            type="button"
                            name="profile-upload-button"
                            id="profile-upload-button"
                            class="profile-upload-button"
                            (click)="fileInputComapnyLogo.click()"
                          >
                            <mat-icon class="upload-file-img"
                              >upload_file</mat-icon
                            >
                          </button>
                          </div>
      
                            <mat-error
                              class="reduce-bottom-space photo-error-sub-user "
                              *ngIf="profileImageError"
                            >
                              <mat-error
                                class="reduce-bottom-space"
                                *ngIf="profileImageError"
                              >
                                Photo is required
                              </mat-error>
                            </mat-error>
                          </div>
                        </div>
                      </div>
                      <div
                        name="profile-heading-data"
                        id="profile-heading-data"
                        class="profile-heading-data"
                      >
                        <div
                          name="profile-heading-company-name"
                          id="profile-heading-company-name"
                          class="text"
                        >
                          <h2
                            name="user-name-heading"
                            id="user-name-heading"
                            class="user-name-heading"
                            *ngIf="adminName"
                          >
                            {{ adminName }}
                          </h2>
                          <h2 class="user-name-heading" *ngIf="!adminName">
                            Not Available
                          </h2>
                          <div
                            name="profile-heading-company-data"
                            id="profile-heading-company-data"
                            class="d-flex flex-wrap text"
                          >
                            <div
                              name="profile-heading-location-spacing"
                              id="profile-heading-location-spacing"
                              class="left-spacing"
                            >
                              <label
                                name="profile-heading-location-data"
                                id="profile-heading-location-data"
                                class="label"
                                *ngIf="adminEmail"
                                >{{ adminEmail }}</label
                              >
                              <label class="label" *ngIf="!adminEmail"
                                >Not Available</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      name="main-container-action-box"
                      id="main-container-action-box"
                      class="action-box"
                      *ngIf="!profileBtnToggle"
                    >
                      <button
                        id="edit-profile-button"
                        class="btn-with-icon action-btn-box"
                        (click)="ToggleProfile()"
                      >
                        <img
                          name="view-profile-button"
                          id="edit-profile-icon"
                          class="btn-icon btn-bg"
                          [src]="imagePath + 'blue-edit-icon.svg'"
                          alt="edit-profile"
                        />Edit Profile
                      </button>
                    </div>

                    <div
                      name="main-container-action-box"
                      id="main-container-action-box"
                      class="action-box"
                      *ngIf="profileBtnToggle"
                    >
                      <button
                        name="view-profile-button"
                        id="view-profile-button"
                        class="btn-with-icon action-btn-box"
                        (click)="ToggleProfile()"
                      >
                        <img
                          id="view-profile-icon"
                          class="btn-icon btn-bg p-0"
                          [src]="imagePath + 'view-profile.svg'"
                          alt="view-profile"
                        />View Profile
                      </button>
                    </div>
                  </div>

                  <mat-divider></mat-divider>

                  <div class="form-section">
                    <div
                      name="user-details-modal-body"
                      id="user-details-modal-body"
                      class="modal-body modal-scroll pb-0"
                    >
                      <div class="form">
                        <div
                          name="user-details-col-1"
                          id="user-details-col-1"
                          class="col-12 col-md-12"
                          [ngClass]="
                            profileBtnToggle == false
                              ? 'view-profile-not-allowed'
                              : ''
                          "
                        >
                          <label
                            name="user-details-col-1-label"
                            id="user-details-col-1-label"
                            class="custom-label"
                            >First Name
                            <span class="text-danger">*</span></label
                          >
                          <mat-form-field
                            hideRequiredMarker
                            name="user-details-col-1-input"
                            id="user-details-col-1-input"
                            class="custom-input"
                            floatLabel="never"
                            floatPlaceholder="never"
                            [ngClass]="
                              profileBtnToggle == false
                                ? 'view-profile-not-allowed-event'
                                : ''
                            "
                          >
                            <input
                              type="text"
                              matInput
                              id="vFirstName"
                              formControlName="vFirstName"
                              placeholder="John"
                              required
                              [readonly]="profileBtnToggle ? false : true"
                            />
                            <mat-error
                              name="user-details-col-1-error-0"
                              id="user-details-col-1-error-0"
                              class="reduce-bottom-space"
                              *ngIf="
                                profileDetailsForm.controls['vFirstName']
                                  .invalid
                              "
                            >
                              <mat-error
                                name="user-details-col-1-error-1"
                                id="user-details-col-1-error-1"
                                class="reduce-bottom-space"
                                *ngIf="
                                  profileDetailsForm.controls['vFirstName']
                                    .errors!.required
                                "
                              >
                                First Name is required
                              </mat-error>
                              <mat-error
                                name="user-details-col-1-error-2"
                                id="user-details-col-1-error-2"
                                class="reduce-bottom-space"
                                *ngIf="
                                  profileDetailsForm.controls['vFirstName']
                                    .errors!.minlength
                                "
                              >
                                Minimum 2 characters are required
                              </mat-error>
                              <mat-error
                                name="user-details-col-1-error-4"
                                id="user-details-col-1-error-4"
                                class="reduce-bottom-space"
                                *ngIf="
                                  profileDetailsForm.controls['vFirstName']
                                    .errors!.maxlength
                                "
                              >
                                Must not exceed 46 characters
                              </mat-error>
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div
                          name="user-details-col-2"
                          id="user-details-col-2"
                          class="col-12 col-md-12"
                          [ngClass]="
                            profileBtnToggle == false
                              ? 'view-profile-not-allowed'
                              : ''
                          "
                        >
                          <label
                            name="user-details-col-1-label"
                            id="user-details-col-2-label"
                            class="custom-label"
                            >Last Name <span class="text-danger">*</span></label
                          >
                          <mat-form-field
                            hideRequiredMarker
                            name="user-details-col-2-input"
                            id="user-details-col-2-input"
                            class="custom-input"
                            floatLabel="never"
                            floatPlaceholder="never"
                            [ngClass]="
                              profileBtnToggle == false
                                ? 'view-profile-not-allowed-event'
                                : ''
                            "
                          >
                            <input
                              type="text"
                              matInput
                              id="vLastName"
                              formControlName="vLastName"
                              placeholder="Doe"
                              required
                              [readonly]="profileBtnToggle ? false : true"
                            />
                            <mat-error
                              name="user-details-col-2-error-0"
                              id="user-details-col-2-error-0"
                              class="reduce-bottom-space"
                              *ngIf="
                                profileDetailsForm.controls['vLastName']
                                  .invalid
                              "
                            >
                              <mat-error
                                name="user-details-col-2-error-1"
                                id="user-details-col-2-error-1"
                                class="reduce-bottom-space"
                                *ngIf="
                                  profileDetailsForm.controls['vLastName']
                                    .errors!.required
                                "
                              >
                                Last Name is required
                              </mat-error>
                              <mat-error
                                name="user-details-col-2-error-2"
                                id="user-details-col-2-error-2"
                                class="reduce-bottom-space"
                                *ngIf="
                                  profileDetailsForm.controls['vLastName']
                                    .errors!.minlength
                                "
                              >
                                Minimum 2 characters are required
                              </mat-error>
                              <mat-error
                                name="user-details-col-2-error-4"
                                id="user-details-col-2-error-4"
                                class="reduce-bottom-space"
                                *ngIf="
                                  profileDetailsForm.controls['vLastName']
                                    .errors!.maxlength
                                "
                              >
                                Must not exceed 46 characters
                              </mat-error>
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div
                          name="user-details-col-2"
                          id="user-details-col-3"
                          class="col-12 col-md-12"
                          [ngClass]="
                            profileBtnToggle == false
                              ? 'view-profile-not-allowed'
                              : 'view-profile-not-allowed'
                          "
                        >
                          <label
                            name="user-details-col-3-label"
                            id="user-details-col-3-label"
                            class="custom-label"
                            >Email <span class="text-danger">*</span>
                            <img
                              class="info-tooltip mx-2"
                              [src]="imagePath + 'info_icon.svg'"
                              alt="email-info"
                              placement="top"
                              ngbTooltip="It is not editable"
                          /></label>
                          <mat-form-field
                            hideRequiredMarker
                            name="user-details-col-3-input"
                            id="user-details-col-3-input"
                            class="custom-input pe-none"
                            floatLabel="never"
                            floatPlaceholder="never"
                          >
                            <input
                              type="text"
                              matInput
                              id="vEmailId"
                              formControlName="vEmailId"
                              placeholder="example@mail.com"
                              readonly="true"
                              required
                            />
                            <mat-error
                              name="user-details-col-3-error-0"
                              id="user-details-col-3-error-0"
                              class="reduce-bottom-space"
                              *ngIf="
                                profileDetailsForm.controls['vEmailId']
                                  .invalid
                              "
                            >
                              <mat-error
                                name="user-details-col-3-error-1"
                                id="user-details-col-3-error-1"
                                class="reduce-bottom-space"
                                *ngIf="
                                  profileDetailsForm.controls['vEmailId']
                                    .errors!.required
                                "
                              >
                                Email ID is required
                              </mat-error>
                              <mat-error
                                name="user-details-col-3-error-1"
                                id="user-details-col-3-error-1"
                                class="reduce-bottom-space"
                                *ngIf="
                                  profileDetailsForm.controls['vEmailId']
                                    .errors!.email
                                "
                              >
                                Enter Valid Email Address
                              </mat-error>
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div *ngIf="profileBtnToggle == true">
                          <mat-divider class="mt-1"></mat-divider>
                          <div
                            name="user-details-modal-footer"
                            id="user-details-modal-footer"
                            class="modal-footer px-0"
                          >
                            <button
                              type="submit"
                              name="user-details-modal-footer-btn-submit"
                              id="user-details-modal-footer-btn-submit"
                              class="btn btn-active"
                              form="profileDetailsForm"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              name="user-details-modal-footer-btn-cancel"
                              id="user-details-modal-footer-btn-cancel"
                              class="btn btn-outline-active"
                              (click)="GetProfileInfo(); CancelForm(); this.profileImageError = false; croppedImage=''"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #Crop let-c="close" let-d="dismiss">
  <div name="crop-modal-header" id="crop-modal-header" class="modal-header">
    <h1 name="crop-modal-title" id="crop-modal-title" class="modal-title">
      Crop Image
    </h1>
  </div>
  <div class="">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="4 / 4"
      [roundCropper]="true"
      format="png"
      (imageCropped)="ImageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
  <div name="crop-modal-footer" id="crop-modal-footer" class="modal-footer">
    <button
      type="submit"
      name="crop-modal-footer-btn-submit"
      id="crop-modal-footer-btn-submit"
      class="btn btn-active"
      (click)="d('cross click'); this.profileImageError = false"
    >
      Crop
    </button>
    <button
      type="button"
      name="crop-modal-footer-btn-cancel"
      id="crop-modal-footer-btn-cancel"
      class="btn btn-outline-active"
      (click)="d('cross click'); CancelCropModel()"
    >
      Cancel
    </button>
  </div>
</ng-template>
