import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalConfig,NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../config/event-emitter.service';
import { PermissionManagementService } from './permission-management.service';
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

@Component({
  selector: 'app-permission-management',
  templateUrl: './permission-management.component.html',
  styles: [
  ]
})
export class PermissionManagementComponent implements OnInit {
  // toggle actions permission
  editRolePermissionActionPermission: boolean = false;

  permission: any[] = [];
  vRoleName:any="";
  iRoleId: any;
  tiUserFeatureType:any;


  displayedColumnsTableOne: string[] = ['indexnumber','vRoleName', 'tiRoleType', 'iCreatedAt','tiStatus','actions'];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

  constructor(
    private permissionService: PermissionManagementService,
    public eventEmitterService: EventEmitterService, 
    private modalService: NgbModal,
    config: NgbModalConfig,
    private userPermissionService: UserPermissionService,
    private notifyService: NotificationService,
  )
  {
    config.backdrop = 'static';
    config.keyboard = false;

    // assign individual permissions
    const EditRolePermissionAction: Permission = Permission.EditRolePermissionAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(EditRolePermissionAction)) {
      this.editRolePermissionActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetRole();
  }

  OpenModal(modalToOpen: any) {
    this.modalService.open(modalToOpen, { centered: true });
  }

  OpenModelEditPermission(modelName:any, element:any, hasPermission: boolean) {
    if (hasPermission) {
      this.GetAllPermission(element.tiRoleType,element.iRoleId);
      this.modalService.open(modelName, { centered: true });
    }
  }


  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  GetRole() {
    this.permissionService.GetRole().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    }).catch((err: any) => {console.log(err)});
  }

  GetAllPermission(tiUserFeatureType:any,iRoleId:any){
    this.permission=[];
    this.vRoleName="";
    this.iRoleId=iRoleId;
    this.tiUserFeatureType=tiUserFeatureType;
    this.permissionService.GetAllPermission(tiUserFeatureType,iRoleId).then((result: any) => {
      if (result['code'] == 200) {
        this.vRoleName=result['data'].vRoleName;
        this.permission = result['data'].userPermissions;
      } else {
        this.notifyService.showError(result.message,'');
      }
    }).catch((err: any) => {console.log(err)});
  }

  EditPermission() {
    let temp:any=[];
    this.permission.map((parent:any)=>{
      if(parent.tiParentStatus)
      parent.child.map((child:any)=>{
        if(child.tiChildStatus){
          temp.push({iRoleId:this.iRoleId,iParentFeatureId:parent.iParentFeatureId,iChildFeatureId:child.iChildFeatureId,tiUserFeatureType:this.tiUserFeatureType});
        }
      });
    });

    let reqData = {
      permissions: temp,
    }

    this.permissionService.EditRolePermission(this.tiUserFeatureType,this.iRoleId,reqData).then((result: any) => {
      if (result['code'] == 200) {
        this.notifyService.showSuccess(result.message,'');
        this.modalService.dismissAll();
        this.permission=[];
        this.vRoleName="";
        this.iRoleId=undefined;
        this.tiUserFeatureType=undefined;
        this.GetRole();
      } else {
        this.notifyService.showError(result.message,'');
      }
    }).catch((err) => {
      console.log(err, "EditRolePermission");
    });
  }



  subPermissionChange(event:any, main:any, sub:any) {
    sub.tiChildStatus = event.target.checked;

    var items = main.child.filter((s:any) => s.tiChildStatus);

    main.tiParentStatus = items.length > 0;
    }

    mainPermissionChange(event:any, main:any){
      main.tiParentStatus = event.target.checked;
      main.child.map((s:any) =>{ 
        s.tiChildStatus=main.tiParentStatus
        return s;
      }
      );
    }

    Toggle(main:any){
      main.isExpanded=!(main.isExpanded);
    }

}
