
import { Router } from '@angular/router';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfileService } from "./profile.service";
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { StaticMsg } from 'src/app/config/en';
import { EventEmitterService } from 'src/app/config/event-emitter.service';
import { NotificationService } from 'src/app/config/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {

  imagePath = environment.image_path;

  @ViewChild("uploadFile") fileInput: any;
  fileAttr: any;

  //Variables
  editUserId:any=11;
  profileBtnToggle:any = true;
  profileImageError = false;
  profileDetailsForm!: FormGroup;
  adminName:any;
  adminEmail:any;
  subUserData:any = [];
  adminUserProfilePic : any = null;
  public oldImage: any = "";
  imageChangedEvent: any = "";
  croppedImage: any = "";
  profileImageFile:any = null;
  iSubUserId:any;

  constructor(config: NgbModalConfig, public eventEmitterService: EventEmitterService, private profileService: ProfileService,private notifyService: NotificationService, private modalService: NgbModal ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.GetProfileInfo();
    this.profileDetailsForm = new FormGroup({
      // newImage: new FormControl("",[Validators.required]),
      // newImage: new FormControl(""),
      vFirstName: new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46)]),
      vLastName:  new FormControl("", [Validators.required, Validators.minLength(2), Validators.maxLength(46)]),
      vEmailId:  new FormControl(""),
    });


  }

  ToggleProfile(){
    if(this.profileBtnToggle == false){
      this.profileBtnToggle = true;
      this.profileImageError = false;
      this.profileDetailsForm.reset();
      this.GetProfileInfo();

  }
  else{
    this.profileBtnToggle = false;
    this.profileImageError = false;
    this.profileDetailsForm.reset();
    this.GetProfileInfo();
  }
}



  // Get Admin Data 

  GetProfileInfo() {
    this.profileService.GetProfileInfo().then((result: any) => {
      if (result.code == 200) {
        
        this.oldImage = result["data"]["vUserProfilePhoto"];
        this.adminName = result.data.vFirstName + ' ' + result.data.vLastName;
        this.adminEmail = result.data.vEmailId;
        this.profileImageFile = this.oldImage;
        this.adminUserProfilePic = this.oldImage;
        this.profileDetailsForm.patchValue({
          vFirstName: result["data"]["vFirstName"],
          vLastName: result["data"]["vLastName"],
          vEmailId: result["data"]["vEmailId"],
          // newImage: result.data.vProfilePhoto,
        });
      }
    });
  }

  // Update Admin Details 
   UpdateProfile() {
    
    if (this.profileImageFile!=null) {
      this.profileImageError = false;
    } else {
      this.profileImageError = true;
      return
    }
      if (this.profileDetailsForm.invalid) {
        return;
      }

    let reqJson={
      vFirstName: this.profileDetailsForm.controls['vFirstName'].value,
      vLastName: this.profileDetailsForm.controls['vLastName'].value,
      vProfilePhoto : this.profileImageFile
    }

    this.profileService.EditProfile(reqJson,this.profileImageFile).then((result: any) => {
      if (result.code == 200) {   
        this.GetProfileInfo();
        this.eventEmitterService.GetProfileDetailsAPICallMethod();
        this.notifyService.showSuccess("Admin Edited Successfully", "");
      }
    });
    this.profileBtnToggle=false;
  }

  CancelForm(){
    this.profileDetailsForm.reset();
    this.profileBtnToggle=false;
  }


  // Profile Photo Functions
  fileChangeEvent(event: any, modelName: any): void {   
    let fileSize = event["target"]["files"][0]["size"] / 1024;
    if (fileSize > 1024) {
      this.notifyService.showError("Uploaded image is larger than 1 MB", "");
      return;
    }
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg") {
      this.imageChangedEvent = event;
      this.modalService.open(modelName, { centered: true, windowClass: "profile-modal" });
    } else {
      this.notifyService.showError(StaticMsg.NotValidImageTypeError, "");
      return;
    }
  }
  async ImageCropped(event: ImageCroppedEvent) {
    this.profileImageFile = null;
    this.croppedImage = event.base64;
    let base64Response = await fetch(this.croppedImage);
    let blob = await base64Response.blob();
    let fileType = blob.type.split("/")[1];
    this.profileImageFile = new File([blob], "file." + fileType);
  }
  CancelCropModel() {
    this.profileImageFile = this.adminUserProfilePic;
    this.imageChangedEvent = "";
    this.croppedImage = "";
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }


}
