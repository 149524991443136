import { Injectable } from '@angular/core';
import { environment } from "./../../../environments/environment";
import * as AWS from "aws-sdk";
import { encode as base64Encode, decode as base64Decode } from "base64-arraybuffer";
import * as ctj from "crypto-js";
import * as moment from "moment";
import * as moment_tz from 'moment-timezone';
import {encrypt as input , decrypt as output} from 'crypto-js/aes';

@Injectable({
  providedIn: 'root'
})
export class TranslateFormService {

  setzero:any[]=environment.aws_keys.set_zero;
  setone:any[]=environment.aws_keys.set_one;
  settwo:any[]=environment.aws_keys.set_two;

  constructor() { }

  EncryptData(plaintext: any) {
    let temp=this.getAws();
    const kms = new AWS.KMS({
      accessKeyId: temp.ACCESSKEYID,
      secretAccessKey: temp.SECRETACCESSKEY,
      region: temp.REGION,
    });


    return new Promise((resolve, reject) => {
      const params = {
        KeyId: environment.aws.key_id,
        Plaintext: JSON.stringify(plaintext),
        EncryptionAlgorithm: "RSAES_OAEP_SHA_1",
      };
      kms.encrypt(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.CiphertextBlob);
        }
      });
    });
  }

  convertExpression(key:any,array1:any,array2?:any){
    let keyValue="";
    let start=0;
    let end=2;
    let temp=2;

    if(array1[0][key].length==20){
      array1.map((obj:any)=>{
          keyValue=keyValue.concat(obj[key].slice(start, end));
          start=end;
          temp=temp+1;
          end=start+temp;
    });
  }else if(array1[0][key].length==40){
      array1.map((obj:any)=>{
        keyValue=keyValue.concat(obj[key].slice(start, end));
        start=end;
        temp=temp+1;
        end=start+temp;
    });
    temp=2;
    start=20;
    end=22;
      array2.map((obj:any)=>{
        keyValue=keyValue.concat(obj[key].slice(start, end));
        start=end;
        temp=temp+1;
        end=start+temp;
    });
  }
    return keyValue;
  }

  getAws(){
    let json={
      ACCESSKEYID:this.convertExpression('ACCESSKEYID',this.setzero),
      SECRETACCESSKEY:this.convertExpression('SECRETACCESSKEY',this.setone,this.settwo),
      REGION: "us-west-1",
    }

    return json;
  }

  DecryptData(encryptedtext: any) {

    let decryptedText = base64Decode(encryptedtext);

    let temp=this.getAws();
    const kms = new AWS.KMS({
      accessKeyId: temp.ACCESSKEYID,
      secretAccessKey: temp.SECRETACCESSKEY,
      region: temp.REGION,
    });  

    return new Promise((resolve, reject) => {
      const params = {
        KeyId: environment.aws.key_id,
        CiphertextBlob: decryptedText,
        EncryptionAlgorithm: "RSAES_OAEP_SHA_1",
      };
      kms.decrypt(params, (err:any, data:any) => {
        if (err) {
          reject(err);
        } else {
          let plaintext = JSON.parse(data.Plaintext);
          resolve(plaintext);
        }
      });
    });

  }

   
   //The Function Below To Encrypt Text
   staticPush(text:any){
    const pp = environment.crypto_js.key;
    const convertData=input(text, pp).toString();
    const cText=convertData.replace('/','1EAC09');
    return cText;
  }


  //The Function Below To Decrypt Text
  staticPop(cText:any) {
    const pp = environment.crypto_js.key;
    const tempText=cText.replace('1EAC09', '/');
    const bytes = output(tempText, pp);
    const oText = bytes.toString(ctj.enc.Utf8);
    const text=JSON.parse(oText);
    return text;
  }
  


   //The Function Below To Encrypt Text
   dynamicPush(text:any){
    const tempDate=new Date().toLocaleDateString('en-GB').split('/');
    const selectDate = new Date(tempDate[2]+"/"+tempDate[1]+"/"+tempDate[0]);
    const currentDateTime = moment(selectDate).format('YYYY-MM-DD, HH:mm:ss');
    const tempDd = moment_tz.tz(currentDateTime, "YYYY-MM-DD HH:mm:ss", "Europe/London");
    const convertedDateTime = tempDd.tz("Europe/London");
    const weekday:any=convertedDateTime.format("dddd");
    const temp = moment(tempDd).format('YYYY-MM-DD');
    const datetime= temp + " " + "00:00";
    const epoch:any = moment_tz.tz(datetime,"Europe/London").valueOf();   
    const pp = btoa(epoch+weekday);
    const convertData=input(text, pp).toString();
    const ctext=convertData.replace('/','1EAC09');
    return ctext;
  }


  //The Function Below To Decrypt Text
  dynamicPop(ctext:any) {
    const tempDate=new Date().toLocaleDateString('en-GB').split('/');
    const selectDate = new Date(tempDate[2]+"/"+tempDate[1]+"/"+tempDate[0]);
    const currentDateTime = moment(selectDate).format('YYYY-MM-DD, HH:mm:ss');
    const tempDd = moment_tz.tz(currentDateTime, "YYYY-MM-DD HH:mm:ss", "Europe/London");
    const convertedDateTime = tempDd.tz("Europe/London");
    const weekday=convertedDateTime.format("dddd");
    const temp = moment(tempDd).format('YYYY-MM-DD');
    const datetime= temp + " " + "00:00";
    const epoch = moment_tz.tz(datetime,"Europe/London").valueOf();   
    const pp = btoa(epoch+weekday);
    const tempText=ctext.replace('1EAC09', '/');
    const bytes = output(tempText, pp);
    const oText = bytes.toString(ctj.enc.Utf8);
    const text=JSON.parse(oText);
    return text;
  }


}
