import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EducationService {

  constructor(private http: HttpClient) { }

  GetDegreeList(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/list/emp-degree`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetBranchList(){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/list/emp-branch`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetEducation(talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/education?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddEducation(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/employee/v1/education?iUserId=${talentUserId}`;
      const formData: FormData = new FormData();
      formData.append("vDocUrl", data.vDocUrl);
      formData.append("iDegreeId", data.iDegreeId);
      formData.append("iBranchId", data.iBranchId);
      formData.append("iJoin", data.iJoin);
      formData.append("iComplete", data.iComplete);
      formData.append("vPercentage", data.vPercentage);
      formData.append("vUniversity", data.vUniversity);
      formData.append("iEduId", data.iEduId);
      formData.append("vOtherDegree", data.vOtherDegree);
      formData.append("vOtherBranch", data.vOtherBranch);
      this.http
        .post(apiURL, formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateEducation(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/employee/v1/education?iUserId=${talentUserId}`;
      const formData: FormData = new FormData();
      formData.append("vDocUrl", data.vDocUrl);
      formData.append("iDegreeId", data.iDegreeId);
      formData.append("iBranchId", data.iBranchId);
      formData.append("iJoin", data.iJoin);
      formData.append("iComplete", data.iComplete);
      formData.append("vPercentage", data.vPercentage);
      formData.append("vUniversity", data.vUniversity);
      formData.append("iEduId", data.iEduId);
      formData.append("vOtherDegree", data.vOtherDegree);
      formData.append("vOtherBranch", data.vOtherBranch);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
