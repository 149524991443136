<div class="container-fluid skill-management">
    <div class="row">
      <div class="col-12">
        <div class="wrapper">

          <div class="tab-content flex-grow-1 w-100" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="title" role="tabpanel" aria-labelledby="skill-management-tab">

              <div class="pagetitle-wrapper">
                <div class="pagetitle-block justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
                  <div class="d-flex align-items-center">
                    <i class="pagetitle-icon"><span class="material-icons">beenhere</span></i>
                    <h2 class="pagetitle">Employee Award</h2>
                  </div>
                  <button class="status-btn lightpurple-bg purple-text text-center ml-0 ml-sm-3 mr-0 mt-3 mt-sm-0 px-3" placement="bottom auto" [ngbTooltip]="addAwardActionPermission ? '':'Not Authorized'" [ngClass]="addAwardActionPermission ? '':'unauthorized-cursor'" (click)="OpenModal(addModel,addAwardActionPermission)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Add Technology">
                    <mat-icon class="mr-2">library_add</mat-icon> Add
                  </button>
                </div>
                <mat-form-field class="w-100" floatLabel="never" class="table-filter">
                  <button matSuffix mat-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <input matInput placeholder="Ex: Computer Science Engineering" (keyup)="applyFilterOne($event)" />
                </mat-form-field>
                <div class="table-responsive">
                  <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
                  </div>
                  <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }" [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                    <ng-container matColumnDef="indexnumber">
                      <th mat-header-cell *matHeaderCellDef> No </th>
                      <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableOnePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.TableOnePaginator.pageIndex * this.TableOnePaginator.pageSize}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                      <td mat-cell *matCellDef="let element">
                        <span>{{element.name}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="iCreatedAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Time </th>
                        <td mat-cell *matCellDef="let element">
                          <span>{{element.iCreatedAt * 1000 | date:'MM/dd/yy, h:mm a'}}</span>
                        </td>
                      </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> Actions </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex flex-wrap">
                          <button class="status-btn lightblue-bg blue-text text-center mb-2" placement="left auto" [ngbTooltip]="editAwardActionPermission ? '':'Not Authorized'" [ngClass]="editAwardActionPermission ? '':'unauthorized-cursor'" (click)="openModelEdit(editModel,element,editAwardActionPermission)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit">
                            <mat-icon>create</mat-icon>
                          </button>
                          <button class="status-btn lightpink-bg pink-text text-center mb-2" placement="left auto" [ngbTooltip]="deleteAwardActionPermission ? '':'Not Authorized'" [ngClass]="deleteAwardActionPermission ? '':'unauthorized-cursor'" (click)="DELETE(element, deleteAwardActionPermission)" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
                  </table>
                </div>
                <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }" #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
                </mat-paginator>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <ng-template #addModel let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Add Employee Award</h1>
          <form class="form" [formGroup]="addForm" (ngSubmit)="handleAdd()">
            <div class="row">
              <div class="col-12">
                <label class="label">Employee Award Name</label>
                <mat-form-field hideRequiredMarker appearance="outline">
                  <input type="text" formControlName="name" matInput placeholder="Ex. ">
                  <mat-error *ngIf="addForm.controls['name'].invalid && (addForm.controls['name'].dirty || addForm.controls['name'].touched)">
                    <mat-error *ngIf="addForm.controls['name'].errors.required">Employee Awaard Name is required</mat-error>
                    <mat-error *ngIf="addForm.controls['name'].errors?.minlength"> Minimum 3 character required </mat-error>
                    <mat-error *ngIf="addForm.controls['name'].errors?.maxlength"> Maximum 50 character allowed </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn">Add</button>
              <button type="button" class="modal-btn-outline" (click)="d('Cross click'); this.addForm.reset(); this.submitted = false;">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #editModel let-c="close" let-d="dismiss">
    <div class="employee-modal employee-modal-edit add-skill-modal">
      <div class="d-flex align-items-center content">
        <div class="w-100">
          <h1 class="title">Edit Employee Award</h1>
          <form class="form" [formGroup]="editForm" (ngSubmit)="handleEdit()">
            <div class="row">
              <div class="col-12">
                <label class="label">Employee Award Name</label>
                <mat-form-field class="profile-input" floatLabel="never" appearance="outline">
                  <input type="text" formControlName="name" matInput placeholder="Ex. Computer Science Engineering">
                  <mat-error *ngIf="editForm.controls['name'].invalid && (editForm.controls['name'].dirty || editForm.controls['name'].touched)">
                    <mat-error *ngIf="editForm.controls['name'].errors.required">Employee Award Name is required</mat-error>
                    <mat-error *ngIf="editForm.controls['name'].errors?.minlength"> Minimum 3 character required </mat-error>
                    <mat-error *ngIf="editForm.controls['name'].errors?.maxlength"> Maximum 50 character allowed </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-row btn-box">
              <button type="submit" class="modal-btn">Edit</button>
              <button type="button" class="modal-btn-outline" (click)="d('Cross click'); this.editForm.reset();">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
