import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalConfig,NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../../../config/event-emitter.service';
import { EmpCertificateService } from './emp-certificate.service';
import { Permission } from "./../../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

@Component({
  selector: 'app-emp-certificate',
  templateUrl: './emp-certificate.component.html'
})
export class EmpCertificateComponent implements OnInit {

  addForm!: FormGroup;
  editForm!:FormGroup;

  displayedColumnsTableOne: string[] = ['indexnumber','name','iCreatedAt','actions'];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

  // toggle actions permission
  addCertificateActionPermission: boolean = false;
  editCertificateActionPermission: boolean = false;
  deleteCertificateActionPermission: boolean = false;

  constructor(
    private service: EmpCertificateService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  )
  {
    config.backdrop = 'static';
    config.keyboard = false;

    // assign individual permissions
    const AddCertificateAction: Permission = Permission.AddCertificateAction;
    const EditCertificateAction: Permission = Permission.EditCertificateAction;
    const DeleteCertificateAction: Permission = Permission.DeleteCertificateAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddCertificateAction)) {
      this.addCertificateActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditCertificateAction)) {
      this.editCertificateActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeleteCertificateAction)) {
      this.deleteCertificateActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GET()
    this.addForm = new FormGroup({
      name: new FormControl('', [Validators.required,Validators.minLength(3),Validators.maxLength(100)]),
    });

    this.editForm = new FormGroup({
      id: new FormControl('', []),
      name: new FormControl('', [Validators.required,Validators.minLength(3),Validators.maxLength(100)]),
    });
  }

  OpenModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
    this.modalService.open(modalToOpen, { centered: true });
    }
  }

  openModelEdit(modelName:any, element:any, hasPermission: boolean) {
    if (hasPermission) {
    this.editForm.patchValue({
      id: element['id'],
      name: element['name']
    })
    this.modalService.open(modelName, { centered: true });
  }
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  GET() {
    this.service.GET().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    }).catch((err: any) => {console.log(err)});
  }

  handleAdd() {
    if (this.addForm.invalid) {
      return;
    }
    this.service.POST(this.addForm.value).then((result: any) => {
      if (result['code'] == 200) {
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message,'');
        this.GET();
      } else {
        this.modalService.dismissAll();
        this.notifyService.showError(result.message,'');
      }
    });
  }

  handleEdit() {
    if (this.editForm.invalid) {
      return;
    }
    this.service.PUT(this.editForm.value).then((result: any) => {
      if (result['code'] == 200) {
        this.notifyService.showSuccess(result.message,'');
        this.modalService.dismissAll();
        this.GET();
      } else {
        this.modalService.dismissAll();
        this.notifyService.showError(result.message,'');
      }
    });
  }

  DELETE(item: any, hasPermission: boolean): void {
    if (hasPermission) {
      this.service.DELETE(item.id).then((result: any) => {
        this.GET();
        this.notifyService.showSuccess(result.message,'');
      });
    }
  }

}
