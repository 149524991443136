import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { OnboardClientService } from './onboard-client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../../config/event-emitter.service';
import { NotificationService } from '../../../config/notification.service';
import { Permission } from "./../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../utils/services/user-permission.service";

@Component({
  selector: 'app-onboard-client',
  templateUrl: './onboard-client.component.html',
})
export class OnboardClientComponent implements OnInit {
    // toggle actions permission
    viewClientOnboardActionPermission: boolean = false;
    resendInviteLinkClientOnboardActionPermission: boolean = false;
    approveClientOnboardActionPermission: boolean = false;
    rejectClientOnboardActionPermission: boolean = false;

    @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
    @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;
    ticket: any;

    displayedColumnsTableOne: string[] = ['indexnumber','vDisplayUniqueId','vEmailId','vPhoneNo','iStatus','iCreatedAt','actions'];
    dataSourceTableOne = new MatTableDataSource();

    constructor(
      private service: OnboardClientService,
      public ngxSmartModalService: NgxSmartModalService,
      public eventEmitterService: EventEmitterService,
      private modalService: NgbModal,
      private notifyService : NotificationService,
      private userPermissionService: UserPermissionService
    ) {
      // assign individual permissions
      const ViewClientOnboardAction: Permission = Permission.ViewClientOnboardAction;
      const ResendInviteLinkClientOnboardAction: Permission = Permission.ResendInviteLinkClientOnboardAction;
      const ApproveClientOnboardAction: Permission = Permission.ApproveClientOnboardAction;
      const RejectClientOnboardAction: Permission = Permission.RejectClientOnboardAction;

      // check what user is authorized
      if (this.userPermissionService.isAuthorizedFor(ViewClientOnboardAction)) {
        this.viewClientOnboardActionPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(ResendInviteLinkClientOnboardAction)) {
        this.resendInviteLinkClientOnboardActionPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(ApproveClientOnboardAction)) {
        this.approveClientOnboardActionPermission = true;
      }
      if (this.userPermissionService.isAuthorizedFor(RejectClientOnboardAction)) {
        this.rejectClientOnboardActionPermission = true;
      }
    }

    ngOnInit(): void {
      this.getOnboardList();
    }

    ngAfterViewInit() {
      this.dataSourceTableOne.paginator = this.tableOnePaginator;
      this.dataSourceTableOne.sort = this.tableOneSort;
    }

    applyFilterOne(filterValue: any) {
      this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
    }

    public primarySkillData: any = [];
    getOnboardList() {
      this.service.getOnboardList().then((result: any) => {
        if (result['code'] == 200) {
          this.dataSourceTableOne.data = result['data'];
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }

    acceptOnboardTicket(data:any, hasPermission: boolean){
      if (hasPermission) {
        this.service.acceptOnboardTicket({iTicketId:data.iTicketId}).then((result: any) => {
          if (result['code'] == 200) {
            this.getOnboardList();
            this.notifyService.showSuccess(result.message,'');
          } else {
            this.notifyService.showError(result.message,'');
          }
        });
      }
    }

    rejectOnboardTicket(data:any, hasPermission: boolean) {
      if (hasPermission) {
        this.service.rejectOnboardTicket({iTicketId:data.iTicketId}).then((result: any) => {
          if (result['code'] == 200) {
            this.getOnboardList();
            this.notifyService.showSuccess(result.message,'');
          } else {
            this.notifyService.showError(result.message,'');
          }
        });
      }
    }

    OnboardingTicketView(data: any, modalToOpen: any, hasPermission: boolean){
      if (hasPermission) {
        this.service.OnboardingTicketView(data.iTicketId).then((result: any) => {
          if (result['code'] == 200) {
            this.ticket = result['data'];
            this.modalService.open(modalToOpen, { centered: true });
          } else {
            this.notifyService.showError(result.message,'');
          }
        });
      }
    }

    public vEmailId:any;
    ClientResendInviteLinkModal(modelName:any, vEmailId:any, hasPermission: boolean) {
      if (hasPermission) {
        this.modalService.open(modelName, { centered:true, windowClass: 'confirmation-modal' });
        this.vEmailId = vEmailId;
      }
    }

    RefreshClientInvite(vEmailId: any){
      this.service.RefreshClientInvite({vEmailId: vEmailId}).then((result: any) => {
        if (result.code == 200) {
          this.notifyService.showSuccess(result.message,'');
        }
      });
    }
}
