import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/config/notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DealEngineService } from './dealengine.service';
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";

@Component({
  selector: 'app-deal-engine',
  templateUrl: './deal-engine.component.html'
})

export class DealEngineComponent implements OnInit {
  // toggle actions permission
  createNewDealActionPermission: boolean = false;
  editDealActionPermission: boolean = false;
  cancelDealActionPermission: boolean = false;
  viewParticipatorActionPermission: boolean = false;
  activeDealActionPermission: boolean = false;
  viewDealHistoryActionPermission: boolean = false;

  getRequestsData: any = [];
  getOpenDealData: any = [];
  getClosedDealData: any = [];
  displayedColumnsOpenDeals: string[] = ['no', 'vDealCode', 'vDealName', 'vCompanyName', 'iDealOpeningDate', 'iDealClosingDate', 'status', 'actions'];
  displayedColumnsRequests: string[] = ['no', 'vDealCode', 'vDealName', 'vPartnerName', 'vPartnerCompanyLocation', 'iRequestDateTime', 'status', 'actions'];
  displayedColumnsHistory: string[] = ['no', 'vDealCode', 'vDealName', 'vCompanyName', 'iDealOpeningDate', 'iDealClosingDate', 'vPartnerName', 'status', 'actions'];

  dataSource = new MatTableDataSource();
  data: any = [];
  activeTab:any="Open Deals";
  TableSize: number = 5;
  TablePageIndex = 0;
  TableCurrentPage: number = 1;
  dismissDealModal: any;
  viewDealModal: any;
  editDealAsParDealIdModal: any;
  createDealModal: any;
  DealOpeningDate: any;
  DealClosingDate: any;
  filterTagFlagOpenDeal: any = 1;
  filterTagFlagforHistory: any = 2;
  searchKey: any = "";
  apiResponse: any;
  tableLength:any;
  getElementDataById: any;
  dismissData: any = []
  newOpenDealDateAfterEdit: any;
  newCloseDealDateAfterEdit: any;
  Today = new Date();
  Tomorrow = new Date();
  // TomorrowDate = new Date(this.Tomorrow.setDate(this.Tomorrow.getDate() + 1));
  TomorrowDate = new Date(this.Tomorrow.setDate(this.Tomorrow.getDate() + 1));
  countryListArray:any = [];

  // Tomorrow =  this.Today.setDate(this.Today.getDate() + 1);

  submitted = false;

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  createDealForm: FormGroup = new FormGroup({
    vDealName: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    vDealDescription: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    iDealTypeId: new FormControl('', [Validators.required]),
    iClientCompanySize: new FormControl('', [Validators.required]),
    iDealOpeningDate: new FormControl('', [Validators.required]),
    iDealClosingDate: new FormControl('', [Validators.required]),
    iClientHqLocation: new FormControl('', [Validators.required]),
    iDealStatus: new FormControl('', [Validators.required]),
    vCompanyName: new FormControl(null, [Validators.required]),
    iClientCompanyEmployees: new FormControl(null, []),
    iDealAmount: new FormControl(null, []),
    iDealRequirement: new FormControl(null, []),
    vDealInitiatorName: new FormControl(null, []),
    vDealOwner: new FormControl(null, []),
    iTRIF: new FormControl(null, []),
    iDealDuration: new FormControl(null, []),
    iDealChannel: new FormControl(null, []),
    txClientWebsite: new FormControl(null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
    iClientIndustry: new FormControl(null, []),
    vProspectName: new FormControl(null, []),
    vJobTitle: new FormControl(null, []),
    vEmail: new FormControl(null, [Validators.email]),
    vPhoneNumber: new FormControl(null, [Validators.minLength(6)]),
    txLinkedin: new FormControl(null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
    vLSRName: new FormControl(null, []),
    vClientCompanyRevenue: new FormControl(null, []),
    txComments: new FormControl(null, [Validators.maxLength(255)]),
  });

  updateDealForm: FormGroup = new FormGroup({
    vDealName: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    vDealDescription: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    iDealTypeId: new FormControl('', [Validators.required]),
    iClientCompanySize: new FormControl('', [Validators.required]),
    iDealOpeningDate: new FormControl('', [Validators.required]),
    iDealClosingDate: new FormControl('', [Validators.required]),
    iClientHqLocation: new FormControl('', [Validators.required]),
    iDealStatus: new FormControl('', [Validators.required]),
    vCompanyName: new FormControl(null, [Validators.required]),
    iClientCompanyEmployees: new FormControl(null, []),
    iDealAmount: new FormControl(null, []),
    iDealRequirement: new FormControl(null, []),
    vDealInitiatorName: new FormControl(null, []),
    vDealOwner: new FormControl(null, []),
    iTRIF: new FormControl(null, []),
    iDealDuration: new FormControl(null, []),
    iDealChannel: new FormControl(null, []),
    txClientWebsite: new FormControl(null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
    iClientIndustry: new FormControl(null, []),
    vProspectName: new FormControl(null, []),
    vJobTitle: new FormControl(null, []),
    vEmail: new FormControl(null, [Validators.email]),
    vPhoneNumber: new FormControl(null, [Validators.minLength(6)]),
    txLinkedin: new FormControl(null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
    vLSRName: new FormControl(null, []),
    vClientCompanyRevenue: new FormControl(null, []),
    txComments: new FormControl(null, [Validators.maxLength(255)]),
  });

  selectedDealFilter = 0;

  selectCompanySize: any = [
    { value: '1', viewValue: "Startup" },
    { value: '2', viewValue: "Micro" },
    { value: '3', viewValue: "Small" },
    { value: '4', viewValue: "Medium" },
    { value: '5', viewValue: "Enterprise" },
  ];
  selectedCompanySize = '0';

  selectClientHQLocation: any = [
    // { value: '233', viewValue: "USA" },
    // { value: '101', viewValue: "India" },
    // { value: '232', viewValue: "UK" },
    // { value: '14', viewValue: "Australia" },
    // { value: '231', viewValue: "UAE" },
    // { value: '204', viewValue: "South Africa" },
    // { value: '182', viewValue: "Russia" },
    // { value: '109', viewValue: "Japan" },
    // { value: '39', viewValue: "Canada" },
    // { value: '45', viewValue: "China" }
  ];
  
  selectedClientHQLocation = null;

  selectDealStatus: any = [
    { value: '1', viewValue: "Open" },
    // { value: '2', viewValue: "Lost" },
    // { value: '3', viewValue: "Won" },
    { value: '4', viewValue: "Upcoming" },
    
  ];
  selectedDealStatus = '0';
  selectedDealStatus1 = '1';
  selectedDealStatus2 = '4';

  selectDealRequirement: any = [
    { value: '1', viewValue: "Embedded" },
    { value: '2', viewValue: "IT" },
    { value: '3', viewValue: "Product" },
    { value: '4', viewValue: "BPO" },
    { value: '5', viewValue: "KPO" },
    { value: '6', viewValue: "Other" },
  ];
  selectedDealRequirement = '0';

  selectDealDurationInMonths: any = [
    { value: '1', viewValue: "1M-3M" },
    { value: '2', viewValue: "3M-6M" },
    { value: '3', viewValue: "6M-12M" },
    { value: '4', viewValue: "12M-24M" },
    { value: '5', viewValue: "24M+" },
  ];
  selectedDealDurationInMonths = '0'

  selectDealChannel: any = [
    { value: '1', viewValue: "Event Expo" },
    { value: '2', viewValue: "Inbound" },
    { value: '3', viewValue: "Outbound" },
    { value: '4', viewValue: "Client Visits" },
    { value: '5', viewValue: "Alliance Partners" },
    { value: '6', viewValue: "Event Online" },
    { value: '7', viewValue: "Referral" },
    { value: '8', viewValue: "UpSale" },
    { value: '9', viewValue: "Cross Sale" },
    { value: '10', viewValue: "Other" },
  ];
  selectedDealChannel = '0';

  selectClientIndustry: any = [
    // { value: '1', viewValue: "Information Technology and Services" },
    // { value: '2', viewValue: "Hospital & Health Care" },
    // { value: '3', viewValue: "Construction" },
    // { value: '4', viewValue: "Education Management" },
  ];
  selectedClientIndustry = null;

  constructor(
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private service: DealEngineService,
    private userPermissionService: UserPermissionService
  ) {
    // assign individual permissions
    const CreateNewDealAction: Permission = Permission.CreateNewDealAction;
    const EditDealAction: Permission = Permission.EditDealAction;
    const CancelDealAction: Permission = Permission.CancelDealAction;
    const ViewParticipatorAction: Permission = Permission.ViewParticipatorAction;
    const ActiveDealAction: Permission = Permission.ActiveDealAction;
    const ViewDealHistoryAction: Permission = Permission.ViewDealHistoryAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(CreateNewDealAction)) {
      this.createNewDealActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditDealAction)) {
      this.editDealActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(CancelDealAction)) {
      this.cancelDealActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ViewParticipatorAction)) {
      this.viewParticipatorActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ActiveDealAction)) {
      this.activeDealActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ViewDealHistoryAction)) {
      this.viewDealHistoryActionPermission = true;
    }

    // this.data = this.dataSource.data;
    // this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.GetCountryList();
    this.GetIndustryList();
    this.getDealCount();
    this.getDealType();
    this.getAllDealData(1);
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // Api Data for All Deals  
  async getAllDealData(id: any, label?: any) {
    this.TableCurrentPage =1;
    if(label){
      this.activeTab=label;
    }
    if(this.activeTab=="Open Deals"){
      id=1;
    }else if(this.activeTab=="Requests"){
      id=3;
    }else if(this.activeTab=="History"){
      id=2;
    }
    this.service.GetAllDealData(id, this.searchKey, this.dealTypeDeal).then(async (result: any) => {
      if (result.code == 200) {
        this.apiResponse = result['data'];
        this.data= result['data'];
        this.tableLength = result['data'].length;
        this.dataSource.data = this.data.slice(1 * this.TableSize - this.TableSize, 1 * this.TableSize);
        // this.dataSource.data = result['data'];
        if (label == "Requests" || this.activeTab == "Requests") {
          let temparr = this.apiResponse.filter((obj: any) => {
            return obj.partnerDetails.length > 0;
          })
          // let temparr = this.dataSource.data;
          let arr: any[] = [];
          await temparr.map((obj: any) => {
            obj.partnerDetails.map((ele: any) => {
              let tempobj: any = JSON.stringify(obj);
              tempobj = JSON.parse(tempobj);
              tempobj.vPartner = ele.vPartner;
              tempobj.vPartnerId = ele.vPartnerId;
              tempobj.vPartnerCountry = ele.vPartnerCountry;
              tempobj.vCompanyLogoUrl = ele.vCompanyLogoUrl;
              tempobj.tiPartnerDealStatus = ele.tiPartnerDealStatus;
              tempobj.iCreatedAt = ele.iCreatedAt;
              arr.push(tempobj);
            })
            return obj.partnerDetails.length == 0;
          });
          await this.temp(arr);
        }
        if (label == "History" || this.activeTab == "History") {
          // let temparr = this.apiResponse.filter((obj: any) => {
          //   return || obj.;
          // })
          // let temparr = this.dataSource.data;
          let arr: any[] = [];
          await this.apiResponse.map((obj: any) => {
            if(obj.partnerDetails.length>0){
            obj.partnerDetails.map((ele: any) => {
              let tempobj: any = JSON.stringify(obj);
              tempobj = JSON.parse(tempobj);
              tempobj.vPartner = ele.vPartner;
              tempobj.vPartnerId = ele.vPartnerId;
              tempobj.vPartnerCountry = ele.vPartnerCountry;
              tempobj.vCompanyLogoUrl = ele.vCompanyLogoUrl;
              tempobj.tiPartnerDealStatus = ele.tiPartnerDealStatus;
              tempobj.iCreatedAt = ele.iCreatedAt;
              arr.push(tempobj);
            })
          }else{
            arr.push(obj);
          }
            return obj.partnerDetails.length == 0;
          });
          await this.tempHistory(arr);
        }

        this.getDealCount();
      }
    });
  }

  tempHistory(arr: any) {
    console.log(arr,">>temparrtemparrtemparr");
    
    let temparr = arr.filter((obj: any) => {
      return obj.tiPartnerDealStatus==3 || obj.iDealStatus ==2;
    })
    this.data= temparr;
    this.dataSource.data = this.data.slice(1 * this.TableSize - this.TableSize, 1 * this.TableSize);;
    this.tableLength = temparr.length;
  }

  temp(arr: any) {
    this.data= arr;
    this.dataSource.data = this.data.slice(1 * this.TableSize - this.TableSize, 1 * this.TableSize);;
    this.tableLength = arr.length;
  }
errorFlag : boolean = false;
editErrorFlag: boolean = false;
  // Date Change method to change status of Deal Status*
  DatechangeforUpcomingStatus(event:any){
    let datePickerValue = event.value.toDateString();
    let datePickerValueSelectDate = event.value.toString();
    let formopenDateEpoch = new Date(this.createDealForm.controls['iDealOpeningDate'].value).getTime() / 1000 ;
    let formclosedDateEpoch = new Date(this.createDealForm.controls['iDealClosingDate'].value).getTime() / 1000 ;
    if(formopenDateEpoch > formclosedDateEpoch){
      this.errorFlag = true
    }else{
      this.errorFlag = false;
    }

    let editformopenDateEpoch = new Date(this.updateDealForm.controls['iDealOpeningDate'].value).getTime() / 1000 ;
    let editformclosedDateEpoch = new Date(this.updateDealForm.controls['iDealClosingDate'].value).getTime() / 1000 ;
    
    if(editformopenDateEpoch > editformclosedDateEpoch){
      this.editErrorFlag = true
    }else{
      this.editErrorFlag = false;
    }

    let tempDate=  new Date(datePickerValueSelectDate);
    this.TomorrowDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
   
    
    let today = this.Today.toDateString();
    if(datePickerValue == today){
      this.selectedDealStatus1 = '1';
      this.createDealForm.controls['iDealStatus'].setValue(this.selectedDealStatus1);
      this.updateDealForm.controls['iDealStatus'].setValue(this.selectedDealStatus1);
      
    }else {
      this.selectedDealStatus2 = '4';
      this.createDealForm.controls['iDealStatus'].setValue(this.selectedDealStatus2);
      this.updateDealForm.controls['iDealStatus'].setValue(this.selectedDealStatus2);
    }
  }

  DateCloseChange(){
    let formopenDateEpoch = new Date(this.createDealForm.controls['iDealOpeningDate'].value).getTime() / 1000 ;
    let formclosedDateEpoch = new Date(this.createDealForm.controls['iDealClosingDate'].value).getTime() / 1000 ;
    
    if(formopenDateEpoch > formclosedDateEpoch){
      this.errorFlag = true
    }else{
      this.errorFlag = false;
    }

  }


  EditDateCloseChange(){
    let formopenDateEpoch = new Date(this.updateDealForm.controls['iDealOpeningDate'].value).getTime() / 1000 ;
    let formclosedDateEpoch = new Date(this.updateDealForm.controls['iDealClosingDate'].value).getTime() / 1000 ;
    
    if(formopenDateEpoch > formclosedDateEpoch){
      this.editErrorFlag = true
    }else{
      this.editErrorFlag = false;
    }

  }


  // get Deal Count value
  countDeals: any = [];
  getDealCount() {
    this.service.DealCount().then((result: any) => {
      if (result.code == 200) {
        this.countDeals = result['data'];
      }
    });
  }

  // get Deal Type Dropdown value
  dealType: any = [];
  getDealType() {
    this.service.DealType().then((result: any) => {
      if (result.code == 200) {
        this.dealType = result['data'];
      }
    });
  }

  // edit Data Api call
  editUserData: any;
  editUserDealId: any;
  editedDataOfDeal: any = [];
  DealCode: any;
  DealName: any;
  async getDealDataByIdForEdit(id: any) {
    await this.service.GetDealDataByID(id).then((result: any) => {
      if (result.code == 200) {
        this.editUserData = result['data'];
        this.editUserDealId = this.editUserData.iDealId;
        this.DealName = this.editUserData.vDealName;
        this.DealCode = this.editUserData.vDealCode;
      
        this.updateDealForm.patchValue({
          vDealName: this.editUserData.vDealName,
          vDealDescription: this.editUserData.vDealDescription,
          iDealTypeId: this.editUserData.iDealTypeId,
          iClientCompanySize: this.editUserData.iClientCompanySize,
          iDealOpeningDate: new Date(this.editUserData.iDealOpeningDate * 1000),
          iDealClosingDate: new Date(this.editUserData.iDealClosingDate * 1000),
          iClientHqLocation: this.editUserData.iClientHqLocation,
          iDealStatus: this.editUserData.iDealStatus,
          vCompanyName: this.editUserData.vCompanyName,
          iClientCompanyEmployees: this.editUserData.iClientCompanyEmployees,
          iDealAmount: this.editUserData.iDealAmount,
          iDealRequirement: this.editUserData.iDealRequirement,
          vDealInitiatorName: this.editUserData.vDealInitiatorName,
          vDealOwner: this.editUserData.vDealOwner,
          iTRIF: this.editUserData.iTRIF,
          iDealDuration: this.editUserData.iDealDuration,
          iDealChannel: this.editUserData.iDealChannel,
          txClientWebsite: this.editUserData.txClientWebsite,
          iClientIndustry: this.editUserData.iClientIndustry,
          vProspectName: this.editUserData.vProspectName,
          vJobTitle: this.editUserData.vJobTitle,
          vEmail: this.editUserData.vEmail,
          vPhoneNumber: this.editUserData.vPhoneNumber,
          txLinkedin: this.editUserData.txLinkedin,
          vLSRName: this.editUserData.vLSRName,
          vClientCompanyRevenue: this.editUserData.vClientCompanyRevenue,
          txComments: this.editUserData.txComments,
        });
        this.selectedCompanySize = String(this.editUserData.iClientCompanySize);
        this.selectedClientHQLocation = this.editUserData.iClientHqLocation;
        this.selectedDealStatus = String(this.editUserData.iDealStatus);
        this.selectedDealRequirement = String(this.editUserData.iDealRequirement);
        this.selectedDealDurationInMonths = String(this.editUserData.iDealDuration);
        this.selectedDealChannel = String(this.editUserData.iDealChannel);
        this.selectedClientIndustry = this.editUserData.iClientIndustry;
      }
    });
  }

  updateFlag: boolean = true;
  UpdateFlag(temp: any) {
    this.updateFlag = false;
  }


  // Dismiss deal api call
  dismissDealDataById(id: any) {
    this.service.DismissDealDataByID(id, '').then((result: any) => {
      if (result.code == 200) {
        this.notifyService.showSuccess('Deal Dismiss Sccessfully!', '');
        this.dismissData = result['data'];
        this.modalService.dismissAll();
        this.getAllDealData(1);
      }
    });
  }

  // Update Api Call
  EditDealProcess() {
    if(this.editErrorFlag==true){
      return;
    }
    // this.editedDataOfDeal = this.updateDealForm.value;
    this.newOpenDealDateAfterEdit = new Date(this.updateDealForm.value['iDealOpeningDate']).getTime() / 1000;
    this.newCloseDealDateAfterEdit = new Date(this.updateDealForm.value['iDealClosingDate']).getTime() / 1000;

    this.service.UpdateDeal(this.editUserDealId, this.updateDealForm.value, this.newOpenDealDateAfterEdit, this.newCloseDealDateAfterEdit).then((result: any) => {
      if (result.code == 200) {
        this.notifyService.showSuccess('Deal Update Successfully!', '');
        this.getAllDealData(1);
        this.selectedCompanySize = '0';
        this.selectedClientHQLocation = null;
        this.selectedDealStatus = '0';
        this.selectedDealRequirement = '0';
        this.selectedDealDurationInMonths = '0';
        this.selectedDealChannel = '0';
        this.selectedClientIndustry = null;
        this.updateDealForm.reset();
        this.modalService.dismissAll();
      } else {
        this.modalService.dismissAll();
        this.notifyService.showSuccess('Deal Not Updated!', '');
      }
    });
  }

  // validation error function
  get g(): any {
    return this.updateDealForm.controls;
  }

  // get id for dismiss Data 
  getIdForDismiss(element: any) {
    this.getElementDataById = element;
  }

  // close model service
  CloseModal() {
    this.modalService.dismissAll();
  }

  // Filter method for all value on selection filter
  dealTypeDeal: any = '';
  filterValue() {
    if (this.selectedDealFilter == 0) {
      this.dealTypeDeal = '';
      this.getAllDealData(1);
    }
    else {
      this.dealTypeDeal = this.selectedDealFilter;
      this.getAllDealData(1);
    }
  }

  // keyup filter
  filterOnKeyUp(value: any){
    this.searchKey = value;
    this.getAllDealData(1);
  }

  // pagination 
  paginate(event: any) {
    this.TablePageIndex = event;

    this.dataSource.data = this.data.slice(event * this.TableSize - this.TableSize, event * this.TableSize);
  }

  // Open Create deal modal function
  OpenCreateDealModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.createDealForm.reset();
      this.selectedDealStatus1 = '1';
      this.createDealForm.controls['iDealStatus'].setValue(this.selectedDealStatus1);
      this.createDealModal = this.modalService.open(modalToOpen, { centered: true, windowClass: 'create-deal-modal' });
    }
  }

  // Create New Deal Function Api call
  CreateDealProcess() {
    this.submitted = true;
    if(this.errorFlag==true){
      return;
    }
    if(this.createDealForm.invalid){
      // console.log("this.createDealForm.invalid");
      return;
    }
    else {
      this.DealOpeningDate = new Date(this.createDealForm.value['iDealOpeningDate']).getTime() / 1000;
      this.DealClosingDate = new Date(this.createDealForm.value['iDealClosingDate']).getTime() / 1000;
      let vClientCompanySize = this.selectCompanySize.map((ele:any)=>{
        if(ele.value == this.createDealForm.controls.iClientCompanySize.value ){
          return ele.viewValue; 
        }
      });
      this.service.CreateDeal(this.createDealForm.value, this.DealOpeningDate, this.DealClosingDate,vClientCompanySize).then((result: any) => {
        if (result.code == 200) {
          this.modalService.dismissAll();
          this.notifyService.showSuccess('Deal Added Successfully!', '');
          this.createDealForm.reset();
          // redirect after create new deal
          let buttonTab = document.getElementById('OpenDealID') as HTMLButtonElement | null;
          buttonTab?.click();
        }
        else {
          this.modalService.dismissAll();
          this.notifyService.showSuccess('Cancel Deal!', '');
        }
      });
    }
  }

  // validation error function
  get f(): any {
    return this.createDealForm.controls;
  }

  // Open Edit deal modal function
  OpenEditDealAsParDealIDModal(modalToOpen: any,id:any, hasPermission: boolean){
    if (hasPermission) {
      // this.selectedDealType=  '0';
      this.selectedCompanySize = '0';
      this.selectedClientHQLocation = null;
      this.selectedDealStatus = '0';
      this.selectedDealRequirement = '0';
      this.selectedDealDurationInMonths = '0';
      this.selectedDealChannel = '0';
      this.selectedClientIndustry = null;
      this.updateDealForm.reset();
      this.getDealDataByIdForEdit(id);
      this.editDealAsParDealIdModal = this.modalService.open(modalToOpen, { centered: true, windowClass: 'create-deal-modal' });
    }
  }

  // cancel Detail button function
  CancelDetail(){
    // this.selectedDealType=  '0';
    this.selectedCompanySize = '0';
    this.selectedClientHQLocation = null;
    this.selectedDealStatus = '0';
    this.selectedDealRequirement = '0';
    this.selectedDealDurationInMonths = '0';
    this.selectedDealChannel = '0';
    this.selectedClientIndustry = null;
    this.updateDealForm.reset();
    this.modalService.dismissAll();
  }

  // Open Dismiss deal modal function
  OpenDismissDealAsParDealIDModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.dismissDealModal = this.modalService.open(modalToOpen, { centered: true, windowClass: 'dismiss-deal-modal' });
    }
  }

  // Open View deal modal function

  getDataOfPartner: any = [];
  OpenViewDealAsParDealIDModal(modalToOpen: any, id: any, iDealId: any, hasPermission: boolean) {
    if (hasPermission) {
      this.viewDealModal = this.modalService.open(modalToOpen, { centered: true, windowClass: 'view-deal-modal' });
      this.service.GetPartnerDealDatabyId(id, iDealId).then((result: any) => {
        if (result.code == 200) {
          this.getDataOfPartner = result['data'];
        }
      });
    }
  }


  // Action to Win Participate api call for win partner 
  async ActionToWinParticipate(partnerid: any, dealid: any, label: any, hasPermission: boolean) {
    if (hasPermission) {
      this.service.EarnedPartner(partnerid, dealid).then(async (result: any) => {
        if (result.code == 200) {
          this.getAllDealData(3, label);
          this.notifyService.showSuccess('Partner earned successfully!', '');
        } else {
        }
      });
    }
  }

  // country list
  GetCountryList(){
    this.service.GetCountryList().then((result: any) => {
      if (result.code == 200) {
        this.selectClientHQLocation = result['data'];
        
      }
    });
  }

    // country list
    GetIndustryList(){
      this.service.GetIndustryList().then((result: any) => {
        if (result.code == 200) {
          this.selectClientIndustry = result['data'];
          
        }
      });
    }
  

}

