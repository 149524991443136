import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PersonalDetailsService {
  constructor(private http: HttpClient) {}

  GetCountryList() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/countryList`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetStateList(countryId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/stateList?iCountryId=` + countryId;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetCityList(stateId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/cityList?iStateId=` + stateId;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetHobbyList() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/list/hobbies`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UpdatePersonalDetails(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/personal-details?iUserId=${talentUserId}`;
      const formData: FormData = new FormData();
      if (data.newImage !== undefined || data.newImage !== null) {
        formData.append("vProfilePhoto", data.newImage);
      }
      if (data.vVideo !== undefined || data.vVideo !== null) {
        formData.append("introvideo", data.vVideo);
      }
      formData.append("vLastName", data.vEmpLastName);
      formData.append("vFirstName", data.vEmpFirstName);
      formData.append("vEmailId", data.vEmailId);
      formData.append("iDob", data.iBirthDate);
      formData.append("vDialCode", data.vDialCode);
      formData.append("vPhoneNo", data.iPhoneNumber);
      formData.append("vCountryCode", data.vCountryCode);
      formData.append("iCountryId", data.iCountryId);
      formData.append("iStateId", data.iStateId);
      formData.append("iCityId", data.iCityId);
      formData.append("txBio", data.iEmpBio);
      formData.append("vAddress", data.vAddress);
      formData.append("iGeneder", data.vGender);
      formData.append("iZipCode", data.iZipCode);
      formData.append("empHobby", data.iHobbies);

      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetPersonalDetail(talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/personal-details?iUserId=${talentUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  IntroVideoThumbDelete(data: any,talentUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/employee/v1/intro-video-thumbnail?iUserId=${talentUserId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
