import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewTalentService } from './view-talent.service';
import { NotificationService } from "../../../config/notification.service";
import { EventEmitterService } from "../../../config/event-emitter.service";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StaticMsg } from "src/app/config/en";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { Permission } from "../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../utils/services/user-permission.service";


@Component({
  selector: 'app-view-talent',
  templateUrl: './view-talent.component.html',
  providers: [NgbModalConfig, NgbModal],
})
export class ViewTalentComponent implements OnInit {
  public personalDetailsCardCompleted: any = false;
  public professionalDetailsCardCompleted: any = false;
  public educationCardCompleted: any = false;
  public projectPortfolioCardCompleted: any = false;
  public awardsDetailsCardCompleted: any = false;
  public certificateDetailsCardCompleted: any = false;


  imagePath = environment.image_path;
  
  talentUserId:any;
  targetLink:any=1;

  goToLink(targetLink:any) {
    this.targetLink=targetLink;
    this.GetProfileStatus();
  }

  publishTalentProfileActionPermission: boolean = false;

  constructor(  private cdr: ChangeDetectorRef,public eventEmitterService: EventEmitterService, private notifyService: NotificationService,private activatedroute: ActivatedRoute,private router:Router,config: NgbModalConfig, private userPermissionService: UserPermissionService, private modalService: NgbModal, private service: ViewTalentService) {

    // assign individual permissions
    const PublishTalentProfileAction: Permission = Permission.PublishTalentProfileAction;

    // check what user is authorized

    if (this.userPermissionService.isAuthorizedFor(PublishTalentProfileAction)) {
      this.publishTalentProfileActionPermission = true;
    }

    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {

    this.talentUserId = this.activatedroute.snapshot.paramMap.get("talentUserId");
    this.GetProfileStatus();
    this.eventEmitterService.GetProfileStatusAPISubMethod.subscribe(() => {
      this.GetProfileStatus();
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  
  public iProfilePublishCompleted: any = 0;
  public progressValue: any;
  public partnerCompanyName: any;
  GetProfileStatus() {
    this.service.GetProfileStatus(this.talentUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result["data"]["iProfilePublishCompleted"];
        this.progressValue = result["data"]["progressValue"];
        this.partnerCompanyName = result["data"]["partnerCompanyName"];
        this.personalDetailsCardCompleted= result["data"]["personalDetailsCardCompleted"];
        this.professionalDetailsCardCompleted= result["data"]["professionalDetailsCardCompleted"];
        this.educationCardCompleted= result["data"]["educationCardCompleted"];
        this.projectPortfolioCardCompleted= result["data"]["projectPortfolioCardCompleted"];
        this.awardsDetailsCardCompleted= result["data"]["awardDetailsCardCompleted"];
        this.certificateDetailsCardCompleted= result["data"]["certificateDetailsCardCompleted"];
        this.eventEmitterService.GetProfileStatusCallMethod(result["data"]);
      }
    });
  }

  PublishProfile() {
    if (this.progressValue < 100) {
      this.notifyService.showError(StaticMsg.ProfileCompleteError, "");
    } else {
      this.service.PublishProfile({},this.talentUserId).then((result: any) => {
        if (result.code == 200) {
          this.GetProfileStatus();
          this.notifyService.showSuccess(result.message, "");
          this.eventEmitterService.GetProfileStatusAPICallMethod();
          this.eventEmitterService.MenuListCallMethod();
        } else {
          this.notifyService.showError(result.message, "");
        }
      });
    }
  }

}
