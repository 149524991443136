import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { EmployeePublishService } from './employee-publish.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../../config/event-emitter.service';
import { environment } from '../../../../environments/environment';
import { Permission } from "./../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

@Component({
  selector: 'app-employee-publish',
  templateUrl: './employee-publish.component.html'
})
export class EmployeePublishComponent implements OnInit {
  // toggle actions permission
  viewEmployeePublishActionPermission: boolean = false;
  approveEmployeePublishActionPermission: boolean = false;
  rejectEmployeePublishActionPermission: boolean = false;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;
  ticket: any;

  displayedColumnsTableOne: string[] = ['indexnumber','vEmailId','actions'];
  dataSourceTableOne = new MatTableDataSource();

  constructor(
    private employeeService: EmployeePublishService,
    public ngxSmartModalService: NgxSmartModalService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  ) {
    // assign individual permissions
    const ViewEmployeePublishAction: Permission = Permission.ViewEmployeePublishAction;
    const ApproveEmployeePublishAction: Permission = Permission.ApproveEmployeePublishAction;
    const RejectEmployeePublishAction: Permission = Permission.RejectEmployeePublishAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ViewEmployeePublishAction)) {
      this.viewEmployeePublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ApproveEmployeePublishAction)) {
      this.approveEmployeePublishActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(RejectEmployeePublishAction)) {
      this.rejectEmployeePublishActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetEmployeePublish();
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  public primarySkillData: any = [];
  GetEmployeePublish() {
    this.employeeService.GetEmployeePublish().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    });
  }

  acceptEmployeePublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.employeeService.acceptEmployeePublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetEmployeePublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }
  }

  rejectEmployeePublish(data:any, hasPermission: boolean){
    if (hasPermission) {
      this.employeeService.rejectEmployeePublish({iUserId:data.iUserId}).then((result: any) => {
        if (result['code'] == 200) {
          this.GetEmployeePublish();
          this.notifyService.showSuccess(result.message,'');
        } else {
          this.notifyService.showError(result.message,'');
        }
      });
    }
  }

  publishView(data: any, modalToOpen: any, hasPermission: boolean){
    if (hasPermission) {
      this.GetProfileStatus(data['iUserId']);
      this.modalService.open(modalToOpen, { centered: true, windowClass: 'wide-modal' });
      setTimeout(() => {
        this.eventEmitterService.viewEmpPublish_Pub(data['iUserId']);
      }, environment.view_open_model);
    }
  }

  public iProfilePublishCompleted:any = 0;
  public progressValue:any;
  public partnerCompanyName:any;
  GetProfileStatus(iUserId:any){
    this.employeeService.GetProfileStatus(iUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result['data']['iProfilePublishCompleted'];
        this.progressValue = result['data']['progressValue'];
        this.partnerCompanyName = result['data']['partnerCompanyName'];
        this.eventEmitterService.GetProfileStatusCallMethod(result['data']);
      }
    });
  }
}
