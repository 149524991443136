import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ParentPermissionManagementService {
  constructor(private http: HttpClient) {}

  GetParentPermissions() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/parent-feature`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  AddParentPermission(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/parent-feature`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  EditParentPermission(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/parent-feature`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteParentPermission(iParentFeatureId	: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + "/admin/v2/parent-feature/" + iParentFeatureId	;
      this.http
        .delete(apiURL)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }
}
