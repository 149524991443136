import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EventEmitterService } from "src/app/config/event-emitter.service";
import { ContractsService } from "./contracts.service";
import { Permission } from "src/app/utils/enums/permission.enum";
import { UserPermissionService } from "src/app/utils/services/user-permission.service";
import { NotificationService } from "src/app/config/notification.service";
import { DocumentType } from "./../../config/en";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html'
})
export class ContractsComponent implements OnInit {
  imagePath = environment.image_path;

  // 
  contractsData: any;
  contractId: number;
  rejectContractForm!: FormGroup;
  clientSignedStatus = true;
  partnerSignedStatus = true;
  view: any = 0;

  filterType: any = "0";
  displayedColumnsTableOne: string[] = [
    "indexNumber",
    "vContractUniqueId",
    "vTalentName",
    "iContractStartDate",
    "iHiringDuration",
    "fAmount",
    "tiContractStatus",
    "actions",
  ];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild("TableOnePaginator", { static: true })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort", { static: true }) tableOneSort: MatSort;

  // toggle actions permission
  approveContractActionPermission: boolean = false;
  rejectContractActionPermission: boolean = false;
  downloadContractPDFActionPermission: boolean = false;
  signClientContractActionPermission: boolean = false;
  signPartnerContractActionPermission: boolean = false;

  constructor(
    private contractService: ContractsService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private userPermissionService: UserPermissionService,
    private notifyService : NotificationService
  ) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const ApproveContractAction: Permission = Permission.ApproveContractAction;
    const RejectContractAction: Permission = Permission.RejectContractAction;
    const DownloadContractPDFAction: Permission =
      Permission.DownloadContractPDFAction;
    const SignClientContractAction: Permission =
      Permission.SignClientContractAction;
    const SignPartnerContractAction: Permission =
      Permission.SignPartnerContractAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ApproveContractAction)) {
      this.approveContractActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(RejectContractAction)) {
      this.rejectContractActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DownloadContractPDFAction)) {
      this.downloadContractPDFActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(SignClientContractAction)) {
      this.signClientContractActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(SignPartnerContractAction)) {
      this.signPartnerContractActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetContracts();

    this.rejectContractForm = new FormGroup({
      rejectComment: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(150),
      ]),
    });

  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  allDetails() {
    this.view = "0";
    this.GetContracts();
  }

  upcomingDetails() {
    this.view = "1";
    this.GetContracts();
  }

  clientDetails() {
    this.view = "3";
    this.GetContracts();
  }

  historyDetails() {
    this.view = "2";
    this.GetContracts();
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value
      .trim()
      .toLowerCase();
  }

  selectContractType() {
    if (this.filterType == "0") {
      this.dataSourceTableOne.data = this.contractsData;
    } else {
      let filteredData = this.contractsData.filter((item: any) => {
        return item.tiReasonType == this.filterType;
      });
      this.dataSourceTableOne.data = filteredData;
    }
  }

  OpenAcceptContractModal(
    modalToOpen: any,
    contractData: any,
    hasPermission: boolean
  ) {
    if (hasPermission) {
      this.contractId = contractData.iContractId;
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  OpenRejectContractModal(
    modalToOpen: any,
    contractData: any,
    hasPermission: boolean
  ) {
    if (hasPermission) {
      this.contractId = contractData.iContractId;
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  GetContracts() {
    this.contractService
      .GetContractsData(this.view)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.contractsData = result["data"];
          // console.log(this.contractsData, "contractsData");

          this.dataSourceTableOne.data = result["data"];
        } else {
          this.notifyService.showError(result.message, "");
          // this.eventEmitterService.modelMessage({
          //   msg: result.message,
          //   status: 1,
          // });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  

  ContractAccept(contractId: number) {
    let reqJSON = {
      iContractId: contractId,
    };
    this.contractService
      .ContractAccept(reqJSON)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.modalService.dismissAll();
          // console.log(result["data"], "result");
          this.GetContracts();
          // this.eventEmitterService.modelMessage({
          //   msg: "Contract Accepted!",
          //   status: 0,
          // });
          this.notifyService.showSuccess("Contract Accepted!", "");
        } else {
          this.modalService.dismissAll();
          // this.eventEmitterService.modelMessage({
          //   msg: result.message,
          //   status: 1,
          // });
          this.notifyService.showError(result.message, "");

        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  HandleContractReject() {
    if (this.rejectContractForm.invalid) {
      return;
    }
    var rejectComment = this.rejectContractForm.value.rejectComment;
    this.ContractReject(rejectComment);
  }

  ContractReject(rejectComment: string) {
    let reqJSON = {
      iContractId: this.contractId,
      txRejectComment: rejectComment,
    };
    this.contractService
      .ContractReject(reqJSON)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.modalService.dismissAll();
          // console.log(result["data"], "result");
          // this.GetContracts();
          // this.eventEmitterService.modelMessage({
          //   msg: "Contract Rejected!",
          //   status: 0,
          // });
          this.notifyService.showSuccess("Contract Rejected!", "");

        } else {
          this.modalService.dismissAll();
          // this.eventEmitterService.modelMessage({
          //   msg: result.message,
          //   status: 1,
          // });
          this.notifyService.showError(result.message, "");
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  DownloadUploadedContract(contractUrl: string) {
    window.open(contractUrl, "_blank");
  }

  OpenClientContractSignedModal(
    modalToOpen: any,
    contractData: any,
    hasPermission: boolean
  ) {
    if (hasPermission) {
      this.contractId = contractData.iContractId;
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  UpdateClientSignedStatus(contractId: number) {
    let reqJSON = {
      iContractId: contractId,
    };
    this.contractService
      .UpdateClientSignedStatus(reqJSON)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.GetContracts();
          this.modalService.dismissAll();
          // this.eventEmitterService.modelMessage({
          //   msg: "Contract Signed by Client!",
          //   status: 0,
          // });
          this.notifyService.showSuccess("Contract Signed by Client!", "");

        } else {
          this.modalService.dismissAll();
          // this.eventEmitterService.modelMessage({
          //   msg: result.message,
          //   status: 1,
          // });
          this.notifyService.showError(result.message, "");

        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  OpenPartnerContractSignedModal(
    modalToOpen: any,
    contractData: any,
    hasPermission: boolean
  ) {
    if (hasPermission) {
      this.contractId = contractData.iContractId;
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  UpdatePartnerSignedStatus(contractId: number) {
    let reqJSON = {
      iContractId: contractId,
    };
    this.contractService
      .UpdatePartnerSignedStatus(reqJSON)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.GetContracts();
          this.modalService.dismissAll();
          // this.eventEmitterService.modelMessage({
          //   msg: "Contract Signed by Partner!",
          //   status: 0,
          // });
          this.notifyService.showSuccess("Contract Signed by Partner!", "");

        } else {
          this.modalService.dismissAll();
          // this.eventEmitterService.modelMessage({
          //   msg: result.message,
          //   status: 1,
          // });
          this.notifyService.showError(result.message, "");
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  CreateContract(contractData: any) {
    let reqJson={
      vClientEmail: contractData.vClientEmail,
      vClientCompanyName: contractData.vClientCompanyName,
      vPartnerEmail: contractData.vPartnerEmail,
      vPartnerCompanyName: contractData.vPartnerCompanyName,
      vContractName: contractData.vContractName,
      iContractId: contractData.iContractId
    }
    this.contractService.CreateContractDocument(reqJson).then((result: any) => {
      if (result.code == 200) {
        this.GetContracts();
        this.notifyService.showSuccess("Digital Contract Created Successfully!", "");
      }else{
        this.notifyService.showError("Something went wrong during digital contract creation!", "");
      }
    });
  }

  CloseModal() {
    this.modalService.dismissAll();
  }

  CancelForm(){
    this.modalService.dismissAll();
  }

}
