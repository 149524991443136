<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="wrapper">
        <div class="pagetitle-wrapper">
          <div class="pagetitle-block">
            <i class="pagetitle-icon"><span class="material-icons">supervisor_account</span></i>
            <h2 class="pagetitle">User Employee</h2>
          </div>
        </div>
        <mat-form-field class="w-100" floatLabel="never" class="table-filter">
          <button matSuffix mat-button>
            <mat-icon>search</mat-icon>
          </button>
          <input matInput (keyup)="applyFilterOne($event)" placeholder="Filter : Name, Email, Phone No" />
        </mat-form-field>
        <div class="table-responsive">
          <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
          </div>
          <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }" [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
            <ng-container matColumnDef="indexnumber">
              <th mat-header-cell *matHeaderCellDef> No </th>
              <td mat-cell *matCellDef="let i = index;">
                {{ this.TableOnePaginator.pageIndex == 0 ?  i+1 : i+ 1 + this.TableOnePaginator.pageIndex * this.TableOnePaginator.pageSize}}
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
              <td mat-cell *matCellDef="let element">{{element.email}}</td>
            </ng-container>
            <ng-container matColumnDef="number">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone No </th>
              <td mat-cell *matCellDef="let element">
                <span>{{element.number}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="uniqueId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Unique Id </th>
              <td mat-cell *matCellDef="let element">{{element.uniqueId}}</td>
            </ng-container>
            <ng-container matColumnDef="publishStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Publish Status </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.publishStatus == 1" class="status status-pending"><span class="material-icons">hourglass_empty</span>Pending</span>
                <span *ngIf="element.publishStatus == 2" class="status status-inactive"><span class="material-icons">block</span>Pending Approval</span>
                <span *ngIf="element.publishStatus == 3" class="status status-active"><span class="material-icons">done</span>Approved</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="accountStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Status </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.accountStatus == 0" class="status status-pending"><span class="material-icons">hourglass_empty</span>Pending</span>
                <span *ngIf="element.accountStatus == 1" class="status status-active"><span class="material-icons">done</span>Active</span>
                <span *ngIf="element.accountStatus == 2" class="status status-inactive"><span class="material-icons">block</span>Block</span>
                <span *ngIf="element.accountStatus == 3" class="status status-inactive"><span class="material-icons">delete</span>Delete</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="create">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
              <td mat-cell *matCellDef="let element">{{element.create * 1000 | date:'MM/dd/yy, h:mm a'}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex flex-wrap">
                  <button class="status-btn lightblue-bg blue-text text-center" placement="left auto" [ngbTooltip]="viewTalentProfileActionPermission ? 'View Profile':'Not Authorized'" [ngClass]="viewTalentProfileActionPermission ? '':'unauthorized-cursor'"
                    (click)="publishView(element,employeeProfileView,viewTalentProfileActionPermission)">
                    <mat-icon>remove_red_eye</mat-icon>
                  </button>
                  <button *ngIf="element.accountStatus == 1" class="status-btn lightpurple-bg purple-text" placement="left auto" [ngbTooltip]="inactiveEmployeeUserActionPermission ? 'Inactive':'Not Authorized'" [ngClass]="inactiveEmployeeUserActionPermission ? '':'unauthorized-cursor'" (click)="changeActiveInactiveUser(element.userId,2,inactiveEmployeeUserActionPermission)">
                    <mat-icon>thumb_down</mat-icon>
                  </button>
                  <button *ngIf="element.accountStatus == 2" class="status-btn lightgreen-bg green-text" placement="left auto" [ngbTooltip]="activeEmployeeUserActionPermission ? 'Active':'Not Authorized'" [ngClass]="activeEmployeeUserActionPermission ? '':'unauthorized-cursor'" (click)="changeActiveInactiveUser(element.userId,1,activeEmployeeUserActionPermission)">
                    <mat-icon>thumb_up</mat-icon>
                  </button>
                  <span *ngIf="element.accountStatus == 0" class="status-btn lightgray-bg gray-text"><span class="material-icons">hide_source</span>No Action</span>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
          </table>
        </div>
        <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }" #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<ng-template #employeeProfileView let-c="close" let-d="dismiss">
  <div class="employee-modal employee-modal-view">
    <button class="modal-close-btn" (click)="d('Cross click')">
      <mat-icon>close</mat-icon>
    </button>
    <div name="main-container" id="main-container" class="d-flex flex-column main-container h-100 mb-5">
      <div name="container-fluid" id="container-fluid" class="container-fluid">
        <div name="main-content-box" id="main-content-box" class="main-content-box">
          <div name="main-container-header" id="main-container-header" class="main-container-header responsive-spacing">
            <h1 name="title" id="title" class="title">View Profile</h1>
          </div>
          <div name="main-row" id="main-row" class="row gy-4 gy-lg-1 gx-1">
            <div name="left-column" id="left-column" class="col-12 col-lg-6">
              <div name="left-column-inner-row" id="left-column-inner-row" class="row g-4">
                <app-personal-details></app-personal-details>
                <app-education></app-education>
                <app-awards></app-awards>
                <app-employee-certifications></app-employee-certifications>
              </div>
            </div>
            <div name="right-column-box" id="right-column-box" class="col-12 col-lg-6">
              <div name="right-column-row" id="right-column-row" class="row g-4">
                <app-professional-details></app-professional-details>
                <app-project-portfolio></app-project-portfolio>
                <app-documents></app-documents>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
