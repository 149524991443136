<a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
  [ngClass]="{'expanded': expanded}" class="menu-list-item {{item.className}}"
  [class.active]="item.route ? router.isActive(item.route, true) : false">
  <span *ngIf="item.route ? router.isActive(item.route, true) : false; else elseBlock">
    <mat-icon class="routeIcon" [ngClass]="{'m-0': item.activeIconName==''}">
      <img *ngIf="item.activeIconName" src="./assets/images/{{item.activeIconName}}" alt="{{item.displayName}}" width="24px" height="24px">
    </mat-icon>
  </span>
  <ng-template #elseBlock>
    <mat-icon class="routeIcon" [ngClass]="{'m-0': item.iconName==''}">
      <img *ngIf="item.iconName" src="./assets/images/{{item.iconName}}" alt="{{item.displayName}}" width="24px" height="24px">
    </mat-icon>
  </ng-template>
  {{item.displayName}}
  <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <!-- <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon> -->
    <mat-icon *ngIf="expanded" style="color:#717682">
      arrow_drop_up
    </mat-icon>
    <mat-icon *ngIf="!expanded">
      arrow_drop_down
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-item>
</div>