import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EventEmitterService } from "../../config/event-emitter.service";
import { TechnologyService } from "./technology.service";
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";

@Component({
  selector: "app-technology",
  templateUrl: "./technology.component.html",
})
export class TechnologyComponent implements OnInit {
  addTechnologyForm!: FormGroup;
  editTechnologyForm!: FormGroup;

  displayedColumnsTableOne: string[] = [
    "indexnumber",
    "vTechnologyName",
    "iCreatedAt",
    "actions",
  ];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild("TableOnePaginator", { static: true })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort", { static: true }) tableOneSort: MatSort;

  // toggle actions permission
  addTechnologyActionPermission: boolean = false;
  editTechnologyActionPermission: boolean = false;
  deleteTechnologyActionPermission: boolean = false;

  constructor(
    private technologyService: TechnologyService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private userPermissionService: UserPermissionService
  ) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const AddTechnologyAction: Permission = Permission.AddTechnologyAction;
    const EditTechnologyAction: Permission = Permission.EditTechnologyAction;
    const DeleteTechnologyAction: Permission =
      Permission.DeleteTechnologyAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddTechnologyAction)) {
      this.addTechnologyActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditTechnologyAction)) {
      this.editTechnologyActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeleteTechnologyAction)) {
      this.deleteTechnologyActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetTechnology();
    this.addTechnologyForm = new FormGroup({
      technologyName: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
    });

    this.editTechnologyForm = new FormGroup({
      iMasterTechnologyId: new FormControl("", []),
      editedTechnologyName: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
    });
  }

  OpenModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  OpenModelEditTechnology(
    modelName: any,
    element: any,
    hasPermission: boolean
  ) {
    if (hasPermission) {
      this.editTechnologyForm.patchValue({
        iMasterTechnologyId: element["iMasterTechnologyId"],
        editedTechnologyName: element["vTechnologyName"],
      });
      this.modalService.open(modelName, { centered: true });
    }
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value
      .trim()
      .toLowerCase();
  }

  GetTechnology() {
    this.technologyService
      .GetTechnology()
      .then((result: any) => {
        if (result["code"] == 200) {
          this.dataSourceTableOne.data = result["data"];
        } else {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  AddTechnology() {
    if (this.addTechnologyForm.invalid) {
      return;
    }
    let reqData = {
      vTechnologyName: this.addTechnologyForm.value.technologyName,
    };
    this.technologyService
      .AddTechnology(reqData)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
          this.GetTechnology();
        } else {
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err, "AddTechnology");
      });
  }

  EditTechnology() {
    if (this.editTechnologyForm.invalid) {
      return;
    }
    let reqData = {
      iMasterTechnologyId: this.editTechnologyForm.value.iMasterTechnologyId,
      vTechnologyName: this.editTechnologyForm.value.editedTechnologyName,
    };
    this.technologyService
      .EditTechnology(reqData)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
          this.modalService.dismissAll();
          this.GetTechnology();
        } else {
          this.modalService.dismissAll();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err, "EditTechnology");
      });
  }

  DeleteTechnology(item: any, hasPermission: boolean): void {
    if (hasPermission) {
      this.technologyService
        .DeleteTechnology(item.iMasterTechnologyId)
        .then((result: any) => {
          this.GetTechnology();
          this.eventEmitterService.modelMessage({
            msg: result.message,
            status: 0,
          });
        })
        .catch((err) => {
          console.log(err, "DeleteTechnology");
        });
    }
  }
}
