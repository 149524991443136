import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { DocumentsService } from "./documents.service";
import { NotificationService } from "../../../../config/notification.service";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { StaticMsg } from "src/app/config/en";
import { environment } from "../../../../../environments/environment";
import { Permission } from "../../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../../utils/services/user-permission.service";

@Component({
  selector: "partner-company-documents",
  templateUrl: "./company-documents.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class PartnerCompanyDocumentsComponent implements OnInit {
  @Input() partnerUserId:any;
  companyDocumentsToggle:boolean=false;
  
  imagePath = environment.image_path;

  // toggle actions permission
  editPartnerCompanyDocumentsActionPermission: boolean = false;

  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  mode: ProgressSpinnerMode = "determinate";

  companyDocumentsForm!: FormGroup;
  companyDocumentsSubmitted: any = false;

  @ViewChild("uploadFile") fileInput: any;
  fileAttr: any;
  vCertificateOfIncorporationPdf: any;
  vCertificateOfShopEstablishmentPdf: any;
  vUdyogAadhaarPdf: any;
  vUtilityBillPdf: any;
  vTANPdf: any;
  vPANPdf: any;
  vCancelledBankChequePdf: any;
  documentsData: any = [];
  documentsStatus: any;
  companyDocumentsFormError: any = false;

  constructor(config: NgbModalConfig, private modalService: NgbModal, private documentsService: DocumentsService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService, private userPermissionService: UserPermissionService) {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const EditPartnerCompanyDocumentsAction: Permission = Permission.EditPartnerCompanyDocumentsAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(EditPartnerCompanyDocumentsAction)) {
      this.editPartnerCompanyDocumentsActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });
    this.GetCompanyDocuments();
    this.companyDocumentsForm = new FormGroup({
      vCorporateIdentityNumber: new FormControl("", [Validators.required, Validators.pattern("^[A-Z0-9]{21}$")]),
      vCertificateOfIncorporationPdf: new FormControl("", [Validators.required]),
      vShopEstablishmentCertificateNumber: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(21), Validators.pattern("^(?! ).*[^ ]$")]),
      vCertificateOfShopEstablishmentPdf: new FormControl("", [Validators.required]),
      vUdyogAadhaarNumber: new FormControl("", [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern("^(?! ).*[^ ]$")]),
      vUdyogAadhaarPdf: new FormControl("", [Validators.required]),
      vUtilityBillPdf: new FormControl("", [Validators.required]),
      vTANNumber: new FormControl("", [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^(?! ).*[^ ]$")]),
      vTANPdf: new FormControl("", [Validators.required]),
      vPANNumber: new FormControl("", [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^(?! ).*[^ ]$")]),
      vPANPdf: new FormControl("", [Validators.required]),
      vCancelledBankChequePdf: new FormControl("", [Validators.required]),
    });
  }

  public companyDocumentsCardCompleted: any = false;
  public iProfilePublishCompleted: any = false;
  updateProfileStatus(data: any) {
    this.companyDocumentsCardCompleted = data["companyDocuments"];
    this.iProfilePublishCompleted = data["iProfilePublishCompleted"];
  }

  toggleCompanyDocuments(hasPermission: boolean) {
    if (hasPermission) {
      this.GetCompanyDocuments();
      this.vCertificateOfIncorporationPdf = "";
      this.vCertificateOfShopEstablishmentPdf = "";
      this.vUdyogAadhaarPdf = "";
      this.vUtilityBillPdf = "";
      this.vTANPdf = "";
      this.vPANPdf = "";
      this.vCancelledBankChequePdf = "";
      this.companyDocumentsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }

  CancelDocumentsModal() {
    this.companyDocumentsForm.reset();
    this.companyDocumentsToggle=false;
  }

  GetCompanyDocuments() {
    this.documentsService.GetCompanyDocuments(this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.documentsData = result["data"];
        this.documentsStatus = result["documentsStatus"];
        if (this.documentsStatus.vCertificateOfIncorporationPdf) {
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].clearValidators();
        } else {
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].setValidators([Validators.required]);
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].patchValue("");
        }
        if (this.documentsStatus.vCertificateOfShopEstablishmentPdf) {
          this.companyDocumentsForm.controls["vCertificateOfShopEstablishmentPdf"].clearValidators();
        } else {
          this.companyDocumentsForm.controls["vCertificateOfShopEstablishmentPdf"].setValidators([Validators.required]);
          this.companyDocumentsForm.controls["vCertificateOfShopEstablishmentPdf"].patchValue("");
        }
        if (this.documentsStatus.vUdyogAadhaarPdf) {
          this.companyDocumentsForm.controls["vUdyogAadhaarPdf"].clearValidators();
        } else {
          this.companyDocumentsForm.controls["vUdyogAadhaarPdf"].setValidators([Validators.required]);
          this.companyDocumentsForm.controls["vUdyogAadhaarPdf"].patchValue("");
        }
        if (this.documentsStatus.vUtilityBillPdf) {
          this.companyDocumentsForm.controls["vUtilityBillPdf"].clearValidators();
        } else {
          this.companyDocumentsForm.controls["vUtilityBillPdf"].setValidators([Validators.required]);
          this.companyDocumentsForm.controls["vUtilityBillPdf"].patchValue("");
        }
        if (this.documentsStatus.vTANPdf) {
          this.companyDocumentsForm.controls["vTANPdf"].clearValidators();
        } else {
          this.companyDocumentsForm.controls["vTANPdf"].setValidators([Validators.required]);
          this.companyDocumentsForm.controls["vTANPdf"].patchValue("");
        }
        if (this.documentsStatus.vPANPdf) {
          this.companyDocumentsForm.controls["vPANPdf"].clearValidators();
        } else {
          this.companyDocumentsForm.controls["vPANPdf"].setValidators([Validators.required]);
          this.companyDocumentsForm.controls["vPANPdf"].patchValue("");
        }
        if (this.documentsStatus.vCancelledBankChequePdf) {
          this.companyDocumentsForm.controls["vCancelledBankChequePdf"].clearValidators();
        } else {
          this.companyDocumentsForm.controls["vCancelledBankChequePdf"].setValidators([Validators.required]);
          this.companyDocumentsForm.controls["vCancelledBankChequePdf"].patchValue("");
        }
        this.companyDocumentsForm.patchValue({
          vCorporateIdentityNumber: result["data"]["vCorporateIdentityNumber"],
          vShopEstablishmentCertificateNumber: result["data"]["vShopEstablishmentCertificateNumber"],
          vUdyogAadhaarNumber: result["data"]["vUdyogAadhaarNumber"],
          vTANNumber: result["data"]["vTANNumber"],
          vPANNumber: result["data"]["vPANNumber"],
        });
      }
    });
  }

  FileBrowseHandler(event: any, type: any) {
    let fileSize = event["target"]["files"][0]["size"] / 1024;
    if (fileSize < 4 * 1024) {
      if (type != 7) {
        if (event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/vnd.ms-excel" || event.target.files[0].type == "image/x-png" || event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg") {
          this.companyDocumentsFormError = false;
          if (type == 1) {
            this.vCertificateOfIncorporationPdf = event.target.files[0];
          }
          if (type == 2) {
            this.vCertificateOfShopEstablishmentPdf = event.target.files[0];
          }
          if (type == 3) {
            this.vUdyogAadhaarPdf = event.target.files[0];
          }
          if (type == 4) {
            this.vUtilityBillPdf = event.target.files[0];
          }
          if (type == 5) {
            this.vTANPdf = event.target.files[0];
          }
          if (type == 6) {
            this.vPANPdf = event.target.files[0];
          }
        } else {
          {
            this.companyDocumentsFormError = true;
            this.notifyService.showError(StaticMsg.NotPdfFileError, "");
            if (type == 1) {
              this.vCertificateOfIncorporationPdf = "";
              this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].patchValue("");
            }
            if (type == 2) {
              this.vCertificateOfShopEstablishmentPdf = "";
              this.companyDocumentsForm.controls["vCertificateOfShopEstablishmentPdf"].patchValue("");
            }
            if (type == 3) {
              this.vUdyogAadhaarPdf = "";
              this.companyDocumentsForm.controls["vUdyogAadhaarPdf"].patchValue("");
            }
            if (type == 4) {
              this.vUtilityBillPdf = "";
              this.companyDocumentsForm.controls["vUtilityBillPdf"].patchValue("");
            }
            if (type == 5) {
              this.vTANPdf = "";
              this.companyDocumentsForm.controls["vTANPdf"].patchValue("");
            }
            if (type == 6) {
              this.vPANPdf = "";
              this.companyDocumentsForm.controls["vPANPdf"].patchValue("");
            }
            return;
          }
        }
      } else if (type == 7 && (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg")) {
        this.companyDocumentsFormError = false;
        this.vCancelledBankChequePdf = event.target.files[0];
      } else {
        this.companyDocumentsFormError = true;
        this.notifyService.showError(StaticMsg.NotValidImageTypeError, "");
        if (type == 7) {
          this.vCancelledBankChequePdf = "";
          this.companyDocumentsForm.controls["vCancelledBankChequePdf"].patchValue("");
        }
        return;
      }
    } else {
      this.companyDocumentsFormError = true;
      this.notifyService.showError(StaticMsg.FileLargerError, "");
      if (type == 1) {
        this.vCertificateOfIncorporationPdf = "";
        this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].patchValue("");
      }
      if (type == 2) {
        this.vCertificateOfShopEstablishmentPdf = "";
        this.companyDocumentsForm.controls["vCertificateOfShopEstablishmentPdf"].patchValue("");
      }
      if (type == 3) {
        this.vUdyogAadhaarPdf = "";
        this.companyDocumentsForm.controls["vUdyogAadhaarPdf"].patchValue("");
      }
      if (type == 4) {
        this.vUtilityBillPdf = "";
        this.companyDocumentsForm.controls["vUtilityBillPdf"].patchValue("");
      }
      if (type == 5) {
        this.vTANPdf = "";
        this.companyDocumentsForm.controls["vTANPdf"].patchValue("");
      }
      if (type == 6) {
        this.vPANPdf = "";
        this.companyDocumentsForm.controls["vPANPdf"].patchValue("");
      }
      if (type == 7) {
        this.vCancelledBankChequePdf = "";
        this.companyDocumentsForm.controls["vCancelledBankChequePdf"].patchValue("");
      }
      return;
    }
  }

  CancelMedia(type: any) {
    if (type == 1) {
      this.vCertificateOfIncorporationPdf = "";
      this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].patchValue("");
    }
    if (type == 2) {
      this.vCertificateOfShopEstablishmentPdf = "";
      this.companyDocumentsForm.controls["vCertificateOfShopEstablishmentPdf"].patchValue("");
    }
    if (type == 3) {
      this.vUdyogAadhaarPdf = "";
      this.companyDocumentsForm.controls["vUdyogAadhaarPdf"].patchValue("");
    }
    if (type == 4) {
      this.vUtilityBillPdf = "";
      this.companyDocumentsForm.controls["vUtilityBillPdf"].patchValue("");
    }
    if (type == 5) {
      this.vTANPdf = "";
      this.companyDocumentsForm.controls["vTANPdf"].patchValue("");
    }
    if (type == 6) {
      this.vPANPdf = "";
      this.companyDocumentsForm.controls["vPANPdf"].patchValue("");
    }
    if (type == 7) {
      this.vCancelledBankChequePdf = "";
      this.companyDocumentsForm.controls["vCancelledBankChequePdf"].patchValue("");
    }
  }

  SubmitCompanyDocuments() {
    this.companyDocumentsSubmitted = true;
    if (this.companyDocumentsForm.invalid || this.companyDocumentsFormError) {
      return;
    }
    let reqData = {
      vCorporateIdentityNumber: this.companyDocumentsForm.value.vCorporateIdentityNumber,
      vCertificateOfIncorporationPdf: this.vCertificateOfIncorporationPdf,
      vShopEstablishmentCertificateNumber: this.companyDocumentsForm.value.vShopEstablishmentCertificateNumber,
      vCertificateOfShopEstablishmentPdf: this.vCertificateOfShopEstablishmentPdf,
      vUdyogAadhaarNumber: this.companyDocumentsForm.value.vUdyogAadhaarNumber,
      vUdyogAadhaarPdf: this.vUdyogAadhaarPdf,
      vUtilityBillPdf: this.vUtilityBillPdf,
      vTANNumber: this.companyDocumentsForm.value.vTANNumber,
      vTANPdf: this.vTANPdf,
      vPANNumber: this.companyDocumentsForm.value.vPANNumber,
      vPANPdf: this.vPANPdf,
      vCancelledBankChequePdf: this.vCancelledBankChequePdf,
    };
    this.documentsService.UpdateDocuments(reqData,this.partnerUserId).then(
      (result: any) => {
        if (result.code == 200) {
          this.notifyService.showSuccess("Documents uploded successfully", "");
          this.companyDocumentsForm.reset();
          this.GetCompanyDocuments();
          this.eventEmitterService.GetProfileStatusAPICallMethod();
          this.companyDocumentsToggle=false;
        } else {
          this.companyDocumentsToggle=false;
        }
      },
      (err: any) => {
        console.log(err, "UpdateCompanyDetails");
      }
    );
  }
  data_list: any = [];
  object: any = {};
  DeleteDocument(document: any) {
    this.documentsService.DeleteDocument(document,this.partnerUserId).then((result: any) => {
      if (result.code == 200) {
        this.notifyService.showSuccess("Document deleted successfully!", "");
        this.GetCompanyDocuments();
      }
    });
  }
}
