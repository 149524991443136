import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class ChildPermissionManagementService {

  constructor( private http: HttpClient ) { }

  GetChildPermissions() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/child-feature`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  AddChildPermission(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/child-feature`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  EditChildPermission(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/admin/v2/child-feature`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteChildPermission(iChildFeatureId	: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + "/admin/v2/child-feature/" + iChildFeatureId	;
      this.http
        .delete(apiURL)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
    return promise;
  }
}
