import { Component, OnInit,ViewChild } from '@angular/core';
import { UserManagementService } from './user-employee.service';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../../config/event-emitter.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Permission } from "./../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

@Component({
  selector: 'app-user-employee',
  templateUrl: './user-employee.component.html'
})
export class UserEmployeeComponent implements OnInit {
  // toggle actions permission
  viewTalentProfileActionPermission: boolean = false;
  activeEmployeeUserActionPermission: boolean = false;
  inactiveEmployeeUserActionPermission: boolean = false;

  constructor(
    private service : UserManagementService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  ){
    // assign individual permissions
    const ViewTalentProfileAction: Permission = Permission.ViewTalentProfileAction;
    const ActiveEmployeeUserAction: Permission = Permission.ActiveEmployeeUserAction;
    const InactiveEmployeeUserAction: Permission = Permission.InactiveEmployeeUserAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(ViewTalentProfileAction)) {
      this.viewTalentProfileActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(ActiveEmployeeUserAction)) {
      this.activeEmployeeUserActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(InactiveEmployeeUserAction)) {
      this.inactiveEmployeeUserActionPermission = true;
    }
  }

  displayedColumnsTableOne: string[] = ['indexnumber', 'name', 'email', 'number' , 'uniqueId', 'publishStatus', 'accountStatus', 'create', 'actions'];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

  ngOnInit(): void {
    this.getUserList();
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  getUserList() {
    this.service.getUserList().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      }
    });
  }

  changeActiveInactiveUser(userId: any, accStatus: any, hasPermission: boolean) {
    if (hasPermission) {
      this.service.changeActiveInactiveUser({userId:userId,accStatus:accStatus}).then((result: any) => {
        if (result['code'] == 200) {
          this.notifyService.showSuccess(result.message,'');
          this.getUserList();
        }
      });
    }
  }

  publishView(data: any, modalToOpen: any, hasPermission: boolean){
    if (hasPermission) {
      this.GetProfileStatus(data['userId']);
      this.modalService.open(modalToOpen, { centered: true, windowClass: 'wide-modal' });
      setTimeout(() => {
        this.eventEmitterService.viewEmpPublish_Pub(data['userId']);
      }, environment.view_open_model);
    }
  }

  public iProfilePublishCompleted:any = 0;
  public progressValue:any;
  public partnerCompanyName:any;
  GetProfileStatus(iUserId:any){
    this.service.GetProfileStatus(iUserId).then((result: any) => {
      if (result.code == 200) {
        this.iProfilePublishCompleted = result['data']['iProfilePublishCompleted'];
        this.progressValue = result['data']['progressValue'];
        this.partnerCompanyName = result['data']['partnerCompanyName'];
        this.eventEmitterService.GetProfileStatusCallMethod(result['data']);
      }
    });
  }
}
