import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../config/event-emitter.service';
import { IndustryService } from './industry.service';
import { Permission } from "./../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../utils/services/user-permission.service";
import { NotificationService } from 'src/app/config/notification.service';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html'
})
export class IndustryComponent implements OnInit {

  addIndustryForm!: FormGroup;
  editIndustryForm!: FormGroup;

  industryList: any = [];

  displayedColumnsTableOne: string[] = ['indexnumber','vIndustryName','iUpdatedAt','actions'];
  dataSourceTableOne = new MatTableDataSource();

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', { static: true }) tableOneSort: MatSort;

  // toggle actions permission
  addIndustryActionPermission: boolean = false;
  editIndustryActionPermission: boolean = false;
  deleteIndustryActionPermission: boolean = false;

  constructor(
    private industryService: IndustryService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private notifyService: NotificationService,
    private userPermissionService: UserPermissionService
  )
  {
    config.backdrop = 'static';
    config.keyboard = false;

    // assign individual permissions
    const AddIndustryAction: Permission = Permission.AddIndustryAction;
    const EditIndustryAction: Permission = Permission.EditIndustryAction;
    const DeleteIndustryAction: Permission = Permission.DeleteIndustryAction;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AddIndustryAction)) {
      this.addIndustryActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(EditIndustryAction)) {
      this.editIndustryActionPermission = true;
    }
    if (this.userPermissionService.isAuthorizedFor(DeleteIndustryAction)) {
      this.deleteIndustryActionPermission = true;
    }
  }

  ngOnInit(): void {
    this.GetIndustry()
    this.addIndustryForm = new FormGroup({
      industryName: new FormControl('', [Validators.required,Validators.minLength(3),Validators.maxLength(100)]),
    });

    this.editIndustryForm = new FormGroup({
      iMasterIndustryId: new FormControl('', []),
      editedIndustryName: new FormControl('', [Validators.required,Validators.minLength(3),Validators.maxLength(100)]),
    });
  }

  selectedPrimarySkill: any;
  openModal(modalToOpen: any, hasPermission: boolean) {
    if (hasPermission) {
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  OpenModelEditIndustry(modelName:any, element:any, hasPermission: boolean) {
    if (hasPermission) {
      this.editIndustryForm.patchValue({
        iMasterIndustryId: element['iMasterIndustryId'],
        editedIndustryName: element['vIndustryName']
      })
      this.modalService.open(modelName, { centered: true });
    }
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  GetIndustry() {
    this.industryService.GetIndustry().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
        this.industryList = result['data'];
      } else {
        this.notifyService.showError(result.message,'');
      }
    }).catch((err: any) => {console.log(err)});
  }

  AddIndustry() {
    if (this.addIndustryForm.invalid) {
      return;
    }
    let reqData = {
      vIndustryName : this.addIndustryForm.value.industryName
    }
    this.industryService.AddIndustry(reqData).then((result: any) => {
      if (result['code'] == 200) {
        this.modalService.dismissAll();
        this.notifyService.showSuccess(result.message,'');
        this.GetIndustry();
      } else {
        this.modalService.dismissAll();
        this.notifyService.showError(result.message,'');
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  EditIndustry() {
    if (this.editIndustryForm.invalid) {
      return;
    }

    let reqData = {
      iMasterIndustryId: this.editIndustryForm.value.iMasterIndustryId,
      vIndustryName: this.editIndustryForm.value.editedIndustryName
    }

    this.industryService.EditIndustry(reqData).then((result: any) => {
      if (result['code'] == 200) {
        this.notifyService.showSuccess(result.message,'');
        this.modalService.dismissAll();
        this.GetIndustry();
      } else {
        this.modalService.dismissAll();
        this.notifyService.showError(result.message,'');
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  DeleteIndustry(item: any, hasPermission: boolean): void {
    if (hasPermission) {
      this.industryService.DeleteIndustry(item.iMasterIndustryId).then((result: any) => {
        this.GetIndustry();
        this.notifyService.showSuccess(result.message,'');
      }).catch((err) => {
        console.log(err);
      })
    }
  }
}
