<div name="company-documents-box" id="company-documents-box" class="col-12">
  <div name="company-documents-card-container" id="company-documents-card-container" class="card-container" *ngIf="companyDocumentsToggle==false">
    <div name="company-documents-card-header" id="company-documents-card-header" class="card-header">
      <div name="company-documents-title-section" id="company-documents-title-section" class="title-section">
        <h4 name="company-documents-card-title" id="company-documents-card-title" class="card-title">Company Documents
        </h4>
        <button name="company-documents-card-edit-button" id="company-documents-card-edit-button"
          class="card-edit-button" type="button" (click)="toggleCompanyDocuments(editClientCompanyDocumentsActionPermission)" [ngClass]="editClientCompanyDocumentsActionPermission ? '':'unauthorized-cursor'" placement="right auto" [ngbTooltip]="editClientCompanyDocumentsActionPermission ?  '':'Not Authorized'"><img
            class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button" /></button>
        <!-- <img class="info-tooltip mx-2" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="top"
          ngbTooltip="It is not editable once approved by Rapidise." /> -->
      </div>
      <div name="company-documents-status-section" id="company-documents-status-section" class="status-section">
        <div name="company-documents-incomplete-status-box" id="company-documents-incomplete-status-box"
          class="status-box" *ngIf="!companyDocumentsCardCompleted">
          <img id="company-documents-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
            alt="status-icon">
          <label name="company-documents-incomplete-status-text" id="company-documents-incomplete-status-text"
            class="status-text incomplete">Pending</label>
        </div>
        <div name="company-documents-completed-status-text" id="company-documents-completed-status-text"
          class="status-box" *ngIf="companyDocumentsCardCompleted">
          <img id="company-documents-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'"
            alt="status-icon">
          <label name="company-documents-completed-status-text" id="company-documents-completed-status-text"
            class="status-text completed">Completed</label>
        </div>
      </div>
    </div>
    <div name="company-documents-card" id="company-documents-card" class="card"
      [ngClass]="companyDocumentsCardCompleted ? 'completed' : 'incomplete'">
      <div name="company-documents-card-content" id="company-documents-card-content" class="card-content">
        <div class="text-box-with-icon bottom-spacing" *ngFor="let field of countryWiseFields;">
          <img class="icon"
            [src]="documentsStatus?.vCompanyFileNumber ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="status-icon">
          <label name="text" id="text" class="text"
            [ngClass]="{'disabled': !documentsStatus?.vCompanyFileNumber}">{{field.label}} <span
              class="text-danger">*</span></label>
        </div>
        <div class="text-box-with-icon bottom-spacing">
          <img class="icon"
            [src]="documentsStatus?.vCorporateIdentityNumber ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="status-icon">
          <label name="text" id="text" class="text"
            [ngClass]="{'disabled': !documentsStatus?.vCorporateIdentityNumber}">Certificate of Incorporation / File
            Number <span class="text-danger">*</span></label>
        </div>
        <div class="text-box-with-icon bottom-spacing">
          <img class="icon"
            [src]="documentsStatus?.vGoodStandingCertificate ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="status-icon">
          <label name="text" id="text" class="text"
            [ngClass]="{'disabled': !documentsStatus?.vGoodStandingCertificate}">Good Standing Certificate </label>
        </div>
        <div class="text-box-with-icon">
          <img class="icon"
            [src]="documentsStatus?.vDUNUSNumber ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="status-icon">
          <label name="text" id="text" class="text" [ngClass]="{'disabled': !documentsStatus?.vDUNUSNumber}">DUNS Number
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="card-container" *ngIf="companyDocumentsToggle==true">
    <div name="company-documents-card-header" id="company-documents-card-header" class="card-header">
      <div name="company-documents-title-section" id="company-documents-title-section" class="title-section">
        <h4 name="company-documents-card-title" id="company-documents-card-title" class="card-title">Company Documents</h4>
      </div>
    </div>
    <div class="edit-profile">
      <div name="company-documents-modal-body" id="company-documents-modal-body" class="modal-body modal-scroll">
        <form id="companyDocumentsForm" name="companyDocumentsForm" class="form" [formGroup]="companyDocumentsForm"
          (ngSubmit)="SubmitCompanyDocuments()">
          <div class="row">
            <div class="col-12" *ngFor="let field of countryWiseFields; let i = index;">
              <div class="row">
                <div class="col-12">
                  <label class="bold-title">
                    {{field.label}}
                  </label>
                </div>
                <div class="col-12 col-md-6 field-box">
                  <label for="{{field.formControlName}}" id="{{field.formControlName}}-label"
                  class="custom-label">{{field.label}} <span class="text-danger">*</span><img class="info-tooltip"
                      [src]="imagePath + 'info_icon.svg'" alt="comapny-name-info" placement="top"
                      ngbTooltip="Based on country selection" />
                  </label>
                  <mat-form-field id="mat-form-field-{{field.formControlName}}" class="custom-input" floatLabel="never"
                     floatPlaceholder="never">
                    <input type="text" matInput name="{{field.formControlName}}" id="{{field.formControlName}}"
                      formControlName="{{field.formControlName}}" placeholder="32-2124444" required>
                    <mat-error id="mat-error-{{field.formControlName}}" class="reduce-bottom-space"
                      *ngIf="companyDocumentsForm.controls[field.formControlName].invalid">
                      <mat-error id="mat-error-{{field.formControlName}}-text-1" class="reduce-bottom-space"
                        *ngIf="companyDocumentsForm.controls[field.formControlName].errors!.required">
                        {{field.errorMsg.required}}
                      </mat-error>
                      <mat-error id="mat-error-{{field.formControlName}}-text-1" class="reduce-bottom-space"
                        *ngIf="companyDocumentsForm.controls[field.formControlName].errors!.minlength">
                        {{field.errorMsg.minimum}}
                      </mat-error>
                      <mat-error id="mat-error-{{field.formControlName}}-text-1" class="reduce-bottom-space"
                        *ngIf="companyDocumentsForm.controls[field.formControlName].errors!.maxlength">
                        {{field.errorMsg.maximum}}
                      </mat-error>
                      <mat-error id="mat-error-{{field.formControlName}}-text-1" class="reduce-bottom-space"
                        *ngIf="!(companyDocumentsForm.controls[field.formControlName].errors!.maxlength) && !(companyDocumentsForm.controls[field.formControlName].errors!.minlength) && companyDocumentsForm.controls[field.formControlName].errors!.pattern">
                        {{field.errorMsg.pattern}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="bold-title d-flex flex-column flex-sm-row">
                <label name="company-documents-certificate-of-incorporation-title"
                  id="company-documents-certificate-of-incorporation-title">
                  Certificate of incorporation
                </label>
                <mat-checkbox name="company-documents-certificate-of-incorporation-checkbox"
                  id="company-documents-certificate-of-incorporation-checkbox" class="custom-checkbox mx-0 mx-sm-3"
                  (change)="CheckApplicable($event)" [checked]="iClientDocumentsRequired"
                  *ngIf="!documentsData.vCertificateOfIncorporationPdf || !documentsData.vCorporateIdentityNumber">Check
                  if not applicable</mat-checkbox>
              </div>
            </div>
            <div class="col-12 col-md-6 field-box" [ngClass]="iClientDocumentsRequired ? 'opacity-4' : ''">
              <label for="vCorporateIdentityNumber" name="company-documents-cin-label" id="company-documents-cin-label"
                class="custom-label">Corporate Identity Number (CIN) <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-cin-input" id="company-documents-cin-input"
                class="custom-input" floatLabel="never"  floatPlaceholder="never">
                <input type="text" matInput id="vCorporateIdentityNumber" formControlName="vCorporateIdentityNumber"
                  placeholder="L21091KA2019OPC141331" required>
                <mat-error class="reduce-bottom-space"
                  *ngIf="companyDocumentsForm.controls['vCorporateIdentityNumber'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vCorporateIdentityNumber'].errors!.required">
                    CIN is required
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vCorporateIdentityNumber'].errors!.pattern">
                    Please enter valid CIN with 21 length
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6" [ngClass]="iClientDocumentsRequired ? 'opacity-4' : ''">
              <label for="vCertificateOfIncorporationPdf" name="company-documents-cin-pdf-label"
                id="company-documents-cin-pdf-label" class="custom-label">Upload PDF <span
                  class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-cin-pdf-input"
                id="company-documents-cin-pdf-input" class="custom-input" floatLabel="never"  floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <div [config]="config">
                        <div class="file" *ngIf="vCertificateOfIncorporationPdf?.name">
                          {{ vCertificateOfIncorporationPdf.name }}
                          <button type="button" name="button" class="btn" (click)="CancelMedia(1)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input name="company-documents-cin-pdf-vCertificateOfIncorporationPdf-1"
                  id="company-documents-cin-pdf-vCertificateOfIncorporationPdf-1" matInput readonly
                  [value]="vCertificateOfIncorporationPdf ? ' ' : ''" formControlName="vCertificateOfIncorporationPdf"
                  placeholder="Upload PDF" />
                <input matSuffix type="file" #uploadFile name="company-documents-cin-pdf-vCertificateOfIncorporationPdf-2"
                  id="company-documents-cin-pdf-vCertificateOfIncorporationPdf-2" class="file-upload-input"
                  (change)="FileBrowseHandler($event,1)" formControlName="vCertificateOfIncorporationPdf"
                  accept="application/pdf" />
                <button name="company-documents-cin-pdf-upload-btn" id="company-documents-cin-pdf-upload-btn"
                  class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-error class="reduce-bottom-space"
                  *ngIf="companyDocumentsForm.controls['vCertificateOfIncorporationPdf'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vCertificateOfIncorporationPdf'].errors!.required">
                    Pdf is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 4MB PDF required</mat-hint>
              </mat-form-field>
            </div>
            <div name="cin-certi-image-display" id="cin-certi-image-display" class="col-12"
              *ngIf="documentsData.vCertificateOfIncorporationPdf">
              <div name="cin-certi-image-class-1" id="cin-certi-image-class-1"
                class="image-display-container margin-class">
                <div name="cin-certi-name-1" id="cin-certi-name-1" class="image-name">
                  <label>1)</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="documentsData.vCertificateOfIncorporationPdfUrl" target="_blank">{{
                    documentsData.vCertificateOfIncorporationPdf }}</a>
                </div>
                <button type="button" name="button"
                  (click)="DeleteDocument(documentsData.vCertificateOfIncorporationPdf)">
                  <img id="cin-certi-img-1" alt="delete-image" [src]="imagePath + 'delete_image.svg'">
                </button>
              </div>
            </div>
            <div class="col-12">
              <label class="bold-title top-spacing">
                Good Standing Certificate
                <img class="info-tooltip mx-2" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="top"
                  ngbTooltip="Upload Latest Certificate" />
              </label>
            </div>
            <div class="col-12 col-md-6 field-box">
              <label for="vGoodStandingCertificate" name="company-documents-aadhaar-number-upload-pdf-label"
                id="company-documents-aadhaar-number-upload-pdf-label" class="custom-label">Upload PDF </label>
              <mat-form-field hideRequiredMarker name="company-documents-aadhaar-number-upload-pdf-input"
                id="company-documents-aadhaar-number-upload-pdf-input" class="custom-input" floatLabel="never"  floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <div [config]="config">
                        <div class="file" *ngIf="vGoodStandingCertificate?.name">
                          {{ vGoodStandingCertificate.name }}
                          <button type="button" name="button" class="btn" (click)="CancelMedia(2)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input name="company-documents-aadhaar-number-upload-pdf-vGoodStandingCertificate-1"
                  id="company-documents-aadhaar-number-upload-pdf-vGoodStandingCertificate-1" matInput readonly
                  [value]="vGoodStandingCertificate ? ' ' : ''" formControlName="vGoodStandingCertificate"
                  placeholder="Upload PDF" />
                <input matSuffix type="file" #uploadFile
                  name="company-documents-aadhaar-number-upload-pdf-vGoodStandingCertificate-2"
                  id="company-documents-aadhaar-number-upload-pdf-vGoodStandingCertificate-2" class="file-upload-input"
                  (change)="FileBrowseHandler($event,2)" formControlName="vGoodStandingCertificate"
                  accept="application/pdf" />
                <button name="company-documents-aadhaar-number-upload-pdf-upload-btn"
                  id="company-documents-aadhaar-number-upload-pdf-upload-btn" class="upload-btn" matSuffix><img
                    [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-hint>Max 4MB PDF required</mat-hint>
              </mat-form-field>
            </div>
            <div name="good-standing-certi-image-display" id="good-standing-certi-image-display" class="col-12"
              *ngIf="documentsData.vGoodStandingCertificate">
              <div name="good-standing-certi-image-class-1" id="good-standing-certi-image-class-1"
                class="image-display-container margin-class">
                <div name="good-standing-certi-name-1" id="good-standing-certi-name-1" class="image-name">
                  <label>1)</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="documentsData.vGoodStandingCertificateUrl" target="_blank">{{
                    documentsData.vGoodStandingCertificate }}</a>
                </div>
                <button type="button" name="button" (click)="DeleteDocument(documentsData.vGoodStandingCertificate)">
                  <img id="good-standing-certi-img-1" alt="delete-image" [src]="imagePath + 'delete_image.svg'">
                </button>
              </div>
            </div>
            <div class="col-12">
              <label class="bold-title top-spacing">
                DUNS Number
              </label>
            </div>
            <div class="col-12 col-md-6 field-box">
              <label for="vDUNUSNumber" name="company-documents-tan-label" id="company-documents-tan-label"
                class="custom-label">DUNS Number </label>
              <mat-form-field hideRequiredMarker name="company-documents-tan-input" id="company-documents-tan-input"
                class="custom-input" floatLabel="never"  floatPlaceholder="never">
                <input type="text" matInput id="vDUNUSNumber" formControlName="vDUNUSNumber" placeholder="0000000000">
                <mat-error class="reduce-bottom-space" *ngIf="companyDocumentsForm.controls['vDUNUSNumber'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="(companyDocumentsForm.controls['vDUNUSNumber'].errors!.minlength || companyDocumentsForm.controls['vDUNUSNumber'].errors!.maxlength) && !(companyDocumentsForm.controls['vDUNUSNumber'].errors!.pattern)">
                    DUNS number length should be 9
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vDUNUSNumber'].errors!.pattern">
                    Only numeric characters allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div name="company-documents-modal-footer" id="company-documents-modal-footer" class="modal-footer">
        <button type="button" name="company-documents-btn-cancel" id="company-documents-btn-cancel"
        class="blue-secondary-small-btn mx-3" (click)="this.companyDocumentsToggle=false;this.companyDocumentsSubmitted = false; CancelDocumentsModal();GetCheckApplicable();
        GetCompanyDocuments();">Cancel</button>
        <button type="submit" name="company-documents-btn-submit" id="company-documents-btn-submit" class="blue-primary-small-btn"
          form="companyDocumentsForm">Save</button>
      </div>
    </div>
  </div>
</div>

