<div name="company-documents-box" id="company-documents-box" class="col-12">
  <div name="company-documents-card-container" id="company-documents-card-container" class="card-container" *ngIf="companyDocumentsToggle==false">
    <div name="company-documents-card-header" id="company-documents-card-header" class="card-header">
      <div name="company-documents-title-section" id="company-documents-title-section" class="title-section">
        <h4 name="company-documents-card-title" id="company-documents-card-title" class="card-title">Company Documents
        </h4>
        <div placement="right auto" [ngbTooltip]="editPartnerCompanyDocumentsActionPermission ? '':'Not Authorized'" [ngClass]="editPartnerCompanyDocumentsActionPermission ? '':'unauthorized-cursor'">
          <button name="company-documents-card-edit-button" id="company-documents-card-edit-button" class="card-edit-button" type="button" (click)="toggleCompanyDocuments(editPartnerCompanyDocumentsActionPermission)" [ngClass]="editPartnerCompanyDocumentsActionPermission ? '':'unauthorized-action'">
            <img class="card-edit-button-icon" [src]="imagePath + 'blue-edit-icon.svg'" alt="edit-button" />
          </button>
        </div>
        <!-- <img class="info-tooltip mx-2" [src]="imagePath + 'info_icon.svg'" alt="email-info" placement="top" ngbTooltip="It is not editable once approved by Rapidise." /> -->
      </div>
      <div name="company-documents-status-section" id="company-documents-status-section" class="status-section">
        <div name="company-documents-incomplete-status-box" id="company-documents-incomplete-status-box"
          class="status-box" *ngIf="!companyDocumentsCardCompleted">
          <img id="company-documents-incomplete-status-icon" class="status-icon" [src]="imagePath + 'pending-status.svg'"
            alt="status-icon">
          <label name="company-documents-incomplete-status-text" id="company-documents-incomplete-status-text"
            class="status-text incomplete">Pending</label>
        </div>
        <div name="company-documents-completed-status-text" id="company-documents-completed-status-text"
          class="status-box" *ngIf="companyDocumentsCardCompleted">
          <img id="company-documents-completed-status-icon" class="status-icon" [src]="imagePath + 'green-tick.svg'"
            alt="status-icon">
          <label name="company-documents-completed-status-text" id="company-documents-completed-status-text"
            class="status-text completed">Completed</label>
        </div>
      </div>
    </div>
    <div name="company-documents-card" id="company-documents-card" class="card"
      [ngClass]="companyDocumentsCardCompleted ? 'completed' : 'incomplete'">
      <div name="company-documents-card-content" id="company-documents-card-content" class="card-content">
        <div name="company-documents-incorporation-text-box-with-icon"
          id="company-documents-incorporation-text-box-with-icon" class="text-box-with-icon bottom-spacing">
          <img id="company-documents-incorporation-icon" class="icon"
            [src]="documentsStatus?.vCertificateOfIncorporationPdf ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="incorporation-img">
          <label name="company-documents-incorporation-text" id="company-documents-incorporation-text" name="text"
            id="text" class="text"
            [ngClass]="{'disabled': !documentsStatus?.vCertificateOfIncorporationPdf}">Certificate of incorporation
            <span class="text-danger"
              [ngClass]="{'d-none': documentsStatus?.vCertificateOfIncorporationPdf}">*</span></label>
        </div>
        <div name="company-documents-shop-establishment-text-box-with-icon"
          id="company-documents-shop-establishment-text-box-with-icon" class="text-box-with-icon bottom-spacing">
          <img id="company-documents-shop-establishment-text-icon" class="icon"
            [src]="documentsStatus?.vCertificateOfShopEstablishmentPdf ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="esta-img">
          <label name="company-documents-shop-establishment-text" id="company-documents-shop-establishment-text"
            class="text" [ngClass]="{'disabled': !documentsStatus?.vCertificateOfShopEstablishmentPdf}">Certificate of
            shop establishment <span class="text-danger"
              [ngClass]="{'d-none': documentsStatus?.vCertificateOfShopEstablishmentPdf}">*</span></label>
        </div>
        <div name="company-documents-udyog-aadhaar-text-box-with-icon"
          id="company-documents-udyog-aadhaar-text-box-with-icon" class="text-box-with-icon bottom-spacing">
          <img id="company-documents-udyog-aadhaar-icon" class="icon"
            [src]="documentsStatus?.vUdyogAadhaarPdf ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="udhyog-img">
          <label name="company-documents-udyog-aadhaar-text" id="company-documents-udyog-aadhaar-text" class="text"
            [ngClass]="{'disabled': !documentsStatus?.vUdyogAadhaarPdf}">Udyog Aadhaar (UID) <span class="text-danger"
              [ngClass]="{'d-none': documentsStatus?.vUdyogAadhaarPdf}">*</span></label>
        </div>
        <div name="company-documents-utility-bill-text-box-with-icon"
          id="company-documents-utility-bill-text-box-with-icon" class="text-box-with-icon bottom-spacing">
          <img id="company-documents-utility-bill-icon" class="icon"
            [src]="documentsStatus?.vUtilityBillPdf ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="utility-img">
          <label name="company-documents-utility-bill-text" id="company-documents-utility-bill-text" class="text"
            [ngClass]="{'disabled': !documentsStatus?.vUtilityBillPdf}">Utility Bill (Any of last 3 months) <span
              class="text-danger" [ngClass]="{'d-none': documentsStatus?.vUtilityBillPdf}">*</span></label>
        </div>
        <div name="company-documents-tax-deduction-account-number-text-box-with-icon"
          id="company-documents-tax-deduction-account-number-text-box-with-icon"
          class="text-box-with-icon bottom-spacing">
          <img id="company-documents-tax-deduction-account-number-icon" class="icon"
            [src]="documentsStatus?.vTANPdf ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="vtan-img">
          <label name="company-documents-tax-deduction-account-number-text"
            id="company-documents-tax-deduction-account-number-text" class="text"
            [ngClass]="{'disabled': !documentsStatus?.vTANPdf}">Tax Deduction Account Number (TAN) <span
              class="text-danger" [ngClass]="{'d-none': documentsStatus?.vTANPdf}">*</span></label>
        </div>
        <div name="company-documents-permanent-account-number-card-text-box-with-icon"
          id="company-documents-permanent-account-number-card-text-box-with-icon"
          class="text-box-with-icon bottom-spacing">
          <img id="company-documents-permanent-account-number-card-icon" class="icon"
            [src]="documentsStatus?.vPANPdf ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="pan-img">
          <label name="company-documents-permanent-account-number-card-text"
            id="company-documents-permanent-account-number-card-text" class="text"
            [ngClass]="{'disabled': !documentsStatus?.vPANPdf}">Permanent Account Number Card (PAN) <span
              class="text-danger" [ngClass]="{'d-none': documentsStatus?.vPANPdf}">*</span></label>
        </div>
        <div name="company-documents-cancel-bank-cheque-text-box-with-icon"
          id="company-documents-cancel-bank-cheque-text-box-with-icon" class="text-box-with-icon">
          <img id="company-documents-cancel-bank-cheque-icon" class="icon"
            [src]="documentsStatus?.vCancelledBankChequePdf ? imagePath + 'green-tick.svg' : imagePath + 'info-icon.svg'"
            alt="cheqe-img">
          <label name="company-documents-cancel-bank-cheque-text" id="company-documents-cancel-bank-cheque-text"
            class="text" [ngClass]="{'disabled': !documentsStatus?.vCancelledBankChequePdf}">Cancel Bank Cheque <span
              class="text-danger" [ngClass]="{'d-none': documentsStatus?.vCancelledBankChequePdf}">*</span></label>
        </div>
      </div>
    </div>
  </div>
  <div class="card-container" *ngIf="companyDocumentsToggle==true">
    <div name="company-documents-card-header" id="company-documents-card-header" class="card-header">
      <div name="company-documents-title-section" id="company-documents-title-section" class="title-section">
        <h4 name="company-documents-card-title" id="company-documents-card-title" class="card-title">Company Documents</h4>
      </div>
    </div>
    <div class="edit-profile">
      <div name="company-documents-modal-body" id="company-documents-modal-body" class="modal-body modal-scroll">
        <form id="companyDocumentsForm" name="companyDocumentsForm" class="form" [formGroup]="companyDocumentsForm"
          (ngSubmit)="SubmitCompanyDocuments()">
          <div class="row">
            <div class="col-12">
              <label class="bold-title">
                Certificate of incorporation
              </label>
            </div>
            <div class="col-12 col-md-6">
              <label for="vCorporateIdentityNumber" name="company-documents-cin-label" id="company-documents-cin-label"
                class="custom-label">Corporate Identity Number (CIN) <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-cin-input" id="company-documents-cin-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vCorporateIdentityNumber" formControlName="vCorporateIdentityNumber"
                  placeholder="L21091KA2019OPC141331" required>
                <mat-error class="reduce-bottom-space"
                  *ngIf="companyDocumentsForm.controls['vCorporateIdentityNumber'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vCorporateIdentityNumber'].errors!.required">
                    CIN is required
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vCorporateIdentityNumber'].errors!.pattern">
                    Please enter valid CIN with 21 string length
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <label for="vCertificateOfIncorporationPdf" name="company-documents-cin-pdf-label"
                id="company-documents-cin-pdf-label"  class="custom-label">Upload File <span
                  class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-cin-pdf-input"
                id="company-documents-cin-pdf-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="vCertificateOfIncorporationPdf?.name">
                          {{ vCertificateOfIncorporationPdf.name }} <button type="button" name="button" class="btn"
                            (click)="CancelMedia(1)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="company-documents-cin-pdf-vCertificateOfIncorporationPdf-1"
                  id="company-documents-cin-pdf-vCertificateOfIncorporationPdf-1" matInput readonly
                  [value]="vCertificateOfIncorporationPdf ? ' ' : ''" formControlName="vCertificateOfIncorporationPdf"
                  placeholder="Upload File" />
                <input matSuffix type="file" #uploadFile name="company-documents-cin-pdf-vCertificateOfIncorporationPdf-2"
                  id="company-documents-cin-pdf-vCertificateOfIncorporationPdf-2" class="file-upload-input"
                  (change)="FileBrowseHandler($event,1)" formControlName="vCertificateOfIncorporationPdf"
                  accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel" />
                <button name="company-documents-cin-pdf-upload-btn" id="company-documents-cin-pdf-upload-btn"
                  class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-error class="reduce-bottom-space"
                  *ngIf="companyDocumentsForm.controls['vCertificateOfIncorporationPdf'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vCertificateOfIncorporationPdf'].errors!.required">
                    File is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 4MB file required</mat-hint>
              </mat-form-field>
            </div>
            <div name="company-documents-cin-pdf-image-display" id="company-documents-cin-pdf-image-display"
              class="col-12" *ngIf="documentsData?.vCertificateOfIncorporationPdf">
              <div name="company-documents-cin-pdf-image-class-1" id="company-documents-cin-pdf-image-class-1"
                class="image-display-container margin-class">
                <div name="company-documents-cin-pdf-name-1" id="company-documents-cin-pdf-name-1" class="image-name">
                  <label>1)</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="documentsData.vCertificateOfIncorporationPdfUrl" target="_blank">{{
                    documentsData.vCertificateOfIncorporationPdf }}</a>
                </div>
                <!-- <button type="button" name="company-documents-cin-pdf-delete-button" id="company-documents-cin-pdf-delete-button" (click)="DeleteDocument(documentsData.vCertificateOfIncorporationPdf)">
                  <img id="company-documents-cin-pdf-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button> -->
              </div>
            </div>
            <div class="col-12">
              <label class="bold-title top-spacing">
                Certificate of shop establishment
              </label>
            </div>
            <div class="col-12 col-md-6">
              <label for="vShopEstablishmentCertificateNumber" name="company-documents-certificate-number-label"
                id="company-documents-certificate-number-label"  class="custom-label">Certificate Number <span
                  class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-certificate-number-input"
                id="company-documents-certificate-number-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vShopEstablishmentCertificateNumber"
                  formControlName="vShopEstablishmentCertificateNumber" placeholder="000000000000000000000" required>
                <mat-error class="reduce-bottom-space"
                  *ngIf="companyDocumentsForm.controls['vShopEstablishmentCertificateNumber'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vShopEstablishmentCertificateNumber'].errors!.required">
                    Certificate Number is required
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="(companyDocumentsForm.controls['vShopEstablishmentCertificateNumber'].errors!.minlength || companyDocumentsForm.controls['vShopEstablishmentCertificateNumber'].errors!.maxlength) && !companyDocumentsForm.controls['vShopEstablishmentCertificateNumber'].errors!.pattern">
                    Length must be between 3-21
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vShopEstablishmentCertificateNumber'].errors!.pattern">
                    No white space allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <label for="vCertificateOfShopEstablishmentPdf" name="company-documents-certificate-number-upload-pdf-label"
                id="company-documents-certificate-number-upload-pdf-label"  class="custom-label">Upload File <span
                  class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-certificate-number-upload-pdf-input"
                id="company-documents-certificate-number-upload-pdf-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="vCertificateOfShopEstablishmentPdf?.name">
                          {{ vCertificateOfShopEstablishmentPdf.name }} <button type="button" name="button" class="btn"
                            (click)="CancelMedia(2)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="company-documents-certificate-number-upload-pdf-vCertificateOfShopEstablishmentPdf-1"
                  id="company-documents-certificate-number-upload-pdf-vCertificateOfShopEstablishmentPdf-1" matInput
                  readonly [value]="vCertificateOfShopEstablishmentPdf ? ' ' : ''"
                  formControlName="vCertificateOfShopEstablishmentPdf" placeholder="Upload File" />
                <input matSuffix type="file" #uploadFile
                  name="company-documents-certificate-number-upload-pdf-vCertificateOfShopEstablishmentPdf-2"
                  id="company-documents-certificate-number-upload-pdf-vCertificateOfShopEstablishmentPdf-2"
                  class="file-upload-input" (change)="FileBrowseHandler($event,2)"
                  formControlName="vCertificateOfShopEstablishmentPdf"
                  accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel" />
                <button name="company-documents-certificate-number-upload-pdf-upload-btn"
                  id="company-documents-certificate-number-upload-pdf-upload-btn" class="upload-btn" matSuffix><img
                    [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-error class="reduce-bottom-space"
                  *ngIf="companyDocumentsForm.controls['vCertificateOfShopEstablishmentPdf'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vCertificateOfShopEstablishmentPdf'].errors!.required">
                    File is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 4MB file required</mat-hint>
              </mat-form-field>
            </div>
            <div name="company-documents-certificate-number-upload-pdf-image-display"
              id="company-documents-certificate-number-upload-pdf-image-display" class="col-12"
              *ngIf="documentsData?.vCertificateOfShopEstablishmentPdf">
              <div name="company-documents-certificate-number-upload-pdf-image-class-1"
                id="company-documents-certificate-number-upload-pdf-image-class-1"
                class="image-display-container margin-class">
                <div name="company-documents-certificate-number-upload-pdf-name-1"
                  id="company-documents-certificate-number-upload-pdf-name-1" class="image-name">
                  <label>1)</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="documentsData.vCertificateOfShopEstablishmentPdfUrl" target="_blank">{{
                    documentsData.vCertificateOfShopEstablishmentPdf }}</a>
                </div>
                <!-- <button type="button" name="company-documents-certificate-number-upload-pdf-delete-button" id="company-documents-certificate-number-upload-pdf-delete-button" (click)="DeleteDocument(documentsData.vCertificateOfShopEstablishmentPdf)">
                  <img id="company-documents-certificate-number-upload-pdf-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button> -->
              </div>
            </div>
            <div class="col-12">
              <label class="bold-title top-spacing">
                Udyog Aadhaar (UID)
              </label>
            </div>
            <div class="col-12 col-md-6">
              <label for="vUdyogAadhaarNumber" name="company-documents-aadhaar-number-label"
                id="company-documents-aadhaar-number-label"  class="custom-label">Udyog Aadhaar Number <span
                  class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-aadhaar-number-input"
                id="company-documents-aadhaar-number-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vUdyogAadhaarNumber" formControlName="vUdyogAadhaarNumber"
                  placeholder="WB00X0000000" required>
                <mat-error class="reduce-bottom-space"
                  *ngIf="companyDocumentsForm.controls['vUdyogAadhaarNumber'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vUdyogAadhaarNumber'].errors!.required">
                    Udyog Aadhaar Number is required
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="(companyDocumentsForm.controls['vUdyogAadhaarNumber'].errors!.minlength || companyDocumentsForm.controls['vUdyogAadhaarNumber'].errors!.maxlength) && !companyDocumentsForm.controls['vUdyogAadhaarNumber'].errors!.pattern">
                    Length must be 12
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vUdyogAadhaarNumber'].errors!.pattern">
                    Please enter valid Udyog Aadhaar Number
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <label for="vUdyogAadhaarPdf" name="company-documents-aadhaar-number-upload-pdf-label"
                id="company-documents-aadhaar-number-upload-pdf-label"  class="custom-label">Upload File <span
                  class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-aadhaar-number-upload-pdf-input"
                id="company-documents-aadhaar-number-upload-pdf-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="vUdyogAadhaarPdf?.name">
                          {{ vUdyogAadhaarPdf.name }} <button type="button" name="button" class="btn"
                            (click)="CancelMedia(3)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="company-documents-aadhaar-number-upload-pdf-vUdyogAadhaarPdf-1"
                  id="company-documents-aadhaar-number-upload-pdf-vUdyogAadhaarPdf-1" matInput readonly
                  [value]="vUdyogAadhaarPdf ? ' ' : ''" formControlName="vUdyogAadhaarPdf" placeholder="Upload File" />
                <input matSuffix type="file" #uploadFile
                  name="company-documents-aadhaar-number-upload-pdf-vUdyogAadhaarPdf-2"
                  id="company-documents-aadhaar-number-upload-pdf-vUdyogAadhaarPdf-2" class="file-upload-input"
                  (change)="FileBrowseHandler($event,3)" formControlName="vUdyogAadhaarPdf"
                  accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel" />
                <button name="company-documents-aadhaar-number-upload-pdf-upload-btn"
                  id="company-documents-aadhaar-number-upload-pdf-upload-btn" class="upload-btn" matSuffix><img
                    [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-error class="reduce-bottom-space" *ngIf="companyDocumentsForm.controls['vUdyogAadhaarPdf'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vUdyogAadhaarPdf'].errors!.required">
                    File is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 4MB file required</mat-hint>
              </mat-form-field>
            </div>
            <div name="company-documents-aadhaar-number-upload-pdf-image-display"
              id="company-documents-aadhaar-number-upload-pdf-image-display" class="col-12"
              *ngIf="documentsData.vUdyogAadhaarPdf">
              <div name="company-documents-aadhaar-number-upload-pdf-image-class-1"
                id="company-documents-aadhaar-number-upload-pdf-image-class-1"
                class="image-display-container margin-class">
                <div name="company-documents-aadhaar-number-upload-pdf-name-1"
                  id="company-documents-aadhaar-number-upload-pdf-name-1" class="image-name">
                  <label>1)</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="documentsData.vUdyogAadhaarPdfUrl" target="_blank">{{ documentsData.vUdyogAadhaarPdf }}</a>
                </div>
                <!-- <button type="button" name="company-documents-aadhaar-number-upload-pdf-delete-button" id="company-documents-aadhaar-number-upload-pdf-delete-button" (click)="DeleteDocument(documentsData.vUdyogAadhaarPdf)">
                  <img id="company-documents-aadhaar-number-upload-pdf-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button> -->
              </div>
            </div>
            <div class="col-12">
              <label class="bold-title top-spacing">
                Utility Bill
              </label>
            </div>
            <div class="col-12 col-md-6">
              <label for="vUtilityBillPdf" name="company-documents-utility-bill-upload-pdf-label"
                id="company-documents-utility-bill-upload-pdf-label"  class="custom-label">Upload File <span
                  class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-utility-bill-upload-pdf-input"
                id="company-documents-utility-bill-upload-pdf-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="vUtilityBillPdf?.name">
                          {{ vUtilityBillPdf.name }} <button type="button" name="button" class="btn"
                            (click)="CancelMedia(4)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="company-documents-utility-bill-upload-pdf-vUtilityBillPdf-1"
                  id="company-documents-utility-bill-upload-pdf-vUtilityBillPdf-1" matInput readonly
                  [value]="vUtilityBillPdf ? ' ' : ''" formControlName="vUtilityBillPdf" placeholder="Upload File" />
                <input matSuffix type="file" #uploadFile
                  name="company-documents-utility-bill-upload-pdf-vUtilityBillPdf-2"
                  id="company-documents-utility-bill-upload-pdf-vUtilityBillPdf-2" class="file-upload-input"
                  (change)="FileBrowseHandler($event,4)" formControlName="vUtilityBillPdf"
                  accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel" />
                <button name="company-documents-utility-bill-upload-pdf-upload-btn"
                  id="company-documents-utility-bill-upload-pdf-upload-btn" class="upload-btn" matSuffix><img
                    [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-error class="reduce-bottom-space" *ngIf="companyDocumentsForm.controls['vUtilityBillPdf'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vUtilityBillPdf'].errors!.required">
                    File is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 4MB file required</mat-hint>
              </mat-form-field>
            </div>
            <div name="company-documents-utility-bill-image-display" id="company-documents-utility-bill-image-display"
              class="col-12" *ngIf="documentsData.vUtilityBillPdf">
              <div name="company-documents-utility-bill-image-class-1" id="company-documents-utility-bill-image-class-1"
                class="image-display-container margin-class">
                <div name="company-documents-utility-bill-name-1" id="company-documents-utility-bill-name-1"
                  class="image-name">
                  <label>1)</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="documentsData.vUtilityBillPdfUrl" target="_blank">{{ documentsData.vUtilityBillPdf }}</a>
                </div>
                <!-- <button type="button" name="company-documents-utility-bill-delete-button" id="company-documents-utility-bill-delete-button" (click)="DeleteDocument(documentsData.vUtilityBillPdf)">
                  <img id="company-documents-utility-bill-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button> -->
              </div>
            </div>
            <div class="col-12">
              <label class="bold-title top-spacing">
                Tax Deduction Account Number
              </label>
            </div>
            <div class="col-12 col-md-6">
              <label for="vTANNumber" name="company-documents-tan-label" id="company-documents-tan-label"
                 class="custom-label">TAN Number <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-tan-input" id="company-documents-tan-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vTANNumber" formControlName="vTANNumber" placeholder="PDES03028F"
                  required>
                <mat-error class="reduce-bottom-space" *ngIf="companyDocumentsForm.controls['vTANNumber'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vTANNumber'].errors!.required">
                    TAN Number is required
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="(companyDocumentsForm.controls['vTANNumber'].errors!.minlength || companyDocumentsForm.controls['vTANNumber'].errors!.maxlength) && !companyDocumentsForm.controls['vTANNumber'].errors!.pattern">
                    Length must be 10
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vTANNumber'].errors!.pattern">
                    No white space allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <label for="vTANPdf" name="company-documents-tan-upload-pdf-label"
                id="company-documents-tan-upload-pdf-label"  class="custom-label">Upload File <span
                  class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-tan-upload-pdf-input"
                id="company-documents-tan-upload-pdf-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="vTANPdf?.name">
                          {{ vTANPdf.name }} <button type="button" name="button" class="btn" (click)="CancelMedia(5)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="company-documents-tan-upload-pdf-vTANPdf-1" id="company-documents-tan-upload-pdf-vTANPdf-1"
                  matInput readonly [value]="vTANPdf ? ' ' : ''" formControlName="vTANPdf" placeholder="Upload File" />
                <input matSuffix type="file" #uploadFile name="company-documents-tan-upload-pdf-vTANPdf-2"
                  id="company-documents-tan-upload-pdf-vTANPdf-2" class="file-upload-input"
                  (change)="FileBrowseHandler($event,5)" formControlName="vTANPdf"
                  accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel" />
                <button name="company-documents-tan-upload-pdf-upload-btn"
                  id="company-documents-tan-upload-pdf-upload-btn" class="upload-btn" matSuffix><img
                    [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-error class="reduce-bottom-space" *ngIf="companyDocumentsForm.controls['vTANPdf'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vTANPdf'].errors!.required">
                    File is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 4MB file required</mat-hint>
              </mat-form-field>
            </div>
            <div name="company-documents-tan-upload-pdf-image-display" id="company-documents-tan-upload-pdf-image-display"
              class="col-12" *ngIf="documentsData.vTANPdf">
              <div name="company-documents-tan-upload-pdf-image-class-1"
                id="company-documents-tan-upload-pdf-image-class-1" class="image-display-container margin-class">
                <div name="company-documents-tan-upload-pdf-name-1" id="company-documents-tan-upload-pdf-name-1"
                  class="image-name">
                  <label>1)</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="documentsData.vTANPdfUrl" target="_blank">{{ documentsData.vTANPdf }}</a>
                </div>
                <!-- <button type="button" name="company-documents-tan-upload-pdf-delete-button" id="company-documents-tan-upload-pdf-delete-button" (click)="DeleteDocument(documentsData.vTANPdf)">
                  <img id="company-documents-tan-upload-pdf-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button> -->
              </div>
            </div>
            <div class="col-12">
              <label class="bold-title top-spacing">
                Permanent Account Number
              </label>
            </div>
            <div class="col-12 col-md-6">
              <label for="vPANNumber" name="company-documents-pan-label" id="company-documents-pan-label"
                 class="custom-label">PAN Number <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-pan-input" id="company-documents-pan-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <input type="text" matInput id="vPANNumber" formControlName="vPANNumber" placeholder="EDPPP2132D"
                  required>
                <mat-error class="reduce-bottom-space" *ngIf="companyDocumentsForm.controls['vPANNumber'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vPANNumber'].errors!.required">
                    PAN is required
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="(companyDocumentsForm.controls['vPANNumber'].errors!.minlength || companyDocumentsForm.controls['vPANNumber'].errors!.maxlength) && !companyDocumentsForm.controls['vPANNumber'].errors!.pattern">
                    Length must be 10
                  </mat-error>
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vPANNumber'].errors!.pattern">
                    No white space allowed
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <label for="vPANPdf" name="company-documents-pan-upload-pdf-label"
                id="company-documents-pan-upload-pdf-label"  class="custom-label">Upload File <span
                  class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-pan-upload-pdf-input"
                id="company-documents-pan-upload-pdf-input" class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="vPANPdf?.name">
                          {{ vPANPdf.name }} <button type="button" name="button" class="btn" (click)="CancelMedia(6)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="company-documents-pan-upload-pdf-vPANPdf-1" id="company-documents-pan-upload-pdf-vPANPdf-1"
                  matInput readonly [value]="vPANPdf ? ' ' : ''" formControlName="vPANPdf" placeholder="Upload File" />
                <input matSuffix type="file" #uploadFile name="company-documents-pan-upload-pdf-vPANPdf-2"
                  id="company-documents-pan-upload-pdf-vPANPdf-2" class="file-upload-input"
                  (change)="FileBrowseHandler($event,6)" formControlName="vPANPdf"
                  accept="image/x-png,image/jpeg,application/pdf,application/vnd.ms-excel" />
                <button name="company-documents-pan-upload-pdf-upload-btn"
                  id="company-documents-pan-upload-pdf-upload-btn" class="upload-btn" matSuffix><img
                    [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-error class="reduce-bottom-space" *ngIf="companyDocumentsForm.controls['vPANPdf'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vPANPdf'].errors!.required">
                    File is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 4MB file required</mat-hint>
              </mat-form-field>
            </div>
            <div name="company-documents-pan-upload-pdf-image-display" id="company-documents-pan-upload-pdf-image-display"
              class="col-12" *ngIf="documentsData.vPANPdf">
              <div name="company-documents-pan-upload-pdf-image-class-1"
                id="company-documents-pan-upload-pdf-image-class-1" class="image-display-container margin-class">
                <div name="company-documents-pan-upload-pdf-name-1" id="company-documents-pan-upload-pdf-name-1"
                  class="image-name">
                  <label>1)</label>
                  <img class="pdf-icon" [src]="imagePath + 'pdf_icon.svg'" alt="images">
                  <a [href]="documentsData.vPANPdfUrl" target="_blank">{{ documentsData.vPANPdf }}</a>
                </div>
                <!-- <button type="button" name="company-documents-pan-upload-pdf-delete-button" id="company-documents-pan-upload-pdf-delete-button" (click)="DeleteDocument(documentsData.vPANPdf)">
                  <img id="company-documents-pan-upload-pdf-img-1" [src]="imagePath + 'delete_image.svg'" alt="Delete icon">
                </button> -->
              </div>
            </div>
            <div class="col-12">
              <label class="bold-title top-spacing">
                Bank Cheque (Cancelled)
              </label>
            </div>
            <div class="col-12 col-md-6">
              <label for="vCancelledBankChequePdf" name="company-documents-photo-label" id="company-documents-photo-label"
                 class="custom-label">Photo <span class="text-danger">*</span></label>
              <mat-form-field hideRequiredMarker name="company-documents-photo-input" id="company-documents-photo-input"
                class="custom-input" floatLabel="never" floatPlaceholder="never">
                <div class="file-container">
                  <div class="files">
                    <div class="file-scroll-box">
                      <perfect-scrollbar [config]="config">
                        <div class="file" *ngIf="vCancelledBankChequePdf?.name">
                          {{ vCancelledBankChequePdf.name }} <button type="button" name="button" class="btn"
                            (click)="CancelMedia(7)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
                <input name="company-documents-photo-vCancelledBankChequePdf-1"
                  id="company-documents-photo-vCancelledBankChequePdf-1" matInput readonly
                  [value]="vCancelledBankChequePdf ? ' ' : ''" formControlName="vCancelledBankChequePdf"
                  placeholder="Upload Photo" />
                <input matSuffix type="file" #uploadFile name="company-documents-photo-vCancelledBankChequePdf-2"
                  id="company-documents-photo-vCancelledBankChequePdf-2" class="file-upload-input"
                  (change)="FileBrowseHandler($event,7)" formControlName="vCancelledBankChequePdf"
                  accept="image/x-png,image/jpeg" />
                <button name="company-documents-photo-upload-btn" id="company-documents-photo-upload-btn"
                  class="upload-btn" matSuffix><img [src]="imagePath + 'file-upload-icon.svg'" alt="file upload"></button>
                <mat-error class="reduce-bottom-space"
                  *ngIf="companyDocumentsForm.controls['vCancelledBankChequePdf'].invalid">
                  <mat-error class="reduce-bottom-space"
                    *ngIf="companyDocumentsForm.controls['vCancelledBankChequePdf'].errors!.required">
                    Photo is required
                  </mat-error>
                </mat-error>
                <mat-hint>Max 4MB Image required</mat-hint>
              </mat-form-field>
            </div>
            <div name="company-documents-photo-upload-image-display" id="company-documents-photo-upload-image-display"
              class="col-12" *ngIf="documentsData.vCancelledBankChequePdf">
              <div name="company-documents-photo-upload-image-class-1" id="company-documents-photo-upload-image-class-1"
                class="image-display-container margin-class">
                <div name="company-documents-photo-upload-name-1" id="company-documents-photo-upload-name-1"
                  class="image-name">
                  <label>1)</label>
                  <img class="image-thumbnail" [src]="documentsData.vCancelledBankChequePdfUrl" alt="images">
                  <a [href]="documentsData.vCancelledBankChequePdfUrl" target="_blank">{{
                    documentsData.vCancelledBankChequePdf }}</a>
                </div>
                <!-- <button type="button" name="company-documents-photo-upload-delete-button" id="company-documents-photo-upload-delete-button" (click)="DeleteDocument(documentsData.vCancelledBankChequePdf)">
                  <img id="company-documents-photo-upload-img-1" [src]="imagePath + 'delete_image.svg" alt="Delete icon">
                </button> -->
              </div>
            </div>
          </div>
        </form>
      </div>
      <div name="company-documents-modal-footer" id="company-documents-modal-footer" class="modal-footer">
        <button type="button" name="company-documents-btn-cancel" id="company-documents-btn-cancel"
          class="blue-secondary-small-btn mx-2"
          (click)="this.companyDocumentsSubmitted = false; CancelDocumentsModal();">Cancel</button>
        <button type="submit" name="company-documents-btn-submit" id="company-documents-btn-submit" class="blue-primary-small-btn"
          form="companyDocumentsForm">Save</button>
      </div>
    </div>
  </div>
</div>

