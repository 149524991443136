import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { EventEmitterService } from "../../../../config/event-emitter.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { CompanyDocumentsService } from "./company-documents.service";
import { NotificationService } from "../../../../config/notification.service";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { StaticMsg } from "src/app/config/en";
import { environment } from "../../../../../environments/environment";
import { Permission } from "../../../../utils/enums/permission.enum";
import { UserPermissionService } from "../../../../utils/services/user-permission.service";

@Component({
  selector: "client-company-documents",
  templateUrl: "./company-documents.component.html",
  providers: [NgbModalConfig, NgbModal],
})
export class ClientCompanyDocumentsComponent implements OnInit {
  @Input() clientUserId:any;
  companyDocumentsToggle:boolean=false;

  editClientCompanyDocumentsActionPermission: boolean = false;

  imagePath = environment.image_path;

  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  mode: ProgressSpinnerMode = "determinate";

  companyDocumentsForm!: FormGroup;
  companyDocumentsSubmitted: any = false;

  @ViewChild("uploadFile") fileInput: any;

  vUdyogAadhaarPdf: any;
  vPANPdf: any;
  vUtilityBillPdf: any;
  vTANPdf: any;
  vCancelledBankChequePdf: any;
  documentsFormError: any = false;
  fileAttr: any;
  vCertificateOfIncorporationPdf: any;
  vGoodStandingCertificate: any;
  documentsData: any = [];
  documentsStatus: any;
  iClientDocumentsRequired: any;
  public companyDocumentsCardCompleted: any = false;
  
  public countryFields: any = [
    {
      id: 1,
      iCountryId: 101,
      label: "Employer Identification Number (EIN)",
      formControlName: "vEINNumber",
      validation: [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern("^[A-Z0-9]+$")],
      errorMsg: {
        required: "EIN is required",
        minimum: "EIN number length should be 9",
        maximum: "EIN number length should be 9",
        pattern: "Only alphanumeric characters allowed",
      },
    },
    {
      id: 2,
      iCountryId: 101,
      label: "Company File Number",
      formControlName: "vCompanyFileNumber",
      validation: [Validators.required, Validators.minLength(6), Validators.maxLength(10)],
      errorMsg: {
        required: "Company file number is required",
        minimum: "Company file number length must be 6-10",
        maximum: "Company file number length must be 6-10",
        pattern: "",
      },
    },
    {
      id: 3,
      iCountryId: 233,
      label: "Employer Identification Number (EIN)",
      formControlName: "vEINNumber",
      validation: [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern("^[A-Z0-9]+$")],
      errorMsg: {
        required: "EIN is required",
        minimum: "EIN number length should be 9",
        maximum: "EIN number length should be 9",
        pattern: "Only alphanumeric characters allowed",
      },
    },
    {
      id: 4,
      iCountryId: 233,
      label: "Company File Number",
      formControlName: "vCompanyFileNumber",
      validation: [Validators.required, Validators.minLength(6), Validators.maxLength(10)],
      errorMsg: {
        required: "Company file number is required",
        minimum: "Company file number length must be 6-10",
        maximum: "Company file number length must be 6-10",
        pattern: "",
      },
    },
  ];

  constructor(config: NgbModalConfig, private userPermissionService: UserPermissionService, private modalService: NgbModal, private companyDocumentsService: CompanyDocumentsService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService) {
    const EditClientCompanyDocumentsAction: Permission =  Permission.EditClientCompanyDocumentsAction;
    
    if (this.userPermissionService.isAuthorizedFor(EditClientCompanyDocumentsAction)) {
      this.editClientCompanyDocumentsActionPermission = true;
    }
    
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.eventEmitterService.GetProfileStatusSubMethod.subscribe((data: any) => {
      this.updateProfileStatus(data);
    });
    this.eventEmitterService.GetDynamicDoucmentsSubMethod.subscribe((data: any) => {
      this.CountryWiseFieldCreation();
    });
    this.companyDocumentsForm = new FormGroup({
      vCorporateIdentityNumber: new FormControl("", [Validators.required, Validators.pattern("^[A-Z0-9]{21}$")]),
      vCertificateOfIncorporationPdf: new FormControl("", [Validators.required]),
      vGoodStandingCertificate: new FormControl("", []),
      vDUNUSNumber: new FormControl("", [Validators.minLength(9), Validators.maxLength(9), Validators.pattern("^[A-Z0-9]+$")]),
    });
    this.GetCompanyDocuments();
  }

  public iProfilePublishCompleted: any = false;
  updateProfileStatus(data: any) {
    this.companyDocumentsCardCompleted = data["companyDocuments"];
    this.iProfilePublishCompleted = data["iProfilePublishCompleted"];
  }


  CancelDocumentsModal() {
    this.companyDocumentsForm.reset();
    this.modalService.dismissAll();
  }

  public countryWiseFields: any = [];
  public countryId: any;
  GetCompanyDocuments() {
    this.companyDocumentsService.GetCompanyDocuments(this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.documentsData = result["data"];
        this.documentsStatus = result["documentsStatus"];
        if (this.documentsStatus.vGoodStandingCertificate) {
          this.vGoodStandingCertificate = this.documentsData.vGoodStandingCertificate;
        }
        if (this.documentsStatus.vCertificateOfIncorporationPdf) {
          this.vCertificateOfIncorporationPdf = this.documentsData.vCertificateOfIncorporationPdf;
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].clearValidators();
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].setErrors(null);
        } else {
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].setValidators([Validators.required]);
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].patchValue("");
        }
        this.companyDocumentsForm.patchValue({
          vCorporateIdentityNumber: result["data"]["vCorporateIdentityNumber"],
          vDUNUSNumber: result["data"]["vDUNUSNumber"],
        });
      }
    });

    this.CountryWiseFieldCreation();
  }

  CountryWiseFieldCreation() {
    this.companyDocumentsService.GetCompanyDocuments(this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.countryId = result["data"]["countryId"];

        this.countryWiseFields = [];
        for (let i = 0; i < this.countryFields.length; i++) {
          if (this.countryId == this.countryFields[i].iCountryId) {
            let formControl = this.countryFields[i].formControlName;

            this.countryWiseFields.push(this.countryFields[i]);
            this.companyDocumentsForm.addControl(this.countryFields[i].formControlName, new FormControl("", this.countryFields[i].validation));

            if (result["data"][formControl]) {
              this.companyDocumentsForm.patchValue({
                [formControl]: result["data"][formControl],
              });
            }
          }
        }
      }
    });
  }

  FileBrowseHandler(event: any, type: any) {
    let fileSize = event["target"]["files"][0]["size"] / 1024;
    if (fileSize < 4 * 1024) {
      if (event.target.files[0].type == "application/pdf") {
        if (type == 1) {
          this.vCertificateOfIncorporationPdf = event.target.files[0];
        }
        if (type == 2) {
          this.vGoodStandingCertificate = event.target.files[0];
        }
      } else {
        this.notifyService.showError(StaticMsg.NotPdfFileError, "");
        return;
      }
    } else {
      this.notifyService.showError(StaticMsg.FileLargerError, "");
      if (type == 1) {
        this.vCertificateOfIncorporationPdf = "";
        this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].patchValue("");
      }
      if (type == 2) {
        this.vGoodStandingCertificate = "";
        this.companyDocumentsForm.controls["vGoodStandingCertificate"].patchValue("");
      }
      return;
    }
  }

  CancelMedia(type: any) {
    if (type == 1) {
      this.vCertificateOfIncorporationPdf = "";
      this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].patchValue("");
    }
    if (type == 2) {
      this.vGoodStandingCertificate = "";
      this.companyDocumentsForm.controls["vGoodStandingCertificate"].patchValue("");
    }
  }

  SubmitCompanyDocuments() {
    this.companyDocumentsSubmitted = true;
    if (this.companyDocumentsForm.invalid) {
      return;
    }
    let reqData = {
      vEINNumber: this.companyDocumentsForm.value.vEINNumber ? this.companyDocumentsForm.value.vEINNumber : '',
      vCompanyFileNumber: this.companyDocumentsForm.value.vCompanyFileNumber ? this.companyDocumentsForm.value.vCompanyFileNumber : '',
      vCorporateIdentityNumber: this.companyDocumentsForm.value.vCorporateIdentityNumber ? this.companyDocumentsForm.value.vCorporateIdentityNumber : '',
      vDUNUSNumber: this.companyDocumentsForm.value.vDUNUSNumber ? this.companyDocumentsForm.value.vDUNUSNumber : '',
      vCertificateOfIncorporationPdf: this.vCertificateOfIncorporationPdf ? this.vCertificateOfIncorporationPdf : '',
      vGoodStandingCertificate: this.vGoodStandingCertificate ? this.vGoodStandingCertificate : ''
    };

    this.companyDocumentsService.UpdateDocuments(reqData,this.clientUserId).then(
      (result: any) => {
        if (result.code == 200) {
          this.companyDocumentsForm.reset();
          this.GetCompanyDocuments();
          this.eventEmitterService.GetProfileStatusAPICallMethod();
          this.companyDocumentsToggle=false;
          this.notifyService.showSuccess("Documents submitted successfully!", "");
        } else {
          this.eventEmitterService.GetProfileStatusAPICallMethod();
        }
      },
      (err: any) => {
        console.log(err, "SubmitCompanyDocuments");
      }
    );
  }

  data_list: any = [];
  object: any = {};
  DeleteDocument(document: any) {
    this.companyDocumentsService.DeleteDocument(document,this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.notifyService.showSuccess("Document deleted successfully!", "");
        this.GetCompanyDocuments();
      }
    });
  }

  GetCheckApplicable() {
    this.companyDocumentsService.GetCheckApplicable(this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.iClientDocumentsRequired = result.data.iClientDocumentsRequired;

        if (this.iClientDocumentsRequired) {
          this.companyDocumentsForm.controls["vCorporateIdentityNumber"].disable();
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].disable();
          this.companyDocumentsForm.controls["vCorporateIdentityNumber"].patchValue("");
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].patchValue("");
          this.companyDocumentsForm.controls["vCorporateIdentityNumber"].clearValidators();
        } else {
          this.companyDocumentsForm.controls["vCorporateIdentityNumber"].enable();
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].enable();
          this.companyDocumentsForm.controls["vCorporateIdentityNumber"].setValidators([Validators.required, Validators.pattern("^[A-Z0-9]{21}$")]);
          this.companyDocumentsForm.controls["vCorporateIdentityNumber"].setErrors({ required: true });
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].setValidators([Validators.required]);
          this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].setErrors({ required: true });
        }
      }
    });
  }

  CheckApplicable(event: any) {
    let reqData = {
      iClientDocumentsRequired: event.checked,
    };
    this.companyDocumentsService.CheckApplicable(reqData,this.clientUserId).then((result: any) => {
      if (result.code == 200) {
        this.companyDocumentsForm.controls["vCorporateIdentityNumber"].patchValue("");
        this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].patchValue("");
        this.companyDocumentsForm.controls["vCorporateIdentityNumber"].clearValidators();
        this.companyDocumentsForm.controls["vCertificateOfIncorporationPdf"].clearValidators();
        this.vCertificateOfIncorporationPdf = "";
        this.GetCheckApplicable();
        this.eventEmitterService.GetProfileStatusAPICallMethod();
      }
    });
  }

  toggleCompanyDocuments(hasPermission:boolean){
    if(hasPermission){
      this.GetCheckApplicable();
      this.GetCompanyDocuments();
      this.vCertificateOfIncorporationPdf = "";
      this.vGoodStandingCertificate = "";
      this.companyDocumentsToggle=true;
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }
}
