import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TicketService } from './ticket.service';
import * as moment from 'moment-timezone';
import { ThemePalette } from '@angular/material/core';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitterService } from '../../config/event-emitter.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/config/notification.service';


@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  providers: [NgbModalConfig, NgbModal]
})
export class TicketComponent implements OnInit {

  @ViewChild('PartnerTablePaginator', { static: true }) partnerTablePaginator: MatPaginator;
  @ViewChild('PartnerTableSort', { static: true }) partnerTableSort: MatSort;

  @ViewChild('ClientTablePaginator', { static: true }) clientTablePaginator: MatPaginator;
  @ViewChild('ClientTableSort', { static: true }) clientTableSort: MatSort;

  @ViewChild('EmployeeTablePaginator', { static: true }) employeeTablePaginator: MatPaginator;
  @ViewChild('EmployeeTableSort', { static: true }) employeeTableSort: MatSort;

  @ViewChild('InterviewTablePaginator', { static: true }) interviewTablePaginator: MatPaginator;
  @ViewChild('InterviewTableSort', { static: true }) interviewTableSort: MatSort;

  @ViewChild('ContractTablePaginator', { static: true }) contractTablePaginator: MatPaginator;
  @ViewChild('ContractTableSort', { static: true }) contractTableSort: MatSort;

  displayedColumnsPartnerTable: string[] = ['indexnumber', 'vDisplayUniqueId', 'vEmailId', 'createdBy', 'tiStatus', 'iCreatedAt', 'actions'];
  dataSourcePartnerTable = new MatTableDataSource();

  displayedColumnsClientTable: string[] = ['indexnumber', 'vDisplayUniqueId', 'vEmailId', 'createdBy', 'tiStatus', 'iCreatedAt', 'actions'];
  dataSourceClientTable = new MatTableDataSource();

  displayedColumnsEmployeeTable: string[] = ['indexnumber', 'vDisplayUniqueId', 'vEmailId', 'createdBy', 'tiStatus', 'iCreatedAt', 'actions'];
  dataSourceEmployeeTable = new MatTableDataSource();

  displayedColumnsInterviewTable: string[] = ['indexnumber', 'tiTicketType', 'vEmailId', 'createdBy', 'tiStatus', 'iCreatedAt', 'actions'];
  dataSourceInterviewTable = new MatTableDataSource();

  displayedColumnsContractTable: string[] = ['indexnumber', 'tiTicketType', 'vEmailId', 'createdBy', 'tiStatus', 'iCreatedAt', 'actions'];
  dataSourceContractTable = new MatTableDataSource();

  //Calander Coding Start
  @ViewChild('picker') picker: any;
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  //Calander Coding End
  tickets: any;
  pageIndex: number = 1;
  perPage: number = 10;
  ticketCount: number = 0;
  TicketRejectForm: FormGroup;
  AddInterviewForm: FormGroup;
  isTicketReasonSubmit: boolean = false;
  isAddInterviewSubmit: boolean = false;
  iTicketId: number = 0;
  tiTicketType: number = 0;

  constructor(
    private notifyService: NotificationService,
    private ticketService: TicketService,
    public ngxSmartModalService: NgxSmartModalService,
    public eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    config: NgbModalConfig
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.refreshAPIdata();
    this.TicketRejectForm = new FormGroup({
      reason: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(150)])
    });
    this.AddInterviewForm = new FormGroup({
      vSubject: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
      startInterview: new FormControl('', [Validators.required]),
      endInterview: new FormControl('', [Validators.required]),
    });
  }

  ngAfterViewInit() {
    this.dataSourcePartnerTable.paginator = this.partnerTablePaginator;
    this.dataSourcePartnerTable.sort = this.partnerTableSort;

    this.dataSourceClientTable.paginator = this.clientTablePaginator;
    this.dataSourceClientTable.sort = this.clientTableSort;

    this.dataSourceEmployeeTable.paginator = this.employeeTablePaginator;
    this.dataSourceEmployeeTable.sort = this.employeeTableSort;

    this.dataSourceInterviewTable.paginator = this.interviewTablePaginator;
    this.dataSourceInterviewTable.sort = this.interviewTableSort;

    this.dataSourceContractTable.paginator = this.contractTablePaginator;
    this.dataSourceContractTable.sort = this.contractTableSort;
  }

  applyFilterPartner(filterValue: any) {
    this.dataSourcePartnerTable.filter = filterValue.target.value.trim().toLowerCase();
  }

  applyFilterClient(filterValue: any) {
    this.dataSourceClientTable.filter = filterValue.target.value.trim().toLowerCase();
  }

  applyFilterEmployee(filterValue: any) {
    this.dataSourceEmployeeTable.filter = filterValue.target.value.trim().toLowerCase();
  }

  applyFilterInterview(filterValue: any) {
    this.dataSourceInterviewTable.filter = filterValue.target.value.trim().toLowerCase();
  }

  applyFilterContract(filterValue: any) {
    this.dataSourceContractTable.filter = filterValue.target.value.trim().toLowerCase();
  }

  OpenInterviewPopup(modalToOpen: any, iTicketId: any) {
    this.iTicketId = iTicketId;
    this.isAddInterviewSubmit = false;
    this.AddInterviewForm.reset();
    this.modalService.open(modalToOpen, { centered: true });
  }

  CloseInterviewPopup() {
    this.isAddInterviewSubmit = false;
    this.AddInterviewForm.reset();
    this.modalService.dismissAll();
  }

  InterviewSchedule() {
    if (this.AddInterviewForm.invalid) {
      this.isAddInterviewSubmit = true;
      return;
    }
    let json_req = {
      iTicketId: this.iTicketId, vSubject: '', startInterview: 0, endInterview: 0
    }
    json_req["vSubject"] = this.AddInterviewForm.get('vSubject') ?.value;
    json_req["startInterview"] = new Date(this.AddInterviewForm.get('startInterview') ?.value).getTime();
    json_req["endInterview"] = new Date(this.AddInterviewForm.get('endInterview') ?.value).getTime();

    if (json_req["startInterview"].toString().length == 13) {
      json_req["startInterview"] = this.AddInterviewForm.get('startInterview') ?.value / 1000;
    }
    if (json_req["endInterview"].toString().length == 13) {
      json_req["endInterview"] = this.AddInterviewForm.get('endInterview') ?.value / 1000;
    }
    if (json_req["endInterview"] - json_req["startInterview"] < 0) {
      this.eventEmitterService.modelMessage({ "msg":"You have entered invalid time!", "status":2 });
      return;
    }
    this.ticketService.InterviewSchedule(json_req).then((result: any) => {
      if (result['code'] == 200 || result['code'] == 204) {
        this.refreshAPIdata();
        this.CloseInterviewPopup();
        this.notifyService.showSuccess(result.message,'');
      } else {
        this.refreshAPIdata();
        this.CloseInterviewPopup();
        this.notifyService.showError(result.message,'');
      }
    }).catch((err: any) => {console.log(err)});
  }

  TicketApproval() {
    var reason;
    this.isTicketReasonSubmit = true;
    if (this.TicketRejectForm.invalid) {
      return;
    }
    reason = this.TicketRejectForm.get('reason') ?.value;
    this.ticketAPICalled(3, reason, this.iTicketId, this.tiTicketType);
  }

  ticketAPICalled(status: any, reason: any, iTicketId: any, tiTicketType: any) {
    let json_data = { status: status, iTicketId: iTicketId, reason: reason, tiTicketType: tiTicketType };
    this.ticketService.TicketApproval(json_data).then((result: any) => {
      if (result['code'] == 200 || result['code'] == 204) {
        this.refreshAPIdata();
        this.closeReasonPopUp();
        this.notifyService.showSuccess(result.message,'');
      } else {
        this.refreshAPIdata();
        this.closeReasonPopUp();
        this.notifyService.showError(result.message,'');
      }
    }).catch((err: any) => {
      console.log(err);
    });
  }

  closeReasonPopUp() {
    this.isTicketReasonSubmit = false;
    this.TicketRejectForm.reset();
    this.modalService.dismissAll();
  }

  selectedEmployeeData: any;
  openModalWithData(modalToOpen: any, data: any) {
    this.modalService.open(modalToOpen, { centered: true });
    this.selectedEmployeeData = data;
  }

  refreshAPIdata(){
    this.interviewTicketList();
    this.contractTicketList();
    this.partnerTicketList();
    this.clientTicketList();
    this.employeeTicketList();
  }

  interviewTicketList(){
    this.ticketService.interviewTicketList().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceInterviewTable.data = result['data'];
      }
    }).catch((err: any) => {console.log(err)});
  }

  contractTicketList(){
    this.ticketService.contractTicketList().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceContractTable.data = result['data'];
      }
    }).catch((err: any) => {console.log(err)});
  }

  partnerTicketList(){
    this.ticketService.partnerTicketList().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourcePartnerTable.data = result['data'];
      }
    }).catch((err: any) => {console.log(err)});
  }

  clientTicketList(){
    this.ticketService.clientTicketList().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceClientTable.data = result['data'];
      }
    }).catch((err: any) => {console.log(err)});
  }

  employeeTicketList(){
    this.ticketService.employeeTicketList().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceEmployeeTable.data = result['data'];
      }
    }).catch((err: any) => {console.log(err)});
  }

  public ticket_data: any = {};
  clickApproveReject(modalToOpen: any, data: any, status: any) {
    this.ticket_data = data;
    if (status == 'accept') {
      this.ticketAPICalled(2, '', data.iTicketId, data.tiTicketType);
    } else {
      this.iTicketId = data.iTicketId;
      this.tiTicketType = data.tiTicketType;
      this.isTicketReasonSubmit = false;
      this.TicketRejectForm.reset();
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  interviewApproveReject(modalToOpen: any, data: any, status: any){
    this.ticket_data = data;
    if(status == 'accept'){
      this.interviewApproveRejectAPICalled(2, '', data.iTicketId, data.tiTicketType);
    }
    else{
      this.iTicketId = data.iTicketId;
      this.tiTicketType = data.tiTicketType;
      this.isTicketReasonSubmit = false;
      this.TicketRejectForm.reset();
      this.modalService.open(modalToOpen, { centered: true });
    }
  }

  interviewRejectReson() {
    var reason;
    this.isTicketReasonSubmit = true;
    if (this.TicketRejectForm.invalid) {
      return;
    }
    reason = this.TicketRejectForm.get('reason') ?.value;
    this.interviewApproveRejectAPICalled(3, reason, this.iTicketId, this.tiTicketType);
  }

  interviewApproveRejectAPICalled(status: any, reason: any, iTicketId: any, tiTicketType: any) {
    let json_data = { status: status, iTicketId: iTicketId, reason: reason, tiTicketType: tiTicketType };
    this.ticketService.interviewApproveRejectAPICalled(json_data).then((result: any) => {
      this.closeReasonPopUp();
      this.interviewTicketList();
      this.notifyService.showSuccess(result.message,'');
    }).catch((err: any) => {console.log(err)});
  }
}
