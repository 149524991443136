import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EventEmitterService } from "src/app/config/event-emitter.service";
import { InterviewsService } from "./interviews.service";
import { Permission } from "src/app/utils/enums/permission.enum";
import { UserPermissionService } from "src/app/utils/services/user-permission.service";
import { NotificationService } from "src/app/config/notification.service";

interface Common {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-interviews',
  templateUrl: './interviews.component.html'
})
export class InterviewsComponent implements OnInit {

  // toggle actions permission
  accessVideoConferencePermission: boolean = false;

  interviewStatus: Common[] = [
    { value: 0, viewValue: "Pending" },
    { value: 1, viewValue: "Hired" },
    { value: 2, viewValue: "Rejected" },
    { value: 3, viewValue: "Reschedualed" },
    { value: 4, viewValue: "Interview completed / expired" },
    { value: 5, viewValue: "Cancled by client" },
    { value: 6, viewValue: "Upcoming interview" },
    { value: 7, viewValue: "Completed (when contract created)" },
    { value: 8, viewValue: "Interview Running" },
    { value: 9, viewValue: "Interview accepted by partner" },
    { value: 10, viewValue: "Cancled by partner" },
    { value: 11, viewValue: "Contract created" },
    { value: 12, viewValue: "Partner proposed time" },
    { value: 13, viewValue: "2nd round" },
  ];

  apiResponse!: any;
  filterType: any = "All";
  filterValue: String = "";
  displayedColumnsTableOne: string[] = ["indexnumber", "vEmpName", "vEmpDesignation", "fEmpExperience", "iDateEpoch", "iRoundNumber", "vClientCompanyName", "vPartnerCompanyName", "tiInterviewStatus", "actions"];
  dataSourceTableOne = new MatTableDataSource();
  view: any = 0;

  @ViewChild("TableOnePaginator", { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort", { static: true }) tableOneSort: MatSort;

  constructor(private interviewService: InterviewsService, 
    public eventEmitterService: EventEmitterService, 
    private modalService: NgbModal, 
    config: NgbModalConfig, 
    private userPermissionService: UserPermissionService,
    private notifyService : NotificationService) 
  {
    config.backdrop = "static";
    config.keyboard = false;

    // assign individual permissions
    const AccessVideoConference: Permission = Permission.AccessVideoConference;

    // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(AccessVideoConference)) {
      this.accessVideoConferencePermission = true;
    }
  }

  ngOnInit(): void {
    this.GetInterview();
  }

  OpenModal(modalToOpen: any) {
    this.modalService.open(modalToOpen, { centered: true });
  }

  OpenModelViewInterview(modelName: any, element: any) {
    this.modalService.open(modelName, { centered: true });
  }

  ngAfterViewInit() {
    this.dataSourceTableOne.paginator = this.tableOnePaginator;
    this.dataSourceTableOne.sort = this.tableOneSort;
  }

  allDetails() {
    this.view = "0";
    this.GetInterview();
  }

  upcomingDetails() {
    this.view = "1";
    this.GetInterview();
  }

  historyDetails() {
    this.view = "2";
    this.GetInterview();
  }

  applyFilterOnSearch(event: any) {
    this.filterValue = event.target.value;
    this.GetInterview();
  }

  // selectReasonType(){
  // this.GetInterview();
  // }

  GetInterview() {
    this.interviewService
      .GetInterviewData(this.view)
      .then((result: any) => {
        if (result["code"] == 200) {
          this.apiResponse = result["data"];
          // console.log(this.apiResponse, "this.apiResponse >>>>>>>>>.");
          
          this.dataSourceTableOne.data = result["data"];
          if (this.filterType == "All") {
            this.dataSourceTableOne.data = this.apiResponse;
            this.dataSourceTableOne.filter = this.filterValue.trim().toLowerCase();
          } else {
            let filteredData = this.apiResponse.filter((item: any) => {
              return item.tiInterviewStatus == this.filterType;
            });
            this.dataSourceTableOne.data = filteredData;
            this.dataSourceTableOne.filter = this.filterValue.trim().toLowerCase();
          }
        } else {
          // this.notifyService.showError(result.message,'');
        this.notifyService.showError(result.message, "");

        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}
