import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CertificationsService {
  constructor(private http: HttpClient) {}

  CheckApplicable(data: any,clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/check-applicable?iUserId=${clientUserId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCheckApplicable(clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/check-applicable?iUserId=${clientUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCertificate(clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/list/certificate?iUserId=${clientUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCMMICertificates(clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/user-certificate?iUserId=${clientUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UploadCertificate(data: any,clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/user-certificate?iUserId=${clientUserId}`;
      const formData: FormData = new FormData();
      formData.append("vCMMIId", data.vCMMIId);
      formData.append("vCMMICerNumber", data.vCMMICerNumber);
      formData.append("cmmiCertificate", data.cmmiCertificate);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteCertificate(id: any,clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/user-certificate/${id}?iUserId=${clientUserId}`;
      this.http
        .delete(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
