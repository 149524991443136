import { Component, OnInit } from '@angular/core';
import { RapidiseContactService } from './rapidiseContact.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-rapidiseContact',
  templateUrl: './rapidiseContact.component.html'
})
export class RapidiseContactComponent implements OnInit {
  
  constructor( 
    private contactUsService : RapidiseContactService
  ){}

  displayedColumnsTableOne: string[] = ['indexnumber', 'vName', 'vEmailId', 'vPhoneNo', 'txMessage', 'iCreatedAt'];
  dataSourceTableOne = new MatTableDataSource();

  ngOnInit(): void {
    this.GetRapidiseContact();
  }

  applyFilterOne(filterValue: any) {
    this.dataSourceTableOne.filter = filterValue.target.value.trim().toLowerCase();
  }

  GetRapidiseContact() {
    this.contactUsService.GetRapidiseContact().then((result: any) => {
      if (result['code'] == 200) {
        this.dataSourceTableOne.data = result['data'];
      }
    });
  }
}
