import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PrimaryDetailsService {
  constructor(private http: HttpClient) {}

  GetCountryList() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/countryList`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetStateList(countryId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/stateList?iCountryId=` + countryId;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetCityList(stateId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v1/cityList?iStateId=` + stateId;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetPrimaryDetails(clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/primary-details?iUserId=${clientUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdatePrimaryDetails(data: any, selectedImage: any, date: any,iCountryId:any,iStateId:any,iCityId:any,clientUserId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/primary-details?iUserId=${clientUserId}`;
      const formData: FormData = new FormData();
      formData.append("iCityId", iCityId);
      formData.append("iCompanyBio", data.iCompanyBio);
      formData.append("iCountryId", iCountryId);
      formData.append("iStateId", iStateId);
      formData.append("iZipCode", data.iZipCode);
      formData.append("txFacebookUrl", data.txFacebookUrl);
      formData.append("txInstagramUrl", data.txInstagramUrl);
      formData.append("txLinkedinUrl", data.txLinkedinUrl);
      formData.append("txTwitterUrl", data.txTwitterUrl);
      formData.append("vAddress", data.vAddress);
      formData.append("vEmailId", data.vEmailId);
      formData.append("vDialCode", data.vPhoneNo.dialCode);
      formData.append("vCountryCode", data.vPhoneNo.countryCode);
      formData.append("vPhoneNo", data.vPhoneNo.number);
      formData.append("vAltEmailId", data.vAltEmailId);
      formData.append("vCompanyName", data.vCompanyName);
      formData.append("vCompanyWebsiteUrl", data.vCompanyWebsiteUrl);
      formData.append("iCompanyFoundedDate", date);
      formData.append("newImage", selectedImage);
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

}
