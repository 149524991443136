<div class="page-content admin-section">

    <h1 class="admin-title">Publish</h1>

    <div>
        <div class="button-container">
            <button mat-button (click)="partnerDetails(viewPartnerPublishActionPermission)" *ngIf="viewPartnerPublishActionPermission" [ngClass]="{'active-tab-btn' : view == 0}"
                class="inactive-tab-btn">Partner</button>
            <button mat-button (click)="clientDetails(viewClientPublishActionPermission)" *ngIf="viewClientPublishActionPermission" [ngClass]="{'active-tab-btn' : view == 1}"
                class="inactive-tab-btn space-left">Client</button>
            <button mat-button (click)="talentDetails(viewEmployeePublishActionPermission)" *ngIf="viewEmployeePublishActionPermission" [ngClass]="{'active-tab-btn' : view == 2}"
                class="inactive-tab-btn space-left">Talent</button>
        </div>
        <div class="view-container">
            <h2 *ngIf="view == 0" class="main-heading">Partner Publish Ticket</h2>
            <h2 *ngIf="view == 1" class="main-heading">Client Publish Ticket</h2>
            <h2 *ngIf="view == 2" class="main-heading">Talent Publish Ticket</h2>
            <mat-form-field class="custom-input custom-search-input" floatLabel="never" floatPlaceholder="never">
                <mat-label>Search Email Address</mat-label>
                <input matInput type="text" (keyup)="applyFilterOne($event)">
                <button matSuffix>
                    <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        viewBox="0 0 20 20">
                        <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
                        <path id="Path_690" data-name="Path 690"
                            d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
                            transform="translate(0)" fill="#464646" />
                    </svg>
                </button>
            </mat-form-field>
        </div>
    </div>

    <div class="table-border">
        <div class="table-responsive">
            <div *ngIf="dataSourceTableOne?.filteredData?.length == 0" class="emptyRecordDiv">No Records Found
            </div>
            <table mat-table [ngClass]="{'dm-mat-hide-table': dataSourceTableOne?.filteredData?.length <= 0 }"
                [dataSource]="dataSourceTableOne" matSort #TableOneSort="matSort" class="datatable">
                <ng-container matColumnDef="indexnumber">
                    <th mat-header-cell *matHeaderCellDef> No </th>
                    <td mat-cell *matCellDef="let i = index;">
                        {{ this.TableOnePaginator.pageIndex == 0 ? i+1 : i+ 1 + this.TableOnePaginator.pageIndex *
                        this.TableOnePaginator.pageSize}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="vEmailId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.vEmailId}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="view == 0" class="d-flex justify-content-start">
                            <button class="status-btn action-btn bg-blue" placement="left auto"
                                [ngbTooltip]="viewPartnerProfileActionPermission ? 'View Profile':'Not Authorized'"
                                [ngClass]="viewPartnerProfileActionPermission ? '':'unauthorized-cursor'"
                                (click)="viewPartnerProfile(element,viewPartnerProfileActionPermission)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="12.5"
                                    viewBox="0 0 18.333 12.5">
                                    <path id="Path_9273" data-name="Path 9273"
                                        d="M10.167,4.5A9.856,9.856,0,0,0,1,10.75a9.847,9.847,0,0,0,18.333,0A9.856,9.856,0,0,0,10.167,4.5Zm0,10.417a4.167,4.167,0,1,1,4.167-4.167A4.168,4.168,0,0,1,10.167,14.917Zm0-6.667a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,10.167,8.25Z"
                                        transform="translate(-1 -4.5)" fill="#48acf0" />
                                </svg>
                            </button>
                            <button class="status-btn action-btn bg-green" placement="left auto"
                                [ngbTooltip]="approvePartnerPublishActionPermission ? 'Approve':'Not Authorized'"
                                [ngClass]="approvePartnerPublishActionPermission ? '':'unauthorized-cursor'"
                                (click)="acceptPartnerPublish(element,approvePartnerPublishActionPermission)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="check_circle_FILL1_wght600_GRAD200_opsz40"
                                        d="M9.142,13.987,14.7,8.41,13.442,7.132l-4.3,4.3L7.02,9.311,5.743,10.588ZM10.25,18.25a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z"
                                        transform="translate(-2.25 -2.25)" fill="#58b47c" />
                                </svg>
                            </button>
                            <button class="status-btn action-btn bg-red" placement="left auto"
                                [ngbTooltip]="rejectPartnerPublishActionPermission ? 'Reject':'Not Authorized'"
                                [ngClass]="rejectPartnerPublishActionPermission ? '':'unauthorized-cursor'"
                                (click)="rejectPartnerPublish(element,rejectPartnerPublishActionPermission)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="cancel_FILL1_wght600_GRAD200_opsz40"
                                        d="M7.546,14.025l2.7-2.685,2.7,2.685,1.07-1.07-2.685-2.7,2.685-2.7-1.07-1.07-2.7,2.685-2.7-2.685-1.07,1.07,2.685,2.7-2.685,2.7Zm2.7,4.225a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z"
                                        transform="translate(-2.25 -2.25)" fill="#ee6060" />
                                </svg>
                            </button>
                        </div>
                        <div *ngIf="view == 1" class="d-flex">
                            <button class="status-btn action-btn bg-blue" placement="left auto"
                                [ngbTooltip]="viewClientProfileActionPermission ? 'View Profile':'Not Authorized'"
                                [ngClass]="viewClientProfileActionPermission ? '':'unauthorized-cursor'"
                                (click)="viewClientProfile(element,viewClientProfileActionPermission)" data-bs-toggle="tooltip"
                                data-bs-placement="bottom" title="View Profile">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="12.5"
                                    viewBox="0 0 18.333 12.5">
                                    <path id="Path_9273" data-name="Path 9273"
                                        d="M10.167,4.5A9.856,9.856,0,0,0,1,10.75a9.847,9.847,0,0,0,18.333,0A9.856,9.856,0,0,0,10.167,4.5Zm0,10.417a4.167,4.167,0,1,1,4.167-4.167A4.168,4.168,0,0,1,10.167,14.917Zm0-6.667a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,10.167,8.25Z"
                                        transform="translate(-1 -4.5)" fill="#48acf0" />
                                </svg>
                            </button>
                            <button class="status-btn action-btn bg-green" placement="left auto"
                                [ngbTooltip]="approveClientPublishActionPermission ? 'Approve':'Not Authorized'"
                                [ngClass]="approveClientPublishActionPermission ? '':'unauthorized-cursor'"
                                (click)="acceptPublish(element,approveClientPublishActionPermission)" data-bs-toggle="tooltip"
                                data-bs-placement="bottom" title="Approve">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="check_circle_FILL1_wght600_GRAD200_opsz40"
                                        d="M9.142,13.987,14.7,8.41,13.442,7.132l-4.3,4.3L7.02,9.311,5.743,10.588ZM10.25,18.25a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z"
                                        transform="translate(-2.25 -2.25)" fill="#58b47c" />
                                </svg>
                            </button>
                            <button class="status-btn action-btn bg-red" placement="left auto"
                                [ngbTooltip]="rejectClientPublishActionPermission ? 'Reject':'Not Authorized'"
                                [ngClass]="rejectClientPublishActionPermission ? '':'unauthorized-cursor'"
                                (click)="rejectPublish(element,rejectClientPublishActionPermission)" data-bs-toggle="tooltip"
                                data-bs-placement="bottom" title="Reject">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="cancel_FILL1_wght600_GRAD200_opsz40"
                                        d="M7.546,14.025l2.7-2.685,2.7,2.685,1.07-1.07-2.685-2.7,2.685-2.7-1.07-1.07-2.7,2.685-2.7-2.685-1.07,1.07,2.685,2.7-2.685,2.7Zm2.7,4.225a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z"
                                        transform="translate(-2.25 -2.25)" fill="#ee6060" />
                                </svg>
                            </button>
                        </div>
                        <div *ngIf="view == 2" class="d-flex">
                            <button class="status-btn action-btn bg-blue" placement="left auto"
                                [ngbTooltip]="viewEmployeePublishActionPermission ? 'View Profile':'Not Authorized'"
                                [ngClass]="viewEmployeePublishActionPermission ? '':'unauthorized-cursor'"
                                (click)="viewTalentProfile(element,viewEmployeePublishActionPermission)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="12.5"
                                    viewBox="0 0 18.333 12.5">
                                    <path id="Path_9273" data-name="Path 9273"
                                        d="M10.167,4.5A9.856,9.856,0,0,0,1,10.75a9.847,9.847,0,0,0,18.333,0A9.856,9.856,0,0,0,10.167,4.5Zm0,10.417a4.167,4.167,0,1,1,4.167-4.167A4.168,4.168,0,0,1,10.167,14.917Zm0-6.667a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,10.167,8.25Z"
                                        transform="translate(-1 -4.5)" fill="#48acf0" />
                                </svg>
                            </button>
                            <button class="status-btn action-btn bg-green" placement="left auto"
                                [ngbTooltip]="approveEmployeePublishActionPermission ? 'Approve':'Not Authorized'"
                                [ngClass]="approveEmployeePublishActionPermission ? '':'unauthorized-cursor'"
                                (click)="acceptEmployeePublish(element,approveEmployeePublishActionPermission)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="check_circle_FILL1_wght600_GRAD200_opsz40"
                                        d="M9.142,13.987,14.7,8.41,13.442,7.132l-4.3,4.3L7.02,9.311,5.743,10.588ZM10.25,18.25a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z"
                                        transform="translate(-2.25 -2.25)" fill="#58b47c" />
                                </svg>
                            </button>
                            <button class="status-btn action-btn bg-red" placement="left auto"
                                [ngbTooltip]="rejectEmployeePublishActionPermission ? 'Reject':'Not Authorized'"
                                [ngClass]="rejectEmployeePublishActionPermission ? '':'unauthorized-cursor'"
                                (click)="rejectEmployeePublish(element,rejectEmployeePublishActionPermission)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="cancel_FILL1_wght600_GRAD200_opsz40"
                                        d="M7.546,14.025l2.7-2.685,2.7,2.685,1.07-1.07-2.685-2.7,2.685-2.7-1.07-1.07-2.7,2.685-2.7-2.685-1.07,1.07,2.685,2.7-2.685,2.7Zm2.7,4.225a7.815,7.815,0,0,1-3.118-.629,7.95,7.95,0,0,1-4.253-4.253,8.038,8.038,0,0,1,0-6.235A8.084,8.084,0,0,1,4.6,4.579,7.984,7.984,0,0,1,7.142,2.87a8.127,8.127,0,0,1,6.226,0,7.963,7.963,0,0,1,4.882,7.38,7.85,7.85,0,0,1-.62,3.108A7.984,7.984,0,0,1,15.921,15.9a8.084,8.084,0,0,1-2.554,1.718A7.815,7.815,0,0,1,10.25,18.25Z"
                                        transform="translate(-2.25 -2.25)" fill="#ee6060" />
                                </svg>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
            </table>
        </div>
        <mat-paginator [ngClass]="{'dm-mat-hide-paginator': dataSourceTableOne?.filteredData?.length <= 0 }"
            #TableOnePaginator="matPaginator" [pageSizeOptions]="[10,25,50]" showFirstLastButtons>
        </mat-paginator>
    </div>

</div>
