<div class="page-content dashboard-redesign">
  <div class="dashboard">
    <h1 class="dashboard-main-heading">Dashboard</h1>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xxl-2 col-md-4 col-sm-6 col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Total Clients</div>
            </div>
            <div class="card-body" [ngClass]="accessClientUserManagementPermission ? '':'unauthorized-layerblur'">
              <div class="card-main-info">
                <div class="main-count">{{countClient?.totalClient}}</div>
                <div>
                  <span *ngIf="countClient?.pl == null" class="count-percentage d-none">
                    {{countClient?.pl | number: '1.0-0'}}
                  </span>
                  <span *ngIf="countClient?.pl >= 0 && countClient?.pl != null" class="count-percentage in-increase">
                    + {{countClient?.pl | number: '1.0-0'}}%
                  </span>
                  <span *ngIf="countClient?.pl < 0 && countClient?.pl != null" class="count-percentage in-decrease">
                    {{countClient?.pl | number: '1.0-0'}}%
                  </span>
                </div>
              </div>
              <div class="card-sub-info">
                <div class="card-loop-count">
                  <div class="sub-count">{{countClient?.onBoardingCount}}</div>
                  <div class="card-sub-info-text">Onboard</div>
                </div>
                <div class="card-loop-count">
                  <div class="sub-count">{{countClient?.publishCount}}</div>
                  <div class="card-sub-info-text">Published</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-2 col-md-4 col-sm-6 col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Total Partner</div>
            </div>
            <div class="card-body" [ngClass]="accessPartnerUserManagementPermission ? '':'unauthorized-layerblur'">
              <div class="card-main-info">
                <div class="main-count">{{countPartner?.totalClient}}</div>
                <div>
                  <span *ngIf="countPartner?.pl == null" class="count-percentage d-none">
                    {{countPartner?.pl | number: '1.0-0'}}
                  </span>
                  <span *ngIf="countPartner?.pl >= 0 && countPartner?.pl != null" class="count-percentage in-increase">
                    + {{countPartner?.pl | number: '1.0-0'}}%
                  </span>
                  <span *ngIf="countPartner?.pl < 0 && countPartner?.pl != null" class="count-percentage in-decrease">
                    {{countPartner?.pl | number: '1.0-0'}}%
                  </span>
                </div>
              </div>
              <div class="card-sub-info">
                <div class="card-loop-count">
                  <div class="sub-count">{{countPartner?.onBoardingCount}}</div>
                  <div class="card-sub-info-text">Onboard</div>
                </div>
                <div class="card-loop-count">
                  <div class="sub-count">{{countPartner?.publishCount}}</div>
                  <div class="card-sub-info-text">Published</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-2 col-md-4 col-sm-6 col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Total Talent</div>
            </div>
            <div class="card-body" [ngClass]="accessEmployeeUserManagementPermission ? '':'unauthorized-layerblur'">
              <div class="card-main-info">
                <div class="main-count">{{countTalent?.totalClient}}</div>
                <div>
                  <span *ngIf="countTalent?.pl == null" class="count-percentage d-none">
                    {{countTalent?.pl | number: '1.0-0'}}
                  </span>
                  <span *ngIf="countTalent?.pl >= 0 && countTalent?.pl != null" class="count-percentage in-increase">
                    + {{countTalent?.pl | number: '1.0-0'}}%
                  </span>
                  <span *ngIf="countTalent?.pl < 0 && countTalent?.pl != null" class="count-percentage in-decrease">
                    {{countTalent?.pl | number: '1.0-0'}}%
                  </span>
                </div>
              </div>
              <div class="card-sub-info">
                <div class="card-loop-count">
                  <div class="sub-count">{{countTalent?.onBoardingCount}}</div>
                  <div class="card-sub-info-text">Onboard</div>
                </div>
                <div class="card-loop-count">
                  <div class="sub-count">{{countTalent?.publishCount}}</div>
                  <div class="card-sub-info-text">Published</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-2 col-md-4 col-sm-6 col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Total Contracts</div>
            </div>
            <div class="card-body" [ngClass]="viewContractsPermission ? '':'unauthorized-layerblur'">
              <div class="card-main-info">
                <div class="main-count">{{countContracts?.totalContracts}}</div>
                <div>
                  <span *ngIf="countContracts?.pl == null" class="count-percentage d-none">
                    {{countContracts?.pl | number: '1.0-0'}}
                  </span>
                  <span *ngIf="countContracts?.pl >= 0 && countContracts?.pl != null"
                    class="count-percentage in-increase">
                    + {{countContracts?.pl | number: '1.0-0'}}%
                  </span>
                  <span *ngIf="countContracts?.pl < 0 && countContracts?.pl != null"
                    class="count-percentage in-decrease">
                    {{countContracts?.pl | number: '1.0-0'}}%
                  </span>
                </div>
              </div>
              <div class="card-sub-info">
                <div class="card-loop-count">
                  <div class="sub-count">{{countContracts?.activeMonthData}}</div>
                  <div class="card-sub-info-text">Active this month</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-2 col-md-4 col-sm-6 col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Total Requirements</div>
            </div>
            <div class="card-body" [ngClass]="viewRequirementsPermission ? '':'unauthorized-layerblur'">
              <div class="card-main-info">
                <div class="main-count">{{countRequirements?.totalRequirements}}</div>
                <div>
                  <span *ngIf="countRequirements?.pl == null" class="count-percentage d-none">
                    {{countRequirements?.pl | number: '1.0-0'}}
                  </span>
                  <span *ngIf="countRequirements?.pl >= 0 && countRequirements?.pl != null"
                    class="count-percentage in-increase">
                    + {{countRequirements?.pl | number: '1.0-0'}}%
                  </span>
                  <span *ngIf="countRequirements?.pl < 0 && countRequirements?.pl != null"
                    class="count-percentage in-decrease">
                    {{countRequirements?.pl | number: '1.0-0'}}%
                  </span>
                </div>
              </div>
              <div class="card-sub-info">
                <div class="card-loop-count">
                  <div class="sub-count">{{countRequirements?.fullFilledDetails}}</div>
                  <div class="card-sub-info-text">Fulfilled this month</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-2 col-md-4 col-sm-6 col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Total Interviews</div>
            </div>
            <div class="card-body" [ngClass]="viewInterviewsPermission ? '':'unauthorized-layerblur'">
              <div class="card-main-info">
                <div class="main-count">{{countInterviews?.totalInterviews}}</div>
                <!-- <div class="count-percentage">+ 20%</div> -->
              </div>
              <div class="card-sub-info">
                <div class="card-loop-count">
                  <div class="sub-count">
                    <span *ngIf="countInterviews?.pl == null" class="d-none">
                      {{countInterviews?.pl | number: '1.0-0'}}
                    </span>
                    <span *ngIf="countInterviews?.pl >= 0 && countInterviews?.pl != null">
                      + {{countInterviews?.pl | number: '1.0-0'}}%
                    </span>
                    <span *ngIf="countInterviews?.pl < 0 && countInterviews?.pl != null">
                      {{countInterviews?.pl | number: '1.0-0'}}%
                    </span>
                  </div>
                  <div class="card-sub-info-text">scheduled this month.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Total inventory value</div>
            </div>
            <div class="card-body" [ngClass]="accessEmployeeUserManagementPermission ? '':'unauthorized-layerblur'">
              <div class="card-main-info">
                <div class="main-count">$ {{talentOverview?.talentsPrice}}</div>
              </div>
              <div class="card-sub-info">
                <div class="card-loop-count">
                  <div class="card-sub-info-text">All available and unavailable engineers MRR</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Total bookable inventory value</div>
            </div>
            <div class="card-body" [ngClass]="accessEmployeeUserManagementPermission ? '':'unauthorized-layerblur'">
              <div class="card-main-info">
                <div class="main-count">$ {{talentOverview?.availableTalentsPrice}}</div>
              </div>
              <div class="card-sub-info">
                <div class="card-loop-count">
                  <div class="card-sub-info-text">All available engineers MRR</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Total inventory under contract value</div>
            </div>
            <div class="card-body" [ngClass]="accessEmployeeUserManagementPermission ? '':'unauthorized-layerblur'">
              <div class="card-main-info">
                <div class="main-count">$ {{talentOverview?.hireTalentsPrice}}</div>
              </div>
              <div class="card-sub-info">
                <div class="card-loop-count">
                  <div class="card-sub-info-text">All hired engineers MRR</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12">
          <div class="card pe-0">
            <div class="card-header card-border-spacing">
              <div class="card-title">Revenue</div>
            </div>
            <div class="card-body pe-3" [ngClass]="viewBillingPermission ? '':'unauthorized-layerblur unauthorized-layerblur-pointer-event'">
              <div class="overview-text-count-wrapper">
                <div class="overview-text-count">
                  <div class="overview-sub-text">
                    Total Revenue
                    <span class="ms-2">
                      <img src="./assets/images/green-increase.svg" alt="increase">
                    </span>
                  </div>
                  <div class="overview-count">$ 0</div>
                  <div class="overview-sub-text">
                    <span class="green-percentage">0%</span> Yearly earning overview
                  </div>
                </div>
                <div class="revenue-dropdown">
                  <mat-form-field appearance="outline">
                    <mat-select [(value)]="selectedRevenue" placeholder="Select Revenue Type">
                      <mat-option *ngFor="let option of vRevenueArray" [value]="option.iRevenueId">
                        {{ option.vRevenueValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="mt-4">
                <canvas baseChart [datasets]="revenueBarChartData" [labels]="revenueBarChartLabels"
                  [options]="revenueBarChartOptions" [plugins]="revenueBarChartPlugins" [legend]="revenueBarChartLegend"
                  [chartType]="revenueBarChartType">
                </canvas>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12">
          <div class="card pe-0">
            <div class="card-header card-border-spacing">
              <div class="card-title">Billing</div>
            </div>
            <div class="card-body pe-3" [ngClass]="viewBillingPermission ? '':'unauthorized-layerblur'">
              <div class="overview-text-count">
                <div class="overview-sub-text">
                  Total Billing
                  <span class="ms-2">
                    <img src="./assets/images/blue-increase.svg" alt="increase">
                  </span>
                </div>
                <div class="overview-count">$ 0</div>
                <div class="overview-sub-text">
                  <span class="blue-percentage me-1">0%</span>
                  Yearly earning overview
                </div>
              </div>
              <div class="mt-4">
                <canvas baseChart [datasets]="billingBarChartData" [labels]="billingBarChartLabels"
                  [options]="billingBarChartOptions" [plugins]="billingBarChartPlugins" [legend]="billingBarChartLegend"
                  [chartType]="billingBarChartType">
                </canvas>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-4 col-md-8 col-12">
          <div class="card pe-0">
            <div class="card-header card-border-spacing">
              <div class="card-title">Client Overview</div>
            </div>
            <div class="card-body pe-3" [ngClass]="accessClientUserManagementPermission ? '':'unauthorized-layerblur'">
              <perfect-scrollbar [config]="config" class="dashboard-client-ps">
                <table mat-table [dataSource]="clientOverviewSource" class="dashboard-table-progressbar">
                  <ng-container matColumnDef="vCompanyName">
                    <th mat-header-cell *matHeaderCellDef> Client Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.vCompanyName}} </td>
                  </ng-container>
                  <ng-container matColumnDef="requirementCount">
                    <th mat-header-cell *matHeaderCellDef> Requirements </th>
                    <td mat-cell *matCellDef="let element" class="table-requirement"> {{element.requirementCount}} </td>
                  </ng-container>
                  <ng-container matColumnDef="fullFilledCount">
                    <th mat-header-cell *matHeaderCellDef class="dashboard-blue-progress-cell"> Fulfilled </th>
                    <td mat-cell *matCellDef="let element" class="dashboard-blue-progress-cell">
                      <div class="dashboard-progress-wrapper">{{element.fullFilledCount}}%
                        <mat-progress-bar mode="determinate" value={{element.fullFilledCount}}
                          class="dashboard-blue-progress"></mat-progress-bar>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="clientOverviewColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: clientOverviewColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                      No data found
                    </td>
                  </tr>
                </table>
              </perfect-scrollbar>
            </div>
          </div>
        </div>

        <div class="col-xxl-2 col-md-4 col-12">
          <div class="card pe-0">
            <div class="card-header card-border-spacing">
              <div class="card-title">Talent</div>
            </div>
            <div class="card-body pe-3" [ngClass]="accessEmployeeUserManagementPermission ? '':'unauthorized-layerblur unauthorized-layerblur-pointer-event'">
              <div class="overview-text-count">
                <div class="overview-sub-text">Talent Overview</div>
                <div class="overview-count">{{talentOverview?.totalTalent}}</div>
                <div class="overview-sub-text">Total Talent</div>
              </div>
              <div class="my-4">
                <canvas baseChart [datasets]="talentDonutChartData" [labels]="talentDonutChartLabels"
                  [options]="talentDonutChartOptions" [plugins]="talentDonutChartPlugins"
                  [legend]="talentDonutChartLegend" [chartType]="talentDonutChartType" height="150">
                </canvas>
              </div>
            </div>
            <div class="card-footer talent" [ngClass]="accessEmployeeUserManagementPermission ? '':'unauthorized-layerblur'">
              <div class="graph-content-wrapper">
                <div class="graph-percentage green">{{talentOverview?.hiredPl}}%</div>
                <div class="graph-text">Hired</div>
              </div>
              <div class="graph-content-wrapper">
                <div class="graph-percentage blue">{{talentOverview?.shortListedPl}}%</div>
                <div class="graph-text">Shortlisted</div>
              </div>
              <div class="graph-content-wrapper">
                <div class="graph-percentage grey">{{talentOverview?.availablePl}}%</div>
                <div class="graph-text">Available</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-6 col-12">
          <div class="card pe-0">
            <div class="card-header card-border-spacing card-header-data">
              <div class="card-title">Total Requirements</div>
              <div class="card-subtitle radio-wrapper" [ngClass]="viewRequirementsPermission ? '':'unauthorized-layerblur unauthorized-layerblur-pointer-event'">
                <mat-radio-group [(ngModel)]="requirementFilterType" class="requirement-filter-type"
                  (change)="OnRequirementFilterChange($event)">
                  <mat-radio-button *ngFor="let requirementFilter of requirementFilterArray"
                    [value]="requirementFilter.requirementId">
                    {{requirementFilter.requirementValue}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="card-body pe-3" [ngClass]="viewRequirementsPermission ? '':'unauthorized-layerblur'">
              <div class="overview-text-count">
                <div class="overview-sub-text">Total Requirements</div>
                <div class="overview-count">{{totalRequirements?.totalRequirement}}</div>
                <div class="overview-sub-text">Requirements created in this month</div>
              </div>
              <div>
                <perfect-scrollbar [config]="config" class="dashboard-requirement-ps">
                  <table mat-table [dataSource]="requirementSource" class="dashboard-table-progressbar">
                    <ng-container matColumnDef="talentRequirement">
                      <th mat-header-cell *matHeaderCellDef> Talent Requirement </th>
                      <td mat-cell *matCellDef="let element"> {{element.vPrimarySkill}} ({{element.skillPercentage}}%)
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="hiredTalent">
                      <th mat-header-cell *matHeaderCellDef class="dashboard-green-progress-cell"> Hired Talent </th>
                      <td mat-cell *matCellDef="let element" class="dashboard-green-progress-cell">
                        <div class="dashboard-progress-wrapper">
                          {{element.userHiredCount}}/{{element.requirementTalentCount}}
                          <mat-progress-bar mode="determinate" value={{element.hiredPercentage}}
                            class="dashboard-green-progress"></mat-progress-bar>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="totalAvailableTalent">
                      <th mat-header-cell *matHeaderCellDef> Total Available Talent </th>
                      <td mat-cell *matCellDef="let element">
                        <span class="green-text">{{element.availableCount}}</span> {{element.vPrimarySkill}}
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="totalRequirementColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: totalRequirementColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="4">
                        No data found
                      </td>
                    </tr>
                  </table>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-2 col-md-4 col-12">
          <div class="card pe-0">
            <div class="card-header card-border-spacing">
              <div class="card-title">Deals</div>
            </div>
            <div class="card-body pe-3" [ngClass]="viewDealEnginePermission ? '':'unauthorized-layerblur'">
              <div class="overview-text-count">
                <div class="overview-sub-text">Deals Overview</div>
                <div class="overview-count">{{dealsOverview?.totalDeals}}</div>
                <div class="overview-sub-text">Total Deals</div>
              </div>
              <div class="my-4">
                <canvas baseChart [datasets]="dealsDonutChartData" [labels]="dealsDonutChartLabels"
                  [options]="dealsDonutChartOptions" [plugins]="dealsDonutChartPlugins" [legend]="dealsDonutChartLegend"
                  [chartType]="dealsDonutChartType" height="150">
                </canvas>
              </div>
            </div>
            <div class="card-footer">
              <div class="graph-content-wrapper">
                <div class="graph-percentage green dot"></div>
                <div class="graph-text">Active Deal</div>
              </div>
              <div class="graph-content-wrapper">
                <div class="graph-percentage grey dot"></div>
                <div class="graph-text">Inactive Deal</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-4 col-md-8 col-12">
          <div class="card pe-0">
            <div class="card-header card-border-spacing">
              <div class="card-title">Profiles by Countries</div>
            </div>
            <div class="card-body pe-3">
              <div class="overview-text-count-wrapper">
                <div class="overview-text-count">
                  <div class="overview-sub-text">Profile Overview</div>
                  <div class="overview-count">{{countryProfileOverview?.totalCountrysProfile}}</div>
                  <div class="overview-sub-text">Total clients, Partners & Talents</div>
                </div>
                <div class="radio-dropdown-wrapper">
                  <div class="revenue-dropdown profile-dropdown" *ngIf="profileFilterType == 1">
                    <mat-form-field appearance="outline">
                      <mat-select [(value)]="selectedProfileMonth" placeholder="Select Month" (selectionChange)="OnProfileMonthChange($event)">
                        <mat-option *ngFor="let option of vProfileMonthArray" [value]="option.iMonthId">
                          {{ option.vMonthValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="radio-wrapper">
                    <mat-radio-group [(ngModel)]="profileFilterType" class="requirement-filter-type"
                      (change)="OnProfileFilterChange($event)">
                      <mat-radio-button *ngFor="let profileFilter of profileFilterArray"
                        [value]="profileFilter.profileId">
                        {{profileFilter.profileValue}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <div class="my-4">
                <canvas baseChart [datasets]="profilesByCountryBarChartData" [labels]="profilesByCountryBarChartLabels"
                  [options]="profilesByCountryBarChartOptions" [plugins]="profilesByCountryBarChartPlugins"
                  [legend]="profilesByCountryBarChartLegend" [chartType]="profilesByCountryBarChartType">
                </canvas>
              </div>
            </div>
            <div class="card-footer">
              <div class="graph-content-wrapper">
                <div class="graph-percentage yellow small">{{countryProfileOverview?.totalCountrysProfile}}%</div>
                <div class="graph-text">Clients</div>
              </div>
              <div class="graph-content-wrapper">
                <div class="graph-percentage red small">{{countryProfileOverview?.totalCountrysProfile}}%</div>
                <div class="graph-text">Partners</div>
              </div>
              <div class="graph-content-wrapper">
                <div class="graph-percentage blue small">{{countryProfileOverview?.totalCountrysProfile}}%</div>
                <div class="graph-text">Talents</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-4 col-md-8 col-12">
          <div class="card pe-0">
            <div class="card-header card-border-spacing card-header-data">
              <div class="card-title">Recent Contracts</div>
              <div class="card-subtitle contracts-view-all" [ngClass]="viewContractsPermission ? '':'unauthorized-layerblur unauthorized-layerblur-pointer-event'">
                <a routerLink="/contracts">View All</a>
              </div>
            </div>
            <div class="card-body pe-3 pt-3" [ngClass]="viewContractsPermission ? '':'unauthorized-layerblur'">
              <perfect-scrollbar [config]="config" class="dashboard-contract-ps">
                <table mat-table [dataSource]="recentContractSource" class="dashboard-table-contract">
                  <ng-container matColumnDef="contractName">
                    <th mat-header-cell *matHeaderCellDef> Contract Name </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.vContractName | titlecase}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="talentName">
                    <th mat-header-cell *matHeaderCellDef> Talent Name </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.vFirstName | titlecase}} {{element.vLastName | titlecase}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef> Start Date </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.iContractStartDate * 1000 | date: 'dd MMM yyyy'}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> Price </th>
                    <td mat-cell *matCellDef="let element" class="contract-price-column">
                      ${{element.fAmount}}/m
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef> Duration </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.iHiringDuration}} Month(s)
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="recentContractColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: recentContractColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                      No data found
                    </td>
                  </tr>
                </table>
              </perfect-scrollbar>
            </div>
          </div>
        </div>

        <div class="col-xxl-2 col-md-4 col-12">
          <div class="card pe-0">
            <div class="card-header card-border-spacing card-header-data">
              <div class="card-title">Interviews</div>
              <div class="card-subtitle interviews-month" [ngClass]="viewInterviewsPermission ? '':'unauthorized-layerblur'">Month</div>
            </div>
            <div class="card-body pe-3" [ngClass]="viewInterviewsPermission ? '':'unauthorized-layerblur'">
              <div class="overview-text-count">
                <div class="overview-sub-text">Interviews Overview</div>
                <div class="overview-count">{{interviewsOverview?.totalInterviews}}</div>
                <div class="overview-sub-text">Total Interviews</div>
              </div>
              <div class="my-4">
                <canvas baseChart [datasets]="interviewsDonutChartData" [labels]="interviewsDonutChartLabels"
                  [options]="interviewsDonutChartOptions" [plugins]="interviewsDonutChartPlugins"
                  [legend]="interviewsDonutChartLegend" [chartType]="interviewsDonutChartType" height="150">
                </canvas>
              </div>
            </div>
            <div class="card-footer" [ngClass]="viewInterviewsPermission ? '':'unauthorized-layerblur'">
              <div class="graph-content-wrapper">
                <div class="graph-percentage yellow dot"></div>
                <div class="graph-text">Hired</div>
              </div>
              <div class="graph-content-wrapper">
                <div class="graph-percentage green dot"></div>
                <div class="graph-text">2nd Round</div>
              </div>
              <div class="graph-content-wrapper">
                <div class="graph-percentage red dot"></div>
                <div class="graph-text">Rejected</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>